import { Table } from '@devexpress/dx-react-grid-material-ui';
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { IconCreditCard, IconMessage, IconMessageCheck, IconPencil } from '@tabler/icons-react';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import React, { memo, type MouseEventHandler, useCallback, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, Link as ReactRouterLink, useHistory, useParams } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RuleType } from '@amal-ia/compensation-definition/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { CustomObjectDefinitionType } from '@amal-ia/data-capture/models/types';
import { type FilterOverwriteRemoveCreationRequestDetails } from '@amal-ia/data-capture/overwrites/components';
import { IconButton } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext, Can } from '@amal-ia/frontend/kernel/authz/context';
import { IconCreditCardClockOutline } from '@amal-ia/frontend/ui-icons';
import {
  selectCurrentStatement,
  selectCurrentStatementThreads,
  selectPeriodsMap,
  selectStatementPaymentForExternalIdAndRuleId,
  selectUsersMap,
  setOpenedThread,
  useCompanyCustomization,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import {
  type DatasetRow,
  OverwriteTypesEnum,
  type Payment,
  PaymentButtonStatus,
  type RootState,
  type StatementThreadScope,
  StatementThreadScopesType,
  TRUNCATED_CONSTANT,
} from '@amal-ia/lib-types';
import { Avatar, eventStopPropagation, useCustomIconStyles } from '@amal-ia/lib-ui';
import { PaymentTooltip } from '@amal-ia/lib-ui-business';
import { DatasetType, type FilterDataset } from '@amal-ia/payout-calculation/shared/types';

import { RowsTableUtils } from './RowsTable.utils';
import { rowsTableCellMessages } from './RowsTableCell.messages';
import RowsTableCellActions from './RowsTableCellActions';
import { RowsTableCellContent } from './RowsTableCellContent';
import RowsTableContext from './RowsTableContext';
import useRowsTableStyles from './useRowsTableStyles';

import DataCellProps = Table.DataCellProps;

const localStopPropagation = (event) => event.stopPropagation();

const RowsTableCell = memo(function RowsTableCell(
  propsCell: DataCellProps & {
    onFocus: (e) => void;
    onClick: (e) => void;
    className: string;
  },
) {
  const classes = useRowsTableStyles();
  const { formatMessage } = useIntl();
  const iconClasses = useCustomIconStyles();
  const dispatch = useThunkDispatch();
  const {
    ruleid: ruleIdFromUrl,
    action,
    externalid: externalIdFromUrl,
  } = useParams<{ ruleid: string; action: string; externalid: string }>();
  const history = useHistory();

  const companyCustomization = useCompanyCustomization();

  const usersMap = useSelector(selectUsersMap);
  const periodsMap = useSelector(selectPeriodsMap);
  const currentStatement = useSelector(selectCurrentStatement);
  const statementThreads = useSelector(selectCurrentStatementThreads);
  const ability = useAbilityContext();

  const {
    tableColumn: { column },
    tableRow,
  } = propsCell;
  const row = tableRow?.row as DatasetRow;

  const {
    fields,
    dataset,
    cellFormats,

    setTracingExternalId,
    setCurrentTracingData,

    hideComments,
    hideEditOverwrite,
    cellEditColumnExtensions,

    ruleId,
    ruleDefinition,

    serializeRow,
    handleOpenOverwriteModal,
    handleClearOverwrite,
    handleOpenFilterOverwriteRemoveModal,
    isForecastedView,
  } = useContext(RowsTableContext);

  const customObjectDefinition =
    currentStatement?.results?.definitions.customObjects[dataset.customObjectDefinition.machineName];

  const isColumnReadOnly = useMemo(
    () => (cellEditColumnExtensions || []).some((col) => col.columnName === column.name && !col.editingEnabled),
    [column, cellEditColumnExtensions],
  );
  const variableDefinition = useMemo(
    () => currentStatement?.results?.definitions.variables[column.name],
    [currentStatement, column],
  );

  // ================ COMMENTS ================
  const cellStatementThread = useMemo(() => {
    if (!currentStatement) {
      return null;
    }

    return RowsTableUtils.getCellCommentThread(
      customObjectDefinition?.id,
      row.externalId,
      column.name,
      ruleId,
      statementThreads,
    );
  }, [customObjectDefinition, statementThreads, row, column, ruleId, currentStatement]);

  const openStatementThread = useCallback(() => {
    const rowId = row.externalId;
    const columnName = column.title;

    if (!currentStatement) {
      return;
    }

    if (!cellStatementThread) {
      history.push(generatePath(routes.STATEMENT_COMMENT_CREATE, { id: currentStatement.id }));
      const scope: StatementThreadScope = {
        type: StatementThreadScopesType.OBJECT,
        id: rowId,
        name: row?.name,
        field: columnName,
        ruleId,
        definitionId: customObjectDefinition?.id,
        datasetMachineName: dataset.filterMachineName,
      };
      dispatch(setOpenedThread(-1, scope));
    } else {
      history.push(
        generatePath(routes.STATEMENT_COMMENT, {
          id: currentStatement.id,
          stid: cellStatementThread.id,
        }),
      );
    }
  }, [
    dispatch,
    currentStatement,
    cellStatementThread,
    column,
    row,
    customObjectDefinition,
    history,
    ruleId,
    dataset.filterMachineName,
  ]);

  // ================ OVERWRITES ================
  const overwrite = useMemo(
    () =>
      row.overwrites
        ? row.overwrites.find(
            (ov) => ov.field === column.name || ov.overwriteType === OverwriteTypesEnum.FILTER_ROW_ADD,
          )
        : undefined,
    [row, column],
  );

  const clearOverwriteProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event): Promise<void> => {
      // Avoid the cell to go into edit mode while the calculation is loading.
      eventStopPropagation(event);

      await handleClearOverwrite(overwrite);
    },
    [overwrite, handleClearOverwrite],
  );

  const details: FilterOverwriteRemoveCreationRequestDetails = {
    rule: ruleDefinition?.name || 'no name',
    filterId:
      dataset.type === DatasetType.filter
        ? currentStatement?.results?.definitions.filters[(dataset as FilterDataset).filterMachineName]?.id
        : 'no-id',
    definitionName: customObjectDefinition?.name,
    definitionId: customObjectDefinition?.id,
    rowNameOrId: row.name ? row.name : row.externalId,
    rowExternalId: row.externalId,
    rowId: tableRow.rowId,
    isAdditionalRow: row.isAdditionalRow,
  };

  // =============== PAYMENTS ===============

  const hnrRuleDefinitionIdForCurrentCell = useMemo(
    () =>
      currentStatement?.results?.definitions.plan.rules?.find(
        (r) => r.type === RuleType.HOLD_AND_RELEASE && r.commissionVariableId === variableDefinition?.id,
      )?.id,
    [currentStatement, variableDefinition],
  );

  const paymentForThisCell = useSelector<RootState, Payment | undefined>((state) =>
    selectStatementPaymentForExternalIdAndRuleId(state, {
      ruleId: hnrRuleDefinitionIdForCurrentCell,
      externalId: row?.externalId,
    }),
  );

  const paymentBtnStatus = useMemo(() => {
    // Show payments only IF user has right to see payments, the rule is Hold & Release and
    // the current column is the commission variable of ONE of HnR rules
    if (!paymentForThisCell || !hnrRuleDefinitionIdForCurrentCell) {
      return PaymentButtonStatus.none;
    }

    if (
      !paymentForThisCell.paymentPeriodId ||
      !paymentForThisCell.paymentPeriod ||
      paymentForThisCell.paymentPeriod.startDate > currentStatement.period.startDate
    ) {
      return PaymentButtonStatus.unpaid;
    }

    return PaymentButtonStatus.paid;
  }, [paymentForThisCell, currentStatement, hnrRuleDefinitionIdForCurrentCell]);

  // ================ RENDER ================

  // Grab the cell value into the row object.
  const rawValue = row.content[column.name];
  const format = cellFormats[column.name];
  const formattedValue = RowsTableUtils.getFormattedCellValue(rawValue, format, !!overwrite);
  const isNumber = format && [FormatsEnum.number, FormatsEnum.percent].includes(format) && typeof rawValue === 'number';

  // Only compute the truncated value if it's a small number
  const shouldTruncateValue = isNumber && !!rawValue && rawValue <= 0.01;
  const truncatedValue = shouldTruncateValue
    ? RowsTableUtils.getFormattedCellValue(rawValue, format, !!overwrite, TRUNCATED_CONSTANT)
    : '';

  const overwriteSourcevalue = overwrite?.sourceValue?.[column.name];
  const overwriteValue = overwrite?.overwriteValue?.[column.name];

  const formattedOverwriteSourceValue = overwriteSourcevalue
    ? RowsTableUtils.getFormattedCellValue(overwriteSourcevalue, format, true)
    : undefined;

  const shouldFormatOverwriteValue = overwriteValue && overwriteValue.symbol !== rawValue?.symbol;
  const formattedOverwriteValue = shouldFormatOverwriteValue
    ? RowsTableUtils.getFormattedCellValue(overwriteValue, format, true)
    : undefined;

  const serializedCell = useMemo(
    () => `${serializeRow?.(row) || tableRow.rowId}.values[${column.name}]`,
    [serializeRow, column, row, tableRow],
  );

  const openDealTracing = useCallback(() => {
    // Open the tracing by setting both the data and the external id
    setCurrentTracingData({ datasetRow: row, dataset, fields });
    setTracingExternalId(row?.externalId);
  }, [setCurrentTracingData, row, dataset, fields, setTracingExternalId]);

  useEffect(() => {
    // Open deal tracing on this deal if externalId and ruleId matches
    if (ruleId === ruleIdFromUrl && row?.externalId === externalIdFromUrl && action === 'tracing') {
      // on load, if we have everything to open deal tracing in the url, open it
      openDealTracing();
    }
  }, [openDealTracing, row, externalIdFromUrl, ruleIdFromUrl, action, ruleId]);

  const handleOpenOverwriteModalProxy = useCallback(() => {
    const property = customObjectDefinition.properties[column.name];

    handleOpenOverwriteModal({
      format: property?.format || format,
      ref: property?.ref,
      rule: ruleDefinition?.name,
      oldValue:
        (property?.format === FormatsEnum.currency || format === FormatsEnum.currency) && !isNil(rawValue)
          ? rawValue.value
          : rawValue,
      field: column.name,
      fieldName: column.title || fields.find((f) => f.name === column.name).label,
      opportunityObject: customObjectDefinition?.name,
      rowNameOrId: row.content.name || row.content.id || row.externalId,
      rowId: tableRow.rowId,
      isProperty: !!property,
    });
  }, [
    customObjectDefinition,
    handleOpenOverwriteModal,
    format,
    ruleDefinition?.name,
    rawValue,
    column,
    fields,
    row.content.name,
    row.content.id,
    row.externalId,
    tableRow.rowId,
  ]);

  const canDeleteRecord = useMemo(
    () =>
      dataset.type === DatasetType.filter &&
      !isForecastedView &&
      (isEmpty(currentStatement?.workflowSteps) ||
        currentStatement.workflowSteps[currentStatement.workflowSteps.length - 1].to === 0) &&
      ability.can(ActionsEnum.overwrite, SubjectsEnum.Statement),
    [ability, currentStatement?.workflowSteps, dataset.type, isForecastedView],
  );

  const propsToPassToCell = {
    name: serializedCell,
    ...propsCell,
    style: { padding: '10px' },
  };
  let content;

  // Special behavior for the id column
  if (column.name === 'id') {
    content = <React.Fragment>{propsCell.value}</React.Fragment>;
  } else if (column.name === 'userId') {
    // Format and display a user and its avatar.
    const userId = row?.content?.userId as string;
    const foundUser = usersMap[userId];

    propsToPassToCell.onFocus = eventStopPropagation;
    propsToPassToCell.onClick = eventStopPropagation;

    content = (
      <Box
        alignItems="center"
        display="flex"
      >
        {foundUser ? (
          <Box marginRight={2}>
            <Avatar user={foundUser} />
          </Box>
        ) : null}
        {propsCell.value}
      </Box>
    );
  } else if (column.name === 'statementId') {
    // Make a clickable link to the statement.
    propsToPassToCell.onFocus = eventStopPropagation;
    propsToPassToCell.onClick = eventStopPropagation;

    content = (
      <ReactRouterLink
        className={classes.link}
        target="_blank"
        to={generatePath(routes.STATEMENT, { id: propsCell.value })}
        onClick={localStopPropagation}
        onFocus={localStopPropagation}
      >
        <FormattedMessage {...rowsTableCellMessages.GO_TO_STATEMENT} />
      </ReactRouterLink>
    );
  } else if (column.name === 'periodId') {
    // Properly format periodId.
    propsToPassToCell.onFocus = eventStopPropagation;
    propsToPassToCell.onClick = eventStopPropagation;

    content = periodsMap[row.content?.periodId as string]?.name || propsCell.value;
  } else if (column.name === 'actions') {
    return (
      <Table.Cell
        {...propsToPassToCell}
        style={{ ...propsToPassToCell.style, padding: '10px' }}
        css={css`
          right: 0;
          position: absolute;
          display: inline-block;
          width: auto;
        `}
      >
        {setCurrentTracingData && ability.can(ActionsEnum.tracing, SubjectsEnum.Statement) ? (
          <RowsTableCellActions
            clearOverwrite={clearOverwriteProxy}
            connector={customObjectDefinition?.type}
            details={details}
            openDealTracing={openDealTracing}
            openFilterOverwriteRemoveModal={canDeleteRecord ? handleOpenFilterOverwriteRemoveModal : null}
            url={row?.url}
          />
        ) : null}
      </Table.Cell>
    );
  } else {
    propsToPassToCell.className = `${classes.overwriteCell} ${propsCell.className || ''}`;
    const propertyRef = customObjectDefinition?.properties?.[column.name]?.ref;

    content = (
      <Box
        position="relative"
        className={
          paymentBtnStatus && paymentBtnStatus !== PaymentButtonStatus.none ? classes.paymentAmountCell : undefined
        }
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <RowsTableCellContent
          cellStatementThread={cellStatementThread}
          clearOverwriteProxy={clearOverwriteProxy}
          format={format}
          formattedOverwriteSourceValue={formattedOverwriteSourceValue}
          formattedOverwriteValue={formattedOverwriteValue}
          formattedValue={formattedValue}
          isReadOnly={hideEditOverwrite || ability.cannot(ActionsEnum.overwrite, SubjectsEnum.Statement)}
          openStatementThread={openStatementThread}
          overwrite={overwrite}
          propertyRef={propertyRef}
          rawValue={rawValue}
          truncatedValue={truncatedValue}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {!hideEditOverwrite && !isColumnReadOnly && (
            <Can
              a={SubjectsEnum.Statement}
              I={ActionsEnum.overwrite}
            >
              <IconButton
                className={clsx(classes.editIcon)}
                icon={<IconPencil />}
                aria-label={formatMessage(rowsTableCellMessages.OVERWRITE_ON_ARIA_LABEL, {
                  rowExternalId: row.externalId,
                  column: column.name,
                })}
                label={formatMessage(rowsTableCellMessages.OVERWRITE_ON, {
                  rowExternalId: row.externalId,
                  column: column.name,
                })}
                onClick={handleOpenOverwriteModalProxy}
              />
            </Can>
          )}

          {!hideComments && customObjectDefinition?.type !== CustomObjectDefinitionType.VIRTUAL && (
            <Can
              a={SubjectsEnum.Statement}
              I={ActionsEnum.view_comments}
            >
              <IconButton
                className={clsx(!cellStatementThread?.id && classes.commentButtonDisappear)}
                icon={
                  cellStatementThread?.thread.isReviewed ? (
                    <IconMessageCheck className={clsx(cellStatementThread?.id && classes.commentIconActive)} />
                  ) : (
                    <IconMessage className={clsx(cellStatementThread?.id && classes.commentIconActive)} />
                  )
                }
                label={formatMessage(rowsTableCellMessages.COMMENT_ON, {
                  rowExternalId: row.externalId,
                  column: column.name,
                  threadStatus: cellStatementThread?.id
                    ? ` (thread ${cellStatementThread?.thread.isReviewed ? 'reviewed' : 'ongoing'})`
                    : '',
                })}
                onFocus={eventStopPropagation}
                onClick={(e) => {
                  // no useCallback here, we need to stop propagation of a different event at each trigger
                  eventStopPropagation(e);
                  openStatementThread();
                }}
              />
            </Can>
          )}

          {paymentForThisCell && paymentBtnStatus !== PaymentButtonStatus.none ? (
            <PaymentTooltip
              companyCustomization={companyCustomization}
              formattedValue={formattedValue}
              payment={paymentForThisCell}
              title={variableDefinition.name}
            >
              <Box>
                <IconButton
                  icon={
                    paymentBtnStatus === PaymentButtonStatus.paid ? (
                      <IconCreditCard className={classes.paymentIconActive} />
                    ) : (
                      <IconCreditCardClockOutline className={iconClasses.customIcon} />
                    )
                  }
                  label={formatMessage(rowsTableCellMessages.SEE_PAYMENTS_ON_ROW, {
                    rowExternalId: row.externalId,
                    column: column.name,
                  })}
                />
              </Box>
            </PaymentTooltip>
          ) : null}
        </div>
      </Box>
    );
  }

  return <Table.Cell {...propsToPassToCell}>{content}</Table.Cell>;
});

export default RowsTableCell;
