import { useTheme } from '@emotion/react';
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo, type ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { type Schema } from 'yup';

import { Portal } from '@amal-ia/ext/react/components';
import { useForwardedRef, useUniqueId } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { useQuickEdit } from '../../../../forms/quick-edit/useQuickEdit';
import { IconAction } from '../../../../general/icon-action/IconAction';
import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';
import { Tooltip } from '../../../../overlays/tooltip/Tooltip';
import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';
import { type RowData } from '../../Table.types';

import * as styles from './CellTextField.styles';
import { cellTextFieldTestIds } from './CellTextField.testIds';

const CELL_TEXT_FIELD_PORTAL_ID = 'cell-text-field-portal';

export type CellTextFieldProps<TRowData extends RowData = RowData> = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'input'>, 'form' | 'onBlur'>,
    {
      /** Content must be a string. */
      value?: string;
      /** Current row. */
      row: TRowData;
      /** Callback when clicking confirm. */
      onChange?: (value: string, row: TRowData) => void;
      /** Is in edit mode. */
      isEditing?: boolean;
      /** Callback when editing mode changes. */
      onChangeIsEditing?: (isEditing: boolean) => void;
      /** Validation schema. Must be synchronous. */
      schema?: Schema;
      /** Override schema error if needed. */
      error?: ReactNode;
    },
  ]
>;

const CellTextFieldForwardRef = forwardRef(function CellTextField<TRowData extends RowData = RowData>(
  {
    row,
    value = '',
    onChange = undefined,
    isEditing: controlledIsEditing = undefined,
    onChangeIsEditing: controlledOnChangeIsEditing = undefined,
    schema = undefined,
    error: propsError = undefined,
    disabled = false,
    className = undefined,
    ...props
  }: CellTextFieldProps<TRowData>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const inputRef = useForwardedRef<HTMLInputElement>(ref);

  const handleChange = useCallback((value: string) => onChange?.(value, row), [onChange, row]);

  const {
    internalValue,
    validationError,
    isInEditMode,
    setInternalValue,
    handleSubmit,
    handleStartEditing,
    onChangeIsEditing,
    handleConfirm,
  } = useQuickEdit({
    value,
    onChange: handleChange,
    isEditing: controlledIsEditing,
    onChangeIsEditing: controlledOnChangeIsEditing,
    schema,
    disabled,
    inputRef,
  });

  const error = propsError || validationError;

  const formId = useUniqueId({ prefix: 'form#' });

  // We want to use QuickEdit + Formik.
  // This would render two forms, which isn't allowed.
  // We are going to render QuickEdit form into a portal
  // Here we generate a portal container in the root body if it doesn't exist yet.
  if (!document.getElementById(CELL_TEXT_FIELD_PORTAL_ID)) {
    document.body.appendChild(document.createElement('div')).id = CELL_TEXT_FIELD_PORTAL_ID;
  }

  return (
    <div className={className}>
      <Portal element={document.getElementById(CELL_TEXT_FIELD_PORTAL_ID)}>
        <form
          id={formId}
          onSubmit={handleSubmit}
        />
      </Portal>

      {/* Display as label when in edit mode so we can click anywhere to focus the input. */}
      <TableDataCellContent
        as={isInEditMode ? 'label' : 'div'}
        className={clsx({ [styles.HAS_ERROR_CLASSNAME]: !!error, [styles.IS_DISABLED_CLASSNAME]: !!disabled })}
        css={styles.cellTextField}
        tabIndex={disabled ? undefined : 0}
        onFocus={disabled ? undefined : handleStartEditing}
      >
        {isInEditMode ? (
          <input
            {...props}
            ref={inputRef}
            aria-invalid={props['aria-invalid'] || !!error}
            css={styles.input}
            data-form-type="other"
            form={formId}
            value={internalValue}
            onBlur={validationError ? () => onChangeIsEditing(false) : handleConfirm} // Change value only if no error.
            onChange={(event) => setInternalValue(event.target.value)}
          />
        ) : (
          <TextOverflow css={[styles.readonly, !value && styles.disabledPlaceholder]}>
            {internalValue || props.placeholder}
          </TextOverflow>
        )}

        {!disabled && (
          <div css={styles.indicatorsContainer}>
            {/* Show the error even when not editing. */}
            {!!error && (
              <Tooltip content={error}>
                <IconExclamationCircle
                  color={theme.ds.colors.danger[500]}
                  data-testid={cellTextFieldTestIds.errorAnchor}
                  size={18}
                />
              </Tooltip>
            )}

            {!!isInEditMode && (
              <IconAction
                disabled={!!validationError}
                icon={<IconCheck />}
                label={formatMessage({ defaultMessage: 'Confirm' })}
                size={IconAction.Size.SMALL}
                variant={IconAction.Variant.SUCCESS}
                onClick={handleConfirm}
              />
            )}
          </div>
        )}
      </TableDataCellContent>
    </div>
  );
});

export const CellTextField = memo(CellTextFieldForwardRef) as <TRowData extends RowData = RowData>(
  props: CellTextFieldProps<TRowData> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof CellTextFieldForwardRef>;
