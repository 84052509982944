import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@amal-ia/frontend/design-system/components';
import {
  type WorkflowCondition,
  WorkflowConditionType,
  type WorkflowStep,
  formatUserFullName,
  deleteAt,
} from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { WorkflowStepConfiguration } from './WorkflowStepConfiguration/WorkflowStepConfiguration';

interface WorkflowStepsConfigurationProps {
  value: WorkflowStep[];
  onChange: (steps: WorkflowStep[]) => void;
  users: UserContract[];
  isReadOnly?: boolean;
}

export const WorkflowStepsConfiguration = memo(function WorkflowStepsConfiguration({
  users,
  value: steps,
  onChange,
  isReadOnly,
}: WorkflowStepsConfigurationProps) {
  const addStep = useCallback(() => {
    onChange(
      steps.concat({
        // The step name will be populated while adding conditions.
        stepName: '',
        conditions: [],
      }),
    );
  }, [steps, onChange]);

  const onClickDeleteStep = useCallback(
    (stepIndex: number) => {
      onChange(deleteAt(steps, stepIndex));
    },
    [steps, onChange],
  );

  const generateStepName = useCallback(
    (conditions: WorkflowCondition[]) =>
      conditions
        .map((c) => {
          switch (c.conditionType) {
            case WorkflowConditionType.ROLE:
              return c.role && `${WorkflowConditionType.ROLE}:${c.role}`;
            case WorkflowConditionType.OWNER:
              return WorkflowConditionType.OWNER;
            case WorkflowConditionType.USER: {
              if (!c.userId) {
                return null;
              }
              const user = users.find((u) => u.id === c.userId);
              if (!user) {
                return null;
              }
              return `${WorkflowConditionType.USER}:${formatUserFullName(user)}`;
            }
            default:
              return null;
          }
        })
        .join(','),
    [users],
  );

  const onChangeStepConfig = useCallback(
    (stepIndex: number, stepConfig) => {
      // Make a shallow copy of the current value.
      const newValue = [...steps];
      // Replace element in the shallow copy with the new step.
      newValue[stepIndex] = {
        conditions: stepConfig.conditions,
        stepName: generateStepName(stepConfig.conditions),
      };
      // Apply changes on the form.
      onChange(newValue);
    },
    [onChange, steps, generateStepName],
  );

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        marginBottom={4}
      >
        {steps.map((step, stepIndex) => (
          <div
            // Index is used to retrieve the step.
            // eslint-disable-next-line react/no-array-index-key
            key={stepIndex}
          >
            <WorkflowStepConfiguration
              isReadOnly={isReadOnly}
              step={step}
              stepIndex={stepIndex}
              users={users}
              onChangeStepConfig={onChangeStepConfig}
              onClickDeleteStep={onClickDeleteStep}
            />
          </div>
        ))}
      </Box>

      <Box textAlign="center">
        <Button
          disabled={isReadOnly}
          icon={<IconPlus />}
          variant={Button.Variant.SECONDARY}
          onClick={addStep}
        >
          <FormattedMessage defaultMessage="Add step" />
        </Button>
      </Box>
    </Fragment>
  );
});
