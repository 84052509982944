import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type FiltersMap } from '@amal-ia/compensation-definition/shared/types';
import { type ReduxAction } from '@amal-ia/lib-types';

import { FILTERS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action) => {
    switch (action.type) {
      case FILTERS_ACTIONS.START:
        return state + 1;
      case FILTERS_ACTIONS.ERROR:
      case FILTERS_ACTIONS.SET_FILTERS:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state: FiltersMap = {}, action: ReduxAction) => {
    switch (action.type) {
      case FILTERS_ACTIONS.SET_FILTERS:
        return keyBy(action.payload.filters, 'id');
      default:
        return state;
    }
  },
});
