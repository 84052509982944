import { type ReactNode, memo, Fragment } from 'react';

import { type CardWithTabsValue } from '../CardWithTabs.types';

export type CardWithTabsTabProps = {
  value: CardWithTabsValue;
  title: ReactNode;
  children: ReactNode;
};

export const CardWithTabsTab = memo(function CardWithTabsTab({
  value: _value,
  title: _title,
  children: _children,
}: CardWithTabsTabProps) {
  return <Fragment>{null}</Fragment>;
});
