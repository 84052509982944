import { keyBy, mapValues, pickBy } from 'lodash';

import { http, qsStringify } from '@amal-ia/frontend/kernel/http';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';
import {
  type CustomObject,
  type CustomObjectContentPropertyType,
  type CustomObjectListDetails,
  type FiltersType,
  type Overwrite,
  type PaginatedQuery,
  type PatchCustomObjectRequest,
} from '@amal-ia/lib-types';

const apiEndpoint = '/objects';

export class CustomObjectsApiClient {
  public static async quickSearch(definition: string, searchText: string): Promise<CustomObject[]> {
    return (
      await http.get<CustomObject[]>(`${apiEndpoint}/${encodeURIComponent(definition)}/quick-search`, {
        params: {
          q: searchText,
        },
      })
    ).data;
  }

  /**
   * List all objects values matching a definition.
   * @param definition
   * @param pagination
   * @param filters
   * @param overwrite
   * @param externalIds
   */
  public static async getObjects(
    definition: string,
    pagination: PaginatedQuery,
    filters?: FiltersType[] | null,
    overwrite = false,
    externalIds?: string,
  ): Promise<CustomObjectListDetails> {
    const params = {
      overwrite,
      ...(externalIds && { externalIds }),
      ...(filters?.length && { filter: filters.map((filter) => JSON.stringify(filter)) }),
    };

    const url = `${apiEndpoint}/${encodeURIComponent(definition)}?${qsStringify(params)}`;

    const { items: responseItems, totalItems } = (
      await http.get<{
        items: CustomObject[];
        totalItems: number;
      }>(url, {
        params: {
          limit: pagination.limit || 10,
          page: pagination.page || 0,
          q: pagination.search?.trim(),
          sort: pagination.sort,
          desc: pagination.desc,
        },
      })
    ).data;

    if (!responseItems) {
      return {
        totalItems: 0,
        items: [],
        itemsWO: [],
        itemsWC: [],
      };
    }
    return {
      totalItems,

      // All items
      items: responseItems.map((item, index) => ({
        index,
        internalId: item.id,
        externalId: item.externalId,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        content: item.content,
        overwrites: item.overwrites,
        // Add all properties of items
        ...mapValues(item.content, (value) =>
          (value as CurrencyValue)?.symbol ? (value as CurrencyValue).value : value,
        ),
      })),

      // Items with overwrites
      itemsWO: responseItems.map((item) => keyBy(item.overwrites, 'field')),

      // Items with currencies
      itemsWC: responseItems.map(
        (item) =>
          pickBy<CustomObjectContentPropertyType>(item.content, (value) => (value as CurrencyValue)?.symbol) as Record<
            string,
            CurrencyValue
          >,
      ),
    };
  }

  public static async purgeObjectsByDefinition(definitionId: string) {
    return http.delete(`${apiEndpoint}/${encodeURIComponent(definitionId)}`);
  }

  public static async patchCustomObject({
    definitionMachineName,
    objectExternalId,
    patch,
  }: {
    definitionMachineName: string;
    objectExternalId: string;
    patch: PatchCustomObjectRequest;
  }): Promise<CustomObject & { createdOverwrite: Overwrite }> {
    const { data } = await http.patch<CustomObject & { createdOverwrite: Overwrite }>(
      `${apiEndpoint}/${encodeURIComponent(definitionMachineName)}/records/${encodeURIComponent(objectExternalId)}`,
      patch,
    );
    return data;
  }

  public static async clearCustomObject({
    definitionMachineName,
    objectExternalId,
    overwriteId,
  }: {
    definitionMachineName: string;
    objectExternalId: string;
    overwriteId: string;
  }) {
    const { data } = await http.post<CustomObject>(
      `${apiEndpoint}/${encodeURIComponent(definitionMachineName)}/records/${encodeURIComponent(
        objectExternalId,
      )}/clear`,
      { overwriteId },
    );
    return data;
  }
}
