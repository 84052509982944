import { createContext, type Dispatch, type SetStateAction } from 'react';

import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type CustomObjectListDetails } from '@amal-ia/lib-types';

export interface CustomTableContextInterface {
  setShouldRefetchTrue?: () => void;
  objectDefinition: CustomObjectDefinition;
  customObjectListDetails: CustomObjectListDetails;
  setOverwriteModalInfo: Dispatch<SetStateAction<any>>;
}

export const CustomTableContext = createContext<CustomTableContextInterface>({} as CustomTableContextInterface);
