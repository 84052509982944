/* eslint-disable no-console */
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { keyBy } from 'lodash';
import { Fragment, memo, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { type PlanRule, RuleType } from '@amal-ia/compensation-definition/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { log } from '@amal-ia/frontend/kernel/logger';
import { getRulesInCategoryV2 } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { type ComputedRule, type ComputedVariable, formatCurrencyAmount, roundNumber } from '@amal-ia/lib-types';
import { Text, TextType, useStatementDetailContext } from '@amal-ia/lib-ui';
import { RuleAccordion, RuleSummary } from '@amal-ia/lib-ui-business';
import { ComputedItemTypes } from '@amal-ia/payout-calculation/shared/types';

import RuleResult from './Rule/RuleResult';
import RuleChallenge from './Rule/ruleTypes/RuleChallenge';
import RuleDefault from './Rule/ruleTypes/RuleDefault';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  categoryTitleContainer: {
    margin: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  categoryRulesGroup: {
    '& + categoryRulesContainer': {
      marginTop: theme.spacing(5),
    },
  },
  categoryRulesContainer: {
    borderLeft: '3px solid transparent',
    padding: `0 ${theme.spacing(0.5)}`,

    '& + $categoryRulesGroup': {
      marginTop: theme.spacing(5),
    },
  },
  categoryRuleItem: {
    '& + $categoryRuleItem': {
      marginTop: theme.spacing(1),
    },
  },
  categoryRulesContainerActive: {
    borderColor: theme.palette.secondary.main,

    '& > $categoryRuleItem': {
      paddingLeft: theme.spacing(3),
    },
  },
  categoryRuleItem__orphan: {
    marginLeft: 0,
  },
}));

interface StatementCategoryProps {
  setCurrentTracingData: any;
  globalSearchValue: string;
  categoryName: string | null;
  activeRuleId: string;
}

const StatementCategory = memo(function StatementCategory({
  setCurrentTracingData,
  globalSearchValue,
  categoryName,
  activeRuleId,
}: StatementCategoryProps) {
  const classes = useStyles();
  const { ruleid: ruleId, action, externalid: externalId } = useParams<Record<string, string>>();
  const ability = useAbilityContext();

  const statement = useStatementDetailContext();

  // Fetch from the definitions the rules that are in the current category.
  const rulesInThisCategory = useMemo(() => {
    const ruleDefs = getRulesInCategoryV2(categoryName, statement.results.definitions.plan.rules || []);

    // Filter the rule that are configured to now show
    return ruleDefs.filter((r: PlanRule) => {
      if (!r.configuration?.showHideRuleVariableId) {
        return true;
      }

      const variableDef = Object.values(statement.results.definitions.variables || {}).find(
        (v) => v.id === r.configuration?.showHideRuleVariableId,
      );
      if (!variableDef) {
        console.warn(`Can't find the variable definition set up for the show rule variable on rule ${r.name}`);
        log.warn(`Can't find the variable definition set up for the show rule variable on rule ${r.name}`);
        return true;
      }

      const computedVariable = statement.results.computedObjects.find(
        (v) => (v as ComputedVariable).variableMachineName === variableDef.machineName,
      );
      if (!computedVariable) {
        console.warn(`Can't find the computed variable set up for the show rule variable on rule ${r.name}`);
        log.warn(`Can't find the computed variable set up for the show rule variable on rule ${r.name}`);
        return true;
      }

      return !!computedVariable.value;
    });
  }, [categoryName, statement.results]);

  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(
    activeRuleId === rulesInThisCategory[0]?.id,
    'ruleExpanded',
  );

  // Build a hashmap with their result.
  const ruleResultsOfThisCategoryMap = useMemo(() => {
    const machineNames = rulesInThisCategory.map((r) => r.ruleMachineName);
    const ruleResults = (statement.results.computedObjects as ComputedRule[])
      .filter((co) => co.type === ComputedItemTypes.RULE && machineNames.includes(co.ruleMachineName))
      .map((r: ComputedRule) => {
        const ruleDef = rulesInThisCategory.find((pr: PlanRule) => pr.ruleMachineName === r.ruleMachineName);
        return {
          ...r,
          definition: ruleDef,
          originalRuleValue:
            statement.isForecastedView &&
            statement.originalStatement?.results?.computedObjects?.find(
              (originalRule): originalRule is ComputedRule => originalRule.id === r.id,
            )?.value,
        };
      });
    return keyBy(ruleResults, 'ruleMachineName');
  }, [
    rulesInThisCategory,
    statement.isForecastedView,
    statement.originalStatement?.results?.computedObjects,
    statement.results?.computedObjects,
  ]);

  const categoryTotal = useMemo(() => {
    // Filter rules that are in percent
    const rules = Object.values(ruleResultsOfThisCategoryMap).filter((r: ComputedRule & { definition?: PlanRule }) => {
      // if no main kpi variable overwrite is configured, don't filter it
      if (!r.definition?.configuration?.mainKpiVariableOverwrite) {
        return true;
      }

      const mainKpiVariableOverwrite = Object.values(statement.results.definitions.variables || {}).find(
        (v) => v.id === r.definition.configuration.mainKpiVariableOverwrite,
      );

      // Remove every rule from the category total if it's not a currency
      return mainKpiVariableOverwrite?.format === FormatsEnum.currency;
    });

    return rules.map((ruleResult) => ruleResult.value || 0).reduce((total, value) => total + value, 0);
  }, [ruleResultsOfThisCategoryMap, statement.results]);

  const isShowTotalForCurrentCategory = useMemo(
    () => !rulesInThisCategory.every((r) => r.type === RuleType.NON_PAYOUT && r.configuration?.hideTotal),
    [rulesInThisCategory],
  );

  // Evaluate the total for this category.
  const formattedTotal = useMemo(() => {
    // Hide total if it's 0
    if (categoryTotal === 0) {
      return null;
    }

    return formatCurrencyAmount({
      amount: categoryTotal,
      currencyRate: statement.rate,
      currencySymbol: statement.currency,
    });
  }, [statement, categoryTotal]);

  const isCategoryAllHnrAndOnSameDataset = useMemo(
    () =>
      rulesInThisCategory.every(
        (r) =>
          r.type === RuleType.HOLD_AND_RELEASE &&
          r.filtersToDisplay?.[0] &&
          r.filtersToDisplay[0]?.id === rulesInThisCategory?.[0]?.filtersToDisplay?.[0]?.id,
      ),
    [rulesInThisCategory],
  );

  // Tracing
  const openRuleTracing = useCallback(
    (computedRule: ComputedRule, event?: any) => {
      event?.preventDefault();
      if (ability.can(ActionsEnum.tracing, SubjectsEnum.Statement)) {
        setCurrentTracingData({ rule: computedRule });
      }
    },
    [setCurrentTracingData, ability],
  );

  useEffect(() => {
    // on load, check if we have everything in the url to open rule tracing.
    // if external id is in the url, don't do anything: the rows table manages the deal tracing opening
    // (as we have to load data from the rows table for the deal tracing)
    if (ruleId && action === 'tracing' && !externalId) {
      // try to fetch the rule definition
      const ruleDefinition = (statement.results?.definitions?.plan?.rules || []).find((r) => r.id === ruleId);
      // try to fetch the computed rule
      if (ruleDefinition) {
        const computedRule: ComputedRule = (statement.results?.computedObjects || []).find(
          (co) => (co as ComputedRule).ruleMachineName === ruleDefinition.ruleMachineName,
        ) as ComputedRule;

        // If we find both matches, we have everything we need to open the rule tracing
        if (computedRule) {
          openRuleTracing(computedRule);
        }
      }
    }
  }, [ruleId, action, openRuleTracing, externalId, statement.results]);

  // If we don't have a rule in this category, don't print anything
  if (!rulesInThisCategory || rulesInThisCategory.length === 0) {
    return null;
  }

  if (isCategoryAllHnrAndOnSameDataset && categoryName) {
    const identifier = `${categoryName}-${rulesInThisCategory[0]?.ruleMachineName || 'none'}`;
    const firstPlanRule = rulesInThisCategory?.[0];
    const firstComputedRule = firstPlanRule ? ruleResultsOfThisCategoryMap[firstPlanRule.ruleMachineName] : null;

    return (
      <Grid
        container
        className={clsx(classes.categoryRulesContainer, classes.categoryRulesGroup)}
        justifyContent="center"
      >
        <Grid
          key={categoryName}
          xs={12}
        >
          <RuleAccordion
            key={categoryName}
            isExpanded={isRuleExpanded}
            label={categoryName}
            machineName={identifier}
            toggleExpanded={toggleRuleExpanded}
            mainKpi={{
              type: RuleType.HOLD_AND_RELEASE,
              value: { amount: categoryTotal, currencyRate: statement.rate, currencySymbol: statement.currency },
            }}
          >
            {(rulesInThisCategory || []).map((rule: PlanRule) => {
              const computedRule = ruleResultsOfThisCategoryMap[rule.ruleMachineName];
              const { formattedAchievedValue, formattedForecastedValue } = {
                formattedForecastedValue: formatCurrencyAmount({
                  amount: roundNumber(computedRule?.value) || 0,
                  currencyRate: statement.rate,
                  currencySymbol: statement.currency,
                }),
                formattedAchievedValue: formatCurrencyAmount({
                  amount: roundNumber(computedRule?.originalRuleValue) || 0,
                  currencyRate: statement.originalStatement?.rate,
                  currencySymbol: statement.originalStatement?.currency,
                }),
              };

              return (
                <RuleSummary
                  key={rule.id}
                  isSubSummary
                  helpLabel={rule.description}
                  label={rule.name}
                  machineName={rule.name}
                  mainKpi={{
                    value: {
                      amount: computedRule?.value || 0,
                      currencyRate: statement.rate,
                      currencySymbol: statement.currency,
                    },
                    isSubMainKpi: true,
                    formattedForecastedValue,
                    formattedOriginalValue: formattedAchievedValue,
                    isForecastedView: statement.isForecastedView,
                    isValueForecasted:
                      statement.isForecastedView && formattedAchievedValue !== formattedForecastedValue,
                  }}
                  onClick={(e) => openRuleTracing(computedRule, e)}
                  onClickToggleRuleAccordion={toggleRuleExpanded}
                />
              );
            })}

            {firstComputedRule ? (
              <Grid container>
                <RuleResult
                  computedRule={firstComputedRule}
                  ruleDefinition={firstPlanRule}
                  setCurrentTracingData={setCurrentTracingData}
                />
              </Grid>
            ) : null}
          </RuleAccordion>
        </Grid>
      </Grid>
    );
  }

  return (
    <Fragment>
      {categoryName ? (
        <Grid
          className={classes.categoryTitleContainer}
          xs={12}
        >
          <Text
            style={{ width: '100%', display: 'block' }}
            type={TextType.STATEMENT_CATEGORY}
          >
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Box component="span">{categoryName}</Box>
              {isShowTotalForCurrentCategory ? <Box component="span">{formattedTotal}</Box> : null}
            </Box>
          </Text>
        </Grid>
      ) : null}
      <Grid
        container
        className={clsx(classes.categoryRulesContainer, categoryName && classes.categoryRulesContainerActive)}
        justifyContent="center"
      >
        {(rulesInThisCategory || []).map((rule) => (
          <Grid
            key={rule.id}
            className={clsx(classes.categoryRuleItem, !categoryName && classes.categoryRuleItem__orphan)}
            xs={12}
          >
            {rule.type !== RuleType.CHALLENGE ? (
              <RuleDefault
                key={rule.id}
                activeRuleId={activeRuleId}
                categoryName={categoryName}
                computedRule={ruleResultsOfThisCategoryMap[rule.ruleMachineName]}
                globalSearchValue={globalSearchValue}
                openRuleTracing={openRuleTracing}
                rule={rule}
                setCurrentTracingData={setCurrentTracingData}
              />
            ) : (
              <RuleChallenge
                key={rule.id}
                activeRuleId={activeRuleId}
                categoryName={categoryName}
                computedRule={ruleResultsOfThisCategoryMap[rule.ruleMachineName]}
                rule={rule}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
});

export default StatementCategory;
