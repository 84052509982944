import { css, type Theme } from '@emotion/react';

import { ColorCategory } from '@amal-ia/frontend/design-system/meta';

export const container = (theme: Theme) => css`
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.soft};
  background-color: ${theme.ds.colors.gray[0]};
  width: 100%;
  height: 100%;
`;

export const tabsContainer = css`
  all: unset;
  display: flex;
`;

export const tabContainer = (isSelectedTab: boolean) => (theme: Theme) => css`
  all: unset;
  display: flex;

  min-width: 150px;
  flex: 1;

  background-color: ${theme.ds.colors.gray[50]};
  border: 1px solid ${theme.ds.colors.gray[100]};

  &:first-child {
    border-top-left-radius: ${theme.ds.borderRadiuses.squared};
  }

  &:last-child {
    border-top-right-radius: ${theme.ds.borderRadiuses.squared};
  }

  ${isSelectedTab &&
  `
    background-color: ${theme.ds.colors.gray[0]};
    border-top: 4px solid ${theme.ds.colors[ColorCategory.PRIMARY][500]};
    border-bottom: none;
  `}
`;

export const tabButton = (isSelectedTab: boolean) => css`
  flex: 1;
  height: 60px;

  padding: 20px 40px;

  ${isSelectedTab &&
  `
    padding-top: 17px;
  `}
`;

export const tabContent = (theme: Theme) => css`
  padding: 40px 40px;
  transition: ${theme.ds.transitions.default()};
`;
