import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconTrash } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconButton, Select, type SelectOption } from '@amal-ia/frontend/design-system/components';
import { type WorkflowCondition, WorkflowConditionType, formatUserFullName } from '@amal-ia/lib-types';
import { UserRoleLabels } from '@amal-ia/tenants/users/roles/components';
import { type UserContract, UserRole } from '@amal-ia/tenants/users/shared/types';

import { messages } from './WorkflowConditionConfiguration.messages';

const useStyles = makeStyles({
  conditionForm: {
    marginTop: '5px',
    display: 'grid',
    gridTemplateColumns: '50px 1fr 1fr 32px',
    justifyContent: 'center',
    alignItems: 'center',
    gridGap: '5px',
    '& > div': {
      marginTop: '0 !important',
    },
    '& > p': {
      textAlign: 'center',
    },
  },
});

interface WorkflowConditionConfigurationProps {
  condition: WorkflowCondition;
  stepIndex: number;
  conditionIndex: number;
  onChangeCondition: (conditionIndex: number, newCondition: WorkflowCondition) => void;
  onDeleteCondition: (conditionIndex: number) => void;
  users: UserContract[];
  isReadOnly?: boolean;
}

export const WorkflowConditionConfiguration = memo(function WorkflowConditionConfiguration({
  condition,
  stepIndex,
  conditionIndex,
  onChangeCondition,
  onDeleteCondition,
  users,
  isReadOnly,
}: WorkflowConditionConfigurationProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const userOptionFormatted = useMemo(
    () =>
      users.map((user) => ({
        label: formatUserFullName(user),
        value: user.id,
      })),
    [users],
  );

  const onChangeConditionType = useCallback(
    (conditionType: WorkflowConditionType) => {
      onChangeCondition(conditionIndex, {
        conditionType,
        role: undefined,
        userId: undefined,
      });
    },
    [onChangeCondition, conditionIndex],
  );

  const onChangeRole = useCallback(
    (role: UserRole) => {
      onChangeCondition(conditionIndex, {
        conditionType: WorkflowConditionType.ROLE,
        role,
      });
    },
    [onChangeCondition, conditionIndex],
  );

  const onChangeUser = useCallback(
    (userId: string) => {
      onChangeCondition(conditionIndex, {
        conditionType: WorkflowConditionType.USER,
        userId,
      });
    },
    [onChangeCondition, conditionIndex],
  );

  const onClickDeleteProxy = useCallback(() => onDeleteCondition(conditionIndex), [onDeleteCondition, conditionIndex]);

  const roleFieldOptionsWithoutEmployee: SelectOption<UserRole>[] = useMemo(
    () =>
      Object.values(UserRole)
        .map((value) => ({
          value,
          label: formatMessage(UserRoleLabels[value]),
        }))
        .filter((opt) => opt.value !== UserRole.EMPLOYEE),
    [formatMessage],
  );

  const conditionTypesOptions: SelectOption<WorkflowConditionType>[] = useMemo(
    () => [
      { value: WorkflowConditionType.OWNER, label: formatMessage({ defaultMessage: 'Statement owner' }) },
      { value: WorkflowConditionType.ROLE, label: formatMessage({ defaultMessage: 'Role' }) },
      { value: WorkflowConditionType.USER, label: formatMessage({ defaultMessage: 'Specific user' }) },
    ],
    [formatMessage],
  );

  const secondSelect = () => {
    switch (condition.conditionType) {
      case WorkflowConditionType.ROLE:
        return (
          <Select
            disabled={isReadOnly}
            id={`role-${stepIndex + 1}-${conditionIndex + 1}`}
            isClearable={false}
            options={roleFieldOptionsWithoutEmployee}
            value={condition.role || null}
            aria-label={formatMessage(messages.ROLE_DROPDOWN_LABEL, {
              stepIndex: stepIndex + 1,
              conditionIndex: conditionIndex + 1,
            })}
            onChange={onChangeRole}
          />
        );
      case WorkflowConditionType.USER:
        return (
          <Select
            disabled={isReadOnly}
            id={`user-${stepIndex + 1}-${conditionIndex + 1}`}
            isClearable={false}
            options={userOptionFormatted}
            value={condition.userId}
            aria-label={formatMessage(messages.USER_DROPDOWN_LABEL, {
              stepIndex: stepIndex + 1,
              conditionIndex: conditionIndex + 1,
            })}
            onChange={onChangeUser}
          />
        );
      default:
        // Solo div to avoid breaking the grid layout.
        return <div />;
    }
  };

  return (
    <Box
      className={classes.conditionForm}
      data-testid={`step#${stepIndex + 1}-condition#${conditionIndex + 1}`}
    >
      {conditionIndex === 0 ? (
        <div />
      ) : (
        <p>
          <FormattedMessage {...messages.OR} />
        </p>
      )}
      <Select
        disabled={isReadOnly}
        id={`conditionType-${stepIndex + 1}-${conditionIndex + 1}`}
        isClearable={false}
        options={conditionTypesOptions}
        value={condition.conditionType}
        aria-label={formatMessage(messages.CONDITION_TYPE_DROPDOWN, {
          stepIndex: stepIndex + 1,
          conditionIndex: conditionIndex + 1,
        })}
        onChange={onChangeConditionType}
      />
      {secondSelect()}
      <IconButton
        disabled={isReadOnly}
        icon={<IconTrash />}
        variant={IconButton.Variant.DANGER}
        label={formatMessage(messages.DELETE_CONDITION, {
          stepIndex: stepIndex + 1,
          conditionIndex: conditionIndex + 1,
        })}
        onClick={onClickDeleteProxy}
      />
    </Box>
  );
});
