import { useQuery } from '@tanstack/react-query';

import { getOvertimeWidget } from '@amal-ia/frontend/web-data-layers';
import { RelativePeriodKeyword } from '@amal-ia/lib-types';

enum OVER_TIME_QUERY_KEYS {
  OVERTIME_DATA = 'OVERTIME_DATA',
}

export const useUserOvertimeData = (
  year: string,
  selectedUserId: string,
  selectedPlanId: string,
  payoutMode:
    | RelativePeriodKeyword.LAST_12_MONTHS
    | RelativePeriodKeyword.YEAR_TO_DATE = RelativePeriodKeyword.YEAR_TO_DATE,
) =>
  useQuery({
    enabled: !!year && !!selectedUserId && !!selectedPlanId,
    queryKey: [OVER_TIME_QUERY_KEYS.OVERTIME_DATA, payoutMode, year, selectedUserId, selectedPlanId],
    queryFn: async () => {
      const overtimeYear = (payoutMode === RelativePeriodKeyword.YEAR_TO_DATE && year) || undefined;
      return getOvertimeWidget(payoutMode, overtimeYear, [selectedUserId], [selectedPlanId]);
    },
  });

export const useOverTimeData = (
  payoutMode:
    | RelativePeriodKeyword.LAST_12_MONTHS
    | RelativePeriodKeyword.YEAR_TO_DATE = RelativePeriodKeyword.YEAR_TO_DATE,
  year?: string,
  selectedUserIds?: string[],
  selectedPlanIds?: string[],
  selectedTeamIds?: string[],
) =>
  useQuery({
    queryKey: [OVER_TIME_QUERY_KEYS.OVERTIME_DATA, payoutMode, year, selectedUserIds, selectedPlanIds, selectedTeamIds],
    queryFn: async () => {
      const overtimeYear = (payoutMode === RelativePeriodKeyword.YEAR_TO_DATE && year) || undefined;
      return getOvertimeWidget(payoutMode, overtimeYear, selectedUserIds, selectedPlanIds, selectedTeamIds);
    },
  });
