import { type ForwardedRef, forwardRef, memo } from 'react';
import { Link as RouterLink, type LinkProps as RouterLinkProps } from 'react-router-dom';

export type LinkProps = Omit<RouterLinkProps, 'to'> & { to?: RouterLinkProps['to'] };

const LinkForwardRef = forwardRef(function Link(
  { to, children, ...props }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return to ? (
    <RouterLink
      {...props}
      ref={ref}
      to={to}
    >
      {children}
    </RouterLink>
  ) : (
    <a
      {...props}
      ref={ref}
    >
      {children}
    </a>
  );
});

export const Link = memo(LinkForwardRef);
