import { type ElementType, memo } from 'react';

import { TextOverflow } from '../../../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../../../general/typography/Typography';
import { type CellSelectOption, type CellSelectSingleValueLabelProps } from '../../CellSelect.types';

import * as styles from './CellSelectSingleValue.styles';

export type CellSelectSingleValueProps<TOption extends CellSelectOption = CellSelectOption> = {
  /** Selected option. */
  option: TOption;
  /** Is the input disabled. */
  disabled?: boolean;
  /** Component to render the label of the selected option inside the control on a single select component. */
  LabelComponent?: ElementType<CellSelectSingleValueLabelProps<TOption>>;
};

const CellSelectSingleValueBase = function CellSelectSingleValue<TOption extends CellSelectOption = CellSelectOption>({
  option,
  disabled,
  LabelComponent,
}: CellSelectSingleValueProps<TOption>) {
  return (
    <Typography
      as={TextOverflow}
      css={styles.cellSelectSingleValue}
      variant={Typography.Variant.BODY_BASE_REGULAR}
    >
      {LabelComponent ? (
        <LabelComponent
          disabled={disabled}
          option={option}
        />
      ) : (
        option.valueLabel || option.label
      )}
    </Typography>
  );
};

export const CellSelectSingleValue = memo(CellSelectSingleValueBase) as typeof CellSelectSingleValueBase;
