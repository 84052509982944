export interface MessageContent {
  type: MessageTypeEnum;
  content: string;
  userId?: string;
}

export enum MessageTypeEnum {
  text = 'text',
  mention = 'mention',
}
