import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { LightTooltip, Text, TextType } from '@amal-ia/lib-ui';

interface ParentTeamGroupProps {
  teamsName: string[];
}

const useStyle = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 1.25),
    margin: theme.spacing(0, 1.25),
  },
  divider: {
    padding: theme.spacing(0, 1.25),
    marginBottom: theme.spacing(0.625),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(0, 1.25),
    marginBottom: theme.spacing(0.625),
  },
  popper: {
    border: 0,
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  tooltip: {
    padding: theme.spacing(0.5, 1.25),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '500px !important',
  },
}));

export const ParentTeamGroup = memo(function ParentTeamGroup({ teamsName }: ParentTeamGroupProps) {
  const classes = useStyle();

  const firstParentTeam = teamsName[0];
  const lastParentTeam = teamsName[teamsName.length - 1];

  const hiddenParentTeams = useMemo(
    () => teamsName.filter((t) => ![firstParentTeam, lastParentTeam].includes(t)),
    [firstParentTeam, lastParentTeam, teamsName],
  );

  return (
    <Box className={classes.container}>
      <Text
        className={classes.title}
        type={TextType.INPUT_NAME}
      >
        {firstParentTeam}
      </Text>
      {teamsName.length > 2 && (
        <Box className={classes.tooltipContainer}>
          <Text
            className={classes.divider}
            type={TextType.SUBTITLE}
          >
            {' '}
            &gt;{' '}
          </Text>
          <LightTooltip
            className={classes.popper}
            placement="top"
            title={
              <div className={classes.tooltip}>
                {hiddenParentTeams.map((teamName, i) => (
                  <Box
                    key={teamName}
                    className={classes.tooltipContainer}
                  >
                    {i !== 0 && (
                      <Text
                        className={classes.divider}
                        type={TextType.SUBTITLE}
                      >
                        {' '}
                        &gt;{' '}
                      </Text>
                    )}
                    <Text
                      className={classes.title}
                      type={TextType.INPUT_NAME}
                    >
                      {teamName}
                    </Text>
                  </Box>
                ))}
              </div>
            }
          >
            <Text
              className={classes.title}
              type={TextType.INPUT_NAME}
            >
              {' '}
              ...{' '}
            </Text>
          </LightTooltip>
        </Box>
      )}
      {teamsName.length > 1 && (
        <Fragment>
          <Text
            className={classes.divider}
            type={TextType.SUBTITLE}
          >
            {' '}
            &gt;{' '}
          </Text>
          <Text
            className={classes.title}
            type={TextType.INPUT_NAME}
          >
            {lastParentTeam}
          </Text>
        </Fragment>
      )}
    </Box>
  );
});
