import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('STARTS_WITH', AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 2;

func.description = 'Return true when the given Source String starts with the given Search String';

func.params = [
  { name: 'sourceString', description: 'String to look into: array, variables, fields, properties, string' },
  { name: 'searchString', description: 'String to look for: array, variables, fields, properties, string' },
];

func.examples = [
  {
    desc: 'Returns true if Opportunity type startsWith Renew.',
    formula: 'STARTS_WITH(opportunity.type, "Renew")' as AmaliaFormula,
  },
  {
    desc: 'Returns true.',
    formula: 'STARTS_WITH("Jean Dupont", "J")' as AmaliaFormula,
    result: true,
  },
  {
    desc: 'Returns false since the check is case sensitive.',
    formula: 'STARTS_WITH("Opportunity - Renewal XYZ", "opportunity")' as AmaliaFormula,
    result: false,
  },
];

/**
 * Check that text contains an other text.
 * @param sourceString
 * @param searchString
 */
func.exec = (sourceString: { toString: () => string }, searchString: string): boolean =>
  sourceString.toString().startsWith(searchString);

export default func;
