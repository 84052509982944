import { css, type Theme } from '@emotion/react';
import { IconGhost } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Button, ButtonSize, ButtonVariant, Typography } from '@amal-ia/frontend/design-system/components';
import { AUTHZ_CONTEXT_QUERY_KEYS, useAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import { UserApiClient } from '@amal-ia/tenants/users/shared/api-client';

const impersonateContainer = (theme: Theme) => css`
  border-top: 1px solid ${theme.ds.colors.gray[100]};
  background-color: ${theme.ds.colors.gray[50]};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 12px 14px;
`;
const purpleGhost = (theme: Theme) => css`
  color: ${theme.ds.colors.secondary[300]};
`;

export const LeftBarImpersonateIndicator = memo(function LeftBarImpersonateIndicator() {
  const { authenticatedContext } = useAuthenticatedContext();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onResetImpersonation = useCallback(async () => {
    await UserApiClient.resetImpersonate();
    await queryClient.invalidateQueries({ queryKey: [AUTHZ_CONTEXT_QUERY_KEYS.AUTHENTICATED_CONTEXT] });
    // force redirect to the root route to refresh UI
    history.push(generatePath(routes.ROOT));
  }, [queryClient, history]);

  // If current user is not impersonating, we don't display anything
  if (!authenticatedContext.impersonation) {
    return null;
  }

  return (
    <div css={impersonateContainer}>
      <IconGhost
        css={purpleGhost}
        size={18}
      />
      <Typography variant={Typography.Variant.BODY_XSMALL_REGULAR}>
        <FormattedMessage defaultMessage="While impersonating, you cannot perform any action." />
      </Typography>
      <Button
        size={ButtonSize.SMALL}
        variant={ButtonVariant.SECONDARY}
        onClick={onResetImpersonation}
      >
        <FormattedMessage defaultMessage="End impersonation" />
      </Button>
    </div>
  );
});
