import { type DataConnectorTypes } from '@amal-ia/data-capture/connectors/types';

import { type AuthType, type ConnectorAuth } from './auth';
import { type UserConnectorSource } from './userConnector';
import { type WebhookIdInterface } from './webhookId.interface';

// FIELDS
// TODO: refactor DataConnectorFieldType to be an enum instead.
export type DataConnectorFieldType =
  | 'address'
  | 'boolean'
  | 'currency'
  | 'date'
  | 'datetime'
  | 'enum'
  | 'number'
  | 'percent'
  | 'string'
  | 'time';

export interface DataConnectorObjectField {
  enabled?: boolean;
  label: string;
  name: string;
  overrideName?: string;
  relationshipName?: string;
  type: DataConnectorFieldType;
  allowedTypes?: DataConnectorFieldType[];
  format?: string;
  isId?: boolean;
  isName?: boolean;
  unique?: boolean;
  referenceTo?: string[];
  isMandatory?: boolean;
  matchingField?: string;
}

// OBJECT
export enum DataConnectorFilterOperator {
  EQ = 'EQ',
  NEQ = 'NEQ',
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  CONTAINS = 'CONTAINS',
  HAS_PROPERTY = 'HAS_PROPERTY',
  IN = 'IN',
}

export interface DataConnectorObjectFilter {
  name?: string;
  value?: Date | string;
  operator?: DataConnectorFilterOperator;
  condition?: string;
}

export interface DataConnectorObjectSort {
  name: string;
  direction: 'ASC' | 'DESC';
}

// RECORD
export interface DataConnectorObjectRecord {
  id?: string;
  object: string;
  content: any;
  ownerId?: string;
  url?: string;
}

export type DataConnectorObjectFrequencyCron = '0 0 * * *' | 'manually';

export interface DataConnectorObject {
  externalId?: string;
  label: string;
  name: string;
  alias?: string;
  mainDateField?: string | undefined;
  maxFetchRecords?: number;
  filters?: DataConnectorObjectFilter[];
  sort?: DataConnectorObjectSort;
  fields?: DataConnectorObjectField[];
  relations?: DataConnectorObject[];
  frequencyCron?: DataConnectorObjectFrequencyCron;
  isLinkedToDirectoryOrQuotas?: boolean;
  synchronizeOnlyToExistantUser?: boolean;
  isPrunable?: boolean;
  shouldPurgeBeforeSync?: boolean;
  /* Used for powerBI: DAX Query used to import object records. */
  query?: string;

  /**
   * Enable data live synchronization for this object.
   */
  isRealTimeSynchronizationEnabled?: boolean;
  isLinkedToTeams?: boolean;
  synchronizeOnlyToExistantTeam?: boolean;
  isLinkedToTeamAssignments?: boolean;

  /**
   * Store webhook id reference configured for this object (in Salesforce that would be the outboundMessage Id and Flow Id).
   */
  webhookId?: WebhookIdInterface;

  /* Used for powerBI: Field name we use as a facet to paginate on during import. */
  paginationField?: {
    table: string;
    column: string;
  };
}

// SOURCE
export interface DataConnectorSource {
  objects?: DataConnectorObject[];
  externalUrl?: string;
}

// TYPES

// CATEGORIES
export enum DataConnectorCategories {
  USER = 'user',
  DATA = 'data',
}

// AUTH
export interface UserInfo {
  userName?: string;
  displayName?: string;
  email?: string;

  tenantId?: string;
}

// USER
export interface DataConnectorUser {
  id: string;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
}

// CONNECTORS
export enum DataConnectorStatus {
  /* Connector is active */
  ACTIVE = 'ACTIVE',
  /* Connector is synchronizing */
  SYNCING = 'SYNCING',
  /* Connector is in error (out of sync) */
  ERROR = 'ERROR',
}

export interface Connector {
  id?: string;
  auth?: ConnectorAuth;
  alias?: string;

  authType?: AuthType;

  source?: DataConnectorSource | UserConnectorSource;
  type?: DataConnectorTypes;
  category: DataConnectorCategories;
  status?: DataConnectorStatus;
  updatedAt?: Date;

  // We can store connector objet catalog when not available from api.
  // catalog entries:
  // - keys : objectNames
  // - values: object definitions
  catalog?: unknown;

  /**
   * tenant id in connector system (can be Salesforce organization id, Hubspot portal id, ...)
   */
  tenantId?: string;
}

export interface DataConnector extends Connector {
  source?: DataConnectorSource;
}

export interface CreateDataConnectorRequest {
  type: DataConnectorTypes;
  category: DataConnectorCategories;
  authType?: AuthType;
  token?: string;
}

export interface PatchDataConnectorRequest {
  type?: DataConnectorTypes;
  externalUrl?: string;
  auth?: ConnectorAuth | null;
  status?: DataConnectorStatus;
  alias?: string;
}
