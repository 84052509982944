import { IconCirclePlus, IconCircleX, IconPlus } from '@tabler/icons-react';
import { memo, type ReactNode } from 'react';

import { Button, IconButton, Typography } from '@amal-ia/frontend/design-system/components';

import useCustomReportEditStyles from '../../useCustomReportEditStyles';

interface DrawerSectionProps {
  label?: string;

  children: ReactNode;
  isEmpty?: boolean;

  addButtonSlot?: ReactNode;
  addButtonLabel?: string;
  onAddButtonClick?: (e?) => void;

  resetButtonSlot?: ReactNode;
  resetButtonLabel?: string;
  onResetButtonClick?: (e?) => void;
}

export const DrawerSection = memo(function DrawerSection({
  label,
  addButtonLabel,
  onAddButtonClick,
  children,
  isEmpty,
  onResetButtonClick,
  resetButtonLabel,
  addButtonSlot = onAddButtonClick &&
    (isEmpty ? (
      <Button
        data-testid={addButtonLabel}
        icon={<IconPlus />}
        variant={Button.Variant.SECONDARY_LIGHT}
        onClick={onAddButtonClick}
      >
        {addButtonLabel}
      </Button>
    ) : (
      <IconButton
        data-testid={addButtonLabel}
        icon={<IconCirclePlus />}
        label={addButtonLabel}
        onClick={onAddButtonClick}
      />
    )),
  resetButtonSlot = onResetButtonClick && !isEmpty && (
    <IconButton
      data-testid={resetButtonLabel}
      icon={<IconCircleX />}
      label={resetButtonLabel}
      onClick={onResetButtonClick}
    />
  ),
}: DrawerSectionProps) {
  const classes = useCustomReportEditStyles();

  return (
    <div className={classes.drawerSection}>
      {label ? <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>{label}</Typography> : null}
      {children}
      {addButtonSlot}
      {resetButtonSlot}
    </div>
  );
});
