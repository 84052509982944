export enum BadgeSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum BadgeHue {
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  CYAN = 'CYAN',
  GREY = 'GREY',
  MAGENTA = 'MAGENTA',
  ORANGE = 'ORANGE',
}

export enum BadgeStatus {
  DANGER = 'DANGER',
  MISSING = 'MISSING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}
