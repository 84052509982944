import { isNil } from 'lodash';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencyAmount, formatTotal, type MainKpi } from '@amal-ia/lib-types';

export const formatKpiTotal = (mainKpi: MainKpi, overwriteFormattedValue: string) => {
  let content: number | string;
  if (overwriteFormattedValue) {
    content = overwriteFormattedValue;
  } else if (mainKpi.value === null) {
    content = '-';
  } else {
    content = !isNil((mainKpi.value as CurrencyAmount).amount)
      ? formatTotal(
          (mainKpi.value as CurrencyAmount).amount,
          FormatsEnum.currency,
          (mainKpi.value as CurrencyAmount).currencySymbol,
          (mainKpi.value as CurrencyAmount).currencyRate,
        )
      : '';
  }

  return content;
};
