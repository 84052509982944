import { type Sorting } from '@devexpress/dx-react-grid';
import { useMemo, useState } from 'react';

import { formatAdjustmentLabelInPayments } from '@amal-ia/frontend/web-data-layers';
import {
  getPeriodName,
  PaymentType,
  type PeriodsMap,
  type PaymentContract,
  type PaginatedResponse,
} from '@amal-ia/lib-types';
import { type DataGridColumn, useDebouncedInput, useQueryString } from '@amal-ia/lib-ui';

export type PaymentDataGridColumns = (DataGridColumn & { realSortColumn: string })[];

export default (
  columns: PaymentDataGridColumns,
  payments: PaginatedResponse<PaymentContract>,
  periodsMap: PeriodsMap,
  initialPageSize?: number,
  hiddenColumn?: string[],
) => {
  const urlParams = useQueryString();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(initialPageSize || 10);
  const [searchValue, setSearchValue] = useState<string>(urlParams?.search || '');
  const [searchValueDebounced, setSearchValueDebounced] = useState('');
  const [sorting, setSorting] = useState<Sorting[]>([]);
  const { onChange: onDebouncedSearchInputChange } = useDebouncedInput(setSearchValue, setSearchValueDebounced, 1000);
  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>(hiddenColumn || []);

  const rows = useMemo(
    () =>
      payments.items.map((payment) => ({
        ...payment,
        achievementPeriod: getPeriodName(payment.periodId, periodsMap),
        firstName: payment.user?.firstName || '',
        lastName: payment.user?.lastName || '',
        plan: payment.plan?.name || '',
        rule: payment.type === PaymentType.ADJUSTMENT ? 'Adjustment' : payment.rule?.name || '',
        field:
          (payment.type === PaymentType.ADJUSTMENT
            ? formatAdjustmentLabelInPayments(payment.adjustment)
            : payment.variableName) || '',
        recordType: payment.dealObjectName || '',
        dealName: payment.dealName || '',
        paymentPeriodId: payment.paymentPeriodId,
      })),
    [payments, periodsMap],
  );

  const realSortColumn = useMemo(
    () => (sorting[0]?.columnName ? columns.find((c) => c.name === sorting[0].columnName)?.realSortColumn : undefined),
    [columns, sorting],
  );

  return {
    currentPage,
    pageSize,
    rows,
    realSortColumn,
    sorting,
    searchValue: searchValueDebounced,
    setCurrentPage,
    options: useMemo(
      () => ({
        pages: {
          paginable: true,
          customPagingTotalItems: payments.totalItems,
          currentPage,
          onCurrentPageChange: setCurrentPage,
          pageSize,
          onPageSizeChange: setPageSize,
        },
        sort: {
          sortable: true,
          overwriteSort: true,
          sorting,
          onSortingChange: setSorting,
          columnExtensions: [
            { columnName: 'recordType', sortingEnabled: false },
            { columnName: 'recordIdName', sortingEnabled: false },
          ],
        },
        search: {
          searchable: true,
          overwriteSearch: true,
          value: searchValue,
          onValueChange: onDebouncedSearchInputChange,
        },
        columnVisibility: {
          active: true,
          hiddenColumnNames,
          onHiddenColumnNamesChange: setHiddenColumnNames,
        },
      }),
      [
        currentPage,
        hiddenColumnNames,
        onDebouncedSearchInputChange,
        pageSize,
        payments.totalItems,
        searchValue,
        sorting,
      ],
    ),
  };
};
