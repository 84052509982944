import { type Period, type WorkflowStatementStateAction } from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/shared/types';
// eslint-disable-next-line max-classes-per-file

export enum CalculationType {
  STATEMENT = 'STATEMENT',
  FORECAST = 'FORECAST',
}
export enum CalculationStatus {
  // New calculation waiting to be launched.
  PENDING = 'PENDING',
  // Calculation has been started.
  STARTED = 'STARTED',
  // All calculation have been done.
  SUCCESS = 'SUCCESS',
  // Calculation has failed.
  ERROR = 'ERROR',
  // Some of the calculations have not been done.
  INCOMPLETE = 'INCOMPLETE',
  // User requested stop.
  STOPPING = 'STOPPING',
  // Calculation successfully stopped.
  STOPPED = 'STOPPED',
}

export interface CalculationDescriptorResult {
  status: CalculationStatus;
  statementId: string;
  error: string | null;
}

export interface CalculationDescriptorBatch {
  status: CalculationStatus;
  planId: string;
  planName: string;
  users: {
    id: string;
    firstName: string;
    lastName: string;
    pictureURL?: string;
    result?: CalculationDescriptorResult;
  }[];
}

export interface CalculationDescriptorStep {
  periodId: string;
  periodName: string;
  plansWeight: number;
  batches: CalculationDescriptorBatch[];
}

export type CalculationDescriptor = CalculationDescriptorStep[];

export const CALCULATION_ONGOING_STATUSES = [
  CalculationStatus.PENDING,
  CalculationStatus.STARTED,
  CalculationStatus.STOPPING,
];

export interface Calculation {
  id: string;

  createdAt: string;

  company?: Company;

  companyId: string;

  status?: CalculationStatus;

  total?: number;

  calculated?: number;

  error?: string;

  errorMetadata?: object;

  finishedAt?: Date;

  lastUpdatedAt?: Date;

  withRollingPeriods?: boolean;

  descriptor: CalculationDescriptor;

  uniqueUserKey?: string;

  type?: CalculationType;
}

export interface CalculationErrorMetadata {
  type: string;
  message: string;
  context: string | null;
  plan: string | null;
}

export abstract class ComputeEngineError extends Error {
  public context?: string;

  public plan?: string;

  public constructor(message: string, context?: string, plan?: string) {
    super(message);
    this.context = context || null;
    this.plan = plan || null;
  }

  public getMetadata(): CalculationErrorMetadata {
    return {
      type: this.constructor.name,
      message: this.message,
      context: this.context,
      plan: this.plan,
    };
  }
}

export class ConfigurationError extends ComputeEngineError {}

export class MissingQuotaError extends ConfigurationError {
  public variableName?: string;

  public variableId?: string;

  public constructor(message: string, context?: string, plan?: string, variableName?: string, variableId?: string) {
    super(message, context, plan);
    this.variableName = variableName;
    this.variableId = variableId;
  }
}

export class PaymentReleaseError extends ComputeEngineError {
  public constructor(message: string, period: Period, paymentIds: string[], statementIds: string[]) {
    super(message);
    this.paymentIds = paymentIds;
    this.statementIds = statementIds;
    this.period = period;
  }

  public statementIds: string[];

  public paymentIds: string[];

  public period: Period;
}

export const CALCULATION_BATCH_SIZE = 20;

export const CALCULATION_MAX_PARALLEL_BATCHES = 10;

export interface CalculationRequest {
  periodId: string;

  userIds?: string[];

  teamId?: string;

  planId?: string;

  action?: WorkflowStatementStateAction;

  type?: CalculationType;
}

export interface PatchCalculationRequest {
  status: CalculationStatus;
}

export interface SaCalculationRequest extends CalculationRequest {
  companyId: string;
}

export interface ScheduleCalculationQuery {
  periodId?: string;
  planId?: string;
  userIds?: string[];
  type?: CalculationType;
}

export interface ScheduleCalculationEvent {
  companyId: string;
  query: ScheduleCalculationQuery;
  options: {
    withRollingPeriods: boolean;
    uniqueUserKey?: string;
  };
  // If this new calculation has been triggered from another one.
  // For example statement + forecast calculation. Forecast calculation is triggered by the statement one
  // In that case triggeredFromCalculationId is the statement calculationId.
  triggeredFromCalculationId?: string;
}

export interface StatementDatasetCacheBurstEvent {
  companyId?: string;
}

export interface ExecutePlanTemplateBuildEvent {
  companyId: string;
  planId?: string;
}

export enum RefreshmentAndCalculationStopActions {
  STOP = 'stop',
  FORCE_STOP = 'forcestop',
}
