import { http } from '@amal-ia/frontend/kernel/http';
import { type CompanyApiKeyContract, type CreateApiKeyDTO, type DeleteApiKeyDTO } from '@amal-ia/lib-types';

const API_ENDPOINT = '/companies/api-keys';

export async function createApiKey(payload: CreateApiKeyDTO): Promise<CompanyApiKeyContract> {
  const { data } = await http.post(API_ENDPOINT, payload);
  return data;
}

export async function listApiKeys(): Promise<CompanyApiKeyContract[]> {
  const { data } = await http.get(API_ENDPOINT);
  return data;
}

export async function deleteApiKey(payload: DeleteApiKeyDTO): Promise<CompanyApiKeyContract> {
  const { data } = await http.delete(`${API_ENDPOINT}/${payload.id}`);
  return data;
}
