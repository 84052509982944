import { ClassNames } from '@emotion/react';
import { IconCheck, IconPencil } from '@tabler/icons-react';
import clsx from 'clsx';
import { Form, Formik, type FormikConfig, type FormikProps } from 'formik';
import { Fragment, cloneElement, memo, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useAsyncEffect } from 'use-async-effect';

import { useBoolState, useHover, useShallowObjectMemo, useTextOverflows } from '@amal-ia/ext/react/hooks';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { IconButton } from '../../../general/icon-button/IconButton';
import { Typography } from '../../../general/typography/Typography';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { Tooltip } from '../../../overlays/tooltip/Tooltip';
import { type TabKey, type VerticalTabsMenuTabShape } from '../VerticalTabsMenu.types';

import * as styles from './VerticalTabsMenuItem.styles';
import { verticalTabsMenuItemTestIds } from './VerticalTabsMenuItem.testIds';

type LabelFormValues = {
  label: string;
};

export type VerticalTabsMenuItemProps<TKey extends TabKey, TEditable extends boolean = false> = {
  /** Tab data. */
  tab: VerticalTabsMenuTabShape<TKey, TEditable>;
  /** Is the tab currently selected. */
  isSelected: boolean;
  /** Click handler. */
  onClick: (key: TKey) => void;
  /** Label change handler. */
  onChangeLabel: TEditable extends true
    ? (key: TKey, label: VerticalTabsMenuTabShape<TKey, true>['label']) => void
    : never;
};

const VerticalTabsMenuItemBase = function VerticalTabsMenuItem<TKey extends TabKey, TEditable extends boolean = false>({
  tab: { key, label, icon = undefined, disabled = false, isEditable = false as TEditable, placeholder = undefined },
  isSelected,
  onClick,
  onChangeLabel,
}: VerticalTabsMenuItemProps<TKey, TEditable>) {
  const { formatMessage } = useIntl();
  const [isHovered, hoverEventHandlers] = useHover<HTMLLIElement>();
  const { isEditing, setEditingTrue, setEditingFalse } = useBoolState(false, 'editing');

  const { ref: overflowRef, doesTextOverflow } = useTextOverflows([label]);

  const handleClick = useCallback(() => onClick(key), [key, onClick]);

  const formRef = useRef<FormikProps<LabelFormValues>>(null);

  const initialValues = useShallowObjectMemo<LabelFormValues>({
    label: isEditable ? (label as string) : '',
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmit: FormikConfig<LabelFormValues>['onSubmit'] = useCallback(
    (values) => {
      onChangeLabel(key, values.label);
      setEditingFalse();
    },
    [key, onChangeLabel, setEditingFalse],
  );

  // When another tab is selected, submit form and stop editing.
  useAsyncEffect(
    async (isMounted) => {
      if (isEditing && !isSelected) {
        await formRef.current?.submitForm();
        if (isMounted()) {
          setEditingFalse();
        }
      }
    },
    [isEditing, isSelected, setEditingFalse],
  );

  // When starting edition, focus the input.
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const clonedIconWithTheme = icon ? (
    <ClassNames>
      {({ css }) =>
        cloneElement(icon, {
          width: 16,
          height: 16,
          className: css`
            flex: none;
          `,
        })
      }
    </ClassNames>
  ) : null;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <li
          {...hoverEventHandlers}
          css={styles.verticalTabsMenuItem}
        >
          <Form css={styles.form}>
            {isEditable && isEditing && !disabled ? (
              <Fragment>
                <input
                  ref={inputRef}
                  css={styles.input}
                  data-testid={verticalTabsMenuItemTestIds.input(key)}
                  placeholder={placeholder || formatMessage({ defaultMessage: 'Label' })}
                  value={values.label}
                  className={clsx({
                    [styles.IS_SELECTED_CLASSNAME]: isSelected,
                    [styles.HAS_ICON_CLASSNAME]: !!icon,
                  })}
                  onChange={(e) => setFieldValue('label', e.target.value)}
                />

                {!!clonedIconWithTheme && (
                  <div
                    css={styles.inputIcon}
                    className={clsx({
                      [styles.IS_SELECTED_CLASSNAME]: isSelected,
                    })}
                  >
                    {clonedIconWithTheme}
                  </div>
                )}
              </Fragment>
            ) : (
              <Tooltip
                content={label}
                disabled={!doesTextOverflow}
                placement="right"
              >
                <UnstyledButton
                  css={styles.label}
                  data-testid={verticalTabsMenuItemTestIds.button(key)}
                  disabled={disabled}
                  className={clsx({
                    [styles.IS_EDITABLE_CLASSNAME]: isEditable,
                    [styles.IS_SELECTED_CLASSNAME]: isSelected,
                    [styles.IS_HOVERED_CLASSNAME]: isHovered,
                    [styles.HAS_ICON_CLASSNAME]: !!icon,
                  })}
                  onClick={handleClick}
                >
                  {clonedIconWithTheme}

                  <Typography
                    ref={overflowRef}
                    className={clsx({ [styles.IS_EDITING_CLASSNAME]: isEditing })}
                    css={styles.overflow}
                    variant={isSelected ? TypographyVariant.BODY_BASE_MEDIUM : TypographyVariant.BODY_BASE_REGULAR}
                  >
                    {label}
                  </Typography>
                </UnstyledButton>
              </Tooltip>
            )}

            {!disabled && isSelected && (isEditable || isEditing) ? (
              !isEditing ? (
                <IconButton
                  key="edit"
                  css={styles.action}
                  icon={<IconPencil />}
                  label={formatMessage({ defaultMessage: 'Edit label' })}
                  size={IconButton.Size.SMALL}
                  onClick={setEditingTrue}
                />
              ) : (
                <IconButton
                  key="submit"
                  css={styles.action}
                  icon={<IconCheck />}
                  label={formatMessage({ defaultMessage: 'Save label' })}
                  size={IconButton.Size.SMALL}
                  type="submit"
                  onClick={setEditingFalse}
                />
              )
            ) : null}
          </Form>
        </li>
      )}
    </Formik>
  );
};

export const VerticalTabsMenuItem = memo(VerticalTabsMenuItemBase) as typeof VerticalTabsMenuItemBase;
