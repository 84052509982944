import { uniqueId } from 'lodash';
import { memo, useMemo } from 'react';

import { CellLoading } from '../../cell-helpers/cell-loading/CellLoading';
import { useTableContext } from '../../Table.context';
import { type RowData, type ColumnDefinitions } from '../../Table.types';
import { TableDataCell } from '../table-data-cell/TableDataCell';
import { TableDataRow } from '../table-data-row/TableDataRow';

export type TableBodyLoadingProps<TData extends RowData> = {
  /** Column definitions. They must be memoed or every cell will be remounted on every render. */
  columns: ColumnDefinitions<TData>;
  /** How many rows to display as Skeleton. */
  rowsCount: number;
};

const TableBodyLoadingBase = function TableBodyLoading<TData extends RowData>({
  columns,
  rowsCount,
}: TableBodyLoadingProps<TData>) {
  const { headerRowsCount } = useTableContext();

  const emptyRows = useMemo(
    () => Array.from({ length: rowsCount }).map(() => ({ id: uniqueId('loadingRow#') })),
    [rowsCount],
  );

  return (
    <tbody>
      {emptyRows.map((row, rowIndex) => (
        <TableDataRow
          key={row.id}
          index={headerRowsCount + rowIndex}
        >
          {columns.map((column) => (
            <TableDataCell
              key={column.id}
              size={column.size}
            >
              <CellLoading />
            </TableDataCell>
          ))}
        </TableDataRow>
      ))}
    </tbody>
  );
};

export const TableBodyLoading = memo(TableBodyLoadingBase) as typeof TableBodyLoadingBase;
