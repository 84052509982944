import { useCallback, useState } from 'react';
import store from 'store';

/**
 * As the same interface than useState, but put state in the query string instead.
 * @param defaultValue
 * @param key
 * @param preferDefaultValue
 */
export const useStateInLocalStorage = <T>(
  defaultValue: T,
  key: string,
  preferDefaultValue?: boolean,
): [T, (newValue: T | ((prev: T) => T)) => void, boolean, () => void] => {
  const hasValueInStorage = !!store.get(key);

  const [valueInState, setValueInState] = useState<T>(() => {
    // If we set preferDefaultValue at true, use it as initial state.
    if (preferDefaultValue) {
      return defaultValue;
    }

    // Else, read the value from the localStorage.
    return hasValueInStorage ? (store.get(key) as T) : defaultValue;
  });

  const setValue = useCallback(
    (newValueOrCallback: T | ((old: T) => T)) => {
      const newValue =
        typeof newValueOrCallback === 'function'
          ? (newValueOrCallback as (old: T) => T)(valueInState)
          : newValueOrCallback;

      store.set(key, newValue);
      setValueInState(newValue);
    },
    [key, valueInState],
  );

  const clearValue = useCallback(() => {
    store.remove(key);
    setValueInState(defaultValue);
  }, [key, setValueInState, defaultValue]);

  return [valueInState, setValue, hasValueInStorage, clearValue];
};
