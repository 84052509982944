import { IconDots, type TablerIconsProps } from '@tabler/icons-react';
import { type ElementType, memo, type MouseEvent, type ReactNode, useCallback, Fragment } from 'react';
import { useIntl } from 'react-intl';

import { DesignerTokenIcon, type DesignerTokenIconProps } from '@amal-ia/amalia-lang/tokens/components';
import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { MenuDropdown, UnstyledButton } from '@amal-ia/frontend/design-system/components';
import { useClipboard } from '@amal-ia/frontend-kernel-clipboard';
import { DesignerObjectsActions, type VariableFormatOptions } from '@amal-ia/lib-types';
import { eventStopPropagation } from '@amal-ia/lib-ui';

import { designerObjectsDetails } from '../../../utils/designer';
import { KpiRowItem } from '../../kpi-row-item/KpiRowItem';

import { kpiTokenDisplayMessages } from './KpiTokenDisplay.messages';
import * as styles from './KpiTokenDisplay.styles';

export interface PlanDesignerTileElement {
  id?: string;
  name: string;
  label?: string;
  format?: FormatsEnum;
  formula?: string;
  formatOptions?: VariableFormatOptions;
  // Definition is used in TokenType.PROPERTY, otherwise it's null
  definition?: CustomObjectDefinition | null;
  machineName?: string;
}

export type KpiTokenDisplayProps = {
  dropdownActions?: {
    icon: ElementType<TablerIconsProps>;
    label: string;
    action: DesignerObjectsActions;
    onClick: () => void;
    disabled?: boolean;
  }[];
  isForecasted?: boolean;
  isWarning?: boolean;
  name: string;
  element: PlanDesignerTileElement;
  rawValue?: number | string;
  value: ReactNode;
  kpiIconProps?: Pick<DesignerTokenIconProps, 'propertyRef' | 'tokenFormat' | 'tokenType'>;
  kpiOnClick?: () => void;
  displayElementIconButton?: ReactNode;
};

export const KpiTokenDisplay = memo(function KpiTokenDisplay({
  dropdownActions,
  isForecasted = false,
  isWarning = false,
  name,
  element,
  rawValue,
  value,
  kpiIconProps,
  kpiOnClick,
  displayElementIconButton,
}: KpiTokenDisplayProps) {
  const { formatMessage } = useIntl();
  const kpiStyles = [isForecasted && styles.forecasted, isWarning && styles.warning].filter(Boolean);

  const { copy } = useClipboard();

  const onClickName = useCallback(
    (event: MouseEvent<unknown>) => {
      if (designerObjectsDetails[kpiIconProps.tokenType].onCopy) {
        eventStopPropagation(event);
        copy(designerObjectsDetails[kpiIconProps.tokenType].onCopy(element));
      }
    },
    [copy, element, kpiIconProps.tokenType],
  );

  return (
    <KpiRowItem
      isDraggable
      dataKpiKey={name}
      elementId={element.id}
      kpiOnClick={kpiOnClick}
      kpiStyles={kpiStyles}
      name={<UnstyledButton onClick={onClickName}>{name}</UnstyledButton>}
      rawValue={rawValue}
      value={value}
      kpiActions={
        <Fragment>
          {displayElementIconButton}
          <MenuDropdown
            content={
              <div>
                {dropdownActions.map(({ action, onClick, icon: Icon, disabled, label }) => (
                  <MenuDropdown.Item
                    key={action}
                    data-testid={`${label}-${element.id}`}
                    disabled={disabled}
                    icon={<Icon css={action === DesignerObjectsActions.DELETE ? styles.red : null} />}
                    label={label}
                    onClick={onClick}
                  />
                ))}
              </div>
            }
          >
            {({ isOpen }) => (
              <MenuDropdown.IconButton
                withBackground
                css={!isOpen && styles.hidden}
                data-testid={`open-kpi-actions-${element.id}`}
                icon={<IconDots />}
                label={formatMessage(kpiTokenDisplayMessages.OPEN_ACTIONS)}
              />
            )}
          </MenuDropdown>
        </Fragment>
      }
      kpiIcon={
        kpiIconProps ? (
          <DesignerTokenIcon
            height="16"
            propertyRef={kpiIconProps.propertyRef}
            tokenFormat={kpiIconProps.tokenFormat}
            tokenType={kpiIconProps.tokenType}
            width="16"
          />
        ) : null
      }
    />
  );
});
