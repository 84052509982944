import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, type AuthenticatedContext, SubjectsEnum } from '@amal-ia/lib-rbac';
import {
  getWorkflowDefinition,
  type Statement,
  type WorkflowDefinition,
  type WorkflowMap,
  type WorkflowStatementState,
  WorkflowStatementStateAction,
} from '@amal-ia/lib-types';
import { COMMON_MESSAGES, ConfirmationModal } from '@amal-ia/lib-ui';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { messages } from '../StatementWorkflow.messages';

import {
  type StatementWorkflowAdditionalOtpions,
  StatementWorkflowPresentation,
} from './StatementWorkflowPresentation';

interface StatementWorkflowProps {
  statement: Statement;
  onNextStep?: (canValidStep: WorkflowStatementStateAction, checked: boolean, isLast?: boolean) => any;
  onPreviousStep?: () => void;
  onReset?: () => void;
  additionalOptions?: StatementWorkflowAdditionalOtpions;
  workflowMap: WorkflowMap;
  usersMap: UsersMap;
  authenticatedContext: AuthenticatedContext;
  workflowDetails: { steps: Partial<WorkflowStatementState>[]; currentStepNumber: number };
  statementHeaderView?: boolean;
}

export const StatementWorkflow = memo(function StatementWorkflow({
  statement,
  onPreviousStep,
  onNextStep,
  onReset,
  additionalOptions,
  workflowMap,
  usersMap,
  authenticatedContext,
  workflowDetails,
  statementHeaderView = false,
}: StatementWorkflowProps) {
  const ability = useAbilityContext();

  const [isResetWorkflowModalOpen, setIsResetWorkflowModalOpen] = useState<boolean>(false);
  const [tooltipInteractive, setTooltipInteractive] = useState<boolean>(true);
  const [tooltipOpened, setTooltipOpened] = useState<boolean>(false);

  const hasRightToResetWorkflow = useMemo(
    () => onReset && ability.can(ActionsEnum.reset, SubjectsEnum.Workflow),
    [ability, onReset],
  );

  const handleResetModalClose = useCallback(() => {
    setIsResetWorkflowModalOpen(false);
    setTooltipInteractive(true);
  }, []);

  const onResetClick = useCallback(() => {
    setIsResetWorkflowModalOpen(true);
    setTooltipOpened(false);
    setTooltipInteractive(false);
  }, []);

  const onResetConfirm = useCallback(() => {
    setIsResetWorkflowModalOpen(false);
    setTooltipInteractive(true);
    onReset?.();
  }, [onReset]);

  const currentWorkflow: WorkflowDefinition | undefined = useMemo(
    () => getWorkflowDefinition(statement, workflowMap),
    [statement, workflowMap],
  );

  const { steps, currentStepNumber } = workflowDetails;

  const onNextStepProxy = useCallback(
    (canValidStep: WorkflowStatementStateAction, checked: boolean) => {
      const isNext = [WorkflowStatementStateAction.NEXT, WorkflowStatementStateAction.FORCE].includes(canValidStep);
      onNextStep?.(canValidStep, checked, isNext ? steps.length === currentStepNumber + 1 : false);
    },
    [currentStepNumber, onNextStep, steps],
  );

  return currentWorkflow && steps ? (
    <Fragment>
      <StatementWorkflowPresentation
        additionalOptions={additionalOptions}
        authenticatedContext={authenticatedContext}
        hasRightToResetWorkflow={hasRightToResetWorkflow}
        setTooltipOpened={setTooltipOpened}
        statement={statement}
        statementHeaderView={statementHeaderView}
        stepNumber={currentStepNumber}
        steps={steps}
        tooltipInteractive={tooltipInteractive}
        tooltipOpened={tooltipOpened}
        usersMap={usersMap}
        workflow={currentWorkflow}
        onNextStep={onNextStep ? onNextStepProxy : undefined}
        onPreviousStep={onPreviousStep}
        onReset={onResetClick}
      />

      <ConfirmationModal
        cancelText={<FormattedMessage {...COMMON_MESSAGES.CANCEL} />}
        confirmText={<FormattedMessage {...COMMON_MESSAGES.RESET} />}
        contentText={<FormattedMessage {...messages.CONFIRMATION_MODAL_CONTENT} />}
        handleClose={handleResetModalClose}
        handleConfirm={onResetConfirm}
        isOpened={isResetWorkflowModalOpen}
        title={<FormattedMessage {...messages.CONFIRMATION_MODAL_TITLE} />}
      />
    </Fragment>
  ) : null;
});
