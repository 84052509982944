import { http } from '@amal-ia/frontend/kernel/http';

import { type CompanyCurrency } from '../../types/company';

const apiEndpoint = '/company_currencies';

/**
 * Creates a company rate.
 * @param companyCurrency
 */
export async function createCompanyCurrency(
  companyCurrency: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>,
): Promise<CompanyCurrency> {
  const { data } = await http.post<CompanyCurrency>(`${apiEndpoint}`, companyCurrency);
  return data;
}

/**
 * Updates a company currency rate.
 * @param companyCurrency
 */
// TODO: change endpoint backend to be PATCH /company_currencies/{id}
export async function updateCompanyCurrency(
  companyCurrency: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>,
): Promise<CompanyCurrency> {
  const { data } = await http.patch<CompanyCurrency>(`${apiEndpoint}`, companyCurrency);
  return data;
}

/**
 * Deletes a company currency.
 * @param companyCurrencyId
 */
export async function deleteCompanyCurrency(companyCurrencyId: string): Promise<void> {
  await http.delete(`${apiEndpoint}/${companyCurrencyId}`);
}

/**
 * List company rates for a given year.
 * @param year
 */
export async function getCompanyCurrencies(year: string): Promise<CompanyCurrency[]> {
  const { data } = await http.get<CompanyCurrency[]>(`${apiEndpoint}/?year=${year}`);
  return data;
}
