import { memo } from 'react';
import { generatePath, Redirect } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { useCurrentCompany } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

export const HomeRedirectView = memo(function HomeRedirectView() {
  const { company } = useCurrentCompany();
  const ability = useAbilityContext();

  if (company.featureFlags?.FLOWS_ENV_ONLY) {
    return <Redirect to={generatePath(routes.FLOWS)} />;
  }

  if (company.featureFlags?.REP_HOME && ability.can(ActionsEnum.view, SubjectsEnum.RepHome)) {
    return <Redirect to={generatePath(routes.HOME)} />;
  }

  return <Redirect to={generatePath(routes.STATEMENTS)} />;
});
