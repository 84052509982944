import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { QuickEdit } from '../../forms/quick-edit/QuickEdit';
import { IconButton } from '../../general/icon-button/IconButton';
import { Typography } from '../../general/typography/Typography';

import * as styles from './Pagination.styles';
import { paginationTestIds } from './Pagination.testIds';

export type PaginationProps = {
  /** Number of pages. */
  pageCount: number;
  /** O-based page index. */
  page: number;
  /** Page change handler. */
  onChangePage: (page: number) => void;
};

export const Pagination = memo(function Pagination({ pageCount, page, onChangePage }: PaginationProps) {
  const { formatMessage } = useIntl();
  const isPreviousDisabled = page <= 0;
  const isNextDisabled = page >= pageCount - 1;
  const [isEditing, setIsEditing] = useState(false);

  const onClickFirst = useCallback(() => onChangePage(0), [onChangePage]);
  const onClickPrevious = useCallback(() => onChangePage(page - 1), [onChangePage, page]);
  const onClickNext = useCallback(() => onChangePage(page + 1), [onChangePage, page]);
  const onClickLast = useCallback(() => onChangePage(pageCount - 1), [onChangePage, pageCount]);

  const handleChangePage = useCallback((newPage: string) => onChangePage(parseInt(newPage, 10) - 1), [onChangePage]);

  const quickEditValidationSchema = useMemo(
    () =>
      Yup.number()
        .integer(formatMessage({ defaultMessage: 'The page number must be an integer.' }))
        .min(1, formatMessage({ defaultMessage: 'The page number must be greater than 0.' }))
        .max(
          pageCount,
          formatMessage({ defaultMessage: 'The page number cannot be greater than the total number of pages.' }),
        ),
    [formatMessage, pageCount],
  );

  return (
    <div css={styles.pagination}>
      <div css={styles.buttonsContainer}>
        <IconButton
          disabled={isPreviousDisabled}
          icon={<IconChevronsLeft />}
          label={formatMessage({ defaultMessage: 'First page' })}
          size={IconButton.Size.SMALL}
          onClick={onClickFirst}
        />

        <IconButton
          disabled={isPreviousDisabled}
          icon={<IconChevronLeft />}
          label={formatMessage({ defaultMessage: 'Previous page' })}
          size={IconButton.Size.SMALL}
          onClick={onClickPrevious}
        />
      </div>

      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
        <FormattedMessage defaultMessage="Page:" />
      </Typography>

      <div>
        <QuickEdit
          data-testid={paginationTestIds.input}
          disabled={pageCount < 2}
          isEditing={isEditing}
          schema={quickEditValidationSchema}
          value={`${page + 1}`}
          variant={QuickEdit.Variant.BODY_BASE_MEDIUM}
          onChange={handleChangePage}
          onChangeIsEditing={setIsEditing}
        />
      </div>

      <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
        <FormattedMessage
          defaultMessage="/ {pageCount, number}"
          values={{ pageCount }}
        />
      </Typography>

      <div css={styles.buttonsContainer}>
        <IconButton
          disabled={isNextDisabled}
          icon={<IconChevronRight />}
          label={formatMessage({ defaultMessage: 'Next page' })}
          size={IconButton.Size.SMALL}
          onClick={onClickNext}
        />

        <IconButton
          disabled={isNextDisabled}
          icon={<IconChevronsRight />}
          label={formatMessage({ defaultMessage: 'Last page' })}
          size={IconButton.Size.SMALL}
          onClick={onClickLast}
        />
      </div>
    </div>
  );
});
