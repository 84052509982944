import { css } from '@emotion/react';
import { type ReactNode, memo } from 'react';

import { MenuGroup } from './menu-group/MenuGroup';
import { MenuItem } from './menu-item/MenuItem';

export type MenuProps = {
  children?: ReactNode;
};

const MenuBase = memo(function Menu({ children }: MenuProps) {
  return (
    <nav
      css={css`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      {children}
    </nav>
  );
});

export const Menu = Object.assign(MenuBase, { Group: MenuGroup, Item: MenuItem });
