import { TableFixedColumns } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentStatement, selectCurrentStatementThreads } from '@amal-ia/frontend/web-data-layers';

import { isAThreadOnARow } from './RowsTable.utils';
import RowsTableContext from './RowsTableContext';
import useRowsTableStyles from './useRowsTableStyles';

export const RowsTableFixedCell = memo(function TableFixedCell(propsCell: any) {
  const classes = useRowsTableStyles();
  const statementThreads = useSelector(selectCurrentStatementThreads);
  const currentStatement = useSelector(selectCurrentStatement);

  const { dataset, ruleId } = useContext(RowsTableContext);

  const { tableRow } = propsCell;
  const { row } = tableRow;
  const isThreadOnCurrentRow = useMemo(
    () => isAThreadOnARow(statementThreads, row, ruleId, currentStatement, dataset),
    [statementThreads, row, ruleId, currentStatement, dataset],
  );
  const isRowHighlighted = (row?.overwrites && row.overwrites.length > 0) || isThreadOnCurrentRow;

  return (
    <TableFixedColumns.Cell
      {...propsCell}
      className={clsx(isRowHighlighted && classes.rowHighlight, classes.fixedCell)}
    >
      {propsCell?.children}
    </TableFixedColumns.Cell>
  );
});
