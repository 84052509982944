import { Box } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { useCompanyCustomization } from '@amal-ia/frontend/web-data-layers';
import {
  formatPeriodName,
  type Payment,
  PaymentCategory,
  PaymentType,
  type Period,
  type Statement,
} from '@amal-ia/lib-types';
import { DataGrid, type DataGridPropsOptions } from '@amal-ia/lib-ui';

import PaymentsTableCell from './PaymentsTableCell';
import PaymentsTableContext, { type PaymentsTableContextInterface } from './PaymentsTableContext';
import PaymentsTableRow from './PaymentsTableRow';

const DEFAULT_HIDDEN_COLUMN_NAMES = ['id', 'plan'];

interface PaymentsTableProps {
  statement: Statement;
  category: PaymentCategory;
  rows: Partial<Payment>[];
  currentPeriod?: Period;
}

const PaymentsTable = memo(function PaymentsTable({ statement, category, rows, currentPeriod }: PaymentsTableProps) {
  const { data: user } = useCurrentUser();
  const companyCustomization = useCompanyCustomization();
  const { formatMessage } = useIntl();

  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>(DEFAULT_HIDDEN_COLUMN_NAMES);

  const getCellValue = useCallback(
    (p: any, columnName: string) => {
      const { statementFrequency } = user.company;
      switch (columnName) {
        case 'rule':
          return p.type === PaymentType.ADJUSTMENT ? (
            <i>
              <FormattedMessage defaultMessage="Adjustment" />
            </i>
          ) : (
            p.rule?.name
          );
        case 'plan':
          return p.statement?.plan?.name;
        // Re-enable this on insight view.
        // case 'paymentPeriod':
        //   return p.paymentPeriodId ? formatPeriodName(statementFrequency, moment(p.paymentPeriod?.startDate, 'X').toDate()) : 'Unpaid';
        case 'statement':
          return formatPeriodName(statementFrequency, p.statement?.period?.startDate);
        case 'object':
          return p.dealName;
        default:
          return p[columnName];
      }
    },
    [user],
  );

  // Datagrid options
  const DATAGRID_OPTIONS: DataGridPropsOptions = useMemo(
    () => ({
      fullWidth: false,
      sort: {
        sortable: true,
      },
      search: {
        searchable: true,
      },
      pages: {
        paginable: true,
      },
      columnVisibility: {
        active: true,
        hiddenColumnNames,
        onHiddenColumnNamesChange: setHiddenColumnNames,
      },
      // For column amount, permits to sort by number and print them with currencies
      getCellValue,
    }),
    [getCellValue, hiddenColumnNames],
  );

  const { showPaymentDate, showForecastPaymentDate, showPaymentPeriod, paymentDateTitle, forecastPaymentDateTitle } =
    useMemo(() => {
      const hasSomePaymentsHaveRulePaymentDateFieldId = (rows || []).some((p) => {
        const ruleForThisPayment = (statement?.results?.definitions?.plan?.rules || []).find((r) => r.id === p.ruleId);
        return !!ruleForThisPayment?.configuration?.paymentDateFieldId;
      });

      const hasSomePaymentsHaveRuleForecastPaymentDateFieldId = (rows || []).some((p) => {
        const ruleForThisPayment = (statement?.results?.definitions?.plan?.rules || []).find((r) => r.id === p.ruleId);
        return !!ruleForThisPayment?.configuration?.forecastPaymentDateFieldId;
      });

      const hasOnePaymentRowDifferentFrequencyThanStatement = (rows || []).some(
        (p) => p.paymentPeriod?.frequency && p.paymentPeriod?.frequency !== p.period?.frequency,
      );

      return {
        showPaymentDate: hasSomePaymentsHaveRulePaymentDateFieldId,
        showForecastPaymentDate: hasSomePaymentsHaveRuleForecastPaymentDateFieldId && category === PaymentCategory.hold,
        showPaymentPeriod: hasOnePaymentRowDifferentFrequencyThanStatement || category === PaymentCategory.hold,
        paymentDateTitle:
          companyCustomization?.holdAndReleasePaymentDateLabel || formatMessage({ defaultMessage: 'Payment date' }),
        forecastPaymentDateTitle:
          companyCustomization?.holdAndReleaseForecastPaymentDateLabel ||
          formatMessage({ defaultMessage: 'Forecast payment date' }),
      };
    }, [category, companyCustomization, rows, statement, formatMessage]);

  // Datagrid columns
  const columns = useMemo(
    () =>
      [
        { name: 'id', title: formatMessage({ defaultMessage: 'Payment Id' }) },
        { name: 'statement', title: formatMessage({ defaultMessage: 'Commission period' }) },
        { name: 'plan', title: formatMessage({ defaultMessage: 'Plan' }) },
        { name: 'rule', title: formatMessage({ defaultMessage: 'Rule' }) },
        (rows || []).some((r) => r.dealExternalId || r.adjustment) && {
          name: 'object',
          title: formatMessage({ defaultMessage: 'Object' }),
        },
        { name: 'value', title: formatMessage({ defaultMessage: 'Commission amount' }) },
        showPaymentPeriod && { name: 'paymentPeriod', title: formatMessage({ defaultMessage: 'Payment period' }) },
        showForecastPaymentDate && { name: 'forecastPaymentDate', title: forecastPaymentDateTitle },
        showPaymentDate && { name: 'paymentDate', title: paymentDateTitle },
        (rows || []).some((r) => r.objectVariable) && {
          name: 'variable',
          title: formatMessage({ defaultMessage: 'Variable' }),
        },
      ].filter(Boolean),
    [
      rows,
      showForecastPaymentDate,
      forecastPaymentDateTitle,
      showPaymentDate,
      paymentDateTitle,
      showPaymentPeriod,
      formatMessage,
    ],
  );

  // We need to compute it to print the tracing button only on the first column
  const firstPrintedColumn = useMemo(
    () => columns?.find((col) => !hiddenColumnNames?.includes(col.name))?.name,
    [columns, hiddenColumnNames],
  );

  const contextContent: PaymentsTableContextInterface = useShallowObjectMemo({
    companyFrequency: user?.company?.statementFrequency,
    currentPeriod,
    firstPrintedColumn,
  });

  return (
    <PaymentsTableContext.Provider value={contextContent}>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          columns={columns}
          id="paymentsTable"
          options={DATAGRID_OPTIONS}
          rows={rows}
          tableProps={{ cellComponent: PaymentsTableCell, rowComponent: PaymentsTableRow }}
        />
      </Box>
    </PaymentsTableContext.Provider>
  );
});

export default PaymentsTable;
