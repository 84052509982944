import { useQuery } from '@tanstack/react-query';

import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { PeriodsRepository } from '@amal-ia/frontend/web-data-layers';
import { formatDate } from '@amal-ia/lib-types';

enum PERIOD_QUERY_KEYS {
  PERIOD = 'period',
}

export const usePeriod = (date?: string, frequency?: PeriodFrequencyEnum) =>
  useQuery({
    queryKey: [PERIOD_QUERY_KEYS.PERIOD, date, frequency],
    queryFn: () => PeriodsRepository.getPeriodByDate(date, frequency),
    select: (data) => {
      const year = formatDate(new Date(data.startDate * 1000), 'YYYY');

      return {
        period: data,
        year,
      };
    },
  });
