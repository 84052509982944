import { IconPinned } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '../../../general/icon-button/IconButton';

export type ColumnPinningProps = {
  isActive: boolean;
  onChange: (value: boolean) => void;
};

const ColumnPinningForwardRef = forwardRef(function ColumnPinning(
  { isActive, onChange }: ColumnPinningProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { formatMessage } = useIntl();

  const handleTogglePinning = useCallback(() => {
    onChange(!isActive);
  }, [onChange, isActive]);

  return (
    <IconButton
      ref={ref}
      withBackground
      aria-checked={isActive}
      icon={<IconPinned />}
      isActive={isActive}
      role="checkbox"
      size={IconButton.Size.MEDIUM}
      label={
        isActive
          ? formatMessage({ defaultMessage: 'Unpin first column' })
          : formatMessage({ defaultMessage: 'Pin first column' })
      }
      onClick={handleTogglePinning}
    />
  );
});

export const ColumnPinning = memo(ColumnPinningForwardRef);
