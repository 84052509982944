import { uniqBy } from 'lodash';
import { type MathNode } from 'mathjs';

import { type AmaliaFormula, type AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';
import { type ComputedFunctionArgs } from '@amal-ia/payout-calculation/shared/types';

import { type CalculationScope } from '../CalculationParser';

export type AmaliaFunctionReturnType = boolean | number | string | null;

class AmaliaFunction {
  private static allFunctions: Record<string, AmaliaFunction> = {};

  public static readonly getAllFunctions = () => AmaliaFunction.allFunctions;

  public static readonly getFunctionsEnum = () =>
    Object.keys(AmaliaFunction.allFunctions).reduce<Record<string, string>>((acc, key) => {
      acc[key] = key;
      return acc;
    }, {});

  public static readonly getAllFunctionsArray = (): AmaliaFunction[] =>
    uniqBy(Object.values(AmaliaFunction.allFunctions), 'name');

  public name: string;

  public nbParamsRequired?: number;

  public category: AmaliaFunctionCategory;

  public description?: string[] | string;

  public examples?: { desc?: string; formula: AmaliaFormula; result?: AmaliaFunctionReturnType }[];

  public params?: { name: string; description: string; defaultValue?: AmaliaFunctionReturnType }[];

  public hasInfiniteParams?: boolean;

  // The function to call in order to execute the function.
  public exec?: (...args: any[]) => AmaliaFunctionReturnType;

  // The function to call if the function is rawArgs.
  public execRawArgs?: (args: any[], mathf: any, scope: CalculationScope) => AmaliaFunctionReturnType;

  // Mock the evaluation in formula validation, useful for ComputedFunctionResults for instance.
  public execMock?: (...args: any[]) => AmaliaFunctionReturnType;

  /**
   * Given the args of the current context, generates the ComputedFunctionResult skeleton
   * for this function.
   */
  public generateComputedFunctionResult?: (args: MathNode[]) => ComputedFunctionArgs;

  /**
   * For some functions, avoid classic parsing on some parameters by ignoring them.
   *
   * It usually means that they should be parsed in another context (for instance a dataset or a different period).
   */
  public parametersToEscapeOnParse?: number[];

  // Using an arrow function so the `this` refers to the object at any time.
  public readonly callFunction = (...args: any[]) => {
    if (this.nbParamsRequired !== undefined) {
      for (let i = 0; i < this.nbParamsRequired; i++) {
        if (args[i] === undefined || args[i] === null) {
          throw new Error(`${this.name} is missing parameter at position ${i + 1} or its value is undefined`);
        }
      }
    }

    if (this.params?.length && args.length > this.params.length) {
      throw new Error(`Too many parameters for function ${this.name}`);
    }

    if (!this.exec) {
      throw new Error(`Exec function not provided for ${this.name}`);
    }

    return this.exec(...args);
  };

  public constructor(name: string, category: AmaliaFunctionCategory) {
    this.name = name;
    this.category = category;

    // Register this function into the global enum.
    AmaliaFunction.allFunctions[name] = this;
  }
}

export default AmaliaFunction;
