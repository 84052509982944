import { type DataConnector } from '@amal-ia/data-capture/connectors/types';
import { type Company } from '@amal-ia/tenants/shared/types';

import { type ScheduleCalculationEvent } from './calculation';
import { type RefreshmentError } from './data';

export enum CustomObjectImportStatus {
  /* new data waiting to be imported */
  PENDING = 'PENDING',
  /* data import has been started */
  STARTED = 'STARTED',
  /* All data have been imported */
  SUCCESS = 'SUCCESS',
  /* Import has failed */
  ERROR = 'ERROR',
  /* Import is requested to stop, not stopped yet */
  STOPPING = 'STOPPING',
  /* Import was stopped */
  STOPPED = 'STOPPED',
  /* Some of the datas have not been imported */
  INCOMPLETE = 'INCOMPLETE',
}

// If the refreshment has one of this statuses, it means it's ongoing.
export const REFRESHMENTS_ONGOING_STATUSES = [
  CustomObjectImportStatus.PENDING,
  CustomObjectImportStatus.STARTED,
  CustomObjectImportStatus.STOPPING,
];

export enum IndexationType {
  /* Full reindex */
  FULL = 'FULL',
  /* Partial reindex */
  PARTIAL = 'PARTIAL',
}

export interface RefreshmentOptions {
  indexationType: IndexationType;

  // List of objects we want to refresh.
  // If null, it means that we want to refresh the whole company.
  dataConnectorObjectsNames:
    | {
        // Type of the connector. We actually need both custom object name and
        // connector type to be able to retrieve the definition.
        type: string;
        name: string;
      }[]
    | null;

  // Should launch calculation at the end of the refreshment?
  willLaunchCalculation: boolean;

  // Options for the triggered calculation
  calculationsOptions?: ScheduleCalculationEvent['options'];

  // Should prune old data at the end of the refreshment?
  willPrune: boolean;

  // Start offset to avoid timout on large refreshment
  startOffset: number;
}

export type DataRefreshmentValidationError = {
  row: Record<string, unknown>;
  error: string;
};

export type DataRefreshment = {
  id?: string;
  updatedAt?: Date;

  company?: Company;
  connector: DataConnector;

  options: RefreshmentOptions;
  status: CustomObjectImportStatus;

  startDate: Date;
  endDate: Date;
  totalUpdate: number;
  updated: number;

  totalDelete: number;
  deleted: number;

  validationErrors: DataRefreshmentValidationError[];
  validationErrorsCount: number;
  error?: RefreshmentError;
};
