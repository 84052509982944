import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { routesTitles } from '../SuperAdmin.routes.messages';

const CompaniesEditPage = lazy(() => import('./CompaniesEditPage'));
const CompaniesPage = lazy(() => import('./CompaniesPage'));

const CompaniesRoutes = memo(function CompaniesRoutes() {
  const { data: currentUser } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Switch>
      <CompanyRoute
        component={CompaniesEditPage}
        path={routes.SUPERADMIN_COMPANY}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
        title={formatMessage(routesTitles.SUPER_ADMIN_COMPANY_DETAILS)}
        user={currentUser}
      />
      <CompanyRoute
        component={CompaniesPage}
        path={routes.SUPERADMIN_COMPANIES}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
        title={formatMessage(routesTitles.SUPER_ADMIN_COMPANY_LIST)}
        user={currentUser}
      />
    </Switch>
  );
});

export default CompaniesRoutes;
