import { Redo } from '@mui/icons-material';
import { ClickAwayListener, type ClickAwayListenerProps, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useState, type ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType, colors } from '@amal-ia/ext/mui/theme';

import { useDebouncedInput } from '../../../../../utils/hooks/useDebouncedInput';
import { TextFieldBase } from '../../../formElements/inputs/TextField/TextField';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    width: '5rem',
    minWidth: 0,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    border: 'none',

    '& > input': {
      textAlign: 'center',
    },
  },
  icon: {
    cursor: 'pointer',
    color: colors['grey-700'],
  },
}));

interface PageChooserProps {
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const isNumberRegex = /^\d+$/u;

export const PageChooser = memo(function PageChooser({ currentPage, setCurrentPage }: PageChooserProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  const [currentPageDebounced, setCurrentPageDebounced] = useState<string>(`${currentPage + 1}`);

  const onCurrentPageChange = useCallback(
    (value: string) => {
      // Only send value to parent if value is number (no empty allowed)
      if (isNumberRegex.test(value)) {
        setCurrentPage(parseInt(value, 10) - 1);
      }
    },
    [setCurrentPage],
  );

  const { onChange: onDebouncedCurrentPageInputChange } = useDebouncedInput(
    setCurrentPageDebounced,
    onCurrentPageChange,
    500,
  );

  const onChangeProxy = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // Only save input's value if it's a number or if it's empty (cleared by user)
      if (isNumberRegex.test(e.target.value) || e.target.value === '') {
        onDebouncedCurrentPageInputChange(e.target.value);
      }
    },
    [onDebouncedCurrentPageInputChange],
  );

  const deployPageChooser = useCallback((e: any) => setAnchorEl(e.currentTarget), [setAnchorEl]);
  const closePageChooser: ClickAwayListenerProps['onClickAway'] = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl],
  );

  return (
    <div
      className={classes.root}
      role="button"
      tabIndex={0}
      onClick={deployPageChooser}
      onKeyDown={deployPageChooser}
    >
      <Redo
        className={classes.icon}
        titleAccess={formatMessage({ defaultMessage: 'Jump to page' })}
      />
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={closePageChooser}
      >
        <ClickAwayListener onClickAway={closePageChooser}>
          <TextFieldBase
            className={classes.input}
            type="string"
            value={currentPageDebounced}
            onChange={onChangeProxy}
          />
        </ClickAwayListener>
      </Popover>
    </div>
  );
});
