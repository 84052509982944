import { type SortingDirection } from '@devexpress/dx-react-grid';
import { IconArrowBarToDown } from '@tabler/icons-react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import store from 'store';
import useAsyncEffect from 'use-async-effect';

import { PageContainer } from '@amal-ia/frontend/connected-components/layout';
import { IconButton, PageHeader, Typography } from '@amal-ia/frontend/design-system/components';
import {
  fetchOverwrites,
  selectOverwriteList,
  selectOverwritesIsLoading,
  useThunkDispatch,
  getOverwrites,
  downloadCsvFile,
} from '@amal-ia/frontend/web-data-layers';
import { type Option } from '@amal-ia/lib-types';
import { type DataGridPropsOptions, useDebouncedInput } from '@amal-ia/lib-ui';
import { FilterBar } from '@amal-ia/lib-ui-business';

import { mapOverwritesToCsv } from './overwrites.utils';
import { messages } from './OverwritesContainer.messages';
import * as styles from './OverwritesContainer.styles';
import OverwritesTable from './OverwritesTable/OverwritesTable';
import useOverwriteFilters from './useOverwriteFilters';

// Datagrid columns
const columns = [
  { name: 'scope', title: 'Scope', realSortColumn: 'overwrite_scope' },
  { name: 'statement', title: 'Salesrep(s)', realSortColumn: 'user_firstname' },
  { name: 'period', title: 'Period(s)', realSortColumn: 'period_startDate' },
  { name: 'plan', title: 'Plan(s)', realSortColumn: 'plan_name' },
  { name: 'rule', title: 'Rule', realSortColumn: 'rule_name' },
  { name: 'overwriteType', title: 'Type', realSortColumn: 'overwrite_type' },
  { name: 'overwrittenBy', title: 'overwrittenBy', realSortColumn: 'creator_firstname' },
  { name: 'customObject', title: 'Record', realSortColumn: 'customobject_externalid' },
  { name: 'variable', title: 'Variable', realSortColumn: 'overwrite_field' },
  { name: 'overwriteValue', title: 'Value', realSortColumn: 'overwrite_overwritevalue' },
  { name: 'clearedAt', title: 'Cleared At', realSortColumn: 'overwrite_clearedat' },
  { name: 'createdAt', title: 'Overwritten Date', realSortColumn: 'overwrite_createdat' },
];

export type FiltersMap = Record<
  string,
  {
    menuTitle: string;
    elements: Option[];
    checkboxFilter: Option[];
    onReset?: () => void;
    onChange: (elements: Option[]) => void;
    isActive: boolean;
  }
>;

const DEFAULT_HIDDEN_COLUMN_NAMES = ['overwriteType', 'rule'];

export const OverwritesContainer = memo(function OverwritesContainer() {
  const dispatch = useThunkDispatch();
  const { formatMessage } = useIntl();

  // Datagrid parameters
  const [sorting, setSorting] = useState<{ columnName: string; direction: SortingDirection }[]>(
    store.get('datagrid_overwritesTable_sorting') || [],
  );

  const [currentPage, setCurrentPage] = useState<number>(store.get('datagrid_overwritesTable_currentPage') || 0);
  const [pageSize, setPageSize] = useState<number>(store.get('datagrid_overwritesTable_pageSize') || 10);

  const [searchValue, setSearchValue] = useState<string>(store.get('datagrid_overwritesTable_searchValue') || '');
  const [searchValueDebounced, setSearchValueDebounced] = useState<string>(
    store.get('datagrid_overwritesTable_searchValue') || '',
  );

  const { onChange: onDebouncedSearchInputChange } = useDebouncedInput(setSearchValue, setSearchValueDebounced, 1000);

  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>(DEFAULT_HIDDEN_COLUMN_NAMES);

  const { filterBarItems, setFilterBarItems, additionalFilters, selectedValues } = useOverwriteFilters();

  const overwritesListDetails = useSelector(selectOverwriteList);
  const isOverwritesLoading = useSelector(selectOverwritesIsLoading);

  // Data fetcher
  useAsyncEffect(async () => {
    const realSortColumn = sorting[0]?.columnName
      ? columns.find((c) => c.name === sorting[0].columnName)?.realSortColumn
      : undefined;
    await dispatch(
      fetchOverwrites(
        searchValueDebounced,
        currentPage + 1,
        pageSize,
        realSortColumn,
        sorting[0]?.direction,
        selectedValues,
      ),
    );
  }, [dispatch, sorting, searchValueDebounced, currentPage, pageSize, filterBarItems]);

  // Page overflow handler
  useEffect(() => {
    if (!isOverwritesLoading && currentPage > overwritesListDetails.totalItems / pageSize) {
      setCurrentPage(0);
    }
  }, [overwritesListDetails, currentPage, pageSize, isOverwritesLoading]);

  // Trigger CSV export
  const onExportOverwrites = useCallback(async () => {
    const realSortColumn = sorting[0]?.columnName
      ? columns.find((c) => c.name === sorting[0].columnName)?.realSortColumn
      : undefined;
    const overwrites = await getOverwrites(
      searchValueDebounced,
      0,
      overwritesListDetails.totalItems,
      realSortColumn,
      sorting[0]?.direction,
      selectedValues,
    );
    const csvData = await mapOverwritesToCsv(overwrites.items);
    downloadCsvFile(csvData, 'overwrites_export');
  }, [selectedValues, overwritesListDetails.totalItems, searchValueDebounced, sorting]);

  // Datagrid options
  const DATAGRID_OPTIONS: DataGridPropsOptions = useMemo(
    () => ({
      sort: {
        sortable: true,
        overwriteSort: true,
        sorting,
        onSortingChange: setSorting,
      },
      search: {
        searchable: true,
        overwriteSearch: true,
        value: searchValue,
        onValueChange: onDebouncedSearchInputChange,
      },
      pages: {
        paginable: true,
        customPagingTotalItems: overwritesListDetails.totalItems,
        currentPage,
        onCurrentPageChange: setCurrentPage,
        pageSize,
        onPageSizeChange: setPageSize,
      },
      columnVisibility: {
        active: true,
        hiddenColumnNames,
        onHiddenColumnNamesChange: setHiddenColumnNames,
      },
    }),
    [
      sorting,
      searchValue,
      onDebouncedSearchInputChange,
      overwritesListDetails,
      currentPage,
      pageSize,
      hiddenColumnNames,
    ],
  );

  // Filters change
  useEffect(() => {
    store.set('datagrid_overwritesTable_sorting', sorting);
    store.set('datagrid_overwritesTable_currentPage', currentPage);
    store.set('datagrid_overwritesTable_pageSize', pageSize);
    store.set('datagrid_overwritesTable_searchValue', searchValueDebounced);
  }, [sorting, currentPage, pageSize, searchValueDebounced]);

  return (
    <PageContainer>
      <div css={styles.container}>
        <PageHeader>
          <PageHeader.Row
            left={
              <Typography variant={Typography.Variant.HEADING_1_BOLD}>
                <FormattedMessage {...messages.OVERWRITES} />
              </Typography>
            }
            right={
              <IconButton
                withBackground
                icon={<IconArrowBarToDown />}
                label={formatMessage(messages.EXPORT_OVERWRITES)}
                onClick={onExportOverwrites}
              />
            }
          />
        </PageHeader>
        <FilterBar
          additionalFilters={additionalFilters}
          items={filterBarItems}
          onUpdateItems={setFilterBarItems}
        />
        <div css={styles.gridContainer}>
          <OverwritesTable
            columns={columns}
            datagridOptions={DATAGRID_OPTIONS}
            rows={overwritesListDetails.items}
          />
        </div>
      </div>
    </PageContainer>
  );
});
