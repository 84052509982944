import { last } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';
import {
  Select,
  type SelectOptionGroup,
  type SelectOption,
  type SelectProps,
} from '@amal-ia/frontend/design-system/components';
import { type Period, RelativePeriodKeyword } from '@amal-ia/lib-types';
import { RELATIVE_PERIOD_KEYWORD_LABELS } from '@amal-ia/lib-ui';

export type PeriodSelection = RelativePeriodKeyword | string;

type PeriodOption = SelectOption<PeriodSelection>;

type PeriodSelectProps = MergeAll<
  [
    Omit<SelectProps<PeriodOption, true, false>, 'isMultiple' | 'options'>,
    {
      overridesPeriodOptions?: SelectOption<string>[];
      periodList: Period[];
    },
  ]
>;

/**
 * PeriodSelect: a component to select a period / a list of periods / a keyword for period relative compute
 * WARNING: periods must be loaded in redux
 */
export const PeriodSelect = memo(function PeriodSelect({
  onChange,
  overridesPeriodOptions,
  periodList,
  ...props
}: PeriodSelectProps) {
  const { formatMessage } = useIntl();

  const periodOptions: SelectOptionGroup<PeriodOption>[] = useMemo(
    () => [
      {
        label: formatMessage({ defaultMessage: 'Relative periods' }),
        options: Object.keys(RelativePeriodKeyword).map((keyword: string) => ({
          value: keyword,
          label: formatMessage(RELATIVE_PERIOD_KEYWORD_LABELS[keyword as RelativePeriodKeyword]),
          componentWhenMultiple: 'RADIO',
        })),
      },
      {
        label: formatMessage({ defaultMessage: 'Custom periods' }),
        options:
          overridesPeriodOptions ||
          periodList.map((period) => ({
            value: period.id,
            label: period.name,
          })),
      },
    ],
    [periodList, overridesPeriodOptions, formatMessage],
  );

  const handleChangeProxy = useCallback(
    (newValues: PeriodSelection[]) => {
      if (newValues.length === 0) {
        onChange([]);
        return;
      }

      const typeOfLastItem: 'KEYWORD' | 'PERIOD' = Object.keys(RelativePeriodKeyword).includes(last(newValues)!)
        ? 'KEYWORD'
        : 'PERIOD';

      if (typeOfLastItem === 'KEYWORD') {
        onChange([last(newValues)!]);
        return;
      }

      onChange(newValues.filter((v) => !Object.keys(RelativePeriodKeyword).includes(v)));
    },
    [onChange],
  );

  return (
    <Select
      {...props}
      isMultiple
      options={periodOptions}
      onChange={handleChangeProxy}
    />
  );
});
