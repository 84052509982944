import { css } from '@emotion/react';
import { InputAdornment, List, ListItem, ListItemText } from '@mui/material';
import { IconSearch, IconX } from '@tabler/icons-react';
import { sortBy, unionBy } from 'lodash';
import { type ChangeEvent, Fragment, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Breadcrumbs, IconButton } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext, useAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import { selectUserStatementsListFacet } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum, SubsetAccessEnum, SubsetsAccessHelper } from '@amal-ia/lib-rbac';
import { formatPeriodMarkerDate, formatUserFullName, type Option, type StatementOption } from '@amal-ia/lib-types';
import { COMMON_MESSAGES, Input, type StatementDetailContextInterface } from '@amal-ia/lib-ui';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import StatementPeriodSelector from '../../../StatementPeriodSelector';

import { messages } from './StatementDetailHeaderBreadcrumbs.messages';

type StatementDetailHeaderBreadcrumbsProps = {
  statement: StatementDetailContextInterface;
};

const StatementDetailHeaderBreadcrumbsBase = function StatementDetailHeaderBreadcrumbsBase({
  statement,
}: StatementDetailHeaderBreadcrumbsProps) {
  const userStatementsListFacet = useSelector(selectUserStatementsListFacet);
  const history = useHistory();
  const { authenticatedContext } = useAuthenticatedContext();
  const [search, setSearch] = useState<string>('');
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  const userViewListRightAccess = useMemo(() => ability.can(ActionsEnum.view_list, SubjectsEnum.Statement), [ability]);

  // PLANS SELECT
  const statementsByPlans: Record<string, StatementOption[]> = useMemo(
    () =>
      (userStatementsListFacet.users || []).reduce(
        (acc, elm) => {
          (elm.statementsOptions || []).forEach((statementOption) => {
            if (!acc[statementOption.planId]) {
              acc[statementOption.planId] = [];
            }
            acc[statementOption.planId] = [
              ...acc[statementOption.planId],
              ...(elm.statementsOptions || [])
                .filter((s) => s.planId === statementOption.planId)
                .map((s) => ({
                  ...s,
                  userId: elm.id,
                })),
            ];
          });
          return acc;
        },
        {} as Record<string, StatementOption[]>,
      ),
    [userStatementsListFacet],
  );

  const plans: Option[] = useMemo(
    () =>
      (userStatementsListFacet.users || [])
        .map((u) => u.statementsOptions || [])
        .reduce((acc, statementsOptions) => {
          const planOptions = statementsOptions.map((s) => ({
            label: s.planName,
            value: s.planId,
          }));
          return unionBy(acc, planOptions, 'value');
        }, [] as Option[]),
    [userStatementsListFacet],
  );

  const proposedPlans: Option[] = useMemo(
    () =>
      sortBy(
        plans.filter((elm) => elm.value !== statement.plan.id),
        'label',
      ),
    [plans, statement.plan],
  );

  const onChangePlan = useCallback(
    (plan: Option) => {
      if (statementsByPlans[plan.value]?.length) {
        const userPlanStatement = statementsByPlans[plan.value].find((elm: any) => elm.userId === statement.user.id);

        history.push(
          generatePath(routes.STATEMENT, {
            id: userPlanStatement?.statementId || statementsByPlans[plan.value][0].statementId,
          }),
        );
      }
    },
    [history, statement.user.id, statementsByPlans],
  );

  // USERS SELECT
  const userSwitchRightAccess = useMemo(
    () =>
      SubsetsAccessHelper.getSubset(authenticatedContext, ActionsEnum.switch_to_another_user, SubjectsEnum.Statement),
    [authenticatedContext],
  );

  const usersCanSeeForThisPlan = useMemo(() => {
    // Get users from the statement Groups that have at least one statement
    const users = (userStatementsListFacet.users || [])
      .map((elm) => ({
        ...elm,
        statementsOptions: (elm.statementsOptions || []).filter((s) => s.planId === statement.plan.id),
      }))
      .filter((elm) => elm.statementsOptions.length);
    switch (userSwitchRightAccess) {
      case SubsetAccessEnum.EVERYTHING:
        return users;
      default:
        // Only return users that current user has access to
        return users.filter((user) => authenticatedContext.iam.scope?.myManagees.includes(user.id || ''));
    }
  }, [authenticatedContext, userSwitchRightAccess, statement, userStatementsListFacet]);

  const hasSeveralUsers = useMemo(
    () => !(usersCanSeeForThisPlan?.length === 1 && usersCanSeeForThisPlan[0].id === authenticatedContext.user.id),
    [authenticatedContext.user.id, usersCanSeeForThisPlan],
  );

  // SEARCH USERS CONTROL
  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value), []);

  const usersFiltered = useMemo(() => {
    if (!search) {
      return usersCanSeeForThisPlan;
    }

    return usersCanSeeForThisPlan.filter((user) =>
      `${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`.includes(search.toLowerCase()),
    );
  }, [usersCanSeeForThisPlan, search]);

  const onChangeUser = useCallback(
    (userClicked: UserComputed) => {
      const user = (userStatementsListFacet.users || []).find((elm) => elm.id === userClicked.id);
      if (user?.statementsOptions?.length) {
        const userStatementWithSamePlan = user.statementsOptions.find((elm) => elm?.planId === statement.plan.id);
        history.push(
          generatePath(routes.STATEMENT, {
            id: userStatementWithSamePlan?.statementId || user.statementsOptions[0].statementId,
          }),
        );
      }
    },
    [history, userStatementsListFacet, statement.plan.id],
  );

  return (
    <Breadcrumbs
      back={
        userViewListRightAccess && statement ? (
          <Link
            to={generatePath(statement.isForecastedView ? routes.FORECASTS_BY_DATE : routes.STATEMENTS_BY_DATE, {
              startDate: formatPeriodMarkerDate(statement.period),
            })}
          >
            <Breadcrumbs.BackButton label={<FormattedMessage {...messages.GO_BACK} />} />
          </Link>
        ) : null
      }
    >
      <StatementPeriodSelector
        frequency={statement.plan?.frequency}
        isForecast={statement.isForecastedView}
        period={statement.period}
        plan={statement.plan}
        user={statement.user}
      />
      {proposedPlans.length ? (
        <Breadcrumbs.SelectItem
          popoverContent={
            <List
              dense
              css={css`
                max-height: 300px;
                overflow-y: scroll;
                width: max-content;
              `}
            >
              {proposedPlans.map((planOption) => (
                <ListItem
                  key={`selectTitle_${planOption.label}`}
                  css={css`
                    cursor: pointer;
                  `}
                  onClick={() => onChangePlan(planOption)}
                >
                  <ListItemText primary={planOption.label} />
                </ListItem>
              ))}
            </List>
          }
        >
          <Fragment>{statement?.plan.name || <FormattedMessage {...messages.PLAN_NAME} />}</Fragment>
        </Breadcrumbs.SelectItem>
      ) : (
        <Breadcrumbs.TextItem>
          <Fragment>{statement?.plan.name || <FormattedMessage {...messages.PLAN_NAME} />}</Fragment>
        </Breadcrumbs.TextItem>
      )}
      {hasSeveralUsers ? (
        <Breadcrumbs.SelectItem
          popoverContent={
            <List
              dense
              css={css`
                max-height: 300px;
                overflow-y: scroll;
                width: max-content;
              `}
            >
              <Input
                autoFocus
                placeholder={formatMessage(COMMON_MESSAGES.SEARCH)}
                startAdornment={<IconSearch />}
                value={search}
                endAdornment={
                  search ? (
                    <InputAdornment position="end">
                      <IconButton
                        icon={<IconX />}
                        label={formatMessage(messages.CLEAR_SEARCH)}
                        onClick={() => setSearch('')}
                      />
                    </InputAdornment>
                  ) : null
                }
                onChange={onSearch}
              />
              {usersFiltered.map((userOption, idx) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`selectTitle_${userOption.lastName}_${idx}`}
                  css={css`
                    cursor: pointer;
                  `}
                  onClick={() => onChangeUser(userOption)}
                >
                  <ListItemText primary={formatUserFullName(userOption)} />
                </ListItem>
              ))}
            </List>
          }
        >
          <Fragment>{formatUserFullName(statement.user)}</Fragment>
        </Breadcrumbs.SelectItem>
      ) : (
        <Breadcrumbs.TextItem>
          <Fragment>{formatUserFullName(statement.user)}</Fragment>
        </Breadcrumbs.TextItem>
      )}
    </Breadcrumbs>
  );
};

export const StatementDetailHeaderBreadcrumbs = memo(StatementDetailHeaderBreadcrumbsBase);
