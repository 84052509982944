import { type Theme, css } from '@emotion/react';

export const container = css`
  height: 100px;
  width: 100%;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const header = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/** @TODO: Remove the visibility hidden when we'll have the actions */
export const actions = css`
  visibility: hidden;
  display: flex;
  gap: 4px;
`;

export const content = css`
  flex: 1;
  overflow-wrap: break-word;
`;

export const date = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[700]};
`;

export const mention = (theme: Theme) => css`
  color: ${theme.ds.colors.secondary[500]};
  background: ${theme.ds.colors.secondary[50]};
`;
