import { Accordion, AccordionDetails } from '@mui/material';
import { type ReactNode, memo } from 'react';

import { type MainKpi } from '@amal-ia/lib-types';

import { RuleSummary } from './RuleSummary';
import { useRuleAccordionStyles } from './useRuleAccordionStyles';

export interface RuleAccordionProps {
  label: ReactNode;
  helpLabel?: string;
  machineName: string;
  mainKpi: MainKpi;
  overwriteMainKpiFormattedValue?: string;
  onClick?: (event: any) => void;
  className?: string;
  icon?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
  isExpanded: boolean;
  toggleExpanded: () => void;
}

export const RuleAccordion = memo(function RuleAccordion({
  label,
  helpLabel,
  icon,
  machineName,
  mainKpi,
  overwriteMainKpiFormattedValue,
  children,
  onClick,
  className,
  actions,
  isExpanded,
  toggleExpanded,
}: RuleAccordionProps) {
  const classes = useRuleAccordionStyles();

  return (
    <Accordion
      className={className}
      elevation={0}
      expanded={isExpanded}
      TransitionProps={{ unmountOnExit: true }}
    >
      <RuleSummary
        actions={actions}
        data-testid={machineName}
        helpLabel={helpLabel}
        icon={icon}
        label={label}
        machineName={machineName}
        mainKpi={mainKpi}
        overwriteMainKpiFormattedValue={overwriteMainKpiFormattedValue}
        onClick={onClick}
        onClickToggleRuleAccordion={toggleExpanded}
      />
      <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
});
