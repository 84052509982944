import { http } from '@amal-ia/frontend/kernel/http';
import { type Variable, type VariableObjectsEnum, type PaginationStoredInRedux } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export class VariablesApiClient {
  public static async list(types?: VariableObjectsEnum[], userIds?: string[], planIds?: string[]): Promise<Variable[]> {
    const qs = [
      ...(types ? types.map((t) => `types=${t}`) : []),
      ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
      ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
    ]
      .filter(Boolean)
      .join('&');

    const { data } = await http.get<Variable[]>(`/variables/?${qs}`);
    return data;
  }

  public static async get(variableId: string): Promise<Variable> {
    const { data } = await http.get<Variable>(`/variables/${variableId}`);
    return data;
  }

  public static async create(
    variable: Omit<Variable, 'id'>,
    createdFrom?: { ruleId?: string; planId?: string; activeFilterId?: string },
  ): Promise<Variable> {
    const { data } = await http.post<Variable>('/variables', variable, {
      params: {
        planId: createdFrom?.planId,
        ruleId: createdFrom?.ruleId,
        activeFilterId: createdFrom?.activeFilterId,
      },
    });
    return data;
  }

  public static async update(variable: Variable, updatedFrom?: { planId?: string }): Promise<Variable> {
    const { data } = await http.put<Variable>('/variables/', variable, {
      params: {
        planId: updatedFrom?.planId,
      },
    });
    return data;
  }

  public static async delete(variableId: string, planId?: string): Promise<void> {
    await http.delete(`/variables/${variableId}`, {
      params: {
        planId,
      },
    });
  }

  public static async getUsersAssigned(
    types: VariableObjectsEnum[],
    planIds?: string[],
    searchText?: string,
    page = 0,
    limit = 10,
    sortOrder = 'asc',
  ): Promise<PaginationStoredInRedux<UserContract>> {
    const { data } = await http.get<PaginationStoredInRedux<UserContract>>('/variables/byusers', {
      params: {
        types,
        planIds,
        page,
        limit,
        q: searchText,
        sortOrder,
      },
    });
    return data;
  }

  public static async duplicateInNewContext(
    variablesId: string[],
    from: { planId: string; ruleId: string; activeFilterId?: string },
  ): Promise<{ isErrorWhileDuplicating: boolean }> {
    const { data } = await http.post<{ isErrorWhileDuplicating: boolean }>(
      '/variables/duplicateInContext',
      variablesId,
      {
        params: {
          planId: from.planId,
          ruleId: from.ruleId,
          activeFilterId: from.activeFilterId,
        },
      },
    );
    return data;
  }
}
