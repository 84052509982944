import { type ReactNode, memo } from 'react';

import * as styles from './PageHeaderRow.styles';

export type PageHeaderRowProps = {
  left?: ReactNode;
  right?: ReactNode;
};

export const PageHeaderRow = memo(function PageHeaderRow({ left, right }: PageHeaderRowProps) {
  return (
    <div css={styles.rowContainer}>
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
});
