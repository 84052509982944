import { css } from '@emotion/react';
import { IconSearch, IconTrash } from '@tabler/icons-react';
import { capitalize } from 'lodash';
import { Fragment, memo, type MouseEventHandler, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { VENDOR_LOGOS } from '@amal-ia/data-capture/connectors/assets';
import { type CustomObjectDefinitionType } from '@amal-ia/data-capture/models/types';
import { type FilterOverwriteRemoveCreationRequestDetails } from '@amal-ia/data-capture/overwrites/components';
import { type Asset } from '@amal-ia/ext/react/types';
import { IconButton, IconButtonVariant } from '@amal-ia/frontend/design-system/components';
import { useOpenInNewTab } from '@amal-ia/lib-ui';

import { rowsTableMessages } from './RowsTable.messages';

interface RowsTableCellActionsProps {
  openDealTracing: () => void;
  url?: string;
  connector?: CustomObjectDefinitionType;
  openFilterOverwriteRemoveModal?: (details: FilterOverwriteRemoveCreationRequestDetails) => void;
  clearOverwrite: MouseEventHandler<HTMLButtonElement>;
  details: FilterOverwriteRemoveCreationRequestDetails;
}

const RowsTableCellActions = memo(function RowsTableCellLeftActions({
  openDealTracing,
  url,
  connector,
  openFilterOverwriteRemoveModal,
  clearOverwrite,
  details,
}: RowsTableCellActionsProps) {
  const { formatMessage } = useIntl();

  const openInNewTab = useOpenInNewTab();

  const openDealTracingProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e?) => {
      e?.stopPropagation();
      openDealTracing();
    },
    [openDealTracing],
  );

  const goToDeal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e?) => {
      e?.stopPropagation();

      if (url) {
        openInNewTab(url);
      }
    },
    [url, openInNewTab],
  );

  const Logo = connector && (VENDOR_LOGOS[connector?.toLowerCase()]?.CompactLogo as Asset);

  const openFilterOverwriteRemoveProxy: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e?) => {
      e?.stopPropagation();
      if (openFilterOverwriteRemoveModal) {
        openFilterOverwriteRemoveModal(details);
      }
    },
    [openFilterOverwriteRemoveModal, details],
  );

  return (
    <div
      css={(theme) => css`
        display: flex;
        background-color: ${theme.ds.colors.gray[0]};
        gap: 6px;
      `}
    >
      {details.isAdditionalRow ? (
        <IconButton
          withBackground
          icon={<IconTrash />}
          label={formatMessage(rowsTableMessages.REMOVE_THIS_SIMULATION_LINE)}
          onClick={clearOverwrite}
        />
      ) : (
        <Fragment>
          {url && connector && Logo ? (
            <IconButton
              withBackground
              label={formatMessage(rowsTableMessages.OPEN_DEAL, { connector: capitalize(connector) })}
              icon={
                <div
                  css={css`
                    --icon-size: 20px;

                    width: var(--icon-size);
                    height: var(--icon-size);
                  `}
                >
                  <Logo />
                </div>
              }
              onClick={goToDeal}
            />
          ) : null}
          <IconButton
            withBackground
            icon={<IconSearch />}
            label={formatMessage(rowsTableMessages.OPEN_TRACING)}
            onClick={openDealTracingProxy}
          />
          {!!openFilterOverwriteRemoveModal && (
            <IconButton
              withBackground
              icon={<IconTrash />}
              label={formatMessage(rowsTableMessages.REMOVE_THIS_LINE_VIA_OVERWRITE)}
              variant={IconButtonVariant.DANGER}
              onClick={openFilterOverwriteRemoveProxy}
            />
          )}
        </Fragment>
      )}
    </div>
  );
});

export default RowsTableCellActions;
