import { VariableObjectsEnum } from '@amal-ia/lib-types';

export const getObjectLabel = (objectCategory: string, variableType?: VariableObjectsEnum): string => {
  if (objectCategory === 'variable') {
    switch (variableType) {
      case VariableObjectsEnum.plan:
      case VariableObjectsEnum.team:
      case VariableObjectsEnum.user:
        return 'quota';
      case VariableObjectsEnum.object:
        return 'field';
      default:
        return objectCategory;
    }
  }
  return objectCategory;
};

export const getObjectClassName = (objectCategory: string, variableType?: VariableObjectsEnum): string | undefined => {
  if (objectCategory === 'variable') {
    switch (variableType) {
      case VariableObjectsEnum.plan:
      case VariableObjectsEnum.team:
      case VariableObjectsEnum.user:
        return 'quota';
      default:
        return variableType;
    }
  }

  const defaultValue = 'record';
  const allowedValues = ['rule'];
  const objectCategoryOverride: Record<string, string> = {
    property: 'object',
  } as const;

  const overridedValue = objectCategoryOverride[objectCategory];
  if (overridedValue) return overridedValue;

  if (allowedValues.includes(objectCategory)) return objectCategory;

  return defaultValue;
};
