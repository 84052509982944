import { Alert, type AlertColor } from '@mui/material';
import { withStyles } from '@mui/styles';
import { memo, useMemo } from 'react';

import { colors } from '@amal-ia/ext/mui/theme';
import { type StatementError } from '@amal-ia/lib-types';

interface StatementErrorBannerProps {
  statementError: StatementError;
}

const CustomAlert = withStyles((theme) => ({
  root: {
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
    color: theme.palette.grey[700],
  },
  standardWarning: {
    backgroundColor: colors['orange-100'],
  },
}))(Alert);

export const StatementErrorBanner = memo(function StatementErrorBanner({ statementError }: StatementErrorBannerProps) {
  const errorMessage = useMemo(() => {
    if (statementError?.context?.variableName) {
      if (statementError.message.includes(statementError.context.variableName)) {
        return statementError.message.split(statementError.context.variableName).map((sentence, idx) => {
          if (idx === 0) {
            return [sentence, <b key={idx}>{statementError.context.variableName}</b>];
          }
          return [sentence];
        });
      }
    }
    return statementError.message;
  }, [statementError.context, statementError.message]);
  return <CustomAlert severity={statementError.level.toLowerCase() as AlertColor}>{errorMessage}</CustomAlert>;
});
