import { unionBy } from 'lodash';
import { combineReducers } from 'redux';

import {
  type PlanOption,
  type Statistics,
  type TeamOption,
  type UserStatements,
  type UserStatementsFacets,
  INITIAL_REDUX_PAGINATION,
  type PaginationStoredInRedux,
} from '@amal-ia/lib-types';

import { PLANS_ACTIONS as PLAN_ACTIONS } from '../plans/constants';

import { USERS_STATEMENTS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action): number => {
    switch (action.type) {
      case USERS_STATEMENTS_ACTIONS.START:
        return state + 1;
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_PAGE_SUCCESS:
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_FACETS_SUCCESS:
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_LIST_SUCCESS:
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_STATISTICS_SUCCESS:
      case USERS_STATEMENTS_ACTIONS.MASS_REVIEW_STATEMENTS:
      case USERS_STATEMENTS_ACTIONS.ERROR:
        return state - 1;
      default:
        return state;
    }
  },
  lastparams: (state: Record<string, any> = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
      case USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS:
        return {};
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_LIST_SUCCESS:
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_PAGE_SUCCESS:
        return action.payload.options;
      default:
        return state;
    }
  },
  currentPage: (state: PaginationStoredInRedux<UserStatements> = INITIAL_REDUX_PAGINATION, action) => {
    switch (action.type) {
      case USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS:
        return {
          ...state,
          currentPage: 0,
          items: [],
          totalItems: 0,
        };
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_PAGE_SUCCESS:
        return {
          ...state,
          currentPage: action.payload.userStatementsPage.currentPage || 0,
          items: action.payload.userStatementsPage.userStatements,
          totalItems: action.payload.userStatementsPage.total,
        };
      default:
        return state;
    }
  },
  userStatementsList: (state: UserStatements[] = [], action) => {
    switch (action.type) {
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_PAGE_SUCCESS:
        return unionBy(state, action.payload.userStatementsPage.userStatements, 'user.id');
      case USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS:
        return [];
      default:
        return state;
    }
  },
  userStatementsListFacet: (state: UserStatementsFacets = { users: [], plans: [] }, action) => {
    switch (action.type) {
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_FACETS_SUCCESS:
        return action.payload.facets;
      case USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS:
      case USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS_FACETS:
        return {
          users: [],
          plans: [],
          teams: [],
          workflow: undefined,
        };
      default:
        return state;
    }
  },
  userStatementsListStatistics: (state: Statistics = { definitions: {}, records: [] }, action) => {
    switch (action.type) {
      case USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_STATISTICS_SUCCESS:
        return action.payload.statistics;
      case USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS:
        return null;
      default:
        return state;
    }
  },
  listSelectedOptions: (state: { plan: PlanOption; team: TeamOption } | null = null, action) => {
    switch (action.type) {
      case USERS_STATEMENTS_ACTIONS.CHANGE_LIST_SELECTED_OPTIONS:
        return {
          planId: '',
          teamId: '',
          ...state,
          ...action.payload.patch,
        };
      default:
        return state;
    }
  },
});
