import { AppBar, Tabs, Toolbar } from '@mui/material';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@amal-ia/frontend/design-system/components';

import { AppbarNavItem, type AppbarNavItemProps } from './AppbarNavItem';
import { useStylesSecondMenu } from './useStylesAppbar';

interface MenuAppSecondBarProps {
  navigationItems?: (AppbarNavItemProps & { value?: string })[];
  actions?: AppbarNavItemProps[];
  currentSubPage?: string;
}

export const MenuAppSecondBar = memo(function MenuAppSecondBar({
  navigationItems = [],
  actions = [],
  currentSubPage,
}: MenuAppSecondBarProps) {
  const history = useHistory();
  const classes = useStylesSecondMenu();

  const navItemIndex = Math.max(0, navigationItems?.findIndex(({ value }) => value === currentSubPage));
  const handleChange = (_, newValue: number): void => {
    if (navigationItems?.[newValue]) {
      history.push(navigationItems[newValue].href);
    }
  };

  return navigationItems?.length || actions?.length ? (
    <AppBar
      className={classes.menu}
      color="transparent"
      position="static"
    >
      <Toolbar>
        <Tabs
          className={classes.title}
          scrollButtons="auto"
          value={navItemIndex}
          variant="scrollable"
          classes={{
            root: classes.tabsRoot,
          }}
          onChange={handleChange}
        >
          {navigationItems?.map(({ href, label }) => (
            <AppbarNavItem
              key={label}
              href={href}
              id={label}
              label={label}
            />
          ))}
        </Tabs>

        {actions?.map(({ href, label }) => (
          <Button
            key={label}
            variant={Button.Variant.SECONDARY}
            onClick={() => {
              history.push(href);
            }}
          >
            {label}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  ) : null;
});
