import { memo } from 'react';

import { Skeleton } from '../../../../feedback/skeleton/Skeleton';
import { Checkbox } from '../../../../forms/checkbox/Checkbox';
import { type UseTableMultiSelectionValue } from '../../hooks/useTableMultiSelection';
import { useTableContext } from '../../Table.context';
import { type RowData, type ColumnDefinitions, type RowKey } from '../../Table.types';
import { TableHeaderCell } from '../table-header-cell/TableHeaderCell';
import { TableHeaderRow } from '../table-header-row/TableHeaderRow';

const SKELETON_PLACEHOLDER = 'Lorem';

export type TableHeaderProps<TData extends RowData, TKey extends RowKey> = Pick<
  UseTableMultiSelectionValue<TKey>,
  | 'handleSelectAllRows'
  | 'hasAllRowsSelected'
  | 'hasSomeRowsSelected'
  | 'isSelectionActive'
  | 'isSelectionEnabled'
  | 'selectionColumnRef'
  | 'selectionColumnWidth'
  | 'setIsSelectionActive'
> & {
  columns: ColumnDefinitions<TData>;
};

const TableHeaderBase = function TableHeader<TData extends RowData, TKey extends RowKey>({
  columns,
  isSelectionEnabled,
  isSelectionActive,
  setIsSelectionActive,
  selectionColumnRef,
  hasAllRowsSelected,
  hasSomeRowsSelected,
  selectionColumnWidth,
  handleSelectAllRows,
}: TableHeaderProps<TData, TKey>) {
  const { isLoading, pinFirstColumn } = useTableContext();

  return (
    <thead>
      <TableHeaderRow index={0}>
        {!!isSelectionEnabled && !!isSelectionActive && (
          <TableHeaderCell
            ref={selectionColumnRef}
            isSticky={!!pinFirstColumn && !isLoading}
            size={0}
            stickyLeftOffset={0}
          >
            <Checkbox
              checked={hasAllRowsSelected}
              indeterminate={!!hasSomeRowsSelected && !hasAllRowsSelected}
              size={Checkbox.Size.SMALL}
              onChange={handleSelectAllRows}
            />
          </TableHeaderCell>
        )}

        {columns.map((column, index) => (
          <TableHeaderCell
            key={column.id}
            actions={!isLoading ? column.actions : undefined}
            backgroundColor={column.headerBackgroundColor}
            icon={!isLoading ? column.icon : undefined}
            isSelectionActive={isSelectionActive}
            isSelectionEnabled={!!isSelectionEnabled && index === 0}
            isSticky={index === 0 && pinFirstColumn}
            size={column.size}
            stickyLeftOffset={selectionColumnWidth}
            subTitle={!isLoading ? column.subTitle : undefined}
            tooltip={!isLoading ? column.tooltip : undefined}
            onChangeIsSelectionActive={setIsSelectionActive}
          >
            {isLoading ? <Skeleton shape={Skeleton.Shape.ROUND}>{SKELETON_PLACEHOLDER}</Skeleton> : column.header}
          </TableHeaderCell>
        ))}
      </TableHeaderRow>
    </thead>
  );
};

export const TableHeader = memo(TableHeaderBase) as typeof TableHeaderBase;
