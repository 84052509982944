import { Box, Checkbox as MuiCheckbox, type CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { TextType, Text } from '../../typography';
import { InputLabel } from '../inputs/Input/Input';

export interface CheckboxProps extends Omit<MuiCheckboxProps, 'color'> {
  color: 'primary' | 'secondary' | 'tertiary';
  compact?: boolean;
  label?: string;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  compact: {
    padding: theme.spacing(0.75, 0.75, 0.75, 0),
  },
  inputLabel: {
    cursor: 'pointer',
  },
}));

const TertiaryCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
  },
}))((props: MuiCheckboxProps) => (
  <MuiCheckbox
    color="default"
    {...props}
  />
));

export const Checkbox = memo(function Checkbox({ color, className, compact, label, ...props }: CheckboxProps) {
  const classes = useStyles();
  const uniqueIdentifier = useMemo(() => props?.id || uniqueId(), [props]);

  const computedClassName = clsx(className, compact && classes.compact);

  return (
    <Box className={classes.root}>
      {color === 'tertiary' ? (
        <TertiaryCheckbox
          className={computedClassName}
          {...props}
          id={uniqueIdentifier}
        />
      ) : (
        <MuiCheckbox
          className={computedClassName}
          color={color}
          {...props}
          id={uniqueIdentifier}
        />
      )}
      {label ? (
        <InputLabel
          compact
          className={classes.inputLabel}
          htmlFor={uniqueIdentifier}
        >
          <Text type={TextType.INPUT_NAME}>{label}</Text>
        </InputLabel>
      ) : null}
    </Box>
  );
});
