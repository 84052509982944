export enum BadgeConfigurationType {
  RANKING = 'RANKING',
  KPI = 'KPI',
}

export enum BadgeConfigurationIcon {
  BEST_DEAL = 'BEST_DEAL',
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  INTERNATIONAL = 'INTERNATIONAL',
  OVER_PERFORMER = 'OVER_PERFORMER',
  STAR = 'STAR',
  TEAM_PLAYER = 'TEAM_PLAYER',
  WINNER = 'WINNER',
  WIN_RATE = 'WIN_RATE',
}

export interface BadgeConfiguration {
  id?: string;

  name: string;
  description?: string;

  type: BadgeConfigurationType;
  icon: BadgeConfigurationIcon;

  planId: string;

  variableId?: string;

  challengeId?: string;

  deletedAt?: Date;
}

export interface BadgeAward {
  id?: string;

  userId: string;

  periodId: string;

  statementId: string;

  configuration: BadgeConfiguration;
  configurationId: string;
}
