import { Box } from '@mui/material';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  setPaymentCategory,
  selectCurrentPaymentCategory,
  selectStatementPaymentAmountByCategory,
  useThunkDispatch,
  showMultiPayouts,
  useFeatureFlag,
} from '@amal-ia/frontend/web-data-layers';
import { type PaymentCategory } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { MultiPayout, type StatementHistogramItem } from '@amal-ia/lib-ui-business';
import { CompanyFeatureFlags } from '@amal-ia/tenants/shared/types';

import { getTotalFromVariable } from './StatementDetailHeaderPayout.utils';
import StatementDetailHeaderPayoutQuotaError from './StatementDetailHeaderPayoutQuotaError';

interface StatementDetailHeaderPayoutProps {
  statementStatistics: StatementHistogramItem[];
}

const StatementDetailHeaderPayout = memo(function StatementDetailHeaderPayout({
  statementStatistics,
}: StatementDetailHeaderPayoutProps) {
  const dispatch = useThunkDispatch();

  const { isFeatureEnabled: isStatementEvolutionEnabled } = useFeatureFlag(CompanyFeatureFlags.STATEMENT_EVOLUTION);
  const statement = useStatementDetailContext();

  const currentPaymentCategory = useSelector(selectCurrentPaymentCategory);
  const paymentTotalByType = useSelector(selectStatementPaymentAmountByCategory);
  const selectPaymentCategory = useCallback(
    (category: PaymentCategory) => dispatch(setPaymentCategory(category)),
    [dispatch],
  );

  const { previousAmount, nextAmount } = useMemo(() => {
    const noTrend: { previousAmount: number | undefined; nextAmount: number | undefined } = {
      previousAmount: undefined,
      nextAmount: undefined,
    };

    // We need at least 2 statement statistics to show a trend
    if (!statementStatistics || statementStatistics.length < 2) return noTrend;

    // Try to find the current period from the statement statistics using the statementId
    const currentPeriodIndex = statementStatistics.findIndex((statistic) => statistic.id === statement.id);

    // If we can't find the current period or it's the first period, we can't show a trend
    if (currentPeriodIndex < 1) return noTrend;

    const pAmount = statementStatistics[currentPeriodIndex - 1].total;
    const nAmount = statementStatistics[currentPeriodIndex].total;

    // If previousAmount is equal to 0, we can't show a trend
    if (pAmount === 0) return noTrend;

    return { previousAmount: pAmount, nextAmount: nAmount };
  }, [statementStatistics, statement.id]);

  // We show Hold & release headers if:
  // - we find a HnR rule in the statement
  // - OR we have some hold payments
  // - OR the total achievement is different than what has already been paid
  const showHoldAndRelease = useMemo(
    () => showMultiPayouts(statement, paymentTotalByType),
    [paymentTotalByType, statement],
  );

  // If plan has been configured to display a variable result instead of total commission,
  // we need to retrieve total variable result.
  const totalFromVariable = useMemo(() => getTotalFromVariable(statement), [statement]);

  return (
    <Box
      justifyContent="center"
      justifyItems="center"
      textAlign="center"
    >
      {!statement.error ? (
        <MultiPayout
          currencyRate={statement?.rate}
          currencySymbol={statement?.currency}
          hideHoldAndRelease={!showHoldAndRelease}
          isStatementEvolutionEnabled={isStatementEvolutionEnabled}
          setView={selectPaymentCategory}
          total={totalFromVariable}
          trendNextValue={nextAmount}
          trendPreviousValue={previousAmount}
          values={paymentTotalByType}
          view={currentPaymentCategory}
        />
      ) : (
        <StatementDetailHeaderPayoutQuotaError />
      )}
    </Box>
  );
});

export default StatementDetailHeaderPayout;
