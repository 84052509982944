import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('TIER', AmaliaFunctionCategory.INDICES);

func.nbParamsRequired = 2;

func.description = 'Compare a parameter to tiers in a table and return the corresponding value';

func.params = [
  { name: 'parameter', description: 'Variables, fields, properties etc.' },
  { name: 'table', description: 'Table containing (min and max) and return values.' },
];

func.examples = [
  {
    desc: 'Returns 0.4',
    formula: 'TIER(0.5548, [[0, 0.3, 0],[0.3, 0.5, 0.1],[0.5, 0.6, 0.4],[0.6, 0.7, 0.7]])' as AmaliaFormula,
    result: 0.4,
  },
  {
    desc: 'Returns the value from the 3rd column of the corresponding tier in the Payout Grid table based on target reach.',
    formula: 'TIER(statement.targetReach, statement.payoutGrid)' as AmaliaFormula,
  },
];

func.exec = (tierToCompare: number, table: [number, number, number][]): number => {
  let toReturn = 0;
  for (const tier of table) {
    if (tier[1] > tierToCompare) {
      toReturn = tier[2];
      break;
    }
  }
  return toReturn;
};

export default func;
