import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import { type CalculationScope } from '../../../CalculationParser';
import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('IF', AmaliaFunctionCategory.MISC);

func.nbParamsRequired = 3;

func.description = 'Return an argument if a condition is met, otherwise, return a different one';

func.params = [
  { name: 'condition', description: 'Boolean condition (returns 1 if true or 0 if false).' },
  { name: 'trueArgument', description: 'Argument to return if the condition is true.' },
  { name: 'falseArgument', description: 'Argument to return if the condition is false.' },
];

func.examples = [
  {
    desc: 'If the close date is after the start date of the period, returns amount, else returns 0.',
    formula: 'IF(opportunity.closeDate >= statementPeriod.startDate, opportunity.amount, 0)' as AmaliaFormula,
  },
  {
    desc: 'If statement.achievement / statement.target is greater than 1, returns accelerator, else returns 0.',
    formula: 'IF(statement.achievement / statement.target > 1, statement.accelerator, 0)' as AmaliaFormula,
  },
  {
    desc: 'If opportunity type if renewal or new business, returns amount, else returns amount * 0.5.',
    formula:
      'IF(opportunityType="Renewal" or opportunityType="New Business", opportunity.amount, opportunity.amount * 0.5)' as AmaliaFormula,
  },
  {
    desc: 'If oppToLineItemLink exists, returns amount, else returns 0.',
    formula: 'IF(opportunity.oppToLineItemLink, opportunity.oppToLineItemLink.amount, 0)' as AmaliaFormula,
  },
];

func.generateComputedFunctionResult = (args) => ({
  condition: args[0].toString() as AmaliaFormula,
});

/* istanbul ignore file */
func.execMock = (): any => 1;

func.execRawArgs = (args: any, _, scope: CalculationScope) => {
  const conditionValue = args[0].compile().evaluate(scope);

  if (conditionValue) {
    return args[1].compile().evaluate(scope);
  }

  return args[2].compile().evaluate(scope);
};

export default func;
