import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaThemeInstance } from '@amal-ia/ext/mui/theme';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';
import { CustomReportsPresetsEnum, formatCurrencyAmount } from '@amal-ia/lib-types';
import { Trend } from '@amal-ia/lib-ui';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';
import { WidgetTitle } from '../WidgetTitle/WidgetTitle';

import { earningsCardMessages } from './EarningsCard.messages';
import { EarningsCardCurrency } from './EarningsCardCurrency';

const useStyles = makeStyles({
  amount: {
    textTransform: 'uppercase',
  },
  trend: {
    borderRadius: '8px',
    height: '16px',
    marginLeft: '10px',
    marginTop: '13px',
    padding: '2px',
    width: '80px',
  },
  trendClass: {
    width: '80px',
  },
});

type EarningsCardProps = {
  period: string;
  currentPeriodEarning: CurrencyValue;
  previousPeriodEarning?: CurrencyValue;
  user: Partial<UserContract>;
};

export const EarningsCards = memo(function EarningsCards({
  period,
  currentPeriodEarning,
  previousPeriodEarning,
  user,
}: EarningsCardProps) {
  const classes = useStyles();
  return (
    <DashboardWidget>
      <WidgetTitle
        customReportId={CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME}
        title={<EarningsCardCurrency symbol={currentPeriodEarning?.symbol || user.currency} />}
        userRole={user.role}
      />

      <div>
        <Typography
          id="earnings-widget-period"
          variant={TypographyVariant.BODY_BASE_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[600]};
            padding-top: 4px;
          `}
        >
          <FormattedMessage
            {...earningsCardMessages.EARNED_IN_PERIOD}
            values={{ period }}
          />
        </Typography>
        {!!currentPeriodEarning && (
          <Box
            display="flex"
            flexDirection="row"
            height="40px"
            id="earnings-widget-value"
          >
            <Typography variant={TypographyVariant.HEADING_3_BOLD}>
              {formatCurrencyAmount({
                amount: currentPeriodEarning.value,
                currencySymbol: currentPeriodEarning.symbol,
              })}
            </Typography>
            <Box
              bgcolor={amaliaThemeInstance.palette.grey[100]}
              className={classes.trend}
              display="flex"
              flexDirection="row"
              width="45px"
            >
              <Trend
                smallIcons
                className={classes.trendClass}
                nextValue={currentPeriodEarning.value}
                previousValue={previousPeriodEarning.value}
              />
            </Box>
          </Box>
        )}
      </div>
    </DashboardWidget>
  );
});
