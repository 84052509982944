import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type UserExternalIdSource } from '@amal-ia/tenants/users/shared/types';

import { userExternalIdSourceLabels } from './UserExternalIdSourceLabel.messages';

export type UserExternalIdSourceLabelProps = {
  externalIdSource: UserExternalIdSource;
};

export const UserExternalIdSourceLabel = memo(function UserExternalIdSourceLabel({
  externalIdSource,
}: UserExternalIdSourceLabelProps) {
  const message = userExternalIdSourceLabels[externalIdSource];

  return <FormattedMessage {...message} />;
});
