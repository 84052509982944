import clsx from 'clsx';
import { type ReactNode, memo, useMemo, forwardRef, type ForwardedRef } from 'react';

import { VENDOR_LOGOS } from '@amal-ia/data-capture/connectors/assets';
import { CustomObjectDefToDataConnectorType, CustomObjectDefinitionType } from '@amal-ia/data-capture/models/types';
import { TextOverflow, Typography, UnstyledButton } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { IconAmaliaLogoColoured } from '@amal-ia/frontend/ui-icons';

import { datasetButton, datasetInnerButton } from './DatasetButton.styles';
import { DatasetButtonVariant } from './DatasetButton.types';

type DatasetButtonProps = Readonly<{
  label: string;
  isActive?: boolean;
  onClick: () => void;
  variant?: DatasetButtonVariant;
  customObjectDefinitionType?: CustomObjectDefinitionType;
  isForecasted?: boolean;
  children?: ReactNode;
  dragHandle?: ReactNode;
  className?: string;
  dataTestId?: string;
}>;

export const DatasetButtonBase = forwardRef(function DatasetButton(
  {
    label,
    isActive,
    onClick,
    variant = DatasetButtonVariant.STATEMENT,
    customObjectDefinitionType = CustomObjectDefinitionType.AMALIA,
    isForecasted = false,
    dragHandle,
    children,
    className,
    dataTestId,
  }: DatasetButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const Logo = useMemo(() => {
    const logoType = CustomObjectDefToDataConnectorType[customObjectDefinitionType];
    return VENDOR_LOGOS?.[logoType]?.CompactLogo;
  }, [customObjectDefinitionType]);

  return (
    <UnstyledButton
      ref={ref}
      className={clsx(variant, className, isActive && 'active', isForecasted && 'forecasted')}
      css={[datasetButton].filter(Boolean)}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <div css={datasetInnerButton}>
        {dragHandle}
        {Logo ? <Logo height={18} /> : <IconAmaliaLogoColoured height={18} />}
        <Typography
          as={TextOverflow}
          variant={isActive ? TypographyVariant.BODY_BASE_MEDIUM : TypographyVariant.BODY_BASE_REGULAR}
        >
          {label}
        </Typography>
        {children}
      </div>
    </UnstyledButton>
  );
});

export const DatasetButton = memo(DatasetButtonBase);
