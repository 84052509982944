import { Auth0Provider, type Auth0ProviderOptions } from '@auth0/auth0-react';
import { StyledEngineProvider, type Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, generatePath, Route, Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { IFrameAuth, InitiatedSSOAuth } from '@amal-ia/frontend/connected-components/auth';
import { DesignSystemProvider } from '@amal-ia/frontend/design-system/components';
import { DEFAULT_THEME } from '@amal-ia/frontend/design-system/themes/default';
import { config } from '@amal-ia/frontend/kernel/config';
import { queryClient } from '@amal-ia/frontend/kernel/query/client';
import { store } from '@amal-ia/frontend/web-data-layers';
import { StoreProvider } from '@amal-ia/lib-ui';

import App from './App';
import { AppProviders } from './AppProviders';
import { WebLocalizationProvider } from './components/WebLocalizationProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const shouldSkipRedirectCallback = [
  generatePath(routes.AUTH_CONNECTOR_CALLBACK, { connector: 'netsuite' }),
  generatePath(routes.AUTH_CONNECTOR_CALLBACK, { connector: 'zoho' }),
].includes(window.location.pathname);

const auth0AuthorizationParams: Auth0ProviderOptions['authorizationParams'] = {
  audience: config.auth0.audience,
  scope: config.auth0.scope,
  redirect_uri: config.auth0.redirectUri,
};

const AuthenticatedApp = function AuthenticatedApp() {
  return (
    <AppProviders>
      <App />
    </AppProviders>
  );
};

createRoot(document.getElementById('root')).render(
  <Auth0Provider
    useRefreshTokens
    useRefreshTokensFallback
    authorizationParams={auth0AuthorizationParams}
    clientId={config.auth0.clientId}
    domain={config.auth0.domain}
    skipRedirectCallback={shouldSkipRedirectCallback}
    useFormData={false}
  >
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <WebLocalizationProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={amaliaTheme}>
              <DesignSystemProvider theme={DEFAULT_THEME}>
                <CssBaseline />
                <BrowserRouter>
                  <Switch>
                    <Route
                      component={IFrameAuth}
                      path={routes.AUTH_IFRAME}
                    />
                    <Route
                      component={InitiatedSSOAuth}
                      path={routes.AUTH_SSO_INITIATED}
                    />
                    <Route
                      component={AuthenticatedApp}
                      path={routes.ROOT}
                    />
                  </Switch>
                </BrowserRouter>
              </DesignSystemProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </WebLocalizationProvider>
      </QueryClientProvider>
    </StoreProvider>
  </Auth0Provider>,
);
