import { Box } from '@mui/material';
import clsx from 'clsx';
import { memo, type ReactNode, createElement, useMemo } from 'react';
import { type DraggableProvided } from 'react-beautiful-dnd';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { IconButton, type TablerIconElement, Typography } from '@amal-ia/frontend/design-system/components';
import { ListElement } from '@amal-ia/lib-ui';

import useCustomReportEditStyles from '../../useCustomReportEditStyles';

interface DrawerItemAction {
  tooltipTitle: string;
  onClick: () => void;
  icon: TablerIconElement;
  alwaysVisible?: boolean;
}

interface DrawerItemProps {
  label: ReactNode;
  startNode?: ReactNode;
  actions?: DrawerItemAction[];
  draggableProvided?: DraggableProvided;
}

export const DrawerItem = memo(function DrawerItem({ label, startNode, actions, draggableProvided }: DrawerItemProps) {
  const classes = useCustomReportEditStyles();

  const isOneActionAlreadyVisible = useMemo(() => (actions || []).find((a) => a.alwaysVisible), [actions]);

  return createElement(
    draggableProvided ? ListElement : 'div',
    {
      className: classes.drawerItem,
      ...(draggableProvided?.draggableProps || {}),
      handleProps: draggableProvided?.dragHandleProps,
      innerRef: draggableProvided?.innerRef,
      styleToApply: { backgroundColor: amaliaTheme.palette.grey['100'] },
    },
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        gap="0.5rem"
      >
        {startNode}
        <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>{label}</Typography>
      </Box>
      {(actions || []).length > 0 && (
        <div className={clsx(classes.drawerItemActions, isOneActionAlreadyVisible && classes.drawerItemAlwaysVisible)}>
          {actions.map(({ tooltipTitle, alwaysVisible, icon, onClick }) => (
            <IconButton
              key={tooltipTitle}
              className={clsx(classes.drawerItemAction, alwaysVisible && classes.drawerItemAlwaysVisible)}
              icon={icon}
              label={tooltipTitle}
              onClick={onClick}
            />
          ))}
        </div>
      )}
    </Box>,
  );
});
