import { createContext } from 'react';

import { type Adjustment, type Statement } from '@amal-ia/lib-types';

export interface AdjustmentsTableContextInterface {
  deleteAdjustment: (adjustmentId: string) => any;
  editAdjustment: (adjustment: Adjustment) => any;
  statement: Statement;
  isWorkflowBegun: boolean;
}

const AdjustmentsTableContext = createContext<AdjustmentsTableContextInterface>({} as AdjustmentsTableContextInterface);

export default AdjustmentsTableContext;
