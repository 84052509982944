import { type TeamContract, type TeamRole } from '@amal-ia/lib-types';

export interface TeamAssignment {
  id: string;
  userId: string;
  teamRole: TeamRole;
  effectiveAsOf: Date | null;
  effectiveUntil: Date | null;
  team?: TeamContract;
}

export interface ComputedTeamSubAssignment {
  id: string;
  status: boolean;
  teamRole: TeamRole;
  effectiveAsOf: Date | null;
  effectiveUntil: Date | null;
}

export interface ComputedTeamAssignment {
  id: string;
  userId: string;

  assignments: ComputedTeamSubAssignment[];
}

// First index is teamId, then userId.
export type TeamAssignmentsMap = Record<string, Record<string, ComputedTeamAssignment>>;

export enum TeamAssignmentUserType {
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
  BOTH = 'BOTH',
}

export interface TeamPlanAssignment {
  planId: string;
  affectedUsersType: TeamAssignmentUserType;
}

// Indexed by planId.
export type TeamPlanAssignmentsMap = Record<string, TeamPlanAssignment>;
