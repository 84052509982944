import { type CSSProperties } from 'react';

import { AmaliaFunction } from '@amal-ia/amalia-lang/formula/evaluate';
import { type Relationship } from '@amal-ia/compensation-definition/shared/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amal-ia/ext/mui/theme';

import { IFormulaNode, FormulaNodeEnum } from './IFormulaNode';

const FunctionsEnum = AmaliaFunction.getFunctionsEnum();

export class FunctionFormulaNode extends IFormulaNode {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: keyof typeof FunctionsEnum,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.FUNCTION, nodeObject, objectDefinition, relationship);
  }

  public getStyles(): CSSProperties {
    return {
      color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.function,
    };
  }

  public getLabel(): string {
    return this.nodeObject as keyof typeof FunctionsEnum;
  }
}
