import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { AlertBanner, Modal } from '@amal-ia/frontend/design-system/components';
import { type Calculation, CalculationStatus, formatUserFullName } from '@amal-ia/lib-types';
import { Progress } from '@amal-ia/lib-ui';

import { CalculationProgressModalStep } from './CalculationProgressModalStep';

interface CalculationProgressionModalProps {
  isOpen: boolean;
  closeModal: () => void;
  stopCalculation?: () => void;
  calculation: Partial<Calculation>;
  calculated?: number;
  total?: number;
}

export const CalculationProgressionModal = memo(function CalculationProgressionModal({
  isOpen,
  closeModal,
  stopCalculation,
  calculation,
  calculated,
  total,
}: CalculationProgressionModalProps) {
  const {
    errors,
    warnings,
  }: {
    errors: Record<string, { id: string; details: string }[]>;
    warnings: Record<string, { id: string; details: string }[]>;
  } = useMemo(() => {
    const resultErrors: Record<string, { id: string; details: string }[]> = {};
    const resultWarnings: Record<string, { id: string; details: string }[]> = {};

    (calculation.descriptor || []).forEach((d) => {
      (d.batches || []).forEach((b) => {
        if ([CalculationStatus.ERROR, CalculationStatus.INCOMPLETE].includes(b.status)) {
          (b.users || [])
            .filter((u) => !!u?.result?.error)
            .forEach((u) => {
              let listToAddError = resultErrors;
              if (u.result.error.includes('has no value for this period')) {
                listToAddError = resultWarnings;
              }

              // If we don't have already this error, create it
              if (!listToAddError[u.result.error]) {
                listToAddError[u.result.error] = [];
              }
              // Push the statement
              listToAddError[u.result.error].push({
                id: u.result.statementId,
                details: `${d.periodName} - ${b.planName} - ${formatUserFullName(u)}`,
              });
            });
        }
      });
    });

    if (calculation.error) {
      if (!resultErrors[calculation.error]) {
        resultErrors[calculation.error] = [];
      }
    }

    return { errors: resultErrors, warnings: resultWarnings };
  }, [calculation.descriptor, calculation.error]);

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.LARGE}
      onClose={closeModal}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Calculation progress" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(errors).length > 0 && (
            <AlertBanner variant={AlertBanner.Variant.ERROR}>
              {Object.entries(errors).map(([error, statements]) => (
                <details key={error}>
                  <summary>
                    <FormattedMessage
                      defaultMessage="{error} ({statementsCount, plural, one {# statement} other {# statements}})"
                      values={{ error, statementsCount: statements.length }}
                    />
                  </summary>
                  {statements.map((s) => (
                    <Box key={s.id}>
                      <Link to={generatePath(routes.STATEMENT, { id: s.id })}>{s.details}</Link>
                    </Box>
                  ))}
                </details>
              ))}
            </AlertBanner>
          )}

          {Object.keys(warnings).length > 0 && (
            <AlertBanner variant={AlertBanner.Variant.WARNING}>
              {Object.entries(warnings).map(([warning, statements]) => (
                <details key={warning}>
                  <summary>
                    <FormattedMessage
                      defaultMessage="{error} ({statementsCount, plural, one {# statement} other {# statements}})"
                      values={{ error: warning, statementsCount: statements.length }}
                    />
                  </summary>
                  {statements.map((s) => (
                    <Box key={s.id}>
                      <Link to={generatePath(routes.STATEMENT, { id: s.id })}>{s.details}</Link>
                    </Box>
                  ))}
                </details>
              ))}
            </AlertBanner>
          )}

          {(calculation.descriptor || []).map((step, index) => (
            <CalculationProgressModalStep
              // eslint-disable-next-line react/no-array-index-key
              key={`step_${index}`}
              index={index}
              step={step}
            />
          ))}
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Box
          alignItems="center"
          display="flex"
          flex={1}
        >
          {!isNil(calculated) && !isNil(total) && (
            <Fragment>
              <Progress
                current={calculated}
                total={total}
              />
              <Box padding={2}>
                <FormattedMessage
                  defaultMessage="{current, number}/{total, number}"
                  values={{ current: calculated, total }}
                />
              </Box>
            </Fragment>
          )}
        </Box>

        {!!stopCalculation && (
          <Modal.MainAction onClick={stopCalculation}>
            <FormattedMessage defaultMessage="Stop calculation" />
          </Modal.MainAction>
        )}
      </Modal.Actions>
    </Modal>
  );
});
