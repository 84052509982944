import { type JSONSchema7 } from 'json-schema';

import { DataConnectorTypes } from '@amal-ia/data-capture/connectors/types';
import { type Property } from '@amal-ia/data-capture/fields/types';
import { type Company } from '@amal-ia/tenants/shared/types';

export type Properties = Record<string, Property>;

/*
 * TODO: Maybe rename to "CapturedModel" ?
 * Or "{Captured|Imported}{Data|Record}Model" ?
 */
export interface CustomObjectDefinition {
  id?: string;
  type?: CustomObjectDefinitionType;
  company?: Company;
  name: string;
  machineName: string;
  schema: JSONSchema7;
  properties: Properties;
  externalIds: string[];
  nameField?: string;
  preferences?: Preferences | SalesforcePreferences;
  relationIds?: string[];
}

export interface Preferences {
  duplicates: DuplicatesOptions;
}
export interface SalesforcePreferences {
  cron: string;
  filters: object;
}

export enum DuplicatesOptions {
  OVERWRITE = 'overwrite',
  SKIP = 'skip',
  UPDATE_MISSING_FIELDS = 'update-missing-fields',
}

export enum CustomObjectDefinitionType {
  AMALIA = 'AMALIA',
  VIRTUAL = 'VIRTUAL',
  SALESFORCE = 'SALESFORCE',
  HUBSPOT = 'HUBSPOT',
  NETSUITE = 'NETSUITE',
  GOOGLESHEET = 'GOOGLESHEET',
  REDSHIFT = 'REDSHIFT',
  SNOWFLAKE = 'SNOWFLAKE',
  POSTGRESQL = 'POSTGRESQL',
  POWERBI = 'POWERBI',
  SQLSERVER = 'SQLSERVER',
  MYSQL = 'MYSQL',
  BIGQUERY = 'BIGQUERY',
  ZOHO = 'ZOHO',
  SALESFORCE_SECOND_ACCOUNT = 'SALESFORCE_SECOND_ACCOUNT',
}

export type CustomObjectDefinitionsMap = Record<string, CustomObjectDefinition>;

export enum VirtualCustomObjects {
  STATEMENT_METRIC = 'statementMetric',
  OBJECT_METRIC = 'objectMetric',
  QUOTA = 'quota',
}

export const HUMAN_READABLE_VIRTUAL_CUSTOM_OBJECT_DEFINITION: Record<VirtualCustomObjects, string> = {
  [VirtualCustomObjects.STATEMENT_METRIC]: 'Statement Metric',
  [VirtualCustomObjects.OBJECT_METRIC]: 'Object Metric',
  [VirtualCustomObjects.QUOTA]: 'Quota',
};

export const CustomObjectDefToDataConnectorType: Record<CustomObjectDefinitionType, DataConnectorTypes | null> = {
  [CustomObjectDefinitionType.SALESFORCE]: DataConnectorTypes.SALESFORCE,
  [CustomObjectDefinitionType.SALESFORCE_SECOND_ACCOUNT]: DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
  [CustomObjectDefinitionType.HUBSPOT]: DataConnectorTypes.HUBSPOT,
  [CustomObjectDefinitionType.NETSUITE]: DataConnectorTypes.NETSUITE,
  [CustomObjectDefinitionType.GOOGLESHEET]: DataConnectorTypes.GOOGLESHEET,
  [CustomObjectDefinitionType.REDSHIFT]: DataConnectorTypes.REDSHIFT,
  [CustomObjectDefinitionType.SNOWFLAKE]: DataConnectorTypes.SNOWFLAKE,
  [CustomObjectDefinitionType.POSTGRESQL]: DataConnectorTypes.POSTGRESQL,
  [CustomObjectDefinitionType.POWERBI]: DataConnectorTypes.POWERBI,
  [CustomObjectDefinitionType.SQLSERVER]: DataConnectorTypes.SQLSERVER,
  [CustomObjectDefinitionType.MYSQL]: DataConnectorTypes.MYSQL,
  [CustomObjectDefinitionType.BIGQUERY]: DataConnectorTypes.BIGQUERY,
  [CustomObjectDefinitionType.ZOHO]: DataConnectorTypes.ZOHO,
  [CustomObjectDefinitionType.AMALIA]: null,
  [CustomObjectDefinitionType.VIRTUAL]: null,
} as const;
export const DataConnectorTypeToCustomObjectDef: Partial<Record<DataConnectorTypes, CustomObjectDefinitionType>> = {
  [DataConnectorTypes.SALESFORCE]: CustomObjectDefinitionType.SALESFORCE,
  [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT]: CustomObjectDefinitionType.SALESFORCE_SECOND_ACCOUNT,
  [DataConnectorTypes.HUBSPOT]: CustomObjectDefinitionType.HUBSPOT,
  [DataConnectorTypes.NETSUITE]: CustomObjectDefinitionType.NETSUITE,
  [DataConnectorTypes.GOOGLESHEET]: CustomObjectDefinitionType.GOOGLESHEET,
  [DataConnectorTypes.REDSHIFT]: CustomObjectDefinitionType.REDSHIFT,
  [DataConnectorTypes.SNOWFLAKE]: CustomObjectDefinitionType.SNOWFLAKE,
  [DataConnectorTypes.POSTGRESQL]: CustomObjectDefinitionType.POSTGRESQL,
  [DataConnectorTypes.POWERBI]: CustomObjectDefinitionType.POWERBI,
  [DataConnectorTypes.SQLSERVER]: CustomObjectDefinitionType.SQLSERVER,
  [DataConnectorTypes.MYSQL]: CustomObjectDefinitionType.MYSQL,
  [DataConnectorTypes.BIGQUERY]: CustomObjectDefinitionType.BIGQUERY,
  [DataConnectorTypes.ZOHO]: CustomObjectDefinitionType.ZOHO,
} as const;
