import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface SearchDrawerListElementProps {
  children: ReactNode;
}

export const SearchDrawerListElement = memo(function SearchDrawerListElement({
  children,
  ...props
}: SearchDrawerListElementProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.listElement}
      {...props}
    >
      {children}
    </div>
  );
});
