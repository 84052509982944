import { css } from '@emotion/react';
import { Fragment, memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../general/button/Button';
import { SelectDropdownItem, type SelectDropdownItemProps } from '../select-dropdown-item/SelectDropdownItem';
import { type SelectDropdownOption } from '../SelectDropdown.types';

export type PaginatedSelectDropdownItemsListProps<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
> = {
  isMultiple?: TIsMultiple;
  options: TOption[];
  checkedOptionsValues: TOption['value'][];
  /** Option checked change handler. */
  onOptionChange: SelectDropdownItemProps<TOption, TIsMultiple>['onChange'];
};

const DEFAULT_PAGE_SIZE = 150;

const PaginatedSelectDropdownItemsListBase = function PaginatedSelectDropdownItemsList<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
>({
  isMultiple,
  options,
  checkedOptionsValues,
  onOptionChange,
}: PaginatedSelectDropdownItemsListProps<TOption, TIsMultiple>) {
  const [itemsToShowCount, setItemsToShowCount] = useState(DEFAULT_PAGE_SIZE);

  const handleShowMore = useCallback(() => setItemsToShowCount((prev) => prev + DEFAULT_PAGE_SIZE), []);

  return (
    <Fragment>
      {options.slice(0, itemsToShowCount).map((option) => (
        <SelectDropdownItem<TOption, TIsMultiple>
          key={String(option.value)}
          checked={checkedOptionsValues.includes(option.value)}
          isMultiple={isMultiple}
          option={option}
          onChange={onOptionChange}
        />
      ))}

      {itemsToShowCount < options.length ? (
        <Button
          variant={Button.Variant.SECONDARY_TEXT}
          css={css`
            margin-top: 1px;
            width: 100%;
            justify-content: center;
          `}
          onClick={handleShowMore}
        >
          <FormattedMessage
            defaultMessage="Show more ({current, number}/{total, number})"
            values={{
              current: itemsToShowCount,
              total: options.length,
            }}
          />
        </Button>
      ) : null}
    </Fragment>
  );
};

export const PaginatedSelectDropdownItemsList = memo(
  PaginatedSelectDropdownItemsListBase,
) as typeof PaginatedSelectDropdownItemsListBase;
