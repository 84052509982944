import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { TextType, Text } from '../typography';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  actionIcon: {
    color: theme.palette.grey[700],
    marginRight: theme.spacing(1),
  },
}));

interface ContextMenuElementProps {
  icon?: ReactNode;
  text: string;
}

export const ContextMenuElement = memo(function ContextMenuElement({ icon, text }: ContextMenuElementProps) {
  const classes = useStyles();
  return (
    <Box
      alignItems="center"
      display="flex"
    >
      {icon ? (
        <Box
          alignItems="center"
          className={classes.actionIcon}
          display="flex"
        >
          {icon}
        </Box>
      ) : null}
      <Text type={TextType.MENU_ACTION}>{text}</Text>
    </Box>
  );
});

ContextMenuElement.displayName = 'ContextMenuElement';
