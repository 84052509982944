import { css, type Theme } from '@emotion/react';

export const tableRow = (theme: Theme) => css`
  &[data-even='true'] {
    background-color: ${theme.ds.colors.graySecondary[50]};
  }

  &[data-even='false'] {
    background-color: ${theme.ds.colors.gray[0]};
  }
`;
