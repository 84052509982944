import { css } from '@emotion/react';

export const presentationContainer = css`
  display: grid;
  grid-template-areas:
    'reviewSwitch reviewStepTitle'
    '. progressBar';
  grid-template-columns: auto auto;
  align-items: center;
  column-gap: 8px;
`;

export const reviewSwitch = css`
  grid-area: reviewSwitch;
  & > span {
    margin: 0;
  }
`;

export const reviewStepTitle = css`
  grid-area: reviewStepTitle;
`;

export const progressBar = css`
  grid-area: progressBar;
  min-width: 100px;
  max-width: 190px;
`;
