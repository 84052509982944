import { combineReducers } from 'redux';

import { type ReduxAction } from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/shared/types';

import { USERS_ACTIONS as USER_ACTIONS } from '../users/constants';

import { COMPANIES_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case COMPANIES_ACTIONS.START:
        return state + 1;
      case COMPANIES_ACTIONS.ERROR:
      case COMPANIES_ACTIONS.SET_COMPANY:
        return state - 1;
      default:
        return state;
    }
  },
  company: (state: Company | null = null, action: ReduxAction) => {
    switch (action.type) {
      case USER_ACTIONS.SET_CURRENT_USER:
        return action.payload.user.company || state;
      case COMPANIES_ACTIONS.SET_COMPANY:
        return action.payload.company;
      default:
        return state;
    }
  },
});
