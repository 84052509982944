import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { designerRoutesMessages } from './Designer/designer.routes.messages';

const DesignerContainer = lazy(() => import('./Designer/common/DesignerContainer'));
const PlanDesignerWrapped = lazy(() => import('./Designer/PlanDesignerWrapped'));
const ToDesignerV2 = lazy(() => import('./DesignerNew/ToDesignerV2'));

export const DesignerRoutes = memo(function DesignerRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="designer">
      <Switch>
        <CompanyRoute
          component={PlanDesignerWrapped}
          path={[routes.DESIGNER_V2_OBJECT, routes.DESIGNER_V2_PLAN]}
          shouldRedirectToHome={ability.cannot(ActionsEnum.modify, SubjectsEnum.Plan)}
          title={formatMessage(designerRoutesMessages.DESIGNER)}
          user={user}
        />

        {/* Link to designer v2 without a plan => load a plan and redirects to designer v2 */}
        <CompanyRoute
          component={ToDesignerV2}
          path={routes.DESIGNER_V2}
          shouldRedirectToHome={ability.cannot(ActionsEnum.modify, SubjectsEnum.Plan)}
          title={formatMessage(designerRoutesMessages.DESIGNER)}
          user={user}
        />

        <CompanyRoute
          component={DesignerContainer}
          path={routes.DESIGNER_OBJECT}
          shouldRedirectToHome={ability.cannot('view', 'Plan')}
          title={formatMessage(designerRoutesMessages.DESIGNER)}
          user={user}
        />
        <CompanyRoute
          component={DesignerContainer}
          path={routes.DESIGNER}
          shouldRedirectToHome={ability.cannot('view', 'Plan')}
          title={formatMessage(designerRoutesMessages.DESIGNER)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
