import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type Company } from '@amal-ia/tenants/shared/types';

export const getSupportedPlanFrequencies = (company: Company): PeriodFrequencyEnum[] => {
  switch (company.statementFrequency) {
    case PeriodFrequencyEnum.month:
      return [PeriodFrequencyEnum.month, PeriodFrequencyEnum.quarter];
    case PeriodFrequencyEnum.quarter:
      return [PeriodFrequencyEnum.quarter];
    default:
      return [];
  }
};
