import { InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconSearch, IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  memo,
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
  type ChangeEvent,
  type MouseEventHandler,
  type KeyboardEventHandler,
} from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@amal-ia/frontend/design-system/components';

import { Input } from '../Input/Input';

import { messages } from './SearchInput.messages';

const useStyles = makeStyles({
  input: {
    border: 'none',
  },
});

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  forceSearchVisible?: boolean;
  className?: string;
  showBorder?: boolean;
}

export const SearchInput = memo(function SearchInput({
  value,
  onChange,
  onClick,
  onKeyDown,
  forceSearchVisible,
  className,
  showBorder = false,
}: SearchInputProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [searchIsVisible, setSearchIsVisible] = useState<boolean>(!!forceSearchVisible);
  const inputRef: any = useRef();

  useEffect(() => {
    if (forceSearchVisible) {
      inputRef?.current?.focus();
    }
  }, [forceSearchVisible, inputRef]);

  const closeSearch = useCallback(() => {
    onChange('');

    // Close input if it's meant to be closed
    if (!forceSearchVisible) {
      setSearchIsVisible(false);
    }
  }, [onChange, forceSearchVisible]);

  const onChangeProxy = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const showClearBtn = useMemo(() => !forceSearchVisible || !!value, [forceSearchVisible, value]);

  if (!searchIsVisible) {
    return (
      <IconButton
        icon={<IconSearch />}
        label={formatMessage(messages.SEARCH)}
        onClick={() => setSearchIsVisible(true)}
      />
    );
  }

  return (
    <Input
      autoComplete="off"
      autoFocus={!forceSearchVisible}
      className={clsx(className, !showBorder ? classes.input : null)}
      id="search-input"
      inputRef={inputRef}
      placeholder={formatMessage(messages.SEARCH)}
      startAdornment={<IconSearch />}
      value={value}
      endAdornment={
        showClearBtn ? (
          <InputAdornment position="end">
            <IconButton
              icon={<IconX />}
              label={formatMessage(messages.CLEAR_SEARCH)}
              onClick={closeSearch}
            />
          </InputAdornment>
        ) : null
      }
      onChange={onChangeProxy}
      onClick={onClick}
      onKeyDown={onKeyDown}
    />
  );
});
