import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type BadgeAward, type BadgeConfiguration } from '@amal-ia/lib-types';
import { Tooltip, Text, TextType } from '@amal-ia/lib-ui';

import { BADGE_LOGOS } from './badges.enum';
import { BadgeSummary } from './BadgeSummary';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  item: {
    padding: theme.spacing(1, 2),
    flex: '0 0 33%',
  },
  itemWrapped: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemTitle: {
    width: theme.spacing(25),
    whiteSpace: 'normal',
    marginLeft: theme.spacing(2),
  },
  itemIcon: {
    display: 'block',
    width: theme.spacing(10),
  },
}));

interface BadgeItemProps {
  award?: BadgeAward;
  configuration: BadgeConfiguration;
}

export const BadgeItem = memo(function BadgeItem({ award, configuration }: BadgeItemProps) {
  const classes = useStyles();

  const Logo = award ? BADGE_LOGOS[configuration.icon].iconColor : BADGE_LOGOS[configuration.icon].iconGrey;

  return (
    <Box className={classes.item}>
      <Box className={classes.itemWrapped}>
        <Box className={classes.itemIcon}>
          <Logo />
        </Box>
        <Text
          className={classes.itemTitle}
          type={TextType.BADGE_TITLE}
        >
          {configuration.description ? (
            <Tooltip
              title={
                <BadgeSummary
                  badgeConfiguration={configuration}
                  isAwarded={!!award}
                />
              }
            >
              <Box style={{ opacity: award ? 1 : 0.5 }}>{configuration.name}</Box>
            </Tooltip>
          ) : (
            configuration.name
          )}
        </Text>
      </Box>
    </Box>
  );
});
