import { IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons-react';
import { isEmpty } from 'lodash';
import { Fragment, memo, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { IconButton, PageHeader, Typography } from '@amal-ia/frontend/design-system/components';
import {
  selectCurrentPaymentCategory,
  selectPeriodContainsStatementWithHoldRules,
  selectStatementPaymentAmountByCategory,
  selectCurrentStatementStatistics,
  useThunkDispatch,
  fetchUserStatementsFacets,
  evaluateHighlightedKpisProgress,
  type CreateAdjustmentDto,
  useCurrentCompany,
} from '@amal-ia/frontend/web-data-layers';
import { type Calculation, PaymentCategory, reformatDateString, type StatementThread } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { StatementAvatar, StatementHistogram, type StatementHistogramItem } from '@amal-ia/lib-ui-business';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { CalculationError } from '../../common/CalculationError';
import StatementHeaderPayoutForecasted from '../../common/StatementHeaderPayoutForecasted';
import { type StatementViewEnum } from '../StatementDetail';

import { StatementDetailHeaderActions } from './actions/StatementDetailHeaderActions';
import { StatementDetailHeaderBreadcrumbs } from './breadcrumbs/StatementDetailHeaderBreadcrumbs';
import { messages } from './StatementDetailHeader.messages';
import * as styles from './StatementDetailHeader.styles';
import StatementDetailHeaderPayout from './StatementDetailHeaderPayout';
import { StatementDetailHeaderUserInformations } from './user-informations/StatementDetailHeaderUserInformations';

interface StatementDetailHeaderProps {
  setSelectAdjustment: (adjustment: CreateAdjustmentDto) => void;
  onClickGoToStatement: (period: string) => void;
  statementCommentThread: StatementThread | null;
  isTracingActive: boolean;
  lastCalculation?: Calculation;
  handleForecastedSwitch: (nextState: StatementViewEnum) => Promise<void>;
}

const StatementDetailHeader = memo(function StatementDetailHeader({
  setSelectAdjustment,
  onClickGoToStatement,
  statementCommentThread,
  isTracingActive,
  lastCalculation,
  handleForecastedSwitch,
}: StatementDetailHeaderProps) {
  const dispatch = useThunkDispatch();
  const { formatMessage } = useIntl();

  const statement = useStatementDetailContext();

  const { company } = useCurrentCompany();
  const [wideChart, setWideChart] = useState<boolean>(false);

  const paymentTotalByType = useSelector(selectStatementPaymentAmountByCategory);
  const currentPaymentCategory = useSelector(selectCurrentPaymentCategory);
  const statistics = useSelector(selectCurrentStatementStatistics);
  const containsHoldRules = useSelector(selectPeriodContainsStatementWithHoldRules);

  const statementComputedProgress = useMemo(
    () => statement?.results && evaluateHighlightedKpisProgress(statement.results),
    [statement],
  );

  useAsyncEffect(async () => {
    if (statement?.period?.id) {
      await dispatch(fetchUserStatementsFacets(statement?.period?.id));
    }
  }, [statement?.period?.id]);

  // ============ PAYMENTS ==============
  const statementsInfos: StatementHistogramItem[] = useMemo(() => {
    try {
      return statistics?.records?.map((rec) => {
        const totalCommission = rec.ruleMetricPayment__value;
        const period = rec.ruleMetricPeriod__month.split('/');
        return {
          id: rec.RULE_METRIC__statementId as string,
          period: reformatDateString(period[0], 'MM/YYYY', 'MMM'),
          total: totalCommission?.value || 0,
          format: FormatsEnum.currency,
          currency: totalCommission?.symbol || statement.currency,
          year: period[1],
        };
      });
    } catch (e) {
      return [];
    }
  }, [statistics, statement]);

  return (
    <Fragment>
      <PageHeader
        stickyRow={
          <div css={{ padding: '4px 0' }}>
            <PageHeader.Row
              left={<StatementDetailHeaderBreadcrumbs statement={statement} />}
              right={
                <StatementDetailHeaderActions.StickyRow
                  companyFeatureFlags={company.featureFlags}
                  handleForecastedSwitch={handleForecastedSwitch}
                  paymentTotalByType={paymentTotalByType}
                  setSelectAdjustment={setSelectAdjustment}
                  statementCommentThread={statementCommentThread}
                />
              }
            />
          </div>
        }
      >
        <PageHeader.Row
          left={<StatementDetailHeaderBreadcrumbs statement={statement} />}
          right={
            <StatementDetailHeaderActions.FirstRow
              companyFeatureFlags={company.featureFlags}
              handleForecastedSwitch={handleForecastedSwitch}
              setSelectAdjustment={setSelectAdjustment}
              statementCommentThread={statementCommentThread}
            />
          }
        />
        <PageHeader.Row
          left={
            <StatementDetailHeaderUserInformations
              statement={statement}
              user={statement.user}
            />
          }
          right={
            <StatementDetailHeaderActions.SecondRow
              companyFeatureFlags={company.featureFlags}
              handleForecastedSwitch={handleForecastedSwitch}
              setSelectAdjustment={setSelectAdjustment}
              statementCommentThread={statementCommentThread}
            />
          }
        />
      </PageHeader>
      <div css={styles.statementDetailsContainer(statement.workflowComplete)}>
        {(!statement.isForecastedView &&
          currentPaymentCategory === PaymentCategory.achievement &&
          !isEmpty(statementsInfos)) ||
        wideChart ? (
          <div css={styles.histogramContainer}>
            <StatementHistogram
              containsHoldRules={containsHoldRules}
              definitions={statistics.definitions}
              items={statementsInfos}
              planName={statement.plan.name}
              user={statement.user as UserContract}
              wideChart={wideChart}
              onClickBar={onClickGoToStatement}
            />
            {company?.featureFlags?.DASHBOARDS && !statement.isForecastedView ? (
              <IconButton
                key={`${wideChart}`}
                icon={wideChart ? <IconArrowsMinimize /> : <IconArrowsMaximize />}
                label={wideChart ? formatMessage(messages.COLLAPSE_RECAP) : formatMessage(messages.EXTEND_RECAP)}
                onClick={() => setWideChart((prev) => !prev)}
              />
            ) : null}
          </div>
        ) : null}
        {!wideChart && (
          <Fragment>
            <div>
              <StatementAvatar
                avatarSize={72}
                error={statement.error || undefined}
                gaugeGap={6}
                gaugeSize={120}
                gaugeStrokeWidth={6}
                highlightedKpis={statementComputedProgress}
                isForecast={statement.isForecastedView}
                user={statement.user}
              />
            </div>
            <div>
              {statement.isForecastedView ? (
                !statement.forecastId ? (
                  <Typography variant={Typography.Variant.HEADING_3_REGULAR}>
                    <FormattedMessage {...messages.NO_FORECASTED_STATEMENT_CALCULATED} />
                  </Typography>
                ) : (
                  <StatementHeaderPayoutForecasted
                    forecastedTotal={{
                      value: statement.total,
                      symbol: statement.currency,
                    }}
                    statementTotal={{
                      value: statement.originalStatement?.total,
                      symbol: statement.originalStatement?.currency,
                    }}
                  />
                )
              ) : (
                <StatementDetailHeaderPayout statementStatistics={statementsInfos} />
              )}
            </div>
          </Fragment>
        )}
      </div>
      <div>
        {!isTracingActive && lastCalculation?.status === 'ERROR' && <CalculationError calculation={lastCalculation} />}

        {isTracingActive ? (
          <div css={styles.tracingMessageContainer}>
            <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
              <FormattedMessage
                {...messages.TRACING_BETA}
                values={{
                  span: (chunks) => <span css={styles.tracingText}>{chunks}</span>,
                }}
              />
            </Typography>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
});

export default StatementDetailHeader;
