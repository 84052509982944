import { memo } from 'react';

import { LeftBarActions } from './left-bar-actions/LeftBarActions';
import { LeftBarHeader } from './left-bar-header/LeftBarHeader';
import { LeftBarImpersonateIndicator } from './left-bar-impersonate-indicator/LeftBarImpersonateIndicator';
import { LeftBarMenu, type LeftBarMenuProps } from './left-bar-menu/LeftBarMenu';
import { LeftBarUser } from './left-bar-user/LeftBarUser';
import * as styles from './LeftBar.styles';

export type LeftBarProps = {
  currentPage?: LeftBarMenuProps['currentPage'];
};

export const LeftBar = memo(function LeftBar({ currentPage }: LeftBarProps) {
  return (
    <aside css={styles.leftBar}>
      <header css={styles.header}>
        <LeftBarHeader />
      </header>

      <div css={styles.menu}>
        <LeftBarMenu currentPage={currentPage} />
      </div>

      <footer css={styles.footer}>
        <LeftBarActions />
        <LeftBarImpersonateIndicator />
        <LeftBarUser />
      </footer>
    </aside>
  );
});
