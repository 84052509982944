import { type ActionCreator } from 'redux';

import { type CommentThreadMessage, type ReduxAction, type StatementThreadScope } from '@amal-ia/lib-types';

import { COMMENTS_ACTIONS } from './constants';

export const setOpenedThread: ActionCreator<ReduxAction> = (
  id: number | string | null,
  scope?: StatementThreadScope,
) => ({
  type: COMMENTS_ACTIONS.SET_OPENED_THREAD,
  payload: { id, scope },
});

export const closeThread: ActionCreator<ReduxAction> = () => ({
  type: COMMENTS_ACTIONS.CLOSE_THREAD,
});

export const addMessageToOpenedThread: ActionCreator<ReduxAction> = (message: CommentThreadMessage) => ({
  type: COMMENTS_ACTIONS.ADD_MESSAGE,
  payload: { message },
});
