import { combineReducers } from 'redux';

import { type PlanForecast, type ReduxAction } from '@amal-ia/lib-types';

import { switchDisplayStatus } from '../plans/reducer';

import { PLAN_FORECAST_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action) => {
    switch (action.type) {
      case PLAN_FORECAST_ACTIONS.START:
        return state + 1;
      case PLAN_FORECAST_ACTIONS.ERROR:
      case PLAN_FORECAST_ACTIONS.SET_PLAN_FORECAST:
        return state - 1;
      default:
        return state;
    }
  },
  currentPlanForecast: (state: PlanForecast | null = null, action: ReduxAction) => {
    switch (action.type) {
      case PLAN_FORECAST_ACTIONS.SET_PLAN_FORECAST:
        return action.payload.planForecast;
      case PLAN_FORECAST_ACTIONS.EDIT_DATASET:
        return {
          ...state,
          forecastConfig: {
            fields: { ...state.forecastConfig.fields },
            kpis: { ...state.forecastConfig.kpis },
            datasets: {
              ...state.forecastConfig.datasets,
              [action.payload.datasetId]: action.payload.formula,
            },
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_FIELD:
        return {
          ...state,
          forecastConfig: {
            datasets: { ...state.forecastConfig.datasets },
            kpis: { ...state.forecastConfig.kpis },
            fields: {
              ...state.forecastConfig.fields,
              [action.payload.fieldId]: action.payload.formula,
            },
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_KPI:
        return {
          ...state,
          forecastConfig: {
            datasets: { ...state.forecastConfig.datasets },
            fields: { ...state.forecastConfig.fields },
            kpis: {
              ...state.forecastConfig.kpis,
              [action.payload.kpiId]: action.payload.formula,
            },
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_OBJECTS_TO_DISPLAY:
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: action.payload.objectsToDisplay,
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_KPIS_TO_DISPLAY:
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: {
              ...state.objectsToDisplay[action.payload.ruleId],
              kpisToDisplay: action.payload.kpisToDisplay,
            },
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_KPI_TO_DISPLAY_STATUS:
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: {
              ...state.objectsToDisplay[action.payload.ruleId],
              kpisToDisplay: state.objectsToDisplay[action.payload.ruleId].kpisToDisplay.map((kpiToDisplay) =>
                kpiToDisplay.id === action.payload.kpiToDisplayId
                  ? { ...kpiToDisplay, displayStatus: switchDisplayStatus(kpiToDisplay.displayStatus) }
                  : kpiToDisplay,
              ),
            },
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_FILTERS_TO_DISPLAY:
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: {
              ...state.objectsToDisplay[action.payload.ruleId],
              filtersToDisplay: action.payload.filtersToDisplay,
            },
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_FILTER_TO_DISPLAY_STATUS:
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: {
              ...state.objectsToDisplay[action.payload.ruleId],
              filtersToDisplay: state.objectsToDisplay[action.payload.ruleId].filtersToDisplay.map((filterToDisplay) =>
                filterToDisplay.id === action.payload.filterToDisplayId
                  ? { ...filterToDisplay, displayStatus: switchDisplayStatus(filterToDisplay.displayStatus) }
                  : filterToDisplay,
              ),
            },
          },
        };

      case PLAN_FORECAST_ACTIONS.EDIT_FIELDS_TO_DISPLAY:
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: {
              ...state.objectsToDisplay[action.payload.ruleId],
              filtersToDisplay: (state.objectsToDisplay[action.payload.ruleId].filtersToDisplay || []).map(
                (filterToDisplay) =>
                  filterToDisplay.id === action.payload.filterId
                    ? { ...filterToDisplay, fieldsToDisplay: action.payload.fieldsToDisplay }
                    : filterToDisplay,
              ),
            },
          },
        };
      case PLAN_FORECAST_ACTIONS.EDIT_FIELD_TO_DISPLAY_STATUS:
        return {
          ...state,
          objectsToDisplay: {
            ...state.objectsToDisplay,
            [action.payload.ruleId]: {
              ...state.objectsToDisplay[action.payload.ruleId],
              filtersToDisplay: state.objectsToDisplay[action.payload.ruleId].filtersToDisplay.map((filterToDisplay) =>
                filterToDisplay.id === action.payload.filterId
                  ? {
                      ...filterToDisplay,
                      fieldsToDisplay: (filterToDisplay.fieldsToDisplay || []).map((fieldToDisplay) =>
                        fieldToDisplay.name === action.payload.fieldMachinename
                          ? { ...fieldToDisplay, displayStatus: switchDisplayStatus(fieldToDisplay.displayStatus) }
                          : fieldToDisplay,
                      ),
                    }
                  : filterToDisplay,
              ),
            },
          },
        };
      default:
        return state;
    }
  },
});
