import invariant from 'invariant';
import { createContext, useContext } from 'react';

import { type TokenType } from '@amal-ia/lib-types';

export type FormulaEditorToken = {
  id?: string;
  objectDefinitionName?: string;
  machineName: string;
  name: string;
  type: TokenType;
};

export type FormulaEditorContextValue = {
  /** List of all the available tokens in the formula, scoped to the current plan. */
  planTokens: Record<FormulaEditorToken['machineName'], FormulaEditorToken>;
  onTokenClick: (token: FormulaEditorToken) => void;
};

export const FormulaEditorContext = createContext<FormulaEditorContextValue | null>(null);

export const useFormulaEditorContext = (): FormulaEditorContextValue => {
  const context = useContext(FormulaEditorContext);
  invariant(context, 'useFormulaEditorContext must be used within a <FormulaEditorContext.Provider>');
  return context;
};
