import { useEffect } from 'react';

/**
 * Use this hook when you want to ask the user for confirmation before leaving the page.
 *
 * It doesn't apply to navigation INSIDE the app. For that you need the
 * react-router Prompt component, like so:
 *   <Prompt
 *     when={shouldBlockNavigation}
 *     message="You have unsaved changes, are you sure you want to leave?"
 *   />
 />

 *
 * @param shouldBlockNavigation
 */
export const useBlockBrowserNavigation = (shouldBlockNavigation: boolean) => {
  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return () => {
      // Proper cleanup.
      window.onbeforeunload = undefined;
    };
  }, [shouldBlockNavigation]);
};
