/* eslint-disable react/no-multi-comp */
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { SearchPanel, Table, TableGroupRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { type TableProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { type ComponentProps, Fragment, memo, useCallback, useMemo, type ReactNode } from 'react';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { formatTotal } from '@amal-ia/lib-types';

import { useDatagridStyles } from '../datagridStyles';

// SEARCH
export const DatagridSearchInput = function DatagridSearchInput({
  ...restProps
}: ComponentProps<typeof SearchPanel.Input>) {
  return (
    <SearchPanel.Input
      {...restProps}
      className="datagrid-search-input"
    />
  );
};

// CURRENCY
interface CurrencyTypeProviderProps {
  currencyColumns: string[];
  currency?: CurrencySymbolsEnum;
}

export const CurrencyTypeProvider = memo(function CurrencyTypeProvider({
  currencyColumns,
  currency,
}: CurrencyTypeProviderProps) {
  const CurrencyFormatter = useCallback(
    ({ value }: any) => (
      <Fragment>{formatTotal(value, FormatsEnum.currency, currency || CurrencySymbolsEnum.EUR, 1)}</Fragment>
    ),
    [currency],
  );
  return (
    <DataTypeProvider
      for={currencyColumns}
      formatterComponent={CurrencyFormatter}
    />
  );
});

// CELLS
export const SmallerGroupCellComponent = memo(function SmallerGroupCellComponent(props: any) {
  const styles = useMemo(
    () => ({
      padding: amaliaTheme.spacing(1),
    }),
    [],
  );

  return (
    <TableGroupRow.Cell
      {...props}
      style={styles}
    />
  );
});

export const SmallerHeaderCellComponent = memo(function SmallerHeaderCellComponent(props: any) {
  const styles = useMemo(
    () => ({
      padding: amaliaTheme.spacing(1),
      color: amaliaTheme.palette.secondary.main,
    }),
    [],
  );

  return (
    <TableHeaderRow.Cell
      {...props}
      style={styles}
    >
      <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>{props.children}</Typography>
    </TableHeaderRow.Cell>
  );
});

export const TableHeaderCell = memo(function TableHeaderCell({
  column,
  children,
  ...restProps
}: TableHeaderRow.CellProps) {
  const classes = useDatagridStyles();
  return (
    <TableHeaderRow.Cell
      column={column}
      {...restProps}
      className={clsx((restProps as any).className, classes.header)}
    >
      <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>{children}</Typography>
    </TableHeaderRow.Cell>
  );
});

export const TableGroupCell = memo(function TableGroupCell({
  column,
  children,
  ...restProps
}: TableGroupRow.CellProps & { children: ReactNode }) {
  const classes = useDatagridStyles();
  return (
    <TableGroupRow.Cell
      column={column}
      {...restProps}
      className={classes.header}
    >
      {children}
    </TableGroupRow.Cell>
  );
});

// HEADER
export const TableHeaderContent = memo(function TableHeaderContent(props: TableHeaderRow.ContentProps) {
  const classes = useDatagridStyles();

  // @ts-expect-error -- Not in datagrid types but we can add it into columns definitions.
  const subTitle = props.column.subTitle;

  return (
    <TableHeaderRow.Content
      {...props}
      className={classes.headerCellContent}
    >
      {props.children}
      {subTitle ? <div className={classes.subTitle}>{subTitle}</div> : null}
    </TableHeaderRow.Content>
  );
});

// TABLES
export const TableComponentBase = memo(function TableComponentBase({ classes, ...restProps }: TableProps) {
  const ownClasses = useDatagridStyles();
  return (
    <Table.Table
      {...restProps}
      className={clsx(ownClasses.root, classes?.root, 'datagrid')}
    />
  );
});

// DataGrid on fullWidth
export const InitialWidthTableComponent = withStyles({
  root: {
    width: 'initial',
    minWidth: '100% !important',
  },
})(TableComponentBase);

export const FullWidthTableComponent = withStyles({
  root: {
    width: '100%',
  },
})(TableComponentBase);
