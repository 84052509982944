import { Table } from '@devexpress/dx-react-grid-material-ui';
import { memo } from 'react';

import usePaymentsTableStyles from './usePaymentsTableStyles';

const PaymentsTableRow = memo(function PaymentsTableRow(props: Table.DataRowProps) {
  const classes = usePaymentsTableStyles();

  return (
    <Table.Row
      {...props}
      className={classes.tableRow}
    />
  );
});

export default PaymentsTableRow;
