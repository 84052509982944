import { isEmpty } from 'lodash';

import { http } from '@amal-ia/frontend/kernel/http';
import { type StatusStatementType, type UserStatementsFacets, type UserStatementsPage } from '@amal-ia/lib-types';

const apiEndpoint = '/userStatements';

export const findUserStatements = async (
  periodId?: string,
  userIds?: string[],
  planId?: string,
  teamId?: string,
  searchQuery?: string,
  status?: StatusStatementType,
  isForecast?: boolean,
  page?: number,
  nbStatementsPerPage?: number,
  sortColumn?: string,
  sortOrder = 'asc',
): Promise<UserStatementsPage> => {
  const { data } = await http.get(apiEndpoint, {
    params: {
      periodId: periodId || undefined,
      userIds: userIds || undefined,
      planId: planId || undefined,
      teamId: teamId || undefined,
      isForecast,
      searchQuery,
      status: !isEmpty(status) ? status : undefined,
      page,
      limit: nbStatementsPerPage || 18,
      sortColumn,
      sortOrder,
    },
  });

  return data;
};

export const getUserStatementsFacets = async (
  periodId?: string,
  planIds?: string[],
  teamId?: string,
  status?: StatusStatementType,
  isForecast?: boolean,
): Promise<UserStatementsFacets> => {
  const { data } = await http.get(`${apiEndpoint}/facets`, {
    params: {
      periodId: periodId || undefined,
      planIds: planIds || undefined,
      teamId: teamId || undefined,
      status: !isEmpty(status) ? status : undefined,
      isForecast: isForecast || undefined,
    },
  });

  return data;
};
