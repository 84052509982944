import { type ForwardedRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { Button } from '../../../../../general/button/Button';
import { ButtonLink, type ButtonLinkProps } from '../../../../../navigation/button-link/ButtonLink';

export type CellMainLinkProps = MergeAll<
  [
    Omit<ButtonLinkProps, 'children' | 'size' | 'variant'>,
    {
      /** Children are optional. */
      children?: ButtonLinkProps['children'];
    },
  ]
>;

const CellMainLinkForwardRef = forwardRef(function CellMainLink(
  { children, ...props }: CellMainLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <ButtonLink
      {...props}
      ref={ref}
      size={Button.Size.SMALL}
      variant={Button.Variant.SECONDARY_LIGHT}
    >
      {children || <FormattedMessage defaultMessage="View" />}
    </ButtonLink>
  );
});

export const CellMainLink = Object.assign(CellMainLinkForwardRef, {
  IconPosition: ButtonLink.IconPosition,
});
