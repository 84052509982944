import { Box, Collapse, type BoxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconChevronUp } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, useCallback, useEffect, useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { useIsMounted } from '@amal-ia/ext/react/hooks';
import { IconButton } from '@amal-ia/frontend/design-system/components';

const ANIMATION_DURATION = 0.3;

const useStyles = makeStyles(() => ({
  collapse: {
    transition: `height ${ANIMATION_DURATION}s ease-in-out`,
  },
  iconButtonBottom: {
    transform: 'rotate(180deg)',
    transition: `transform ${ANIMATION_DURATION}s ease-in-out`,
  },
  iconButtonTop: {
    transform: 'rotate(0deg)',
    transition: `transform ${ANIMATION_DURATION}s ease-in-out`,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
}));

export type CollapsibleBoxProps = Omit<BoxProps, 'children' | 'title'> & {
  children: ReactNode;
  defaultOpenState?: boolean;
  title: ReactNode;
};

export const CollapsibleBox = memo(function CollapsibleBox({
  children,
  defaultOpenState = true,
  title,
  ...boxProps
}: CollapsibleBoxProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(defaultOpenState);
  const [renderInsideContent, setRenderInsideContent] = useState(false);
  const isMounted = useIsMounted();

  const handleToggle = useCallback(() => {
    setIsOpen((openState) => !openState);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setRenderInsideContent(true);
    } else {
      setTimeout(() => {
        if (isMounted()) {
          setRenderInsideContent(false);
        }
      }, ANIMATION_DURATION);
    }
  }, [isOpen, isMounted]);

  return (
    <Box {...boxProps}>
      <Box
        className={classes.titleContainer}
        onClick={handleToggle}
      >
        {title}

        <IconButton
          className={isOpen ? classes.iconButtonTop : classes.iconButtonBottom}
          // If we handle the click here + on the box, the toggle will be called twice
          // and the collapse won't open.
          data-test-id="toggle collapse"
          icon={<IconChevronUp />}
          size={IconButton.Size.MEDIUM}
          label={
            isOpen
              ? formatMessage({ defaultMessage: 'Close the section' })
              : formatMessage({ defaultMessage: 'Open the section' })
          }
          onClick={noop}
        />
      </Box>

      <Collapse
        className={classes.collapse}
        data-testid="collapsible-box-content"
        in={isOpen}
      >
        {renderInsideContent ? children : null}
      </Collapse>
    </Box>
  );
});
