import { type ActionCreator } from 'redux';

import { type TeamContract, type ReduxAction, type ThunkResult, type TeamNode } from '@amal-ia/lib-types';

import * as TeamsRepository from '../../services/teams/teams.repository';
import { type ComputedTeamAssignment, type TeamAssignmentsMap, type TeamPlanAssignment } from '../../types/teams';
import { addSnackbar } from '../snackbars/actions';

import { TEAMS_ACTIONS } from './constants';
import { selectTeamAssignmentsMap, selectTeamMap, selectTeamMapIsFullyLoaded } from './selectors';

const teamsStart: ActionCreator<ReduxAction> = () => ({
  type: TEAMS_ACTIONS.START,
});

const teamsError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: TEAMS_ACTIONS.ERROR,
  error,
});

// =================== TEAM MANAGEMENT ===================

const setTeams: ActionCreator<ReduxAction> = (teams: TeamContract[]) => ({
  type: TEAMS_ACTIONS.SET_TEAMS,
  payload: { teams },
});

export const fetchAllTeamsThunkAction =
  (forceFetch: boolean = false): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    const isTeamMapFullyLoaded = selectTeamMapIsFullyLoaded(getState());
    const teamsMap = selectTeamMap(getState());

    if (isTeamMapFullyLoaded && !forceFetch) {
      return setTeams(Object.values(teamsMap));
    }

    dispatch(teamsStart());

    try {
      const teams = await TeamsRepository.getTeams();
      return dispatch(setTeams(teams));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

const setTeam: ActionCreator<ReduxAction> = (team: TeamContract) => ({
  type: TEAMS_ACTIONS.SET_TEAM,
  payload: { team },
});

export const fetchTeamThunkAction =
  (teamId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    // Check if we have it in the cache before fetching it.
    const teamMap = selectTeamMap(getState());
    if (teamMap[teamId]) {
      // Returning the action creator without dispatching it so we don't break
      // this function signature (still returning a ReduxAction).
      return setTeam(teamMap[teamId]);
    }

    dispatch(teamsStart());

    try {
      const team = await TeamsRepository.getTeam(teamId);
      return dispatch(setTeam(team));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

export const updateTeamThunkAction =
  (team: Partial<TeamContract>, showSnackbar: boolean = true): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      const updatedTeam = await TeamsRepository.updateTeam(team);
      if (showSnackbar) {
        dispatch(addSnackbar({ message: 'Team updated', options: { variant: 'success' } }));
      }
      return dispatch(setTeam(updatedTeam));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

export const createTeamThunkAction =
  (team: Partial<TeamContract>): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      const newTeam = await TeamsRepository.createTeam(team);
      dispatch(addSnackbar({ message: 'Team created', options: { variant: 'success' } }));
      return dispatch(setTeam(newTeam));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

const deleteTeamAction: ActionCreator<ReduxAction> = (teamId: string) => ({
  type: TEAMS_ACTIONS.DELETE_TEAM,
  payload: { teamId },
});

export const deleteTeamThunkAction =
  (teamId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      await TeamsRepository.deleteTeam(teamId);
      dispatch(addSnackbar({ message: 'Team deleted', options: { variant: 'success' } }));
      return dispatch(deleteTeamAction(teamId));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

// =================== TEAM HIERARCHY ===================

const setTeamHierarchy: ActionCreator<ReduxAction> = (hierarchy: TeamNode[]) => ({
  type: TEAMS_ACTIONS.SET_TEAM_HIERARCHY,
  payload: { hierarchy },
});

export const fetchTeamHierarchyThunkAction =
  (showArchived: boolean): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      const hierarchy = await TeamsRepository.getTeamHierarchy(showArchived);
      return dispatch(setTeamHierarchy(hierarchy));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };
// =================== TEAM ASSIGNMENTS ===================

const setMultipleTeamsAssignments: ActionCreator<ReduxAction> = (teamAssignments: TeamAssignmentsMap) => ({
  type: TEAMS_ACTIONS.SET_TEAMS_ASSIGNMENTS,
  payload: { teamAssignments },
});

export const fetchMultipleTeamAssignmentsThunkAction =
  (teamIds: string[], force?: boolean): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    // Check if we have it in the cache before fetching it.
    const teamAssignmentsMap = selectTeamAssignmentsMap(getState());

    dispatch(teamsStart());

    try {
      let multipleTeamAssignments = {};

      if (force || teamIds.filter((t) => !teamAssignmentsMap[t]).length > 0) {
        multipleTeamAssignments = await TeamsRepository.fetchMultipleTeamMembersAssignments(
          teamIds.filter((t) => !teamAssignmentsMap[t] || force),
        );
      }

      // We need to pass already in-cache assignments because some components need the whole list
      return dispatch(
        setMultipleTeamsAssignments({
          ...teamAssignmentsMap,
          ...multipleTeamAssignments,
        }),
      );
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

const setTeamAssignments: ActionCreator<ReduxAction> = (
  teamId: string,
  teamAssignments: Record<string, ComputedTeamAssignment>,
) => ({
  type: TEAMS_ACTIONS.SET_TEAM_ASSIGNMENTS,
  payload: { teamId, teamAssignments },
});

export const fetchTeamAssignmentsThunkAction =
  (teamId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    // Check if we have it in the cache before fetching it.
    const teamAssignmentsMap = selectTeamAssignmentsMap(getState());

    if (teamAssignmentsMap[teamId]) {
      // Returning the action creator without dispatching it so we don't break
      // this function signature (still returning a ReduxAction).
      return setTeamAssignments(teamId, teamAssignmentsMap[teamId]);
    }

    dispatch(teamsStart());

    try {
      const teamAssignments = await TeamsRepository.fetchTeamMembersAssignments(teamId);

      return dispatch(setTeamAssignments(teamId, teamAssignments));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

const setTeamAssignment: ActionCreator<ReduxAction> = (
  teamId: string,
  userId: string,
  assignment: ComputedTeamAssignment,
) => ({
  type: TEAMS_ACTIONS.SET_TEAM_ASSIGNMENT,
  payload: {
    teamId,
    userId,
    assignment,
  },
});

export const updateTeamMemberAssignmentThunkAction =
  (teamId: string, updatedAssignment: ComputedTeamAssignment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    const { userId } = updatedAssignment;
    const teamAssignmentMap = selectTeamAssignmentsMap(getState());
    const previousAssignment = teamAssignmentMap[teamId][userId];

    dispatch(teamsStart());

    try {
      const newAssignment = await TeamsRepository.applyAssignmentDiff(teamId, previousAssignment, updatedAssignment);
      return dispatch(setTeamAssignment(teamId, userId, newAssignment));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

export const createTeamMemberAssignmentThunkAction =
  (teamId: string, assignment: ComputedTeamAssignment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      const newAssignment = await TeamsRepository.applyAssignmentDiff(teamId, null, assignment);
      return dispatch(setTeamAssignment(teamId, assignment.userId, newAssignment));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

const deleteTeamAssignment: ActionCreator<ReduxAction> = (teamId: string, userId: string) => ({
  type: TEAMS_ACTIONS.DELETE_TEAM_ASSIGNMENT,
  payload: {
    teamId,
    userId,
  },
});

export const deleteTeamMemberAssignmentThunkAction =
  (teamId: string, assignmentToDelete: ComputedTeamAssignment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      await Promise.all(
        assignmentToDelete.assignments.map(async (assignment) => {
          await TeamsRepository.removeTeamAssignment(assignment.id, teamId);
        }),
      );
      return dispatch(deleteTeamAssignment(teamId, assignmentToDelete.userId));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

// =================== TEAM PLAN ASSIGNMENT ===================

const setTeamPlanAssignments: ActionCreator<ReduxAction> = (teamId: string, planAssignments: TeamPlanAssignment[]) => ({
  type: TEAMS_ACTIONS.SET_TEAM_PLAN_ASSIGNMENTS,
  payload: { teamId, planAssignments },
});

export const fetchTeamPlanAssignmentsThunkAction =
  (teamId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      const teamPlanAssignments = await TeamsRepository.getTeamPlanAssignments(teamId);

      return dispatch(setTeamPlanAssignments(teamId, teamPlanAssignments));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

const setTeamPlanAssignment: ActionCreator<ReduxAction> = (teamId: string, planAssignment: TeamPlanAssignment) => ({
  type: TEAMS_ACTIONS.SET_TEAM_PLAN_ASSIGNMENT,
  payload: {
    teamId,
    planAssignment,
  },
});

export const updateTeamPlanAssignmentThunkAction =
  (teamId: string, updatedAssignment: TeamPlanAssignment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      const newAssignment = await TeamsRepository.updateTeamPlanAssignment(teamId, updatedAssignment);
      return dispatch(setTeamPlanAssignment(teamId, newAssignment));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

export const createTeamPlanAssignmentThunkAction =
  (teamId: string, planAssignment: TeamPlanAssignment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      const newAssignment = await TeamsRepository.addTeamPlanAssignment(teamId, planAssignment);
      return dispatch(setTeamPlanAssignment(teamId, newAssignment));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };

const deleteTeamPlanAssignmentAction: ActionCreator<ReduxAction> = (
  teamId: string,
  planAssignment: TeamPlanAssignment,
) => ({
  type: TEAMS_ACTIONS.DELETE_TEAM_PLAN_ASSIGNMENT,
  payload: {
    teamId,
    planAssignment,
  },
});

export const deleteTeamPlanAssignmentThunkAction =
  (teamId: string, planAssignment: TeamPlanAssignment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(teamsStart());

    try {
      await TeamsRepository.removeTeamPlanAssignment(teamId, planAssignment);
      return dispatch(deleteTeamPlanAssignmentAction(teamId, planAssignment));
    } catch (error) {
      return dispatch(teamsError(error));
    }
  };
