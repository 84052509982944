import { Box, Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { max } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomReportColumn, formatTotalAmount } from '@amal-ia/lib-types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { type StatementHistogramConfig, type StatementHistogramItem } from './statementHistogram.types';
import { StatementHistogramBar } from './StatementHistogramBar';
import { StatementHistogramExpanded } from './StatementHistogramExpanded';

const useStyles = makeStyles({
  averageContainer: {
    marginLeft: 10,
    position: 'relative',
    width: '4rem',
  },
  averageContent: {
    position: 'absolute',
  },
  nonWideGraph: {
    display: 'grid',
    rowGap: '5px',
    gridTemplateRows: '1fr auto',
    gridAutoFlow: 'column',
    position: 'relative',
    justifyContent: 'center',
  },
  noaverage: {
    position: 'absolute',
    zIndex: 1,
    borderBottom: '1px dashed rgba(0, 0, 0, 0.6)',
    height: 1,
    width: '100%',
  },
});

const buildConfig = (columns: number, isMobile: boolean): StatementHistogramConfig => {
  const gridGap = isMobile ? 1 : 20 - columns;

  return {
    barHeight: 135,
    barWidth: isMobile ? 5 : 10,
    barContainerWidth: isMobile ? 6 : 21,
    gridGap,
  };
};

const evaluateAverage = (statements: StatementHistogramItem[], evaluateNegativeAmounts: boolean = true): number =>
  statements.reduce(
    (sum: number, statement) => sum + max(evaluateNegativeAmounts ? [statement.total] : [statement.total, 0]),
    0,
  ) / statements.length;

interface StatementHistogramProps {
  items: StatementHistogramItem[];
  definitions: Record<string, CustomReportColumn>;
  onClickBar: (period: string) => void;
  wideChart?: boolean;
  user?: UserComputed;
  planName?: string;
  containsHoldRules?: boolean;
}

export const StatementHistogram = memo(function StatementHistogram({
  items,
  definitions,
  onClickBar,
  wideChart,
  user,
  planName,
  containsHoldRules,
}: StatementHistogramProps) {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1500px)');

  const memoedStatements = useMemo(() => items.slice(-12), [items]);
  const averageForGraph = useMemo(() => evaluateAverage(memoedStatements, false), [memoedStatements]);
  const averageToPrint = useMemo(() => evaluateAverage(memoedStatements), [memoedStatements]);

  const formattedAverage = useMemo(() => {
    try {
      const { format, currency } = memoedStatements[0];
      return formatTotalAmount(
        format === FormatsEnum.currency ? Math.round(averageToPrint) : averageToPrint,
        format,
        currency,
      );
    } catch {
      return 0;
    }
  }, [averageToPrint, memoedStatements]);

  const maxValue = useMemo(() => max(memoedStatements.map((s) => s.total)) || 0, [memoedStatements]);

  const averagePositiontop = useMemo(
    () => (maxValue ? 1 - averageForGraph / maxValue : 0),
    [maxValue, averageForGraph],
  );

  const config = useMemo(() => buildConfig(memoedStatements.length, isMobile), [memoedStatements, isMobile]);

  // The width of the divider is the number of bars multiplied by the width of a bar + the width of a gap between two bars.
  // We need to subtract one grid gap at the end because the gap is in-between elements.
  const averageDividerWidth = useMemo(
    () => (config.barContainerWidth + config.gridGap) * items.length,
    [items, config],
  );

  return wideChart ? (
    <StatementHistogramExpanded
      containsHoldRules={containsHoldRules}
      definitions={definitions}
      items={items}
      planName={planName}
      user={user}
    />
  ) : (
    <Grid>
      <Grid lg={12}>
        <Box
          className={classes.nonWideGraph}
          sx={{
            gridTemplateColumns: `repeat(${memoedStatements.length}, ${config.barContainerWidth}) auto`,
            gridGap: config.gridGap,
            alignSelf: 'center',
          }}
        >
          {averageForGraph === averageToPrint && (
            <Box
              className={classes.noaverage}
              sx={{
                top: config.barHeight * averagePositiontop,
                width: averageDividerWidth,
              }}
            />
          )}

          {memoedStatements.map((statement) => (
            <Fragment key={`${statement.period}-${statement.year}`}>
              <StatementHistogramBar
                config={config}
                maximumAmount={maxValue}
                statement={statement}
                onClickBar={onClickBar}
              />
              <span style={{ textAlign: 'center' }}>{statement.period}</span>
            </Fragment>
          ))}

          <div className={classes.averageContainer}>
            <div
              className={classes.averageContent}
              style={{ top: `calc(${averagePositiontop * 100}% - 1.5em)`, right: 0 }}
            >
              <FormattedMessage defaultMessage="Average" />
              <br />
              {formattedAverage}
            </div>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
});
