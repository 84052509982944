import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { formatValue, type PayoutAndPerformanceChartStatisticsRecord } from '@amal-ia/lib-types';

import { cardMessages } from './Card.messages';

type CardTitleProps = Readonly<{
  record: PayoutAndPerformanceChartStatisticsRecord;
  subTitle?: string;
  userCurrency?: CurrencySymbolsEnum;
}>;
export const CardTitle = memo(function TabCard({ record, subTitle, userCurrency }: CardTitleProps) {
  const hasData = !!record;

  const title = formatValue(
    record?.ruleMetricPayment__value?.value ?? 0,
    FormatsEnum.currency,
    record?.ruleMetricPayment__value?.symbol ?? userCurrency,
  );

  return (
    <div>
      <Typography variant={TypographyVariant.HEADING_2_BOLD}>
        {hasData ? title : <FormattedMessage {...cardMessages.NO_DATA} />}
      </Typography>
      <Typography variant={TypographyVariant.BODY_BASE_REGULAR}>{subTitle}</Typography>
    </div>
  );
});
