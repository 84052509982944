import invariant from 'invariant';
import { createContext, useContext } from 'react';

import { type KeysOfType } from '@amal-ia/ext/typescript';

import { type RowKey, type RowData } from './Table.types';

export type TableContextValue<TData extends RowData = RowData, TKey extends RowKey = RowKey> = {
  /** Total number of header rows and data rows. */
  totalRowsCount: number;
  /** Number of header rows. */
  headerRowsCount: number;
  /** Number of data rows. */
  dataRowsCount: number;
  /** Is the table in loading state. */
  isLoading: boolean;
  /** Should make the first column sticky. */
  pinFirstColumn: boolean;
  /** Get row key. */
  rowKey: KeysOfType<TData, TKey> | ((row: TData) => TKey);
};

export const TableContext = createContext<TableContextValue | null>(null);

export const useTableContext = <TData extends RowData = RowData, TKey extends RowKey = RowKey>(): TableContextValue<
  TData,
  TKey
> => {
  const context = useContext(TableContext);
  invariant(context, 'Table components cannot be rendered outside the Table component');

  // We cannot make sure that the context respects the given generic type so just cast it.
  return context as unknown as TableContextValue<TData, TKey>;
};
