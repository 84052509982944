import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { auditRoutesMessages } from './audit.routes.messages';

const AuditContainer = lazy(() => import('./AuditContainer'));

export const AuditRoutes = memo(function AuditRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="audit">
      <Switch>
        <CompanyRoute
          component={AuditContainer}
          path={routes.AUDIT}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Audit)}
          title={formatMessage(auditRoutesMessages.AUDIT)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
