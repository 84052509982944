import { Box } from '@mui/material';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { CustomReportsPresetsEnum } from '@amal-ia/lib-types';
import { DonutChart } from '@amal-ia/lib-ui';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';
import { WidgetTitle } from '../WidgetTitle/WidgetTitle';

interface BenchmarkData {
  name: string;
  value: number;
}
type BenchmarkByPlanProps = {
  currency?: CurrencySymbolsEnum;
  data: BenchmarkData[];
  loading?: boolean;
  user: Partial<UserContract>;
};

export const BenchmarkByPlan = memo(function BenchmarkByPlan({
  currency = CurrencySymbolsEnum.EUR,
  data,
  loading = true,
  user,
}: BenchmarkByPlanProps) {
  const cleanedData = useMemo(() => data.filter((row) => row.value >= 0), [data]);

  return (
    <DashboardWidget>
      <WidgetTitle
        customReportId={CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_PLAN}
        title={<FormattedMessage defaultMessage="Benchmark by plans" />}
        userRole={user.role}
      />

      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        justifyContent="center"
        width="100%"
      >
        <DonutChart
          currency={currency}
          data={cleanedData}
          donutPadding={100}
          hideTooltip={false}
          innerRadius={80}
          loading={loading}
          thickness={15}
        />
      </Box>
    </DashboardWidget>
  );
});
