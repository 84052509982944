import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch, useLocation } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { statementsRoutesMessages } from './statements.routes.messages';

const StatementDetail = lazy(() => import('./detail/StatementDetail'));
const Statements = lazy(() => import('./list/Statements'));
const StatementNoDetail = lazy(() => import('./noDetail/StatementNoDetail'));

export const StatementsRoutes = memo(function StatementsRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();
  const isForecast = useLocation().pathname.includes('forecasts');

  return (
    <Layout currentPage={isForecast ? 'forecasts' : 'statements'}>
      <AmaliaHead title={formatMessage(statementsRoutesMessages.STATEMENTS)} />
      <Switch>
        <CompanyRoute
          component={StatementDetail}
          path={routes.STATEMENT_COMMENT}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view_comments, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />

        <CompanyRoute
          component={StatementDetail}
          path={routes.STATEMENT_RULE_ACTION_EXTERNAL_ID}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />
        <CompanyRoute
          component={StatementDetail}
          path={routes.STATEMENT_RULE_ACTION}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />

        <CompanyRoute
          component={StatementDetail}
          path={routes.STATEMENT_RULE}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />

        <CompanyRoute
          component={StatementNoDetail}
          path={routes.USER_PERIOD_STATEMENTS}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.STATEMENTS)}
          user={user}
        />
        <CompanyRoute
          component={StatementDetail}
          path={routes.STATEMENT}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />

        <CompanyRoute
          component={Statements}
          path={routes.FORECASTS_BY_DATE}
          title={formatMessage(statementsRoutesMessages.FORECASTS)}
          user={user}
        />
        <CompanyRoute
          component={StatementDetail}
          path={routes.FORECAST}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.FORECAST)}
          user={user}
        />
        <CompanyRoute
          component={Statements}
          path={routes.FORECASTS}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.FORECAST)}
          user={user}
        />
        <CompanyRoute
          component={Statements}
          path={routes.STATEMENTS_BY_DATE}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />
        <CompanyRoute
          component={StatementDetail}
          path={routes.STATEMENT}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Statement)}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />
        <CompanyRoute
          component={Statements}
          path={routes.STATEMENTS}
          title={formatMessage(statementsRoutesMessages.STATEMENT)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
