import { type Theme, css } from '@emotion/react';
import { IconX } from '@tabler/icons-react';
import { memo, type ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconButton, Switch, Typography } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { type CommentThread, type MessageContent, type Statement, type VariableObjectsEnum } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { messages } from './CommentDrawerPresentation.messages';
import { CommentScope } from './CommentScope';
import { CommentFormPresentation } from './form/CommentFormPresentation';

const rootContainer = (theme: Theme) => css`
  background: ${theme.ds.colors.gray[0]};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const header = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.ds.colors.gray[100]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding: 16px;
  padding-left: 24px;
`;

const titleContainer = css`
  display: flex;
  padding: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const commentList = (theme: Theme) => css`
  border-top: 1px solid ${theme.ds.colors.gray[100]};
  flex: 1;
  overflow-y: auto;
`;

type CommentDrawerPresentationProps = {
  thread: CommentThread | null;
  closeOpenedThread: () => void;
  children: ReactNode;
  handleNewComment: (values: MessageContent[]) => Promise<void> | void;
  isReviewed: boolean;
  setIsReviewed: (status: boolean) => void;
  // Object that take `title: value` to be printed
  commentScope?: {
    rule?: string;
    variable?: string;
    dataset?: string;
    dataSource?: {
      label: string;
      link: unknown;
      type: VariableObjectsEnum;
    };
  };
  getUsersAllowedToViewStatement: (statementId: string) => Promise<Partial<UserContract>[]>;
  currentStatement?: Statement;
};

// Presentation layer of the comment drawer
export const CommentDrawerPresentation = memo(function CommentDrawerPresentation({
  children,
  thread,
  closeOpenedThread,
  handleNewComment,
  isReviewed,
  setIsReviewed,
  commentScope,
  getUsersAllowedToViewStatement,
  currentStatement,
}: CommentDrawerPresentationProps) {
  const ability = useAbilityContext();
  const { formatMessage } = useIntl();

  const canReviewThread = useMemo(
    () => thread?.id && ability.can(ActionsEnum.review_comments, SubjectsEnum.Statement),
    [thread, ability],
  );

  return (
    <div css={rootContainer}>
      <div>
        <div css={header}>
          <div css={titleContainer}>
            <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>
              <FormattedMessage defaultMessage="Comments" />
            </Typography>
            <IconButton
              icon={<IconX />}
              label={formatMessage(messages.CLOSE_COMMENT_DRAWER)}
              onClick={closeOpenedThread}
            />
          </div>

          {!!commentScope && <CommentScope commentScope={commentScope} />}

          <Switch
            checked={isReviewed}
            disabled={!canReviewThread}
            label={formatMessage({ defaultMessage: 'Reviewed' })}
            size={Switch.Size.SMALL}
            onChange={setIsReviewed}
          />
        </div>
      </div>

      {/* Shows children (thread messages) */}
      <div
        css={commentList}
        id="commentList"
      >
        {children}
      </div>

      {/* New message form */}
      {/* FIXME: Uggly ability but i fix what i can first, then i'll refactor */}
      {ability.can(ActionsEnum.modify_comments, SubjectsEnum.Statement) ? (
        <CommentFormPresentation
          currentStatement={currentStatement}
          getUsersAllowedToViewStatement={getUsersAllowedToViewStatement}
          onSubmit={handleNewComment}
        />
      ) : null}
    </div>
  );
});
