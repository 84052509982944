import { type RootState } from '@amal-ia/lib-types';

import { type Currency } from '../../types/currency';

export const selectCurrencyRate = (state: RootState): number => state.currencies.currencyRate;

export const selectCurrenciesWithCompanyCurrencies = (state: RootState): Currency[] =>
  state.currencies.currenciesWithCompanyCurrencies;

export const selectCurrencyIsLoading = (state: RootState): boolean => !!state.currencies.isLoading;
