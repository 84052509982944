import { Box } from '@mui/material';
import { memo } from 'react';
import { FormattedList } from 'react-intl';

import { formatUserFullName } from '@amal-ia/lib-types';
import { AvatarGroup } from '@amal-ia/lib-ui';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { useMiniCardStyles } from './useMiniCardStyles';

interface MiniCardGroupProps {
  users: UserComputed[];
  subTitle?: string;
}

export const MiniCardGroup = memo(function MiniCardGroup({ users, subTitle }: MiniCardGroupProps) {
  const classes = useMiniCardStyles();

  return (
    <Box className={classes.root}>
      <Box>
        <AvatarGroup
          max={3}
          // If no user is currently selected, show all of them.
          users={users || []}
        />
      </Box>
      <Box className={classes.cardDetails}>
        <Box>
          <FormattedList
            style="short"
            type="unit"
            value={users.map((u) => formatUserFullName(u))}
          />
        </Box>
        <Box>{subTitle}</Box>
      </Box>
    </Box>
  );
});
