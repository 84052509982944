import { defineMessages } from 'react-intl';

export const flowsRoutesMessages = defineMessages({
  CREATE_FLOW: {
    defaultMessage: 'Create Flow',
  },
  FLOW: {
    defaultMessage: 'Flow',
  },
  FLOWS: {
    defaultMessage: 'Flows',
  },
} as const);
