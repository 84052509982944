import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

interface ListProps {
  className?: string;
  innerRef?: any;
  mode?: 'horizontalNoWrap' | 'horizontalWrap' | 'vertical';
  children?: ReactNode;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  list: {
    width: '100%',
  },
  horizontal: {
    display: 'flex',
    '& $listElement': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  horizontalWrap: {
    flexWrap: 'wrap',
  },
  horizontalNoWrap: {
    maxWidth: '100%',
    overflow: 'auto',
  },
  listElement: {},
}));

export const List = memo(function List({ children, className, mode, innerRef, ...props }: ListProps) {
  const classes = useStyles();
  return (
    <div
      ref={innerRef}
      className={clsx(
        classes.list,
        (mode === 'horizontalWrap' || mode === 'horizontalNoWrap') && classes.horizontal,
        mode === 'horizontalWrap' && classes.horizontalWrap,
        mode === 'horizontalNoWrap' && classes.horizontalNoWrap,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});
