import { type ReactNode, memo, isValidElement } from 'react';

import { CellText } from '../../../cell-helpers/cell-text/CellText';
import { type ColumnDefinition, type RowData } from '../../../Table.types';

const getValue = <TData extends RowData>(row: TData, column: ColumnDefinition<TData>) => {
  if ('accessorKey' in column) {
    return row[column.accessorKey];
  }

  if ('accessorFn' in column) {
    return column.accessorFn(row);
  }

  return undefined;
};

export type TableBodyRenderCellProps<TData extends RowData> = {
  column: ColumnDefinition<TData>;
  row: TData;
  cellIndex: number;
  rowIndex: number;
};

const TableBodyRenderCellBase = function TableBodyRenderCell<TData extends RowData>({
  column,
  row,
  cellIndex,
  rowIndex,
}: TableBodyRenderCellProps<TData>) {
  const renderValue = column.cell
    ? column.cell({
        cellIndex,
        row,
        rowIndex,
        value: getValue(row, column),
      })
    : getValue(row, column);

  // If the returned value is a react element, we assume it's handling its own padding/overflow/etc.
  // If not, we wrap it with CellText for ease of use.
  return isValidElement(renderValue) ? renderValue : <CellText>{renderValue as ReactNode}</CellText>;
};

export const TableBodyRenderCell = memo(TableBodyRenderCellBase) as typeof TableBodyRenderCellBase;
