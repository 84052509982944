import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: `${theme.palette.grey['100']}`,
    marginTop: '0.5em',
  },
}));

interface SearchModalListElementProps {
  children: ReactNode;
}

export const SearchModalListElement = memo(function SearchModalListElement({
  children,
  ...props
}: SearchModalListElementProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.listElement}
      {...props}
    >
      {children}
    </div>
  );
});
