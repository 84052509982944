import { type AccessorNode, type ConstantNode } from 'mathjs';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';
import { getValueOrFormula } from '../../utils';
import { getRowFieldAmount, getTableSlice } from '../common';

const func = new AmaliaFunction('rowTiersIndex', AmaliaFunctionCategory.INDICES);

func.nbParamsRequired = 5;

func.description =
  'This function will get the index at which the `dataObject` is present ' +
  'in the `filteredRows`, and will compute a cumulative sum of the `fieldToSum` until this ' +
  'row, included. It will then apply the `table` to this cumulative sum.';

func.params = [
  { name: 'dataObject', description: 'Data Object' },
  { name: 'table', description: 'Table for indices' },
  { name: 'rows', description: 'Filter of records: can be sorted' },
  { name: 'fieldToSum', description: 'Field usd for cumulative sum' },
  { name: 'uniqueId', description: 'A unique id used by Amalia calculation system to retrieve the current line' },
  {
    name: 'startFrom',
    description: "Row tiers index computation will start cumulative sum from this value. By default it's 0",
    defaultValue: 0,
  },
];

func.examples = [
  {
    desc: 'Performs a tier based calculation of Amount Adjusted from opportunity based on the filter closedInPeriod starting from the oldest closing date.',
    formula:
      'rowTiersIndex(opportunity, statement.commissionTable, SORT(filter.closedInPeriod, "closingDate"), "amountAdjusted", "id")' as AmaliaFormula,
  },
  {
    desc: 'Performs a tier based calculation of Amount Adjusted from opportunity based on the filter closedInPeriod starting from the oldest closing date and from record 2000.',
    formula:
      'rowTiersIndex(opportunity, statement.commissionTable, SORT(filter.closedInPeriod, "closingDate"), "amountAdjusted", "id", 2000)' as AmaliaFormula,
  },
];

func.generateComputedFunctionResult = (args) => ({
  array: getValueOrFormula(args[2]),
  formula: `${(args[0] as AccessorNode).name}.${(args[3] as ConstantNode).value}` as AmaliaFormula,
});

func.exec = (
  rowToCompute: any,
  table: any[],
  rows: any[],
  fieldToSum: string,
  uniqueId: string,
  startFromRaw: number,
): number => {
  const startFrom = startFromRaw || 0;

  // 1- sum all filter rows to get correct slice index to apply.
  // Retrieve rows until row to compare
  const indexRowToCompute = rows.findIndex((row) => row[uniqueId] === rowToCompute[uniqueId]);
  const sumPreviousRows =
    rows.slice(0, indexRowToCompute).reduce((acc, row) => acc + getRowFieldAmount(row, fieldToSum), 0) + startFrom;

  const currentRowValue = getRowFieldAmount(rowToCompute, fieldToSum);
  const sumWithCurrentRow = sumPreviousRows + currentRowValue;
  const currentSlice = table
    .slice()
    .reverse()
    .map((slice) => getTableSlice(slice))
    .find((tableSlice) => sumWithCurrentRow >= tableSlice.min);
  if (!currentSlice) {
    return 0;
  }

  return currentSlice.percent;
};

export default func;
