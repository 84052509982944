import { IconMessageCheck, IconMessage, IconWriting } from '@tabler/icons-react';
import { noop } from 'lodash';
import { Fragment, memo } from 'react';

import { IconButton } from '@amal-ia/frontend/design-system/components';

import { KpiRowItem } from '../../kpi-row-item/KpiRowItem';

import * as styles from './KpiItem.styles';

export type KpiItemProps = {
  canComment?: boolean;
  canOverwrite?: boolean;
  isThreadOpen?: boolean;
  isThreadReviewed?: boolean;
  isOverwritten?: boolean;
  isForecasted?: boolean;
  commentTooltipText?: string;
  overwriteTooltipText?: string;
  name: string;
  rawValue?: number | string;
  value: number | string;
  onClickComment?: () => void;
  onClickOverwrite?: () => void;
};

export const KpiItem = memo(function KpiItem({
  canComment = false,
  canOverwrite = false,
  isThreadOpen = false,
  isThreadReviewed = false,
  isOverwritten = false,
  isForecasted = false,
  commentTooltipText,
  overwriteTooltipText,
  name,
  rawValue,
  value,
  onClickComment = noop,
  onClickOverwrite = noop,
}: KpiItemProps) {
  const kpiStyles = [isOverwritten && styles.overwritten, isForecasted && styles.forecasted].filter(Boolean);

  return (
    <KpiRowItem
      dataKpiKey={name}
      kpiStyles={kpiStyles}
      name={name}
      rawValue={rawValue}
      value={value}
      kpiActions={
        <Fragment>
          {canComment ? (
            <IconButton
              withBackground
              css={styles.actionIcon(isThreadOpen)}
              icon={isThreadReviewed ? <IconMessageCheck /> : <IconMessage />}
              label={commentTooltipText}
              onClick={onClickComment}
            />
          ) : null}
          {canOverwrite ? (
            <IconButton
              withBackground
              css={styles.actionIcon()}
              icon={<IconWriting />}
              label={overwriteTooltipText}
              onClick={onClickOverwrite}
            />
          ) : null}
        </Fragment>
      }
    />
  );
});
