import { type ForwardedRef, forwardRef, memo } from 'react';

import { Link, type LinkProps } from '@amal-ia/ext/react-router-dom';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { Button, type ButtonProps } from '../../general/button/Button';

import * as styles from './ButtonLink.styles';

export type ButtonLinkProps = MergeAll<[ButtonProps, Pick<LinkProps, 'to'>]>;

const ButtonLinkForwardRef = forwardRef(function ButtonLink(
  { to, children, ...props }: ButtonLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <Link
      ref={ref}
      css={styles.buttonLink}
      to={to}
    >
      <Button {...props}>{children}</Button>
    </Link>
  );
});

export const ButtonLink = Object.assign(memo(ButtonLinkForwardRef), {
  IconPosition: Button.IconPosition,
  Size: Button.Size,
  Variant: Button.Variant,
});
