import { http } from '@amal-ia/frontend/kernel/http';
import {
  type CommentThreadMessage,
  type PaginatedResponse,
  type StatementThread,
  type StatementThreadScope,
} from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export const getThreadsForStatement = async (statementId: string): Promise<StatementThread[]> => {
  const { data } = await http.get(`/statements/${statementId}/statementthreads`);
  return data;
};

export async function getStatementThreads(
  filters: string[],
  searchText?: string,
  page = 0,
  limit = 10,
  sortColumn?: string,
  sortOrder = 'asc',
): Promise<PaginatedResponse<Partial<StatementThread>>> {
  let url = `/statementthreads?page=${page}&limit=${limit}`;

  Object.values(filters).forEach((filter: string) => {
    url += `&filter=${filter}`;
  });

  if (sortColumn) {
    url += `&sort=${sortColumn}&${sortOrder}`;
  }

  if (searchText) {
    url += `&q=${searchText}`;
  }

  const { items: responseItems, totalItems, pageCount } = (await http.get(url)).data;

  if (!responseItems) {
    return {
      totalItems: 0,
      items: [],
      pageCount: 0,
    };
  }

  return {
    totalItems,
    items: responseItems,
    pageCount,
  };
}

export const setThreadIsReviewed = async (
  statementId: string,
  statementThreadId: string,
  isReviewed: boolean,
): Promise<StatementThread> => {
  const { data } = await http.patch(`/statements/${statementId}/statementthreads/${statementThreadId}`, {
    reviewed: isReviewed,
  });
  return data;
};

export const createThreadOnStatement = async (
  statementId: string,
  scope?: StatementThreadScope,
): Promise<StatementThread> => {
  const { data } = await http.post(`/statements/${statementId}/statementthreads`, { scope });
  return data;
};

export const getStatementThread = async (statementId: string, statementThreadId: string): Promise<StatementThread> => {
  const { data } = await http.get(`/statements/${statementId}/statementthreads/${statementThreadId}`);
  return data;
};

export const getStatementThreadMessages = async (
  statementId: string,
  statementThreadId: string,
): Promise<CommentThreadMessage[]> => {
  const { data } = await http.get(`/statements/${statementId}/statementthreads/${statementThreadId}/messages`);
  return data;
};

export const postMessageOnStatementThread = async (
  statementId: string,
  statementThreadId: string,
  message: Pick<CommentThreadMessage, 'content'>,
) => {
  const { data } = await http.post(
    `/statements/${statementId}/statementthreads/${statementThreadId}/messages`,
    message,
  );
  return data;
};

export const getUsersAllowedToViewStatement = async (statementId: string): Promise<Partial<UserContract>[]> => {
  const { data } = await http.get(`/statements/${statementId}/statementthreads/usersAllowedToView`);
  return data;
};
