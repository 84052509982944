import invariant from 'invariant';
import { memo } from 'react';

import { Badge } from '@amal-ia/frontend/design-system/components';
import { UserRole } from '@amal-ia/tenants/users/shared/types';

import { UserRoleLabel } from './UserRoleLabel';

export type UserRoleBadgeProps = {
  role: UserRole;
};

type BadgeHue = (typeof Badge.Hue)[keyof typeof Badge.Hue];

export const UserRoleBadgeVariantRecord: Record<UserRole, BadgeHue> = {
  [UserRole.ADMIN]: Badge.Hue.ORANGE,
  [UserRole.READ_ONLY_ADMIN]: Badge.Hue.ORANGE,
  [UserRole.FINANCE]: Badge.Hue.CYAN,
  [UserRole.MANAGER]: Badge.Hue.MAGENTA,
  [UserRole.READ_ONLY_MANAGER]: Badge.Hue.MAGENTA,
  [UserRole.EMPLOYEE]: Badge.Hue.BLUE,
  [UserRole.READ_ONLY_EMPLOYEE]: Badge.Hue.BLUE,
} as const;

export const UserRoleBadge = memo(function UserRoleBadge({ role }: UserRoleBadgeProps) {
  invariant(role in UserRoleBadgeVariantRecord, `Invalid role: ${role}`);

  const badgeVariant = UserRoleBadgeVariantRecord[role];

  return (
    <Badge
      size={Badge.Size.MEDIUM}
      variant={badgeVariant}
    >
      <UserRoleLabel role={role} />
    </Badge>
  );
});
