import { type ReactElement, type ComponentType, type ReactNode } from 'react';
import { type MessageDescriptor } from 'react-intl';

import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';

export enum DesignerObjectsActions {
  ASSIGN = 'ASSIGN',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT',
  DUPLICATE = 'DUPLICATE',
  EDIT = 'EDIT',
  EDIT_ACHIEVED = 'EDIT_ACHIEVED',
  EDIT_DESCRIPTION = 'EDIT_DESCRIPTION',
  FORECAST = 'FORECAST',
  OPEN = 'OPEN',
  REMOVE_FROM_RULE = 'REMOVE_FROM_RULE',
}

export enum TokenType {
  VARIABLE = 'VARIABLE',
  FILTER = 'FILTER',
  RULE = 'RULE',
  LINK = 'LINK',
  FIELD = 'FIELD',
  QUOTA = 'QUOTA',
  FUNCTION = 'FUNCTION',
  KEYWORD = 'KEYWORD',
  PROPERTY = 'PROPERTY',
  VIRTUAL_PROPERTY = 'VIRTUAL_PROPERTY',
  PLAN = 'PLAN',
  OBJECT = 'OBJECT',
}
export interface DesignerCustomObject {
  type: TokenType;
  object: CustomObjectDefinition;
}

export interface DesignerObjectDetails {
  key: string;
  icon: ComponentType<{ className?: string }>;
  singular: string;
  plural: string;
  color?: string;
  textColor?: string;
  fieldsToSearchIn?: string[];
  group?: {
    field: string | ((object: any) => string);
    format?: (value: any) => MessageDescriptor | string;
  };
  sortField: string;
  onCopy?: (object: any) => string;
  getDescription?: (object: any) => string;
  possibleActions: DesignerObjectsActions[];
}

export interface DesignerHeaderActionsProps {
  compute: () => void;
  isComputing: boolean;
  onClickImportRule?: () => void;
  onClickCreateCategory: () => void;
  currentStatementId?: string;
  onClickSettings?: () => void;
  computeButtonTooltip: ReactNode;
}

export type DesignerActionToRender = {
  label: string;
  callback: (event?: any, id?: string) => any;
  children: ReactNode;
  action: DesignerObjectsActions;
  icon?: ReactElement;
  disabled?: boolean;
};

export interface DesignerOnSubmitOptions {
  refreshPlanId?: string;
}

export enum LibraryContextValues {
  CURRENT_PLAN = 'current',
  ALL_PLANS = 'all',
}
