import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';
import { formatCurrencyAmount, PaymentCategory, roundNumber } from '@amal-ia/lib-types';
import { TextType, PAYMENT_CATEGORIES_LABELS } from '@amal-ia/lib-ui';
import { ForecastedKpiTooltip, Payout } from '@amal-ia/lib-ui-business';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface StatementHeaderPayoutForecastedProps {
  forecastedTotal: CurrencyValue;

  statementTotal: CurrencyValue;
}

export default memo(function StatementHeaderPayoutForecasted({
  forecastedTotal,
  statementTotal,
}: StatementHeaderPayoutForecastedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { formattedAchievedValue, formattedForecastedValue } = useMemo(
    () => ({
      formattedForecastedValue: formatCurrencyAmount({
        amount: roundNumber(forecastedTotal.value) || 0,
        currencySymbol: forecastedTotal.symbol,
      }),
      formattedAchievedValue: formatCurrencyAmount({
        amount: roundNumber(statementTotal.value) || 0,
        currencySymbol: statementTotal.symbol,
      }),
    }),
    [forecastedTotal, statementTotal],
  );

  return formattedForecastedValue !== formattedAchievedValue ? (
    <ForecastedKpiTooltip
      isValueForecasted
      formattedAchievedValue={formattedAchievedValue}
      formattedForecastedValue={formattedForecastedValue}
      position="top"
    >
      <Payout
        amount={formattedForecastedValue}
        amountTextType={TextType.FORECASTED_PAYOUT}
        className={classes.root}
        forceColor="unset"
        label={formatMessage(PAYMENT_CATEGORIES_LABELS[PaymentCategory.achievement])}
        LabelNode={formatMessage(
          { defaultMessage: 'Total achieved <bold>forecasted</bold>' },
          {
            bold: (text) => <b>&nbsp;{text}</b>,
          },
        )}
      />
    </ForecastedKpiTooltip>
  ) : (
    <Payout
      amount={formattedForecastedValue}
      amountTextType={TextType.PAYOUT}
      className={classes.root}
      forceColor="unset"
      label={formatMessage(PAYMENT_CATEGORIES_LABELS[PaymentCategory.achievement])}
      LabelNode={formatMessage(
        { defaultMessage: 'Total achieved <bold>forecasted</bold>' },
        {
          bold: (text) => <b>&nbsp;{text}</b>,
        },
      )}
    />
  );
});
