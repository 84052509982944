import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import {
  type PlanRule,
  type PlanRuleCategory,
  PlanRuleChartEnumType,
} from '@amal-ia/compensation-definition/shared/types';
import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';

import { type BadgeConfiguration } from './badges';
import { type PlanAssignment } from './planAssignment';
import { type PlanForecast } from './planForecast';

export interface ComputedHighlightedKpi {
  format: FormatsEnum;

  minimumValue: number;
  maximumValue: number;
  currentValue: number;

  progress: number;

  minimumVariableLabel: string | null;
  maximumVariableLabel: string | null;
  variableLabel: string;
}

export interface PlanRuleCategoryAssignment {
  id: string;
  name: string;
  index: number;
}

export enum HighlightedKpiIdentifier {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface HighlightedKpi {
  identifier: HighlightedKpiIdentifier;
  variableId: string;
  minimumVariableId: string | null;
  maximumVariableId: string | null;
}

export interface IndexedPlanTemplate {
  dependencyIds: string[];
}

export interface PlanTemplateError {
  context: string;
  message: string;
}

export interface Plan {
  id?: string;
  name: string;
  /* True if plan use a variable result instead of total */
  useVariableInsteadOfTotal?: boolean;
  /* Variable to use if useVariableInsteadOfTotal is true */
  totalVariableId?: string;
  rules?: PlanRule[];
  categories?: PlanRuleCategory[];
  highlightedKpis?: HighlightedKpi[];
  badgeConfigurations?: BadgeConfiguration[];
  adjustments_index?: number;
  workflowId?: string;
  planAssignements?: PlanAssignment[];
  linkToPlanDocumentation?: string;
  frequency: PeriodFrequencyEnum;
  categoriesV2?: PlanCategory[];
  planTemplateError?: PlanTemplateError;

  indexedPlanTemplate?: IndexedPlanTemplate;
  indexedWeight?: number;
  manualWeight?: number;
  archived?: boolean;
  forecast?: Partial<PlanForecast>;
  forecastId?: string;
  isForecasted?: boolean;
  calculateBothStatements?: boolean;
  isSimulationEnabled?: boolean;
  isSimulationAddRecordEnabled?: boolean;
}

export interface CreatePlanRequest extends Omit<Plan, 'frequency' | 'id'> {
  duplicateOf?: string;
  frequency?: PeriodFrequencyEnum;
}

export interface PlanCategory {
  name: string;
}

// Indexed by planId.
export type PlansMap = Record<string, Plan>;

export type PlanRuleCategoryMap = Record<string, PlanRuleCategory>;

type PlanRuleChartDetail = { name: string };

export const PlanRuleChartDetails: Record<PlanRuleChartEnumType, PlanRuleChartDetail> = {
  [PlanRuleChartEnumType.TARGET_ACHIEVEMENT]: {
    name: 'Target Achievement',
  },
  [PlanRuleChartEnumType.STATEMENT_METRIC]: {
    name: 'Metric',
  },
};
