import { DataConnectorTypes, DataConnectorCategories } from '@amal-ia/data-capture/connectors/types';

export interface ConnectorDefinition {
  name: string;
  cardTitle: string;
  connectorType: DataConnectorTypes | null;
  isDisabled?: boolean;
  isOAuth2?: boolean;
  maxFilters: number;
  category: DataConnectorCategories;
  isRealTimeSynchronizationSupported?: boolean;
}

const buildUserConnector = (name: string, connectorType: DataConnectorTypes): ConnectorDefinition => ({
  name,
  cardTitle: `Integrates with ${name}`,
  connectorType,
  category: DataConnectorCategories.USER,
  maxFilters: Infinity,
});

const listUserConnectors = () => ({
  [DataConnectorTypes.ADPWORKFORCENOW]: buildUserConnector('ADP Workforce Now', DataConnectorTypes.ADPWORKFORCENOW),
  [DataConnectorTypes.BAMBOOHR]: buildUserConnector('Bamboo', DataConnectorTypes.BAMBOOHR),
  [DataConnectorTypes.SAGEHR]: buildUserConnector('Sage HR', DataConnectorTypes.SAGEHR),
  [DataConnectorTypes.SAPSUCCESSFACTORS]: buildUserConnector(
    'SAP Success Factors',
    DataConnectorTypes.SAPSUCCESSFACTORS,
  ),
  [DataConnectorTypes.WORKDAY]: buildUserConnector('Workday', DataConnectorTypes.WORKDAY),
  [DataConnectorTypes.PERSONIO]: buildUserConnector('Personio', DataConnectorTypes.PERSONIO),
});

export const listConnectorsDefinition = (): Record<string, ConnectorDefinition> => ({
  [DataConnectorTypes.SALESFORCE]: {
    name: 'Salesforce',
    cardTitle: 'Integrates with Salesforce',
    connectorType: DataConnectorTypes.SALESFORCE,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: true,
  },
  [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT]: {
    name: 'Salesforce Second Account',
    cardTitle: 'Integrates with Salesforce using a second account',
    connectorType: DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
    isRealTimeSynchronizationSupported: false,
  },
  [DataConnectorTypes.HUBSPOT]: {
    name: 'Hubspot',
    cardTitle: 'Integrates with Hubspot',
    connectorType: DataConnectorTypes.HUBSPOT,
    category: DataConnectorCategories.DATA,
    // it's 3 but we add another one by default
    maxFilters: 2,
  },
  [DataConnectorTypes.NETSUITE]: {
    name: 'Netsuite',
    cardTitle: 'Integrates with Netsuite',
    connectorType: DataConnectorTypes.NETSUITE,
    category: DataConnectorCategories.DATA,
    isOAuth2: true,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.GOOGLESHEET]: {
    name: 'Googlesheet',
    cardTitle: 'Integrates with GSheets',
    connectorType: DataConnectorTypes.GOOGLESHEET,
    category: DataConnectorCategories.DATA,
    maxFilters: 0,
  },
  [DataConnectorTypes.REDSHIFT]: {
    name: 'Redshift',
    cardTitle: 'Integrates with Redshift',
    connectorType: DataConnectorTypes.REDSHIFT,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.BIGQUERY]: {
    name: 'BigQuery',
    cardTitle: 'Integrates with BigQuery',
    connectorType: DataConnectorTypes.BIGQUERY,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SNOWFLAKE]: {
    name: 'Snowflake',
    cardTitle: 'Integrates with Snowflake',
    connectorType: DataConnectorTypes.SNOWFLAKE,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.POSTGRESQL]: {
    name: 'Postgresql',
    cardTitle: 'Integrates with PostgreSql',
    connectorType: DataConnectorTypes.POSTGRESQL,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.POWERBI]: {
    name: 'Power BI',
    cardTitle: 'Integrates with Power BI',
    connectorType: DataConnectorTypes.POWERBI,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.SQLSERVER]: {
    name: 'SqlServer',
    cardTitle: 'Integrates with SqlServer',
    connectorType: DataConnectorTypes.SQLSERVER,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.MYSQL]: {
    name: 'MySql',
    cardTitle: 'Integrates with Mysql',
    connectorType: DataConnectorTypes.MYSQL,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  [DataConnectorTypes.ZOHO]: {
    name: 'Zoho',
    cardTitle: 'Integrates with Zoho CRM',
    connectorType: DataConnectorTypes.ZOHO,
    category: DataConnectorCategories.DATA,
    maxFilters: Infinity,
  },
  ...listUserConnectors(),
});
