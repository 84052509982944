import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import { type ForwardedRef, forwardRef, memo, type ReactElement, useCallback } from 'react';
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import { useIntl } from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { DatePickerBaseHeader } from './date-picker-base-header/DatePickerBaseHeader';
import { DATE_PICKER_CALENDAR_DEFAULT_PORTAL_ELEMENT_ID } from './DatePickerBase.constants';
import * as styles from './DatePickerBase.styles';

const YEARS_IN_PERIOD = 12;

export type DatePickerBaseRef<TWithRange extends boolean | undefined = undefined> = ReactDatePicker<never, TWithRange>;

export type DatePickerBaseProps<TWithRange extends boolean | undefined = undefined> = MergeAll<
  [
    Omit<
      ReactDatePickerProps<never, TWithRange>,
      'locale' | 'showFourColumnMonthYearPicker' | 'showPopperArrow' | 'yearItemNumber'
    >,
    {
      /** Make portalId nullable so we can opt-out of using portalId. */
      portalId?: ReactDatePickerProps<never, TWithRange>['portalId'] | null;
    },
  ]
>;

const DatePickerBaseForwardRef = forwardRef(function DatePickerBase<TWithRange extends boolean | undefined = undefined>(
  {
    popperPlacement = 'bottom',
    portalId = DATE_PICKER_CALENDAR_DEFAULT_PORTAL_ELEMENT_ID,
    dateFormat = 'P',
    timeFormat = 'p',
    ...props
  }: DatePickerBaseProps<TWithRange>,
  ref: ForwardedRef<DatePickerBaseRef<TWithRange>>,
) {
  const { locale } = useIntl();

  const { showMonthYearPicker, showQuarterYearPicker, showYearPicker } = props;

  const renderCustomHeader: Required<ReactDatePickerProps<never, TWithRange>>['renderCustomHeader'] = useCallback(
    (headerProps) => (
      <DatePickerBaseHeader
        {...headerProps}
        showMonthYearPicker={showMonthYearPicker}
        showQuarterYearPicker={showQuarterYearPicker}
        showYearPicker={showYearPicker}
      />
    ),
    [showMonthYearPicker, showQuarterYearPicker, showYearPicker],
  );

  return (
    <ClassNames>
      {(classNamesContent) => (
        <ReactDatePicker<never, TWithRange>
          {...props}
          ref={ref}
          showFourColumnMonthYearPicker
          dateFormat={dateFormat}
          locale={locale}
          popperClassName={clsx(props.popperClassName, styles.datePickerBasePopover(classNamesContent))}
          popperPlacement={popperPlacement}
          portalId={portalId ?? undefined}
          renderCustomHeader={props.renderCustomHeader || renderCustomHeader}
          showPopperArrow={false}
          timeFormat={timeFormat}
          yearItemNumber={YEARS_IN_PERIOD}
        />
      )}
    </ClassNames>
  );
});

export const DatePickerBase = memo(DatePickerBaseForwardRef) as <TWithRange extends boolean | undefined = undefined>(
  props: DatePickerBaseProps<TWithRange> & { ref?: ForwardedRef<DatePickerBaseRef<TWithRange>> },
) => ReactElement | null;
