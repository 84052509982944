import { type EmptyObject } from 'type-fest';

import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';

import { type PlanAgreementAssignment } from './planAgreementAssignments';
import { type Plan } from './plans';

export enum PlanAgreementActionType {
  ARCHIVE = 'ARCHIVE',
  CONFIRM_AGREEMENT = 'CONFIRM_AGREEMENT',
  FINALIZE = 'FINALIZE',
  SEND_AGREEMENT = 'SEND_AGREEMENT',
  SEND_AGREEMENTS_NOT_SEND_YET = 'SEND_AGREEMENTS_NOT_SEND_YET',
  REGENERATE_AGREEMENTS = 'REGENERATE_AGREEMENTS',
  REFRESH_TEMPLATE = 'REFRESH_TEMPLATE',
}

export type PlanAgreementAction =
  | {
      type:
        | PlanAgreementActionType.ARCHIVE
        | PlanAgreementActionType.CONFIRM_AGREEMENT
        | PlanAgreementActionType.FINALIZE
        | PlanAgreementActionType.REFRESH_TEMPLATE
        | PlanAgreementActionType.SEND_AGREEMENTS_NOT_SEND_YET;
    }
  | {
      type: PlanAgreementActionType.REGENERATE_AGREEMENTS;
      payload: {
        assignmentIds: string[];
      };
    }
  | {
      type: PlanAgreementActionType.SEND_AGREEMENT;
      payload: {
        assignmentId: string;
      };
    };

export enum PlanAgreementConfirmationMethod {
  NO_CONFIRMATION = 'NO_CONFIRMATION',
  IN_APP_CONFIRMATION = 'IN_APP_CONFIRMATION',
  DOC_UPLOAD_CONFIRMATION = 'DOC_UPLOAD_CONFIRMATION',
}

export enum PlanAgreementErrorType {
  CANNOT_ACCESS_TEMPLATE_ERROR = 'CANNOT_ACCESS_TEMPLATE_ERROR',
  COPY_TEMPLATE_ERROR = 'COPY_TEMPLATE_ERROR',
  DOCS_GENERATION_ERROR = 'DOCS_GENERATION_ERROR',
}

export interface PlanAgreementError {
  type: PlanAgreementErrorType;
  message: string;
}

export enum PlanAgreementStatus {
  ARCHIVED = 'ARCHIVED',
  DONE = 'DONE',
  DRAFT = 'DRAFT',
  FINALIZED = 'FINALIZED',
}

export interface PlanAgreement {
  id: string;
  confirmationMethod: PlanAgreementConfirmationMethod;
  error?: PlanAgreementError | null;
  externalGoogleDocId?: string | null;
  googleDocFolderId?: string | null;
  internalGoogleDocId?: string | null;
  name: string;
  plan?: Plan | null;
  planAgreementAssignments: PlanAgreementAssignment[];
  startingAssignmentDate?: Date | null;
  status: PlanAgreementStatus;
  year?: number | null;
  templateKeywords?: KeywordsByTypeParsed | null;
}

export type CreatePlanAgreement = Pick<PlanAgreement, 'name'> & {
  associatedPlanId?: string;
};

export type PatchPlanAgreement = Pick<PlanAgreement, 'externalGoogleDocId' | 'startingAssignmentDate' | 'year'> & {
  associatedPlanId?: string | null;
  confirmationMethod?: PlanAgreementConfirmationMethod;
  name?: string;
};

export enum KeywordType {
  USER = 'USER',
  COMPANY = 'COMPANY',
  PLAN = 'PLAN',
  AGREEMENT = 'AGREEMENT',
  TEAM = 'TEAM',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum UserKeywordProperty {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CURRENCY = 'currency',
  EMAIL = 'email',
  MANAGERS = 'managers',
  PLAN_ASSIGNMENT_STARTDATE = 'planAssignmentStardDate',
  PLAN_ASSIGNMENT_ENDDATE = 'planAssignmentEndDate',
  QUOTAS = 'quotas',
}

export enum CompanyKeywordProperty {
  CURRENCY = 'currency',
  NAME = 'name',
}

export enum PlanKeywordProperty {
  NAME = 'name',
  RULE_NAME = 'ruleName',
  FREQUENCY = 'frequency',
  QUOTAS = 'quotas',
}

export enum AgreementKeywordProperty {
  PERIOD = 'period',
}

export enum TeamKeywordProperty {
  QUOTAS = 'quotas',
  NAME = 'name',
}

export type KeywordFound = {
  rawKeyword: string;
  keyword: string;
};

export type KeywordsByType = {
  user: KeywordFound[];
  company: KeywordFound[];
  plan: KeywordFound[];
  agreement: KeywordFound[];
  team: KeywordFound[];
  miscellaneous: KeywordFound[];
};

export type KeywordsByTypeParsed = {
  user: KeywordParsed<UserKeywordProperty>[];
  company: KeywordParsed<CompanyKeywordProperty>[];
  plan: KeywordParsed<PlanKeywordProperty>[];
  agreement: KeywordParsed<AgreementKeywordProperty>[];
  team: KeywordParsed<TeamKeywordProperty>[];
  miscellaneous: KeywordParsed<any>[];
};

export type KeywordParsed<T extends KeywordProperty> = (T extends PlanKeywordProperty
  ? {
      ruleNumber: number | null;
    }
  : EmptyObject) &
  (T extends PlanKeywordProperty | TeamKeywordProperty | UserKeywordProperty
    ? {
        period: {
          startDate: number | null;
          endDate: number | null;
          frequency: PeriodFrequencyEnum;
        };
        variableMachineName: string | null;
      }
    : EmptyObject) & {
    type: KeywordType;
    property: T | null;
    rawKeyword: string;
  };

type KeywordProperty =
  | AgreementKeywordProperty
  | CompanyKeywordProperty
  | PlanKeywordProperty
  | TeamKeywordProperty
  | UserKeywordProperty;

export type RawKeywordWithValue = {
  rawKeyword: string;
  value: number | string;
};
