import { type ComponentPropsWithoutRef, forwardRef, type ForwardedRef } from 'react';

export type ExternalLinkProps = ComponentPropsWithoutRef<'a'>;

export const ExternalLink = forwardRef(function ExternalLink(
  { href, children, ...props }: ExternalLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <a
      {...props}
      ref={ref}
      href={href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
});
