import { Box } from '@mui/material';
import { memo } from 'react';

import { type Challenge, type ChallengeLeaderboard, type TeamMap } from '@amal-ia/lib-types';
import { LightTooltip } from '@amal-ia/lib-ui';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { UserChallengeElement } from './UserChallengeElement';

interface TeamChallengeProps {
  line: ChallengeLeaderboard;
  usersMap: UsersMap;
  teamsMap: TeamMap;
  challenge: Challenge;
}

export const TeamChallenge = memo(function TeamChallenge({ line, usersMap, teamsMap, challenge }: TeamChallengeProps) {
  const winner = line.position === 1;
  const team = teamsMap[line.teamId];

  if (!team) {
    return null;
  }

  const tooltipContent = line.statementIds.map((s) => {
    const lineInResult = challenge.results.find((r) => r.statementId === s);
    const user = usersMap[lineInResult.userId];

    if (!user) {
      return null;
    }

    return (
      <UserChallengeElement
        key={s}
        padding={2}
        statementId={s}
        user={user}
        winner={winner}
      />
    );
  });

  return (
    <LightTooltip title={<Box display="block">{tooltipContent}</Box>}>
      <Box
        alignItems="center"
        display="flex"
        style={{ cursor: 'pointer' }}
      >
        {team.name}
      </Box>
    </LightTooltip>
  );
});
