import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { isEmpty } from 'lodash';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { toError } from '@amal-ia/ext/typescript';
import { AlertBanner, Button, useSnackbars } from '@amal-ia/frontend/design-system/components';
import { addDatasetRows } from '@amal-ia/frontend/web-data-layers';
import { type CustomObject, type Option } from '@amal-ia/lib-types';
import {
  COMMON_MESSAGES,
  DialogTitleWithCloseButton,
  SearchInput,
  Text,
  TextType,
  useDebouncedInput,
  useStatementDetailContext,
} from '@amal-ia/lib-ui';

import RowsTableContext from '../RowsTableContext';

import { addRecordsModalMessages } from './AddRecordsModal.messages';
import { EmptyModal } from './EmptyModal';
import { SearchRecordResults } from './SearchRecordResults';
import { useSearchRecords } from './useSearchRecords';

interface AddRecordsModalProps {
  statementId: string;
  isOpen: boolean;
}

export const AddRecordsModal = memo(function AddRecordsModal({ statementId, isOpen }: AddRecordsModalProps) {
  const { dataset, toggleAddRecordModal, setAddedRows } = useContext(RowsTableContext);

  const { launchCalculation } = useStatementDetailContext();

  const { snackError } = useSnackbars();

  const [searchValue, setSearchValue] = useState<string | null>();
  const [searchValueDebounced, setSearchValueDebounced] = useState<string | null>();
  const [selectedRecords, setSelectedRecords] = useState<Option[]>([]);
  const { onChange: onDebouncedSearchInputChange } = useDebouncedInput(setSearchValue, setSearchValueDebounced, 1000);

  const { data: searchRecords, isLoading } = useSearchRecords(
    dataset?.customObjectDefinition?.machineName,
    searchValueDebounced,
  );

  const foundRecords = useMemo(
    () =>
      searchRecords?.map((item: CustomObject) => ({
        label: item.name,
        value: item.id,
      })),
    [searchRecords],
  );

  const addRecordsProxy = useCallback(async () => {
    try {
      const newRows = await addDatasetRows(statementId, dataset.id, {
        customObjectIds: selectedRecords?.map((item) => item.value),
      });

      setAddedRows(newRows);

      toggleAddRecordModal();

      await launchCalculation?.();
    } catch (e) {
      snackError(toError(e).message);
    }
  }, [statementId, dataset.id, selectedRecords, setAddedRows, toggleAddRecordModal, launchCalculation, snackError]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={toggleAddRecordModal}
    >
      <DialogTitleWithCloseButton handleClose={toggleAddRecordModal}>
        <Text type={TextType.MODAL_TITLE}>
          <FormattedMessage {...addRecordsModalMessages.ADD_RECORDS} />
        </Text>
      </DialogTitleWithCloseButton>

      <DialogContent>
        <Box py={amaliaTheme.spacing(1)}>
          <AlertBanner variant={AlertBanner.Variant.INFO}>
            <FormattedMessage {...addRecordsModalMessages.ADD_ONE_SEVERAL_RECORDS} />
          </AlertBanner>
        </Box>

        <Box py={amaliaTheme.spacing(1)}>
          <SearchInput
            forceSearchVisible
            showBorder
            value={searchValue}
            onChange={onDebouncedSearchInputChange}
          />
        </Box>

        {!isLoading && isEmpty(foundRecords) ? (
          <EmptyModal search={searchValueDebounced} />
        ) : (
          <SearchRecordResults
            foundRecords={foundRecords}
            isSearchRecordsLoading={isLoading}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant={Button.Variant.LIGHT}
          onClick={toggleAddRecordModal}
        >
          <FormattedMessage {...COMMON_MESSAGES.CANCEL} />
        </Button>
        <Button
          type="submit"
          onClick={addRecordsProxy}
        >
          <FormattedMessage {...COMMON_MESSAGES.APPLY} />
        </Button>
      </DialogActions>
    </Dialog>
  );
});
