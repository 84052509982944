import { useTheme, ClassNames } from '@emotion/react';
import { IconAlertTriangle, IconCircleCheck, IconExclamationCircle, IconInfoCircle } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ReactNode, cloneElement, memo } from 'react';

import { type TablerIconElement } from '../../general/icons/types';
import { Typography } from '../../general/typography/Typography';

import * as styles from './AlertBanner.styles';
import { AlertBannerVariant } from './AlertBanner.types';

const DEFAULT_VARIANT_ICON_MAPPING: Record<AlertBannerVariant, TablerIconElement> = {
  [AlertBannerVariant.INFO]: <IconInfoCircle />,
  [AlertBannerVariant.WARNING]: <IconAlertTriangle />,
  [AlertBannerVariant.ERROR]: <IconExclamationCircle />,
  [AlertBannerVariant.SUCCESS]: <IconCircleCheck />,
};

export type AlertBannerProps = {
  /** Alert variant. */
  variant?: AlertBannerVariant;
  /** Should render as inline-flex. */
  inline?: boolean;
  /** Alert message. Text typography is handled by the component. */
  children: ReactNode;
};

const AlertBannerBase = memo(function AlertBanner({
  variant = AlertBannerVariant.INFO,
  inline = false,
  children,
}: AlertBannerProps) {
  const theme = useTheme();

  return (
    <div
      className={clsx(variant, { [styles.INLINE_CLASSNAME]: inline })}
      css={styles.alertBanner}
    >
      <ClassNames>
        {({ css }) =>
          cloneElement(DEFAULT_VARIANT_ICON_MAPPING[variant], {
            width: 16, // Actual size of icon.
            height: theme.ds.typographies.bodySmallMedium.lineHeight, // Align-center with first line of text.
            color: styles.getIconColor(theme, variant),
            className: css`
              flex: none;
            `,
          })
        }
      </ClassNames>

      <Typography
        as="div"
        variant={Typography.Variant.BODY_SMALL_MEDIUM}
      >
        {children}
      </Typography>
    </div>
  );
});

export const AlertBanner = Object.assign(AlertBannerBase, {
  Variant: AlertBannerVariant,
});
