import { type ActionCreator } from 'redux';

import { RelationshipsApiClient } from '@amal-ia/compensation-definition/api-client';
import { type Relationship } from '@amal-ia/compensation-definition/shared/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import {
  getObjectDefinitions,
  getVirtualObjectDefinitions,
} from '../../services/customObjects/customObjectDefinitions.repository';

import { CUSTOM_OBJECT_DEFINITIONS_ACTIONS } from './constants';

const customObjectsStart: ActionCreator<ReduxAction> = () => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.START,
});

const customObjectsError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.ERROR,
  error,
});

export const setCustomObjectDefinitions: ActionCreator<ReduxAction> = (
  customObjectDefinitions: CustomObjectDefinition[],
) => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_CUSTOM_OBJECT_DEFINITIONS,
  payload: { customObjectDefinitions },
});

export const fetchCustomObjectDefinitions = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(customObjectsStart());
  try {
    const [customObjectDefinitions, virtualCustomObjectDefinitions] = await Promise.all([
      getObjectDefinitions(),
      getVirtualObjectDefinitions(),
    ]);

    return dispatch(setCustomObjectDefinitions([...customObjectDefinitions, ...virtualCustomObjectDefinitions]));
  } catch (error) {
    return dispatch(customObjectsError());
  }
};

export const setRelationships: ActionCreator<ReduxAction> = (relationships: Relationship[]) => ({
  type: CUSTOM_OBJECT_DEFINITIONS_ACTIONS.SET_RELATIONSHIPS,
  payload: { relationships },
});

export const fetchRelationships = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(customObjectsStart());
  try {
    const relationships = await RelationshipsApiClient.list();

    return dispatch(setRelationships(relationships));
  } catch (error) {
    return dispatch(customObjectsError());
  }
};
