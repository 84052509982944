import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCaretLeft } from '@tabler/icons-react';
import { last } from 'lodash';
import { type Dispatch, Fragment, memo, type SetStateAction, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, Typography } from '@amal-ia/frontend/design-system/components';
import {
  type UserStatementsFacets,
  type WorkflowStatementState,
  WorkflowStatementStateAction,
} from '@amal-ia/lib-types';
import { ProgressWithLabel, Switch, Tooltip } from '@amal-ia/lib-ui';
import { type UserContract, UserRole, type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { WorkflowStepper } from '../../../workflow/WorkflowStepper/WorkflowStepper';
import { messages } from '../StatementWorkflow.messages';
import { useWorkflowStepName } from '../useWorkflowStepName';

import * as styles from './StatementListWorkflowPresentation.styles';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
  },
  actions: {
    padding: theme.spacing(2),
  },
  action: {
    marginRight: theme.spacing(2),
  },
}));
interface StatementListWorkflowPresentationProps {
  steps: Partial<WorkflowStatementState>[];
  stepNumber: number;
  onNextStep?: (canValidStep: WorkflowStatementStateAction, checked: boolean) => void;
  onPreviousStep?: () => void;
  hasRightToPreviousStepWorkflow: boolean;
  usersMap: UsersMap;
  currentUser: UserContract;
  tooltipOpened?: boolean;
  setTooltipOpened: Dispatch<SetStateAction<boolean>>;
  userStatementsFacets: UserStatementsFacets;
}

export const StatementListWorkflowPresentation = memo(function StatementListWorkflowPresentation({
  steps,
  stepNumber,
  onNextStep,
  onPreviousStep,
  hasRightToPreviousStepWorkflow,
  currentUser,
  usersMap,
  tooltipOpened,
  setTooltipOpened,
  userStatementsFacets,
}: StatementListWorkflowPresentationProps) {
  const classes = useStyles();

  const [areAllStatementsCompleted, canValidSteps, workflow] = useMemo(
    () => [
      userStatementsFacets.workflow?.workflowCompleted?.unreviewed.count === 0,
      userStatementsFacets.workflow?.canValidSteps || [],
      userStatementsFacets.workflow?.definition,
    ],
    [userStatementsFacets],
  );

  const haveValidatedStep: boolean = useMemo(
    () =>
      !!workflow &&
      canValidSteps.slice(0, stepNumber).filter((step) => step !== WorkflowStatementStateAction.UNAUTHORIZED).length >
        0,
    [canValidSteps, stepNumber, workflow],
  );

  const canValidThisStep = useMemo(() => {
    if (!workflow || !canValidSteps[stepNumber]) {
      return WorkflowStatementStateAction.UNAUTHORIZED;
    }

    return canValidSteps[stepNumber];
  }, [canValidSteps, stepNumber, workflow]);

  const canBackStep: boolean = useMemo(() => {
    if (!onPreviousStep || !hasRightToPreviousStepWorkflow || stepNumber === 0) {
      return false;
    }

    return canValidSteps[stepNumber - 1] !== WorkflowStatementStateAction.UNAUTHORIZED;
  }, [canValidSteps, onPreviousStep, hasRightToPreviousStepWorkflow, stepNumber]);

  const isReviewed: boolean = useMemo(
    () =>
      areAllStatementsCompleted ||
      (haveValidatedStep && canValidThisStep === WorkflowStatementStateAction.UNAUTHORIZED),
    [canValidThisStep, haveValidatedStep, areAllStatementsCompleted],
  );

  const { formatStepName } = useWorkflowStepName();

  // Compute last validated step name.
  const stepToPrint: {
    // Name of the step to be printed
    name: string;
    // Tells if the step name needs to be preceded by the text "Reviewed By"
    printReviewedBy: boolean;
  } = useMemo(() => {
    // If statement is complete, just print "Reviewed"
    if (areAllStatementsCompleted) {
      return {
        name: '',
        printReviewedBy: false,
      };
    }
    // If user hasn't the right to validate current step AND he already has validated a previous step,
    // Show the previous validated step name
    if (isReviewed && haveValidatedStep) {
      const indexOfLastValidatedStep = last(
        // map into [index, right] to filter the step current user had the right to validate, then get its index
        // to get the step name from the workflow steps
        Object.entries(canValidSteps).filter(([, right]) => right !== WorkflowStatementStateAction.UNAUTHORIZED),
      )?.[0];

      if (indexOfLastValidatedStep) {
        return {
          name: formatStepName(
            steps[parseInt(indexOfLastValidatedStep, 10)]?.stepName || steps[stepNumber]?.stepName || '',
          ),
          printReviewedBy: true,
        };
      }
    }
    // Otherwise, toggle is disabled and current step name is printed
    return {
      name: formatStepName(steps[stepNumber]?.stepName || ''),
      printReviewedBy: true,
    };
  }, [haveValidatedStep, canValidSteps, steps, areAllStatementsCompleted, isReviewed, stepNumber, formatStepName]);

  const canToggleStep: boolean = useMemo(() => {
    // If no workflow definition => all statements are not on the same definition
    if (!workflow || steps.length === 0) {
      return false;
    }

    if (isReviewed && stepNumber > 0) {
      const canValidatePreviousStep = canValidSteps[stepNumber - 1] !== WorkflowStatementStateAction.UNAUTHORIZED;

      // User has the right to back one step if he can validate this step OR if it's the last step AND he's admin

      // User can toggle step :
      // - If he is allowed to validate previous step on the workflow definition.
      // In the case where workflow is complete, only non employees users are allowed to unreview the statements.
      return canValidatePreviousStep && (!areAllStatementsCompleted || currentUser.role !== UserRole.EMPLOYEE);
    }

    return canValidThisStep !== WorkflowStatementStateAction.UNAUTHORIZED;
  }, [
    areAllStatementsCompleted,
    currentUser,
    isReviewed,
    canValidThisStep,
    workflow,
    steps,
    canValidSteps,
    stepNumber,
  ]);

  const handleTooltipClose = useCallback(() => {
    setTooltipOpened(false);
  }, [setTooltipOpened]);

  const handleTooltipOpen = useCallback(() => {
    setTooltipOpened(true);
  }, [setTooltipOpened]);

  const onNextStepProxy = useCallback(
    (_, checked: boolean) => onNextStep?.(canValidThisStep, checked),
    [canValidThisStep, onNextStep],
  );

  const isShowProgressBar = steps.length > 1;

  return (
    <div css={styles.presentationContainer}>
      {onNextStep ? (
        <Fragment>
          <div css={styles.reviewSwitch}>
            <Switch
              checked={isReviewed}
              data-testid="statement-list-workflow-switch"
              disabled={!canToggleStep}
              height={20}
              onChange={onNextStepProxy}
            />
          </div>
          <div css={styles.reviewStepTitle}>
            {stepToPrint.printReviewedBy ? (
              <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
                <FormattedMessage
                  {...messages.REVIEW_BY}
                  values={{ reviewer: stepToPrint.name }}
                />
              </Typography>
            ) : null}
            {isReviewed ? (
              <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
                <FormattedMessage {...messages.REVIEWED} />
              </Typography>
            ) : null}
          </div>
        </Fragment>
      ) : null}
      {workflow && isShowProgressBar ? (
        <div css={styles.progressBar}>
          <Tooltip
            open={tooltipOpened}
            title={
              <Fragment>
                <Box>
                  <WorkflowStepper
                    usersMap={usersMap}
                    workflowDefinition={workflow}
                    workflowState={userStatementsFacets.workflow?.steps || []}
                  />
                </Box>
                {canBackStep ? (
                  <Box
                    className={stepNumber > 0 ? classes.actions : undefined}
                    textAlign="right"
                  >
                    <Button
                      icon={<IconCaretLeft />}
                      variant={Button.Variant.LIGHT}
                      onClick={onPreviousStep}
                    >
                      <FormattedMessage defaultMessage="Previous step" />
                    </Button>
                  </Box>
                ) : null}
              </Fragment>
            }
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
          >
            <Box
              alignItems="center"
              display="flex"
              height="50"
              width="100%"
            >
              <ProgressWithLabel
                current={stepNumber}
                total={workflow.steps.length}
              />
            </Box>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
});
