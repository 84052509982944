import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { customReportRoutesMessages } from './customReport.routes.messages';

const CustomReportEdit = lazy(() => import('./edit/CustomReportEdit'));
const CustomReports = lazy(() => import('./list/CustomReportList'));

export const CustomReportRoutes = memo(function CustomReportRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="customReport">
      <Switch>
        <CompanyRoute
          component={CustomReportEdit}
          path={routes.CUSTOM_REPORT_ITEM}
          title={formatMessage(customReportRoutesMessages.CUSTOM_REPORT_ITEM)}
          user={user}
        />
        <CompanyRoute
          component={CustomReports}
          path={routes.CUSTOM_REPORT_LIST}
          title={formatMessage(customReportRoutesMessages.CUSTOM_REPORT_LIST)}
          user={user}
          shouldRedirectToHome={
            ability.cannot(ActionsEnum.view, SubjectsEnum.CustomReport) &&
            ability.cannot(ActionsEnum.modify, SubjectsEnum.CustomReport) &&
            ability.cannot(ActionsEnum.delete, SubjectsEnum.CustomReport)
          }
        />
      </Switch>
    </Layout>
  );
});

export default CustomReportRoutes;
