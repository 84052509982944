import {
  type ListSelectedOptions,
  type PaginationStoredInRedux,
  type PayoutAndPerformanceChartStatistics,
  type RootState,
  type UserStatements,
  type UserStatementsFacets,
} from '@amal-ia/lib-types';

export const selectLastUserStatementsFetchParams = (state: RootState): Record<string, any> =>
  state.userStatements.lastparams;

export const selectUserStatementsList = (state: RootState): UserStatements[] => state.userStatements.userStatementsList;

export const selectUserStatementsListCurrentPage = (state: RootState): PaginationStoredInRedux<UserStatements> =>
  state.userStatements.currentPage;

export const selectUserStatementsListFacet = (state: RootState): UserStatementsFacets =>
  state.userStatements.userStatementsListFacet;

export const selectUserStatementsListStatistics = (state: RootState): PayoutAndPerformanceChartStatistics =>
  state.userStatements.userStatementsListStatistics;

export const selectUserStatementsIsLoading = (state: RootState): boolean => !!state.userStatements.isLoading;

export const selectUserStatementsListSelectedOptions = (state: RootState): ListSelectedOptions =>
  state.userStatements.listSelectedOptions;
