import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@amal-ia/frontend/design-system/components';
import { type AuthenticatedContext } from '@amal-ia/lib-rbac';
import { type UserStatements, type WorkflowMap, type WorkflowStatementState } from '@amal-ia/lib-types';
import { ProgressWithLabel } from '@amal-ia/lib-ui';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { StatementWorkflow } from './workflow/StatementWorkflow/StatementWorkflow';

const useStyles = makeStyles({
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    minWidth: '200px',
    margin: '0 auto',
  },
});

interface UserStatementsProgressBarProps {
  userStatements: UserStatements;
  workflowMap: WorkflowMap;
  usersMap: UsersMap;
  authenticatedContext: AuthenticatedContext;
  workflowDetails: { steps: Partial<WorkflowStatementState>[]; currentStepNumber: number };
}

export const UserStatementsProgressBar = memo(function UserStatementsProgressBar({
  userStatements,
  workflowMap,
  usersMap,
  authenticatedContext,
  workflowDetails,
}: UserStatementsProgressBarProps) {
  const classes = useStyles();

  if (userStatements.lines.length > 1) {
    const nbCompleted = userStatements.lines.filter((s) => s.workflowComplete).length;

    return (
      <Box className={classes.progressBarContainer}>
        <Tooltip
          content={
            <Box
              display="flex"
              flexDirection="column"
              gap={0.5}
            >
              <FormattedMessage
                defaultMessage="Completed workflows: {current, number}/{total, number}"
                values={{ current: nbCompleted, total: userStatements.lines.length }}
              />
            </Box>
          }
        >
          <Box
            alignItems="center"
            display="flex"
            height="50"
            minWidth="200px"
            width="100%"
          >
            <ProgressWithLabel
              current={nbCompleted}
              total={userStatements.lines.length}
            />
          </Box>
        </Tooltip>
      </Box>
    );
  }
  return (
    <StatementWorkflow
      authenticatedContext={authenticatedContext}
      statement={userStatements.lines[0]}
      usersMap={usersMap}
      workflowDetails={workflowDetails}
      workflowMap={workflowMap}
      additionalOptions={{
        forceShowProgressBar: true,
      }}
    />
  );
});
