import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import {
  fetchCustomObjectDefinitions,
  selectCustomObjectDefinitionsMapById,
  selectIsCustomObjectDefinitionsLoading,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { type Overwrite } from '@amal-ia/lib-types';
import { Spinner, SpinnerBoundary, DataGrid, type DataGridPropsOptions } from '@amal-ia/lib-ui';

import OverwritesTableContext, { type OverwritesTableContextInterface } from './OverwritesTableContext';
import OverwriteTableCell from './OverwriteTableCell';

interface OverwritesTableProps {
  rows: Partial<Overwrite>[];
  columns: { name: string; title: string; realSortColumn?: string }[];
  datagridOptions: DataGridPropsOptions;
}

const tableColumnExtensions = [{ columnName: 'customObject', wordWrapEnabled: true }];

const OverwritesTable = memo(function OverwritesTable({ rows, columns, datagridOptions }: OverwritesTableProps) {
  const dispatch = useThunkDispatch();

  const isCustomObjectDefinitionsLoading = useSelector(selectIsCustomObjectDefinitionsLoading);
  const customObjectDefinitionsById = useSelector(selectCustomObjectDefinitionsMapById);

  const contextContent: OverwritesTableContextInterface = useShallowObjectMemo({
    customObjectDefinitionsById,
  });

  useEffect(() => {
    dispatch(fetchCustomObjectDefinitions());
  }, [dispatch]);

  if (isCustomObjectDefinitionsLoading) {
    return (
      <SpinnerBoundary>
        <Spinner />
      </SpinnerBoundary>
    );
  }
  return (
    <OverwritesTableContext.Provider value={contextContent}>
      <DataGrid
        columns={columns}
        id="overwritesTable"
        options={datagridOptions}
        rows={rows}
        tableProps={{ cellComponent: OverwriteTableCell, columnExtensions: tableColumnExtensions }}
      />
    </OverwritesTableContext.Provider>
  );
});

export default OverwritesTable;
