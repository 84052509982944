import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('isNull', AmaliaFunctionCategory.MISC);

// Do not put 1 here because execution will crash if null,
// which is going against the purpose of this function.
func.nbParamsRequired = 0;

func.description = 'Check if parameter is null';

func.params = [
  { name: 'parameter', description: 'Variables, Fields, Properties, Links etc. to check.', defaultValue: null },
];

func.examples = [
  {
    desc: 'Returns true if cancelled date does not exist.',
    formula: 'isNull(opportunity.cancelledDate)' as AmaliaFormula,
  },
  {
    desc: 'Returns false since the parameter (0) is not null.',
    formula: 'isNull(0)' as AmaliaFormula,
    result: false,
  },
  {
    desc: 'Returns true if the link to previous opportunity does not exist.',
    formula: 'isNull(opportunity.previousOpportunity)' as AmaliaFormula,
  },
  {
    desc: 'Returns true since the parameter is null.',
    formula: 'isNull(null)' as AmaliaFormula,
    result: true,
  },
  {
    desc: 'Returns true since the parameter is undefined.',
    formula: 'isNull(undefined)' as AmaliaFormula,
    result: true,
  },
  {
    desc: 'Returns false since the parameter is defined.',
    formula: "isNull('test')" as AmaliaFormula,
    result: false,
  },
];

func.exec = (evaluate: any): boolean =>
  evaluate === null || evaluate === undefined || evaluate === Infinity || evaluate === -Infinity;

export default func;
