import { noop } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import {
  OVERWRITE_CONTEXT,
  type OverwriteCreationRequestDetails,
  OverwriteModalContainer,
} from '@amal-ia/data-capture/overwrites/components';
import { OverwriteScopeEnum } from '@amal-ia/data-capture/overwrites/shared/types';
import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import {
  DataGrid,
  getPageCount,
  SortDirection,
  useDataGridStateInLocalStorage,
  useSnackbars,
} from '@amal-ia/frontend/design-system/components';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { type FiltersType } from '@amal-ia/lib-types';
import { CustomTableContext, type CustomTableContextInterface } from '@amal-ia/lib-ui';

import { useCustomObjectsPage, usePatchCustomObject } from '../../../state/custom-objects/queries';

import CustomTableUtils from './CustomTable.utils';
import { useCustomTableColumns } from './useCustomTableColumns';

interface CustomTableProps {
  objectDefinition: CustomObjectDefinition;
  filters: FiltersType[] | null;
  shouldRefetch?: boolean;
  setShouldRefetchTrue?: () => void;
  // In option show only some fields
  fieldsToDisplay?: string[];
  refetchInterval?: number;
}

export const CustomTable = memo(function CustomTable({
  objectDefinition,
  filters,
  shouldRefetch,
  setShouldRefetchTrue,
  fieldsToDisplay,
  refetchInterval,
}: CustomTableProps) {
  const { snackError } = useSnackbars();
  const { data: currentUser } = useCurrentUser();
  const [overwriteModalInfo, setOverwriteModalInfo] = useState<OverwriteCreationRequestDetails>(null);

  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    columnVisibility,
    setColumnVisibility,
    columnSorting,
    setColumnSorting,
    columnPinning,
    setColumnPinning,
    searchText,
    setSearchText,
  } = useDataGridStateInLocalStorage(objectDefinition.id);

  const {
    data: customObjectsPage,
    isLoading: isCustomObjectsLoading,
    refetch,
  } = useCustomObjectsPage(
    objectDefinition.machineName,
    {
      page,
      search: searchText,
      limit: pageSize,
      sort: columnSorting?.[0]?.id,
      desc: columnSorting?.[0]?.direction === SortDirection.DESC,
    },
    filters,
    true,
    refetchInterval,
  );

  useAsyncEffect(async () => {
    if (shouldRefetch) {
      await refetch();
    }
  }, [shouldRefetch]);

  const { mutateAsync: onPatch } = usePatchCustomObject(objectDefinition.machineName);

  const onCommitChanges = useCallback(
    async ({ changed }: any) => {
      if (changed && objectDefinition?.schema?.properties) {
        const overwriteToCreate = CustomTableUtils.onCommitChanges(
          objectDefinition,
          customObjectsPage,
          { changed },
          snackError,
        );
        if (overwriteToCreate) {
          await onPatch({
            definitionMachineName: objectDefinition.machineName,
            objectExternalId: overwriteToCreate.objectId,
            patch: {
              field: overwriteToCreate.field,
              overwriteValue: overwriteToCreate.overwriteValue,
              scope: OverwriteScopeEnum.GLOBAL,
            },
          }).catch(noop);
        }
      }
    },
    [onPatch, objectDefinition, customObjectsPage, snackError],
  );

  const handleCloseModal = useCallback(() => {
    setOverwriteModalInfo(null);
  }, []);

  useEffect(() => {
    if (!isCustomObjectsLoading && page > customObjectsPage?.totalItems / pageSize) {
      setPage(0);
    }
  }, [customObjectsPage, page, pageSize, isCustomObjectsLoading, setPage]);

  const columns = useCustomTableColumns(objectDefinition, fieldsToDisplay);

  const contextContent: CustomTableContextInterface = useShallowObjectMemo({
    objectDefinition,
    setShouldRefetchTrue,
    customObjectListDetails: customObjectsPage,
    setOverwriteModalInfo,
  });

  return (
    <CustomTableContext.Provider value={contextContent}>
      <DataGrid
        columns={columns}
        data={customObjectsPage?.items || []}
        isLoading={isCustomObjectsLoading || !objectDefinition || !customObjectsPage?.items}
        rowKey="id"
        totalItems={customObjectsPage?.totalItems}
        columnPinning={
          <DataGrid.ColumnPinning
            isActive={columnPinning}
            onChange={setColumnPinning}
          />
        }
        columnSorting={
          <DataGrid.ColumnSorting.Single
            columnSorting={columnSorting}
            onChangeColumnSorting={setColumnSorting}
          />
        }
        columnVisibility={
          <DataGrid.ColumnVisibility
            columnVisibility={columnVisibility}
            onChangeColumnVisibility={setColumnVisibility}
          />
        }
        pageSize={
          <DataGrid.PageSize
            value={pageSize}
            onChange={setPageSize}
          />
        }
        pagination={
          Number.isFinite(customObjectsPage?.totalItems) ? (
            <DataGrid.Pagination
              page={page}
              pageCount={getPageCount(customObjectsPage?.totalItems, pageSize)}
              onChangePage={setPage}
            />
          ) : undefined
        }
        search={
          <DataGrid.Search
            value={searchText}
            onChange={setSearchText}
          />
        }
      />

      {!!overwriteModalInfo && (
        <OverwriteModalContainer
          currentObjectDetails={overwriteModalInfo}
          currentUser={currentUser}
          handleClose={handleCloseModal}
          handleSubmit={onCommitChanges}
          isOpen={!!overwriteModalInfo}
          overwriteContext={OVERWRITE_CONTEXT.DATA}
        />
      )}
    </CustomTableContext.Provider>
  );
});
