import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';

import { VariableObjectsEnum } from '../data/constants';

export interface VariableFormatOptionsColumn {
  machineName: string;
  name: string;
  format: FormatsEnum;
}

export interface VariableFormatOptionsTable extends VariableFormatOptions {
  columns: VariableFormatOptionsColumn[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface -- Could be retyped later.
export interface VariableFormatOptions {}

export const VariableQuotaObjectsEnumLabels = [
  { value: VariableObjectsEnum.user, label: 'User' },
  { value: VariableObjectsEnum.team, label: 'Team' },
  { value: VariableObjectsEnum.plan, label: 'Plan' },
];

export interface Variable {
  id: string;
  description?: string;
  formula: string;
  formatOptions?: VariableFormatOptions;
  frequency?: PeriodFrequencyEnum;
  type: VariableObjectsEnum;
  planId?: string;
  ruleId?: string;

  // If variable is type object, we have the object definition.
  object?: CustomObjectDefinition;

  objectId?: string;
  indexMetric?: boolean;
  isQuotaRequired?: boolean;

  name: string;
  machineName: string;
  format: FormatsEnum;

  isEnum?: boolean;
}

export type VariableDefinition = Pick<
  Variable,
  'description' | 'format' | 'formatOptions' | 'formula' | 'id' | 'indexMetric' | 'machineName' | 'name' | 'type'
>;

export type VariablesMap = Record<string, Variable>;

export interface SyncQuotasRequest {
  userId?: string;

  teamId?: string;

  planId?: string;

  variableId: string;

  value: number | string;

  startDate?: number;

  endDate?: number;

  currency?: CurrencySymbolsEnum;
}

export interface BulkSyncQuotasRequest {
  quotas: SyncQuotasRequest[];
}

export type QuotaType = VariableObjectsEnum.plan | VariableObjectsEnum.team | VariableObjectsEnum.user;

export type Quota = Variable & {
  userId?: string;
  type: QuotaType;
};
