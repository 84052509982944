import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo, forwardRef, type ForwardedRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { DropdownItemContent, type DropdownItemContentProps } from '../dropdown-item-content/DropdownItemContent';
import { DropdownItemContainer } from '../Dropdown.styles';

export type DropdownItemButtonProps = MergeAll<
  [Omit<DropdownItemContentProps, 'isInteractive'>, ComponentPropsWithoutRef<'button'>]
>;

const DropdownItemButtonForwardRef = forwardRef(function DropdownItemButton(
  { label, icon, disabled, isActive, ...props }: DropdownItemButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <DropdownItemContainer>
      <UnstyledButton
        {...props}
        ref={ref}
        disabled={disabled}
        css={(theme) => css`
          width: 100%;
          display: block;
          border-radius: ${theme.ds.borderRadiuses.squared};

          transition: ${theme.ds.transitions.default('box-shadow')};

          &:focus-visible {
            outline: 1px solid ${theme.ds.colors.secondary[200]};
            outline-offset: -1px;
            box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};

            position: relative;
            z-index: 1;
          }
        `}
      >
        <DropdownItemContent
          isInteractive
          disabled={disabled}
          icon={icon}
          isActive={isActive}
          label={label}
        />
      </UnstyledButton>
    </DropdownItemContainer>
  );
});

export const DropdownItemButton = memo(DropdownItemButtonForwardRef);
