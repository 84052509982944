import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import invariant from 'invariant';
import { memo, useCallback } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconAction } from '@amal-ia/frontend/design-system/components';
import { formatUserFullName } from '@amal-ia/lib-types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { IconTransformations, TransformedIcon } from '../../../../../utils/common/transformedIcons';
import { Avatar } from '../../../Avatar';
import { AvatarGroup } from '../../../AvatarGroup';
import { UserSelect, type UserSelectProps } from '../UserSelect/UserSelect';

const useUserSelectAvatarsStyles = makeStyles((theme: AmaliaThemeType) => ({
  renderedName: {
    margin: theme.spacing(0, 1),
    color: theme.palette.secondary.main,
  },
}));

type UserSelectAvatarsProps = UserSelectProps & {
  doRenderName?: boolean;
};

export const UserSelectAvatars = memo(function UserSelectAvatars({ doRenderName, ...props }: UserSelectAvatarsProps) {
  const classes = useUserSelectAvatarsStyles();

  const { multiple, users } = props;

  const renderAvatars = useCallback(
    (selectedUsers: UserComputed | UserComputed[] | null) => {
      if (multiple && Array.isArray(selectedUsers)) {
        return (
          <AvatarGroup
            max={3}
            // If no user is currently selected, show all of them.
            users={(selectedUsers?.length ? selectedUsers : users) || []}
          />
        );
      }
      if (multiple && !selectedUsers) {
        return (
          <AvatarGroup
            max={3}
            // If no user is currently selected, show all of them.
            users={users || []}
          />
        );
      }
      invariant(!Array.isArray(selectedUsers), 'UserSelect: multiple is false but value is an array');

      return selectedUsers ? <Avatar user={selectedUsers} /> : <FormattedMessage defaultMessage="No user selected" />;
    },
    [multiple, users],
  );

  const renderName = useCallback(
    (value: UserComputed | UserComputed[] | null) => {
      const name =
        multiple && Array.isArray(value) ? (
          <FormattedList
            style="short"
            type="unit"
            value={value.map(formatUserFullName)}
          />
        ) : value && !Array.isArray(value) ? (
          formatUserFullName(value)
        ) : (
          ''
        );

      return <Box className={classes.renderedName}>{name}</Box>;
    },
    [classes, multiple],
  );

  const renderDeployButton = useCallback(
    (onClick: VoidFunction, open: boolean) => (
      <Box
        bottom={-5}
        position={doRenderName ? 'static' : 'absolute'}
        right={-5}
      >
        <IconAction
          label={<FormattedMessage defaultMessage="Filter users" />}
          icon={
            <TransformedIcon
              icon={open ? <ArrowDropUp /> : <ArrowDropDown />}
              transformations={[IconTransformations.SIZE(2)]}
            />
          }
          onClick={onClick}
        />
      </Box>
    ),
    [doRenderName],
  );

  return (
    <UserSelect {...props}>
      {({ onClick, value, open }) => (
        <Box
          alignItems="center"
          display="flex"
        >
          <Box
            alignItems="center"
            display="flex"
            onClick={onClick}
          >
            {renderAvatars(value)}
            {doRenderName ? renderName(value) : null}
          </Box>
          {renderDeployButton(onClick, open)}
        </Box>
      )}
    </UserSelect>
  );
});
