import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { selectCurrentStatementThreads, setOpenedThread, useThunkDispatch } from '@amal-ia/frontend/web-data-layers';
import {
  type ComputedVariableWithVariableIdAndLabel,
  StatementThreadScopesType,
  type ComputedVariable,
  type StatementThread,
  type StatementThreadScope,
} from '@amal-ia/lib-types';
import { type StatementDetailContextInterface } from '@amal-ia/lib-ui';

/**
 * A hook to handle everything related to KPI statement threads (creating, opening, etc.)
 */
export const useKPIThread = (
  statementContext: StatementDetailContextInterface,
  ruleDefinition: PlanRule,
  statementRuleComputedVariables: ComputedVariableWithVariableIdAndLabel[],
) => {
  const dispatch = useThunkDispatch();
  const history = useHistory();

  const statementThreads = useSelector(selectCurrentStatementThreads);

  const threadsPerKpi: Record<string, StatementThread> = useMemo(
    () =>
      statementRuleComputedVariables.reduce<Record<string, StatementThread>>((acc, computedVariable) => {
        acc[computedVariable.id] =
          Object.values(statementThreads).filter(
            (statementThread: StatementThread) =>
              statementThread.scope?.type === StatementThreadScopesType.KPI &&
              (!statementThread.scope?.ruleId || statementThread.scope?.ruleId === ruleDefinition.id) &&
              statementThread.scope?.id ===
                statementContext.results.definitions.variables[computedVariable.variableMachineName]?.id,
          )[0] || null;

        return acc;
      }, {}),
    [
      statementRuleComputedVariables,
      ruleDefinition.id,
      statementContext.results.definitions.variables,
      statementThreads,
    ],
  );

  const openStatementThread = useCallback(
    (statementThreadId: string | null, variable: ComputedVariable) => {
      if (!statementContext.id) {
        return;
      }
      if (!statementThreadId) {
        const scope: StatementThreadScope = {
          type: StatementThreadScopesType.KPI,
          id: statementContext.results.definitions.variables[variable.variableMachineName].id,
          ruleId: ruleDefinition.id,
        };

        dispatch(setOpenedThread(-1, scope));
      } else {
        history.push(
          generatePath(routes.STATEMENT_COMMENT, {
            id: statementContext.id,
            stid: statementThreadId,
          }),
        );
      }
    },
    [dispatch, history, ruleDefinition.id, statementContext.id, statementContext.results.definitions.variables],
  );

  return {
    threadsPerKpi,
    openStatementThread,
  };
};
