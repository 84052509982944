import { type Theme, css } from '@emotion/react';

import { InputSize } from './Input.types';

export const IS_DISABLED_CLASSNAME = 'disabled';
export const HAS_LEFT_ICON_CLASSNAME = 'hasLeftIcon';
export const HAS_RIGHT_ICON_CLASSNAME = 'hasRightIcon';
export const HAS_ERROR_CLASSNAME = 'hasError';
export const HAS_ACTION_CLASSNAME = 'hasAction';

export const container = (theme: Theme) => css`
  position: relative;

  color: ${theme.ds.colors.gray[900]};

  &.${IS_DISABLED_CLASSNAME} {
    color: ${theme.ds.colors.gray[300]};
  }
`;

export const iconContainer = (theme: Theme) => css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  display: flex;

  pointer-events: none; /* Clicking the icon focuses the input. */

  transition: ${theme.ds.transitions.default()};
`;

export const leftIcon = css`
  &.${InputSize.SMALL} {
    left: 12px;
  }

  &.${InputSize.MEDIUM} {
    left: 16px;
  }
`;

export const rightIcon = css`
  &.${InputSize.SMALL} {
    right: 12px;
  }

  &.${InputSize.MEDIUM} {
    right: 16px;
  }
`;

export const input = (theme: Theme) => css`
  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  outline: none;
  outline-offset: -1px;

  width: 100%;

  box-shadow: ${theme.ds.shadows.soft};

  transition: ${theme.ds.transitions.default(
    'border-color',
    'box-shadow',
    'color',
    'background-color',
    'padding',
    'padding-right',
    'padding-left',
    'font-size',
    'line-height',
  )};

  color: inherit;
  background-color: ${theme.ds.colors.gray[0]};

  min-width: 0;

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.secondary[200]};

    &.${HAS_ERROR_CLASSNAME} {
      outline: none;
    }

    &::placeholder {
      color: ${theme.ds.colors.gray[400]};
    }
  }

  &::placeholder {
    color: ${theme.ds.colors.gray[400]};
  }

  &:disabled {
    color: ${theme.ds.colors.gray[300]};
    background-color: ${theme.ds.colors.gray[50]};

    &::placeholder {
      color: ${theme.ds.colors.gray[200]};
    }
  }

  &:not(:disabled) {
    &.${HAS_ERROR_CLASSNAME} {
      border-color: ${theme.ds.colors.danger[500]};
    }
  }

  &.${InputSize.SMALL} {
    ${theme.ds.typographies.bodyBaseRegular};

    padding: 4.5px 11px;

    &:not(.${HAS_RIGHT_ICON_CLASSNAME}).${HAS_ACTION_CLASSNAME},
      &:not(.${HAS_ACTION_CLASSNAME}).${HAS_RIGHT_ICON_CLASSNAME} {
      padding-right: 42.2px;
    }

    &.${HAS_RIGHT_ICON_CLASSNAME}.${HAS_ACTION_CLASSNAME} {
      padding-right: 61.4px;
    }

    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};

      &.${HAS_ERROR_CLASSNAME} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
      }
    }

    &.${HAS_LEFT_ICON_CLASSNAME} {
      padding-left: 33px;
    }
  }

  &.${InputSize.MEDIUM} {
    ${theme.ds.typographies.bodyLargeRegular};

    padding: 7px 15px;

    &:not(.${HAS_RIGHT_ICON_CLASSNAME}).${HAS_ACTION_CLASSNAME},
      &:not(.${HAS_ACTION_CLASSNAME}).${HAS_RIGHT_ICON_CLASSNAME} {
      padding-right: 51px;
    }

    &.${HAS_RIGHT_ICON_CLASSNAME}.${HAS_ACTION_CLASSNAME} {
      padding-right: 75px;
    }

    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};

      &.${HAS_ERROR_CLASSNAME} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
      }
    }

    &.${HAS_LEFT_ICON_CLASSNAME} {
      padding-left: 45px;
    }
  }
`;

export const action = (theme: Theme) => css`
  position: absolute;

  top: 50%;
  transform: translate(0, -50%);

  transition: ${theme.ds.transitions.default()};

  .${InputSize.SMALL} ~ & {
    right: 12px;
  }

  .${InputSize.SMALL}.${HAS_RIGHT_ICON_CLASSNAME} ~ & {
    right: 37.2px;
  }

  .${InputSize.MEDIUM} ~ & {
    right: 16px;
  }

  .${InputSize.MEDIUM}.${HAS_RIGHT_ICON_CLASSNAME} ~ & {
    right: 46px;
  }
`;
