import { http, qsStringify } from '@amal-ia/frontend/kernel/http';
import { type PlanAssignment } from '@amal-ia/lib-types';

import {
  type PlanAssignmentWithPlan,
  type PlanAssignmentWithPlanAndTeam,
  type PlanAssignmentWithUser,
} from '../../types/planAssignments';

const apiEndpoint = '/assignments';

export const createPlanAssignment = async (planAssignments: PlanAssignment[]): Promise<PlanAssignment> => {
  const { data } = await http.post<PlanAssignment>(`${apiEndpoint}`, planAssignments);
  return data;
};

export const updatePlanAssignment = async (planAssignment: PlanAssignment): Promise<PlanAssignment> => {
  const { data } = await http.patch<PlanAssignment>(`${apiEndpoint}`, planAssignment);
  return data;
};

export const deletePlanAssignment = async (planAssignment: PlanAssignment): Promise<any> => {
  const { data } = await http.delete(`${apiEndpoint}`, {
    data: planAssignment,
  });
  return data;
};

export const listPlanAssignmentsForPlan = async (planId: string): Promise<PlanAssignmentWithUser[]> => {
  const { data } = await http.get<PlanAssignmentWithUser[]>(`${apiEndpoint}/?planId=${planId}&relations=user`);
  return data;
};

export const listPlanAssignmentsForPlans = async (planIds: string[]): Promise<PlanAssignmentWithUser[]> => {
  const { data } = await http.get<PlanAssignmentWithUser[]>(
    `${apiEndpoint}/?${qsStringify({ planId: planIds, relations: 'user' })}`,
  );
  return data;
};

// TODO: move to their domain?
// @deprecated
export const deprecatedListPlanAssignmentsForUser = async (userId: string): Promise<PlanAssignmentWithPlan[]> => {
  const { data } = await http.get<PlanAssignmentWithPlan[]>(`${apiEndpoint}/?userId=${userId}&relations=plan`);
  return data;
};

export const listPlanAssignmentsForUser = async (userId: string) => {
  const { data } = await http.get<PlanAssignmentWithPlanAndTeam[]>(
    `${apiEndpoint}?${qsStringify({ userId, relations: 'plan,mainTeam' })}`,
  );

  return data.map((assignment) => ({
    ...assignment,
    id: assignment.id ?? '',
    effectiveUntil: assignment.effectiveUntil ? new Date(assignment.effectiveUntil * 1000) : null,
    effectiveAsOf: assignment.effectiveAsOf ? new Date(assignment.effectiveAsOf * 1000) : null,
  }));
};
