import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('CONCAT', AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 0;

func.description = 'Return the concatenated strings';

func.hasInfiniteParams = true;

func.examples = [
  {
    desc: 'Returns First Name - Opportunity ID.',
    formula: 'CONCAT(user.firstName, " - ", opportunity.id)' as AmaliaFormula,
  },
  {
    desc: 'Returns Jean, Dupont.',
    formula: 'CONCAT("Jean", ", ", "Dupont")' as AmaliaFormula,
    result: 'Jean, Dupont',
  },
];

/**
 * Concatenate n strings.
 * @param stringsToConcat
 */

func.exec = (...stringsToConcat: any[] | string[]): string => stringsToConcat.join('');

export default func;
