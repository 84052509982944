import { Edit as EditIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useMemo } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type Filter } from '@amal-ia/compensation-definition/shared/types';
import { colors } from '@amal-ia/ext/mui/theme';
import { TokenType, type Plan, type Variable } from '@amal-ia/lib-types';
import { formikToMui } from '@amal-ia/lib-ui';

const useStyles = makeStyles({
  root: {
    color: colors['grey-700'],
    cursor: 'pointer',
    width: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1.25rem',
  },
});

export interface DesignerRedirectProps {
  plans?: Plan[];
  filters?: Filter[];
  variables?: Variable[];
  value?: string | null;

  // eslint-disable-next-line react/no-unused-prop-types -- we do not match this prop -- only used with Formik
  name?: string;
}

export const DesignerRedirectBase = memo(function DesignerRedirectBase({
  plans,
  filters,
  variables,
  value,
}: DesignerRedirectProps) {
  const history = useHistory();
  const classes = useStyles();

  const { objectType, objectName, objectId } = useMemo(() => {
    const result = { objectType: '', objectName: '', objectId: '' };

    if (!value) {
      return result;
    }

    const planFound = (plans || []).find((p) => p.name === value || p.id === value);
    const filterFound = (filters || []).find((f) => f.machineName === value || f.id === value);
    const variableFound = (variables || []).find((v) => v.machineName === value || v.id === value);

    if (planFound) {
      return {
        objectType: TokenType.PLAN,
        objectName: planFound.name,
        objectId: planFound.id,
      };
    }

    if (filterFound) {
      return {
        objectType: TokenType.FILTER,
        objectName: filterFound.name,
        objectId: filterFound.id,
      };
    }

    if (variableFound) {
      return {
        objectType: variableFound.object ? TokenType.FIELD : TokenType.VARIABLE,
        objectName: variableFound.name,
        objectId: variableFound.id,
      };
    }

    return result;
  }, [plans, filters, variables, value]);

  const openLink = useCallback(
    () => history.push(generatePath(routes.DESIGNER_OBJECT, { objectType, objectId })),
    [history, objectType, objectId],
  );

  if (!objectId) {
    return null;
  }

  return (
    <Box
      aria-label={`Open ${objectType?.toLowerCase()} ${objectName}`}
      className={classes.root}
      title={`Open ${objectType?.toLowerCase()} ${objectName}`}
      onClick={openLink}
    >
      <EditIcon />
    </Box>
  );
});

export const DesignerRedirect = memo<DesignerRedirectProps>(formikToMui(DesignerRedirectBase));

DesignerRedirect.displayName = 'DesignerRedirect';
