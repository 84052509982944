import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isNil, lowerCase, startCase } from 'lodash';
import moment from 'moment';
import { Fragment, memo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import {
  FieldValuePrettyFormat,
  isFieldValuePrettyFormatCompatible,
  LabelVariant,
} from '@amal-ia/data-capture/fields/components';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { OverwriteTooltip } from '@amal-ia/data-capture/overwrites/components';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { isEnum } from '@amal-ia/ext/typescript';
import { Button, ButtonLink } from '@amal-ia/frontend/design-system/components';
import { clearOverwrite as clearOverwriteAction, useThunkDispatch } from '@amal-ia/frontend/web-data-layers';
import { formatDatasetCell, formatUserFullName, type OverwriteResponse } from '@amal-ia/lib-types';
import { objectToQs, UserTableCell } from '@amal-ia/lib-ui';

import OverwritesTableContext from './OverwritesTableContext';
import { overwriteTableCellMessages } from './OverwriteTableCell.messages';

import DataCellProps = Table.DataCellProps;

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  link: {
    color: theme.palette.link.main,
  },
  username: {
    color: theme.palette.link.main,
  },
}));

const emptyCellValue = '-';

const OverwriteTableCell = memo(function OverwriteTableCell(cellProps: DataCellProps) {
  const dispatch = useThunkDispatch();
  const classes = useStyles();
  const { customObjectDefinitionsById } = useContext(OverwritesTableContext);

  const { column, tableRow } = cellProps;
  const overwrite = tableRow.row as OverwriteResponse;

  const propertyRef =
    customObjectDefinitionsById?.[overwrite?.appliesToDefinitionId]?.properties?.[overwrite?.field]?.ref;

  if (column.name === 'scope') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {startCase(lowerCase(overwrite.scope))}
      </Table.Cell>
    );
  }

  if (column.name === 'variable') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {overwrite.variable}
      </Table.Cell>
    );
  }
  if (column.name === 'plan') {
    if (!overwrite.statement?.plan) {
      return (
        <Table.Cell
          name={`${tableRow.rowId}.values[${column.name}]`}
          {...cellProps}
        >
          {emptyCellValue}
        </Table.Cell>
      );
    }
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {overwrite.statement?.plan.name || emptyCellValue}
      </Table.Cell>
    );
  }

  if (column.name === 'statement') {
    if (!overwrite.statement) {
      return (
        <Table.Cell
          name={`${tableRow.rowId}.values[${column.name}]`}
          {...cellProps}
        >
          {emptyCellValue}
        </Table.Cell>
      );
    }

    const onClickHandler = overwrite.createdOnStatementId
      ? () => window.open(`/statements/${overwrite.createdOnStatementId}`, '_blank')
      : undefined;
    return (
      <UserTableCell
        contextProps={cellProps}
        user={overwrite.statement?.user}
        onClick={onClickHandler}
      />
    );
  }

  if (column.name === 'period') {
    if (!overwrite.statement?.period) {
      return (
        <Table.Cell
          name={`${tableRow.rowId}.values[${column.name}]`}
          {...cellProps}
        >
          {emptyCellValue}
        </Table.Cell>
      );
    }

    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        <Box>{overwrite?.statement?.period?.name || emptyCellValue}</Box>
      </Table.Cell>
    );
  }

  if (column.name === 'overwrittenBy') {
    if (!overwrite.creator) {
      return (
        <Table.Cell
          name={`${tableRow.rowId}.values[${column.name}]`}
          {...cellProps}
        >
          {emptyCellValue}
        </Table.Cell>
      );
    }

    const userName = `${overwrite.creator.firstName} ${overwrite.creator.lastName}`;

    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        <Box>{userName || emptyCellValue}</Box>
      </Table.Cell>
    );
  }

  if (column.name === 'rule') {
    if (!overwrite.rule) {
      return (
        <Table.Cell
          name={`${tableRow.rowId}.values[${column.name}]`}
          {...cellProps}
        >
          {emptyCellValue}
        </Table.Cell>
      );
    }
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {overwrite.rule.name || emptyCellValue}
      </Table.Cell>
    );
  }

  if (column.name === 'overwriteType') {
    if (!overwrite.overwriteType) {
      return (
        <Table.Cell
          name={`${tableRow.rowId}.values[${column.name}]`}
          {...cellProps}
        >
          {emptyCellValue}
        </Table.Cell>
      );
    }

    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        <Box>{startCase(lowerCase(overwrite?.overwriteType)) || emptyCellValue}</Box>
      </Table.Cell>
    );
  }

  if (column.name === 'customObject') {
    if (
      !cellProps.value?.name &&
      !cellProps.value?.externalId &&
      !overwrite?.appliesToDealName &&
      !overwrite?.appliesToExternalId
    ) {
      return (
        <Table.Cell
          name={`${tableRow.rowId}.values[${column.name}]`}
          {...cellProps}
        >
          {emptyCellValue}
        </Table.Cell>
      );
    }

    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        <ButtonLink
          rel="noreferrer"
          size={ButtonLink.Size.SMALL}
          variant={ButtonLink.Variant.LIGHT_TEXT}
          to={{
            pathname: overwrite.appliesToDefinitionName
              ? generatePath(routes.DATA_SLUG, { slug: overwrite.appliesToDefinitionName })
              : generatePath(routes.DATA),
            search: `?${objectToQs({ search: overwrite.appliesToExternalId })}`,
          }}
        >
          {overwrite?.appliesToDealName ||
            overwrite?.appliesToExternalId ||
            cellProps.value?.name ||
            cellProps.value?.externalId ||
            emptyCellValue}
        </ButtonLink>
      </Table.Cell>
    );
  }

  if (column.name === 'overwriteValue') {
    // Here we're struggling a bit to retrieve the value.
    // For overwrites over object variables, it's in the key of a JSON in the field.
    // For the other, we have it directly.
    // Be careful, in JS typeof null is object, meaning we have to assert it isn't null first.
    const maybePercentFormat = overwrite.variableFormat === FormatsEnum.percent ? FormatsEnum.percent : undefined;

    const overwriteValue =
      !!overwrite.overwriteValue &&
      typeof overwrite.overwriteValue === 'object' &&
      Object.keys(overwrite.overwriteValue).includes(overwrite.field)
        ? overwrite.overwriteValue?.[overwrite.field]
        : overwrite.overwriteValue;

    const sourceValue =
      !!overwrite.sourceValue &&
      typeof overwrite.sourceValue === 'object' &&
      Object.keys(overwrite.sourceValue).includes(overwrite.field)
        ? overwrite.sourceValue?.[overwrite.field]
        : overwrite.sourceValue;

    const format: FormatsEnum | undefined = isEnum(overwrite.variableFormat, FormatsEnum)
      ? overwrite.variableFormat
      : undefined;

    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
        className={!overwrite.clearedAt ? classes.link : null}
        value={overwriteValue}
      >
        <OverwriteTooltip
          isReadOnly
          author={`${overwrite.creator.firstName} ${overwrite.creator.lastName}`}
          date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
          newValue={formatDatasetCell(overwriteValue, maybePercentFormat)}
          oldValue={formatDatasetCell(sourceValue, maybePercentFormat)}
          valueFormat={format}
          valueRef={propertyRef}
        >
          {!isNil(overwriteValue) ? (
            isFieldValuePrettyFormatCompatible(format, propertyRef) ? (
              <FieldValuePrettyFormat
                format={format}
                value={overwriteValue}
                valueRef={propertyRef}
                variant={LabelVariant.ACCENTUATED}
              />
            ) : (
              <Fragment>{formatDatasetCell(overwriteValue, maybePercentFormat)}</Fragment>
            )
          ) : (
            <Fragment>{emptyCellValue}</Fragment>
          )}
        </OverwriteTooltip>
      </Table.Cell>
    );
  }

  if (column.name === 'creator') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {formatUserFullName(cellProps.value)}
      </Table.Cell>
    );
  }

  if (column.name === 'createdAt') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {cellProps.value ? moment(cellProps.value, 'YYYY-MM-DD').format('YYYY-MM-DD') : emptyCellValue}
      </Table.Cell>
    );
  }

  if (column.name === 'clearedAt') {
    return (
      <Table.Cell
        name={`${tableRow.rowId}.values[${column.name}]`}
        {...cellProps}
      >
        {cellProps.value ? (
          moment(cellProps.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
        ) : (
          <Button
            size={Button.Size.SMALL}
            variant={Button.Variant.SECONDARY}
            onClick={async () => {
              await dispatch(clearOverwriteAction(overwrite));
            }}
          >
            <FormattedMessage {...overwriteTableCellMessages.CLEAR} />
          </Button>
        )}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell
      name={`${tableRow.rowId}.values[${column.name}]`}
      {...cellProps}
    />
  );
});

export default OverwriteTableCell;
