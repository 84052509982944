import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { ButtonLink } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, subject, SubjectsEnum } from '@amal-ia/lib-rbac';
import { formatUserFullName, type Period } from '@amal-ia/lib-types';
import { Avatar, DecorationKeys } from '@amal-ia/lib-ui';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

const firstPlaceDecorations = [DecorationKeys.CROWN];

interface UserChallengeElementProps {
  user: UserContract;
  statementId: string;
  winner: boolean;
  padding?: number;
  period?: Period;
}
const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  usernameWithLink: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.grey['100'],
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
      color: theme.palette.tertiary.main,
    },
  },
  usernameWithoutLink: {
    color: theme.palette.secondary.main,
  },
  linkBtnStatement: {
    textDecoration: 'none',
  },
}));

export const UserChallengeElement = memo(function UserChallengeElement({
  winner,
  statementId,
  user,
  padding,
  period,
}: UserChallengeElementProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Box
      alignItems="center"
      display="flex"
      padding={padding}
    >
      <Box marginRight={2}>
        <Avatar
          decorations={winner ? firstPlaceDecorations : []}
          user={user}
        />
      </Box>
      {statementId &&
      ability.can(ActionsEnum.view, subject(SubjectsEnum.Statement, { user: { id: user.id }, period })) ? (
        <ButtonLink
          aria-label={formatMessage({ defaultMessage: 'statement of {user}' }, { user: formatUserFullName(user) })}
          role="link"
          to={generatePath(routes.STATEMENT, { id: statementId })}
          variant={ButtonLink.Variant.LIGHT}
        >
          {formatUserFullName(user)}
        </ButtonLink>
      ) : (
        <Box className={classes.usernameWithoutLink}>{formatUserFullName(user)}</Box>
      )}
    </Box>
  );
});
