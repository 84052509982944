import invariant from 'invariant';
import { createContext, useContext } from 'react';

import {
  type CalculationRequest,
  type ListSelectedOptions,
  type Period,
  type StatusStatementType,
  type UserStatements,
  type UserStatementsFacets,
} from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/shared/types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

export type StatementListContextInterface = {
  company: Company;
  status: StatusStatementType | null;
  setStatus: (status: StatusStatementType) => void;

  isForecast?: boolean;

  period: Period;
  userStatementsFacet: UserStatementsFacets;
  userStatementsList: UserStatements[];
  calculationRequest: CalculationRequest;
  listSelectedOptions: ListSelectedOptions;
  usersFiltered: UserComputed[];
};

const StatementListContext = createContext<StatementListContextInterface | null>(null);
export const StatementListContextProvider = StatementListContext.Provider;

export const useStatementListContext = () => {
  const context = useContext(StatementListContext);
  invariant(context, 'useStatementListContext must be used within a StatementListContext');
  return context;
};
