import { memo } from 'react';

import { type ChallengeLeaderboard, type Period } from '@amal-ia/lib-types';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { UserChallengeElement } from './UserChallengeElement';

interface UserChallengeProps {
  line: ChallengeLeaderboard;
  usersMap: UsersMap;
  period: Period;
}

export const UserChallenge = memo(function UserChallenge({ line, usersMap, period }: UserChallengeProps) {
  const winner = line.position === 1;
  const user = usersMap[line.userId];
  const statementId = line.statementIds[0];

  if (!user) {
    return null;
  }

  return (
    <UserChallengeElement
      period={period}
      statementId={statementId}
      user={user}
      winner={winner}
    />
  );
});
