import { useMemo } from 'react';

import { type PayoutAndPerformanceChartStatistics } from '@amal-ia/lib-types';

import { mapPayoutTickLevels } from './amountTickLevels.mapper';

/**
 * Custom hook to compute amount ticks.
 * @param statistics
 */
export const useAmountTicks = (statistics: PayoutAndPerformanceChartStatistics) =>
  useMemo(() => {
    if (!statistics?.records) {
      return [];
    }

    // Find the max amount to display the ticks.
    const maxAmount = Math.max(...statistics.records.map((record) => record.ruleMetricPayment__value?.value));

    return mapPayoutTickLevels(maxAmount);
  }, [statistics?.records]);
