import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('LOWER', AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 1;

func.description = 'Return the string in lower case';

func.params = [{ name: 'string', description: 'String: variables, fields, properties, string' }];

func.hasInfiniteParams = false;

func.examples = [
  {
    desc: 'Returns hello.',
    formula: 'LOWER("Hello")' as AmaliaFormula,
    result: 'hello',
  },
  {
    desc: "Returns the user's first name in lower case.",
    formula: 'LOWER(user.firstName)',
  },
];

/**
 * Put a string in lowercase
 * @param stringToLower
 * @returns
 */
func.exec = (stringToLower: string): string => stringToLower?.toString()?.toLowerCase() || '';

export default func;
