import { Box } from '@mui/material';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { PageContainer } from '@amal-ia/frontend/connected-components/layout';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { PageHeader } from '@amal-ia/lib-ui';
import { useUpdateUserSettings } from '@amal-ia/tenants/users/shared/state';

import SettingsForm, { type SettingsFormProps } from './SettingsForm';

export default memo(function SettingsPage() {
  const { data: user } = useCurrentUser();
  const { mutateAsync: updateUserSettings } = useUpdateUserSettings();

  const handleValidateSettings: SettingsFormProps['onSubmit'] = useCallback(
    async (settings) => {
      // Update user settings
      await updateUserSettings(settings);
    },
    [updateUserSettings],
  );

  return (
    <PageContainer containerMaxWidth="md">
      <PageHeader
        title={
          <FormattedMessage
            defaultMessage="Settings"
            description="views.user.settings.title"
          />
        }
      >
        <Box px={amaliaTheme.spacing(2.5)}>
          {user ? (
            <SettingsForm
              company={user?.company}
              settings={user.settings}
              onSubmit={handleValidateSettings}
            />
          ) : null}
        </Box>
      </PageHeader>
    </PageContainer>
  );
});
