export type ActionsType =
  // eslint-disable-next-line @typescript-eslint/sort-type-constituents
  | 'view'
  | 'manage'
  | 'modify'
  | 'create'
  // Statement
  | 'tracing'
  | 'review'
  | 'calculate'
  | 'adjust'
  | 'customize'
  | 'view_comments'
  | 'modify_comments'
  | 'review_comments'
  | 'view_list'
  | 'debug'

  // Statement & Data
  | 'overwrite'

  // Statement & Overwrite_Recap
  | 'export'

  // Forecasted statement
  | 'simulate'

  // Team
  | 'automate'
  | 'view_assignments'
  | 'modify_assignments'

  // User
  | 'impersonate'

  // Quota
  | 'assign_values'

  // Workflow
  | 'reset'

  // Company & CustomReport
  | 'delete'

  // Company
  | 'view_rates'
  | 'modify_rates'
  | 'view_settings'
  | 'modify_settings'
  | 'view_directory';

export enum ActionsEnum {
  view = 'view',
  view_list = 'view_list',
  manage = 'manage',
  modify = 'modify',
  create = 'create',
  tracing = 'tracing',
  review = 'review',
  calculate = 'calculate',
  export = 'export',
  adjust = 'adjust',
  customize = 'customize',
  view_comments = 'view_comments',
  modify_comments = 'modify_comments',
  review_comments = 'review_comments',
  overwrite = 'overwrite',
  automate = 'automate',
  view_assignments = 'view_assignments',
  modify_assignments = 'modify_assignments',
  assign_values = 'assign_values',
  reset = 'reset',
  delete = 'delete',
  view_settings = 'view_settings',
  modify_settings = 'modify_settings',
  view_rates = 'view_rates',
  modify_rates = 'modify_rates',
  view_directory = 'view_directory',
  modify_directory_email_domain = 'modify_directory_email_domain',
  simulate = 'simulate',
  impersonate = 'impersonate',
  toggle_deactivation = 'toggle_deactivation',
  // Statement only, if you have visibility view on multiple persons.
  // TODO: there is probably a better way to do this.
  switch_to_another_user = 'switch_to_another_user',
}
