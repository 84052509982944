import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type Currency } from '@amal-ia/kernel/monetary/types';
import { type Company } from '@amal-ia/tenants/shared/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export const DEFAULT_REPORTS_PRECISION = 2;

export type CreateCompanyRequest = Partial<Company> & {
  admin: Partial<UserContract>;
  createCommonFiltersAndQuotas: boolean;
};

export interface CompanyCurrency {
  /**
   * Company currency id.
   */
  id?: string;

  /**
   * Currency code.
   */
  currency: Currency;

  /**
   * User that submitted a company currency.
   */
  creator?: UserContract;

  /**
   * Currency symbol.
   */
  symbol: CurrencySymbolsEnum;

  /**
   * Currency rate (against euro)
   */
  rate: number;
}
