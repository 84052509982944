import { defineMessages } from 'react-intl';

export const customReportRoutesMessages = defineMessages({
  CUSTOM_REPORT_ITEM: {
    defaultMessage: 'Custom report',
  },
  CUSTOM_REPORT_LIST: {
    defaultMessage: 'Custom reports',
  },
} as const);
