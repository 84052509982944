import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('endOfYear', AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the last day of year of a given date';

func.params = [
  {
    name: 'date',
    description:
      'Date to apply function on: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
  },
];

func.examples = [
  {
    desc: 'Returns "2023-12-31"',
    formula: 'endOfYear(toDate("2023-05-19"))' as AmaliaFormula,
    result: 1_703_980_800,
  },
  {
    desc: "Returns last day of year of the user's plan assignment start date.",
    formula: 'endOfYear(planAssignement.effectiveAsOf)' as AmaliaFormula,
  },
  {
    desc: 'Returns last day of year of opportunity close date.',
    formula: 'endOfYear(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null =>
  +moment(date, 'X').endOf('year').startOf('day').format('X') || null;

export default func;
