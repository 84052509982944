import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { overwritesRoutesMessages } from './overwrites.routes.messages';
import { OverwritesContainer } from './OverwritesContainer';

export const OverwritesRoutes = memo(function OverwritesRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="overwrites">
      <Switch>
        <CompanyRoute
          component={OverwritesContainer}
          path={routes.OVERWRITES}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Overwrite_Recap)}
          title={formatMessage(overwritesRoutesMessages.OVERWRITES)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
