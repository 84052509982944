import { Field, type FieldProps, getIn } from 'formik';
import { type FC } from 'react';

function hoc<T>(WrappedComponent: FC<T>): FC<T> {
  return ((props) => {
    const FormComponent: FC<FieldProps & { hidden?: boolean }> = function FormComponent(fieldElementProps) {
      const { field, form, hidden, ...currProps } = fieldElementProps;
      const hasError = getIn(form.errors, field.name);
      const isTouched = getIn(form.touched, field.name);
      return !hidden ? (
        // @ts-expect-error -- Cannot type a HOC.
        <WrappedComponent
          {...field}
          {...currProps}
          error={isTouched ? hasError : null}
          form={form}
        />
      ) : null;
    };

    return (
      <Field
        {...props}
        component={FormComponent}
      />
    );
  }) as FC;
}

export const formikToMui = hoc;
