import { css } from '@emotion/react';
import { Box, Paper } from '@mui/material';
import { IconGhostOff } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Select, type SelectOption, Typography } from '@amal-ia/frontend/design-system/components';
import { useAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';

import { useImpersonateUserMutation } from '../superAdmin.mutations';
import { ImpersonatingBox } from '../utils/ImpersonatingBox';
import { SAMenu } from '../utils/SAMenu';

export type CompanyOption = SelectOption<string>;

export type SuperadminHeaderProps = {
  companiesOptions: CompanyOption[];
  selectedCompanies: string[];
  onChangeSelectedCompanies: (selectedCompanies: string[]) => void;
};

export const SuperadminHeader = memo(function SuperadminHeader({
  companiesOptions,
  selectedCompanies,
  onChangeSelectedCompanies,
}: SuperadminHeaderProps) {
  const { formatMessage } = useIntl();
  const { authenticatedContext } = useAuthenticatedContext();

  const { mutateAsync: impersonateUser } = useImpersonateUserMutation();
  const onClickResetImpersonate = useCallback(async () => {
    await impersonateUser(authenticatedContext.meta?.amaliaImpersonatorEmail);
  }, [authenticatedContext.meta, impersonateUser]);

  return (
    <Paper
      css={css`
        overflow: hidden;
      `}
    >
      <Box p={3}>
        <Box mb={2}>
          <Typography variant={Typography.Variant.HEADING_3_BOLD}>
            <FormattedMessage defaultMessage="Superadmin panel" />
          </Typography>
        </Box>

        <Box
          alignItems="center"
          display="flex"
          gap={2}
        >
          <ImpersonatingBox />
          {/* Here when the user have an amaliaImpersonatorEmail, the user is currently impersonating someone*/}
          {!!authenticatedContext.meta?.amaliaImpersonatorEmail && (
            <Button
              icon={<IconGhostOff />}
              size={Button.Size.LARGE}
              variant={Button.Variant.SECONDARY_LIGHT}
              onClick={onClickResetImpersonate}
            >
              <FormattedMessage defaultMessage="Reset impersonate" />
            </Button>
          )}

          <Box ml="auto">
            <Select<CompanyOption, true>
              isMultiple
              id="selectedCompanies"
              options={companiesOptions}
              placeholder={formatMessage({ defaultMessage: 'Filter companies' })}
              value={selectedCompanies}
              onChange={onChangeSelectedCompanies}
            />
          </Box>
        </Box>
      </Box>

      <SAMenu />
    </Paper>
  );
});
