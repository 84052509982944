import { useQuery } from '@tanstack/react-query';

import { fetchPaginatedDatasetRows } from '../services/statements/statementDatasets.repository';

type UseDatasetProps = {
  statementId: string;
  datasetId: string;
  currentPage: number;
  searchValue?: string;
  pageSize: number;
  forecasted: boolean;
};

enum STATEMENT_DATASET_QUERY_KEYS {
  STATEMENT_DATASET = 'statement-dataset',
}

export const useStatementDataset = ({
  statementId,
  datasetId,
  currentPage,
  searchValue,
  pageSize,
  forecasted,
}: UseDatasetProps) =>
  useQuery({
    queryKey: [
      STATEMENT_DATASET_QUERY_KEYS.STATEMENT_DATASET,
      statementId,
      datasetId,
      currentPage,
      searchValue,
      pageSize,
      forecasted,
    ],
    queryFn: () =>
      fetchPaginatedDatasetRows(
        statementId,
        datasetId,
        {
          // Datagrid counts pages starting at 0, but API starts at 1.
          page: currentPage + 1,
          search: searchValue !== '' ? searchValue : undefined,
          limit: pageSize,
        },
        forecasted,
      ),
    enabled: !!statementId && !!datasetId,
  });
