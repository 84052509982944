import { http } from '@amal-ia/frontend/kernel/http';
import {
  type CalculationRequest,
  type CreateStatementOverwriteRequest,
  type Overwrite,
  type Statement,
  type StatementForecast,
  type WorkflowStatementStateAction,
} from '@amal-ia/lib-types';

const apiEndpoint = '/statements';

export const getStatements = async (ids: string[]): Promise<Statement[]> => {
  const { data } = await http.post(`${apiEndpoint}/getByIds`, {
    ids,
  });
  return data;
};

export const findStatements = async (
  periodId?: string,
  planId?: string,
  teamId?: string,
  userIds?: string[],
  withKpiResults?: boolean,
): Promise<Statement[]> => {
  const { data } = await http.get(apiEndpoint, {
    params: {
      periodId: periodId || undefined,
      planId: planId || undefined,
      teamId: teamId || undefined,
      userIds: !!userIds && userIds.length > 0 ? userIds : undefined,
      relations: 'user,plan,period,adjustments',
      withKpiResults: withKpiResults || false,
    },
  });

  return data;
};

export const getStatement = async (statementId: string): Promise<Statement> => {
  const { data } = await http.get(`${apiEndpoint}/${statementId}`);
  return data;
};

export const getForecastedStatement = async (
  statementForecastedId: string,
  statementId: string,
  withObjectsToDisplay: boolean,
): Promise<StatementForecast> => {
  const { data } = await http.get(`${apiEndpoint}/${statementId}/forecasts/${statementForecastedId}`, {
    params: { withObjectsToDisplay },
  });
  return data;
};

export const findStatementByCriteria = async (periodId: string, planId: string, userId: string): Promise<Statement> => {
  const { data } = await http.get(`${apiEndpoint}/findOneByCriteria`, {
    params: {
      periodId,
      planId,
      userId,
    },
  });
  return data;
};

// REVIEW
export const processWorkflowStep = async (
  statementId: string,
  workflowAction: WorkflowStatementStateAction,
  isNotify: boolean,
): Promise<Statement> => {
  const { data } = await http.patch<Statement>(`${apiEndpoint}/${statementId}?isNotify=${isNotify.toString()}`, {
    workflowAction,
  });
  return data;
};

export const reviewStatements = async (calculationRequest: CalculationRequest, isNotify: boolean): Promise<void> => {
  await http.post(`${apiEndpoint}/reviews?isNotify=${isNotify.toString()}`, calculationRequest);
};

// RIGHTS
export const listUsersThatCurrentUserCanSee = async (planIds?: string[], teamIds?: string[]) => {
  const url = `${apiEndpoint}/listUsers`;
  const params: string[] = [];
  Object.values(planIds || []).forEach((planId: string) => {
    params.push(`planIds=${planId}`);
  });
  Object.values(teamIds || []).forEach((teamId: string) => {
    params.push(`teamIds=${teamId}`);
  });

  const { data } = await http.get(`${url}${params.length > 0 ? `?${params.join('&')}` : ''}`);
  return data;
};

export const createOverwrite = async (
  statementId: string,
  createStatementOverwriteRequest: CreateStatementOverwriteRequest,
): Promise<Overwrite> => {
  const { data } = await http.post(`${apiEndpoint}/${statementId}/overwrites`, createStatementOverwriteRequest);
  return data;
};

export const clearStatementOverwrite = async (statementId: string, overwriteId: string) => {
  const { data } = await http.delete(`${apiEndpoint}/${statementId}/overwrites/${overwriteId}`);
  return data;
};
