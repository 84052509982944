import { css, type Theme } from '@emotion/react';

import { CountBadgeSize, CountBadgeVariant } from './CountBadge.types';

export const IS_DISABLED_CLASSNAME = 'is-disabled';
export const WITH_STROKE_CLASSNAME = 'with-stroke';

export const countBadge = (theme: Theme) => css`
  flex-shrink: 0;

  display: inline-flex;
  align-items: baseline;
  border-radius: ${theme.ds.borderRadiuses.squared};

  transition: ${theme.ds.transitions.default()};

  &.${IS_DISABLED_CLASSNAME} {
    background-color: ${theme.ds.colors.gray[100]};
    color: ${theme.ds.colors.gray[300]};

    &.${WITH_STROKE_CLASSNAME} {
      box-shadow: inset 0 0 0 1px ${theme.ds.colors.gray[200]};
    }
  }

  &:not(.${IS_DISABLED_CLASSNAME}) {
    &.${CountBadgeVariant.DEFAULT} {
      background-color: ${theme.ds.colors.secondary[50]};
      color: ${theme.ds.colors.secondary[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.colors.secondary[100]};
      }
    }

    &.${CountBadgeVariant.BLUE} {
      background-color: ${theme.ds.hues.blue[50]};
      color: ${theme.ds.hues.blue[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.blue[100]};
      }
    }

    &.${CountBadgeVariant.BROWN} {
      background-color: ${theme.ds.hues.brown[50]};
      color: ${theme.ds.hues.brown[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.brown[100]};
      }
    }

    &.${CountBadgeVariant.CYAN} {
      background-color: ${theme.ds.hues.cyan[50]};
      color: ${theme.ds.hues.cyan[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.cyan[100]};
      }
    }

    &.${CountBadgeVariant.MAGENTA} {
      background-color: ${theme.ds.hues.magenta[50]};
      color: ${theme.ds.hues.magenta[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.magenta[100]};
      }
    }

    &.${CountBadgeVariant.ORANGE} {
      background-color: ${theme.ds.hues.orange[50]};
      color: ${theme.ds.hues.orange[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.orange[100]};
      }
    }

    &.${CountBadgeVariant.RED} {
      background-color: ${theme.ds.hues.red[50]};
      color: ${theme.ds.hues.red[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.red[100]};
      }
    }

    &.${CountBadgeVariant.GREEN} {
      background-color: ${theme.ds.hues.green[50]};
      color: ${theme.ds.hues.green[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.green[100]};
      }
    }

    &.${CountBadgeVariant.GREY} {
      background-color: ${theme.ds.colors.gray[100]};
      color: ${theme.ds.colors.gray[500]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.colors.gray[200]};
      }
    }

    &.${CountBadgeVariant.YELLOW} {
      background-color: ${theme.ds.hues.yellow[50]};
      color: ${theme.ds.hues.yellow[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.yellow[100]};
      }
    }

    &.${CountBadgeVariant.PURPLE} {
      background-color: ${theme.ds.hues.purple[50]};
      color: ${theme.ds.hues.purple[700]};

      &.${WITH_STROKE_CLASSNAME} {
        box-shadow: inset 0 0 0 1px ${theme.ds.hues.purple[100]};
      }
    }
  }

  &.${CountBadgeSize.SMALL} {
    ${theme.ds.typographies.bodyXsmallRegular};
    height: 16px;
    padding: 0 4px;
  }

  &.${CountBadgeSize.MEDIUM} {
    ${theme.ds.typographies.bodyBaseRegular};
    height: 20px;
    padding: 0 6px;
  }

  &.${CountBadgeSize.LARGE} {
    ${theme.ds.typographies.bodyLargeRegular};
    height: 24px;
    padding: 0 8px;
  }
`;
