import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Text, TextType } from '@amal-ia/lib-ui';

interface NotificationsLine {
  label: ReactNode;
  key: string;
  childrenEmail: ReactNode;
  childrenSlack?: ReactNode;
}

export interface NotificationsSection {
  title: ReactNode;
  key: string;
  lines: NotificationsLine[];
}

export type NotificationSectionProps = {
  title: ReactNode;
  lines: NotificationsLine[];
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  section: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: '5px 0',
    '& > h2': {
      margin: '10px 20px',
    },
  },
  line: {
    padding: '0 20px',
    display: 'grid',
    gridTemplateColumns: '1fr 100px 100px',
    alignItems: 'center',
  },
}));

const NotificationSection = memo(function NotificationSection({ title, lines }: NotificationSectionProps) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Text type={TextType.SUBTITLE}>{title}</Text>
      <div>
        {lines.map((line) => (
          <div
            key={line.key}
            className={classes.line}
          >
            <span>{line.label}</span>
            <span>{line.childrenEmail}</span>
            {line.childrenSlack ? <span>{line.childrenSlack}</span> : null}
          </div>
        ))}
      </div>
    </div>
  );
});

export default NotificationSection;
