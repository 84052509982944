import { AddAPhotoOutlined } from '@mui/icons-material';
import { Avatar as MuiAvatar, type AvatarProps as MuiAvatarProps, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { type CSSProperties, type FunctionComponent, memo, type SVGProps, useMemo } from 'react';

import { amaliaTheme, type AmaliaThemeType, colors } from '@amal-ia/ext/mui/theme';
import { IconCrown } from '@amal-ia/frontend/ui-icons';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

export enum DecorationKeys {
  CROWN = 'CROWN',
}

export const DecorationValues: Record<
  DecorationKeys,
  { Tag: FunctionComponent<SVGProps<SVGSVGElement>>; styles: object }
> = {
  [DecorationKeys.CROWN]: {
    Tag: IconCrown,
    styles: {
      fill: colors['primary-500'],
      height: '50%',
      width: '50%',
      position: 'absolute',
      left: '25%',
      top: 'calc(10% - 23px)',
    },
  },
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    position: 'relative',
    top: 0,
  },
  avatar: {
    color: theme.palette.common.white,
    zIndex: 0,
    position: 'relative',
  },
  decorationWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

interface AvatarProps extends MuiAvatarProps {
  user: Partial<UserComputed>;
  onClick?: () => void;
  style?: CSSProperties;
  avatarStyles?: CSSProperties;
  addAPicture?: boolean;
  decorations?: DecorationKeys[];
  parentClassName?: string;
}

export const Avatar = memo(function Avatar({
  user,
  onClick,
  style,
  addAPicture,
  decorations,
  parentClassName,
  avatarStyles,
  ...props
}: AvatarProps) {
  const classes = useStyles();
  const alt = `${user?.firstName} ${user?.lastName}`;
  const className = clsx(props.className, classes.avatar);

  const avatarStyle = useMemo(
    () => ({
      ...style,
      ...avatarStyles,
      // If we have an onClick function, set cursor pointer.
      cursor: onClick ? 'pointer' : 'initial',
    }),
    [style, avatarStyles, onClick],
  );

  const rootStyle = useMemo(
    () => ({
      ...style,
      top: 0,
    }),
    [style],
  );

  const getInitial = (value: string) => value.charAt(0).toUpperCase() ?? '?';

  const userInitials = `${user?.firstName && getInitial(user.firstName)}${user?.lastName && getInitial(user.lastName)}`;
  const userPicture = user?.pictureURL || '';

  const content = useMemo(() => {
    if (props.children) {
      return props.children;
    }
    if (!addAPicture) {
      return userInitials;
    }
    return <AddAPhotoOutlined style={{ color: amaliaTheme.palette.link.main }} />;
  }, [userInitials, props, addAPicture]);

  return (
    <Box
      className={clsx(classes.root, parentClassName)}
      style={rootStyle}
    >
      <MuiAvatar
        {...props}
        alt={alt}
        className={className}
        src={userPicture}
        style={avatarStyle}
        onClick={onClick}
      >
        {content}
      </MuiAvatar>

      {(decorations || []).map((d) => {
        const { Tag, styles } = DecorationValues[d];
        return (
          <div
            key={d}
            className={classes.decorationWrapper}
          >
            <Tag style={styles} />
          </div>
        );
      })}
    </Box>
  );
});
