import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { commonMathJs } from '@amal-ia/amalia-lang/amalia-mathjs';
import { type ArrayNode } from '@amal-ia/amalia-lang/formula/evaluate';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { toError } from '@amal-ia/ext/typescript';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { log } from '@amal-ia/frontend/kernel/logger';
import { StringUtils, type VariableFormatOptionsTable, type VariableFormatOptionsColumn } from '@amal-ia/lib-types';

import { TableBuilder } from './TableBuilder';
import { type TableBuilderColumn } from './tableBuilder.types';

interface TableBuilderFieldProps {
  formula: string;
  formatOptions: VariableFormatOptionsTable | undefined;
  setFieldValue: (field: 'formatOptions' | 'formula', value: VariableFormatOptionsTable | string | null) => any;
}

export const TableBuilderField = memo(function TableBuilderField({
  formula,
  formatOptions,
  setFieldValue,
}: TableBuilderFieldProps) {
  const { snackError } = useSnackbars();

  const setColumns = useCallback(
    (columns: TableBuilderColumn[]) => {
      setFieldValue('formatOptions', {
        ...(formatOptions || {}),
        columns: columns as VariableFormatOptionsColumn[],
      });
    },
    [formatOptions, setFieldValue],
  );

  const setRows = useCallback(
    (rows: any) => {
      const newRows = (rows || []).map((row: any) => `[${(row || []).join(', ')}]`);
      const newFormula = `[${newRows.join(', ')}]`;

      try {
        commonMathJs.parse(newFormula);
        setFieldValue('formula', newFormula);
      } catch (e) {
        snackError(
          <FormattedMessage
            defaultMessage="Invalid value: {error}"
            values={{ error: toError(e).message }}
          />,
        );
      }
    },
    [setFieldValue, snackError],
  );

  const rows = useMemo(() => {
    try {
      const parsedFormula = commonMathJs.parse(formula) as ArrayNode;

      if (!parsedFormula.items) {
        return [];
      }

      const parsedItemArray = parsedFormula.items
        .map((row: any) => {
          if (!row?.items) {
            return false;
          }

          return row.items.map((item: any) => item.toString());
        })
        .filter(Boolean);

      if (!parsedItemArray) {
        throw new Error('Unparseable array');
      }

      return parsedItemArray;
    } catch (e) {
      log.error(e, { formula });
      snackError(<FormattedMessage defaultMessage="Formula can't be parsed as a table. We reset the table for you." />);
      return [[0]];
    }
  }, [formula, snackError]);

  const columns = useMemo(() => {
    let parsedColumns = formatOptions?.columns;

    // If no column retrieved, intialize columns array
    if (!parsedColumns) {
      parsedColumns = [];
    }

    let newColumnsCount = 1;

    // If there's more data than columns, push new columns
    while (rows[0]?.length > parsedColumns.length) {
      const newName = `Column ${newColumnsCount}`;
      parsedColumns.push({
        name: newName,
        machineName: StringUtils.camelCase(newName),
        format: FormatsEnum.number,
      });
      newColumnsCount += 1;
    }

    // If there's less data than columns, delete the extra columns
    if (rows[0]?.length < parsedColumns.length) {
      parsedColumns = parsedColumns.splice(0, rows[0].length);
    }

    return parsedColumns;
  }, [formatOptions, rows]);

  return (
    <TableBuilder
      columns={columns}
      rows={rows}
      setColumns={setColumns}
      setRows={setRows}
    />
  );
});
