import { Box, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCaretLeft, IconReload } from '@tabler/icons-react';
import { last } from 'lodash';
import {
  type Dispatch,
  Fragment,
  memo,
  type MouseEventHandler,
  type SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, Typography } from '@amal-ia/frontend/design-system/components';
import { type AuthenticatedContext, WorkflowUtils } from '@amal-ia/lib-rbac';
import {
  type Statement,
  type WorkflowDefinition,
  type WorkflowStatementState,
  WorkflowStatementStateAction,
  type WorkflowStep,
} from '@amal-ia/lib-types';
import { ProgressWithLabel, Switch, Text, TextType, Tooltip } from '@amal-ia/lib-ui';
import { type UserContract, UserRole, type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { WorkflowStepper } from '../../../workflow/WorkflowStepper/WorkflowStepper';
import { messages } from '../StatementWorkflow.messages';
import { useWorkflowStepName } from '../useWorkflowStepName';

import * as styles from './StatementWorkflowPresentation.styles';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

export interface StatementWorkflowAdditionalOtpions {
  forceShowProgressBar?: boolean;
}

interface StatementWorkflowPresentationProps {
  statement: Statement;
  workflow: WorkflowDefinition;
  steps: Partial<WorkflowStatementState>[];
  stepNumber: number;
  onNextStep?: (canValidStep: WorkflowStatementStateAction, checked: boolean) => void;
  onPreviousStep?: MouseEventHandler;
  onReset?: MouseEventHandler;
  usersMap: UsersMap;
  authenticatedContext: AuthenticatedContext;
  tooltipInteractive?: boolean;
  setTooltipOpened: Dispatch<SetStateAction<boolean>>;
  tooltipOpened?: boolean;
  additionalOptions?: StatementWorkflowAdditionalOtpions;
  hasRightToResetWorkflow?: boolean;
  statementHeaderView?: boolean;
}

export const StatementWorkflowPresentation = memo(function StatementWorkflowPresentation({
  statement,
  workflow,
  steps,
  stepNumber,
  onNextStep,
  onPreviousStep,
  onReset,
  authenticatedContext,
  usersMap,
  tooltipInteractive,
  tooltipOpened,
  setTooltipOpened,
  additionalOptions,
  hasRightToResetWorkflow,
  statementHeaderView,
}: StatementWorkflowPresentationProps) {
  const classes = useStyles();

  // List the list of authorization for each step of the statement's workflow
  // for example, for [step1, step2], we may have [UNAUTHORIZED, NEXT] that means
  // Current user can validate step 2 but not step 1
  const canValidSteps: WorkflowStatementStateAction[] = useMemo(
    // For this step, compute the right of current user on it
    () =>
      workflow.steps.map((step: WorkflowStep) =>
        WorkflowUtils.canValidStep(
          authenticatedContext,
          statement.user as UserContract,
          step,
          workflow.workflowOptions,
        ),
      ),
    [authenticatedContext, statement, workflow],
  );

  const haveValidatedStep: boolean = useMemo(
    () =>
      canValidSteps.slice(0, stepNumber).filter((step) => step !== WorkflowStatementStateAction.UNAUTHORIZED).length >
      0,
    [canValidSteps, stepNumber],
  );

  const canValidThisStep = useMemo(() => {
    if (!canValidSteps[stepNumber]) {
      return WorkflowStatementStateAction.UNAUTHORIZED;
    }

    return canValidSteps[stepNumber];
  }, [canValidSteps, stepNumber]);

  const isReviewed: boolean = useMemo(
    () =>
      statement.workflowComplete ||
      (haveValidatedStep && canValidThisStep === WorkflowStatementStateAction.UNAUTHORIZED),
    [canValidThisStep, haveValidatedStep, statement],
  );

  const { formatStepName } = useWorkflowStepName();

  // Compute last validated step name.
  const stepToPrint: {
    // Name of the step to be printed
    name: string;
    // Tells if the step name needs to be preceded by the text "Reviewed By"
    printReviewedBy: boolean;
  } = useMemo(() => {
    // If staatement is complete, just print "Reviewed"
    if (statement.workflowComplete) {
      return {
        name: '',
        printReviewedBy: false,
      };
    }
    // If user hasn't the right to validate current step AND he already has validated a previous step,
    // Show the previous validated step name
    if (isReviewed && haveValidatedStep) {
      const indexOfLastValidatedStep = last(
        // map into [index, right] to filter the step current user had the right to validate, then get its index
        // to get the step name from the workflow steps
        Object.entries(canValidSteps).filter(([, right]) => right !== WorkflowStatementStateAction.UNAUTHORIZED),
      )?.[0];

      if (indexOfLastValidatedStep) {
        return {
          name: formatStepName(
            steps[parseInt(indexOfLastValidatedStep, 10)]?.stepName || steps[stepNumber]?.stepName || '',
          ),
          printReviewedBy: true,
        };
      }
    }
    // Otherwise, toggle is disabled and current step name is printed
    return {
      name: formatStepName(steps[stepNumber]?.stepName || ''),
      printReviewedBy: true,
    };
  }, [haveValidatedStep, canValidSteps, steps, statement, isReviewed, stepNumber, formatStepName]);

  const canToggleStep: boolean = useMemo(() => {
    if (isReviewed && stepNumber > 0) {
      const canValidatePreviousStep = canValidSteps[stepNumber - 1] !== WorkflowStatementStateAction.UNAUTHORIZED;

      // User has the right to back one step if he can validate this step OR if it's the last step AND he's admin

      // User can toggle step :
      // - If he is allowed to validate previous step on the workflow definition.
      // In the case where workflow is complete, only non employees users are allowed to unreview the statement.
      return (
        canValidatePreviousStep && (!statement.workflowComplete || authenticatedContext.user.role !== UserRole.EMPLOYEE)
      );
    }

    return canValidThisStep !== WorkflowStatementStateAction.UNAUTHORIZED;
  }, [isReviewed, stepNumber, canValidThisStep, canValidSteps, authenticatedContext, statement.workflowComplete]);

  const handleTooltipClose = useCallback(() => {
    setTooltipOpened(false);
  }, [setTooltipOpened]);

  const handleTooltipOpen = useCallback(() => {
    setTooltipOpened(true);
  }, [setTooltipOpened]);

  const canBackStep: boolean = useMemo(
    () =>
      !!onPreviousStep &&
      hasRightToResetWorkflow === true &&
      (stepNumber > 0
        ? WorkflowUtils.canValidStep(
            authenticatedContext,
            statement.user as UserContract,
            workflow.steps[stepNumber - 1],
            workflow.workflowOptions,
          ) !== WorkflowStatementStateAction.UNAUTHORIZED
        : false),
    [statement, stepNumber, authenticatedContext, workflow, hasRightToResetWorkflow, onPreviousStep],
  );

  const canResetSteps: boolean = useMemo(
    () =>
      !!onReset &&
      hasRightToResetWorkflow === true &&
      (stepNumber > 0
        ? WorkflowUtils.canResetSteps(authenticatedContext, statement.user as UserContract, false) !==
          WorkflowStatementStateAction.UNAUTHORIZED
        : false),
    [statement, stepNumber, authenticatedContext, hasRightToResetWorkflow, onReset],
  );

  const onNextStepProxy = useCallback(
    (_, checked: boolean) => onNextStep?.(canValidThisStep, checked),
    [canValidThisStep, onNextStep],
  );

  const isShowProgressBar = steps.length > 1 || additionalOptions?.forceShowProgressBar;

  if (statementHeaderView) {
    return (
      <div css={styles.presentationContainer}>
        <div css={styles.reviewSwitch}>
          <Switch
            checked={isReviewed}
            disabled={!canToggleStep}
            height={20}
            onChange={onNextStepProxy}
          />
        </div>
        <div css={styles.reviewStepTitle}>
          {stepToPrint.printReviewedBy ? (
            <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
              <FormattedMessage
                {...messages.REVIEW_BY}
                values={{ reviewer: stepToPrint.name }}
              />
            </Typography>
          ) : null}
          {isReviewed ? (
            <Typography variant={Typography.Variant.BODY_BASE_BOLD}>
              <FormattedMessage {...messages.REVIEWED} />
            </Typography>
          ) : null}
        </div>
        {isShowProgressBar ? (
          <div css={styles.progressBar}>
            <Tooltip
              disableInteractive={!tooltipInteractive}
              open={tooltipOpened}
              title={
                <Fragment>
                  <Box>
                    <WorkflowStepper
                      compact={stepNumber === 0}
                      usersMap={usersMap}
                      workflowState={steps}
                    />
                  </Box>
                  {canBackStep || canResetSteps ? (
                    <Box
                      className={stepNumber > 0 ? classes.actions : undefined}
                      display="flex"
                      justifyContent="space-between"
                      textAlign="right"
                    >
                      {canBackStep && onPreviousStep ? (
                        <Button
                          icon={<IconCaretLeft />}
                          variant={Button.Variant.LIGHT}
                          onClick={onPreviousStep}
                        >
                          <FormattedMessage {...messages.PREVIOUS_STEP} />
                        </Button>
                      ) : null}
                      {canResetSteps && onReset ? (
                        <Button
                          icon={<IconReload />}
                          variant={Button.Variant.LIGHT}
                          onClick={onReset}
                        >
                          <FormattedMessage {...messages.RESET_WORKFLOW} />
                        </Button>
                      ) : null}
                    </Box>
                  ) : null}
                </Fragment>
              }
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
            >
              <Box
                alignItems="center"
                display="flex"
                height="50"
                width="100%"
              >
                <ProgressWithLabel
                  current={stepNumber}
                  total={workflow.steps.length}
                />
              </Box>
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <Fragment>
      {isShowProgressBar ? (
        <Box className={classes.root}>
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Tooltip
              disableInteractive={!tooltipInteractive}
              open={tooltipOpened}
              title={
                <Fragment>
                  <Box>
                    <WorkflowStepper
                      compact={stepNumber === 0}
                      usersMap={usersMap}
                      workflowState={steps}
                    />
                  </Box>
                  {canBackStep || canResetSteps ? (
                    <Box
                      className={stepNumber > 0 ? classes.actions : undefined}
                      display="flex"
                      justifyContent="space-between"
                      textAlign="right"
                    >
                      {canBackStep && onPreviousStep ? (
                        <Button
                          icon={<IconCaretLeft />}
                          variant={Button.Variant.LIGHT}
                          onClick={onPreviousStep}
                        >
                          <FormattedMessage {...messages.PREVIOUS_STEP} />
                        </Button>
                      ) : null}
                      {canResetSteps && onReset ? (
                        <Button
                          icon={<IconReload />}
                          variant={Button.Variant.LIGHT}
                          onClick={onReset}
                        >
                          <FormattedMessage {...messages.RESET_WORKFLOW} />
                        </Button>
                      ) : null}
                    </Box>
                  ) : null}
                </Fragment>
              }
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
            >
              <Box
                alignItems="center"
                display="flex"
                height="50"
                width="100%"
              >
                <ProgressWithLabel
                  current={stepNumber}
                  total={workflow.steps.length}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      ) : null}
      {onNextStep ? (
        <FormControlLabel
          checked={isReviewed}
          disabled={!canToggleStep}
          labelPlacement="end"
          style={{ justifyContent: 'center' }}
          control={
            <Switch
              className="reviewCalculation"
              color="primary"
            />
          }
          label={
            <Text type={TextType.INPUT_NAME}>
              {stepToPrint.printReviewedBy ? (
                <FormattedMessage
                  defaultMessage="Review by{br}{reviewer}"
                  values={{ reviewer: stepToPrint.name }}
                />
              ) : null}
            </Text>
          }
          onChange={onNextStepProxy}
        />
      ) : null}
    </Fragment>
  );
});
