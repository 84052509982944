import clsx from 'clsx';
import { noop } from 'lodash';
import { memo } from 'react';
import { useFocusRing } from 'react-aria';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { useFormLayoutContext } from '../../layout/form-layout/FormLayout.context';
import { FormLayoutSize } from '../../layout/form-layout/FormLayout.types';
import { FormField } from '../meta/form-field/FormField';
import { useFormFieldProps, type UseFormFieldPropsOptions } from '../meta/form-field/hooks/useFormFieldProps';
import { FieldSize } from '../meta/types';
import { type RadioOptionValue } from '../radio/Radio.types';

import { RadioButtonOption } from './radio-button-option/RadioButtonOption';
import { radioButtonGroupFormFieldType } from './RadioButtonGroup.constants';
import * as styles from './RadioButtonGroup.styles';
import { RadioButtonGroupSize, type RadioButtonOptionShape } from './RadioButtonGroup.types';

const RADIO_GROUP_SIZE_FIELD_SIZE_MAPPING: Record<RadioButtonGroupSize, FieldSize> = {
  [RadioButtonGroupSize.SMALL]: FieldSize.SMALL,
  [RadioButtonGroupSize.MEDIUM]: FieldSize.MEDIUM,
};

const FORM_LAYOUT_SIZE_RADIO_GROUP_SIZE_MAPPING: Record<FormLayoutSize, RadioButtonGroupSize> = {
  [FormLayoutSize.SMALL]: RadioButtonGroupSize.SMALL,
  [FormLayoutSize.MEDIUM]: RadioButtonGroupSize.MEDIUM,
};

export type RadioButtonGroupProps<TValue extends RadioOptionValue> = MergeAll<
  [
    UseFormFieldPropsOptions,
    {
      /** HTML field name. Used for grouping radio elements. */
      name: string;
      /** Current selected value. */
      value: TValue;
      /** Radio options. We can have a mix of only icons / no icons / icons + text options. */
      options: (RadioButtonOptionShape<TValue, false | undefined> | RadioButtonOptionShape<TValue, true>)[];
      /** Change handler. */
      onChange?: (newValue: TValue) => void;
      /** Is the control disabled. */
      disabled?: boolean;
      /** Size of options. */
      size?: RadioButtonGroupSize;
    },
  ]
>;

const RadioButtonGroupBase = function RadioButtonGroup<TValue extends RadioOptionValue>(
  props: RadioButtonGroupProps<TValue>,
) {
  const { size: formLayoutSize } = useFormLayoutContext() || {};

  // There is no :focus-visible-within pseudo-class so we need to do it in JS.
  // Alternative is :has(:focus-visible) in CSS but not supported by Firefox as of writing.
  // https://caniuse.com/css-has
  const { isFocusVisible, focusProps } = useFocusRing({ within: true });
  const {
    formFieldProps,
    otherProps: {
      name,
      value,
      options,
      onChange = noop,
      disabled = false,
      size = formLayoutSize ? FORM_LAYOUT_SIZE_RADIO_GROUP_SIZE_MAPPING[formLayoutSize] : RadioButtonGroupSize.MEDIUM,
    },
  } = useFormFieldProps(props);

  return (
    <FormField
      {...formFieldProps}
      formFieldType={radioButtonGroupFormFieldType}
      size={RADIO_GROUP_SIZE_FIELD_SIZE_MAPPING[size]}
    >
      <div
        {...focusProps}
        aria-disabled={disabled}
        css={styles.radioButtonGroup}
        role="radiogroup"
        className={clsx(size, {
          [styles.IS_DISABLED_CLASSNAME]: disabled,
          [styles.IS_FOCUS_VISIBLE_CLASSNAME]: isFocusVisible,
        })}
      >
        {options.map((option) => (
          <RadioButtonOption<TValue, typeof option.showOnlyIcon>
            key={option.value}
            {...option}
            checked={option.value === value}
            data-testid={`${name}_${option.value}`}
            disabled={disabled}
            name={name}
            size={size}
            onChange={onChange}
          />
        ))}
      </div>
    </FormField>
  );
};

export const RadioButtonGroup = Object.assign(memo(RadioButtonGroupBase) as typeof RadioButtonGroupBase, {
  Size: RadioButtonGroupSize,
});
