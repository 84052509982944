import { memo, useState } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amal-ia/common/routes';
import { type DataConnectorTypes } from '@amal-ia/data-capture/connectors/types';
import { toError } from '@amal-ia/ext/typescript';
import { AuthenticationError } from '@amal-ia/frontend/connected-components/auth';
import { DataConnectorsRepository } from '@amal-ia/frontend/web-data-layers';

export const OAuthCallback = memo(function OAuthCallback() {
  const { connector } = useParams<Record<string, string>>();
  const [error, setError] = useState<string | undefined>();
  const queryParams = useLocation().search;
  const history = useHistory();
  useAsyncEffect(async () => {
    if (queryParams) {
      try {
        await DataConnectorsRepository.authorize(queryParams, connector as DataConnectorTypes);
        history.push(generatePath(routes.DATA_CONNECTOR, { connectorType: connector }));
      } catch (e) {
        setError(toError(e).message);
      }
    }
  }, [queryParams]);
  return error ? <AuthenticationError message={error} /> : null;
});

export default OAuthCallback;
