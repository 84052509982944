import { noop } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { type LinkProps, generatePath, useHistory } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RightSidebarPortal } from '@amal-ia/frontend/connected-components/layout';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import {
  closeThread,
  setStatementThreadReviewThunkAction,
  selectCurrentlyOpenedStatementThread,
  selectCurrentStatement,
  useThunkDispatch,
  refreshTodosCount,
  fetchUsers,
  selectUsersMap,
  getCustomObjectDefinitionFromDefinitionIdInStatemnet,
  getRuleDefinitionFromIdInStatement,
  getVariableDefinitionFromIdInStatement,
  getVariableDefinitionFromMachineNameInStatement,
  getUsersAllowedToViewStatement,
  selectOpenedThread,
  getDatasetNameFromStatementDefinition,
} from '@amal-ia/frontend/web-data-layers';
import {
  type MessageContent,
  type VariableObjectsEnum,
  type CommentThreadMessage,
  StatementThreadScopesType,
  type StatementThreadScope,
} from '@amal-ia/lib-types';
import { objectToQs } from '@amal-ia/lib-ui';
import {
  CommentDrawerPresentation,
  CommentMessage,
  NoCommentsFound,
} from '@amal-ia/payout-collaboration/comments/components';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

interface CommentDrawerContainerProps {
  handleNewMessage: (messageContent: MessageContent[], scope?: StatementThreadScope) => Promise<void>;
}

// Shows an entire comment message thread
export const CommentDrawerContainer = memo(function CommentDrawerContainer({
  handleNewMessage,
}: CommentDrawerContainerProps) {
  const dispatch = useThunkDispatch();

  const statement = useSelector(selectCurrentStatement);
  const history = useHistory();
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
  const { data: currentUser } = useCurrentUser();

  // Currently opened thread. Useful during the creation.
  const { id: currentlyOpenedThreadId, options: openedThreadScope } = useSelector(selectOpenedThread);

  // Currently opened statementThread.
  const currentlyOpenedStatementThread = useSelector(selectCurrentlyOpenedStatementThread);

  // Current thread scope (variable / cell), undefined if the scope is global
  const scope = (currentlyOpenedStatementThread?.scope ?? openedThreadScope) || undefined;

  // Get the users map to have messages' authors infos
  const threadUsers: UsersMap = useSelector(selectUsersMap);

  const closeOpenedThread = useCallback(() => {
    // Close the drawer

    // Id thread is not created yet, close the thread by deleting the temporary one
    if (!currentlyOpenedStatementThread?.id) {
      dispatch(closeThread());
    }
    // change the URL to close it.
    // If there's an threadId, it will dispatch an action to close it
    history.push(generatePath(routes.STATEMENT, { id: statement.id }));
  }, [history, statement, dispatch, currentlyOpenedStatementThread]);

  useEffect(() => {
    // If we have a thread, load related data
    if (currentlyOpenedStatementThread) {
      // Compute authors ids and fetch associated users
      const userIds = (currentlyOpenedStatementThread.thread.messages || []).map(
        (msg: CommentThreadMessage) => msg.author.id,
      );

      dispatch(fetchUsers(userIds)).catch(noop);
    }
  }, [currentlyOpenedStatementThread, dispatch]);

  // Handle new comment form
  const handleNewComment = useCallback(
    async (commentMessage: MessageContent[]): Promise<void> => {
      // Add a message on this thread
      await Promise.all([
        // Post the message.
        handleNewMessage(commentMessage, scope),
        // Also fetch me, now than i'm part of the discussion.
        dispatch(fetchUsers([currentUser.id])),
      ]);
    },
    [handleNewMessage, currentUser, dispatch, scope],
  );

  // Sorted comments
  const sortedComments: CommentThreadMessage[] = useMemo(() => {
    if (!currentlyOpenedStatementThread) {
      return [];
    }

    return currentlyOpenedStatementThread.thread.messages;
  }, [currentlyOpenedStatementThread]);

  useEffect(() => {
    const scrollToBottom = () => {
      endOfMessagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };
    scrollToBottom();
  }, [sortedComments]);

  const handleReview = async (checked: boolean) => {
    if (!statement?.id || !currentlyOpenedStatementThread || !currentlyOpenedStatementThread.id) {
      return;
    }

    // Mark thread as reviewed.
    await dispatch(setStatementThreadReviewThunkAction(statement.id, currentlyOpenedStatementThread.id, checked));
    // And update todos.
    await dispatch(refreshTodosCount());
  };

  const threadDetails = useMemo(() => {
    if (scope?.type === StatementThreadScopesType.OBJECT) {
      // Get rule and variables name
      const rule = getRuleDefinitionFromIdInStatement(statement.results, scope.ruleId)?.name;

      const variableName =
        getVariableDefinitionFromMachineNameInStatement(statement.results, scope.field)?.name || scope.field;

      const variableType: VariableObjectsEnum = getVariableDefinitionFromMachineNameInStatement(
        statement.results,
        scope.field,
      )?.type;

      // Get custom object definition and field from schema
      const customObjectDefinition = getCustomObjectDefinitionFromDefinitionIdInStatemnet(
        statement.results,
        scope.definitionId,
      );

      let link: LinkProps['to'];
      if (customObjectDefinition) {
        link = {
          pathname: customObjectDefinition.machineName
            ? generatePath(routes.DATA_SLUG, { slug: customObjectDefinition.machineName })
            : generatePath(routes.DATA),
          search: `?${objectToQs({ search: scope.id })}`,
        };
      }

      const dataset = scope?.datasetMachineName
        ? getDatasetNameFromStatementDefinition(statement.results, scope.datasetMachineName)
        : undefined;

      return {
        dataset,
        rule,
        dataSource: {
          label: scope?.name ? scope.name : scope?.id,
          link,
          type: variableType,
        },
        variable: variableName,
      };
    }

    if (scope?.type === StatementThreadScopesType.KPI) {
      // Get rule name and KPI variable name
      const ruleName = getRuleDefinitionFromIdInStatement(statement.results, scope?.ruleId)?.name;
      const variableName = getVariableDefinitionFromIdInStatement(statement.results, scope?.id)?.name;

      return {
        rule: ruleName,
        variable: variableName,
      };
    }

    return {};
  }, [scope, statement]);

  if (!currentlyOpenedStatementThread && !currentlyOpenedThreadId) {
    return null;
  }

  return (
    <RightSidebarPortal>
      <CommentDrawerPresentation
        closeOpenedThread={closeOpenedThread}
        commentScope={threadDetails}
        currentStatement={statement}
        getUsersAllowedToViewStatement={getUsersAllowedToViewStatement}
        handleNewComment={handleNewComment}
        isReviewed={currentlyOpenedStatementThread?.thread.isReviewed || false}
        setIsReviewed={handleReview}
        thread={currentlyOpenedStatementThread?.thread || (currentlyOpenedThreadId === -1 && { id: '-1' }) || null}
      >
        {sortedComments.map((message: CommentThreadMessage) => (
          <CommentMessage
            key={message.id}
            message={message}
            user={threadUsers[message.author.id]}
          />
        ))}
        <div
          ref={endOfMessagesRef}
          style={{ paddingBottom: '15px' }}
        />

        {!sortedComments.length && <NoCommentsFound />}
      </CommentDrawerPresentation>
    </RightSidebarPortal>
  );
});
