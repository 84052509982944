import { type Theme, css } from '@emotion/react';

export const IS_SELECTED_CLASSNAME = 'isSelected';
export const IS_HOVERED_CLASSNAME = 'isHovered';
export const IS_EDITABLE_CLASSNAME = 'isEditable';
export const IS_EDITING_CLASSNAME = 'isEditing';
export const HAS_ICON_CLASSNAME = 'hasIcon';

export const verticalTabsMenuItem = css`
  display: flex;
  flex-direction: column;
`;

export const form = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const label = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 3.5px 12px;
  text-align: left;

  border-radius: ${theme.ds.borderRadiuses.squared};

  color: ${theme.ds.colors.gray[900]};

  transition: ${theme.ds.transitions.default('color', 'background-color', 'box-shadow')};

  &:disabled {
    cursor: not-allowed;
    color: ${theme.ds.colors.gray[200]};
  }

  &:focus-visible {
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
    z-index: 1;
    outline: 1px solid ${theme.ds.colors.secondary[200]};
    outline-offset: -1px;
  }

  &:not(:disabled) {
    &.${IS_HOVERED_CLASSNAME}, &.${IS_SELECTED_CLASSNAME} {
      background-color: ${theme.ds.colors.secondary[50]};
    }

    &.${IS_SELECTED_CLASSNAME} {
      color: ${theme.ds.colors.secondary[500]};
    }

    /**
     * When editable, and the container is hovered or the item is selected,
     * reduce the size of the label to leave place for the button.
     */
    &.${IS_EDITABLE_CLASSNAME} {
      &.${IS_SELECTED_CLASSNAME} {
        padding-right: 32px;
      }
    }
  }
`;

export const overflow = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const action = css`
  position: absolute;
  right: 8px;
  top: 2px;
  bottom: 2px;
  z-index: 1;
`;

export const input = (theme: Theme) => css`
  ${theme.ds.typographies.bodyBaseMedium};

  padding: 3.5px 40px 3.5px 12px;
  max-width: 100%;

  outline: none;
  border: none;
  border-radius: ${theme.ds.borderRadiuses.squared};

  color: ${theme.ds.colors.secondary[500]};
  background-color: ${theme.ds.colors.secondary[50]};

  transition: ${theme.ds.transitions.default('color', 'background-color', 'box-shadow')};

  &::placeholder {
    color: ${theme.ds.colors.secondary[100]};
  }

  &.${HAS_ICON_CLASSNAME} {
    padding-left: 36px;
  }

  &:focus-visible {
    box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
    z-index: 1;
    outline: 1px solid ${theme.ds.colors.secondary[200]};
    outline-offset: -1px;
  }
`;

export const inputIcon = (theme: Theme) => css`
  display: flex;
  position: absolute;
  top: 6px;
  left: 12px;

  color: ${theme.ds.colors.gray[900]};
  z-index: 1;

  &.${IS_SELECTED_CLASSNAME} {
    color: ${theme.ds.colors.secondary[500]};
  }
`;
