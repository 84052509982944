import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '../../../general/typography/Typography';

export type TotalItemsProps = {
  page?: number;
  pageSize?: number;
  totalItems?: number;
};

export const TotalItems = memo(function TotalItems({ page, pageSize, totalItems }: TotalItemsProps) {
  return !!pageSize && !!totalItems && page !== undefined ? (
    <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
      <FormattedMessage
        defaultMessage="{startIndex, number} - {endIndex, number} of {totalItems, number}"
        values={{
          startIndex: page * pageSize + 1,
          endIndex: Math.min((page + 1) * pageSize, totalItems),
          totalItems,
        }}
      />
    </Typography>
  ) : undefined;
});
