import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconMessageCheck, IconMessage, IconWriting } from '@tabler/icons-react';
import clsx from 'clsx';
import { noop } from 'lodash';
import { Fragment, memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton, Tooltip } from '@amal-ia/frontend/design-system/components';
import { DEFAULT_THEME } from '@amal-ia/frontend/design-system/themes/default';
import { Text, TextType } from '@amal-ia/lib-ui';

export type KpiCardItemProps = {
  canComment?: boolean;
  canOverwrite?: boolean;
  isThreadOpen?: boolean;
  isThreadReviewed?: boolean;
  isOverwritten?: boolean;
  isForecasted?: boolean;
  commentTooltipText?: string;
  overwriteTooltipText?: string;
  name: string;
  rawValue?: number | string;
  value: number | string;
  onClickComment?: () => void;
  onClickOverwrite?: () => void;
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    position: 'relative',
    width: '15rem',
    maxWidth: '15rem',
    borderRadius: '12px',
    border: 0,
    backgroundColor: theme.palette.grey[100],

    '&:hover': {
      '& $cardActionDisappear': {
        opacity: 1,
      },
    },
  },
  rootHighlight: {
    backgroundColor: theme.palette.tertiary.contrastText,
  },
  rootForecasted: {
    backgroundColor: DEFAULT_THEME.ds.hues.blue[100],
  },
  cardHeader: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(5),
  },
  cardContent: {
    '&:last-child': {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0),
    },
  },
  iconCommentsActive: {
    color: theme.palette.link.main,
  },
  cardActionDisappear: {
    opacity: 0,
    transition: 'opacity .3s',
  },
  kpiTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    lineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
}));

export const KpiCardItem = memo(function KpiCardItem({
  canComment = false,
  canOverwrite = false,
  isThreadOpen = false,
  isThreadReviewed = false,
  isOverwritten = false,
  isForecasted = false,
  commentTooltipText,
  overwriteTooltipText,
  name,
  rawValue,
  value,
  onClickComment = noop,
  onClickOverwrite = noop,
}: KpiCardItemProps) {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, isOverwritten && classes.rootHighlight, isForecasted && classes.rootForecasted)}
      data-kpi={name}
      elevation={0}
      variant="outlined"
    >
      <CardHeader
        className={classes.cardHeader}
        action={
          <Box
            display="flex"
            flexDirection="column"
          >
            {!!canOverwrite && (
              <IconButton
                className={classes.cardActionDisappear}
                icon={<IconWriting color="currentColor" />}
                label={overwriteTooltipText}
                onClick={onClickOverwrite}
              />
            )}

            {!!canComment && (
              <IconButton
                className={clsx(isThreadOpen ? classes.iconCommentsActive : classes.cardActionDisappear)}
                label={commentTooltipText}
                icon={
                  isThreadReviewed ? <IconMessageCheck color="currentColor" /> : <IconMessage color="currentColor" />
                }
                onClick={onClickComment}
              />
            )}
          </Box>
        }
        subheader={
          <Fragment>
            <Tooltip
              content={name}
              placement="top"
            >
              <Text
                className={classes.kpiTitle}
                type={TextType.STATEMENT_KPI_TITLE}
              >
                {name}
              </Text>
            </Tooltip>
            <CardContent className={classes.cardContent}>
              <Text
                lineHeight="144%"
                type={TextType.STATEMENT_KPI_VALUE}
              >
                {rawValue ? (
                  <Tooltip content={<span>{rawValue}</span>}>
                    <Box>{value}</Box>
                  </Tooltip>
                ) : (
                  value
                )}
              </Text>
            </CardContent>
          </Fragment>
        }
      />
    </Card>
  );
});
