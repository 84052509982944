import { http } from '@amal-ia/frontend/kernel/http';

import { type Currency } from '../../types/currency';

const apiEndpoint = '/currencies';

export async function getCurrencies(year: string): Promise<Currency[]> {
  const { data } = await http.get<Currency[]>(`${apiEndpoint}/?year=${year}`);
  return data.sort((c1, c2) => c1.date - c2.date);
}
