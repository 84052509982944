import { Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type RuleType } from '@amal-ia/compensation-definition/shared/types';
import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type MainKpi, type PaymentCategory } from '@amal-ia/lib-types';
import { Text, TextType, PAYMENT_CATEGORIES_LABELS, RULE_TYPES_LABELS_TRANSLATED } from '@amal-ia/lib-ui';

import { formatKpiTotal } from './kpiRuleItem.utils';

export const useKpiRuleItemStyles = makeStyles((theme: AmaliaThemeType) => ({
  kpiRule: {
    color: amaliaTheme.palette.grey['800'],
    '&:hover': {
      color: amaliaTheme.palette.grey['800'],
      textDecorationColor: amaliaTheme.palette.grey['800'],
    },
  },
  kpiRuleClickable: {
    color: theme.palette.link.main,
    '&:hover': {
      color: theme.palette.link.main,
      textDecorationColor: theme.palette.link.main,
    },
  },
  kpiRule__noTracing: {
    cursor: 'auto',
    color: theme.palette.secondary.main,
    display: 'flex',
  },
  kpiForecasted: {
    color: theme.palette.grey['800'],
  },
}));

interface KpiRuleItemProps {
  mainKpi: MainKpi;
  overwriteFormattedValue?: string;
  onClick?: (event: any) => void;
}

export const KpiRuleItem = memo(function KpiRuleItem({ mainKpi, overwriteFormattedValue, onClick }: KpiRuleItemProps) {
  const classes = useKpiRuleItemStyles();
  const { formatMessage } = useIntl();

  const additionalClassName = onClick ? classes.kpiRuleClickable : undefined;

  const formattedTotal = useMemo(() => {
    const content: number | string = formatKpiTotal(mainKpi, overwriteFormattedValue);
    if (!content) {
      return null;
    }
    return (
      <Text
        className={clsx(classes.kpiRule, additionalClassName, mainKpi.isForecastedView && classes.kpiForecasted)}
        type={mainKpi.isForecastedView && mainKpi.isValueForecasted ? TextType.FORECASTED_PAYOUT : TextType.PAYOUT}
      >
        {content}
      </Text>
    );
  }, [mainKpi, overwriteFormattedValue, classes, additionalClassName]);

  const ruleTotalMessage = formatMessage({ defaultMessage: 'Rule total' });

  const mainKpiLabel = useMemo(() => {
    let rulelabel = '';
    if (mainKpi.label) {
      rulelabel = mainKpi.label;
    } else if (RULE_TYPES_LABELS_TRANSLATED[mainKpi.type as RuleType]) {
      rulelabel = formatMessage(RULE_TYPES_LABELS_TRANSLATED[mainKpi.type as RuleType]);
    } else if (PAYMENT_CATEGORIES_LABELS[mainKpi.type as PaymentCategory]) {
      rulelabel = formatMessage(PAYMENT_CATEGORIES_LABELS[mainKpi.type as PaymentCategory]);
    }
    if (mainKpi.isForecastedView) {
      return formatMessage({ defaultMessage: 'Forecasted {rulelabel}' }, { rulelabel });
    }
    return rulelabel;
  }, [formatMessage, mainKpi.isForecastedView, mainKpi.label, mainKpi.type]);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      {mainKpi.label || mainKpi.type ? <Text type={TextType.PAYOUT_LABEL}>{mainKpiLabel}</Text> : null}
      {onClick ? (
        <Link
          aria-label={ruleTotalMessage}
          className={clsx(classes.kpiRule, additionalClassName)}
          href="#"
          underline="hover"
          onClick={onClick}
        >
          {formattedTotal}
        </Link>
      ) : (
        <Box
          aria-label={ruleTotalMessage}
          className={classes.kpiRule__noTracing}
        >
          {formattedTotal}
        </Box>
      )}
    </Box>
  );
});
