import styled from '@emotion/styled';

import { CardActions } from './card-actions/CardActions';
import { CardFooter } from './card-footer/CardFooter';

export const Card = Object.assign(
  styled.article`
    ${({ theme }) => theme.ds.typographies.bodyBaseRegular};
    background-color: ${({ theme }) => theme.ds.colors.gray[0]};
    border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
    box-shadow: ${({ theme }) => theme.ds.shadows.soft};
  `,
  {
    Actions: CardActions,
    Footer: CardFooter,
  },
);
