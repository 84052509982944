import { type Theme, css } from '@emotion/react';

import { ColorCategory, ShadowVariant } from '@amal-ia/frontend/design-system/meta';

export const container = (theme: Theme) => css`
  min-height: 340px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px 40px;

  background-color: ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows[ShadowVariant.SOFT]};
`;

export const body = css`
  width: 100%;
  flex: 1;
`;

export const rulesAndTotalSeparator = css`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const statementTotalRow = css`
  display: flex;
  justify-content: space-between;
`;

export const statementTotalHighlight = (theme: Theme) => css`
  background-color: ${theme.ds.colors[ColorCategory.PRIMARY]['50']};
`;

export const footer = css`
  display: flex;
  justify-content: flex-end;
`;

export const statementLink = css`
  text-decoration: none;
`;
