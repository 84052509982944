import { type ForwardedRef, forwardRef, memo } from 'react';

import { IconButton, type IconButtonProps } from '../../../../general/icon-button/IconButton';

export type CellIconActionProps = Omit<IconButtonProps, 'size' | 'withBackground'>;

const CellIconActionForwardRef = forwardRef(function CellIconAction(
  props: CellIconActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <IconButton
      {...props}
      ref={ref}
      withBackground
      size={IconButton.Size.SMALL}
    />
  );
});

export const CellIconAction = Object.assign(memo(CellIconActionForwardRef), {
  Variant: IconButton.Variant,
});
