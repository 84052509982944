import { Unstable_Grid2 as Grid } from '@mui/material';
import { memo, useMemo } from 'react';

import { type PlanRule, RuleType } from '@amal-ia/compensation-definition/shared/types';
import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import {
  type ComputedRule,
  type ComputedVariable,
  formatCurrencyAmount,
  formatTotal,
  roundNumber,
} from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { RuleAccordion } from '@amal-ia/lib-ui-business';
import { type ComputeEnginePrimitiveTypes } from '@amal-ia/payout-calculation/shared/types';

import RuleResult from '../RuleResult';

interface RuleDefaultProps {
  rule: PlanRule;
  computedRule: ComputedRule & { originalRuleValue: number };
  setCurrentTracingData: any;
  globalSearchValue: string;
  openRuleTracing: (computedRule: ComputedRule, event?: any) => any;
  categoryName: string | null;
  activeRuleId?: string;
}

const RuleDefault = memo(function RuleDefault({
  rule,
  computedRule,
  openRuleTracing,
  globalSearchValue,
  setCurrentTracingData,
  categoryName,
  activeRuleId,
}: RuleDefaultProps) {
  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(activeRuleId === rule.id, 'ruleExpanded');

  const identifier = `${categoryName || 'none'}-${rule.ruleMachineName}`;
  const statement = useStatementDetailContext();

  const { overwriteMainKpiFormattedValue, overwriteMainKpiVariableName } = useMemo(() => {
    let overwriteMainKpiFormattedValueInitial: string;
    let overwriteMainKpiVariableNameInitial: string;
    // Manage the overwrite of the main Kpi
    if (rule.type === RuleType.NON_PAYOUT && rule.configuration?.mainKpiVariableOverwrite) {
      const variableDefinition = Object.values(statement.results.definitions?.variables || {}).find(
        (v) => v.id === rule.configuration?.mainKpiVariableOverwrite,
      );

      if (variableDefinition) {
        const computedVariable = (statement.results.computedObjects || []).find(
          (co) => (co as ComputedVariable).variableMachineName === variableDefinition.machineName,
        );

        if (computedVariable) {
          overwriteMainKpiVariableNameInitial = statement.isForecastedView
            ? `FORECASTED ${variableDefinition.name}`
            : variableDefinition.name;
          overwriteMainKpiFormattedValueInitial = formatTotal(
            (computedVariable.overwrite?.overwriteValue || computedVariable.value) as ComputeEnginePrimitiveTypes,
            variableDefinition.format,
            computedVariable.currency || statement.currency || CurrencySymbolsEnum.EUR,
            statement.rate,
          )?.toString();
        }
      }
    }
    return {
      overwriteMainKpiFormattedValue: overwriteMainKpiFormattedValueInitial,
      overwriteMainKpiVariableName: overwriteMainKpiVariableNameInitial,
    };
  }, [statement, rule.configuration?.mainKpiVariableOverwrite, rule.type]);

  const { formattedAchievedValue, formattedForecastedValue } = {
    formattedForecastedValue: formatCurrencyAmount({
      amount: roundNumber(computedRule?.value) || 0,
      currencyRate: statement.rate,
      currencySymbol: statement.currency,
    }),
    formattedAchievedValue: formatCurrencyAmount({
      amount: roundNumber(computedRule?.originalRuleValue) || 0,
      currencyRate: statement.originalStatement?.rate,
      currencySymbol: statement.originalStatement?.currency,
    }),
  };

  return (
    <RuleAccordion
      key={rule.id}
      helpLabel={rule.description}
      isExpanded={isRuleExpanded}
      label={rule.name}
      machineName={identifier}
      overwriteMainKpiFormattedValue={overwriteMainKpiFormattedValue}
      toggleExpanded={toggleRuleExpanded}
      mainKpi={{
        type: rule.type,
        value: { amount: computedRule?.value || 0, currencyRate: statement.rate, currencySymbol: statement.currency },
        hideTotal: rule.configuration?.hideTotal,
        label: overwriteMainKpiVariableName,
        isForecastedView: statement.isForecastedView,
        formattedForecastedValue,
        formattedOriginalValue: formattedAchievedValue,
        isValueForecasted: statement.isForecastedView && formattedAchievedValue !== formattedForecastedValue,
      }}
      onClick={!statement.isForecastedView && ((e) => openRuleTracing(computedRule, e))}
    >
      {!!computedRule && (
        <Grid container>
          <RuleResult
            computedRule={computedRule}
            globalSearchValue={globalSearchValue}
            ruleDefinition={rule}
            setCurrentTracingData={setCurrentTracingData}
          />
        </Grid>
      )}
    </RuleAccordion>
  );
});

export default RuleDefault;
