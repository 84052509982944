import { memo, useCallback } from 'react';

import { type ComputedStatementDefinitions } from '@amal-ia/lib-types';
import { DatasetButton, DatasetButtonVariant } from '@amal-ia/lib-ui-business';
import {
  type Dataset,
  type FilterDataset,
  type MetricsDataset,
  type QuotaDataset,
} from '@amal-ia/payout-calculation/shared/types';

type StatementRuleDatasetMenuItemProps = Readonly<{
  computedStatementDefinitions: ComputedStatementDefinitions;
  dataset: FilterDataset | MetricsDataset | QuotaDataset;
  selectedDataset: Dataset;
  selectDatasetToDisplay: (dataset: Dataset) => void;
}>;

export const StatementRuleDatasetMenuItem = memo(function StatementRuleDatasetMenuItem({
  computedStatementDefinitions,
  dataset,
  selectedDataset,
  selectDatasetToDisplay,
}: StatementRuleDatasetMenuItemProps) {
  const onClickHandler = useCallback(() => {
    selectDatasetToDisplay(dataset);
  }, [dataset, selectDatasetToDisplay]);

  const customObjectDefinition =
    computedStatementDefinitions?.customObjects[dataset?.customObjectDefinition.machineName];

  const filterDefinition = computedStatementDefinitions?.filters?.[dataset.filterMachineName];

  return (
    <DatasetButton
      customObjectDefinitionType={customObjectDefinition?.type}
      isActive={selectedDataset.id === dataset.id}
      label={filterDefinition.name}
      variant={DatasetButtonVariant.STATEMENT}
      onClick={onClickHandler}
    />
  );
});
