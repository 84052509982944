import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { http } from '@amal-ia/frontend/kernel/http';

const apiEndpoint = '/objects-definitions';

/**
 * Delete object definition.
 * @param objectDefinition
 */
export async function deleteObjectDefinition(objectDefinition: CustomObjectDefinition) {
  return http.delete(`${apiEndpoint}/${objectDefinition.id}`);
}

/**
 * Get object definitions.
 * @return {Promise<CustomObjectDefinition[]>}
 */
export async function getObjectDefinitions(): Promise<CustomObjectDefinition[]> {
  const { data } = await http.get(`${apiEndpoint}`);
  return data;
}

export async function getVirtualObjectDefinitions(): Promise<CustomObjectDefinition[]> {
  const { data } = await http.get('/virtual-custom-object-definitions');
  return data;
}
