import { ClassNames } from '@emotion/react';
import { cloneElement, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { Typography, type TablerIconElement } from '@amal-ia/frontend/design-system/components';

export type SaMenuOption = {
  name: string;
  url: string;
  icon: TablerIconElement;
};

export enum SaMenuEnum {
  home = 'home',
  settings = 'settings',
  companies = 'companies',
  calculations = 'calculations',
  refreshments = 'refreshments',
  connectors = 'connectors',
  statements = 'statements',
  users = 'users',
  plans = 'plans',
}

interface SAMenuTabProps {
  menu: SaMenuEnum;
  details: SaMenuOption;
}

export const SAMenuTab = memo(function SAMenuTab({ menu, details }: SAMenuTabProps) {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <NavLink
          key={menu}
          activeClassName="active"
          to={details.url}
          className={css`
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: ${theme.ds.colors.gray[900]};
            border-bottom: 2px solid transparent;

            transition: ${theme.ds.transitions.default()};
            text-decoration: none;

            &.active {
              border-bottom-color: ${theme.ds.colors.secondary[500]};

              > svg {
                display: block;
              }
            }
          `}
        >
          {cloneElement(details.icon, {
            size: 16,
            className: css`
              display: none;

              @media (max-width: 1000px) {
                display: block;
              }
            `,
          })}

          <Typography
            variant={Typography.Variant.BODY_BASE_MEDIUM}
            className={css`
              @media (max-width: 1000px) {
                display: none;
              }
            `}
          >
            {details.name}
          </Typography>
        </NavLink>
      )}
    </ClassNames>
  );
});
