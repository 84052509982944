import { css } from '@emotion/react';
import { Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Fragment, memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  toolbar: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    minHeight: theme.spacing(8),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  leftActions: {
    marginRight: theme.spacing(2),
  },
  rightActions: {
    marginLeft: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}`,
  },
  headerContainerWithHeaderContent: {
    marginBottom: theme.spacing(2),
  },
  separator: {
    flex: 1,
  },
}));

// transform group to element and just apply loop on this
interface ToolbarProps {
  leftActions?: ReactNode | string;
  rightActions?: ReactNode | string;
  containerClassName?: string;
  children?: ReactNode;
  groups?: ElementGroups[];
}

export interface ElementGroups {
  groupName?: string;
  leftActions?: ReactNode | string;
  rightActions?: ReactNode | string;
  children?: ReactNode;
}

export const Toolbar = memo(function Toolbar({
  leftActions,
  rightActions,
  containerClassName,
  children,
  groups,
}: ToolbarProps) {
  const classes = useStyles();
  return (
    <Paper
      className={clsx(classes.toolbar, containerClassName)}
      elevation={0}
      css={(theme) => css`
        border-radius: ${theme.ds.borderRadiuses.squared};
        box-shadow: ${theme.ds.shadows.soft};
      `}
    >
      <Box className={classes.headerContainer}>
        {groups ? (
          groups.map((groupItem) => (
            <Fragment key={groupItem.groupName}>
              <Box className={classes.header}>{groupItem.groupName}</Box>
              <Box className={clsx(classes.header, children && classes.headerContainerWithHeaderContent)}>
                {groupItem.leftActions ? <Box className={classes.leftActions}>{groupItem.leftActions}</Box> : null}
                <Box className={classes.separator} />
                {groupItem.rightActions ? <Box className={classes.rightActions}>{groupItem.rightActions}</Box> : null}
              </Box>
            </Fragment>
          ))
        ) : (
          <Box className={clsx(classes.header, children && classes.headerContainerWithHeaderContent)}>
            {leftActions ? <Box className={classes.leftActions}>{leftActions}</Box> : null}
            <Box className={classes.separator} />
            {rightActions ? <Box className={classes.rightActions}>{rightActions}</Box> : null}
          </Box>
        )}
      </Box>
    </Paper>
  );
});
