import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type Relationship, type RuleType } from '@amal-ia/compensation-definition/shared/types';
import { FORMATS_SYMBOLS_AND_LABELS, type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton, type TablerIconElement } from '@amal-ia/frontend/design-system/components';
import { useClipboard } from '@amal-ia/frontend-kernel-clipboard';
import {
  type DesignerActionToRender,
  DesignerObjectsActions,
  type PlansMap,
  TokenType,
  VariableObjectsEnum,
} from '@amal-ia/lib-types';
import { RULE_TYPES_CONF_LABELS, Text, TextType, Tooltip, useCustomIconStyles } from '@amal-ia/lib-ui';

import { designerObjectsActionsDetails, designerObjectsDetails } from '../../../utils/designer';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 32,

    '&:hover $label': {
      fontStyle: 'italic',
    },
    '&:hover $actions': {
      display: 'inline-flex',
    },
  },
  actions: {
    display: 'none',
  },
  label: {
    margin: 0,
  },
  formatIndicator: {
    color: theme.palette.tertiary.main,
  },
  contextLabel: {
    color: theme.palette.grey[600],
  },
}));

interface DesignerObjectProps {
  canEdit: boolean;
  object: any;
  type: TokenType;
  onClickDesignerAction: (
    actionType: DesignerObjectsActions,
    type: TokenType,
    options?: { selectedObjectId?: string },
  ) => any;
  context?: string;
  isDuplicated?: boolean;
  plansMap: PlansMap;
}

export const DesignerObject = memo(function DesignerObject({
  canEdit,
  object,
  type,
  onClickDesignerAction,
  context,
  isDuplicated,
  plansMap,
}: DesignerObjectProps) {
  const classes = useStyles();
  const iconClasses = useCustomIconStyles();

  const { copy } = useClipboard();
  const { formatMessage } = useIntl();

  const labelStyle = useMemo(
    () => ({
      backgroundColor: object.color || designerObjectsDetails[type].color,
      // Cursor as a pointer if we can click on it.
      cursor: designerObjectsDetails[type].onCopy && 'pointer',
    }),
    [object, type],
  );

  const formatSymbolAndLabel = useMemo(
    () => FORMATS_SYMBOLS_AND_LABELS[object?.format as FormatsEnum] || null,
    [object],
  );

  const fullMachineName = useMemo(() => {
    if (!Object.values(VariableObjectsEnum).includes(object.type)) {
      return object.machineName;
    }
    if (type === TokenType.LINK) {
      return null;
    }
    return `${object?.type === VariableObjectsEnum.object ? object?.object?.machineName : object.type}.${
      object.machineName
    }`;
  }, [object, type]);

  const onClick = useCallback(() => {
    if (designerObjectsDetails[type].onCopy) {
      copy(designerObjectsDetails[type].onCopy(object));
    }
  }, [type, object, copy]);

  const tooltipTitle = useMemo(() => {
    let additionalTitle = '';

    if (type === TokenType.PROPERTY) {
      additionalTitle = object.definition.machineName;
    }

    if (type === TokenType.RULE && object?.type) {
      additionalTitle = formatMessage(RULE_TYPES_CONF_LABELS[object.type as RuleType]);
    }

    if ([TokenType.FILTER, TokenType.FIELD].includes(type) && object?.object?.name) {
      additionalTitle = object.object.name;
    }

    if (type === TokenType.LINK) {
      return object.name;
    }

    return `${object?.name} ${additionalTitle ? ` // ${additionalTitle}` : ''}`;
  }, [object, type, formatMessage]);

  const description = useMemo(() => designerObjectsDetails[type].getDescription?.(object) || '', [type, object]);

  const formulaToPrint = useMemo(() => {
    let formula = object?.formula || object?.condition;

    if (type === TokenType.PROPERTY) {
      return `${object.definition.machineName}.${object.id}`;
    }

    if (type === TokenType.FUNCTION) {
      const params = object.hasInfiniteParams
        ? 'param1, param2, ...'
        : (object.params || []).map((p: any) => p.name).join(', ');
      return `${object.name}(${params})`;
    }

    if (!formula && type === TokenType.LINK) {
      const relationship = object as Relationship;
      formula =
        `${relationship.fromDefinitionMachineName}.${relationship.fromDefinitionField}` +
        ` = ${relationship.toDefinitionMachineName}.${relationship.toDefinitionField}`;
    }

    return formula;
  }, [object, type]);

  const actionsToRender: DesignerActionToRender[] = useMemo(
    () =>
      designerObjectsDetails[type].possibleActions
        .filter(
          (action) =>
            ![
              DesignerObjectsActions.CREATE,
              DesignerObjectsActions.EDIT_ACHIEVED,
              DesignerObjectsActions.FORECAST,
            ].includes(action),
        )
        .map((action) => ({
          children: designerObjectsActionsDetails[action].icon(iconClasses),
          label: designerObjectsActionsDetails[action].label,
          callback: () => onClickDesignerAction(action, type, { selectedObjectId: object?.id }),
          action,
        })),
    [type, onClickDesignerAction, object, iconClasses],
  );

  return (
    <li className={clsx(classes.listElement)}>
      <Box
        flex={1}
        paddingY={0.5}
      >
        <Tooltip
          placement="right"
          title={
            <Box
              padding={1}
              width={400}
            >
              <Text type={TextType.TOOLTIP}>
                {tooltipTitle}
                {formatSymbolAndLabel ? (
                  <span className={classes.formatIndicator}>&nbsp;({formatSymbolAndLabel.label})</span>
                ) : null}
              </Text>
              {fullMachineName ? <em>{fullMachineName}</em> : null}
              {formulaToPrint ? (
                <Box
                  fontFamily="Courier"
                  mt={2}
                >
                  {formulaToPrint}
                </Box>
              ) : null}

              {description ? <Box mt={2}>{description}</Box> : null}
            </Box>
          }
        >
          <span>
            {/* eslint-disable-next-line */}
            <span
              className={classes.label}
              style={labelStyle}
              onClick={onClick}
            >
              {object?.label || object?.name}
            </span>
            {formatSymbolAndLabel ? (
              <span className={classes.formatIndicator}>&nbsp;{formatSymbolAndLabel.symbol}</span>
            ) : null}
            {isDuplicated ? (
              <span className={classes.contextLabel}>
                &nbsp;
                {object.planId !== context && context !== 'global' ? plansMap[object.planId]?.name || 'Global' : ''}
              </span>
            ) : null}
          </span>
        </Tooltip>
      </Box>
      {canEdit ? (
        <Box className={classes.actions}>
          {actionsToRender.map(({ label, callback, children }, index) => (
            <IconButton
              key={index}
              icon={children as TablerIconElement}
              label={label}
              onClick={callback}
            />
          ))}
        </Box>
      ) : null}
    </li>
  );
});
