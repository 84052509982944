import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('dayOfWeekNumber', AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the number of weekday of a given date: Sunday is 0, Saturday is 6';

func.params = [
  {
    name: 'date',
    description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
  },
];

func.examples = [
  {
    desc: 'Returns 5 for Friday',
    formula: 'dayOfWeekNumber(toDate("2022-09-16"))' as AmaliaFormula,
    result: 5,
  },
  {
    desc: 'Returns the number of weekday of Close Date.',
    formula: 'dayOfWeekNumber(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null => moment(date, 'X').day();

export default func;
