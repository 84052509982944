import { type ActionCreator } from 'redux';

import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/shared/types';

import * as CompaniesRepository from '../../services/companies/companies.repository';
import { addSnackbar } from '../snackbars/actions';

import { COMPANIES_ACTIONS } from './constants';

const companyStart: ActionCreator<ReduxAction> = () => ({
  type: COMPANIES_ACTIONS.START,
});

const companyError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: COMPANIES_ACTIONS.ERROR,
  error,
});

const setCompany: ActionCreator<ReduxAction> = (company: Company) => ({
  type: COMPANIES_ACTIONS.SET_COMPANY,
  payload: { company },
});

export const fetchCompany = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(companyStart());

  try {
    const company = await CompaniesRepository.getCompany();
    return dispatch(setCompany(company));
  } catch (error) {
    return dispatch(companyError(error));
  }
};

export const patchCompany =
  (company: Company): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(companyStart());

    try {
      const updatedCompany = await CompaniesRepository.updateCompany(company);
      dispatch(addSnackbar({ message: 'Company updated!', options: { variant: 'success' } }));
      return dispatch(setCompany(updatedCompany));
    } catch (error) {
      return dispatch(companyError(error));
    }
  };
