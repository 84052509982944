import { memo, useMemo } from 'react';

import { colors } from '@amal-ia/ext/mui/theme';
import { type ChangelogEntry, ChangelogReleaseType, ChangelogRole } from '@amal-ia/lib-types';
import { Pill } from '@amal-ia/lib-ui';

export interface ChangelogPillsProps {
  changelogEntry: ChangelogEntry;
}

const ROLES_COLOR_CODES: Record<ChangelogRole, string> = {
  [ChangelogRole.ADMIN]: colors['tertiary-100'],
  [ChangelogRole.MANAGER]: colors['tertiary-100'],
  [ChangelogRole.SALESREP]: colors['tertiary-100'],
};

const RELEASE_TYPE_COLOR_CODES: Record<ChangelogReleaseType, string> = {
  [ChangelogReleaseType.NEW_RELEASE]: colors['green-100'],
  [ChangelogReleaseType.IMPROVEMENT]: colors['primary-100'],
};

export const ChangelogPills = memo(function ChangelogPills({ changelogEntry }: ChangelogPillsProps) {
  const pills = useMemo(
    () =>
      [
        changelogEntry.releaseType && {
          label: changelogEntry.releaseType,
          color: RELEASE_TYPE_COLOR_CODES[changelogEntry.releaseType],
        },
        ...changelogEntry.roles.map((role) => ({
          label: role,
          color: ROLES_COLOR_CODES[role],
        })),
        !!changelogEntry.topic && {
          label: changelogEntry.topic,
          color: colors['grey-100'],
        },
      ].filter(Boolean),
    [changelogEntry],
  );

  return (
    <div>
      {pills.map((p) => (
        <Pill
          key={p.label}
          backgroundColor={p.color}
          label={p.label}
        />
      ))}
    </div>
  );
});

export default ChangelogPills;
