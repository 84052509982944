import { css } from '@emotion/react';

export const cellWithActions = css`
  justify-content: space-between;
  gap: 8px;
`;

export const actionsContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: none;
  // We're supposed to only use buttons and icon buttons inside the actions.
  // This prevents button text from wrapping which is causing weird width calculations and overflow with flex: none on actions.
  white-space: nowrap;
`;

export const actionsContainerHideHover = css`
  display: none;

  *:hover > & {
    display: flex;
  }
`;
