import { Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { type Dispatch, memo, type SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { selectCurrentStatement, useCurrentCompany } from '@amal-ia/frontend/web-data-layers';
import { type Adjustment, type PlanCategory, type TracingTypes } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { BadgeList } from '@amal-ia/lib-ui-business';

import StatementAdjustmentsList from './Adjustments/StatementAdjustmentsList';
import StatementCategory from './StatementCategory';
import { CURRENCY_RATE } from './StatementDetail';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  subheader: {
    padding: theme.spacing(4),
  },
}));

interface StatementDetailPayoutProps {
  setSelectAdjustment: Dispatch<SetStateAction<Adjustment | null>>;
  setCurrentTracingData: Dispatch<SetStateAction<TracingTypes.CurrentTracingDataType | null>>;
}

const StatementDetailPayout = memo(function StatementDetailPayout({
  setSelectAdjustment,
  setCurrentTracingData,
}: StatementDetailPayoutProps) {
  const classes = useStyles();
  const { company } = useCurrentCompany();
  const { ruleid: ruleId } = useParams<Record<string, string>>();

  const { currency } = useStatementDetailContext();

  const statement = useSelector(selectCurrentStatement);
  const searchValue = '';

  const isBadgesEnabled = useMemo(() => company.featureFlags?.BADGES, [company]);

  return (
    <Grid
      container
      className={classes.subheader}
      justifyContent="center"
      spacing={4}
    >
      {isBadgesEnabled ? (
        <BadgeList
          badgeAwards={statement.badgeAwards || []}
          configurations={statement.results.definitions.plan.badgeConfigurations}
        />
      ) : null}

      <StatementCategory
        activeRuleId={ruleId}
        categoryName={null}
        globalSearchValue={searchValue}
        setCurrentTracingData={setCurrentTracingData}
      />

      {(statement.results.definitions.plan.categoriesV2 || []).map((category: PlanCategory) => (
        <StatementCategory
          key={category.name}
          activeRuleId={ruleId}
          categoryName={category.name}
          globalSearchValue={searchValue}
          setCurrentTracingData={setCurrentTracingData}
        />
      ))}
      {statement.adjustments && statement.adjustments.length > 0 ? (
        <StatementAdjustmentsList
          currencyRate={CURRENCY_RATE}
          currencySymbol={currency}
          setSelectAdjustment={setSelectAdjustment}
          statement={statement}
        />
      ) : null}
    </Grid>
  );
});

export default StatementDetailPayout;
