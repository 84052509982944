import { memo } from 'react';
import { useIntl } from 'react-intl';

import { SwitchFieldBase } from '@amal-ia/lib-ui';

type OptionsProps = {
  isApplyToOverall: boolean;
  setIsApplyToOverall: (field: string, value: any) => void;
};

export const OverwriteOptions = memo(function OverwriteOptions({
  isApplyToOverall,
  setIsApplyToOverall,
}: OptionsProps) {
  const { formatMessage } = useIntl();
  return (
    <SwitchFieldBase
      checked={!isApplyToOverall}
      id="isApplyToOverall"
      label={formatMessage({ defaultMessage: 'Apply to this statement only' })}
      labelLeft={formatMessage({ defaultMessage: 'Apply overall', description: 'Apply to all statements' })}
      name="isApplyToOverall"
      onChange={() => setIsApplyToOverall('isApplyToOverall', !isApplyToOverall)}
    />
  );
});
