import { type ComponentPropsWithoutRef, memo } from 'react';

import { TextOverflow, type TextOverflowProps } from '../../../../general/text-overflow/TextOverflow';
import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';

export type CellTextProps = ComponentPropsWithoutRef<typeof TableDataCellContent> & {
  tooltipContent?: TextOverflowProps['tooltipContent'];
  tooltipPlacement?: TextOverflowProps['tooltipPlacement'];
};

export const CellText = memo(function CellText({
  tooltipContent,
  tooltipPlacement,
  children,
  ...props
}: CellTextProps) {
  return (
    <TableDataCellContent {...props}>
      <TextOverflow
        tooltipContent={tooltipContent}
        tooltipPlacement={tooltipPlacement}
      >
        {children}
      </TextOverflow>
    </TableDataCellContent>
  );
});
