import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css, useTheme, type Interpolation, type Theme } from '@emotion/react';
import { IconGripVertical } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, type ReactNode } from 'react';

import { Tooltip, Typography, UnstyledButton } from '@amal-ia/frontend/design-system/components';

import * as styles from './KpiRowItem.styles';

export type KpiRowItemProps = {
  kpiActions?: ReactNode;
  kpiOnClick?: () => void;
  kpiIcon?: ReactNode;
  kpiStyles?: Interpolation<Theme>;
  name: ReactNode;
  dataKpiKey: string;
  rawValue?: number | string;
  value: ReactNode;
  isDraggable?: boolean;
  elementId?: string;
};

export const KpiRowItem = memo(function KpiRowItem({
  kpiActions,
  kpiOnClick,
  kpiIcon,
  kpiStyles,
  name,
  dataKpiKey,
  rawValue,
  value,
  isDraggable = false,
  elementId,
}: KpiRowItemProps) {
  const theme = useTheme();
  const kpiContainerStyles = [
    styles.kpiContainer,
    styles.isButton(!!kpiOnClick),
    kpiStyles,
    styles.isDraggable(isDraggable),
  ].filter(Boolean);

  const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging, transition } = useSortable({
    id: elementId,
    disabled: !isDraggable,
  });

  const isKpiDragging = isDraggable && isDragging;

  return (
    <div
      ref={setNodeRef}
      data-kpi={`${dataKpiKey} Container`}
      css={[
        styles.container,
        css`
          transform: ${CSS.Transform.toString(transform)};
          transition: ${[transition, theme.ds.transitions.default('box-shadow')].filter(Boolean).join(', ')};
          z-index: ${isKpiDragging ? 1 : 0};
          box-shadow: ${isKpiDragging ? theme.ds.shadows.hard : 'none'};
          background-color: ${isKpiDragging ? theme.ds.colors.gray[0] : 'transparent'};
        `,
      ]}
    >
      {!!isDraggable && (
        <div
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
          css={styles.grabHandle}
        >
          <IconGripVertical
            color={theme.ds.colors.gray[500]}
            size={16}
          />
        </div>
      )}
      <UnstyledButton
        css={kpiContainerStyles}
        data-kpi={dataKpiKey}
        onClick={kpiOnClick ? kpiOnClick : noop}
      >
        <div css={styles.nameContainer}>
          {kpiIcon}
          <Typography variant={Typography.Variant.BODY_LARGE_REGULAR}>{name}</Typography>
        </div>
        <div css={styles.divider} />
        <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>
          {rawValue ? (
            <Tooltip content={<span>{rawValue}</span>}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            value
          )}
        </Typography>
      </UnstyledButton>
      <div
        id="actions-container"
        css={[
          styles.actionsContainer,
          css`
            & > :not(:first-child) {
              visibility: ${isKpiDragging && 'hidden !important'};
            }
          `,
        ]}
      >
        {kpiActions}
      </div>
    </div>
  );
});
