import { css, type Theme } from '@emotion/react';
import { Fragment, memo, type MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import { FieldValuePrettyFormat, LabelVariant } from '@amal-ia/data-capture/fields/components';
import { type FormatsEnum, type PropertyRef } from '@amal-ia/data-capture/fields/types';
import { Tooltip, Typography, UnstyledButton } from '@amal-ia/frontend/design-system/components';

import { messages } from './OverwriteTooltipValue.messages';

export const label = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[800]};
`;

export const valueContainer = css`
  max-width: 208px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
`;

export const converted = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[700]};
`;

export enum OverwriteTooltipValueVariant {
  NEW = 'NEW',
  OLD = 'OLD',
}

export type OverwriteTooltipValueProps<TValue> = {
  variant?: OverwriteTooltipValueVariant;
  valueFormat?: FormatsEnum;
  valueRef?: PropertyRef;
  value: TValue;
  rawValue?: TValue;
  onClick: MouseEventHandler<unknown>;
};

export const OverwriteTooltipValue = memo(function OverwriteTooltipValue({
  variant = OverwriteTooltipValueVariant.NEW,
  valueFormat,
  valueRef,
  value,
  rawValue,
  onClick,
}: OverwriteTooltipValueProps</* FIXME */ any>) {
  return (
    <Fragment>
      <Typography
        css={label}
        variant={Typography.Variant.BODY_SMALL_MEDIUM}
      >
        <FormattedMessage
          {...(variant === OverwriteTooltipValueVariant.NEW ? messages.NEW_VALUE : messages.OLD_VALUE)}
        />
      </Typography>
      <Tooltip
        content={
          <FormattedMessage
            {...messages.COPY_VALUE}
            values={{ value }}
          />
        }
      >
        <div css={valueContainer}>
          <UnstyledButton onClick={onClick}>
            <FieldValuePrettyFormat
              format={valueFormat}
              value={value}
              valueRef={valueRef}
              variant={LabelVariant.DEFAULT}
              onClick={onClick}
            />
          </UnstyledButton>
        </div>
      </Tooltip>
      {rawValue ? (
        <Typography
          css={converted}
          variant={Typography.Variant.BODY_XSMALL_REGULAR}
        >
          <FormattedMessage
            {...messages.CONVERTED_FROM}
            values={{ value: rawValue }}
          />
        </Typography>
      ) : null}
    </Fragment>
  );
});
