import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type PeriodsMap, type ReduxAction } from '@amal-ia/lib-types';

import { PERIOD_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case PERIOD_ACTIONS.START:
        return state + 1;
      case PERIOD_ACTIONS.ERROR:
      case PERIOD_ACTIONS.SET_PERIODS:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state: PeriodsMap = {}, action: ReduxAction) => {
    switch (action.type) {
      case PERIOD_ACTIONS.SET_PERIODS:
        return keyBy(action.payload.periods, 'id');
      default:
        return state;
    }
  },
});
