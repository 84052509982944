import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  CONFIRMATION_MODAL_TITLE: { defaultMessage: 'Reset workflow?' },
  CONFIRMATION_MODAL_CONTENT: {
    defaultMessage:
      'You are about to reset the validation workflow for this statement.{br}All the users that already reviewed this statement will have to review it again.',
  },
  PREVIOUS_STEP: { defaultMessage: 'Previous step' },
  RESET_WORKFLOW: { defaultMessage: 'Reset the workflow' },
  REVIEW_BY: { defaultMessage: 'Review by {reviewer}' },
  REVIEWED: { defaultMessage: 'Reviewed' },
} as const);
