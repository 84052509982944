import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { http } from '@amal-ia/frontend/kernel/http';

const apiEndpoint = '/currency_rates';

/**
 * Get currency rate for a given date.
 * @param symbol
 * @param date
 */
export async function getCurrencyRate(symbol: CurrencySymbolsEnum, date: number): Promise<number> {
  const { data } = await http.get<number>(`${apiEndpoint}/${symbol}?date=${date}`);
  return data;
}
