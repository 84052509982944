import { useMemo, useState } from 'react';
import { useStore } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { toError } from '@amal-ia/ext/typescript';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import {
  useThunkDispatch,
  fetchAllUsers,
  fetchUsers,
  USERS_ACTIONS as USER_ACTIONS,
  selectUsersMap,
  getDataFilters,
  type OverwriteFilters,
} from '@amal-ia/frontend/web-data-layers';
import { type RootState } from '@amal-ia/lib-types';
import { type FilterItem, type SelectItem, type SelectUserItem } from '@amal-ia/lib-ui-business';

export default () => {
  const dispatch = useThunkDispatch();
  const store = useStore<RootState>();
  const { snackError } = useSnackbars();
  const [filterBarItems, setFilterBarItems] = useState<Record<string, FilterItem>>();
  const [additionalFilters, setAdditionalFilters] =
    useState<Record<'dataSources' | 'overwrittenBy' | 'periods' | 'status' | 'types', FilterItem>>();
  useAsyncEffect(async () => {
    try {
      const overwriteFilters = await getDataFilters();

      const userAction = await dispatch(fetchAllUsers(true));

      if (userAction.type !== USER_ACTIONS.SET_USERS) {
        return;
      }

      const userMap = selectUsersMap(store.getState());

      if (overwriteFilters?.users?.length > 0) {
        await dispatch(fetchUsers(overwriteFilters.users.map((u) => u.value)));
      }

      const overwriteUsers = overwriteFilters.users?.map((u) => userMap[u.value]).filter(Boolean);
      setFilterBarItems({
        users: {
          checked: [],
          users: overwriteUsers,
          isUserFilter: true,
          label: 'Users',
        } as SelectUserItem,
        plans: {
          checked: [],
          elements: overwriteFilters.plans,
          label: 'Plans',
        },
      });

      setAdditionalFilters({
        periods: {
          checked: [],
          elements: overwriteFilters.periods,
          label: 'Periods',
        },
        status: {
          checked: [],
          elements: [
            { label: 'Active', value: 'status_active' },
            { label: 'Inactive', value: 'status_inactive' },
          ],
          label: 'Status',
        },
        overwrittenBy: {
          checked: [],
          elements: overwriteFilters.overwrittenBy,
          isUserFilter: true,
          label: 'Overwritten By',
        },
        dataSources: {
          checked: [],
          elements: overwriteFilters.dataSources,
          label: 'Datasource',
        },
        types: {
          checked: [],
          elements: overwriteFilters.types,
          label: 'Types',
        },
      });
    } catch (e) {
      snackError(toError(e).message);
    }
  }, [dispatch, snackError]);

  const selectedValues: OverwriteFilters = useMemo(
    () => ({
      dataSourceIds: (filterBarItems?.dataSources as SelectItem)?.checked,
      status: (filterBarItems?.status as SelectItem)?.checked,
      planIds: (filterBarItems?.plans as SelectItem)?.checked,
      periodIds: (filterBarItems?.periods as SelectItem)?.checked,
      typeName: (filterBarItems?.types as SelectItem)?.checked,
      overwrittenByIds: (filterBarItems?.overwrittenBy as SelectItem)?.checked,
      userIds: (filterBarItems?.users as SelectUserItem)?.checked?.map((u) => u.id),
    }),
    [filterBarItems],
  );

  return {
    filterBarItems,
    setFilterBarItems,
    additionalFilters,
    selectedValues,
  };
};
