import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { formatCurrencyAmount, formatTotalAmount, PaymentCategory, roundNumber } from '@amal-ia/lib-types';
import { Trend, PAYMENT_CATEGORIES_LABELS } from '@amal-ia/lib-ui';

import { Payout } from '../Payout/Payout';

import { MultiPayoutTab } from './MultiPayoutTab';

interface MultiPayoutProps {
  view: PaymentCategory;
  setView: (view: PaymentCategory) => void;
  hideHoldAndRelease: boolean;
  values: {
    [PaymentCategory.achievement]: number | undefined;
    [PaymentCategory.hold]: number | undefined;
    [PaymentCategory.paid]: number | undefined;
  };
  averages?: {
    [PaymentCategory.achievement]: number | undefined;
    [PaymentCategory.hold]: number | undefined;
    [PaymentCategory.paid]: number | undefined;
  };
  trendPreviousValue?: number;
  trendNextValue?: number;
  currencyRate: number;
  currencySymbol: CurrencySymbolsEnum;
  total?: {
    label: string;
    value: number | null | undefined;
    format: FormatsEnum;
  };
  overridesLabels?: {
    tabs?: {
      [PaymentCategory.achievement]?: string;
    };
  };
  isStatementEvolutionEnabled?: boolean;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    maxWidth: 500,
  },
  tabsRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  paymentRoot: {
    height: '100%',
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  hidden: {
    visibility: 'hidden',
  },
  trendChip: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 50,
    padding: theme.spacing(0, 0.5),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1.2),
    alignSelf: 'end',
  },
  averageChip: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(0.5, 1),
    borderRadius: 50,
  },
}));

export const MultiPayout = memo(function MultiPayout({
  view,
  setView,
  values,
  averages,
  trendPreviousValue,
  trendNextValue,
  currencyRate,
  currencySymbol,
  hideHoldAndRelease,
  total,
  overridesLabels,
  isStatementEvolutionEnabled = false,
}: MultiPayoutProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const average = (averages as Record<PaymentCategory, number> | undefined)?.[view];

  const totalFormattedAmount = useMemo(() => {
    const value = total ? total.value || 0 : (values as Record<PaymentCategory, number>)[view];
    const totalFormat = total ? total.format : FormatsEnum.currency;
    return formatTotalAmount(value, totalFormat, currencySymbol, currencyRate);
  }, [currencyRate, currencySymbol, total, values, view]);

  const totalLabel = useMemo(() => {
    if (total) {
      return total.label;
    }
    if (hideHoldAndRelease) {
      return formatMessage(PAYMENT_CATEGORIES_LABELS[PaymentCategory.achievement]);
    }
    return null;
  }, [formatMessage, total, hideHoldAndRelease]);

  return (
    <Box
      className={classes.root}
      data-cy="multi-payout-view-selector"
    >
      {!hideHoldAndRelease && !total && (
        <Box className={classes.tabsRoot}>
          {[PaymentCategory.achievement, PaymentCategory.paid, PaymentCategory.hold].map((paymentCategory) => (
            <MultiPayoutTab
              key={paymentCategory}
              overridesLabels={overridesLabels}
              paymentCategory={paymentCategory}
              setView={setView}
              view={view}
            />
          ))}
        </Box>
      )}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Box className={classes.paymentRoot}>
          <Payout
            amount={totalFormattedAmount}
            color="primary"
            label={totalLabel}
          />
          {!!average && average !== Infinity && (
            <span className={classes.averageChip}>
              <FormattedMessage
                defaultMessage="AVG: {amount}"
                values={{
                  amount: formatCurrencyAmount({
                    amount: roundNumber(average),
                    currencyRate,
                    currencySymbol,
                  }),
                }}
              />
            </span>
          )}
        </Box>
        {isStatementEvolutionEnabled ? (
          <Box className={classes.trendChip}>
            {!!trendPreviousValue && !!trendNextValue && (
              <Trend
                // Just hide the Trend instead of not render it, so all three tabs have the same height.
                // Since they are in a horizontal flex, we'd have to hardcode the height.
                // Instead we just keep the Trend hidden as a placeholder.
                className={clsx(view !== PaymentCategory.achievement && classes.hidden)}
                nextValue={trendNextValue}
                previousValue={trendPreviousValue}
              />
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
});
