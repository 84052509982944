import { Fragment, memo, type ReactNode } from 'react';

import { NavbarLandingPage } from './Navbar/Navbar';

interface LayoutAnonymousProps {
  children: ReactNode;
}

export const LayoutAnonymous = memo(function LayoutAnonymous({ children }: LayoutAnonymousProps) {
  return (
    <Fragment>
      <NavbarLandingPage />
      {children}
    </Fragment>
  );
});
