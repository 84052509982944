import { css } from '@emotion/react';
import { Warning as WarningIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useCallback, useMemo, useState, Fragment } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Avatar, Tooltip, type AvatarProps } from '@amal-ia/frontend/design-system/components';
import {
  type ComputedHighlightedKpi,
  type HighlightedKpiIdentifier,
  type StatementError,
  StatementErrorLevel,
} from '@amal-ia/lib-types';
import { type GaugeGroupProps } from '@amal-ia/lib-ui';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { StatementGaugeGroup } from '../statement-gauge-group/StatementGaugeGroup';

const themeColorForStatementErrorType: Record<StatementErrorLevel, string> = {
  [StatementErrorLevel.WARNING]: amaliaTheme.palette.warning.main,
  [StatementErrorLevel.ERROR]: amaliaTheme.palette.error.main,
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    position: 'relative',
  },
  kpiTooltipTable: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    columnGap: theme.spacing(1),
    textAlign: 'center',
  },
  kpiTooltipTablePercent: {
    gridTemplateColumns: 'auto',
  },
  warningIconContainer: {
    position: 'absolute',
    bottom: '15%',
    left: '65%',
    height: '2.2rem',
    width: '2.2rem',
    borderRadius: '1.1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
  warningIcon: {},
}));

interface StatementAvatarProps {
  user: UserComputed;
  error?: StatementError;
  highlightedKpis?: Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null>;
  fullProgress?: boolean;
  isForecast?: boolean;
  hideError?: boolean;
  hideKpisOnHover?: boolean;
  avatarSize?: AvatarProps['size'];
  gaugeSize?: GaugeGroupProps['width'];
  gaugeStrokeWidth?: GaugeGroupProps['strokeWidth'];
  gaugeGap?: GaugeGroupProps['gap'];
}

const FULL_PROGRESS = {
  primary: 100,
  secondary: 100,
};

export const StatementAvatar = memo(function StatementAvatar({
  user,
  error,
  highlightedKpis,
  fullProgress,
  isForecast,
  hideKpisOnHover,
  hideError = false,
  avatarSize = Avatar.Size.LARGE,
  gaugeSize = 80,
  gaugeStrokeWidth = 4,
  gaugeGap = 4,
}: StatementAvatarProps) {
  const classes = useStyles();

  const [gaugeHoverId, setGaugeHoverId] = useState<HighlightedKpiIdentifier | null>(null);

  const onGaugeHover = useCallback(
    (identifier: HighlightedKpiIdentifier, isHover: boolean) => setGaugeHoverId(isHover ? identifier : null),
    [],
  );

  const renderInnerGaugeTooltip = useCallback(() => {
    if (error || !highlightedKpis || !gaugeHoverId) {
      return '';
    }

    const currentProgress = highlightedKpis[gaugeHoverId];

    if (!currentProgress) {
      return '';
    }

    const isPercent = currentProgress?.format === FormatsEnum.percent;

    return (
      <Box className={clsx(classes.kpiTooltipTable, isPercent && classes.kpiTooltipTablePercent)}>
        {!isPercent && (
          <Fragment>
            <span>
              <FormattedMessage defaultMessage="Min." />
            </span>
            <span>
              <FormattedMessage defaultMessage="Value" />
            </span>
            <span>
              <FormattedMessage defaultMessage="Max." />
            </span>
          </Fragment>
        )}

        {!isPercent && <span>{currentProgress.minimumValue}</span>}
        <span>
          <FormattedNumber
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            style={isPercent ? 'percent' : 'decimal'}
            value={currentProgress.currentValue}
          />
        </span>

        {!isPercent && (
          <span>
            <FormattedNumber value={currentProgress.maximumValue} />
          </span>
        )}

        {!isPercent && <span>{currentProgress.minimumVariableLabel}</span>}
        <span>{currentProgress.variableLabel}</span>
        {!isPercent && <span>{currentProgress.maximumVariableLabel}</span>}
      </Box>
    );
  }, [highlightedKpis, gaugeHoverId, classes, error]);

  const gaugeProgress = useMemo(
    () => ({
      primary: highlightedKpis?.primary?.progress,
      secondary: highlightedKpis?.secondary?.progress,
    }),
    [highlightedKpis],
  );

  return (
    <div className={classes.root}>
      <Tooltip
        content={renderInnerGaugeTooltip()}
        isOpen={!!gaugeHoverId}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          position="relative"
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              pointer-events: none;
            `}
          >
            <Avatar
              size={avatarSize}
              user={user}
            />
          </div>

          {fullProgress ? (
            <StatementGaugeGroup
              errorLevel={error?.level}
              gap={gaugeGap}
              isForecast={isForecast}
              progress={FULL_PROGRESS}
              strokeWidth={gaugeStrokeWidth}
              width={gaugeSize}
            />
          ) : (
            <StatementGaugeGroup
              errorLevel={error?.level}
              gap={gaugeGap}
              isForecast={isForecast}
              progress={gaugeProgress}
              strokeWidth={gaugeStrokeWidth}
              width={gaugeSize}
              onGaugeHover={hideKpisOnHover ? undefined : onGaugeHover}
            />
          )}

          {error && !hideError ? (
            <div className={classes.warningIconContainer}>
              <WarningIcon
                className={classes.warningIcon}
                style={{ color: themeColorForStatementErrorType[error.level] }}
              />
            </div>
          ) : null}
        </Box>
      </Tooltip>
    </div>
  );
});
