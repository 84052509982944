import {
  AssignmentType,
  ConfigurationError,
  MatchScope,
  type TeamScope,
  type AssignmentListScope,
} from '@amal-ia/lib-types';
/**
 * Given the scope of a MATCH_USER_ASSIGNED function, returns split args
 * @param scope arg filled in MATCH_USER_ASSIGNED function
 * @returns the scope split in 3 parts : Role, Scope and Depth on the hierarchy
 */
export const splitTeamScopeArgs = (scope: string): TeamScope => {
  const splitScope = scope.split('.');

  const role = splitScope[0] as AssignmentType;
  if (role === AssignmentType.MINE) {
    return {
      role,
      scope: MatchScope.ALL,
      depth: 0,
    };
  }

  const splitDepth = +splitScope[splitScope.length - 1];
  const depth = Number.isNaN(splitDepth) ? 0 : splitDepth;

  const roleScope =
    splitScope.length > 2
      ? splitScope[1]
      : // We use find here because user can type 'EMPLOYEE' / 'EMPLOYEE.ALL' / 'EMPLOYEE.1' so we are not sure of this position
        splitScope.find((s) => Object.values(MatchScope).includes(s as MatchScope));

  const roleScopeFinal = !roleScope ? MatchScope.ALL : roleScope;

  if (!role || !Object.values(AssignmentType).includes(role as AssignmentType)) {
    throw new ConfigurationError(`Role ${role} not available in MATCH_USERS_ASSIGNED`);
  }

  if (!roleScopeFinal || !Object.values(MatchScope).includes(roleScopeFinal as MatchScope)) {
    throw new ConfigurationError(`Scope ${roleScopeFinal} not available in MATCH_USERS_ASSIGNED`);
  }

  return {
    role,
    scope: roleScopeFinal as MatchScope,
    depth,
  };
};

export const emptyAssignmentList: AssignmentListScope = {
  [AssignmentType.MINE]: {
    [MatchScope.ALL]: {},
  },
  [AssignmentType.EMPLOYEE]: {
    [MatchScope.ALL]: {},
    [MatchScope.SUB]: {},
  },
  [AssignmentType.MANAGER]: {
    [MatchScope.ALL]: {},
    [MatchScope.SUB]: {},
  },
  [AssignmentType.MEMBER]: {
    [MatchScope.ALL]: {},
    [MatchScope.SUB]: {},
  },
};
