import { createSelector } from 'reselect';

import { type PeriodsMap, type RootState } from '@amal-ia/lib-types';

export const selectPeriodsMap = (state: RootState): PeriodsMap => state.periods.map;

export const selectPeriodsList = createSelector(
  [selectPeriodsMap],
  // Removing legacy forPeriods until we clean up the db properly.
  (periodsMap) =>
    Object.values(periodsMap)
      .filter((p) => !p.name.includes('forPeriod'))
      .sort((p1, p2) => (p1.startDate > p2.startDate ? 1 : -1)),
);

export const selectPeriodsIsLoading = (state: RootState): boolean => !!state.periods.isLoading;

export const selectOngoingPeriod = createSelector([selectPeriodsList], (periods) => {
  const now = Date.now() / 1000;
  return periods.find((p) => p.startDate <= now && p.endDate > now);
});
