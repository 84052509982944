import { convertTimestampToDate, type Period, type PlanAssignment } from '@amal-ia/lib-types';

import { type PlanAssignmentWithUser } from '../../types/planAssignments';

export const isPlanAssignmentEffective = (pa: PlanAssignmentWithUser, period: Period) => {
  // If we have no range, planAssignment is always effective.
  if (!pa.effectiveAsOf && !pa.effectiveUntil) return true;

  // If we have only a startDate for the planAssignment, planAssignment is effective if
  // the startDate is before the period end.
  if (pa.effectiveAsOf && !pa.effectiveUntil) return pa.effectiveAsOf <= period.endDate;

  // If we have both a startDate and an endDate for the planAssignment, planAssignment is effective if
  // the startDate is before the period end and the endDate is after the period start.
  // The check on the effectiveUntil is a dummy check to make sure that the effectiveUntil is after the effectiveAsOf.
  if (pa.effectiveAsOf && pa.effectiveUntil)
    return pa.effectiveAsOf <= period.endDate && pa.effectiveUntil >= period.startDate;

  // If we only have an endDate for the planAssignment, planAssignment is effective if
  // the endDate is after the period start.
  return pa.effectiveUntil >= period.startDate;
};

/**
 * Returns true if the planAssignment is effective for the given year.
 * @param pa
 * @param year
 */
export const isPlanAssignmentEffectiveForYear = (pa: PlanAssignment, year: number) => {
  // If we have no range, planAssignment is always valid.
  if (!pa.effectiveAsOf && !pa.effectiveUntil) return true;

  const effectiveAsOfYear = pa.effectiveAsOf ? convertTimestampToDate(pa.effectiveAsOf).getFullYear() : undefined;
  const effectiveUntilYear = pa.effectiveUntil ? convertTimestampToDate(pa.effectiveUntil).getFullYear() : undefined;

  if (effectiveAsOfYear && effectiveUntilYear) {
    return effectiveAsOfYear <= year && effectiveUntilYear >= year;
  }

  if (effectiveAsOfYear) {
    return effectiveAsOfYear <= year;
  }

  return effectiveUntilYear >= year;
};
