import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconX } from '@tabler/icons-react';
import { keyBy } from 'lodash';
import { type Dispatch, memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amal-ia/common/routes';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { IconButton, useSnackbars } from '@amal-ia/frontend/design-system/components';
import * as StatementDatasetsRepository from '@amal-ia/frontend/web-data-layers';
import { type Statement, type StatementDataset, type TracingTypes } from '@amal-ia/lib-types';
import { Text, TextType, TracingContext, type TracingContextInterface, useQueryString } from '@amal-ia/lib-ui';

import TracingRow from './TracingRow';
import TracingRule from './TracingRule';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    flexGrow: 1,
    padding: `0 ${theme.spacing(3)}`,
  },
  card: {
    minHeight: 140,
    width: '75%',
  },
}));

interface TracingPageProps {
  currentTracingData?: TracingTypes.CurrentTracingDataType;
  setCurrentTracingData: Dispatch<any>;
  statement: Statement;
  currencySymbol: CurrencySymbolsEnum;
  currencyRate: number;
}

/**
 * TracingPage
 * Main component of Tracing. Acts as a container that render selectively a child component according to the tracing data
 */
const TracingPage = memo(function TracingPage({
  currentTracingData,
  setCurrentTracingData,
  statement,
  currencySymbol,
  currencyRate,
}: TracingPageProps) {
  const classes = useStyles();
  const urlParams = useQueryString();
  const { ruleid: ruleId } = useParams<Record<string, string>>();
  const history = useHistory();
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  const closeTracing = useCallback(
    (event: any) => {
      // If the user clicks the close button, set the tracingData to null in the parent component
      event.preventDefault();
      if (setCurrentTracingData) {
        setCurrentTracingData(null);

        // if we have a backtostatementid param, it means that we come from another statement.
        // If that's the case, get back to it, and count on redux to have save the navigation state
        if (urlParams?.backtostatementid) {
          history.push(generatePath(routes.STATEMENT, { id: urlParams.backtostatementid }));
        } else {
          history.push(
            ruleId
              ? generatePath(routes.STATEMENT, { id: statement.id })
              : generatePath(routes.STATEMENT_RULE, { id: statement.id, ruleid: ruleId }),
          );
        }
      } else {
        history.push(generatePath(routes.STATEMENTS));
      }
    },
    [history, setCurrentTracingData, ruleId, statement, urlParams],
  );

  // Populate a record of datasets (by datasetId) we're encountering in the tracing.
  const [statementDatasets, setStatementDatasets] = useState<Record<string, StatementDataset>>({});

  useAsyncEffect(async () => {
    try {
      const statementDatasetsToLoad = await StatementDatasetsRepository.fetchDatasets(statement.id);
      setStatementDatasets(keyBy(statementDatasetsToLoad, 'datasetId'));
    } catch (e) {
      snackError(e.message);
    }
  }, [statement.id]);

  const contextContent = useShallowObjectMemo<TracingContextInterface>({ statementDatasets });

  if (!currentTracingData?.rule) return null;

  const tracingRuleDefinition = statement.results.definitions.plan.rules?.find(
    (rule) => rule.ruleMachineName === currentTracingData?.rule?.ruleMachineName,
  );

  return (
    <TracingContext.Provider value={contextContent}>
      <Grid
        container
        className={classes.root}
      >
        <Grid
          marginBottom={2}
          xs={12}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            my={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              textAlign="center"
            >
              {/* If tracing per deal, try to print name (as title) + id (as subtitle).
            If no name, print id as title. If tracing, print rule name as title */}
              <Text type={TextType.PAGE_TITLE}>
                {currentTracingData.datasetRow?.content
                  ? currentTracingData.datasetRow?.content?.name
                  : tracingRuleDefinition?.name}
              </Text>
            </Box>
            <IconButton
              icon={<IconX />}
              label={formatMessage({ defaultMessage: 'Close tracing' })}
              onClick={closeTracing}
            />
          </Box>
        </Grid>

        <Grid xs={12}>
          <Grid
            container
            alignItems="center"
            direction="column"
            justifyContent="center"
            spacing={4}
          >
            {currentTracingData.fields && currentTracingData.datasetRow && currentTracingData.dataset ? (
              <TracingRow
                currencyRate={currencyRate}
                currencySymbol={currencySymbol}
                dataset={currentTracingData.dataset}
                datasetRow={currentTracingData.datasetRow}
                fields={currentTracingData.fields}
                ruleResult={currentTracingData.rule}
                statement={statement}
              />
            ) : (
              <TracingRule
                computedRule={currentTracingData.rule}
                currencyRate={currencyRate}
                currencySymbol={currencySymbol}
                statement={statement}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </TracingContext.Provider>
  );
});

export default TracingPage;
