import { IconArrowsSort } from '@tabler/icons-react';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Dropdown } from '../../../../overlays/dropdown/Dropdown';
import { getMenuColumnName } from '../../columns';
import { useDataGridContext } from '../../DataGrid.context';
import { SortDirection, type ColumnSortingState } from '../../DataGrid.types';
import {
  ColumnSortingMenuItem,
  type ColumnSortingMenuItemProps,
} from '../column-sorting-menu-item/ColumnSortingMenuItem';
import * as styles from '../ColumnSorting.styles';
import { useSortableColumns } from '../hooks/useSortableColumns';

export type ColumnSortingSingleProps = {
  /** Sort state. */
  columnSorting: ColumnSortingState;
  /** Sort state change handler. */
  onChangeColumnSorting: (sort: ColumnSortingState) => void;
};

export const ColumnSortingSingle = memo(function ColumnSortingSingle({
  columnSorting,
  onChangeColumnSorting,
}: ColumnSortingSingleProps) {
  const { columns } = useDataGridContext();
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const sortableColumns = useSortableColumns(columns, searchText);

  const handleChangeActivated: ColumnSortingMenuItemProps['onChangeActivated'] = useCallback(
    (id, isActivated) => onChangeColumnSorting(isActivated ? [{ id, direction: SortDirection.ASC }] : []),
    [onChangeColumnSorting],
  );

  const handleChangeDirection: Required<ColumnSortingMenuItemProps>['onChangeDirection'] = useCallback(
    (id, direction) => onChangeColumnSorting([{ id, direction }]),
    [onChangeColumnSorting],
  );

  return (
    <Dropdown
      title={<FormattedMessage defaultMessage="Sort by" />}
      content={
        <ul css={styles.listContainer}>
          {sortableColumns.map((column) => {
            const sortDirection = columnSorting.find((columnSort) => columnSort.id === column.id)?.direction;
            return (
              <ColumnSortingMenuItem
                key={column.id}
                disableReordering
                direction={sortDirection}
                icon={column.icon}
                id={column.id}
                isActivated={!!sortDirection}
                label={getMenuColumnName(column)}
                onChangeActivated={handleChangeActivated}
                onChangeDirection={handleChangeDirection}
              />
            );
          })}
        </ul>
      }
      searchInput={
        <Dropdown.SearchInput
          placeholder={formatMessage({ defaultMessage: 'Search column' })}
          value={searchText}
          onChange={setSearchText}
        />
      }
    >
      <Dropdown.IconButton
        withBackground
        icon={<IconArrowsSort />}
        label={formatMessage({ defaultMessage: 'Sorting' })}
      />
    </Dropdown>
  );
});
