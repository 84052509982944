import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Search as SearchIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import moment from 'moment';
import { Fragment, memo, type ReactNode, useCallback, useContext } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { formatAdjustmentLabelInPayments } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { formatTotal, type Payment, PaymentType } from '@amal-ia/lib-types';
import { objectToQs } from '@amal-ia/lib-ui';

import PaymentsTableContext from './PaymentsTableContext';
import PaymentsTableForecastCell from './PaymentsTableForecastCell';
import usePaymentsTableStyles from './usePaymentsTableStyles';

const PaymentsTableCell = memo(function PaymentsTableCell(cellProps: any) {
  const { column, tableRow, value } = cellProps;
  const payment = tableRow.row as Payment;
  const classes = usePaymentsTableStyles();
  const history = useHistory();
  const rowIdentifier = `${payment.rule.name}-${payment.dealExternalId || 'none'}`;
  const ability = useAbilityContext();

  const { companyFrequency, firstPrintedColumn, currentPeriod } = useContext(PaymentsTableContext);

  const openTracing = useCallback(
    (e?: any) => {
      e?.stopPropagation();

      switch (true) {
        case !!payment?.ruleId && !!payment?.dealExternalId: {
          history.push(
            generatePath(routes.STATEMENT_RULE_ACTION_EXTERNAL_ID, {
              id: payment.statementId,
              ruleid: payment.ruleId,
              action: 'tracing',
              externalid: payment.dealExternalId,
            }),
          );
          return;
        }
        case !!payment?.ruleId: {
          history.push(
            generatePath(routes.STATEMENT_RULE_ACTION, {
              id: payment.statementId,
              ruleid: payment.ruleId,
              action: 'tracing',
            }),
          );
          return;
        }
        default: {
          history.push(generatePath(routes.STATEMENT, { id: payment.statementId }));
        }
      }
    },
    [history, payment],
  );

  let cellContent: ReactNode = '-';

  switch (column.name) {
    case 'statement':
      if (!companyFrequency) {
        cellContent = null;
      } else {
        // Build statement link
        cellContent = (
          <Link
            className={classes.link}
            rel="noreferrer"
            target="_blank"
            to={generatePath(routes.STATEMENT, { id: payment.statementId })}
          >
            {payment.period.name}
          </Link>
        );
      }
      break;

    case 'object': {
      const link =
        payment.dealName && payment.dealObjectName && ability.can(ActionsEnum.view, SubjectsEnum.Data)
          ? {
              pathname: generatePath(routes.DATA_SLUG, { slug: payment.dealObjectName }),
              search: `?${objectToQs({ search: payment.dealExternalId })}`,
            }
          : null;

      const linkComponent = link ? (
        <Link
          className={classes.link}
          rel="noreferrer"
          target="_blank"
          to={link}
        >
          {payment.dealName}
        </Link>
      ) : (
        payment.dealName
      );

      cellContent =
        payment.type === PaymentType.ADJUSTMENT ? (
          <Fragment>
            {formatAdjustmentLabelInPayments(payment.adjustment)}
            {link ? linkComponent : null}
          </Fragment>
        ) : (
          linkComponent
        );

      break;
    }

    case 'value':
      // Format payment value
      cellContent =
        payment.currency && payment.value !== undefined
          ? formatTotal(payment.value, FormatsEnum.currency, payment.currency)
          : null;
      break;

    case 'paymentDate':
      cellContent = value ? moment.utc(value).format('YYYY-MM-DD') : '-';
      break;

    case 'paymentPeriod':
      cellContent = payment.paymentPeriod?.name;
      break;

    case 'forecastPaymentDate': {
      cellContent = (
        <PaymentsTableForecastCell
          currentPeriod={currentPeriod}
          forecastDate={value}
        />
      );
      break;
    }

    default:
      cellContent = value;
      break;
  }

  return (
    <Table.Cell
      name={`${rowIdentifier}.values[${column.name}]`}
      {...cellProps}
    >
      {/* If we're on the first column, print the tracing button */}
      {firstPrintedColumn === column.name ? (
        <Box className={classes.tracingDealContainer}>
          <IconButton
            className={classes.tracingDeal}
            size="large"
            onClick={(e) => openTracing(e)}
            onFocus={(e) => e.stopPropagation()}
          >
            <SearchIcon />
          </IconButton>
        </Box>
      ) : null}
      {cellContent}
    </Table.Cell>
  );
});

export default PaymentsTableCell;
