import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { Form, Formik, type FormikHelpers } from 'formik';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type CurrencySymbolsEnum, DEPRECATED_CURRENCY_SYMBOL_LABELS } from '@amal-ia/ext/iso-4217';
import {
  Button,
  FormLayout,
  FormikInput,
  FormikSelect,
  FormikSwitch,
} from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { DEFAULT_REPORTS_PRECISION, StatementFrequency } from '@amal-ia/lib-types';
import { convertEnumToOptions } from '@amal-ia/lib-ui';
import { type Company } from '@amal-ia/tenants/shared/types';

const companySchema = Yup.object().shape({
  currency: Yup.string().required(),
  customization: Yup.object().shape({
    holdAndReleaseForecastPaymentDateLabel: Yup.string(),
    holdAndReleasePaymentDateLabel: Yup.string(),
    legacyKpiCardView: Yup.boolean(),
    reportsPrecision: Yup.number().integer().min(0).max(10).required(),
    totalCommissionLabel: Yup.string(),
  }),
  name: Yup.string().required().min(2).max(100),
  statementFrequency: Yup.string().required(),
  symbols: Yup.array(),
});

const statementOptions = convertEnumToOptions(StatementFrequency);
const currencyOptions = convertEnumToOptions(DEPRECATED_CURRENCY_SYMBOL_LABELS);

interface CompanyFormProps {
  onSubmit: (values: Partial<Company>, actions: FormikHelpers<Partial<Company>>) => Promise<void>;
  company: Company;
}

export const CompanyForm = memo(function CompanyForm({ company, onSubmit }: CompanyFormProps) {
  const ability = useAbilityContext();
  const { formatMessage } = useIntl();

  const initialValues = useMemo(
    () => ({
      name: company?.name || '',
      statementFrequency: (company?.statementFrequency || '') as PeriodFrequencyEnum,
      currency: (company?.currency || '') as CurrencySymbolsEnum,
      symbols: company?.symbols || [],
      customization: {
        holdAndReleasePaymentDateLabel: company?.customization?.holdAndReleasePaymentDateLabel || '',
        holdAndReleaseForecastPaymentDateLabel: company?.customization?.holdAndReleaseForecastPaymentDateLabel || '',
        totalCommissionLabel: company?.customization?.totalCommissionLabel || '',
        reportsPrecision: company?.customization?.reportsPrecision || DEFAULT_REPORTS_PRECISION,
        legacyKpiCardView: company?.customization?.legacyKpiCardView || false,
      },
    }),
    [company],
  );

  const canModifyCompanySettings = ability.can(ActionsEnum.modify_settings, SubjectsEnum.Company);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={companySchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty }) => (
        <Form
          css={css`
            display: contents;
          `}
        >
          <FormLayout.Group title={<FormattedMessage defaultMessage="Company main settings" />}>
            <FormikInput
              required
              disabled={!canModifyCompanySettings}
              id="name"
              label={<FormattedMessage defaultMessage="Name" />}
              name="name"
            />

            <FormikSelect
              required
              disabled={!canModifyCompanySettings}
              id="frequency"
              isClearable={false}
              label={<FormattedMessage defaultMessage="Frequency of commission calculation" />}
              name="statementFrequency"
              options={statementOptions}
            />

            <FormikSelect
              disabled
              required
              id="currency"
              isClearable={false}
              label={<FormattedMessage defaultMessage="Company main currency" />}
              name="currency"
              options={currencyOptions}
              tooltip={
                <FormattedMessage defaultMessage="You cannot change your main currency. Contact your CSM for more information." />
              }
            />

            <FormikSelect
              isMultiple
              disabled={!canModifyCompanySettings}
              label={<FormattedMessage defaultMessage="Company currencies" />}
              name="symbols"
              options={currencyOptions}
              placeholder={formatMessage({ defaultMessage: 'No company currencies' })}
            />
          </FormLayout.Group>

          <FormLayout.Group title={<FormattedMessage defaultMessage="Customization options" />}>
            <FormikInput
              disabled={!canModifyCompanySettings}
              label={<FormattedMessage defaultMessage="Payable name" />}
              name="customization.holdAndReleasePaymentDateLabel"
            />

            <FormikInput
              disabled={!canModifyCompanySettings}
              label={<FormattedMessage defaultMessage="Forecast payable name" />}
              name="customization.holdAndReleaseForecastPaymentDateLabel"
            />

            <FormikInput
              disabled={!canModifyCompanySettings}
              label={<FormattedMessage defaultMessage="Total commission name" />}
              name="customization.totalCommissionLabel"
            />

            <FormikInput
              required
              disabled={!canModifyCompanySettings}
              label={<FormattedMessage defaultMessage="Reports precision" />}
              max={10}
              min={0}
              name="customization.reportsPrecision"
              type="number"
            />

            <FormikSwitch
              disabled={!canModifyCompanySettings}
              label={<FormattedMessage defaultMessage="Use legacy statement kpi card view" />}
              name="customization.legacyKpiCardView"
            />
          </FormLayout.Group>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
            textAlign="center"
          >
            <Button
              disabled={!isValid || !dirty || !canModifyCompanySettings}
              icon={<IconDeviceFloppy />}
              type="submit"
            >
              <FormattedMessage defaultMessage="Save settings" />
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
});
