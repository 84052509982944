import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconEyeOff } from '@tabler/icons-react';
import clsx from 'clsx';
import { Fragment, memo, useMemo } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { OVERWRITE_CONTEXT, OverwriteModalContainer } from '@amal-ia/data-capture/overwrites/components';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { useCompanyCustomization } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, subject, SubjectsEnum } from '@amal-ia/lib-rbac';
import { isBeingReviewed } from '@amal-ia/lib-types';
import { SelectFilter, useStatementDetailContext } from '@amal-ia/lib-ui';

import ForecastedKPI from './forecasted-kpi/ForecastedKPI';
import OverwritableKPI from './overwritable-kpi/OverwritableKPI';
import { useKPIDisplay } from './useKPIDisplay';
import { useKPIOverwrite } from './useKPIOverwrite';
import { useKPIThread } from './useKPIThread';
import { useStatementRuleComputedVariables } from './useStatementRuleComputedVariables';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  kpisListContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(7.25),
  },
  kpisCardContainer: {
    display: 'flex',
    flexFlow: 'wrap',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
}));

type KPIListProps = {
  ruleDefinition: PlanRule;
};

export const KPIList = memo(function KPIList({ ruleDefinition }: KPIListProps) {
  const classes = useStyles();

  const statementContext = useStatementDetailContext();
  const { data: currentUser } = useCurrentUser();
  const { legacyKpiCardView } = useCompanyCustomization();

  const statementRuleComputedVariables = useStatementRuleComputedVariables(statementContext, ruleDefinition);

  const {
    clearOverwrite,
    closeOverwriteModal,
    isOverwriteModalOpen,
    openOverwriteModal,
    overwriteObjectDetails,
    saveOverwrite,
    setOverwriteObjectDetails,
  } = useKPIOverwrite(statementContext);

  const { openStatementThread, threadsPerKpi } = useKPIThread(
    statementContext,
    ruleDefinition,
    statementRuleComputedVariables,
  );

  const { availableKPIOptions, displayedKPIIds, displayedKPIs, setDisplayedKPIIds } = useKPIDisplay(
    statementContext,
    ruleDefinition,
    statementRuleComputedVariables,
  );

  const ability = useAbilityContext();

  const canSimulateForecastedStatement =
    ability.can(ActionsEnum.simulate, subject(SubjectsEnum.Forecasted_Statement, statementContext)) &&
    !!statementContext?.plan.isSimulationEnabled;
  const canViewStatementComments = ability.can(ActionsEnum.view_comments, SubjectsEnum.Statement);

  const isWorkflowBegun = useMemo(() => isBeingReviewed(statementContext), [statementContext]);

  if (!availableKPIOptions?.length) {
    return null;
  }

  return (
    <Fragment>
      <Grid
        container
        alignItems="flex-start"
        p={2}
        spacing={legacyKpiCardView ? 1 : 0.5}
      >
        <Grid xs={11}>
          <Box className={clsx(legacyKpiCardView ? classes.kpisCardContainer : classes.kpisListContainer)}>
            {displayedKPIs.map((variable) => {
              if (statementContext.isForecastedView) {
                return (
                  <ForecastedKPI
                    key={variable.id}
                    canSimulate={canSimulateForecastedStatement}
                    computedVariable={variable}
                    openOverwriteModal={openOverwriteModal}
                    resetOverwrite={clearOverwrite}
                    ruleDefinition={ruleDefinition}
                    setOverwriteObjectDetails={setOverwriteObjectDetails}
                    variableDefinition={statementContext.results.definitions.variables[variable.variableMachineName]}
                  />
                );
              }
              return (
                <OverwritableKPI
                  key={variable.id}
                  canComment={canViewStatementComments}
                  computedVariable={variable}
                  isReadOnly={isWorkflowBegun || ability.cannot(ActionsEnum.overwrite, SubjectsEnum.Statement)}
                  openOverwriteModal={openOverwriteModal}
                  openStatementThread={openStatementThread}
                  resetOverwrite={clearOverwrite}
                  ruleDefinition={ruleDefinition}
                  setOverwriteObjectDetails={setOverwriteObjectDetails}
                  statementThread={threadsPerKpi[variable.id]}
                  variableDefinition={statementContext.results.definitions.variables[variable.variableMachineName]}
                />
              );
            })}
          </Box>
        </Grid>
        <Grid
          className={classes.filterContainer}
          xs={1}
        >
          <SelectFilter
            color="primary"
            items={availableKPIOptions}
            value={displayedKPIIds}
            content={
              <IconButton
                icon={<IconEyeOff />}
                label=""
              />
            }
            onChange={setDisplayedKPIIds}
          />
        </Grid>
      </Grid>
      {!!overwriteObjectDetails && (
        <OverwriteModalContainer
          currentObjectDetails={overwriteObjectDetails}
          currentUser={currentUser}
          handleClose={closeOverwriteModal}
          handleSubmit={saveOverwrite}
          isOpen={isOverwriteModalOpen}
          isSimulation={statementContext.isForecastedView}
          overwriteContext={OVERWRITE_CONTEXT.KPI}
        />
      )}
    </Fragment>
  );
});
