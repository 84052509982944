import { createSelector } from 'reselect';

import { type TeamContract, type TeamMap, type RootState, type TeamNode } from '@amal-ia/lib-types';

import { type TeamAssignmentsMap, type TeamPlanAssignmentsMap } from '../../types/teams';

export const selectIsTeamsLoading = (state: RootState): boolean => !!state.teams.isLoading;

export const selectTeamMap = (state: RootState): TeamMap => state.teams.map;
export const selectTeamMapIsFullyLoaded = (state: RootState): boolean => state.teams.isMapFullyLoaded;

export const selectTeamHierarchy = (state: RootState): TeamNode[] => state.teams.hierarchy;
export const selectTeams = createSelector([selectTeamMap], (teamsMap: TeamMap) => Object.values(teamsMap));

export const selectTeamsSortedByName = createSelector([selectTeams], (teams: TeamContract[]) =>
  [...teams].sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectTeamAssignmentsMap = (state: RootState): TeamAssignmentsMap => state.teams.teamAssignments;

export const selectTeamPlanAssignmentsMap = (state: RootState): TeamPlanAssignmentsMap =>
  state.teams.currentTeamPlanAssignments;
