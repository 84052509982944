import { type MessageContent } from './message';

export interface CommentThreadMessage {
  id: string;

  // Message content.
  content: MessageContent[];

  // User that wrote the message.
  author: { id: string };

  // created date of the message.
  createdAt: Date;
}

export interface CommentThread {
  id: string | null;

  messages?: CommentThreadMessage[];

  author?: { id: string };

  isReviewed?: boolean;
}

export enum StatementThreadScopesType {
  KPI = 'kpi',
  OBJECT = 'object',
}

export type StatementThreadScope = {
  type: StatementThreadScopesType;
  /* If type === KPI,
      id = variable.id, fulfill periodStartDate and periodEndDate
    If type === object
      id = externalId (Id of the row), field is the column, definitionId the customObjectDefinitionId, fulfill periodStartDate and periodEndDate
  */
  id: string;
  /** Rule Id of the Dataset */
  ruleId: string;
  periodStartDate?: Date;
  periodEndDate?: Date;
} & (DatasetThreadScope | VariableThreadScope);

export type VariableThreadScope = {
  type: StatementThreadScopesType.KPI;
};
export type DatasetThreadScope = {
  type: StatementThreadScopesType.OBJECT;
  /** Name of the Row*/
  name: string;
  /** Column of the dataset */
  field: string;
  /** Custom Object Definition Id */
  definitionId: string;
  /** Dataset Machine Name */
  datasetMachineName?: string;
};

export interface StatementThread {
  id: string;

  thread: CommentThread;

  // Statement id.
  statementId: string;

  // Scope.
  scope?: StatementThreadScope;
}
