import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { Progress, type ProgressProps } from './Progress';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  progressLabel: {
    minWidth: 35,
    marginLeft: theme.spacing(2),
  },
}));

export const ProgressWithLabel = memo(function ProgressWithLabel({ total, current }: ProgressProps) {
  const classes = useStyles();

  return (
    <Fragment>
      <Progress
        current={current}
        total={total}
      />
      <Box className={classes.progressLabel}>
        <FormattedMessage
          defaultMessage="{current, number}/{total, number}"
          values={{ current, total }}
        />
      </Box>
    </Fragment>
  );
});
