import { css, type Theme } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconSend } from '@tabler/icons-react';
import { type Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, type JSONContent, useEditor } from '@tiptap/react';
import { memo, useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, useSnackbars } from '@amal-ia/frontend/design-system/components';
import { type MessageContent, type Statement } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { useStatementUsers } from '../mentions/useStatementUsers';
import { useSuggestions } from '../mentions/useSuggestions';

const useStyles = makeStyles({
  container: {
    '& > div': {
      outline: 'none',
    },
  },
  mention: {
    // new theme secondary[500]
    color: '#6A35FF',
    // new theme secondary[50]
    background: '#F0EBFF',
  },
  input: {
    '&.is-editor-empty:first-child::before': {
      content: 'attr(data-placeholder)',
      color: '#adb5bd',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
      fontSize: '14px',
    },
    minHeight: '80px',
    letterSpacing: '0.05em',
    // #EDEDED is the new gray[100] from the new theme
    border: `1px solid #EDEDED`,
    borderRadius: '4px',
    alignItems: 'center',
    padding: '12px 16px',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
});

type CommentFormPresentationProps = {
  onSubmit: (values: MessageContent[]) => Promise<void> | void;
  getUsersAllowedToViewStatement: (statementId: string) => Promise<Partial<UserContract>[]>;
  currentStatement?: Statement;
};

/**
 * New comment message form
 */
export const CommentFormPresentation = memo(function CommentFormPresentation({
  onSubmit,
  getUsersAllowedToViewStatement,
  currentStatement,
}: CommentFormPresentationProps) {
  const { formatMessage } = useIntl();
  const { snackError } = useSnackbars();
  const classes = useStyles();

  // Ref are used here because setState doesn't work well with tiptap Editor
  const editorContentRef = useRef<JSONContent>();
  const editorRef = useRef<Editor>();

  const statementUsers = useStatementUsers(getUsersAllowedToViewStatement, currentStatement);

  const { suggestion, isOpen: isMentionPopupOpened } = useSuggestions(statementUsers);

  const handleSendComment = useCallback(async () => {
    if (!editorContentRef.current) {
      return null;
    }
    const comment = editorContentRef.current
      .content![0].content?.map((node: JSONContent) => {
        if (node.type === 'text') {
          return {
            type: 'text',
            content: node.text,
          };
        }
        if (node.type === 'mention') {
          return {
            type: 'mention',
            content: node.attrs?.label,
            userId: node.attrs?.id,
          };
        }
        return null;
      })
      .filter(Boolean);
    if (!comment) {
      return null;
    }
    editorRef.current?.commands.clearContent(true);
    await onSubmit(comment as MessageContent[]);
    return null;
  }, [onSubmit]);

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph.configure({
          HTMLAttributes: {
            class: classes.input,
            id: 'commentMessage',
          },
        }),
        Text,
        HardBreak,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          placeholder: formatMessage({ defaultMessage: 'Add a comment…' }),
        }),
        Mention.configure({
          HTMLAttributes: {
            class: classes.mention,
          },
          suggestion,
        }),
      ],
      injectCSS: false,
      editorProps: {
        handleDOMEvents: {
          keydown: (_, event) => {
            if (event.key === 'Enter' && !event.shiftKey && !isMentionPopupOpened.current) {
              event.preventDefault();
              handleSendComment().catch((e) => snackError(e));
            }
            return false;
          },
        },
        attributes: {
          id: 'commentMessageEditor',
        },
      },
      onCreate: ({ editor: currentEditor }) => {
        editorRef.current = currentEditor;
      },
      onUpdate: ({ editor: currentEditor }) => {
        editorContentRef.current = currentEditor.getJSON();
      },
    },
    [suggestion, handleSendComment],
  );

  return (
    <div
      css={(theme: Theme) => css`
        border-top: 1px solid ${theme.ds.colors.gray[100]};
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        text-align: left;
        align-items: flex-end;
        gap: 12px;
      `}
    >
      <div
        css={css`
          flex: 1;
          width: 100%;
        `}
      >
        <EditorContent
          className={classes.container}
          editor={editor}
        />
      </div>
      <div>
        <Button
          icon={<IconSend />}
          onClick={handleSendComment}
        >
          <FormattedMessage defaultMessage="Send comment" />
        </Button>
      </div>
    </div>
  );
});
