import { isEqual, pick, uniqWith } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('UNIQUE', AmaliaFunctionCategory.ARRAY);

func.nbParamsRequired = 2;

func.description = [
  'Return the filtered dataset with unique values on a parameter.',
  ' Used in advanced settings of filters or links and can be combined with SORT function.',
];

func.params = [
  {
    name: 'array',
    description: '$rows for the dataset of the active filter or SORT($rows, ...) if you want the sorted dataset.',
  },
  {
    name: 'parameters',
    description: 'Fields or properties on which we want to have only unique records. Format as follow: "machineName".',
  },
];

func.examples = [
  {
    desc: 'Returns the dataset with unique records on the property “opportunity name”',
    formula: 'UNIQUE($rows, “accountId”)' as AmaliaFormula,
  },
  {
    desc: 'Returns the dataset with unique records on both properties “opportunity name” and “accountId”',
    formula: 'UNIQUE($rows, [“opportunityName”, “accountId”])' as AmaliaFormula,
  },
  {
    desc: 'Returns the dataset with unique records having the highest value for amount on the property “opportunity name”',
    formula: 'UNIQUE(SORT($rows, “amount”, “DESC”), “opportunityName”)' as AmaliaFormula,
  },
];

/**
 *
 * @param rows
 * @param fields
 */
func.exec = (rows: any[], fields: string[] | string): any =>
  uniqWith(rows, (actVal, otherVal) => {
    const arrPick = pick(actVal, fields);
    const otherPick = pick(otherVal, fields);

    return isEqual(arrPick, otherPick);
  });

export default func;
