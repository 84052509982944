import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { Avatar } from '../../../Avatar';
import { TitleSelectWithAction } from '../../TitleSelectWithAction';

import { messages } from './UserSelect.messages';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  avatar: {
    width: '28px',
    height: '28px',
  },
  userInformations: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  selectedUser: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    color: theme.palette.secondary.main,
  },
  unassignedUser: {
    color: theme.palette.grey[300],
  },
}));

export interface UserSelectDesignerProps {
  users: UserComputed[] | null;
  value: UserComputed | null;
  onChange: (user: UserComputed) => void;
  allUsers: UserComputed[] | null;
  setUserToAssign: (user: UserComputed) => void;
  isPlanArchived: boolean;
}

export const UserSelectDesigner = memo(function UserSelectDesigner({
  users,
  onChange,
  allUsers,
  setUserToAssign,
  isPlanArchived,
}: UserSelectDesignerProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const onChangeProxy = useCallback(
    (userId: string) => {
      if (users.length) {
        onChange(users.find((u) => u.id === userId)!);
      } else {
        setUserToAssign(allUsers.find((u) => u.id === userId)!);
      }
    },
    [allUsers, onChange, setUserToAssign, users],
  );

  const userOptions = useMemo(
    () =>
      users.length
        ? users.map((u) => ({
            value: u.id,
            label: `${u.firstName} ${u.lastName}`,
            text: (
              <div className={classes.userInformations}>
                <Avatar
                  className={classes.avatar}
                  user={u}
                />
                {u.firstName} {u.lastName}
              </div>
            ),
          }))
        : // If plan is archive, hide all users not assigned
          isPlanArchived
          ? []
          : allUsers.map((u) => ({
              value: u.id,
              label: `${u.firstName} ${u.lastName}`,
              text: (
                <div className={clsx(classes.userInformations, classes.unassignedUser)}>
                  <Avatar
                    className={classes.avatar}
                    user={u}
                  />
                  {u.firstName} {u.lastName}
                </div>
              ),
            })),
    [isPlanArchived, allUsers, classes, users],
  );

  return (
    <TitleSelectWithAction
      options={userOptions}
      title={formatMessage(messages.SWITCH_USER)}
      onChange={onChangeProxy}
    />
  );
});
