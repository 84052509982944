import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import * as Yup from 'yup';

import { CustomObjectDefinitionType } from '@amal-ia/data-capture/models/types';
import {
  FormLayout,
  FormikInput,
  FormikSelect,
  FormikSwitch,
  FormikTextArea,
  Modal,
} from '@amal-ia/frontend/design-system/components';
import {
  type CreateAdjustmentDto,
  fetchCustomObjectDefinitions,
  fetchPeriods,
  selectCurrentPeriod,
  selectCustomObjectDefinitionsList,
  selectPeriodsList,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { type Adjustment, getCurrencySymbolCharacters, type Statement } from '@amal-ia/lib-types';
import { COMMON_MESSAGES } from '@amal-ia/lib-ui';
import { AdjustmentStatementDetails } from '@amal-ia/lib-ui-business';

import { adjustmentModalMessages } from './AdjustmentModal.messages';

const schema = Yup.object().shape({
  amount: Yup.number().required(),
  description: Yup.string(),
  name: Yup.string().required().min(2).max(250),
  rowDefinitionId: Yup.string().when('appliesToRow', {
    is: (v: unknown) => !!v,
    then: (yupSchema) => yupSchema.required(),
  }),
  rowExternalId: Yup.string().when('appliesToRow', {
    is: (v: unknown) => !!v,
    then: (yupSchema) => yupSchema.required(),
  }),
});

interface AdjustmentModalProps {
  adjustment: CreateAdjustmentDto | null;
  onCancel: () => void;
  onSubmit: (values: CreateAdjustmentDto) => void;
  statement: Statement;
}

const AdjustmentModal = memo(function AdjustmentModal({
  adjustment,
  onCancel,
  onSubmit,
  statement,
}: AdjustmentModalProps) {
  const { formatMessage } = useIntl();
  const dispatch = useThunkDispatch();

  useAsyncEffect(async () => {
    await Promise.all([dispatch(fetchCustomObjectDefinitions()), dispatch(fetchPeriods())]);
  }, []);

  const customObjectDefinitions = useSelector(selectCustomObjectDefinitionsList);

  const customObjectDefinitionsOptions = useMemo(
    () =>
      (customObjectDefinitions || [])
        .filter((c) => c.type !== CustomObjectDefinitionType.VIRTUAL)
        .map((c) => ({ label: c.name, value: c.id })),
    [customObjectDefinitions],
  );

  const periods = useSelector(selectPeriodsList);
  const currentPeriod = useSelector(selectCurrentPeriod);

  const periodsOptions = useMemo(() => (periods || []).map((c) => ({ label: c.name, value: c.id })), [periods]);

  const statementCurrencySymbolCharacters = useMemo(() => getCurrencySymbolCharacters(statement.currency), [statement]);

  const initialValuesProxy: CreateAdjustmentDto & { appliesToRow: boolean } = useMemo(() => {
    if (!adjustment) {
      return null;
    }
    return {
      ...adjustment,
      appliesToRow: !!adjustment?.rowExternalId,
      rowExternalId: adjustment?.rowExternalId || '',
      rowDefinitionId: adjustment?.rowDefinitionId || '',
      paymentPeriodId:
        adjustment?.paymentPeriodId !== undefined
          ? // Put the period id (and empty string if it's null).
            adjustment.paymentPeriodId || ''
          : // Else select the current statement period.
            periodsOptions.find((p) => p.value === currentPeriod.id)?.value || '',
    };
  }, [adjustment, periodsOptions, currentPeriod]);

  const onSubmitProxy = useCallback(
    (values: CreateAdjustmentDto & { appliesToRow: boolean }) => {
      const keysToOmit = ['appliesToRow'];

      if (!values.appliesToRow) {
        keysToOmit.push('rowDefinitionId');
        keysToOmit.push('rowExternalId');
      }

      onSubmit({
        ...omit(values, keysToOmit),
        amount: parseFloat(`${values.amount}`), // Amount is a string in the form values.
        // For an edition, add the id we had in props.
        id: adjustment.id,
        // Replace '' with null.
        paymentPeriodId: values.paymentPeriodId || null,
      } as Adjustment);
    },
    [onSubmit, adjustment],
  );

  if (statement) {
    return (
      <Modal
        isOpen={!!adjustment}
        onClose={onCancel}
      >
        {adjustment ? (
          <Formik
            validateOnMount
            initialValues={initialValuesProxy}
            validationSchema={schema}
            onSubmit={onSubmitProxy}
          >
            {({ isValid, dirty, values }) => (
              <Form
                css={css`
                  display: contents;
                `}
              >
                <Modal.Content>
                  <Modal.Header>
                    <Modal.Title>
                      <FormattedMessage defaultMessage="Make an adjustment" />
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <FormLayout>
                      <FormLayout.Group>
                        <AdjustmentStatementDetails statement={statement} />

                        <FormikInput
                          required
                          id="name"
                          label={formatMessage(COMMON_MESSAGES.NAME)}
                          name="name"
                        />

                        <FormikInput
                          required
                          id="amount"
                          name="amount"
                          type="number"
                          label={formatMessage(adjustmentModalMessages.AMOUNT, {
                            currency: statementCurrencySymbolCharacters,
                          })}
                        />

                        <FormikSelect
                          isClearable
                          id="paymentPeriodId"
                          label={formatMessage(adjustmentModalMessages.PAYMENT_PERIOD)}
                          name="paymentPeriodId"
                          options={periodsOptions}
                          placeholder={formatMessage(adjustmentModalMessages.NONE)}
                          tooltip={formatMessage(adjustmentModalMessages.PAYMENT_PERIOD_HELP)}
                        />

                        <FormikSwitch
                          id="appliesToRow"
                          label={formatMessage(adjustmentModalMessages.APPLIES_TO_RECORD)}
                          name="appliesToRow"
                          tooltip={formatMessage(adjustmentModalMessages.APPLIES_TO_RECORD_HELP)}
                        />

                        {!!values.appliesToRow && (
                          <Fragment>
                            <FormikSelect
                              required
                              id="rowDefinitionId"
                              isClearable={false}
                              label={formatMessage(adjustmentModalMessages.RECORD_TYPE)}
                              name="rowDefinitionId"
                              options={customObjectDefinitionsOptions}
                            />

                            <FormikInput
                              required
                              id="rowExternalId"
                              label={formatMessage(adjustmentModalMessages.RECORD_EXTERNAL_ID)}
                              name="rowExternalId"
                            />
                          </Fragment>
                        )}

                        <FormikTextArea
                          id="description"
                          label={formatMessage(adjustmentModalMessages.DESCRIPTION)}
                          name="description"
                          css={css`
                            min-height: 150px;
                          `}
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Modal.Body>
                </Modal.Content>

                <Modal.Actions>
                  <Modal.CancelAction />

                  <Modal.MainAction
                    disabled={!isValid || !dirty}
                    type="submit"
                  >
                    {adjustment.id ? (
                      <FormattedMessage {...COMMON_MESSAGES.UPDATE} />
                    ) : (
                      <FormattedMessage {...COMMON_MESSAGES.APPLY} />
                    )}
                  </Modal.MainAction>
                </Modal.Actions>
              </Form>
            )}
          </Formik>
        ) : null}
      </Modal>
    );
  }

  return null;
});

export default AdjustmentModal;
