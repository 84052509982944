import invariant from 'invariant';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type TeamRole } from '@amal-ia/lib-types';

import { TeamRoleLabels } from './roles.messages';

export type TeamRoleLabelProps = {
  teamRole: TeamRole;
};

export const TeamRoleLabel = memo(function TeamRoleLabel({ teamRole }: TeamRoleLabelProps) {
  invariant(teamRole in TeamRoleLabels, `Invalid team role: ${teamRole}`);

  return <FormattedMessage {...TeamRoleLabels[teamRole]} />;
});
