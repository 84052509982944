import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  NO_FORECASTED_STATEMENT_CALCULATED: {
    defaultMessage: 'There is no forecasted statement calculated for this period.',
  },
  COLLAPSE_RECAP: { defaultMessage: 'Collapse recap' },
  EXTEND_RECAP: { defaultMessage: 'Extend recap' },
  TRACING_BETA: { defaultMessage: '<span>Tracing</span> is in beta version' },
} as const);
