import { Box } from '@mui/material';
import { Form, Formik, type FormikHelpers } from 'formik';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { AlertBanner, Button } from '@amal-ia/frontend/design-system/components';
import { SwitchField, Text, TextType, COMMON_MESSAGES } from '@amal-ia/lib-ui';
import { type Company } from '@amal-ia/tenants/shared/types';
import { type UserSettings } from '@amal-ia/tenants/users/shared/types';

import NotificationsSettings from './NotificationsSettings';

export interface SettingsFormProps {
  onSubmit: (values: UserSettings, actions: FormikHelpers<UserSettings>) => Promise<void> | void;
  settings: UserSettings;
  company?: Company;
}

const SettingsForm = memo(function SettingsForm({ onSubmit, settings, company }: SettingsFormProps) {
  const { formatMessage } = useIntl();

  const settingsSchema = useMemo(
    () =>
      Yup.object().shape({
        isReceiveEmailsOnNewComment: Yup.boolean(),
        isReceiveEmailsOnNewExport: Yup.boolean(),
      }),
    [],
  );

  const isSlackSettingsAvailable = !!company?.integrationsStatus?.slack?.status;

  const help = isSlackSettingsAvailable ? undefined : (
    <FormattedMessage defaultMessage="To activate Slack notifications, contact Amalia team." />
  );

  return (
    <Formik
      enableReinitialize
      initialValues={settings}
      validationSchema={settingsSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Text type={TextType.SUBTITLE}>
          <FormattedMessage defaultMessage="Notifications" />
        </Text>
        <AlertBanner variant={AlertBanner.Variant.INFO}>
          <FormattedMessage defaultMessage="From here, you can select which channel will be used based on notification type." />
        </AlertBanner>
        <NotificationsSettings
          sections={[
            {
              title: <FormattedMessage defaultMessage="Comments" />,
              key: 'Comments',
              lines: [
                {
                  key: 'isReceiveEmailsOnNewComment',
                  label: (
                    <Text type={TextType.USER_SETTINGS_LABEL}>
                      <FormattedMessage defaultMessage="When another person comments" />
                    </Text>
                  ),
                  childrenEmail: (
                    <SwitchField
                      aria-label={formatMessage({ defaultMessage: 'User should receive email on new comment' })}
                      name="isReceiveEmailsOnNewComment"
                    />
                  ),
                  childrenSlack: (
                    <SwitchField
                      aria-label={formatMessage({ defaultMessage: 'User should receive Slack message on new comment' })}
                      disabled={!isSlackSettingsAvailable}
                      help={help}
                      name="isReceiveSlacksOnNewComment"
                    />
                  ),
                },
              ],
            },
            {
              title: <FormattedMessage defaultMessage="Exports" />,
              key: 'Exports',
              lines: [
                {
                  key: 'isReceiveEmailsOnNewExport',
                  label: (
                    <Text type={TextType.USER_SETTINGS_LABEL}>
                      <FormattedMessage defaultMessage="When new export is available" />
                    </Text>
                  ),
                  childrenEmail: (
                    <SwitchField
                      aria-label={formatMessage({ defaultMessage: 'User should receive email on new export' })}
                      name="isReceiveEmailsOnNewExport"
                    />
                  ),
                  childrenSlack: (
                    <SwitchField
                      aria-label={formatMessage({ defaultMessage: 'User should receive Slack message on new export' })}
                      disabled={!isSlackSettingsAvailable}
                      help={help}
                      name="isReceiveSlacksOnNewExport"
                    />
                  ),
                },
              ],
            },
            {
              title: <FormattedMessage defaultMessage="Reviews" />,
              key: 'Reviews',
              lines: [
                {
                  key: 'isReceiveEmailsOnReview',
                  label: (
                    <Text type={TextType.USER_SETTINGS_LABEL}>
                      <FormattedMessage defaultMessage="When my statement is reviewed" />
                    </Text>
                  ),
                  childrenEmail: (
                    <SwitchField
                      aria-label={formatMessage({ defaultMessage: 'User should receive email on review' })}
                      name="isReceiveEmailsOnReview"
                    />
                  ),
                  childrenSlack: (
                    <SwitchField
                      aria-label={formatMessage({ defaultMessage: 'User should receive Slack message on review' })}
                      disabled={!isSlackSettingsAvailable}
                      help={help}
                      name="isReceiveSlacksOnReview"
                    />
                  ),
                },
              ],
            },
            company.featureFlags.PLAN_AGREEMENTS && {
              title: <FormattedMessage defaultMessage="Plan agreements" />,
              key: 'PlanAgreements',
              lines: [
                {
                  key: 'isReceiveEmailsOnPlanAgreementToConfirm',
                  label: (
                    <Text type={TextType.USER_SETTINGS_LABEL}>
                      <FormattedMessage defaultMessage="When a plan agreement is sent to me for confirmation" />
                    </Text>
                  ),
                  childrenEmail: (
                    <SwitchField
                      name="isReceiveEmailsOnPlanAgreementToConfirm"
                      aria-label={formatMessage({
                        defaultMessage: 'User should receive email when a plan agreement is ready for confirmation',
                      })}
                    />
                  ),
                },
              ],
            },
          ].filter(Boolean)}
        />

        <Box
          display="flex"
          justifyContent="center"
          marginTop={amaliaTheme.spacing(6)}
        >
          <Button type="submit">
            <FormattedMessage {...COMMON_MESSAGES.SUBMIT} />
          </Button>
        </Box>
      </Form>
    </Formik>
  );
});

export default SettingsForm;
