import { lazy } from 'react';

import { Layout } from '@amal-ia/frontend/connected-components/layout';

const PaymentsLockPage = lazy(() => import('./PaymentsLockPage'));

export const PaymentsLockView = function PaymentsLockView() {
  return (
    <Layout>
      <PaymentsLockPage />
    </Layout>
  );
};
