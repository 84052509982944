import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { type RootState, type VariablesMap } from '@amal-ia/lib-types';

import { type VariableValue } from '../../types/variables';

export const selectVariablesMap = (state: RootState): VariablesMap => state.variables.map;

export const selectVariablesList = createSelector([selectVariablesMap], (map) => Object.values(map));

export const selectVariablesByMachineNameMap = createSelector([selectVariablesMap], (variablesMap: VariablesMap) =>
  keyBy(Object.values(variablesMap), 'machineName'),
);

export const selectVariablesIsLoading = (state: RootState): boolean => !!state.variables.isLoading;

export const selectCurrentVariableValues = (state: RootState): VariableValue[] => state.variables.values;
