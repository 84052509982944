import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('MIN', AmaliaFunctionCategory.NUMBERS);

func.nbParamsRequired = 0;

func.description = 'Return the minimum among parameters such as numbers, fields, properties, variables, formulas etc';

func.params = [
  { name: 'param1', description: 'The first param to compare' },
  { name: 'param2', description: 'The second param to compare' },
  { name: 'paramN', description: 'The Nth param to compare' },
];

func.hasInfiniteParams = true;

func.examples = [
  {
    desc: 'Returns 3',
    formula: 'MIN(8, 3)' as AmaliaFormula,
    result: 3,
  },
  {
    desc: 'Returns 0',
    formula: 'MIN(10, 2, 0, 5, 623)' as AmaliaFormula,
    result: 0,
  },
  {
    desc: 'Returns the minimum between Commission and On Target Commission. This will cap the Commission.',
    formula: 'MIN(statement.onTargetCommission, statement.commission)',
  },
  {
    desc: 'Returns the oldest date between Close Date and Payment Date.',
    formula: 'MIN(opportunity.closeDate, statementPeriod.paymentDate)',
  },
];

func.exec = (...numbers: number[]): number => Math.min(...numbers);

export default func;
