import { css } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { IconArrowUpRight } from '@tabler/icons-react';
import { Fragment, memo, type ReactNode, useCallback, useMemo } from 'react';

import { IconButton, PageHeader, Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { useOpenInNewTab } from '@amal-ia/lib-ui';
import { UserRole } from '@amal-ia/tenants/users/shared/types';

const useStyles = makeStyles({
  title: {
    cursor: 'pointer',
  },
});

export const actionButtonsContainer = css`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;

interface WidgetTitleProps {
  customReportId?: string;
  actions?: ReactNode;
  title: ReactNode;
  userRole: UserRole;
}

export const WidgetTitle = memo(function WidgetTitle({ customReportId, title, userRole, actions }: WidgetTitleProps) {
  const openInNewTab = useOpenInNewTab();
  const classes = useStyles();

  const canVisitCustomReport = useMemo(
    () => customReportId && [UserRole.ADMIN, UserRole.READ_ONLY_ADMIN].includes(userRole),
    [customReportId, userRole],
  );

  const handleClickOnTitle = useCallback(
    // FIXME @sylvain: this route does not seem to exist, is this dead code?
    () => openInNewTab(`/customReport/${customReportId}/edit`),
    [customReportId, openInNewTab],
  );

  return (
    <PageHeader.Row
      left={
        <Typography
          className={classes.title}
          variant={TypographyVariant.HEADING_3_BOLD}
          onClick={canVisitCustomReport ? handleClickOnTitle : null}
        >
          {title}
        </Typography>
      }
      right={
        <div css={actionButtonsContainer}>
          {!!actions && <Fragment>{actions}</Fragment>}

          {!!canVisitCustomReport && (
            <IconButton
              icon={<IconArrowUpRight />}
              label=""
              onClick={handleClickOnTitle}
            />
          )}
        </div>
      }
    />
  );
});
