import { Skeleton } from '@mui/lab';
import { Box } from '@mui/material';
import { mapValues } from 'lodash';
import { memo, useMemo } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { amaliaTheme, colors as colorPalette } from '@amal-ia/ext/mui/theme';
import { IconCrown } from '@amal-ia/frontend/ui-icons';
import { formatCurrencyAmount, formatUserFullName } from '@amal-ia/lib-types';
import { Avatar, Text, TextType } from '@amal-ia/lib-ui';

import { type DataLeaderboard, getUserCurrency, sumTotalForUser } from '../../../utils/dashboard.utils';

type LeaderBoardRowProps = {
  idx: number;
  max?: number;
  row?: DataLeaderboard;
  colors?: string[];
  loading?: boolean;
  rowsCount?: number;
};

export const LeaderBoardRow = memo(function LeaderBoardRow({
  idx,
  max,
  row,
  colors,
  loading,
  rowsCount = 0,
}: LeaderBoardRowProps) {
  const amount = useMemo(() => sumTotalForUser(row), [row]);
  const currencySymbol = useMemo(() => getUserCurrency(row), [row]);
  return (
    <Box
      key={`rank=${idx + 1}`}
      display="flex"
      flexDirection="column"
      height={50}
      id={`leaderboard-rank-${idx + 1}`}
      marginBottom={amaliaTheme.spacing(0.5)}
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        paddingLeft={amaliaTheme.spacing(0.5)}
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyItems="space-between"
            position="relative"
            width="100%"
          >
            {idx === 0 && amount > 0 && rowsCount > 1 && (
              <IconCrown
                fill={loading ? colorPalette['grey-200'] : colorPalette['primary-500']}
                height={25}
                style={{ position: 'absolute', top: amaliaTheme.spacing(-2.6) }}
                width={25}
              />
            )}
            {!loading ? (
              <Avatar
                style={{ height: amaliaTheme.spacing(3.1), width: amaliaTheme.spacing(3.1) }}
                user={row.user}
              />
            ) : (
              <Skeleton
                style={{ height: amaliaTheme.spacing(3.1), width: amaliaTheme.spacing(3.1) }}
                variant="circular"
              />
            )}
            <Text
              type={TextType.LEADERBOARD_ROW_TITLE}
              style={{
                marginLeft: amaliaTheme.spacing(1.4),
              }}
            >
              {loading ? (
                <Skeleton
                  style={{ width: amaliaTheme.spacing(10) }}
                  variant="text"
                />
              ) : (
                formatUserFullName(row.user)
              )}
            </Text>
          </Box>
          <Text
            type={TextType.LEADERBOARD_ROW_VALUE}
            style={{
              textAlign: 'right',
              width: amaliaTheme.spacing(15),
              paddingRight: amaliaTheme.spacing(0.75),
            }}
          >
            {loading ? (
              <Skeleton
                style={{ width: amaliaTheme.spacing(10) }}
                variant="text"
              />
            ) : (
              formatCurrencyAmount({
                amount,
                currencySymbol,
              })
            )}
          </Text>
        </Box>
      </Box>
      {loading ? (
        <Skeleton
          style={{ width: `calc(${100 - idx * 6}%)` }}
          variant="text"
        />
      ) : (
        <ResponsiveContainer
          height="100%"
          width="100%"
        >
          <BarChart
            height={300}
            layout="vertical"
            maxBarSize={max}
            width={500}
            data={[
              {
                ...mapValues(row.stats, (s) => s.value),
                name: row.user.lastName,
              },
            ]}
          >
            <XAxis
              hide
              domain={[0, max]}
              type="number"
            />
            <YAxis
              hide
              dataKey="name"
              scale="band"
              type="category"
            />

            {Object.keys(row.stats).map((bar, index) => (
              <Bar
                key={index}
                {...(index === 0 ? { background: { fill: colorPalette['grey-100'] } } : {})}
                barSize={9}
                dataKey={bar}
                fill={colors[index % colors.length]}
                radius={Object.keys(row.stats).length === index + 1 ? [0, 10, 10, 0] : [0, 0, 0, 0]}
                stackId="1"
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
});
