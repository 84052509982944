import { css } from '@emotion/react';
import { IconCircleFilled } from '@tabler/icons-react';
import clsx from 'clsx';
import { type ReactNode, memo, type ComponentPropsWithoutRef, type ForwardedRef, forwardRef } from 'react';

import { isEnum, type MergeAll } from '@amal-ia/ext/typescript';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { Typography } from '../typography/Typography';

import * as styles from './Badge.styles';
import { BadgeHue, BadgeSize, BadgeStatus } from './Badge.types';

const SIZE_TYPOGRAPHY_MAPPING: Record<BadgeSize, TypographyVariant> = {
  [BadgeSize.SMALL]: TypographyVariant.BODY_XSMALL_REGULAR,
  [BadgeSize.MEDIUM]: TypographyVariant.BODY_BASE_REGULAR,
  [BadgeSize.LARGE]: TypographyVariant.BODY_LARGE_REGULAR,
} as const;

const SIZE_ICON_SIZE_MAPPING: Record<BadgeSize, number> = {
  [BadgeSize.SMALL]: 4,
  [BadgeSize.MEDIUM]: 6,
  [BadgeSize.LARGE]: 6,
} as const;

export type BadgeProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Badge size. */
      size?: BadgeSize;
      /** Badge content. */
      children: ReactNode;
      /** Variant. */
      variant: BadgeHue | BadgeStatus;
    },
  ]
>;

const BadgeBase = forwardRef(function Badge(
  { variant, size = BadgeSize.MEDIUM, className = undefined, children, ...props }: BadgeProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      ref={ref}
      className={clsx(className, size, variant)}
      css={styles.badge}
    >
      {!!isEnum(variant, BadgeStatus) && (
        <IconCircleFilled
          color="currentColor"
          size={SIZE_ICON_SIZE_MAPPING[size]}
          css={css`
            flex: none;
          `}
        />
      )}

      <Typography
        css={styles.text}
        variant={SIZE_TYPOGRAPHY_MAPPING[size]}
      >
        {children}
      </Typography>
    </div>
  );
});

export const Badge = Object.assign(memo(BadgeBase), {
  Size: BadgeSize,
  Hue: BadgeHue,
  Status: BadgeStatus,
});
