import clsx from 'clsx';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import * as styles from './CountBadge.styles';
import { CountBadgeSize, CountBadgeVariant } from './CountBadge.types';

export type CountBadgeProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      disabled?: boolean;
      size?: CountBadgeSize;
      variant: CountBadgeVariant;
      withStroke?: boolean;
    },
  ]
>;

const CountBadgeBase = memo(function CountBadge({
  disabled = false,
  size = CountBadgeSize.MEDIUM,
  variant,
  children,
  ...props
}: CountBadgeProps) {
  return (
    <div
      {...props}
      css={styles.countBadge}
      className={clsx(props.className, variant, size, {
        [styles.IS_DISABLED_CLASSNAME]: disabled,
        [styles.WITH_STROKE_CLASSNAME]: props.withStroke,
      })}
    >
      {children}
    </div>
  );
});

export const CountBadge = Object.assign(CountBadgeBase, {
  Size: CountBadgeSize,
  Variant: CountBadgeVariant,
});
