import { type FormEventHandler, type ReactNode, type RefObject, useCallback, useLayoutEffect, useState } from 'react';
import { type Schema, ValidationError } from 'yup';

import { useUpdateEffect } from '@amal-ia/ext/react/hooks';
import { toError } from '@amal-ia/ext/typescript';

const getValidationError = (value: string, schema?: Schema) => {
  if (!schema) {
    return null;
  }

  try {
    schema.validateSync(value);
    return null;
  } catch (err) {
    if (ValidationError.isError(err)) {
      return err.message;
    }

    return toError(err).message;
  }
};

export const useQuickEdit = ({
  value,
  onChange,
  schema,
  disabled,
  isEditing: controlledIsEditing,
  onChangeIsEditing: controlledOnChangeIsEditing,
  inputRef,
}: {
  value: string;
  onChange?: (value: string) => void;
  schema?: Schema;
  validationError?: ReactNode;
  disabled?: boolean;
  isEditing?: boolean;
  onChangeIsEditing?: (isEditing: boolean) => void;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const [uncontrolledIsEditing, setUncontrolledIsEditing] = useState(controlledIsEditing ?? false);

  const isEditing = controlledIsEditing ?? uncontrolledIsEditing;
  const onChangeIsEditing = controlledOnChangeIsEditing ?? setUncontrolledIsEditing;

  // When disabled, consider the control as not being in edit mode.
  const isInEditMode = isEditing && !disabled;

  // If the value changes, update the internal value.
  useLayoutEffect(() => {
    setInternalValue(value);
  }, [value]);

  // Focus the input when entering edit mode. Need to do it in an effect because edit mode can be controlled by the parent.
  useUpdateEffect(() => {
    if (isInEditMode) {
      inputRef.current?.focus();
    }
  }, [isInEditMode, inputRef]);

  const handleConfirm = useCallback(() => {
    onChange?.(internalValue);
    onChangeIsEditing(false);
  }, [internalValue, onChange, onChangeIsEditing]);

  // Validate the value when it changes. Error passed as prop overrides the validation error.
  const validationError: ReactNode = getValidationError(internalValue, schema);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      if (!validationError) {
        handleConfirm();
      }
    },
    [validationError, handleConfirm],
  );

  // Cannot use useBoolState because onChangeIsEditing can be controlled by the parent.
  const handleStartEditing = useCallback(() => onChangeIsEditing(true), [onChangeIsEditing]);

  return {
    internalValue,
    validationError,
    isEditing,
    isInEditMode,
    setInternalValue,
    onChangeIsEditing,
    handleSubmit,
    handleStartEditing,
    handleConfirm,
  };
};
