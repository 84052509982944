import { css, useTheme } from '@emotion/react';
import { type Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { trim } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Tooltip } from '@amal-ia/frontend/design-system/components';
import { TokenType } from '@amal-ia/lib-types';

import { useFormulaEditorContext } from './FormulaEditor.context';

/**
 * The exact color of tokens is specific to the context. In token icons, we use the 100 strength.
 */
const useTokenBackgroundColor = (tokenType: TokenType): string | undefined => {
  const theme = useTheme();

  return {
    [TokenType.VARIABLE]: theme.ds.hues.cyan[50],
    [TokenType.FILTER]: theme.ds.hues.green[50],
    [TokenType.LINK]: theme.ds.hues.red[50],
    [TokenType.QUOTA]: theme.ds.hues.purple[50],
    [TokenType.FUNCTION]: undefined,
    [TokenType.KEYWORD]: theme.ds.hues.magenta[50],
    [TokenType.FIELD]: theme.ds.hues.orange[50],
    [TokenType.PROPERTY]: theme.ds.hues.orange[50],
    [TokenType.VIRTUAL_PROPERTY]: theme.ds.hues.orange[50],
    [TokenType.OBJECT]: theme.ds.hues.orange[50],
    [TokenType.RULE]: undefined,
    [TokenType.PLAN]: undefined,
  }[tokenType];
};

export type FormulaTokenNodeProps = {
  node: ProseMirrorNode & {
    attrs: {
      machineName: string;
    };
  };
};

export const FormulaTokenNode = function FormulaTokenNode({
  node: {
    attrs: { machineName },
  },
}: FormulaTokenNodeProps) {
  const { planTokens, onTokenClick } = useFormulaEditorContext();
  const token = planTokens[machineName];
  const backgroundColor = useTokenBackgroundColor(token?.type);
  const { formatMessage } = useIntl();

  const onClickHandler = useCallback(() => onTokenClick(token), [onTokenClick, token]);

  return (
    <NodeViewWrapper
      as="span"
      data-text-content={machineName}
    >
      <NodeViewContent
        as="span"
        css={[
          (theme) => css`
            ${theme.ds.typographies.bodyBaseMedium};
            display: inline-flex;
            border-radius: ${theme.ds.borderRadiuses.squared};
            padding: 0 6px;
          `,
          token
            ? (theme) => css`
                color: ${theme.ds.colors.gray[900]};
              `
            : (theme) => css`
                color: ${theme.ds.colors.danger[500]};
              `,
          token?.id &&
            css`
              cursor: pointer;
            `,
          backgroundColor &&
            css`
              background-color: ${backgroundColor};
            `,
        ]}
        onClick={token?.id ? onClickHandler : undefined}
      >
        {token ? (
          trim(token.name)
        ) : (
          <Tooltip
            content={formatMessage({
              defaultMessage: 'This token has not been found. It may be invalid in this context.',
            })}
          >
            <span>{machineName}</span>
          </Tooltip>
        )}
      </NodeViewContent>
    </NodeViewWrapper>
  );
};
