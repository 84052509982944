import { useTheme } from '@emotion/react';
import { Fragment, memo, useCallback } from 'react';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { StatementErrorLevel } from '@amal-ia/lib-types';

import { minMaxLimit } from '../../../../utils/common/maths';
import { Gauge } from '../Gauge/Gauge';

export interface GaugeGroupProps {
  progress?: {
    primary: number | null | undefined;
    secondary: number | null | undefined;
  };
  onGaugeHover?: (identifier: string, isHover: boolean) => void;
  errorLevel?: StatementErrorLevel;
  width?: number;
  gap?: number;
  strokeWidth?: number;
  gaugeColor?: {
    primary: string;
    primaryEmptyStroke?: string;
    secondary: string;
    secondaryEmptyStroke?: string;
  };
}

export const spacingToNumber = (spacing: string) => Number(spacing.replace('px', ''));

const DEFAULT_WIDTH = spacingToNumber(amaliaTheme.spacing(22));

export const GaugeGroup = memo(function GaugeGroup({
  errorLevel,
  onGaugeHover,
  progress,
  width = DEFAULT_WIDTH,
  gap = 4,
  strokeWidth = 9.6,
  gaugeColor = {
    primary: amaliaTheme.palette.primary.main,
    secondary: amaliaTheme.palette.link.main,
  },
}: GaugeGroupProps) {
  const theme = useTheme();

  const themeColorForStatementErrorType: Record<StatementErrorLevel, string> = {
    [StatementErrorLevel.WARNING]: theme.ds.colors.warning[500],
    [StatementErrorLevel.ERROR]: theme.ds.colors.danger[500],
  };

  const gaugeHover = useCallback(
    (identifier: string, isHover: boolean) => onGaugeHover?.(identifier, isHover),
    [onGaugeHover],
  );

  const innerGaugeRadius = (width - (strokeWidth + gap) * 2) / 2;

  return (
    <svg
      height={width}
      width={width}
    >
      {errorLevel ? (
        <Gauge
          boxSize={width}
          color={themeColorForStatementErrorType[errorLevel]}
          progress={100}
          radius={innerGaugeRadius}
          stroke={strokeWidth}
        />
      ) : (
        <Fragment>
          <Gauge
            boxSize={width}
            color={gaugeColor.secondary}
            colorEmptyStroke={gaugeColor.secondaryEmptyStroke}
            progress={minMaxLimit(progress?.secondary || 0, 0, 100)}
            radius={width / 2}
            stroke={strokeWidth}
            onGaugeHover={(isHover: boolean) => gaugeHover('secondary', isHover)}
          />

          <Gauge
            boxSize={width}
            color={gaugeColor.primary}
            colorEmptyStroke={gaugeColor.primaryEmptyStroke}
            progress={minMaxLimit(progress?.primary || 0, 0, 100)}
            radius={innerGaugeRadius}
            stroke={strokeWidth}
            onGaugeHover={(isHover: boolean) => gaugeHover('primary', isHover)}
          />
        </Fragment>
      )}
    </svg>
  );
});
