import { css, type Theme } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo, type ComponentPropsWithoutRef } from 'react';

import { ExternalLink, Link, type LinkProps } from '@amal-ia/ext/react-router-dom';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { DropdownItemContent, type DropdownItemContentProps } from '../dropdown-item-content/DropdownItemContent';
import { DropdownItemContainer } from '../Dropdown.styles';

const linkStyle = (theme: Theme) => css`
  width: 100%;
  display: block;
  border-radius: ${theme.ds.borderRadiuses.squared};

  transition: ${theme.ds.transitions.default('box-shadow')};

  text-decoration: none;

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.secondary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};

    position: relative;
    z-index: 1;
  }
`;

export type DropdownItemLinkProps = MergeAll<
  [
    Omit<DropdownItemContentProps, 'contentEditable' | 'isInteractive'>,
    Omit<ComponentPropsWithoutRef<'a'>, 'contentEditable'>,
    {
      to?: LinkProps['to'];
      isExternal?: boolean;
    },
  ]
>;

const DropdownItemLinkForwardRef = forwardRef(function DropdownItemLink(
  { to, isExternal, href, label, icon, disabled, isActive, ...props }: DropdownItemLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const content = (
    <DropdownItemContent
      isInteractive
      disabled={disabled}
      icon={icon}
      isActive={isActive}
      label={label}
    />
  );

  return (
    <DropdownItemContainer>
      {isExternal ? (
        <ExternalLink
          {...props}
          ref={ref}
          css={linkStyle}
          href={disabled ? undefined : href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {content}
        </ExternalLink>
      ) : (
        <Link
          {...props}
          ref={ref}
          css={linkStyle}
          to={disabled ? undefined : to}
        >
          {content}
        </Link>
      )}
    </DropdownItemContainer>
  );
});

export const DropdownItemLink = memo(DropdownItemLinkForwardRef);
