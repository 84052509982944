import { Box, Popover, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { useEditor } from '@tiptap/react';
import { memo, useCallback, useState, Fragment, type MouseEventHandler } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';
import { IconIFrameVariableOutline } from '@amal-ia/frontend/ui-icons';
import { useCustomIconStyles } from '@amal-ia/lib-ui';

import { type DesignerAllObjects } from '../../designer/drawer/drawer.types';

import { FunctionNode, formulaToHtml } from './designerExtension';
import { DesignerObjectsModalPicker } from './DesignerObjectsModalPicker';
import { FormulaEditorContent } from './FormulaEditorContent';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  editor: {
    marginTop: '10px',
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.grey['200'],
    backgroundColor: theme.palette.common.white,
    minHeight: '6rem',
    display: 'inline-flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  formula: {
    alignSelf: 'flex-start',
    width: '100%',
  },
}));

export interface FormulaEditorProps {
  name: string;
  id: string;
  designerObjects?: DesignerAllObjects;
  formula: string;
  onFormulaChange: (value: string) => void;
  onBlur?: (e: FocusEvent) => void;
  onRefreshScope?: () => void;
}

/**
 * Formula editor.
 */
export const FormulaEditor = memo(function FormulaEditor({
  designerObjects,
  formula,
  onFormulaChange,
  onBlur,
  name,
  id,
  onRefreshScope,
}: FormulaEditorProps) {
  const classes = useStyles();
  const iconClasses = useCustomIconStyles();
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const { formatMessage } = useIntl();

  const handleVariableIconClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const editor = useEditor(
    {
      extensions: [Document, FunctionNode, History, Paragraph, Text],
      editorProps: { attributes: { name, id, 'data-testid': id } },
      content: formulaToHtml(formula, designerObjects),
      onUpdate: ({ transaction }) => {
        onFormulaChange(transaction?.doc?.textContent);
      },
      onBlur: onBlur
        ? ({ event }) => {
            onBlur(event);
          }
        : () => {},
    },
    [designerObjects],
  );

  return (
    <Grid
      container
      alignItems="center"
      className={classes.editor}
      direction="row"
      justifyContent="flex-start"
      onClick={() => editor?.commands.focus()}
    >
      {designerObjects && editor ? (
        <Fragment>
          <IconButton
            data-testid="designerObjectsPicker"
            icon={<IconIFrameVariableOutline className={iconClasses.customIcon} />}
            label={formatMessage({ defaultMessage: 'Search' })}
            onClick={handleVariableIconClick}
          />
          <Popover
            anchorEl={anchorEl}
            data-testid="categoryPopper"
            id="categoryPopper"
            open={!!anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
          >
            <DesignerObjectsModalPicker
              designerObjects={designerObjects}
              editor={editor}
              onRefreshScope={onRefreshScope}
            />
          </Popover>
        </Fragment>
      ) : null}

      <Box
        className={classes.formula}
        display="block"
      >
        <FormulaEditorContent editor={editor} />
      </Box>
    </Grid>
  );
});
