import { type Theme, css } from '@emotion/react';

export const container = css`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const gridContainer = (theme: Theme) => css`
  background-color: ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.soft};
`;
