import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container, Paper } from '@mui/material';
import { IconRefresh } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { LayoutAnonymous, Text, TextType, useLoadingScreen } from '@amal-ia/lib-ui';

/**
 * This component is used when we embed our app in an IFrame.
 * In this scenario, we are not able to do the full authentication in the IFrame because of some security headers
 * (either in Auth0 or Google auth).
 * As a workaround, user is redirected in a new page where he can authenticate,
 * then as soon as he reloads this page, he is redirected on the homepage.
 * @constructor
 */
export const IFrameAuth = function IFrameAuth() {
  const [isClicked, setIsClicked] = useState<boolean>();

  const { hideLoading } = useLoadingScreen();

  const { isAuthenticated: isAuthenticatedWithAuth0, isLoading } = useAuth0();
  useEffect(() => {
    if (isLoading === false) {
      hideLoading();
    }

    if (isAuthenticatedWithAuth0) {
      window.location.href = generatePath(routes.ROOT);
    }
  }, [isLoading, isAuthenticatedWithAuth0, hideLoading]);

  const onClickHandler = () => {
    // If the login button has already been clicked, we reload the page
    // and user will be redirected in the homepage, if login was successful.
    if (isClicked) {
      window.location.reload();
      return;
    }

    setIsClicked(true);
    window.open(generatePath(routes.ROOT), '_blank');
  };

  return (
    !isLoading && (
      <LayoutAnonymous>
        <Box mt={amaliaTheme.spacing(8)}>
          <Container maxWidth="sm">
            <Paper>
              <Box
                p={amaliaTheme.spacing(8)}
                textAlign="center"
              >
                <Box margin={2}>
                  <Text type={TextType.NORMAL_TEXT}>
                    {isClicked ? (
                      <FormattedMessage defaultMessage="Once authenticated in Amalia, please reload to connect to your Amalia account." />
                    ) : (
                      <FormattedMessage defaultMessage="You can connect to Amalia.io using your account." />
                    )}
                  </Text>
                </Box>

                <Button
                  icon={isClicked ? <IconRefresh /> : undefined}
                  onClick={onClickHandler}
                >
                  {isClicked ? (
                    <FormattedMessage defaultMessage="Reload" />
                  ) : (
                    <FormattedMessage defaultMessage="Login" />
                  )}
                </Button>
              </Box>
            </Paper>
          </Container>
        </Box>
      </LayoutAnonymous>
    )
  );
};
