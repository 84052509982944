import { Unstable_Grid2 as Grid } from '@mui/material';
import { uniq } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { toError } from '@amal-ia/ext/typescript';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { IconMedal } from '@amal-ia/frontend/ui-icons';
import {
  selectPaymentsByCategoryForCurrentStatement,
  selectCurrentPeriod,
  useThunkDispatch,
  selectTeamMap,
  fetchUsers,
  selectUsersMap,
  useCurrentCompany,
} from '@amal-ia/frontend/web-data-layers';
import * as ChallengeRepository from '@amal-ia/frontend/web-data-layers';
import { type Challenge, type ComputedRule, type MainKpi } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { LeaderboardDetails, RuleAccordion } from '@amal-ia/lib-ui-business';

interface RuleChallengeProps {
  rule: PlanRule;
  computedRule: ComputedRule;
  categoryName: string | null;
  activeRuleId?: string;
}

const RuleChallenge = memo(function RuleChallenge({
  rule,
  computedRule,
  categoryName,
  activeRuleId,
}: RuleChallengeProps) {
  const identifier = `${categoryName || 'none'}-${rule.ruleMachineName}`;

  const { snackError } = useSnackbars();
  const dispatch = useThunkDispatch();
  const usersMap = useSelector(selectUsersMap);
  const teamsMap = useSelector(selectTeamMap);
  const payments = useSelector(selectPaymentsByCategoryForCurrentStatement);
  const { company } = useCurrentCompany();
  const currentPeriod = useSelector(selectCurrentPeriod);
  const { formatMessage } = useIntl();

  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(activeRuleId === rule.id, 'ruleExpanded');

  const statement = useStatementDetailContext();

  const comparisonVariableDefinition = useMemo(
    () =>
      Object.values(statement?.results.definitions.variables).find(
        (v) => v.id === rule.configuration?.challengeComparisonVariableId,
      ),
    [rule, statement.results],
  );

  const [challenge, setChallenge] = useState<Challenge | null>(null);

  useAsyncEffect(async () => {
    try {
      const challengeFromApi = await ChallengeRepository.getChallenge(rule.id, statement.period.id);

      setChallenge(challengeFromApi);

      if (!challengeFromApi) {
        return;
      }

      const userIdsToFetch = uniq((challengeFromApi.leaderboard || []).map((r) => r.userId).filter(Boolean));
      if (userIdsToFetch.length) {
        await dispatch(fetchUsers(userIdsToFetch));
      }
    } catch (e) {
      snackError(toError(e).message);
    }
  }, [statement]);

  // Format the display we put on the rule summary.
  const { mainKpi, overwriteMainKpiFormattedValue } = useMemo(() => {
    if (!challenge?.results) {
      return {
        mainKpi: {
          label: formatMessage({ defaultMessage: 'RANK' }),
          value: 0,
        },
        overwriteMainKpiFormattedValue: '-',
      };
    }

    // Number if you have a rank, null if non eligible, undefined if not loaded.
    const currentUserPosition = challenge?.leaderboard?.find((l) => l.userId === statement.user.id)?.position;
    const contestantsLength = challenge?.leaderboard?.length;

    // If null, it means you're not eligible.
    const rank =
      currentUserPosition === null
        ? formatMessage({ defaultMessage: 'Non eligible' })
        : currentUserPosition
          ? // If undefined, it means it's not loaded.
            formatMessage(
              { defaultMessage: '{userPosition, number} / {contestantsCount, number}' },
              {
                userPosition: currentUserPosition,
                contestantsCount: contestantsLength,
              },
            )
          : formatMessage(
              { defaultMessage: '- / {contestantsCount, number}' },
              { contestantsCount: contestantsLength },
            );

    // If the challenge is non-payout, display something like:
    // | RANK
    // | 2 / 5
    if (!rule.configuration?.challengePricesTableVariableId) {
      return {
        mainKpi: {
          label: formatMessage({ defaultMessage: 'RANK' }),
          value: 0,
        },
        overwriteMainKpiFormattedValue: rank,
      };
    }

    // If the challenge is payout, display something like:
    // | RANK: 2 / 5
    // | 1500 €

    // First try to recover the associated payout.
    const payment = payments.paid.find((p) => p.challengeId === challenge.id);

    return {
      mainKpi: {
        label: formatMessage({ defaultMessage: `RANK: {rank}` }, { rank }),
        value: payment
          ? {
              amount: payment.value || 0,
              currencySymbol: payment.currency,
              // Putting 1 here because the generated payment for the challenge
              // has already the right currency at the right rate.
              currencyRate: 1,
            }
          : { amount: 0 },
      } as MainKpi,
    };
  }, [payments, rule, challenge, statement, formatMessage]);

  // Completely mask the area when the challenge doesn't have a result.
  // It happens if the challenge is deactivated for this period, or if all statements are in error
  // for that period.
  if (!challenge) {
    return null;
  }

  return (
    <RuleAccordion
      key={rule.id}
      helpLabel={rule.description}
      icon={<IconMedal />}
      isExpanded={isRuleExpanded}
      label={rule.name}
      machineName={identifier}
      mainKpi={mainKpi}
      overwriteMainKpiFormattedValue={overwriteMainKpiFormattedValue}
      toggleExpanded={toggleRuleExpanded}
    >
      {computedRule && challenge?.leaderboard && comparisonVariableDefinition ? (
        <Grid container>
          <LeaderboardDetails
            challenge={challenge}
            company={company}
            currentPeriod={currentPeriod}
            teamsMap={teamsMap}
            usersMap={usersMap}
            variableFormat={comparisonVariableDefinition.format}
            variableName={comparisonVariableDefinition.name}
          />
        </Grid>
      ) : null}
    </RuleAccordion>
  );
});

export default RuleChallenge;
