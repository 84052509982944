import { Box } from '@mui/material';
import { IconLayoutGrid, IconTimeline } from '@tabler/icons-react';
import { useState, memo, Fragment } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { RadioButtonGroup } from '@amal-ia/frontend/design-system/components';

import Charts from './Charts/Charts';
import { KPIList } from './kpis/KPIList';

enum RuleView {
  KPI = 'kpi',
  CHART = 'chart',
}

const viewOptions = [
  {
    value: RuleView.KPI,
    icon: <IconLayoutGrid />,
    label: 'Switch to KPIs',
    showOnlyIcon: true,
  },
  {
    value: RuleView.CHART,
    icon: <IconTimeline />,
    label: 'Switch to charts',
    showOnlyIcon: true,
  },
];

interface RuleResultAccessoryResultsProps {
  ruleDefinition: PlanRule;
}

const RuleResultAccessoryResults = memo(function RuleResultAccessoryResults({
  ruleDefinition,
}: RuleResultAccessoryResultsProps) {
  const [selectedView, setSelectedView] = useState<RuleView>(RuleView.KPI);

  // If no charts are available, print only KPIs
  if ((ruleDefinition.charts || []).length === 0) {
    return <KPIList ruleDefinition={ruleDefinition} />;
  }

  return (
    <Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
      >
        <RadioButtonGroup<RuleView>
          name="ruleViewRadio"
          options={viewOptions}
          value={selectedView}
          onChange={setSelectedView}
        />
      </Box>
      {selectedView === RuleView.KPI && <KPIList ruleDefinition={ruleDefinition} />}
      {selectedView === RuleView.CHART && <Charts ruleDefinition={ruleDefinition} />}
    </Fragment>
  );
});

export default RuleResultAccessoryResults;
