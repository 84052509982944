import { useAuth0 } from '@auth0/auth0-react';
import { IconLogout, IconSelector, IconSettings, IconUser } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { useTextOverflows } from '@amal-ia/ext/react/hooks';
import { Avatar, MenuDropdown, Tooltip, Typography } from '@amal-ia/frontend/design-system/components';
import { useAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import { config } from '@amal-ia/frontend/kernel/config';
import { formatUserFullName } from '@amal-ia/lib-types';
import { UserApiClient } from '@amal-ia/tenants/users/shared/api-client';
import { tenantUsersRoutes } from '@amal-ia/tenants/users/shared/routes';

import * as styles from './LeftBarUser.styles';

export const LeftBarUser = memo(function LeftBarUser() {
  const { authenticatedContext } = useAuthenticatedContext();
  const { logout: auth0logout } = useAuth0();
  const { ref: overflowRef, doesTextOverflow } = useTextOverflows();
  const { formatMessage } = useIntl();
  const { user } = authenticatedContext;

  const handleLogout = useCallback(async () => {
    await UserApiClient.logout();
    await auth0logout({
      logoutParams: {
        returnTo: config.auth0.redirectUri,
      },
    });
  }, [auth0logout]);

  return (
    <div css={styles.leftBarUser(!!authenticatedContext.impersonation)}>
      <Avatar
        size={Avatar.Size.XSMALL}
        user={authenticatedContext.user}
      />

      <Tooltip
        content={
          authenticatedContext.impersonation ? (
            <FormattedMessage
              defaultMessage="You are impersonating: {userName} ({email})"
              values={{
                userName: formatUserFullName(authenticatedContext.user),
                email: authenticatedContext.user.email,
              }}
            />
          ) : doesTextOverflow ? (
            <FormattedMessage
              defaultMessage="{userName} ({email})"
              values={{
                userName: formatUserFullName(user),
                email: user.email,
              }}
            />
          ) : (
            user.email
          )
        }
      >
        <Typography
          ref={overflowRef}
          css={styles.userName}
          variant={Typography.Variant.BODY_SMALL_MEDIUM}
        >
          {formatUserFullName(user)}
        </Typography>
      </Tooltip>

      <MenuDropdown
        content={
          <Fragment>
            <MenuDropdown.ItemLink
              icon={<IconUser />}
              label={<FormattedMessage defaultMessage="Profile" />}
              to={generatePath(tenantUsersRoutes.PROFILE_PAGE)}
            />

            <MenuDropdown.ItemLink
              icon={<IconSettings />}
              label={<FormattedMessage defaultMessage="User settings" />}
              to={generatePath(routes.SETTINGS)}
            />

            <MenuDropdown.Item
              icon={<IconLogout />}
              label={<FormattedMessage defaultMessage="Log out" />}
              onClick={handleLogout}
            />
          </Fragment>
        }
      >
        <MenuDropdown.IconButton
          icon={<IconSelector />}
          label={formatMessage({ defaultMessage: 'User menu' })}
        />
      </MenuDropdown>
    </div>
  );
});
