import { forwardRef, type HTMLAttributes, type LegacyRef, memo } from 'react';

import { FormatsEnum, isUserModelRefValue, type PropertyRef } from '@amal-ia/data-capture/fields/types';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { type CurrencyValue, isCurrencyValue } from '@amal-ia/kernel/monetary/types';
import { formatTotal, formatValueTotal } from '@amal-ia/lib-types';
import { type ComputeEnginePrimitiveTypes } from '@amal-ia/payout-calculation/shared/types';

import { BooleanPrettyFormat, toBoolean } from './boolean';
import { accentuatedLabel, defaultLabel, dimmedLabel, label } from './FieldValuePrettyFormat.styles';
import { LabelVariant, TypographyVariantMap } from './types';
import { UserContainer } from './user';

export { LabelVariant };
export { isFieldValuePrettyFormatCompatible } from './FieldValuePrettyFormat.compatibility';

export type ValueWithFormat<Format extends FormatsEnum, Value> = {
  format?: Format;
  value?: Value | null;
};

// FIXME -- move to data-capture/fields/types?
export const getPrettyFormatProps = (
  format: FormatsEnum,
  value?: unknown,
  valueRef?: PropertyRef,
): ValuesWithFormats & { valueRef?: PropertyRef } => {
  if (isUserModelRefValue(value, valueRef)) {
    return { format: FormatsEnum.text, value, valueRef };
  }
  if (format === FormatsEnum.boolean) {
    return { format, value: value as boolean };
  }

  if (format === FormatsEnum.currency) {
    return { format, value: value as CurrencyValue };
  }
  return { format: FormatsEnum.text, value: value as string };
};

export type ValuesWithFormats =
  | ValueWithFormat<(typeof FormatsEnum)['date-time'], string>
  | ValueWithFormat<FormatsEnum.boolean, boolean | string>
  | ValueWithFormat<FormatsEnum.currency, CurrencyValue | number | string>
  | ValueWithFormat<FormatsEnum.date, string>
  | ValueWithFormat<FormatsEnum.number, number>
  | ValueWithFormat<FormatsEnum.percent, number>
  // FIXME?
  | ValueWithFormat<FormatsEnum.table, string>
  | ValueWithFormat<FormatsEnum.text, string>;

export type FieldValuePrettyFormatProps = HTMLAttributes<HTMLDivElement> &
  ValuesWithFormats & {
    variant?: LabelVariant;
    valueRef?: PropertyRef;
  };

const FieldValuePrettyFormatForwardRef = forwardRef(function FieldValuePrettyFormat(
  { format, value, valueRef, variant = LabelVariant.DEFAULT, ...restProps }: FieldValuePrettyFormatProps,
  ref?: LegacyRef<HTMLDivElement>,
) {
  const cssStyles = [
    variant === LabelVariant.DEFAULT && defaultLabel,
    variant === LabelVariant.ACCENTUATED && accentuatedLabel,
    variant === LabelVariant.DIMMED && dimmedLabel,
  ];
  return (
    <div
      ref={ref}
      {...restProps}
      css={label}
    >
      {(() => {
        // PropertyRef has higher priority than format to display the correct component
        if (isUserModelRefValue(value, valueRef)) {
          return (
            <UserContainer
              property="externalId"
              value={value}
              variant={variant}
            />
          );
        }

        switch (format) {
          case FormatsEnum.boolean:
            return (
              <BooleanPrettyFormat
                value={toBoolean(value)}
                variant={variant}
              />
            );
          case FormatsEnum.currency:
            return (
              <Typography
                css={cssStyles}
                variant={TypographyVariantMap[variant]}
              >
                {isCurrencyValue(value)
                  ? formatTotal(value.value, FormatsEnum.currency, value.symbol, 1)
                  : formatValueTotal(value)}
              </Typography>
            );
          default:
            return (
              <Typography
                css={cssStyles}
                variant={TypographyVariantMap[variant]}
              >
                {formatValueTotal(value as ComputeEnginePrimitiveTypes)}
              </Typography>
            );
        }
      })()}
    </div>
  );
});

export const FieldValuePrettyFormat = memo(FieldValuePrettyFormatForwardRef);
