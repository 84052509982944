import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import { memo, useCallback } from 'react';

import { useBoolState } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { type DatePickerValueProps } from '../../../../forms/date-picker/DatePicker.types';
import { useMapDatePickerProps } from '../../../../forms/date-picker/hooks/useMapDatePickerProps';
import { DatePickerBase, type DatePickerBaseProps } from '../../../../forms/date-picker-base/DatePickerBase';
import { type RowData } from '../../Table.types';

import { CellDatePickerAnchor } from './cell-date-picker-anchor/CellDatePickerAnchor';

export type CellDatePickerProps<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData = RowData,
> = MergeAll<
  [
    DatePickerBaseProps<TWithRange>,
    DatePickerValueProps<TWithRange>,
    {
      row: TRowData;
      onChange?: (date: Parameters<DatePickerBaseProps<TWithRange>['onChange']>[0], row: TRowData) => void;
    },
  ]
>;

const CellDatePickerBase = function CellDatePicker<
  TWithRange extends boolean | undefined = undefined,
  TRowData extends RowData = RowData,
>({
  value,
  row,
  placeholder,
  endDate,
  startDate,
  onCalendarOpen,
  onCalendarClose,
  onChange,
  ...props
}: CellDatePickerProps<TWithRange, TRowData>) {
  const { isCalendarOpen, setCalendarOpenFalse, setCalendarOpenTrue } = useBoolState(false, 'calendarOpen');

  const mappedDatePickerProps = useMapDatePickerProps<TWithRange>({
    value,
    placeholder,
    endDate,
    startDate,
  });

  const handleCalendarOpen = useCallback(() => {
    setCalendarOpenTrue();
    onCalendarOpen?.();
  }, [setCalendarOpenTrue, onCalendarOpen]);

  const handleCalendarClose = useCallback(() => {
    setCalendarOpenFalse();
    onCalendarClose?.();
  }, [setCalendarOpenFalse, onCalendarClose]);

  const handleChange: DatePickerBaseProps<TWithRange>['onChange'] = useCallback(
    (date) => onChange?.(date, row),
    [onChange, row],
  );

  return (
    <ClassNames>
      {({ css }) => (
        <DatePickerBase<TWithRange>
          {...props}
          {...mappedDatePickerProps}
          customInput={<CellDatePickerAnchor isCalendarOpen={isCalendarOpen} />}
          wrapperClassName={clsx(
            props.wrapperClassName,
            css`
              &.react-datepicker-wrapper {
                display: block;
              }
            `,
          )}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          onChange={handleChange}
        />
      )}
    </ClassNames>
  );
};

export const CellDatePicker = memo(CellDatePickerBase) as typeof CellDatePickerBase;
