import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import {
  type ForwardedRef,
  type ReactNode,
  cloneElement,
  forwardRef,
  memo,
  type ComponentPropsWithoutRef,
} from 'react';

import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { IconLoading } from '../icon-loading/IconLoading';
import { type TablerIconElement } from '../icons/types';
import { Typography } from '../typography/Typography';

import * as styles from './Button.styles';
import { ButtonIconPosition, ButtonSize, ButtonVariant } from './Button.types';

const ICON_SIZE_MAPPING_PX: Record<ButtonSize, number> = {
  [ButtonSize.SMALL]: 14,
  [ButtonSize.MEDIUM]: 16,
  [ButtonSize.LARGE]: 18,
} as const;

const TYPOGRAPHY_VARIANT_MAPPING: Record<ButtonSize, TypographyVariant> = {
  [ButtonSize.SMALL]: TypographyVariant.BUTTON_SMALL,
  [ButtonSize.MEDIUM]: TypographyVariant.BUTTON_BASE,
  [ButtonSize.LARGE]: TypographyVariant.BUTTON_LARGE,
} as const;

export type ButtonProps = Omit<ComponentPropsWithoutRef<'button'>, 'children' | 'size'> & {
  /** Color. */
  variant?: ButtonVariant;
  /** Size. */
  size?: ButtonSize;
  /** Icon if any. Position with iconPosition. */
  icon?: TablerIconElement;
  /** Icon position if icon is defined. */
  iconPosition?: ButtonIconPosition;
  /** Force active state. */
  isActive?: boolean;
  /** Loading state. Will disable the button. */
  isLoading?: boolean;
  /** While in loading state, show this tooltip label instead. */
  iconLoading?: TablerIconElement;
  /** Label. */
  children: ReactNode;
};

const ButtonForwardRef = forwardRef(function Button(
  {
    type = 'button',
    icon = undefined,
    iconPosition = ButtonIconPosition.LEFT,
    variant = ButtonVariant.PRIMARY,
    size = ButtonSize.MEDIUM,
    className = undefined,
    isActive = false,
    isLoading = false,
    iconLoading = <IconLoading />,
    children,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const clonedIconWithTheme = icon ? (
    <ClassNames>
      {({ css }) =>
        cloneElement(isLoading ? iconLoading : icon, {
          size: ICON_SIZE_MAPPING_PX[size],
          color: 'currentColor',
          className: css`
            flex: none;
          `,
        })
      }
    </ClassNames>
  ) : null;

  return (
    <button
      {...props}
      ref={ref}
      className={clsx(className, size, variant, { [styles.IS_ACTIVE_CLASSNAME]: isActive })}
      css={styles.button}
      disabled={props.disabled || isLoading}
      type={type}
    >
      {iconPosition === ButtonIconPosition.LEFT && clonedIconWithTheme}

      <Typography
        as="span"
        className={size}
        css={styles.text}
        variant={TYPOGRAPHY_VARIANT_MAPPING[size]}
      >
        {children}
      </Typography>

      {iconPosition === ButtonIconPosition.RIGHT && clonedIconWithTheme}
    </button>
  );
});

export const Button = Object.assign(memo(ButtonForwardRef), {
  Variant: ButtonVariant,
  Size: ButtonSize,
  IconPosition: ButtonIconPosition,
});
