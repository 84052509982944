import { Divider } from '@mui/material';
import { capitalize } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { InfoWithTooltip, Text, TextType } from '@amal-ia/lib-ui';

interface YTODStatItemProps {
  label?: string;
  toolTipMessage?: string;
  price?: string;
  priceColorIndex?: number;
  average?: string;
}

const COLORROTATION = ['hsl(45deg 99% 63%)', 'hsl(208deg 58% 81%)', 'hsl(254deg 55% 86%)'];

export const YTODStatItem = memo(function YTODStatItem({
  label,
  toolTipMessage,
  price,
  priceColorIndex,
  average,
}: YTODStatItemProps) {
  return (
    <div
      style={{
        width: 320,
        display: 'flex',
        margin: '10px 0px',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          height: '40px',
          width: '160px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Text type={TextType.PAYOUT_LABEL}>{capitalize(label)}</Text>
          {toolTipMessage ? <InfoWithTooltip>{toolTipMessage}</InfoWithTooltip> : null}
        </div>
        <Text
          type={TextType.PAYOUT}
          style={{
            marginTop: 7,
            color: COLORROTATION[priceColorIndex || 0],
          }}
        >
          {price}
        </Text>
      </div>
      <Divider />
      {average ? (
        <div
          style={{
            cursor: 'pointer',
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            height: '40px',
            borderLeft: average ? `1px solid ${amaliaTheme.palette.grey['400']}` : 'none',
            width: '180px',
            paddingLeft: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span
              style={{
                color: amaliaTheme.palette.grey['700'],
              }}
            >
              <FormattedMessage defaultMessage="Average" />
            </span>
          </div>
          <div
            style={{
              marginTop: 7,
              color: amaliaTheme.palette.grey['800'],
            }}
          >
            {average}
          </div>
        </div>
      ) : null}
    </div>
  );
});
