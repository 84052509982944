import { Check as CheckIcon } from '@mui/icons-material';
import { Box, CircularProgress, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type CalculationDescriptorStep, CALCULATION_ONGOING_STATUSES } from '@amal-ia/lib-types';
import { Text, TextType } from '@amal-ia/lib-ui';

import { CalculationProgressModalStepBatch } from './CalculationProgressModalStepBatch';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  step: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  stepTop: {
    display: 'flex',
    alignItems: 'center',
  },
  stepTitle: {
    marginLeft: theme.spacing(2),
  },
  stepContent: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    boxSizing: 'border-box',

    '& + $stepContent': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface CalculationProgressModalStepProps {
  step: CalculationDescriptorStep;
  index: number;
}

export const CalculationProgressModalStep = memo(function CalculationProgressModalStep({
  step,
  index,
}: CalculationProgressModalStepProps) {
  const classes = useStyles();

  const [nbCompletedBatches, nbBatches] = useMemo(
    () => [
      (step.batches || []).filter((b) => !CALCULATION_ONGOING_STATUSES.includes(b.status)).length,
      (step.batches || []).length,
    ],
    [step],
  );

  const isStepOngoing = useMemo(
    () => nbCompletedBatches > 0 && nbCompletedBatches < nbBatches,
    [nbCompletedBatches, nbBatches],
  );

  const icon = useMemo(() => {
    if (nbCompletedBatches === 0) {
      return <CircularProgress />;
    }
    if (isStepOngoing) {
      return (
        <CircularProgress
          value={(nbCompletedBatches / nbBatches) * 100}
          variant="determinate"
        />
      );
    }
    return (
      <Fab color="primary">
        <Box sx={{ color: amaliaTheme.palette.common.white, height: '1.5em' }}>
          <CheckIcon />
        </Box>
      </Fab>
    );
  }, [nbCompletedBatches, nbBatches, isStepOngoing]);

  return (
    <Box className={classes.step}>
      <Box className={classes.stepTop}>
        {icon}
        <Text
          className={classes.stepTitle}
          type={TextType.WORKFLOW_STEP}
        >
          <FormattedMessage
            defaultMessage="Step #{stepNumber, number} - {stepPeriod} - {completedBatches, number}/{totalBatches, number}"
            values={{
              stepNumber: index + 1,
              stepPeriod: step.periodName,
              completedBatches: nbCompletedBatches,
              totalBatches: nbBatches,
            }}
          />
        </Text>
      </Box>
      <Box className={classes.stepContent}>
        <Box>
          {step.batches.map((batch, i) => (
            <CalculationProgressModalStepBatch
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              batch={batch}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
});
