import invariant from 'invariant';
import { useMemo } from 'react';

import { type ColumnDefinition, type ColumnDefinitions, type RowData } from '../../table/Table.types';
import { type ColumnSortingState, SortDirection } from '../DataGrid.types';

const DEFAULT_COLUMN_SORTING: ColumnSortingState = [];

const filterRows = <TData extends RowData = RowData>(
  data: TData[],
  columns: ColumnDefinitions<TData>,
  searchText: string,
) =>
  !searchText || !columns.some((column) => column.filterFn)
    ? data
    : data.filter((row) =>
        columns.some((column) => {
          if (!column.filterFn) {
            return false;
          }
          if ('accessorKey' in column) {
            return column.filterFn(row[column.accessorKey], searchText);
          }
          if ('accessorFn' in column) {
            return column.filterFn(column.accessorFn(row), searchText);
          }
          return column.filterFn(row, searchText);
        }),
      );

/**
 * Call the sorting function defined on the column with the correct parameters based on the type of column.
 *
 * @param column Column definition.
 * @param a First row.
 * @param b Second row.
 * @returns Sorting function return value.
 */
const callSortingFn = <TData extends RowData = RowData>(column: ColumnDefinition<TData>, a: TData, b: TData) => {
  invariant(column.sortingFn, 'Sorting function must be defined.');

  return 'accessorKey' in column
    ? column.sortingFn(a[column.accessorKey], b[column.accessorKey])
    : 'accessorFn' in column
      ? column.sortingFn(column.accessorFn(a), column.accessorFn(b))
      : column.sortingFn(a, b);
};

const sortRows = <TData extends RowData = RowData>(
  data: TData[],
  columns: ColumnDefinitions<TData>,
  columnSorting: ColumnSortingState,
) => {
  if (!columnSorting.length) {
    return data;
  }

  return data.toSorted((a, b) => {
    // Go through all columns in the sorting state and apply sorting.
    for (const { id, direction } of columnSorting) {
      const column = columns.find((c) => c.id === id);

      // Ignore columns with no sorting function defined.
      if (!column?.sortingFn) {
        continue;
      }

      const sortReturn = callSortingFn(column, a, b);

      // If the sorting function returned non 0 value, we can break the loop.
      if (sortReturn !== 0) {
        // If the direction is DESC, we need to invert the sorting return.
        return direction === SortDirection.ASC ? sortReturn : -sortReturn;
      }
    }

    return 0;
  });
};

export const useControlledDataGridData = <TData extends RowData = RowData>(
  /** Column definitions. They must be memoed or every cell will be remounted on every render. */
  columns: ColumnDefinitions<TData>,
  /** Rows. */
  data: TData[],
  {
    page = 0,
    pageSize = undefined,
    columnSorting = DEFAULT_COLUMN_SORTING,
    searchText = '',
  }: {
    /** Current page (0-based index). */
    page?: number;
    /** Page size in number of items. */
    pageSize?: number;
    /** Column sorting state. */
    columnSorting?: ColumnSortingState;
    /** Filter text. */
    searchText?: string;
  },
) => {
  // Apply text filtering.
  const filteredData = useMemo(() => filterRows(data, columns, searchText), [data, searchText, columns]);

  // Apply sorting.
  const sortedData = useMemo(
    () => sortRows(filteredData, columns, columnSorting),
    [columns, columnSorting, filteredData],
  );

  // Apply pagination.
  const pagedData = useMemo(
    () =>
      pageSize && (pageSize < sortedData.length || page > 0)
        ? sortedData.slice(page * pageSize, (page + 1) * pageSize)
        : sortedData,
    [page, pageSize, sortedData],
  );

  return { data: pagedData, total: filteredData.length };
};
