import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { amaliaTheme, amaliaThemeInstance } from '@amal-ia/ext/mui/theme';
import { type Period } from '@amal-ia/lib-types';
import { Tooltip } from '@amal-ia/lib-ui';

type BadgeColor = 'green' | 'orange' | 'red';

interface PaymentsTableBadgeProps {
  currentPeriod?: Period;
  forecastDate: string | null;
}

const DOT_SIZE = 10;

const COLORS: Record<BadgeColor, string> = {
  green: amaliaTheme.palette.success.main,
  orange: amaliaTheme.palette.warning.main,
  red: amaliaTheme.palette.error.main,
};

const useStyles = makeStyles({
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE / 2,
    backgroundColor: ({ color }: { color: BadgeColor }) => COLORS[color] || amaliaThemeInstance.palette.common.black,
    marginLeft: '1rem',
  },
});

const PaymentsTableForecastCell = memo(function PaymentsTableForecastCell({
  currentPeriod,
  forecastDate,
}: PaymentsTableBadgeProps) {
  const { formatMessage } = useIntl();

  const { color, label, tooltipText } = useMemo(() => {
    if (!currentPeriod || !forecastDate) {
      return { color: null, label: null, tooltipText: null };
    }

    const momentForecast = moment.utc(forecastDate);
    const forecastUnixTimestamp = +momentForecast.format('X');

    let computedColor: BadgeColor;
    let computedTooltipText: string;

    if (forecastUnixTimestamp > currentPeriod.endDate) {
      // If forecast is after the current period, we're clear.
      computedTooltipText = formatMessage({ defaultMessage: "We're before the forecasted date, all clear!" });
      computedColor = 'green';
    } else if (forecastUnixTimestamp >= currentPeriod.startDate && forecastUnixTimestamp < currentPeriod.endDate) {
      // In the current period, it starts to smell the paté.
      computedTooltipText = formatMessage({ defaultMessage: 'Forecast date is in the current period' });
      computedColor = 'orange';
    } else {
      // Dead.
      computedTooltipText = formatMessage({ defaultMessage: 'Forecast date is in the past!' });
      computedColor = 'red';
    }

    return {
      color: computedColor,
      label: momentForecast.format('YYYY-MM-DD'),
      tooltipText: computedTooltipText,
    };
  }, [currentPeriod, forecastDate, formatMessage]);

  const classes = useStyles({ color });

  return color && tooltipText ? (
    <Tooltip title={tooltipText}>
      <Box
        alignItems="center"
        display="flex"
      >
        {label}
        <div className={classes.dot} />
      </Box>
    </Tooltip>
  ) : (
    <FormattedMessage
      defaultMessage="-"
      description="No value"
    />
  );
});

export default PaymentsTableForecastCell;
