import { Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { RuleType } from '@amal-ia/compensation-definition/shared/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { type Adjustment, type Statement } from '@amal-ia/lib-types';
import { RuleAccordion } from '@amal-ia/lib-ui-business';

import AdjustmentsTable from './AdjustementsTable/AdjustmentsTable';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  adjustementsAccordion: {
    '&, &.Mui-expanded:first-child': {
      marginTop: theme.spacing(4),
    },
  },
  adjustmentCategory: {
    '&.MuiGrid2-item': {
      padding: `0 ${theme.spacing(0.5)}`,
      borderLeft: '3px solid transparent',
    },
  },
}));

interface StatementAdjustmentsListProps {
  statement: Statement;
  currencyRate: number;
  currencySymbol: CurrencySymbolsEnum;
  setSelectAdjustment: (adjustment: Adjustment) => void;
}

const StatementAdjustmentsList = memo(function StatementAdjustmentsList({
  statement,
  currencySymbol,
  currencyRate,
  setSelectAdjustment,
}: StatementAdjustmentsListProps) {
  const classes = useStyles();

  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(false, 'ruleExpanded');

  const total = useMemo(
    () => (statement.adjustments || []).reduce((acc, adjustment) => acc + adjustment.amount, 0),
    [statement],
  );

  return (
    <Grid
      className={classes.adjustmentCategory}
      xs={12}
    >
      {statement.adjustments && statement.adjustments.length > 0 ? (
        <RuleAccordion
          key="Adjustments"
          className={classes.adjustementsAccordion}
          isExpanded={isRuleExpanded}
          label={<FormattedMessage defaultMessage="Adjustments" />}
          machineName="adjustments"
          toggleExpanded={toggleRuleExpanded}
          mainKpi={{
            value: { amount: total, currencyRate, currencySymbol },
            type: RuleType.PAYOUT,
          }}
        >
          <AdjustmentsTable
            adjustments={statement.adjustments}
            setSelectAdjustment={setSelectAdjustment}
          />
        </RuleAccordion>
      ) : null}
    </Grid>
  );
});

export default StatementAdjustmentsList;
