import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type PaymentCategory } from '@amal-ia/lib-types';
import {
  InfoWithTooltip,
  Text,
  TextType,
  PAYMENT_CATEGORIES_LABELS,
  PAYMENT_CATEGORIES_TOOLTIPS,
} from '@amal-ia/lib-ui';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  element: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    '&$active': {
      borderBottom: `2px solid ${theme.palette.tertiary.main}`,
    },
  },
  active: {},
}));

interface MultiPayoutTabProps {
  view: PaymentCategory;
  setView: (view: PaymentCategory) => void;
  overridesLabels?: {
    tabs?: {
      [PaymentCategory.achievement]?: string;
    };
  };
  paymentCategory: PaymentCategory;
}

export const MultiPayoutTab = memo(function MultiPayoutTab({
  view,
  setView,
  paymentCategory,
  overridesLabels,
}: MultiPayoutTabProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const setViewProxy = useCallback(() => setView(paymentCategory), [setView, paymentCategory]);

  const label: string = useMemo(() => {
    if (
      Object.keys(overridesLabels?.tabs || {}).includes(paymentCategory) &&
      overridesLabels?.tabs?.[paymentCategory]
    ) {
      return overridesLabels?.tabs?.[paymentCategory];
    }
    return formatMessage(PAYMENT_CATEGORIES_LABELS[paymentCategory]);
  }, [formatMessage, overridesLabels, paymentCategory]);

  return (
    <Box
      key={paymentCategory}
      className={clsx(classes.element, view === paymentCategory && classes.active)}
      onClick={setViewProxy}
    >
      <Text type={TextType.PAYOUT_LABEL}>{label}</Text>
      <InfoWithTooltip>{formatMessage(PAYMENT_CATEGORIES_TOOLTIPS[paymentCategory])}</InfoWithTooltip>
    </Box>
  );
});
