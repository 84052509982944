const TICK_LEVELS = 4;

/**
 * Maps the payout ticks based on the max amount.
 * We want to have 4 levels of ticks with even numbers.
 * @param maxAmount
 */
export const mapPayoutTickLevels = (maxAmount: number) => {
  // Example maxAmount = 244000.
  // logScale = 5
  const logScale = Math.floor(Math.log10(maxAmount));

  // Find the nearest number multiple of 4 on the same log scale as maxAmount: this will be our max threshold.
  // Example: 244000 => 250000
  // Example: 500000 => 500000
  // Example: 650000 => 750000
  const maxThreshold = Math.ceil(maxAmount / 10 ** logScale) * 10 ** logScale;

  // We want equals steps between each tick.
  const stepSize = maxThreshold / TICK_LEVELS;

  // Ticks = [100000, 200000, 300000]
  return Array.from({ length: TICK_LEVELS }, (_, i) => (i + 1) * stepSize);
};
