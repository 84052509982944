import { createContext } from 'react';

import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type Period } from '@amal-ia/lib-types';

export interface PaymentsTableContextInterface {
  companyFrequency?: PeriodFrequencyEnum;
  currentPeriod?: Period;

  // Tracing
  firstPrintedColumn?: string;
}

const PaymentsTableContext = createContext<PaymentsTableContextInterface>({} as PaymentsTableContextInterface);

export default PaymentsTableContext;
