import { css, type Theme } from '@emotion/react';
import { memo, type ReactNode } from 'react';

export const container = (theme: Theme) => css`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 24px 40px;

  gap: 24px;

  background-color: #ffffff;

  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.soft};
`;

type WidgetProps = {
  children: ReactNode;
};
export const DashboardWidget = memo(function Widget({ children }: WidgetProps) {
  return <div css={container}>{children}</div>;
});
