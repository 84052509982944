import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('dayNumber', AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the day of the month of a given date';

func.params = [
  {
    name: 'date',
    description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
  },
];

func.examples = [
  {
    desc: 'Returns 16',
    formula: 'dayNumber(toDate("2022-09-16"))' as AmaliaFormula,
    result: 16,
  },
  {
    desc: 'Returns the day of the month of Close Date.',
    formula: 'dayNumber(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null => moment(date, 'X').date();

export default func;
