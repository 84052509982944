import { keyBy } from 'lodash';

import { type AmaliaFormula } from '@amal-ia/amalia-lang/formula/shared/types';
import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import {
  HidableElementVisibility,
  type PlanRule,
  type PlanRuleCategory,
  RuleType,
  type Filter,
} from '@amal-ia/compensation-definition/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import {
  type CustomObjectDefinition,
  CustomObjectDefinitionType,
  DuplicatesOptions,
} from '@amal-ia/data-capture/models/types';
import { type Plan, type Variable, VariableObjectsEnum } from '@amal-ia/lib-types';

export const planMockRuleCategories: PlanRuleCategory[] = [
  { id: 'f075c380-1b93-4567-8181-c7aef534cf33', name: 'Payout', index: 0 },
  { id: '2df62bd0-064f-48fc-a72a-74506197ef3c', name: 'Total achieved', index: 0 },
];

export const planMockRules: PlanRule[] = [
  {
    id: 'ff390448-cf48-4ac4-af2e-d609fd329825',
    name: 'KAM Commission',
    ruleMachineName: 'kam commission',
    index: 0,
    type: RuleType.PAYOUT,
    formula: '' as AmaliaFormula,
    filtersToDisplay: [
      {
        id: '38151bc7-a42f-4190-99f6-0bf672fe6c17',
        displayStatus: HidableElementVisibility.ON_DISPLAY,
        fieldsToDisplay: [
          { name: 'id', displayStatus: HidableElementVisibility.ON_DISPLAY },
          { name: 'name', displayStatus: HidableElementVisibility.ON_DISPLAY },
          { name: 'stageName', displayStatus: HidableElementVisibility.AVAILABLE },
          { name: 'amount', displayStatus: HidableElementVisibility.AVAILABLE },
        ],
      },
    ],
    kpisToDisplay: [
      { id: '53302ef4-3e63-4ee1-9f15-e8eefe1be44a', displayStatus: HidableElementVisibility.ON_DISPLAY },
      { id: '36973284-04d9-4085-9b02-a8178c556f54', displayStatus: HidableElementVisibility.AVAILABLE },
      { id: 'a817cc13-6673-4790-a30f-3b1bbfe7be2e', displayStatus: HidableElementVisibility.ON_DISPLAY },
    ],
    charts: [],
    categories: [
      { id: 'f075c380-1b93-4567-8181-c7aef534cf33', name: 'Payout', index: 0 },
      { id: '2df62bd0-064f-48fc-a72a-74506197ef3c', name: 'Total achieved', index: 0 },
    ],
  },
  {
    id: '37aa9435-4aea-470a-919b-10aae5f8195d',
    name: 'KAM Commission from Future Monthly Invoicing',
    ruleMachineName: 'KAM Commission from future monthly invoicing',
    filtersToDisplay: [],
    index: 0,
    type: RuleType.PAYOUT,
    formula: '' as AmaliaFormula,
    kpisToDisplay: [],
    charts: [],
    categories: [{ id: '2df62bd0-064f-48fc-a72a-74506197ef3c', name: 'Total achieved', index: 0 }],
  },
  {
    id: 'e61b1ed1-f490-4c05-afcf-30a6744cb92b',
    name: 'KAM Commission from Past Monthly Invoicing Q-1',
    ruleMachineName: 'KAM Commission from Past Monthly invoicing q-1',
    filtersToDisplay: [],
    index: 0,
    type: RuleType.PAYOUT,
    formula: '' as AmaliaFormula,
    kpisToDisplay: [],
    charts: [],
    categories: [{ id: 'f075c380-1b93-4567-8181-c7aef534cf33', name: 'Payout', index: 0 }],
  },
  {
    id: 'c2e74798-36d1-4f8b-af20-67262f399d18',
    name: 'KAM Commission from Past Monthly Invoicing Q-2',
    ruleMachineName: 'KAM Commission from Past Monthly invoicing q-2',
    filtersToDisplay: [],
    index: 0,
    type: RuleType.PAYOUT,
    formula: '' as AmaliaFormula,
    kpisToDisplay: [],
    charts: [],
    categories: [{ id: 'f075c380-1b93-4567-8181-c7aef534cf33', name: 'Payout', index: 0 }],
  },
  {
    id: 'c2e74790-36d1-4f8b-af20-67262f399d18',
    name: 'A rule without category',
    ruleMachineName: 'a rule without category',
    filtersToDisplay: [],
    index: 0,
    type: RuleType.PAYOUT,
    formula: '' as AmaliaFormula,
    kpisToDisplay: [],
    charts: [],
    categories: [],
  },
  {
    id: 'c2e74799-36d1-4f8b-af20-67262f399d18',
    name: 'Another rule without category',
    ruleMachineName: 'another rule without category',
    filtersToDisplay: [],
    index: 0,
    type: RuleType.PAYOUT,
    formula: '' as AmaliaFormula,
    kpisToDisplay: [],
    charts: [],
    categories: [],
  },
];

export const planMockPlan: Plan = {
  id: '129be6c1-1fd8-4dc4-a4d6-9bcaf235a20b',
  name: 'SENIOR KAM 2019-2020',
  rules: [],
  highlightedKpis: [],
  frequency: PeriodFrequencyEnum.month,
};

export const planMockObjectDefinition: CustomObjectDefinition = {
  id: '860ec547-66dc-4837-b303-d214bebe60a2',
  externalIds: ['id'],
  name: 'Opportunity',
  machineName: 'opportunity',
  properties: {
    id: {
      name: 'Opportunity ID',
      machineName: 'id',
      format: FormatsEnum.text,
      isRequired: true,
    },
    accountId: {
      name: 'Account ID',
      machineName: 'accountId',
      format: FormatsEnum.text,
      isRequired: false,
    },
    name: {
      name: 'Name',
      machineName: 'name',
      format: FormatsEnum.text,
      isRequired: false,
    },
    stageName: {
      name: 'Stage',
      machineName: 'stageName',
      format: FormatsEnum.text,
      isRequired: false,
    },
    amount: {
      name: 'Amount',
      machineName: 'amount',
      format: FormatsEnum.currency,
      isRequired: true,
    },
    closeDate: {
      name: 'Close Date',
      machineName: 'closeDate',
      format: FormatsEnum.date,
      isRequired: false,
    },
    type: {
      name: 'Opportunity Type',
      machineName: 'type',
      format: FormatsEnum.text,
      isRequired: false,
    },
    leadSource: {
      name: 'Lead Source',
      machineName: 'leadSource',
      format: FormatsEnum.text,
      isRequired: false,
    },
    isClosed: {
      name: 'Closed',
      machineName: 'isClosed',
      format: FormatsEnum.boolean,
      isRequired: false,
    },
    isWon: {
      name: 'Won',
      machineName: 'isWon',
      format: FormatsEnum.boolean,
      isRequired: false,
    },
    currencyIsoCode: {
      name: 'Opportunity Currency',
      machineName: 'currencyIsoCode',
      format: FormatsEnum.text,
      isRequired: false,
    },
    ownerId: {
      name: 'Owner ID',
      machineName: 'ownerId',
      format: FormatsEnum.text,
      isRequired: false,
    },
    createdDate: {
      name: 'Created Date',
      machineName: 'createdDate',
      format: FormatsEnum['date-time'],
      isRequired: false,
    },
    lastModifiedDate: {
      name: 'Last Modified Date',
      machineName: 'lastModifiedDate',
      format: FormatsEnum['date-time'],
      isRequired: false,
    },
    contractTermMonthsC: {
      name: 'Contract Term (months)',
      machineName: 'contractTermMonthsC',
      format: FormatsEnum.number,
      isRequired: false,
    },
    paymentC: {
      name: 'Invoicing Frequency',
      machineName: 'paymentC',
      format: FormatsEnum.text,
      isRequired: false,
    },
    renewedSubscriptionsAmountC: {
      name: 'Renewed Subscriptions Amount',
      machineName: 'renewedSubscriptionsAmountC',
      format: FormatsEnum.currency,
      isRequired: true,
    },
    sofactoappPeriodiciteC: {
      name: 'Frequency',
      machineName: 'sofactoappPeriodiciteC',
      format: FormatsEnum.text,
      isRequired: false,
    },
    jppAmountC: {
      name: 'ABR',
      machineName: 'jppAmountC',
      format: FormatsEnum.currency,
      isRequired: true,
    },
    previousOppsNameC: {
      name: 'Previous opps name',
      machineName: 'previousOppsNameC',
      format: FormatsEnum.text,
      isRequired: false,
    },
    licenceMrr2C: {
      name: 'Licence MRR',
      machineName: 'licenceMrr2C',
      format: FormatsEnum.currency,
      isRequired: true,
    },
    supportMrr2C: {
      name: 'Support MRR',
      machineName: 'supportMrr2C',
      format: FormatsEnum.currency,
      isRequired: true,
    },
    previousContractEndDateC: {
      name: 'Previous Contract End Date',
      machineName: 'previousContractEndDateC',
      format: FormatsEnum.date,
      isRequired: false,
    },
    expansionC: {
      name: 'Expansion',
      machineName: 'expansionC',
      format: FormatsEnum.boolean,
      isRequired: false,
    },
  },
  schema: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $id: 'http://amalia.io/opportunity.schema.json',
    title: 'Opportunity',
    description: '',
    type: 'object',
    required: ['id'],
    additionalProperties: false,
    properties: {
      id: {
        $id: '#/properties/id',
        type: 'string',
        description: 'Opportunity ID',
        title: 'Id',
      },
      accountId: {
        $id: '#/properties/accountId',
        type: ['string', 'null'],
        default: null,
        description: 'Account ID',
        title: 'AccountId',
      },
      name: {
        $id: '#/properties/name',
        type: ['string', 'null'],
        default: null,
        description: 'Name',
        title: 'Name',
      },
      stageName: {
        $id: '#/properties/stageName',
        type: ['string', 'null'],
        default: null,
        description: 'Stage',
        title: 'StageName',
      },
      amount: {
        $ref: '#/definitions/currency',
        $id: '#/properties/amount',
        description: 'Amount',
        title: 'Amount',
      },
      closeDate: {
        $id: '#/properties/closeDate',
        type: ['string', 'null'],
        default: null,
        description: 'Close Date',
        title: 'CloseDate',
        format: 'date',
      },
      type: {
        $id: '#/properties/type',
        type: ['string', 'null'],
        default: null,
        description: 'Opportunity Type',
        title: 'Type',
      },
      leadSource: {
        $id: '#/properties/leadSource',
        type: ['string', 'null'],
        default: null,
        description: 'Lead Source',
        title: 'LeadSource',
      },
      isClosed: {
        $id: '#/properties/isClosed',
        type: ['boolean', 'null'],
        default: null,
        description: 'Closed',
        title: 'IsClosed',
      },
      isWon: {
        $id: '#/properties/isWon',
        type: ['boolean', 'null'],
        default: null,
        description: 'Won',
        title: 'IsWon',
      },
      currencyIsoCode: {
        $id: '#/properties/currencyIsoCode',
        type: ['string', 'null'],
        default: null,
        description: 'Opportunity Currency',
        title: 'CurrencyIsoCode',
      },
      ownerId: {
        $id: '#/properties/ownerId',
        type: ['string', 'null'],
        default: null,
        description: 'Owner ID',
        title: 'OwnerId',
      },
      createdDate: {
        $id: '#/properties/createdDate',
        type: ['string', 'null'],
        default: null,
        description: 'Created Date',
        title: 'CreatedDate',
        format: 'date-time',
      },
      lastModifiedDate: {
        $id: '#/properties/lastModifiedDate',
        type: ['string', 'null'],
        default: null,
        description: 'Last Modified Date',
        title: 'LastModifiedDate',
        format: 'date-time',
      },
      contractTermMonthsC: {
        $id: '#/properties/contractTermMonthsC',
        type: ['number', 'null'],
        default: null,
        description: 'Contract Term (months)',
        title: 'Contract_Term_months__c',
      },
      paymentC: {
        $id: '#/properties/paymentC',
        type: ['string', 'null'],
        default: null,
        description: 'Invoicing Frequency',
        title: 'Payment__c',
      },
      renewedSubscriptionsAmountC: {
        $ref: '#/definitions/currency',
        $id: '#/properties/renewedSubscriptionsAmountC',
        description: 'Renewed Subscriptions Amount',
        title: 'Renewed_Subscriptions_Amount__c',
      },
      sofactoappPeriodiciteC: {
        $id: '#/properties/sofactoappPeriodiciteC',
        type: ['string', 'null'],
        default: null,
        description: 'Frequency',
        title: 'sofactoapp__Periodicite__c',
      },
      jppAmountC: {
        $ref: '#/definitions/currency',
        $id: '#/properties/jppAmountC',
        description: 'ABR',
        title: 'JPP_Amount__c',
      },
      previousOppsNameC: {
        $id: '#/properties/previousOppsNameC',
        type: ['string', 'null'],
        default: null,
        description: 'Previous opps name',
        title: 'Previous_opps_name__c',
      },
      licenceMrr2C: {
        $ref: '#/definitions/currency',
        $id: '#/properties/licenceMrr2C',
        description: 'Licence MRR',
        title: 'Licence_MRR2__c',
      },
      supportMrr2C: {
        $ref: '#/definitions/currency',
        $id: '#/properties/supportMrr2C',
        description: 'Support MRR',
        title: 'Support_MRR2__c',
      },
      previousContractEndDateC: {
        $id: '#/properties/previousContractEndDateC',
        type: ['string', 'null'],
        default: null,
        description: 'Previous Contract End Date',
        title: 'Previous_Contract_End_Date__c',
        format: 'date',
      },
      expansionC: {
        $id: '#/properties/expansionC',
        type: ['boolean', 'null'],
        default: null,
        description: 'Expansion',
        title: 'Expansion__c',
      },
    },
    definitions: {
      currency: {
        type: 'object',
        properties: {
          symbol: {
            type: 'string',
            enum: [
              'AED',
              'AFN',
              'ALL',
              'AMD',
              'ANG',
              'AOA',
              'ARS',
              'AUD',
              'AWG',
              'AZN',
              'BAM',
              'BBD',
              'BDT',
              'BGN',
              'BHD',
              'BIF',
              'BMD',
              'BND',
              'BOB',
              'BRL',
              'BSD',
              'BTC',
              'BTN',
              'BWP',
              'BYN',
              'BYR',
              'BZD',
              'CAD',
              'CDF',
              'CHF',
              'CLF',
              'CLP',
              'CNY',
              'COP',
              'CRC',
              'CUC',
              'CUP',
              'CVE',
              'CZK',
              'DJF',
              'DKK',
              'DOP',
              'DZD',
              'EGP',
              'ERN',
              'ETB',
              'EUR',
              'FJD',
              'FKP',
              'GBP',
              'GEL',
              'GGP',
              'GHS',
              'GIP',
              'GMD',
              'GNF',
              'GTQ',
              'GYD',
              'HKD',
              'HNL',
              'HRK',
              'HTG',
              'HUF',
              'IDR',
              'ILS',
              'IMP',
              'INR',
              'IQD',
              'IRR',
              'ISK',
              'JEP',
              'JMD',
              'JOD',
              'JPY',
              'KES',
              'KGS',
              'KHR',
              'KMF',
              'KPW',
              'KRW',
              'KWD',
              'KYD',
              'KZT',
              'LAK',
              'LBP',
              'LKR',
              'LRD',
              'LSL',
              'LTL',
              'LVL',
              'LYD',
              'MAD',
              'MDL',
              'MGA',
              'MKD',
              'MMK',
              'MNT',
              'MOP',
              'MRO',
              'MUR',
              'MVR',
              'MWK',
              'MXN',
              'MYR',
              'MZN',
              'NAD',
              'NGN',
              'NIO',
              'NOK',
              'NPR',
              'NZD',
              'OMR',
              'PAB',
              'PEN',
              'PGK',
              'PHP',
              'PKR',
              'PLN',
              'PYG',
              'QAR',
              'RON',
              'RSD',
              'RUB',
              'RWF',
              'SAR',
              'SBD',
              'SCR',
              'SDG',
              'SEK',
              'SGD',
              'SHP',
              'SLL',
              'SOS',
              'SRD',
              'STD',
              'SVC',
              'SYP',
              'SZL',
              'THB',
              'TJS',
              'TMT',
              'TND',
              'TOP',
              'TRY',
              'TTD',
              'TWD',
              'TZS',
              'UAH',
              'UGX',
              'USD',
              'UYU',
              'UZS',
              'VEF',
              'VND',
              'VUV',
              'WST',
              'XAF',
              'XAG',
              'XAU',
              'XCD',
              'XDR',
              'XOF',
              'XPF',
              'YER',
              'ZAR',
              'ZMK',
              'ZMW',
              'ZWL',
            ],
            default: 'EUR',
          },
          value: {
            type: ['number', 'null'],
            default: null,
          },
        },
      },
    },
  },
  preferences: {
    duplicates: DuplicatesOptions.OVERWRITE,
  },
  type: CustomObjectDefinitionType.SALESFORCE,
};

export const planMockFilters: Filter[] = [
  {
    id: '38151bc7-a42f-4190-99f6-0bf672fe6c17',
    name: 'Closed In Quarter\t',
    machineName: 'closedInQuarter',
    condition:
      'opportunity.closeDate >= statementPeriod.startDate and opportunity.closeDate <= statementPeriod.endDate and opportunity.ownerId = user.externalId and opportunity.isWon = true' as AmaliaFormula,
    object: planMockObjectDefinition,
  },
  {
    id: 'af9f266b-03c4-4ddf-8295-c4ea0b3c874e',
    name: 'Closed Month 1\t',
    machineName: 'closedMonth1',
    condition:
      'opportunity.closeDate >= statementPeriod.startDate and opportunity.closeDate <= endOfMonth(statementPeriod.startDate) and opportunity.ownerId = user.externalId and opportunity.isWon = true' as AmaliaFormula,
    object: planMockObjectDefinition,
  },
];

const planMockVariables: Variable[] = [
  {
    id: '53302ef4-3e63-4ee1-9f15-e8eefe1be44a',
    name: 'KAM Allowed Churn',
    machineName: 'kamAllowedChurn',
    formula: '',
    format: FormatsEnum.percent,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.user,
  },
  {
    id: '36973284-04d9-4085-9b02-a8178c556f54',
    name: 'KAM Customer Base',
    machineName: 'kamCustomerBase',
    formula: '',
    format: FormatsEnum.currency,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.user,
  },
  {
    id: 'bcee6d61-b1d4-4cd5-8d26-62ab818587a3',
    name: 'KAM Ramp CAP Bonus Tab\t',
    machineName: 'kamRampCapBonusTab',
    formula: '[[0, 3, 1.4], [3, 5, 1.2], [5, Infinity, 0]]',
    format: FormatsEnum.table,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.statement,
  },
  {
    id: 'a817cc13-6673-4790-a30f-3b1bbfe7be2e',
    name: 'KAM Ramp Tab\t',
    machineName: 'kamRampTab',
    formula: '[[0, 1, 0], [1, 2, 1 / 6], [2, 3, 1 / 2], [3, 4, 5 / 6], [4, Infinity, 1]]',
    format: FormatsEnum.table,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.statement,
  },
  {
    id: 'fff9a8c3-57c8-409c-a555-e40079461158',
    name: 'Target Achievement',
    machineName: 'targetAchievement',
    formula: 'TIER(statement.var1, statement.commissionTable)',
    format: FormatsEnum.currency,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.statement,
  },
];

export const planMockVariablesMap = keyBy(planMockVariables, 'id');
export const planMockFiltersMap = keyBy(planMockFilters, 'id');
export const planMockCustomObjectDefinitionsMap = keyBy([planMockObjectDefinition], 'id');
