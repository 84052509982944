import { css, type Theme } from '@emotion/react';
import { memo } from 'react';

import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { reformatDateString } from '@amal-ia/lib-types';

import { getDefinitionNameFromDataKey, type ChartSeries } from '../chartSeries';

import { TooltipItem } from './TooltipItem';

type TooltipProps = {
  seriesMetadata?: Record<string, ChartSeries>;
  currency: CurrencySymbolsEnum;
  active?: boolean;
  payload?: { name: string; value: number; unit: string }[];
  label?: string;
  frequency?: PeriodFrequencyEnum;
};

const tooltipContainerCss = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${theme.ds.colors.gray[0]};
  padding: 16px 20px;
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.soft};
  border: 1px solid ${theme.ds.colors.gray[100]};
`;

export const Tooltip = memo(function Tooltip({
  active,
  payload,
  label,
  seriesMetadata,
  currency,
  frequency,
}: TooltipProps) {
  if (active && !!payload?.length) {
    const monthLabel = reformatDateString(
      label,
      'MM/YYYY',
      frequency === PeriodFrequencyEnum.quarter ? '[Q]Q YYYY' : 'MMMM YYYY',
    );

    return (
      <div css={tooltipContainerCss}>
        <Typography variant={TypographyVariant.BODY_SMALL_BOLD}>{monthLabel}</Typography>
        {payload.map((item) => (
          <TooltipItem
            key={item.name}
            currency={currency}
            series={seriesMetadata?.[getDefinitionNameFromDataKey(item.name)]}
            value={item.value}
          />
        ))}
      </div>
    );
  }

  return null;
});
