import { type Theme, css } from '@emotion/react';

export const leftBarActions = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 2px;

  padding: 4px 6px;

  background-color: ${theme.ds.colors.gray[0]};
`;

export const stickLeft = css`
  margin-right: auto;

  &:empty {
    display: none;
  }
`;
