import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import {
  selectPaymentsByCategoryForCurrentStatement,
  selectStatementPaymentAmountByCategory,
  selectCurrentPeriod,
  selectCurrentStatement,
} from '@amal-ia/frontend/web-data-layers';
import { type PaymentCategory } from '@amal-ia/lib-types';
import { PAYMENT_CATEGORIES_LABELS } from '@amal-ia/lib-ui';
import { RuleAccordion } from '@amal-ia/lib-ui-business';

import PaymentsTable from './PaymentsTable/PaymentsTable';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
}));

interface StatementPaymentsProps {
  category: PaymentCategory;
}

const StatementPayments = memo(function StatementPayments({ category }: StatementPaymentsProps) {
  const classes = useStyles();

  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(true, 'ruleExpanded');

  const statement = useSelector(selectCurrentStatement);
  const currentPeriod = useSelector(selectCurrentPeriod);
  const paymentsByCategory = useSelector(selectPaymentsByCategoryForCurrentStatement);
  const paymentAmountsByCategory = useSelector(selectStatementPaymentAmountByCategory);

  const mainKpi = useMemo(
    () => ({
      value: {
        currencySymbol: statement.currency,
        amount: paymentAmountsByCategory[category],
      },
      type: category,
    }),
    [statement, paymentAmountsByCategory, category],
  );

  const paymentsToShow = useMemo(
    // We want payment for the selected category that have a value and > 0
    () => (paymentsByCategory[category] || []).filter((p) => !!p.value),
    [paymentsByCategory, category],
  );

  return (
    <Box className={classes.root}>
      <RuleAccordion
        isExpanded={isRuleExpanded}
        label={<FormattedMessage {...PAYMENT_CATEGORIES_LABELS[category]} />}
        machineName={category}
        mainKpi={mainKpi}
        toggleExpanded={toggleRuleExpanded}
      >
        <PaymentsTable
          category={category}
          currentPeriod={currentPeriod}
          rows={paymentsToShow}
          statement={statement}
        />
      </RuleAccordion>
    </Box>
  );
});

export default StatementPayments;
