import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';

import { todosRoutesMessages } from './todos.routes.messages';

const TodosIndex = lazy(() => import('./TodoTable/TodosTableContainer'));

export const TodosRoutes = memo(function TodosRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <Switch>
        <CompanyRoute
          component={TodosIndex}
          path={routes.TODOS}
          title={formatMessage(todosRoutesMessages.TODOS)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
