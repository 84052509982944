import { createSelector } from 'reselect';

import { type Rule, type RootState } from '@amal-ia/lib-types';

export const selectRules = (state: RootState): Rule[] => state.rules.list;

export const selectRulesSortedByName = createSelector([selectRules], (rules) =>
  [...(rules || [])].sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectIsRulesLoading = (state: RootState): boolean => !!state.rules.isLoading;
