import { Fragment, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute, ProtectedRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ChangelogView } from '@amal-ia/frontend/views/amalia-meta/changelog';
import { SuperAdminRoutes } from '@amal-ia/frontend/views/amalia-meta/superadmin';
import { DesignerRoutes } from '@amal-ia/frontend/views/compensation-definition/designer';
import { PlansRoutes, QuotasRoutes, TeamRoutes } from '@amal-ia/frontend/views/compensation-definition/management';
import { DataExports, StatementsRoutes } from '@amal-ia/frontend/views/compensation-results/statements';
import { TodosRoutes } from '@amal-ia/frontend/views/compensation-results/todos-table';
import { CustomObjectRoutes, OAuthCallback } from '@amal-ia/frontend/views/data-capture/data';
import { FlowRoutes } from '@amal-ia/frontend/views/data-capture/flows';
import { PaymentView } from '@amal-ia/frontend/views/reporting/commission-report';
import { CustomReportRoutes } from '@amal-ia/frontend/views/reporting/custom-reports';
import { DashboardView, HomeRedirectView, HomeView } from '@amal-ia/frontend/views/reporting/homepage';
import { OverwritesRoutes } from '@amal-ia/frontend/views/reporting/overwrites-report';
import { AuditRoutes } from '@amal-ia/frontend/views/tenants/audit';
import {
  CompanyRoutes,
  IntegrationOauthCallback,
  PaymentsLockView,
} from '@amal-ia/frontend/views/tenants/company-settings';
import { PlanAgreementsRoutes } from '@amal-ia/frontend/views/tenants/plan-agreements';
import { DirectoryPage, NewInvitationPage, SettingsPage } from '@amal-ia/frontend/views/tenants/user';
import { checkLocalStorageOnLoad, useFeatureFlag } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum, userProfileSubject } from '@amal-ia/lib-rbac';
import { CompanyFeatureFlags } from '@amal-ia/tenants/shared/types';
import { MemberProfileView } from '@amal-ia/tenants/users/profile/views/member';
import { CurrentUserProfileView } from '@amal-ia/tenants/users/profile/views/profile';
import { tenantUsersRoutes } from '@amal-ia/tenants/users/shared/routes';

const App = function App() {
  const { data: user } = useCurrentUser();
  const ability = useAbilityContext();
  const { formatMessage } = useIntl();

  const { isFeatureEnabled: isRepHomeFFEnabled } = useFeatureFlag(CompanyFeatureFlags.REP_HOME);

  const canSeeCompanyProperties = useMemo(
    () =>
      ability.can(ActionsEnum.view_rates, SubjectsEnum.Company) ||
      ability.can(ActionsEnum.view_directory, SubjectsEnum.Company) ||
      ability.can(ActionsEnum.view_settings, SubjectsEnum.Company),
    [ability],
  );

  useEffect(
    // Clear localstorage if company is different
    () => user && checkLocalStorageOnLoad(user),
    [user],
  );

  return (
    <Switch>
      <Route
        path={routes.AUTH_CONNECTOR_CALLBACK}
        render={() => (
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
            <OAuthCallback />
          </Fragment>
        )}
      />
      <Route
        path={routes.AUTH_INTEGRATION_CALLBACK}
        render={() => (
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
            <IntegrationOauthCallback />
          </Fragment>
        )}
      />
      <CompanyRoute
        component={HomeView}
        path={routes.HOME}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.RepHome) || !isRepHomeFFEnabled}
        title={formatMessage({ defaultMessage: 'Home' })}
        user={user}
      />
      <CompanyRoute
        component={DataExports}
        path={routes.DATA_EXPORT}
        title={formatMessage({ defaultMessage: 'Data export' })}
        user={user}
      />
      <CompanyRoute
        component={DesignerRoutes}
        path={routes.DESIGNER}
        title={formatMessage({ defaultMessage: 'Designer' })}
        user={user}
      />
      <CompanyRoute
        component={PlansRoutes}
        path={routes.PLANS}
        title={formatMessage({ defaultMessage: 'Plans' })}
        user={user}
      />
      <CompanyRoute
        component={PlansRoutes}
        path={routes.PLAN_OVERVIEW}
        title={formatMessage({ defaultMessage: 'Plans' })}
        user={user}
      />
      <CompanyRoute
        component={TeamRoutes}
        path={routes.TEAM_LIST}
        title={formatMessage({ defaultMessage: 'Teams' })}
        user={user}
      />
      <CompanyRoute
        component={QuotasRoutes}
        path={routes.QUOTAS}
        title={formatMessage({ defaultMessage: 'Quotas' })}
        user={user}
      />
      <CompanyRoute
        component={AuditRoutes}
        path={routes.AUDIT}
        title={formatMessage({ defaultMessage: 'Audit' })}
        user={user}
      />
      <CompanyRoute
        component={PaymentsLockView}
        path={routes.COMPANY_PAYMENTS_LOCK}
        user={user}
      />
      <CompanyRoute
        component={PlanAgreementsRoutes}
        path={routes.PLAN_AGREEMENTS}
        user={user}
      />
      {/* AMALIA */}
      <CompanyRoute
        component={ChangelogView}
        path={routes.CHANGELOG}
        user={user}
      />
      {/* REPORTING */}
      <CompanyRoute
        component={OverwritesRoutes}
        path={routes.OVERWRITES}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Overwrite_Recap)}
        title={formatMessage({ defaultMessage: 'Overwrites' })}
        user={user}
      />
      <CompanyRoute
        component={PaymentView}
        path={routes.COMMISSION_REPORT}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Payment)}
        title={formatMessage({ defaultMessage: 'Payments' })}
        user={user}
      />

      <ProtectedRoute
        key="tenantUsersRoutes.PROFILE"
        path={tenantUsersRoutes.PROFILE_PAGE}
        user={user}
        render={() => (
          <Layout currentPage="directory">
            <CurrentUserProfileView />
          </Layout>
        )}
      />

      <CompanyRoute
        key="tenantUsersRoutes.MEMBER_PAGE"
        path={tenantUsersRoutes.MEMBER_PAGE}
        user={user}
        render={() => (
          <Layout currentPage="directory">
            <MemberProfileView />
          </Layout>
        )}
        shouldRedirectToHome={(params) => {
          const { memberId } = params.match.params ?? {};
          return ability.cannot(ActionsEnum.view, userProfileSubject({ id: memberId }));
        }}
      />

      <ProtectedRoute
        key="routes.SETTINGS"
        path={routes.SETTINGS}
        title={formatMessage({ defaultMessage: 'Settings' })}
        user={user}
        render={() => (
          <Layout>
            <SettingsPage />
          </Layout>
        )}
      />

      <CompanyRoute
        key="routes.COMPANY_DIRECTORY_CREATE"
        path={routes.COMPANY_DIRECTORY_CREATE}
        shouldRedirectToHome={ability.cannot(ActionsEnum.modify, userProfileSubject())}
        title={formatMessage({ defaultMessage: 'New directory' })}
        user={user}
        render={() => (
          <Layout currentPage="directory">
            <NewInvitationPage />
          </Layout>
        )}
      />

      <CompanyRoute
        key="routes.COMPANY_DIRECTORY"
        path={routes.COMPANY_DIRECTORY}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view_directory, SubjectsEnum.Company)}
        title={formatMessage({ defaultMessage: 'Directory ' })}
        user={user}
        render={() => (
          <Layout currentPage="directory">
            <DirectoryPage />
          </Layout>
        )}
      />

      <CompanyRoute
        component={CustomReportRoutes}
        path={routes.CUSTOM_REPORT_LIST}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.CustomReport)}
        title={formatMessage({ defaultMessage: 'Reports' })}
        user={user}
      />

      {/* ADMIN */}
      <ProtectedRoute
        component={CustomObjectRoutes}
        path={routes.DATA}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Data)}
        user={user}
      />
      <ProtectedRoute
        component={CompanyRoutes}
        path={routes.COMPANY}
        shouldRedirectToHome={!canSeeCompanyProperties}
        user={user}
      />
      <CompanyRoute
        component={TodosRoutes}
        path={routes.TODOS}
        title={formatMessage({ defaultMessage: 'Todo' })}
        user={user}
      />
      <CompanyRoute
        component={SuperAdminRoutes}
        path={routes.SUPERADMIN}
        shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
        title={formatMessage({ defaultMessage: 'Admin' })}
        user={user}
      />
      <CompanyRoute
        component={DashboardView}
        path={[routes.DASHBOARD_START_DATE, routes.DASHBOARD]}
        shouldRedirectToHome={!user?.company?.featureFlags?.DASHBOARDS}
        user={user}
      />
      {/* FLOW */}
      <CompanyRoute
        component={FlowRoutes}
        path={routes.FLOWS}
        title={formatMessage({ defaultMessage: 'Flows' })}
        user={user}
      />
      {/* Statement */}
      <Route
        component={StatementsRoutes}
        path={[routes.STATEMENTS, routes.STATEMENTS_BY_DATE, routes.FORECASTS, routes.FORECASTS_BY_DATE]}
      />
      {/* ROOT */}
      <CompanyRoute
        component={HomeRedirectView}
        path={routes.ROOT}
        title={formatMessage({ defaultMessage: 'Home' })}
        user={user}
      />
    </Switch>
  );
};

export default App;
