import { Children, memo, useState, type JSXElementConstructor, type ReactElement, isValidElement } from 'react';

import { UnstyledButton } from '../../general/unstyled-button/UnstyledButton';

import * as styles from './CardWithTabs.styles';
import { type CardWithTabsValue } from './CardWithTabs.types';
import { CardWithTabsTab, type CardWithTabsTabProps } from './tab/CardWithTabsTab';

export type CardWithTabsProps = {
  children: ReactElement<CardWithTabsTabProps, JSXElementConstructor<CardWithTabsTabProps>>[];
  /** Current tab. Defaults to the first tab. */
  defaultTab?: CardWithTabsValue;
};

const CardWithTabsBase = memo(function CardWithTabsBase({ children, defaultTab }: CardWithTabsProps) {
  const filteredChildren = Children.toArray(children).filter(isValidElement<CardWithTabsTabProps>);

  const [selectedTab, setSelectedTab] = useState<CardWithTabsValue | null>(
    defaultTab ?? filteredChildren.at(0)?.props.value ?? null,
  );

  const selectedChild = filteredChildren.find((child) => child.props.value === selectedTab);

  return (
    <div css={styles.container}>
      <ul css={styles.tabsContainer}>
        {Children.map(filteredChildren, ({ props: { value, title } }) => {
          const isSelectedTab = selectedTab === value;

          return (
            <li
              key={String(value)}
              css={styles.tabContainer(isSelectedTab)}
            >
              <UnstyledButton
                css={styles.tabButton(isSelectedTab)}
                onClick={() => setSelectedTab(value)}
              >
                {title}
              </UnstyledButton>
            </li>
          );
        })}
      </ul>

      <div css={styles.tabContent}>{selectedChild?.props.children}</div>
    </div>
  );
});

export const CardWithTabs = Object.assign(CardWithTabsBase, {
  Tab: CardWithTabsTab,
});
