import { Box } from '@mui/material';
import { IconArrowLeft } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo, type MouseEvent, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  Button,
  type TablerIconElement,
  IconButton,
  type ButtonVariant,
} from '@amal-ia/frontend/design-system/components';

import { COMMON_MESSAGES } from '../../../../utils/messages/common.messages';
import { TextType, Text } from '../../typography';

import { usePageHeaderStyles } from './usePageLayoutStyles';

export interface PageHeaderButtonProps {
  title: ReactNode | string;
  variant?: ButtonVariant;
  dataTestId?: string;
  icon?: TablerIconElement;
  onClick?: (event: MouseEvent<HTMLButtonElement, any>) => void;
  url?: string;
  disabled?: boolean;
}

interface PageHeaderProps {
  title: ReactNode | string;
  subTitle?: ReactNode | string;
  containerClassName?: string;
  headerContainerClassName?: string;
  buttonActions?: PageHeaderButtonProps[];
  leftActions?: ReactNode | string;
  leftActionsRightSide?: ReactNode | string;
  rightActions?: ReactNode | string;
  onClickGoBack?: () => void;
  children?: ReactNode;
  withFilterBar?: boolean;
}

export const PageHeader = memo(function PageHeader({
  title,
  subTitle,
  containerClassName,
  headerContainerClassName,
  buttonActions,
  leftActions,
  leftActionsRightSide,
  rightActions,
  onClickGoBack,
  children,
  withFilterBar,
}: PageHeaderProps) {
  const classes = usePageHeaderStyles();
  const history = useHistory();
  const { formatMessage } = useIntl();
  return (
    <section className={clsx(classes.container, containerClassName)}>
      <Box className={classes.headerContainer}>
        <Box
          className={clsx(
            classes.header,
            children && classes.headerContainerWithHeaderContent,
            headerContainerClassName,
          )}
        >
          {onClickGoBack ? (
            <IconButton
              className={classes.backButton}
              icon={<IconArrowLeft />}
              label={formatMessage(COMMON_MESSAGES.GO_BACK)}
              onClick={onClickGoBack}
            />
          ) : null}
          {leftActions ? <Box className={classes.leftActions}>{leftActions}</Box> : null}
          <Box className={clsx(classes.headerTitle, withFilterBar && classes.headerBorderRight)}>
            <Text
              id="page-header-title"
              type={TextType.PAGE_TITLE}
            >
              {title}
            </Text>
            {subTitle ? (
              <Box mt={0.5}>
                <Text
                  id="page-header-subtitle"
                  type={TextType.PAGE_SUBTITLE}
                >
                  {subTitle}
                </Text>
              </Box>
            ) : null}
          </Box>
          {leftActionsRightSide ? <Box className={classes.leftActions}>{leftActionsRightSide}</Box> : null}
          <Box className={classes.separator} />
          {buttonActions?.map((btnAction) =>
            btnAction.url ? (
              <Button
                key={btnAction.dataTestId || btnAction.url || btnAction.title.toString()}
                data-testid={btnAction.dataTestId}
                disabled={btnAction.disabled}
                icon={btnAction.icon}
                variant={btnAction.variant}
                onClick={() => history.push(btnAction.url)}
              >
                {btnAction.title}
              </Button>
            ) : (
              <Button
                key={btnAction.dataTestId || btnAction.url || btnAction.title.toString()}
                data-testid={btnAction.dataTestId}
                disabled={btnAction.disabled}
                icon={btnAction.icon}
                variant={btnAction.variant}
                onClick={btnAction.onClick}
              >
                {btnAction.title}
              </Button>
            ),
          )}
          {rightActions ? <Box className={classes.rightActions}>{rightActions}</Box> : null}
        </Box>
        {children}
      </Box>
    </section>
  );
});
