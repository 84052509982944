import { orderBy } from 'lodash';

import { commonMathJs } from '@amal-ia/amalia-lang/amalia-mathjs';
import { AmaliaFunction, SanitizeFormula } from '@amal-ia/amalia-lang/formula/evaluate';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { log } from '@amal-ia/frontend/kernel/logger';
import {
  type ComputedRule,
  type ComputedStatement,
  type ComputedVariable,
  type DatasetRow,
  getFieldAsOption,
  ObjectsEnum,
  type Statement,
  type StatementDataset,
  TracingTypes,
  type Variable,
  type VariableDefinition,
  VariableObjectsEnum,
} from '@amal-ia/lib-types';
import {
  type ComputedFunctionArgs_SUM,
  type ComputedFunctionResult,
  type ComputedItem,
  ComputedItemTypes,
  type Dataset,
  DatasetType,
  type FilterDataset,
  type RelationDataset,
} from '@amal-ia/payout-calculation/shared/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

const FunctionsEnum = AmaliaFunction.getFunctionsEnum();

export const getDatasetByParentIdAndFilterMachineName = (
  computedStatement: ComputedStatement,
  filterMachineName: string,
  computedObjectId?: string,
): FilterDataset | undefined =>
  (computedStatement.datasets as FilterDataset[]).find(
    (dataset) =>
      (computedObjectId ? dataset.parentIds?.includes(computedObjectId) : true) &&
      dataset.filterMachineName === filterMachineName,
  );

export const parseRowMarginalIndex = (
  nodeToPush: any,
  computedItemVariable: VariableDefinition,
  computedRule: ComputedRule,
  statement: Statement,
  statementDatasets: Record<string, StatementDataset>,
  datasetRow?: DatasetRow,
) => {
  if (!computedItemVariable?.formula) {
    return nodeToPush;
  }

  const parsedResults = commonMathJs.parse(SanitizeFormula.amaliaFormulaToMathJs(computedItemVariable.formula)) as any;

  const rowMarginalArguments = ['add', 'multiply'].includes(parsedResults.fn)
    ? parsedResults.args[0]?.args
    : parsedResults.args;

  const isRelation =
    (rowMarginalArguments[2]?.fn?.name === 'SORT'
      ? rowMarginalArguments[2]?.args[0].object?.name
      : rowMarginalArguments[2]?.object?.name) !== 'filter';

  // If we have a SORT function wrapping the filter, then the filter name is the first parameter of the SORT.
  const filterOrRelationMachineName =
    rowMarginalArguments[2]?.fn?.name === 'SORT'
      ? rowMarginalArguments[2]?.args[0].index?.dimensions?.[0]?.value
      : rowMarginalArguments[2]?.index?.dimensions?.[0]?.value;

  let filterComputationObject: FilterDataset | RelationDataset | undefined;

  if (isRelation) {
    const relationDefinitionMachineName =
      statement.results.definitions.relationships?.[filterOrRelationMachineName]?.toDefinitionMachineName;
    filterComputationObject = {
      rows: (datasetRow?.content[filterOrRelationMachineName] || []).map(
        (relationRow: { id: string; externalId: string }) => ({
          content: relationRow,
          id: relationRow.id,
          externalId: relationRow.id?.toString(),
        }),
      ),
      customObjectDefinition: statement.results.definitions.customObjects[relationDefinitionMachineName],
      relationMachineName: filterOrRelationMachineName,
      type: DatasetType.relation,
      id: '',
      parentIds: [],
    } as RelationDataset;
  } else {
    filterComputationObject = statement.results?.datasets.find(
      (dataset) =>
        dataset.type === DatasetType.filter &&
        (dataset as FilterDataset).filterMachineName === filterOrRelationMachineName,
    ) as FilterDataset;
  }

  if (!filterComputationObject) {
    return {};
  }

  let filterComputationObjectRows: DatasetRow[];

  if (filterComputationObject.type === DatasetType.filter) {
    // Something somewhere overwrites the dataset id to replace it with the filterId,
    // so we're recomputing the id based on definition, which is something that should never
    // be done. Tracing v2 should solve this, hopefully.
    const datasetId = `f:${filterComputationObject.customObjectDefinition.machineName}:${filterComputationObject.filterMachineName}`;
    if (!statementDatasets[datasetId]) {
      log.warn('Cannot display dataset', filterComputationObject);
      return {};
    }

    filterComputationObjectRows = statementDatasets[datasetId].rows.slice();
  } else {
    // @ts-expect-error -- It still works for relation datasets, because they haven't been extracted.
    filterComputationObjectRows = ((filterComputationObject.rows as DatasetRow[]) || []).slice();
  }

  // Additional fields that will be displayed in the rowMarginal filter
  const fieldsToAdd = [];

  // The fieldToSum is the fourth parameter.
  const fieldToSumMachineName = rowMarginalArguments[3]?.value;
  fieldsToAdd.push({ name: fieldToSumMachineName, label: fieldToSumMachineName });

  // The uniqueIdField is the fifth parameter
  const uniquerIdField = rowMarginalArguments[4]?.value;
  fieldsToAdd.push({ name: uniquerIdField, label: uniquerIdField });

  // If using SORT, grab its second parameter.
  const unparsedSortColumns = rowMarginalArguments[2].fn?.name === 'SORT' ? rowMarginalArguments[2].args[1] : null;

  const sortColumns = [];
  if (unparsedSortColumns) {
    // If SORT is used, we need to show the sort columns in the fields

    if (unparsedSortColumns.items) {
      sortColumns.push(...unparsedSortColumns.items.map((item: any) => ({ name: item?.value, label: item?.value })));
    } else {
      sortColumns.push({ name: unparsedSortColumns.value, label: unparsedSortColumns.value });
    }

    filterComputationObjectRows = orderBy(filterComputationObjectRows, [
      ...sortColumns.map((s) => `content.${s.name}`),
      'id',
    ]);
    fieldsToAdd.push(...sortColumns);
  }

  const computationItemMachineName = computedItemVariable?.machineName;
  if (datasetRow?.id) {
    // Push machineName of item that has the rowMarginal to see it in filter
    fieldsToAdd.push({
      name: computationItemMachineName,
      label: computedItemVariable.name || computationItemMachineName,
    });

    if (filterComputationObjectRows) {
      // Modify filter rows to add machineName of variable that includes rowMaginalIndex
      filterComputationObjectRows = filterComputationObjectRows.map((row: DatasetRow) => ({
        ...row,
        content: {
          ...row.content,
          ...(row.externalId === datasetRow?.externalId
            ? {
                [computationItemMachineName]: datasetRow?.content?.[computationItemMachineName],
              }
            : {
                // Set to null to not print anything
                [computationItemMachineName]: null,
              }),
        },
      }));
    }
  }

  const startAmount = rowMarginalArguments[5]?.toString({
    handler: FormulaService.toArrayHandler,
    computedRule,
    statement,
    statementDatasets,
    datasetRow,
  })?.[0];

  const computedItemInStatement = rowMarginalArguments[1]?.toString({
    handler: FormulaService.toArrayHandler,
    computedRule,
    statement,
    statementDatasets,
    datasetRow,
  });

  let tracingTable;

  if (computedItemInStatement?.[0].type === 'custom_object') {
    // If it's an object variable, we have to fetch the table in the row.
    const [itemDefinition] = computedItemInStatement;
    const key = itemDefinition?.value?.machineName;
    tracingTable = key && datasetRow?.content?.[key];
  } else {
    // If not, it's a global variable so we just have to read its value.
    tracingTable = computedItemInStatement?.[0]?.value?.value;
  }

  return {
    // Filter to show
    filter: {
      ...filterComputationObject,
      rows: filterComputationObjectRows,
    },
    // Additional fields to show on rowMarginal filter
    fieldsToAdd,
    // Field machine name that contains the field to sum
    fieldToSum: fieldToSumMachineName,
    // Sort columns
    sortColumns,
    // Total
    total: computationItemMachineName ? datasetRow?.content[computationItemMachineName] : null,
    // External id of the row that is traced in the parent filter
    // This row will have to be highlighted in the child filter if found
    parentExternalId: datasetRow?.externalId,
    startAmount,
    tracingTable,
  };
};

export class FormulaService {
  public static getFormulaNodes(
    formula: string,
    ruleResult: ComputedRule,
    statement: Statement,
    statementDatasets: Record<string, StatementDataset>,
    datasetRow?: DatasetRow,
    dataSet?: Dataset,
  ): any[] {
    const formulaNode = commonMathJs.parse(formula);
    return formulaNode.toString({
      handler: FormulaService.toArrayHandler,
      ruleResult,
      statement,
      datasetRow,
      statementDatasets,
      dataSet,
    }) as any;
  }

  /**
   * Retrieve all custom object fields included in formula.
   */
  public static getFormulaObjectsFields(
    formula: string,
    definition: CustomObjectDefinition,
    computedStatement: ComputedStatement,
    fields?: { name: string; label: string }[],
    recursiveIndex = 0,
  ): { name: string; label: string }[] {
    const sanitizedFormula = SanitizeFormula.amaliaFormulaToMathJs(formula);
    const formulaNode = commonMathJs.parse(sanitizedFormula);
    const formulaFields = fields || [];

    formulaNode.toString({
      handler: FormulaService.formulaObjectFieldsHandler,
      computedStatement,
      definition,
      fields: formulaFields,
      recursiveIndex,
      formula,
    });

    return formulaFields;
  }

  public static formulaObjectFieldsHandler = (
    node: any,
    options: {
      computedStatement: ComputedStatement;
      definition: CustomObjectDefinition;
      fields: { name: string; label: string }[];
      recursiveIndex: number;
      formula: string;
    },
  ): { name: string; label: string }[] => {
    const { computedStatement, definition, fields, formula } = options;
    const recursiveIndex = options.recursiveIndex + 1;

    if (recursiveIndex > 10) {
      log.error(`Infinite recursive loop on retrieving fields for formula ${options.formula}`);
      return fields;
    }

    if (node.type === TracingTypes.MathJsNodeType.AccessorNode) {
      if (node.object.name === definition.machineName) {
        if (!fields.find((field) => field.name === node.index.dimensions[0].value)) {
          const field = getFieldAsOption(definition, node.index.dimensions[0].value);
          if (field) {
            fields.push(field);
          } else {
            // Field is not included in the object definition => it is an object variable.
            // We need to retrieve variable from computed statement to get its label.
            const fieldDefinitionMatchingVariable =
              computedStatement.definitions.variables[node.index.dimensions[0].value];

            const label =
              ((fieldDefinitionMatchingVariable as Variable) || undefined)?.name || node.index.dimensions[0].value;
            fields.push({ name: node.index.dimensions[0].value, label });
          }
        }
      }

      const variableDefinition = computedStatement.definitions.variables[node.index.dimensions[0].value];

      if (variableDefinition?.formula) {
        FormulaService.getFormulaObjectsFields(
          variableDefinition.formula,
          definition,
          computedStatement,
          fields,
          recursiveIndex + 1,
        );
      }
    } else if (node.type === TracingTypes.MathJsNodeType.FunctionNode && node.fn.name === FunctionsEnum.SUM) {
      const nodeSumFilter = node?.args?.[0]?.toString();
      const nodeSumFormula = node?.args?.[1]?.toString();

      const sumComputedObject: ComputedFunctionResult | undefined = computedStatement.computedObjects
        .map((co: ComputedItem) => co.evaluations || [])
        .flat()
        .find(
          (ev: ComputedFunctionResult) =>
            ev.type === ComputedItemTypes.FUNCTION_RESULT &&
            ev.function === 'SUM' &&
            (ev.args as ComputedFunctionArgs_SUM)?.array === nodeSumFilter &&
            (ev.args as ComputedFunctionArgs_SUM)?.formula === nodeSumFormula,
        );

      const sumFormula = (sumComputedObject?.args as ComputedFunctionArgs_SUM)?.formula;
      if (sumFormula) {
        FormulaService.getFormulaObjectsFields(sumFormula, definition, computedStatement, fields, recursiveIndex + 1);
      }

      const sumFilterDefinition = computedStatement.definitions.filters[node?.args?.[0]?.index?.dimensions?.[0]];
      const filterFormula = sumFilterDefinition?.condition ?? '';
      if (filterFormula) {
        FormulaService.getFormulaObjectsFields(
          filterFormula,
          definition,
          computedStatement,
          fields,
          recursiveIndex + 1,
        );
      }
    } else if (node.type === TracingTypes.MathJsNodeType.ParenthesisNode) {
      FormulaService.formulaObjectFieldsHandler(node.content, {
        computedStatement,
        definition,
        fields,
        recursiveIndex: recursiveIndex + 1,
        formula,
      });
    } else if (node.args && node.args.length > 0) {
      for (const nodeArg of node.args) {
        FormulaService.formulaObjectFieldsHandler(nodeArg, {
          computedStatement,
          definition,
          fields,
          recursiveIndex: recursiveIndex + 1,
          formula,
        });
      }
    }

    return fields;
  };

  public static toArrayHandler = (
    node: any,
    options: {
      ruleResult: ComputedRule;
      statement: Statement;
      statementDatasets: Record<string, StatementDataset>;
      datasetRow?: DatasetRow;
      dataSet?: Dataset;
    },
  ): any[] => {
    const nodeArray: any[] = [];

    const { ruleResult, statement, datasetRow, dataSet, statementDatasets } = options;

    if (node) {
      if (node.type === TracingTypes.MathJsNodeType.OperatorNode) {
        if (node.args.length === 1) {
          // If this is operator is unary (not, -1, etc...)
          nodeArray.push({ value: node.op, type: TracingTypes.FormulaNodeType.operator });
          nodeArray.push(...FormulaService.toArrayHandler(node.args[0], options));
        } else {
          // else (+-/*) parse the preceding nodes
          nodeArray.push(...FormulaService.toArrayHandler(node.args[0], options));
          // Push the operator
          nodeArray.push({ value: node.op, type: TracingTypes.FormulaNodeType.operator });
          // and also the following nodes
          nodeArray.push(...FormulaService.toArrayHandler(node.args[1], options));
        }
      } else if (node.type === TracingTypes.MathJsNodeType.ArrayNode) {
        // Just push the array as a string
        nodeArray.push({
          value: node.toString(),
          type: TracingTypes.FormulaNodeType.array,
          values: node.items.map((item: any) => FormulaService.toArrayHandler(item, options)),
        });
      } else if (node.type === TracingTypes.MathJsNodeType.ConstantNode) {
        // Just push the constant
        nodeArray.push({ value: node.value, type: TracingTypes.FormulaNodeType.constant });
      } else if (node.type === TracingTypes.MathJsNodeType.SymbolNode) {
        // Just push the symbol
        nodeArray.push({ value: node.name, type: TracingTypes.FormulaNodeType.constant });
      } else if (node.type === TracingTypes.MathJsNodeType.AccessorNode) {
        const itemMachineName = node.index?.dimensions?.[0]?.value;
        switch (node.object.name) {
          case ObjectsEnum.statement: {
            const computedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableObjectsEnum.statement &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // For statement variables, we have to get its value from the computation item from its machineName
            nodeArray.push({
              value: {
                ...computedObject,
                ...statement.results.definitions.variables[itemMachineName],
                total: computedObject?.value,
                type: TracingTypes.FormulaNodeType.statement,
              },
              type: TracingTypes.FormulaNodeType.statement,
            });
            break;
          }
          case ObjectsEnum.user: {
            // For user variables, we have first to fetch the corresponding computation item from its machine name
            const userComputedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableObjectsEnum.user &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // Also, we fetch the variable from the user statement
            const userFromComputedStatement: UserContract = statement.results.planAssignment.user;
            // If found in the user statement, format it as a computation item
            const userVariableValue =
              userFromComputedStatement && Object.keys(userFromComputedStatement).includes(itemMachineName)
                ? {
                    total: userFromComputedStatement[itemMachineName as keyof UserContract],
                    formula: userFromComputedStatement[itemMachineName as keyof UserContract],
                    type: TracingTypes.FormulaNodeType.user,
                    name: itemMachineName,
                    label: itemMachineName,
                    machineName: itemMachineName,
                  }
                : null;

            // If a computation item is found, use it. Otherwise, use the value from the user
            const nodeToPush = {
              value: userComputedObject
                ? {
                    ...userComputedObject,
                    ...statement.results.definitions.variables[itemMachineName],
                    total: userComputedObject.value,
                  }
                : userVariableValue,
              type: TracingTypes.FormulaNodeType.user,
            };

            if (nodeToPush.value && !nodeToPush.value.formula) {
              nodeToPush.value.formula = nodeToPush.value?.total?.toString();
            }
            nodeArray.push(nodeToPush);
            break;
          }
          case ObjectsEnum.team: {
            const teamComputedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableObjectsEnum.team &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // If a computation item is found, use it. Otherwise, use the value from the user
            const nodeToPush = {
              value: {
                ...teamComputedObject,
                ...statement.results.definitions.variables[itemMachineName],
                total: teamComputedObject?.value,
              },
              type: TracingTypes.FormulaNodeType.team,
            };

            if (nodeToPush.value && !nodeToPush.value.formula && nodeToPush.value.total) {
              nodeToPush.value.formula = nodeToPush.value?.total?.toString();
            }
            nodeArray.push(nodeToPush);
            break;
          }
          case ObjectsEnum.plan: {
            const planComputedObject = statement.results.computedObjects.find(
              (co: ComputedItem) =>
                co.type === ComputedItemTypes.VARIABLE &&
                (co as ComputedVariable).variableType === VariableObjectsEnum.plan &&
                (co as ComputedVariable).variableMachineName === itemMachineName,
            );

            // If a computation item is found, use it. Otherwise, use the value from the user
            const nodeToPush = {
              value: {
                ...planComputedObject,
                ...statement.results.definitions.variables[itemMachineName],
                total: planComputedObject?.value,
              },
              type: TracingTypes.FormulaNodeType.plan,
            };

            if (nodeToPush.value && !nodeToPush.value.formula && nodeToPush.value.total) {
              nodeToPush.value.formula = nodeToPush.value?.total?.toString();
            }
            nodeArray.push(nodeToPush);
            break;
          }
          case ObjectsEnum.filter: {
            // Get filter by rule
            const filterComputationObject = getDatasetByParentIdAndFilterMachineName(
              statement.results,
              itemMachineName,
              datasetRow?.id,
            );

            const datasetDefinition = statement.results.definitions.filters[itemMachineName];

            if (filterComputationObject) {
              // If we retrieve a filter, then add it. Otherwise, print it as a constant
              const filterFormula = datasetDefinition?.condition ?? '';

              nodeArray.push({
                value: {
                  ...filterComputationObject,
                  ...datasetDefinition,
                  formula: filterFormula,
                  type: TracingTypes.FormulaNodeType.filter_dataset,
                },
                type: TracingTypes.FormulaNodeType.filter_dataset,
              });
            } else {
              nodeArray.push({
                value: (node?.formula || node).toString(),
                type: TracingTypes.FormulaNodeType.constant,
              });
            }
            break;
          }
          default: {
            const customObjectDefinition = statement.results.definitions.customObjects[node.object?.name];
            // If current accessor node corresponds to a custom object definition
            if (customObjectDefinition) {
              const propertyMachineName = node.index.dimensions[0].value;

              const computedObjects = dataSet ? dataSet.computedItems : statement.results.computedObjects;

              // For custom object variables, get the computation item
              const computationItem = computedObjects?.find(
                (co: ComputedItem) =>
                  co.type === ComputedItemTypes.VARIABLE &&
                  (co as ComputedVariable).variableType === VariableObjectsEnum.object &&
                  (co as ComputedVariable).variableMachineName === propertyMachineName,
              );

              const computationItemDefinition = computationItem
                ? statement.results.definitions.variables[(computationItem as ComputedVariable).variableMachineName]
                : null;

              const label =
                computationItemDefinition?.name ||
                (customObjectDefinition.schema?.properties?.[propertyMachineName] as any)?.title ||
                (customObjectDefinition.schema?.properties?.[propertyMachineName] as any)?.$comment ||
                propertyMachineName;

              const nodeToPush = {
                value: {
                  label,
                  formula: (computationItemDefinition?.formula as any) || null,
                  name: propertyMachineName,
                  machineName: propertyMachineName,
                  customObjectMachineName: node.object?.name,
                  format: computationItemDefinition?.format,
                  type: computationItem
                    ? TracingTypes.FormulaNodeType.variable
                    : TracingTypes.FormulaNodeType.custom_object,
                  // May be populated after with a non typed variable
                  subFilter: null as any,
                } as any,
                type: computationItem
                  ? TracingTypes.FormulaNodeType.variable
                  : TracingTypes.FormulaNodeType.custom_object,
              };

              if (computationItemDefinition?.formula?.includes('rowMarginalIndex')) {
                nodeToPush.value.subFilter = parseRowMarginalIndex(
                  nodeToPush,
                  computationItemDefinition,
                  ruleResult,
                  statement,
                  statementDatasets,
                  datasetRow,
                );
                nodeToPush.type = TracingTypes.FormulaNodeType.rowMarginal;
                nodeToPush.value.type = TracingTypes.FormulaNodeType.rowMarginal;
              }

              nodeArray.push(nodeToPush);
            } else {
              // If the value is linked to another type of data, just push its name without trying to retrieve a value
              nodeArray.push({
                value: (node?.formula || node).toString(),
                type: TracingTypes.FormulaNodeType.constant,
              });
            }
          }
        }
      } else if (node.type === TracingTypes.MathJsNodeType.FunctionNode) {
        // Function node: push the function + its parameters formatted with operators

        // Overloads
        switch (node.fn?.name) {
          case 'equal':
            // Only add first parameter
            if (node.args?.[0]) {
              nodeArray.push(...FormulaService.toArrayHandler(node.args?.[0], options));
            }
            break;
          case 'compareNatural':
            // Don't print function name and replace comma with =
            if (node.args?.[0]) {
              nodeArray.push(...FormulaService.toArrayHandler(node.args?.[0], options));
            }
            nodeArray.push({ value: '=', type: TracingTypes.FormulaNodeType.operator });
            if (node.args?.[1]) {
              nodeArray.push(...FormulaService.toArrayHandler(node.args?.[1], options));
            }
            break;
          default: {
            nodeArray.push({
              value: { function: (node.fn?.name as typeof FunctionsEnum) || node.fn?.name },
              type: TracingTypes.FormulaNodeType.function,
              name: (node.fn?.name as typeof FunctionsEnum) || node.fn?.name,
            });
            nodeArray.push({ value: '(', type: TracingTypes.FormulaNodeType.operator });
            node.args.forEach((arg: any, index: number) => {
              if (index > 0) {
                nodeArray.push({ value: ',', type: TracingTypes.FormulaNodeType.operator });
              }

              // Particular case for second argument of SUM: all customObjects and variables
              // should be converted to customObjects with no value
              if (node.fn?.name === FunctionsEnum.SUM && index === 1) {
                nodeArray.push(
                  ...FormulaService.toArrayHandler(node.args[1], options).map((childNode: any) => {
                    if (
                      [TracingTypes.FormulaNodeType.custom_object, TracingTypes.FormulaNodeType.variable].includes(
                        childNode.type,
                      )
                    ) {
                      return {
                        ...childNode,
                        value: {
                          ...childNode.value,
                          // Remove value
                          total: null,
                          // Override type to custom object
                          type: TracingTypes.FormulaNodeType.custom_object,
                        },
                        type: TracingTypes.FormulaNodeType.custom_object,
                      };
                    }
                    return childNode;
                  }),
                );
              } else {
                nodeArray.push(...FormulaService.toArrayHandler(arg, options));
              }
            });
            nodeArray.push({ value: ')', type: TracingTypes.FormulaNodeType.operator });
          }
        }
      } else if (node.type === TracingTypes.MathJsNodeType.ParenthesisNode) {
        // For parenthesis node alone, just puth the child value surrounded by parenthesis nodes
        nodeArray.push({ value: '(', type: TracingTypes.FormulaNodeType.operator });
        // Push the child nodes
        nodeArray.push(...FormulaService.toArrayHandler(node.content, options));
        nodeArray.push({ value: ')', type: TracingTypes.FormulaNodeType.operator });
      } else {
        // If this is another type of node, just push it like this, don't parse it
        nodeArray.push(node.value);
      }
    }

    return nodeArray;
  };
}
