import { Box, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconDots, IconEye, IconPencil } from '@tabler/icons-react';
import { Fragment, type MouseEventHandler, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconAction } from '@amal-ia/frontend/design-system/components';
import { type DesignerActionToRender, TokenType } from '@amal-ia/lib-types';
import {
  ContextMenu,
  useContextMenu,
  Tooltip,
  Text,
  TextType,
  eventStopPropagationAndPreventDefault,
} from '@amal-ia/lib-ui';

export interface ClickableOption {
  value: any;
  label: string;
  format: {
    symbol: string;
    label: string;
  };
  tooltipTitle: string;
  fullMachineName: string;
  formulaToPrint: string;
  description: string;
  planName: string;
  planId: string;
  id: string;
  definitionMachinename?: string | null;
}

interface FormulaEditorOptionProps {
  option: ClickableOption;
  handleListItemClick: MouseEventHandler<HTMLLIElement>;
  formatSymbolClass: string;
  backgroundColor?: string;
  displayContext?: boolean;
  v2?: boolean;
  openInNewTab?: MouseEventHandler<HTMLButtonElement>;
  actionsToRender?: DesignerActionToRender[];
  clickedCategory?: TokenType;
}

const useStyles = makeStyles<AmaliaThemeType, { backgroundColor: string }>((theme) => ({
  planName: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(1),
  },
  italic: {
    '&:hover': {
      fontStyle: 'italic',
      '& $itemAction': {
        display: 'flex',
      },
    },
  },
  itemAction: {
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'absolute',
    right: '0px',
    display: 'none',
    top: 0,
    bottom: 0,
  },
  itemsContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  formulaTooltip: {
    color: theme.palette.common.black,
  },
  formatTooltip: {
    color: theme.palette.tertiary.main,
  },
  itemLabel: {
    color: theme.palette.common.black,
    backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : ''),
  },
}));

const DESIGNER_OBJECTS_CANT_BE_EDITED = [
  TokenType.FUNCTION,
  TokenType.KEYWORD,
  TokenType.PROPERTY,
  TokenType.VIRTUAL_PROPERTY,
];

export const FormulaEditorOption = memo(function FormulaEditorOption({
  option,
  handleListItemClick,
  formatSymbolClass,
  backgroundColor,
  displayContext,
  v2,
  openInNewTab,
  actionsToRender,
  clickedCategory,
}: FormulaEditorOptionProps) {
  const classes = useStyles({ backgroundColor });
  const { formatMessage } = useIntl();

  const { handleOpenMenu, handleCloseMenu, isOpen, anchorEl } = useContextMenu();

  const handleClickMoreOptions: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      eventStopPropagationAndPreventDefault(event);
      handleOpenMenu(event);
    },
    [handleOpenMenu],
  );

  return (
    <ListItem
      key={option.value}
      className={v2 ? classes.italic : null}
      data-testid={option.id}
      onClick={handleListItemClick}
    >
      <ListItemText
        primary={
          <Text
            className={classes.itemsContainer}
            component="div"
            type={TextType.NORMAL_TEXT}
          >
            <Tooltip
              placement="right"
              title={
                <Box
                  padding={1}
                  width={400}
                >
                  <Text type={TextType.TOOLTIP}>
                    {option.tooltipTitle}
                    {option.format ? <span className={classes.formatTooltip}>&nbsp;{option.format.label}</span> : null}
                  </Text>
                  {option.fullMachineName ? <em>{option.fullMachineName}</em> : null}
                  {option.formulaToPrint ? (
                    <Text
                      className={classes.formulaTooltip}
                      mt={2}
                      type={TextType.FORMULA}
                    >
                      {option.formulaToPrint}
                    </Text>
                  ) : null}
                  {option.description ? <Box mt={2}>{option.description}</Box> : null}
                </Box>
              }
            >
              <span className={classes.itemLabel}>{option.label}</span>
            </Tooltip>
            {option.format ? (
              <Text
                className={formatSymbolClass}
                component="span"
                type={TextType.ACTIVE_TEXT}
              >
                {option.format.symbol}
              </Text>
            ) : null}
            {displayContext ? <span className={classes.planName}>{option.planName}</span> : null}
            {v2 && openInNewTab ? (
              <div className={classes.itemAction}>
                <IconAction
                  icon={DESIGNER_OBJECTS_CANT_BE_EDITED.includes(clickedCategory) ? <IconEye /> : <IconPencil />}
                  label={formatMessage({ defaultMessage: 'Open in tab' })}
                  size={IconAction.Size.SMALL}
                  onClick={openInNewTab}
                />
                {actionsToRender ? (
                  <Fragment>
                    {anchorEl ? (
                      <ContextMenu
                        actionsToRender={actionsToRender}
                        anchorEl={anchorEl}
                        callbackParam={option.id}
                        handleCloseMenu={handleCloseMenu}
                        isOpen={isOpen}
                        renderPosition={{ anchorVertical: 'center', transformHorizontal: 'left' }}
                      />
                    ) : null}
                    <IconAction
                      icon={<IconDots />}
                      label={formatMessage({ defaultMessage: 'More options' })}
                      size={IconAction.Size.SMALL}
                      onClick={handleClickMoreOptions}
                    />
                  </Fragment>
                ) : null}
              </div>
            ) : null}
          </Text>
        }
      />
    </ListItem>
  );
});
