import { Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';

import ChartItem from './ChartItem';

const useStyles = makeStyles({
  root: {
    flexFlow: 'wrap',
    justifyContent: 'center',
  },
});

interface ChartsProps {
  ruleDefinition: PlanRule;
}

const Charts = memo(function Charts({ ruleDefinition }: ChartsProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
    >
      {(ruleDefinition.charts || []).map((c) => (
        <ChartItem
          key={c.id}
          chart={c}
        />
      ))}
    </Grid>
  );
});

export default Charts;
