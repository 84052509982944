import { memo } from 'react';

import { SpecialRoute } from './SpecialRoute';
import { type UserConditionedRouteProps } from './userConditionedRouteProps';

const ProtectedRouteBase = function ProtectedRoute<TPath extends string = string>({
  user,
  shouldRedirectToHome,
  ...props
}: UserConditionedRouteProps<TPath>) {
  return (
    <SpecialRoute
      shouldRedirectToHome={!user || shouldRedirectToHome}
      {...props}
    />
  );
};

export const ProtectedRoute = memo(ProtectedRouteBase) as typeof ProtectedRouteBase;
