import { IconDownload, IconInfoCircle, IconViewfinder } from '@tabler/icons-react';
import { noop } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import {
  type CreateAdjustmentDto,
  createDataExport,
  setOpenedThread,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import {
  type DataExportRequest,
  isBeingReviewed,
  type PaymentCategory,
  type StatementThread,
} from '@amal-ia/lib-types';
import { useOpenInNewTab, useStatementDetailContext } from '@amal-ia/lib-ui';
import { type CompanyFeatureFlags } from '@amal-ia/tenants/shared/types';

import { type StatementViewEnum } from '../../StatementDetail';

import { messages } from './StatementDetailHeaderActions.messages';

export type StatementDetailHeaderActionsProps = {
  setSelectAdjustment: (adjustment: CreateAdjustmentDto) => void;
  handleForecastedSwitch: (nextState: StatementViewEnum) => Promise<void>;
  statementCommentThread: StatementThread | null;
  paymentTotalByType?: Record<PaymentCategory, number> & {
    containsHoldRules: boolean;
  };
  companyFeatureFlags: Record<CompanyFeatureFlags, boolean>;
};

export const useStatementDetailHeaderActions = ({
  setSelectAdjustment,
  handleForecastedSwitch,
  statementCommentThread,
  paymentTotalByType,
  companyFeatureFlags,
}: StatementDetailHeaderActionsProps) => {
  const ability = useAbilityContext();
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const openInNewTab = useOpenInNewTab();
  const { formatMessage } = useIntl();

  // CALCULATION
  const statement = useStatementDetailContext();

  const handleClickQuota = useCallback(() => {
    if (statement.user.id) {
      openInNewTab(`/quotas/user/${statement.user.id}`);
    }
  }, [openInNewTab, statement.user.id]);

  const handleClickDocumentation = useCallback(() => {
    if (statement.plan?.linkToPlanDocumentation) {
      openInNewTab(statement.plan.linkToPlanDocumentation);
    }
  }, [openInNewTab, statement.plan]);

  // WORKFLOW
  const isWorkflowBegun = useMemo(() => isBeingReviewed(statement), [statement]);

  // ADJUSTMENTS
  const handleNewAdjustment = useCallback(
    () =>
      setSelectAdjustment
        ? setSelectAdjustment({
            name: '',
            description: '',
            paymentPeriodId: statement.period.id,
          })
        : noop,
    [setSelectAdjustment, statement],
  );

  // EXPORT
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);

  const handleExportModalOpen = useCallback(() => setIsExportModalOpen(true), []);
  const handleExportModalClose = useCallback(() => setIsExportModalOpen(false), []);
  const handleExportModalSubmit = useCallback(async (values: DataExportRequest) => {
    await createDataExport(values);
    setIsExportModalOpen(false);
  }, []);

  // COMMENTS
  const onStatementCommentsClicked = useCallback(() => {
    if (statementCommentThread?.id) {
      history.push(
        generatePath(routes.STATEMENT_COMMENT, {
          id: statement.id,
          stid: statementCommentThread.id,
        }),
      );
    } else {
      dispatch(setOpenedThread(-1));
    }
  }, [statementCommentThread, statement, history, dispatch]);

  // ICONS
  const menuIconsList = useMemo(
    () =>
      [
        {
          label: formatMessage(messages.GO_TO_QUOTA),
          icon: <IconViewfinder />,
          handleClick: handleClickQuota,
        },
        ability.can(ActionsEnum.export, SubjectsEnum.Statement) &&
          !statement.isForecastedView && {
            icon: <IconDownload />,
            label: formatMessage(messages.EXPORT),
            handleClick: handleExportModalOpen,
          },
        statement.plan?.linkToPlanDocumentation && {
          icon: <IconInfoCircle />,
          label: formatMessage(messages.PLAN_DOCUMENTATION),
          handleClick: handleClickDocumentation,
        },
      ].filter(Boolean),
    [ability, handleClickQuota, handleExportModalOpen, handleClickDocumentation, statement, formatMessage],
  );

  return {
    statement,
    ability,
    isWorkflowBegun,
    companyFeatureFlags,
    handleForecastedSwitch,
    handleExportModalClose,
    handleExportModalSubmit,
    formatMessage,
    handleNewAdjustment,
    statementCommentThread,
    onStatementCommentsClicked,
    menuIconsList,
    isExportModalOpen,
    paymentTotalByType,
  };
};
