import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css, useTheme } from '@emotion/react';
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import { type ReactNode, memo, useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { RadioButtonGroup, type RadioButtonGroupProps } from '../../../../forms/radio-button-group/RadioButtonGroup';
import { type RadioButtonOptionShape } from '../../../../forms/radio-button-group/RadioButtonGroup.types';
import { Switch, type SwitchProps } from '../../../../forms/switch/Switch';
import { GrabHandle } from '../../../../general/grab-handle/GrabHandle';
import { DropdownItemContent } from '../../../../overlays/dropdown/dropdown-item-content/DropdownItemContent';
import { type ColumnDefinition } from '../../../table/Table.types';
import { SortDirection } from '../../DataGrid.types';

import { sortDirectionMessages } from './ColumnSortingMenuItem.messages';
import * as styles from './ColumnSortingMenuItem.styles';

type DirectionOption = RadioButtonOptionShape<SortDirection, true>;

export type ColumnSortingMenuItemProps = {
  id: ColumnDefinition['id'];
  icon?: ColumnDefinition['icon'];
  label: ReactNode;
  onChangeActivated: (id: ColumnDefinition['id'], activated: boolean) => void;
  isActivated: boolean;
  direction?: SortDirection;
  disableReordering?: boolean;
  onChangeDirection?: (id: ColumnDefinition['id'], direction: SortDirection) => void;
};

export const ColumnSortingMenuItem = memo(function ColumnSortingMenuItem({
  id,
  icon,
  label,
  isActivated,
  direction,
  disableReordering,
  onChangeActivated,
  onChangeDirection,
}: ColumnSortingMenuItemProps) {
  const theme = useTheme();

  const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging, transition } = useSortable({
    id,
    disabled: !isActivated || disableReordering,
  });

  const handleChangeDirection: Required<RadioButtonGroupProps<SortDirection>>['onChange'] = useCallback(
    (newDirection) => onChangeDirection?.(id, newDirection),
    [id, onChangeDirection],
  );

  const handleChangeActivated: Required<SwitchProps>['onChange'] = useCallback(
    (activated) => onChangeActivated(id, activated),
    [id, onChangeActivated],
  );

  const directionOptions: DirectionOption[] = useMemo(
    () => [
      {
        value: SortDirection.ASC,
        showOnlyIcon: true,
        icon: <IconSortAscending />,
        label: (
          <FormattedMessage
            {...sortDirectionMessages[SortDirection.ASC]}
            values={{ columnName: label }}
          />
        ),
      },
      {
        value: SortDirection.DESC,
        showOnlyIcon: true,
        icon: <IconSortDescending />,
        label: (
          <FormattedMessage
            {...sortDirectionMessages[SortDirection.DESC]}
            values={{ columnName: label }}
          />
        ),
      },
    ],
    [label],
  );

  return (
    <li
      ref={setNodeRef}
      css={[
        styles.sortMenuItem,
        css`
          transform: ${CSS.Transform.toString(transform)};
          transition: ${[transition, theme.ds.transitions.default('box-shadow')].filter(Boolean).join(', ')};
          z-index: ${isDragging ? 1 : 0};
          box-shadow: ${isDragging ? theme.ds.shadows.hard : 'none'};
        `,
      ]}
    >
      <DropdownItemContent
        icon={icon}
        label={label}
        grabHandle={
          !!isActivated && !disableReordering ? (
            <GrabHandle
              {...attributes}
              {...listeners}
              ref={setActivatorNodeRef}
            />
          ) : undefined
        }
        rightActions={
          <DropdownItemContent.Actions>
            {!!isActivated && !!direction && (
              <RadioButtonGroup<SortDirection>
                name={`${id}-sort-direction`}
                options={directionOptions}
                size={RadioButtonGroup.Size.SMALL}
                value={direction}
                onChange={handleChangeDirection}
              />
            )}

            <Switch
              checked={isActivated}
              size={Switch.Size.SMALL}
              onChange={handleChangeActivated}
            />
          </DropdownItemContent.Actions>
        }
      />
    </li>
  );
});
