import { createContext } from 'react';

import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type Challenge, type Period, type TeamMap } from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/shared/types';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

export interface LeaderboardTableContextInterface {
  challenge: Challenge;
  variableFormat: FormatsEnum;
  usersMap: UsersMap;
  teamsMap: TeamMap;
  currentPeriod: Period;
  company: Company;
}

export const LeaderboardTableContext = createContext<LeaderboardTableContextInterface>(
  {} as LeaderboardTableContextInterface,
);
