import { defineMessages } from 'react-intl';

export const plansRoutesMessages = defineMessages({
  PLANS: {
    defaultMessage: 'Plans',
  },
  PLAN_OVERVIEW: {
    defaultMessage: 'Plan overview',
  },
  PLAN_ASSIGNMENTS: {
    defaultMessage: 'Plan assignments',
  },
} as const);
