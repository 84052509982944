import { type Editor, EditorContent } from '@tiptap/react';
import { memo } from 'react';

import { editorContentCss } from './FormulaEditorContent.styles';

export interface FormulaEditorContentProps {
  editor: Editor;
}

export const FormulaEditorContent = memo(function FormulaEditorContent({ editor }: FormulaEditorContentProps) {
  return (
    <EditorContent
      css={editorContentCss}
      editor={editor}
      spellCheck={false}
    />
  );
});
