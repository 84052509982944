import { pick } from 'lodash';
import { memo } from 'react';

import { useUserInformations } from '@amal-ia/tenants/users/shared/state';
import { type KeysOfUserWithStringValues } from '@amal-ia/tenants/users/shared/types';

import { UserPrettyFormat, type UserPrettyFormatProps } from '../pretty-format/UserPrettyFormat';

export type UserFetchContainerProps<TProperty extends KeysOfUserWithStringValues> = Pick<
  UserPrettyFormatProps,
  'variant'
> & {
  property: TProperty;
  /** except 'null' string value */
  value: string;
};

export const UserFetchContainerBase = function UserFetchContainer<TProperty extends KeysOfUserWithStringValues>({
  property,
  value,
  variant,
}: UserFetchContainerProps<TProperty>) {
  const { data: userComputed, isLoading } = useUserInformations(property, value);

  return (
    <UserPrettyFormat
      isLoading={isLoading}
      subLabel={value}
      variant={variant}
      {...pick(userComputed, ['firstName', 'lastName', 'pictureURL'])}
    />
  );
};

export const UserFetchContainer = memo(UserFetchContainerBase) as typeof UserFetchContainerBase;
