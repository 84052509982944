import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Tooltip, TextType, Text } from '@amal-ia/lib-ui';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {},
  amount: {
    display: 'block',
    transition: 'color .2s',
  },
  clickable: {
    cursor: 'pointer',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  tertiary: {
    color: theme.palette.tertiary.main,
  },
}));

interface PayoutProps {
  amount: string | null;
  label: string | null;
  color?: 'primary' | 'secondary' | 'tertiary';
  forceColor?: string;
  className?: string;
  classNames?: { label?: string; amount?: string };
  onClick?: () => void;
  tooltipTitle?: string;
  LabelNode?: ReactNode;
  amountTextType?: TextType;
}

export const Payout = memo(function Payout({
  label,
  amount,
  color,
  forceColor,
  className,
  classNames,
  onClick,
  tooltipTitle,
  LabelNode,
  amountTextType = TextType.PAYOUT,
}: PayoutProps) {
  const classes = useStyles();

  const render = (
    <Box
      className={clsx(classes.root, onClick && classes.clickable, className)}
      onClick={onClick}
    >
      <Text
        className={classNames?.label}
        type={TextType.INPUT_NAME}
      >
        {LabelNode || label}
      </Text>
      <Text
        className={clsx(classes.amount, classes[color || 'primary'], classNames?.amount)}
        data-cy="payout"
        style={{ color: forceColor }}
        title={label || undefined}
        type={amountTextType}
      >
        {amount}
      </Text>
    </Box>
  );
  return tooltipTitle ? <Tooltip title={tooltipTitle}>{render}</Tooltip> : render;
});
