import invariant from 'invariant';
import { createContext, useContext } from 'react';

import { type TableProps } from '../table/Table';
import { type RowKey, type RowData } from '../table/Table.types';

export type DataGridContextValue<TData extends RowData = RowData, TKey extends RowKey = RowKey> = {
  /** Instance of table returned by useReactTable. */
  columns: TableProps<TData, TKey>['columns'];
};

export const DataGridContext = createContext<DataGridContextValue | null>(null);

export const useDataGridContext = <TData extends RowData>(): DataGridContextValue<TData> => {
  const context = useContext(DataGridContext);
  invariant(context, 'useDataGridContext must be used within a <DataGridContext.Provider>');
  // Need to cast here because we can't use generics in createContext.
  return context as DataGridContextValue<TData>;
};
