import { type ForwardedRef, forwardRef, memo } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import {
  BreadcrumbsBackButton,
  type BreadcrumbsBackButtonProps,
} from '../breadcrumbs-back-button/BreadcrumbsBackButton';

export type BreadcrumbsBackLinkProps = Omit<BreadcrumbsBackButtonProps, 'onClick'> & {
  to: LinkProps['to'];
};

const BreadcrumbsBackLinkForwardRef = forwardRef(function BreadcrumbsBackLink(
  { to, ...props }: BreadcrumbsBackLinkProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Link to={to}>
      <BreadcrumbsBackButton
        {...props}
        ref={ref}
      />
    </Link>
  );
});

export const BreadcrumbsBackLink = memo(BreadcrumbsBackLinkForwardRef);
