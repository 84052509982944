import { type PlanRuleCategory, type RuleConfiguration, RuleType } from '@amal-ia/compensation-definition/shared/types';

export const RULE_TYPES_LABELS: Record<RuleType, string> = {
  [RuleType.PAYOUT]: 'Payout',
  [RuleType.LINE_PAYOUT]: 'Payout',
  [RuleType.NON_PAYOUT]: 'Non-Payout',
  [RuleType.SPLIT]: 'Split Payout',
  [RuleType.HOLD_AND_RELEASE]: 'Hold & Release',
  [RuleType.CHALLENGE]: 'Challenge',
};

export enum RuleTypeWithAdjustment {
  ADJUSTMENT = 'ADJUSTMENT',
}

export interface HoldAndReleaseSettings {
  releaseCondition?: string;
  paymentDate?: string;
  forecastPaymentDate?: string;
}

export interface Rule {
  id: string;
  name: string;
  description?: string;
  formula: string;
  filter?: { id: string };
  filterId: string;
  machineName?: string;
  type: RuleType;
  categories?: PlanRuleCategory[];
  filterMachineName?: string;
  planId?: string | null;

  // Split
  splitVariables?: string[];

  // H&R
  commissionVariableId?: string | null;
  releaseConditionVariableId?: string | null;

  // Configuration
  configuration?: RuleConfiguration;
}

export enum RuleMainType {
  RECORDS = 'RECORDS',
  TOTAL = 'TOTAL',
  CHALLENGE = 'CHALLENGE',
}

export interface RuleInForm extends Rule {
  ruleMainType: RuleMainType;
  isPayout: boolean;
  isHoldAndRelease: boolean;
  isSplit: boolean;
  valueSelector: string;
}
