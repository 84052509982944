import invariant from 'invariant';
import { createContext, useContext } from 'react';

import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type StatisticDefinition } from '@amal-ia/lib-types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

export interface StatementHistogramContextProps {
  user: UserComputed;
  planName: string;
  definitions: Record<string, StatisticDefinition>;
  currency: CurrencySymbolsEnum;

  totals: {
    achievement: number;
    paid: number;
    hold: number;
  };
}

const StatementHistogramContext = createContext<StatementHistogramContextProps>(null);

export const StatementHistogramProvider = StatementHistogramContext.Provider;

export const useStatementHistogramContext = () => {
  const context = useContext(StatementHistogramContext);
  invariant(context, 'useStatementHistogramContext must be used within a StatementHistogramContext');
  return context;
};
