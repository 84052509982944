import { combineReducers } from 'redux';

import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type ReduxAction } from '@amal-ia/lib-types';

import { type CompanyCurrency } from '../../types/company';
import { type Currency } from '../../types/currency';

import { COMPANIES_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case COMPANIES_ACTIONS.START:
        return state + 1;
      case COMPANIES_ACTIONS.ERROR:
      case COMPANIES_ACTIONS.SET_CURRENCY_RATE:
      case COMPANIES_ACTIONS.SET_CURRENCIES_WITH_COMPANY_CURRENCIES:
      case COMPANIES_ACTIONS.COMPANY_CURRENCY_CREATE:
      case COMPANIES_ACTIONS.COMPANY_CURRENCY_UPDATE:
      case COMPANIES_ACTIONS.COMPANY_CURRENCY_DELETE:
        return state - 1;
      default:
        return state;
    }
  },
  currencyRate: (state: number | null = null, action: ReduxAction) => {
    switch (action.type) {
      case COMPANIES_ACTIONS.SET_CURRENCY_RATE:
        return action.payload.currencyRate;
      default:
        return state;
    }
  },
  // Currency array with company currencies, per year
  currenciesWithCompanyCurrencies: (state: Currency[] = [], action: ReduxAction) => {
    switch (action.type) {
      case COMPANIES_ACTIONS.SET_CURRENCIES_WITH_COMPANY_CURRENCIES:
        return action.payload.currencies.map((currency: Currency) => {
          // Get all company currencies linked to this currency
          const relatedCompanyCurrencies = action.payload.companyCurrencies.filter(
            (cc: CompanyCurrency) => cc.currency.id === currency.id,
          );

          return {
            // Copy currency
            ...currency,

            // If there are company currencies, copy them into an object, with their symbol as key
            overwrites: relatedCompanyCurrencies
              ? relatedCompanyCurrencies.reduce(
                  (acc: Record<CurrencySymbolsEnum, CompanyCurrency>, companyCurrency: CompanyCurrency) => {
                    acc[companyCurrency.symbol] = {
                      ...companyCurrency,
                      // Keep the original value into amaliaRate attribute
                      amaliaRate: currency.rates[companyCurrency.symbol],
                    } as CompanyCurrency;
                    return acc;
                  },
                  {} as Record<CurrencySymbolsEnum, CompanyCurrency>,
                )
              : undefined,
            rates: {
              // Copy all currency rates
              ...currency.rates,
              // Then, apply company currencies values
              ...(relatedCompanyCurrencies
                ? relatedCompanyCurrencies.reduce(
                    (acc: Record<CurrencySymbolsEnum, number>, companyCurrency: CompanyCurrency) => {
                      acc[companyCurrency.symbol] = companyCurrency.rate;
                      return acc;
                    },
                    {} as Record<CurrencySymbolsEnum, number>,
                  )
                : {}),
            },
          };
        });
      case COMPANIES_ACTIONS.COMPANY_CURRENCY_DELETE: {
        return state.map((currency) => ({
          // Copy original currency
          ...currency,
          overwrites: {
            // Copy also its overwrites
            ...currency.overwrites,
            // Then, overwrite the previous overwrite symbol to undefined to cancel it
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: undefined,
                }
              : {}),
          },
          rates: {
            // Copy the original rates
            ...currency.rates,
            // then, reapply the original rate on the overwrite symbol
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: action.payload.companyCurrency.amaliaRate,
                }
              : {}),
          },
        }));
      }
      case COMPANIES_ACTIONS.COMPANY_CURRENCY_CREATE:
      case COMPANIES_ACTIONS.COMPANY_CURRENCY_UPDATE:
        return state.map((currency) => ({
          // Copy the original currency
          ...currency,
          overwrites: {
            // With its overwrites
            ...currency.overwrites,
            // Then, set the overwrite on the correct currency and symbol
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: {
                    // Copy the overwrite
                    ...action.payload.companyCurrency,
                    // To get the original rate,
                    // Either we already have a company currency, or we get the rate from currency.rates
                    amaliaRate:
                      currency.overwrites?.[action.payload.companyCurrency.symbol as CurrencySymbolsEnum]?.amaliaRate ||
                      currency.rates[action.payload.companyCurrency.symbol as CurrencySymbolsEnum],
                  },
                }
              : {}),
          },
          rates: {
            // Copy the original rates
            ...currency.rates,
            // Then, set the new rate on the overwrite symbol
            ...(currency.id === action.payload.companyCurrency.currency.id
              ? {
                  [action.payload.companyCurrency.symbol]: action.payload.companyCurrency.rate,
                }
              : {}),
          },
        }));
      default:
        return state;
    }
  },
});
