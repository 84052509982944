import { type ComponentProps, memo } from 'react';
import { Helmet } from 'react-helmet';

export type AmaliaHeadProps = ComponentProps<typeof Helmet> & {
  title?: string;
};

// TODO: tests
export const AmaliaHead = memo(function AmaliaHead({ title, children, ...props }: AmaliaHeadProps) {
  return (
    <Helmet {...props}>
      <title>{[title, 'Amalia'].filter(Boolean).join(' | ')}</title>
      {children}
    </Helmet>
  );
});
