import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export interface TeamAssignment {
  effectiveAsOf: number;
  effectiveUntil: number;
  user: UserContract;
  teamId: string;
  hierarchy?: string[];
}
export interface NewTeam {
  name: string;
  parentTeamId?: string;
}

export interface TeamContract {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
  parentTeamId?: string;
  parentTeam?: TeamContract;
  childrenTeams: TeamContract[];
  hierarchy?: { teamParents: TeamContract[]; teamChildren: TeamContract[] };
}

// Indexed by teamId.
export type TeamMap = Record<string, TeamContract>;

export interface Team {
  id?: string;

  name?: string;

  archived?: boolean;

  members?: UserContract[];

  managers?: UserContract[];

  employees?: UserContract[];
}

export interface TeamNode {
  id?: string;
  name: string;
  archived?: boolean;
  childrenTeams: TeamNode[];
}

export enum TeamRole {
  TEAM_MANAGER = 'TEAM_MANAGER',
  TEAM_EMPLOYEE = 'TEAM_EMPLOYEE',
}

export enum TeamFormattedRole {
  TEAM_MANAGER = 'MANAGER',
  TEAM_EMPLOYEE = 'EMPLOYEE',
}

export enum TeamMemberType {
  EMPLOYEE = 'employee',
  MANAGER = 'manager',
}

export interface TeamScope {
  role: AssignmentType;
  scope: MatchScope;
  depth: number;
}

export enum AssignmentType {
  MINE = 'MINE',
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
}

export enum MatchScope {
  ALL = 'ALL',
  SUB = 'SUB',
}
