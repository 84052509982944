import { type Filter } from '@amal-ia/compensation-definition/shared/types';
import { type PlansMap, type Variable } from '@amal-ia/lib-types';
import { type Option } from '@amal-ia/lib-ui';

import { DesignerItemWithContext } from '../components/designer/DesignerItemWithContext/DesignerItemWithContext';

export const renderOptions = <T extends Filter | Variable>(
  options: T[],
  plansMap: PlansMap,
  field: 'id' | 'machineName' = 'id',
): Option[] =>
  options
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((v) => ({
      value: v[field],
      label: (
        <DesignerItemWithContext
          object={v}
          plansMap={plansMap}
        />
      ),
      searchString: `${v.name} ${v.machineName}`,
    }));
