import { IconHelpCircle } from '@tabler/icons-react';
import { memo } from 'react';

import { ExternalLink, type ExternalLinkProps } from '@amal-ia/ext/react-router-dom';

import { IconAction } from '../../../../general/icon-action/IconAction';
import { type TooltipProps } from '../../../../overlays/tooltip/Tooltip';

export type ColumnLinkTooltipProps = {
  href: ExternalLinkProps['href'];
  content: TooltipProps['content'];
};

export const ColumnLinkTooltip = memo(function ColumnLinkTooltip({ href, content }: ColumnLinkTooltipProps) {
  return (
    <ExternalLink href={href}>
      <IconAction
        icon={<IconHelpCircle />}
        label={content}
        size={IconAction.Size.XSMALL}
      />
    </ExternalLink>
  );
});
