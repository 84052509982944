import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amal-ia/common/routes';
import { PageContainer } from '@amal-ia/frontend/connected-components/layout';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { SpeakerOutlined } from '@amal-ia/frontend/ui-icons';
import {
  closeThread,
  type CreateAdjustmentDto,
  createAdjustmentThunkAction,
  createStatementThreadThunkAction,
  editAdjustmentThunkAction,
  fetchContextualizedPaymentsForStatement,
  fetchCurrentStatementThreadsThunkAction,
  fetchForecastedStatementThunkAction,
  fetchPaymentAmountsForStatement,
  fetchPaymentsForStatement,
  fetchPeriods,
  fetchStatementStatisticsThunkAction,
  fetchStatementThreadMessagesThunkAction,
  fetchStatementThunkAction,
  fetchUserStatementsThunkAction,
  postMessageInStatementThreadThunkAction,
  selectCurrentForecastedStatement,
  selectCurrentPaymentCategory,
  selectCurrentStatement,
  selectCurrentStatementThreads,
  selectOpenedThreadId,
  selectPaymentsIsLoading,
  selectStatementPaymentAmountByCategory,
  selectStatementsIsLoading,
  setCurrentPeriodThunkAction,
  setOpenedThread,
  showMultiPayouts,
  STATEMENTS_ACTIONS,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import {
  type Adjustment,
  type CalculationRequest,
  CalculationType,
  formatDate,
  type MessageContent,
  PaymentCategory,
  type Statement,
  type StatementThreadScope,
  type TracingTypes,
} from '@amal-ia/lib-types';
import {
  helpdocLinks,
  InformationBanner,
  type StatementDetailContextInterface,
  StatementDetailContextProvider,
  useCustomIconStyles,
} from '@amal-ia/lib-ui';
import { DatasetType } from '@amal-ia/payout-calculation/shared/types';

import TracingPage from '../Tracing/TracingPage';
import { useCalculation } from '../useCalculation';

import AdjustmentModal from './Adjustments/AdjustmentModal';
import { CommentDrawerContainer } from './CommentDrawerContainer';
import NotFoundStatementError from './Errors/NotFoundStatementError';
import StatementDetailHeader from './Header/StatementDetailHeader';
import StatementPayments from './Payments/StatementPayments';
import { messages } from './StatementDetail.messages';
import * as styles from './StatementDetail.styles';
import StatementDetailPayout from './StatementDetailPayout';

export const CURRENCY_RATE = 1;

export enum StatementViewEnum {
  ACHIEVED = 'achieved',
  FORECASTED = 'forecasted',
}

export default memo(function StatementDetail() {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useThunkDispatch();
  const { snackError } = useSnackbars();
  const ability = useAbilityContext();
  const { formatMessage } = useIntl();
  const iconClasses = useCustomIconStyles();

  const { data: currentUser } = useCurrentUser();

  const {
    id: statementId,
    stid: statementThreadIdToOpen,
    action: actionOnStatement,
  } = useParams<Record<string, string>>();
  const statement = useSelector(selectCurrentStatement);
  const [isForecastedView, setIsForecastedView] = useState<boolean>(pathname?.includes('forecasts'));
  const statementIsLoading = useSelector(selectStatementsIsLoading);
  const forecastedStatement = useSelector(selectCurrentForecastedStatement);

  // PAYMENTS
  const paymentsIsLoading = useSelector(selectPaymentsIsLoading);
  const currentPaymentCategory = useSelector(selectCurrentPaymentCategory);
  const paymentTotalByType = useSelector(selectStatementPaymentAmountByCategory);

  const [selectedAdjustment, setSelectedAdjustment] = useState<CreateAdjustmentDto | null>(null);

  const [currentTracingData, setCurrentTracingData] = useState<TracingTypes.CurrentTracingDataType | null>(null);

  const statementThreads = useSelector(selectCurrentStatementThreads);
  const openedThreadId = useSelector(selectOpenedThreadId);

  const [showStatementNotFoundError, setShowStatementNotFoundError] = useState<boolean>(false);

  // =============== DATA FETCH ===============

  const refresh = useCallback(async () => {
    // Fetch statement by its id.
    const action = await dispatch(fetchStatementThunkAction(statementId));

    if (action.type === STATEMENTS_ACTIONS.ERROR && action.error?.statusCode === 404) {
      setShowStatementNotFoundError(true);
      return action;
    }
    setShowStatementNotFoundError(false);

    await Promise.all([
      // Fetch current statement payment amounts
      dispatch(fetchPaymentAmountsForStatement(statementId)),
      dispatch(fetchPaymentsForStatement(statementId)),
      dispatch(fetchContextualizedPaymentsForStatement(statementId)),
    ]);

    return action;
  }, [statementId, dispatch]);

  /**
   * Get the statement on page load.
   */
  useAsyncEffect(async () => {
    // Reset tracing: we're changing from one statement to another,
    // Values, plans, users... everything may have changed
    setCurrentTracingData(null);

    const action = await refresh();

    if (action.type !== STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS) {
      return;
    }

    const { statement: currentStatement }: { statement: Statement } = action.payload;
    const year = formatDate(new Date(currentStatement.period.startDate * 1000), 'YYYY');
    await Promise.all(
      [
        // Fetch current period.
        dispatch(
          setCurrentPeriodThunkAction({
            ...currentStatement.period,
            frequency: currentStatement.plan.frequency || currentUser.company.statementFrequency,
          }),
        ),
        // Fetch current statement statistics
        currentUser.company.featureFlags.DASHBOARDS &&
          dispatch(fetchStatementStatisticsThunkAction(currentStatement.user.id, currentStatement.plan.id, year)),
        // Fetch history of statements for trend and histogram.
        dispatch(
          fetchUserStatementsThunkAction(undefined, currentStatement.plan.id, undefined, currentStatement.user.id),
        ),
        // Fetch all periods if we have a metrics dataset.
        currentStatement.results.datasets.some((d) => d.type === DatasetType.metrics) && dispatch(fetchPeriods()),
      ].filter(Boolean) as Promise<any>[],
    );
  }, [statementId, dispatch]);

  useAsyncEffect(async () => {
    if (statement?.forecastId && isForecastedView) {
      await Promise.all([dispatch(fetchForecastedStatementThunkAction(statement.forecastId, statement.id, true))]);
    }
  }, [statement?.forecastId]);

  // =============== CALCULATIONS ===============

  const calculationRequest: CalculationRequest = useMemo(
    () =>
      statement?.period?.id
        ? {
            periodId: statement.period.id,
            planId: statement.plan.id,
            userIds: [statement.user.id],
            type: isForecastedView ? CalculationType.FORECAST : CalculationType.STATEMENT,
          }
        : null,
    [isForecastedView, statement?.period?.id, statement?.plan?.id, statement?.user?.id],
  );

  const postUpdate = useCallback(async () => {
    // Fetch current statement payment amounts
    await Promise.all(
      [
        refresh(),
        statement?.forecastId && dispatch(fetchForecastedStatementThunkAction(statement.forecastId, statementId, true)),
      ].filter(Boolean),
    );
  }, [dispatch, refresh, statement?.forecastId, statementId]);

  const { launchCalculation, isCalculationRunning, stopCalculation, calculationProgress, lastCalculation } =
    useCalculation(calculationRequest, postUpdate);

  // =============== COMMENTS ===============

  const mainThread = useMemo(
    () => Object.values(statementThreads).find((thread) => !thread.scope?.type) || null,
    [statementThreads],
  );

  /**
   * Create main thread if it doesn't exist, then open the drawer.
   */
  const openStatementThread = useCallback(
    async (statementThreadId: string) => {
      if (!statementId) {
        return;
      }
      if (!statementThreads[statementThreadId]?.thread.messages) {
        const action = await dispatch(fetchStatementThreadMessagesThunkAction(statementId, statementThreadId));
        dispatch(setOpenedThread(statementThreadId, action.payload.messages, statementThreads[statementThreadId]));
      } else {
        dispatch(
          setOpenedThread(
            statementThreadId,
            statementThreads[statementThreadId].thread.messages,
            statementThreads[statementThreadId],
          ),
        );
      }
    },
    [dispatch, statementId, statementThreads],
  );

  useEffect(() => {
    dispatch(fetchCurrentStatementThreadsThunkAction(statementId));
  }, [dispatch, statementId]);

  /**
   * Get open threads on the statement.
   */
  useAsyncEffect(() => {
    // If URL contains a threadId AND both the statement and statement threads have finished to load
    if (statementThreadIdToOpen && !statementIsLoading && statement && statement.id === statementId) {
      // if we find it in the statement threads, open it
      if (Object.keys(statementThreads).includes(statementThreadIdToOpen)) {
        openStatementThread(statementThreadIdToOpen);
      } else {
        // Otherwise show error and remove it from URL
        snackError(formatMessage(messages.COMMENT_THREAD_NOT_EXIST));
        history.push(generatePath(routes.STATEMENT, { id: statementId }));
      }
    }

    const fullUrl = window.location.href;

    // If URL has no threadId, close the opened one. If the URL does not include "comments", there's no opened thread.
    // URL for thread that is not created yet is /statement/{statementId}/comments
    // URL for thread that exists already is /statements/{statementId}/comments/{threadId}
    if (!statementThreadIdToOpen && openedThreadId && !fullUrl.includes('comments')) {
      dispatch(closeThread());
    }
  }, [dispatch, statementId, statementThreadIdToOpen, statementIsLoading, statement, statementThreads]);

  /**
   * Create new comment handler
   */
  const handleNewMessage = useCallback(
    async (content: MessageContent[], scope?: StatementThreadScope) => {
      if (!openedThreadId) {
        return;
      }

      // Thread is not created yet. Create it now
      if (openedThreadId === -1) {
        // Create the thread
        const newThreadAction = await dispatch(createStatementThreadThunkAction(statementId, scope));

        if (newThreadAction.type === STATEMENTS_ACTIONS.CREATE_STATEMENT_THREAD) {
          const newStatementThread = newThreadAction.payload.statementThread;

          // Create first message
          const newMessageAction = await dispatch(
            postMessageInStatementThreadThunkAction(statementId, newStatementThread.id || '', {
              content,
            }),
          );

          // Open the new statement thread
          if (newMessageAction.type === STATEMENTS_ACTIONS.ADD_STATEMENT_THREAD_MESSAGE) {
            // Refresh opened thread with new thread id and created message by pushing it into the URL
            history.push(
              generatePath(routes.STATEMENT_COMMENT, {
                id: statementId,
                stid: newStatementThread.id,
              }),
            );
          }
        }
      } else {
        // Just create the message
        await dispatch(
          postMessageInStatementThreadThunkAction(statementId, openedThreadId as string, {
            content,
          }),
        );
      }
    },
    [dispatch, openedThreadId, statementId, history],
  );

  // =============== PAYMENTS =======================
  const isShowMultiPayouts = useMemo(
    () => showMultiPayouts(statement, paymentTotalByType),
    [statement, paymentTotalByType],
  );

  // =============== ADJUSTMENT MODAL ===============

  const closeAdjustmentModal = useCallback(() => setSelectedAdjustment(null), [setSelectedAdjustment]);

  const onSubmitAdjustmentsModal = useCallback(
    async (newAdjustment: Adjustment): Promise<void> => {
      const action = newAdjustment.id
        ? await dispatch(editAdjustmentThunkAction(newAdjustment))
        : await dispatch(createAdjustmentThunkAction(statementId, newAdjustment));
      if (action.type !== STATEMENTS_ACTIONS.ERROR) {
        closeAdjustmentModal();
        await launchCalculation();
      }
    },
    [closeAdjustmentModal, statementId, dispatch, launchCalculation],
  );

  // =============== NAVIGATION ===============
  const onClickGoToStatement = useCallback(
    (id: string) => history.push(generatePath(routes.STATEMENT, { id })),
    [history],
  );

  // =============== EDITION ===============

  // STATEMENT CONTEXT
  const statementContext: StatementDetailContextInterface = useMemo(
    () => ({
      launchCalculation,
      stopCalculation,
      isCalculationRunning,
      calculationProgress,
      isForecastedView,
      ...statement,
      currency: isForecastedView ? forecastedStatement?.currency : statement?.currency,
      total: isForecastedView ? forecastedStatement?.total : statement?.total,
      rate: CURRENCY_RATE,
      objectsToDisplay: isForecastedView ? forecastedStatement?.planForecast?.objectsToDisplay : {},
      results: isForecastedView ? forecastedStatement?.results : statement?.results,
      forecastId: statement?.forecastId,
      originalStatement: isForecastedView ? statement : null,
      error: isForecastedView ? forecastedStatement?.error : statement?.error,
      lastCalculationDate: isForecastedView ? forecastedStatement?.lastCalculationDate : statement?.lastCalculationDate,
      statementId: statement?.id,
      userId: statement?.user?.id,
    }),
    [
      launchCalculation,
      stopCalculation,
      isCalculationRunning,
      calculationProgress,
      isForecastedView,
      statement,
      forecastedStatement,
    ],
  );

  useEffect(() => setCurrentTracingData(null), [currentPaymentCategory]);

  const handleForecastedSwitch = useCallback(
    async (nextState: StatementViewEnum) => {
      if (nextState === StatementViewEnum.FORECASTED && statement?.forecastId) {
        await dispatch(fetchForecastedStatementThunkAction(statement.forecastId, statement.id, true));
      } else {
        dispatch(fetchStatementThunkAction(statementId));
      }
      setIsForecastedView((prev) => {
        if (prev === true) {
          history.push(generatePath(routes.STATEMENT, { id: statementId }));
        } else {
          history.push(generatePath(routes.FORECAST, { id: statementId }));
        }
        return !prev;
      });
    },
    [dispatch, history, statement?.forecastId, statement?.id, statementId],
  );

  const handleClickLearnMoreSimulation = () => {
    window.open(helpdocLinks.SIMULATION, '_blank', 'noopener,noreferrer');
  };

  const innerContent = useMemo(() => {
    if (isForecastedView && (!forecastedStatement || !statement.forecastId)) {
      return null;
    }

    if (currentTracingData) {
      return (
        <TracingPage
          currencyRate={CURRENCY_RATE}
          currencySymbol={statement.currency}
          currentTracingData={currentTracingData}
          setCurrentTracingData={setCurrentTracingData}
          statement={statement}
        />
      );
    }

    /* If statement is not HnR => then we only have achievement, so we display statement details and not payments. */
    // Force open tracing if it is asked to be opened
    if (
      currentPaymentCategory === PaymentCategory.achievement ||
      !isShowMultiPayouts ||
      actionOnStatement === 'tracing'
    ) {
      return (
        <StatementDetailPayout
          setCurrentTracingData={setCurrentTracingData}
          setSelectAdjustment={setSelectedAdjustment}
        />
      );
    }

    return <StatementPayments category={currentPaymentCategory as PaymentCategory} />;
  }, [
    isForecastedView,
    forecastedStatement,
    currentTracingData,
    currentPaymentCategory,
    isShowMultiPayouts,
    actionOnStatement,
    statement,
  ]);

  // =============== RENDER ===============
  return (
    <PageContainer showLoadingBar={statementIsLoading || paymentsIsLoading}>
      <AmaliaHead title={formatMessage(messages.USER_STATEMENTS, { user: statement?.user?.firstName || '' })} />
      <StatementDetailContextProvider value={statementContext}>
        {statement && currentUser ? (
          ability.can(ActionsEnum.view, SubjectsEnum.Statement) ? (
            <Fragment>
              <StatementDetailHeader
                handleForecastedSwitch={handleForecastedSwitch}
                isTracingActive={!!currentTracingData}
                lastCalculation={lastCalculation}
                setSelectAdjustment={setSelectedAdjustment}
                statementCommentThread={mainThread}
                onClickGoToStatement={onClickGoToStatement}
              />
              {isForecastedView && statement?.plan.isSimulationEnabled ? (
                <div css={styles.simulationBanner}>
                  <InformationBanner
                    buttonTitle={<FormattedMessage {...messages.SIMULATION_LEARN_MORE} />}
                    description={<FormattedMessage {...messages.SIMULATION_DESCRIPTION} />}
                    icon={<SpeakerOutlined className={iconClasses.customIcon} />}
                    title={<FormattedMessage {...messages.SIMULATION_IS_AVAILABLE} />}
                    onClickCTA={handleClickLearnMoreSimulation}
                  />
                </div>
              ) : null}
              {innerContent}

              <CommentDrawerContainer handleNewMessage={handleNewMessage} />

              {ability.can(ActionsEnum.adjust, SubjectsEnum.Statement) && (
                <AdjustmentModal
                  adjustment={selectedAdjustment}
                  statement={statement}
                  onCancel={closeAdjustmentModal}
                  onSubmit={onSubmitAdjustmentsModal}
                />
              )}
            </Fragment>
          ) : (
            <FormattedMessage {...messages.NO_AUTHORIZATION_TO_VIEW_STATEMENT} />
          )
        ) : null}
        {showStatementNotFoundError && currentUser && ability.can(ActionsEnum.view, SubjectsEnum.Statement) ? (
          <NotFoundStatementError />
        ) : null}
      </StatementDetailContextProvider>
    </PageContainer>
  );
});
