import { useLocalStorageState } from 'ahooks';
import { useCallback, useMemo } from 'react';

import {
  HidableElementVisibility,
  type PlanRuleKpiToDisplay,
  type PlanRule,
} from '@amal-ia/compensation-definition/shared/types';
import { type ComputedVariableWithVariableIdAndLabel } from '@amal-ia/lib-types';
import { type Option, type StatementDetailContextInterface } from '@amal-ia/lib-ui';

/**
 * Hook to handle the list of selected kpis to display in the statement
 * for a given rule, also taking into account the view (forecasted or not) and
 * the user preferences (stored in local storage).
 *
 * IMPORTANT TO NOTE THAT THE SOURCE OF TRUTH FOR THE KPIs TO DISPLAY COMES FROM THE DESIGNER
 * WE ONLY APPLY THE USER PREFERENCES ON TOP OF IT
 */
export const useKPIDisplay = (
  statementContext: StatementDetailContextInterface,
  ruleDefinition: PlanRule,
  statementRuleComputedVariables: ComputedVariableWithVariableIdAndLabel[],
) => {
  // We extract here the kpis "source" and the local storage key to avoid conditional later in every useMemo
  // Both depend on the view (forecasted or not)
  const kpiSource = useMemo(
    () =>
      (statementContext.isForecastedView
        ? statementContext.objectsToDisplay?.[ruleDefinition.id]?.kpisToDisplay
        : ruleDefinition.kpisToDisplay) || [],
    [
      ruleDefinition.id,
      ruleDefinition.kpisToDisplay,
      statementContext.isForecastedView,
      statementContext.objectsToDisplay,
    ],
  );
  const selectedKPIsStorageKey = statementContext.isForecastedView
    ? `displayed-forecasted-kpis-${statementContext.plan.id}-${ruleDefinition.id}`
    : `displayed-kpis-${statementContext.plan.id}-${ruleDefinition.id}`;

  // Save the displayed/hidden kpis in local storage to keep the user preferences
  // We can't only save the ids because the order of the kpis is important
  // We'll need to update the list if the displayed kpis change in the designer
  const [kpisVisibilityUserPreferences, setKpisVisibilityUserPreferences] = useLocalStorageState<
    Pick<PlanRuleKpiToDisplay, 'displayStatus' | 'id'>[]
  >(selectedKPIsStorageKey, {
    defaultValue: () =>
      kpiSource.map(({ id, displayStatus }) => ({
        id,
        displayStatus,
      })),
  });

  // The KPI ids to display from the kpiSource as the source of truth
  // Override when needed with the user preferences
  const displayedKPIIds = useMemo(
    () =>
      kpiSource
        .map((kpiFromStatement) => {
          // If the kpi is not in the user preferences, we return the kpi from the source (designer state)
          const kpiInUserPreferences = kpisVisibilityUserPreferences.find(({ id }) => id === kpiFromStatement.id);
          return kpiInUserPreferences ?? kpiFromStatement;
        })
        .filter(({ displayStatus }) => displayStatus === HidableElementVisibility.ON_DISPLAY)
        .map(({ id }) => id),
    [kpiSource, kpisVisibilityUserPreferences],
  );

  // The KPIs to display from the list of planRuleKPIs (including the value)
  const displayedKPIs = useMemo(
    () => statementRuleComputedVariables.filter(({ kpiVariableId }) => displayedKPIIds.includes(kpiVariableId)),
    [displayedKPIIds, statementRuleComputedVariables],
  );

  const availableKPIOptions: Option[] = useMemo(
    () =>
      statementRuleComputedVariables.map(({ label, kpiVariableId: value }) => ({
        label,
        value,
      })),
    [statementRuleComputedVariables],
  );

  // The callback proxy to update the local storage and everything that depends on it
  // So basically, all the process from the top of this hook
  const setDisplayedKPIIds = useCallback(
    (newDisplayedKPIIds: string[]) => {
      setKpisVisibilityUserPreferences(
        kpiSource.map(({ id }) => ({
          id,
          displayStatus: newDisplayedKPIIds.includes(id)
            ? HidableElementVisibility.ON_DISPLAY
            : HidableElementVisibility.AVAILABLE,
        })),
      );
    },
    [kpiSource, setKpisVisibilityUserPreferences],
  );

  return {
    availableKPIOptions,
    displayedKPIs,
    displayedKPIIds,
    setDisplayedKPIIds,
  };
};
