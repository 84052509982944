import { combineReducers } from 'redux';

import { type AuditRecord } from '@amal-ia/backend/kernel/monitoring/audit';
import { type ReduxAction } from '@amal-ia/lib-types';

import { type AuditFiltersOptions } from '../../types/audit';

import { AUDIT_ACTIONS } from './constants';

const emptyRecords: AuditRecord[] = [];
const emptyFilters: AuditFiltersOptions = {
  users: [],
  objects: [],
  targets: [],
  types: [],
  whats: [],
  limit: null,
  offset: null,
};

export default combineReducers({
  loading: (state: number = 0, action) => {
    switch (action.type) {
      case AUDIT_ACTIONS.START:
      case AUDIT_ACTIONS.START_FILTERS:
      case AUDIT_ACTIONS.START_COUNT:
      case AUDIT_ACTIONS.START_DATA:
        return state + 1;
      case AUDIT_ACTIONS.ERROR:
      case AUDIT_ACTIONS.SET_FILTERS:
      case AUDIT_ACTIONS.SET_RECORDS:
      case AUDIT_ACTIONS.SET_RECORDS_COUNT:
        return state - 1;
      default:
        return state;
    }
  },
  records: (state: AuditRecord[] = emptyRecords, action: ReduxAction) => {
    switch (action.type) {
      case AUDIT_ACTIONS.SET_RECORDS:
        return action.payload;
      case AUDIT_ACTIONS.CLEAR_RECORDS:
        return [];
      default:
        return state;
    }
  },
  recordsCount: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case AUDIT_ACTIONS.SET_RECORDS_COUNT:
        return action.payload;
      default:
        return state;
    }
  },
  filters: (state: AuditFiltersOptions = emptyFilters, action: ReduxAction) => {
    switch (action.type) {
      case AUDIT_ACTIONS.SET_FILTERS:
        return action.payload;
      default:
        return state;
    }
  },
});
