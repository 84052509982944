import { defineMessages } from 'react-intl';

export const companyRoutesMessages = defineMessages({
  WORKFLOW: {
    defaultMessage: 'Workflow',
  },
  CURRENCY: {
    defaultMessage: 'Currency',
  },
  COMPANY_API: {
    defaultMessage: 'Company API',
  },
  COMPANY: {
    defaultMessage: 'Company',
  },
} as const);
