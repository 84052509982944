import { memo } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

import { TextType, Text } from '../../typography';

export const AppbarNavItemLink = memo(function AppbarNavItemLink({ children, ...props }: LinkProps) {
  return (
    <Link {...props}>
      <Text type={TextType.NAVBAR_ITEM}>{children}</Text>
    </Link>
  );
});
