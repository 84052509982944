import { css, useTheme } from '@emotion/react';
import { IconSquareRoundedCheck, IconSquareRoundedX } from '@tabler/icons-react';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amal-ia/frontend/design-system/components';

import { accentuatedLabel, defaultLabel, dimmedLabel } from '../../FieldValuePrettyFormat.styles';
import { LabelVariant } from '../../types';

import { messages } from './BooleanPrettyFormat.messages';
import { BooleanLabelTypographyVariantMap } from './types';

const container = css`
  display: flex;
  align-items: center;

  gap: 8px;
`;

export type BooleanPrettyFormatProps = {
  value?: boolean | null;
  variant?: LabelVariant;
};

export const BooleanPrettyFormat = memo(function BooleanPrettyFormat({
  value,
  variant = LabelVariant.DEFAULT,
}: BooleanPrettyFormatProps) {
  const theme = useTheme();

  if (typeof value !== 'boolean') {
    // Blank if null
    return null;
  }

  return (
    <span
      css={[
        container,
        variant === LabelVariant.DEFAULT && defaultLabel,
        variant === LabelVariant.ACCENTUATED && accentuatedLabel,
        variant === LabelVariant.DIMMED && dimmedLabel,
      ]}
    >
      {value ? (
        <Fragment>
          <IconSquareRoundedCheck
            color={theme.ds.hues.green[500]}
            size="16px"
          />
          <Typography variant={BooleanLabelTypographyVariantMap[variant]}>
            <FormattedMessage {...messages.TRUE} />
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <IconSquareRoundedX
            color={theme.ds.hues.red[500]}
            size="16px"
          />
          <Typography variant={BooleanLabelTypographyVariantMap[variant]}>
            <FormattedMessage {...messages.FALSE} />
          </Typography>
        </Fragment>
      )}
    </span>
  );
});
