import { round } from 'lodash';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type VariableDefinition, type CurrencyAmount, PaymentCategory } from '@amal-ia/lib-types';
import { type ComputedItem } from '@amal-ia/payout-calculation/shared/types';

import { formatTotal, roundNumber } from './formatTotal';

/**
 * Each amount -MIN_VALID_AMOUNT < amount < MIN_VALID_AMOUNT is considered to be 0.
 */
export const MIN_VALID_AMOUNT = 0.005;

export const formatAmount = (amount: number, currency: string) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: FormatsEnum.currency,
    currency: currency || 'EUR',
    minimumFractionDigits: 0,
  });

  // If the amount is contained between -0.004999... and 0.0049999..., it
  // will be rounded then displayed as -0 and we want to avoid that.
  const cappedAmount = Math.abs(amount) < MIN_VALID_AMOUNT ? 0 : amount;

  return formatter.format(cappedAmount);
};

export const formatValue = (value: number, format: FormatsEnum, currency?: string) => {
  if (format === FormatsEnum.currency) {
    return formatAmount(value, currency);
  }

  const isPercent = format === FormatsEnum.percent;
  return `${round((isPercent ? 100 : 1) * value, 2)}${isPercent ? ' %' : ''}`;
};

export const formatKpi = (variableDefinition: VariableDefinition, computedVariable: ComputedItem): string =>
  formatValue(computedVariable.value as number, variableDefinition?.format, computedVariable.currency);

export const formatCurrencyAmount = (currencyAmount: CurrencyAmount): string =>
  formatTotal(
    currencyAmount.amount,
    FormatsEnum.currency,
    currencyAmount.currencySymbol,
    currencyAmount.currencyRate,
  )?.toString();

/**
 * Function that prints a currency symbol character from a currency symbol
 * i.e. getCurrencySymbolCharacters('EUR') => €
 *
 * @param currencySymbol
 *
 * The trick is to format "0.0" with the formatTotal function, then remove every space, numbers and points just to have the character
 * Adapted from https://stackoverflow.com/questions/50650503/get-the-currency-symbol-for-a-locale
 */
export const getCurrencySymbolCharacters = (currencySymbol = CurrencySymbolsEnum.EUR) =>
  formatTotal(0, FormatsEnum.currency, currencySymbol)
    ?.toString()
    .replace(/[0-9.]+/gu, '')
    .trim();

export const showPayment = (
  allAmounts: Partial<Record<PaymentCategory, number | undefined>>,
  category: PaymentCategory,
): boolean => {
  const categoryAmount = allAmounts[category];
  const roundedCategoryAmount = roundNumber(categoryAmount !== undefined ? categoryAmount : 0);

  // Show future payments if different than 0
  if (category === PaymentCategory.hold) {
    return roundedCategoryAmount !== 0;
  }

  // Show if different than achievement
  if (category === PaymentCategory.paid) {
    const achievedAmount = roundNumber(allAmounts[PaymentCategory.achievement]);
    return roundedCategoryAmount !== achievedAmount;
  }

  return categoryAmount !== undefined;
};

/**
 * Format total amout according to format (%, currency, number).
 * @param value
 * @param totalFormat
 * @param currencySymbol
 * @param currencyRate
 */
export const formatTotalAmount = (
  value: number,
  totalFormat: FormatsEnum,
  currencySymbol?: CurrencySymbolsEnum,
  currencyRate?: number,
): string => {
  const currency = currencySymbol || CurrencySymbolsEnum.EUR;
  switch (totalFormat) {
    case FormatsEnum.number:
      return `${roundNumber(value) || 0}`;
    case FormatsEnum.percent:
      return `${formatTotal(value, FormatsEnum.percent) || 0}`;
    default:
      return formatCurrencyAmount({
        amount: roundNumber(value) || 0,
        currencyRate,
        currencySymbol: currency,
      });
  }
};
