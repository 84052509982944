export enum DataConnectorTypes {
  SALESFORCE = 'salesforce',
  SALESFORCE_SECOND_ACCOUNT = 'salesforce-second-account',
  HUBSPOT = 'hubspot',
  NETSUITE = 'netsuite',
  GOOGLESHEET = 'googlesheet',
  REDSHIFT = 'redshift',
  SNOWFLAKE = 'snowflake',
  POSTGRESQL = 'postgresql',
  POWERBI = 'powerbi',
  SQLSERVER = 'sqlserver',
  MYSQL = 'mysql',
  BIGQUERY = 'bigquery',
  BAMBOOHR = 'bamboohr',
  ADPWORKFORCENOW = 'adp-workforce-now',
  PERSONIO = 'personio',
  SAPSUCCESSFACTORS = 'sap-successfactors',
  SAGEHR = 'sage-hr',
  WORKDAY = 'workday',
  ZOHO = 'zoho',
}
