import { type Theme, css } from '@emotion/react';

export const statementDetailsContainer = (isStatementReviewed: boolean) => (theme: Theme) => css`
  width: 100%;
  background-color: ${theme.ds.colors.gray[0]};
  margin-top: 32px;
  display: flex;
  justify-content: space-around;
  align-self: center;
  align-items: center;
  padding: 16px;
  border-radius: ${theme.ds.borderRadiuses.squared};
  box-shadow: ${theme.ds.shadows.soft};
  border-bottom: ${isStatementReviewed && `4px solid ${theme.ds.colors.primary[500]}`};
`;

export const histogramContainer = css`
  display: flex;
`;

export const tracingMessageContainer = (theme: Theme) => css`
  display: flex;
  margin-top: 8px;
  width: 100%;
  height: 3rem;
  justify-content: center;
  align-items: center;
  background-color: ${theme.ds.hues.blue[50]};
  border-radius: ${theme.ds.borderRadiuses.squared};
`;

export const tracingText = (theme: Theme) => css`
  color: ${theme.ds.hues.blue[500]};
`;
