import { mapValues } from 'lodash';
import { type PartialDeep } from 'type-fest';

/**
 * You need to create a test object for a unit test,
 * but you're too lazy to create a full mock?
 * This function is for you!
 * Write a partial, choose the output type, and you're good to go!
 *
 * @example
 *  const user = createTestObject<User>({ firstName: 'Jean' });
 * @param object
 */
export const createTestObject = <T>(object: unknown) => object as T;

/**
 * Give an object, generate a list of objects by appending the index to each key.
 *
 * @param obj - Object.
 * @param quantity - Number of objects to generate.
 * @param override - Property who would be the same for each object.
 * @param addProperties - Function to add properties to each object.
 */
export const generateFakeList = <T extends object>(
  obj: PartialDeep<T>,
  quantity: number,
  override?: PartialDeep<T>,
  addProperties?: (object: T, index: number) => Partial<T>,
): T[] => {
  const list: T[] = [];

  for (let i = 0; i < quantity; i++) {
    const createdObject: T = {
      ...mapValues(obj as unknown as object, (v) => `${v}-${i + 1}`),
      ...override,
    } as T;

    list.push({
      ...createdObject,
      ...(addProperties ? addProperties(createdObject, i) : {}),
    });
  }

  return list;
};
