import { type ComponentProps, type JSXElementConstructor, memo, type ReactElement } from 'react';
import { useInView } from 'react-intersection-observer';

import { PageHeaderRow, type PageHeaderRowProps } from './page-header-row/PageHeaderRow';
import * as styles from './PageHeader.styles';

type PageHeaderProps = Omit<ComponentProps<'div'>, 'children'> & {
  /**
   * The rows of the page header.
   */
  children?:
    | ReactElement<PageHeaderRowProps, JSXElementConstructor<PageHeaderRowProps>>
    | ReactElement<PageHeaderRowProps, JSXElementConstructor<PageHeaderRowProps>>[];
  stickyRow?: ReactElement<PageHeaderRowProps, JSXElementConstructor<PageHeaderRowProps>>;
};

const PageHeaderBase = function PageHeader({ children = undefined, stickyRow, ...props }: PageHeaderProps) {
  const { ref, inView } = useInView({ threshold: 0.25 });
  return (
    <div
      {...props}
      ref={ref}
    >
      <div css={styles.pageHeaderContainer}>{children}</div>
      {!inView && stickyRow ? <div css={styles.pageHeaderContainerSticky}>{stickyRow}</div> : null}
    </div>
  );
};

export const PageHeader = Object.assign(memo(PageHeaderBase), {
  Row: PageHeaderRow,
});
