import { flatten } from 'lodash';
import { type ActionCreator } from 'redux';

import { VariablesApiClient } from '@amal-ia/compensation-definition/api-client';
import {
  type SyncQuotasRequest,
  type Variable,
  type VariableObjectsEnum,
  type QuotaType,
  type ReduxAction,
  type ThunkResult,
} from '@amal-ia/lib-types';

import * as VariableValuesRepository from '../../services/variables/variableValues.repository';
import { bulkSyncQuotas } from '../../services/variables/variableValues.repository';
import { isTheSameVariableValue } from '../../services/variables/variableValues.service';
import { type VariableValue } from '../../types/variables';
import { addSnackbar } from '../snackbars/actions';

import { VARIABLES_ACTIONS } from './constants';
import { selectCurrentVariableValues } from './selectors';

const variablesStart: ActionCreator<ReduxAction> = () => ({
  type: VARIABLES_ACTIONS.START,
});

const variablesSuccess: ActionCreator<ReduxAction> = () => ({
  type: VARIABLES_ACTIONS.SUCCESS,
});

const variablesError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: VARIABLES_ACTIONS.ERROR,
  error,
});

const setVariables: ActionCreator<ReduxAction> = (variables: Variable) => ({
  type: VARIABLES_ACTIONS.SET_VARIABLES,
  payload: { variables },
});

export const clearVariables: ActionCreator<ReduxAction> = () => ({
  type: VARIABLES_ACTIONS.CLEAR_VARIABLES,
});

export const fetchVariables =
  (types?: VariableObjectsEnum[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(variablesStart());
    try {
      const variables = await VariablesApiClient.list(types);
      return dispatch(setVariables(variables));
    } catch (error) {
      return dispatch(variablesError(error));
    }
  };

export const duplicateVariablesInNewContextThunkAction =
  (variablesId: string[], planId: string, ruleId: string, activeFilterId?: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(variablesStart());
    try {
      const { isErrorWhileDuplicating } = await VariablesApiClient.duplicateInNewContext(variablesId, {
        planId,
        ruleId,
        activeFilterId,
      });
      if (isErrorWhileDuplicating) {
        dispatch(
          addSnackbar({
            message: 'Error while importing variable(s). Variable(s) with the same name already exist.',
            options: { variant: 'error' },
          }),
        );
      }
      const variablesUpdated = await VariablesApiClient.list();
      return dispatch(setVariables(variablesUpdated));
    } catch (error) {
      return dispatch(variablesError(error));
    }
  };

export const clearVariableValues: ActionCreator<ReduxAction> = () => ({
  type: VARIABLES_ACTIONS.CLEAR_VARIABLE_VALUES,
});

const setVariableValues: ActionCreator<ReduxAction> = (variableValues: VariableValue[]) => ({
  type: VARIABLES_ACTIONS.SET_VARIABLE_VALUES,
  payload: { variableValues },
});

export const fetchVariableValues =
  (variableId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(variablesStart());
    try {
      const variableValues = await VariableValuesRepository.getVariableValues(variableId);
      return dispatch(setVariableValues(variableValues));
    } catch (error) {
      return dispatch(variablesError(error));
    }
  };

export const fetchVariableValuesOfUser =
  (userId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(variablesStart());
    try {
      const variableValues = await VariableValuesRepository.getVariableValues(undefined, userId);
      return dispatch(setVariableValues(flatten(variableValues)));
    } catch (error) {
      return dispatch(variablesError(error));
    }
  };

const setVariableValue: ActionCreator<ReduxAction> = (variableValue: VariableValue) => ({
  type: VARIABLES_ACTIONS.SET_VARIABLE_VALUE,
  payload: { variableValue },
});

export const upsertVariableValue =
  (variableValue: VariableValue): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    dispatch(variablesStart());

    const currentVariableValues = selectCurrentVariableValues(getState());

    // Write it into the table so it's immediate.
    dispatch(setVariableValue(variableValue));

    try {
      const existingVariableValue = currentVariableValues.find((vv) => isTheSameVariableValue(vv, variableValue));

      if (!existingVariableValue && variableValue.value !== null) {
        // If it's a new one, create it.
        await VariableValuesRepository.createVariableValue(variableValue);
      } else if (existingVariableValue && variableValue.value !== null) {
        // If it's an edition, do a PATCH on the API.
        await VariableValuesRepository.updateVariableValue(variableValue);
      } else if (existingVariableValue && variableValue.value === null) {
        // If the value is null, remove it.
        await VariableValuesRepository.deleteVariableValue(variableValue);
      } else {
        // Something weird happened.
        throw new Error('Invalid operation');
      }

      return dispatch(variablesSuccess());
    } catch (error) {
      // If an error did happen, fetch variable values once more to sync the screen state with the actual data.
      await dispatch(fetchVariableValues(variableValue.variableId));
      return dispatch(variablesError(error));
    }
  };

export const bulkSyncVariableValues =
  (variableId: string, quotas: SyncQuotasRequest[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(variablesStart());

    try {
      await bulkSyncQuotas(quotas);
      await dispatch(fetchVariableValues(variableId));
    } catch (e) {
      await dispatch(fetchVariableValues(variableId));
      return dispatch(variablesError(e));
    }

    return dispatch(variablesSuccess());
  };

const CLEAR_ACTION_TYPES = {
  user: {
    action: VARIABLES_ACTIONS.CLEAR_USER_VARIABLE_VALUES,
    param: 'userId',
  },
  plan: {
    action: VARIABLES_ACTIONS.CLEAR_PLAN_VARIABLE_VALUES,
    param: 'planId',
  },
  team: {
    action: VARIABLES_ACTIONS.CLEAR_TEAM_VARIABLE_VALUES,
    param: 'teamId',
  },
};

const clearVariableValueForAction: ActionCreator<ReduxAction> = (type: QuotaType, entityId: string) => ({
  type: CLEAR_ACTION_TYPES[type].action,
  payload: { [CLEAR_ACTION_TYPES[type].param]: entityId },
});

export const clearVariableValueFor =
  (type: QuotaType, entityId: string, variableId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(variablesStart());
    try {
      await VariableValuesRepository.clearValuesFor(type, entityId, variableId);
      return dispatch(clearVariableValueForAction(type, entityId));
    } catch (error) {
      return dispatch(variablesError(error));
    }
  };
