import { type Theme, css } from '@emotion/react';

export const dot = (theme: Theme) => css`
  flex: none;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${theme.ds.colors.primary[500]};
`;

export const stepsContainer = css`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const step = (theme: Theme) => css`
  display: flex;
  align-items: center;

  > span {
    margin: 0 8px;
    white-space: nowrap;
  }

  > hr {
    flex: 1;
    margin: 0;
    min-width: 50px;
    border: 1px solid ${theme.ds.colors.primary[500]};
  }

  &[data-last='true'] {
    > hr {
      display: none;
    }
  }
`;
