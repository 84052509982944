import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { quotasRoutesMessages } from './quotas.routes.messages';

const QuotaListContainer = lazy(() => import('./QuotaListContainer'));
const QuotaValueContainer = lazy(() => import('./variableValue/QuotaValueContainer'));
const QuotaByUserContainer = lazy(() => import('./viewQuotasByUsers/QuotasByUserDetailsContainer'));

export const QuotasRoutes = memo(function QuotasRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="quotas">
      <Switch>
        <CompanyRoute
          component={QuotaValueContainer}
          path={routes.QUOTAS_VALUE}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Quota)}
          title={formatMessage(quotasRoutesMessages.QUOTAS_VALUE)}
          user={user}
        />
        <CompanyRoute
          component={QuotaByUserContainer}
          path={routes.QUOTAS_USER}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Quota)}
          title={formatMessage(quotasRoutesMessages.QUOTAS_USER)}
          user={user}
        />
        <CompanyRoute
          component={QuotaListContainer}
          path={routes.QUOTAS}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Quota)}
          title={formatMessage(quotasRoutesMessages.QUOTAS)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
