import { Search } from '@mui/icons-material';
import { Box, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconX } from '@tabler/icons-react';
import { type ReactNode, memo, type Dispatch, type SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';

import { eventStopPropagation, eventStopPropagationAndPreventDefault } from '../../../utils/common/eventHelpers';
import { Input } from '../formElements/inputs/Input/Input';
import { TextType, Text } from '../typography';

import { messages } from './ListPopUp.messages';

interface ListPopUpProps {
  children: ReactNode;
  buttonComponent?: ReactNode;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  title?: ReactNode | string;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    minWidth: '16rem',
    maxWidth: '16rem',
    maxHeight: '25rem',
    minHeight: '25rem',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
  title: {
    margin: '10px',
  },
  searchInput: {
    border: `1px solid ${theme.palette.grey[200]}`,
    width: '15.9rem',
    marginBottom: '10px',
  },
  listContainer: {
    marginBottom: theme.spacing(1),
    height: '17rem',
    position: 'relative',
    overflow: 'auto',
  },
  buttonContainer: {
    padding: '10px 20px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    width: '16rem',
    zIndex: 999,
  },
}));

export const ListPopUp = memo(function ListPopUp({
  children,
  search,
  setSearch,
  title,
  buttonComponent,
}: ListPopUpProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Text
          className={classes.title}
          type={TextType.INPUT_NAME}
        >
          {title || ''}
        </Text>
        <Input
          autoFocus
          className={classes.searchInput}
          placeholder={formatMessage({ defaultMessage: 'Search' })}
          startAdornment={<Search fontSize="small" />}
          value={search}
          endAdornment={
            search ? (
              <InputAdornment position="end">
                <IconButton
                  icon={<IconX />}
                  label={formatMessage(messages.CLEAR_SEARCH)}
                  onClick={() => setSearch('')}
                />
              </InputAdornment>
            ) : null
          }
          onChange={(e) => setSearch(e.currentTarget.value)}
          onClick={eventStopPropagationAndPreventDefault}
          onKeyDown={eventStopPropagation}
        />
      </Box>
      <Box className={classes.listContainer}>{children}</Box>
      {!!buttonComponent && <Box className={classes.buttonContainer}>{buttonComponent}</Box>}
    </Box>
  );
});
