import { http } from '@amal-ia/frontend/kernel/http';
import { type PlanForecast } from '@amal-ia/lib-types';

export async function get(planId: string, planForecastId: string): Promise<PlanForecast> {
  const { data } = await http.get<PlanForecast>(`/plans/${planId}/forecasts/${planForecastId}`);
  return data;
}

export async function update(planId: string, planForecastId, planForecast: PlanForecast): Promise<PlanForecast> {
  const { data } = await http.put<PlanForecast>(`/plans/${planId}/forecasts/${planForecastId}`, { ...planForecast });
  return data;
}
