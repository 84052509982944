import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  forwardRef,
  type ForwardedRef,
  cloneElement,
  type ComponentPropsWithoutRef,
} from 'react';

import { useTextOverflows } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { CheckboxIcon, type CheckboxIconProps } from '../../../general/checkbox-icon/CheckboxIcon';
import { type TablerIconElement } from '../../../general/icons/types';
import { Typography } from '../../../general/typography/Typography';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { Tooltip } from '../../tooltip/Tooltip';
import { DropdownItemContainer } from '../Dropdown.styles';

import * as styles from './DropdownItemCheckbox.styles';

export type DropdownItemCheckboxProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'button'>, 'aria-disabled' | 'aria-selected' | 'role'>,
    {
      label: ReactNode;
      icon?: TablerIconElement;
      checked?: CheckboxIconProps['checked'];
      indeterminate?: CheckboxIconProps['indeterminate'];
    },
  ]
>;

const DropdownItemCheckboxForwardRef = forwardRef(function DropdownItemCheckbox(
  {
    label,
    checked = undefined,
    icon = undefined,
    indeterminate = undefined,
    disabled = false,
    ...props
  }: DropdownItemCheckboxProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { ref: labelRef, doesTextOverflow } = useTextOverflows([label]);

  return (
    <Tooltip
      content={doesTextOverflow ? label : ''}
      placement="right"
    >
      <DropdownItemContainer>
        <UnstyledButton
          {...props}
          ref={ref}
          aria-checked={checked}
          aria-disabled={disabled}
          css={styles.dropdownItemButton}
          disabled={disabled}
          role="option"
          className={clsx(props.className, {
            [styles.IS_CHECKED_CLASSNAME]: checked,
            [styles.IS_DISABLED_CLASSNAME]: disabled,
          })}
        >
          <div css={styles.dropdownItem}>
            <CheckboxIcon
              checked={checked}
              disabled={disabled}
              indeterminate={indeterminate}
              size={CheckboxIcon.Size.SMALL}
            />

            {!!icon && (
              <ClassNames>
                {({ css }) =>
                  cloneElement(icon, {
                    width: icon.props.width || icon.props.size || 18,
                    height: icon.props.height || icon.props.size || 18,
                    color: disabled || !icon.props.color ? 'currentColor' : icon.props.color,
                    className: clsx(
                      icon.props.className,
                      css`
                        flex: none;
                      `,
                    ),
                  })
                }
              </ClassNames>
            )}

            <Typography
              ref={labelRef}
              css={styles.label}
              variant={Typography.Variant.BODY_BASE_REGULAR}
            >
              {label}
            </Typography>
          </div>
        </UnstyledButton>
      </DropdownItemContainer>
    </Tooltip>
  );
});

export const DropdownItemCheckbox = memo(DropdownItemCheckboxForwardRef);
