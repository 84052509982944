import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconLayoutGrid, IconTimeline } from '@tabler/icons-react';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { RadioButtonGroup } from '@amal-ia/frontend/design-system/components';
import { type Challenge, type Period, type TeamMap } from '@amal-ia/lib-types';
import { useStateInLocalStorage } from '@amal-ia/lib-ui';
import { type Company } from '@amal-ia/tenants/shared/types';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { LeaderboardHistogram } from '../LeaderboardHistogram';
import { LeaderboardTable } from '../LeaderboardTable/LeaderboardTable';

enum LEADERBOARD_VIEW {
  LIST = 'list',
  CHART = 'chart',
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  switchModeContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '16px',
  },
});

export interface LeaderboardDetailsProps {
  variableFormat: FormatsEnum;
  variableName: string;
  challenge: Challenge;
  usersMap: UsersMap;
  teamsMap: TeamMap;
  currentPeriod: Period;
  company: Company;
}

export const LeaderboardDetails = memo(function LeaderboardDetails({
  variableFormat,
  variableName,
  challenge,
  usersMap,
  teamsMap,
  currentPeriod,
  company,
}: LeaderboardDetailsProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const viewOptions = useMemo(
    () => [
      {
        value: LEADERBOARD_VIEW.LIST,
        icon: <IconLayoutGrid />,
        label: formatMessage({ defaultMessage: 'Switch to list view' }),
        showOnlyIcon: true,
      },
      {
        value: LEADERBOARD_VIEW.CHART,
        icon: <IconTimeline />,
        label: formatMessage({ defaultMessage: 'Switch to chart view' }),
        showOnlyIcon: true,
      },
    ],
    [formatMessage],
  );

  const [selectedView, setSelectedView] = useStateInLocalStorage<LEADERBOARD_VIEW>(
    LEADERBOARD_VIEW.LIST,
    'leaderboard_mode',
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.switchModeContainer}>
        <RadioButtonGroup<LEADERBOARD_VIEW>
          name="leaderboardView"
          options={viewOptions}
          value={selectedView}
          onChange={setSelectedView}
        />
      </Box>
      {selectedView === 'list' ? (
        <LeaderboardTable
          challenge={challenge}
          company={company}
          currentPeriod={currentPeriod}
          teamsMap={teamsMap}
          usersMap={usersMap}
          variableFormat={variableFormat}
          variableName={variableName}
        />
      ) : (
        <LeaderboardHistogram
          challenge={challenge}
          company={company}
          currentPeriod={currentPeriod}
          teamsMap={teamsMap}
          usersMap={usersMap}
          variableFormat={variableFormat}
          variableName={variableName}
        />
      )}
    </Box>
  );
});
