import { FormHelperText, type InputBaseComponentProps, type SwitchProps } from '@mui/material';
import { uniqueId } from 'lodash';
import { memo, useMemo, type ReactElement } from 'react';

import { useEditorStyles } from '../../../../utils/hooks/useEditorStyles';
import { InfoWithTooltip } from '../../InfoWithTooltip';
import { TextType, Text } from '../../typography';
import { formikToMui } from '../formikToMui';
import { FormElementContainer, InputLabel } from '../inputs/Input/Input';

import { Switch } from './Switch';

type SwitchFieldBaseProps = InputBaseComponentProps &
  Partial<Omit<SwitchProps, 'value'>> & {
    labelLeft?: string;
    help?: ReactElement | string;
    helpLeft?: ReactElement | string;
    compactMode?: boolean;
  };

export const SwitchFieldBase = memo(function SwitchFieldBase({
  label,
  labelLeft,
  help,
  helpLeft,
  error,
  onChange,
  value,
  name,
  ariaLabel,
  color,
  compactMode,
  ...rest
}: SwitchFieldBaseProps) {
  const uniqueIdentifier = useMemo(() => rest?.id || uniqueId(), [rest]);
  const editorClasses = useEditorStyles();

  return (
    <FormElementContainer
      align
      className={compactMode ? editorClasses.fieldContainer : null}
    >
      {labelLeft ? (
        <InputLabel
          compact
          htmlFor={uniqueIdentifier}
        >
          <Text type={TextType.INPUT_NAME}>{labelLeft}</Text>
        </InputLabel>
      ) : null}
      {labelLeft && helpLeft ? <InfoWithTooltip>{helpLeft}</InfoWithTooltip> : null}
      <Switch
        aria-label={ariaLabel}
        checked={value}
        color={(color as 'primary' | 'secondary') || 'secondary'}
        height={23}
        id={uniqueIdentifier}
        name={name}
        onChange={onChange}
        {...rest}
      />
      <InputLabel
        compact
        htmlFor={uniqueIdentifier}
      >
        <Text type={TextType.INPUT_NAME}>{label}</Text>
      </InputLabel>
      {help ? <InfoWithTooltip>{help}</InfoWithTooltip> : null}
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormElementContainer>
  );
});

export const SwitchField = memo<SwitchFieldBaseProps>(formikToMui(SwitchFieldBase));
