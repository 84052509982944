export const STATEMENTS_ACTIONS = {
  START: 'STATEMENTS/START',
  ERROR: 'STATEMENTS/ERROR',

  SET_STATEMENTS_SUCCESS: 'STATEMENTS/SET_STATEMENTS_SUCCESS',
  SET_STATEMENT_SUCCESS: 'STATEMENTS/SET_STATEMENT_SUCCESS',
  SET_FORECASTED_STATEMENT_SUCCESS: 'STATEMENTS/SET_FORECASTED_STATEMENT_SUCCESS',

  SET_STATEMENT_STATISTICS_SUCCESS: 'STATEMENTS/SET_STATEMENT_STATISTICS_SUCCESS',

  CLEAR_STATEMENTS: 'STATEMENTS/CLEAR_STATEMENTS',
  CLEAR_STATEMENT: 'STATEMENTS/CLEAR_STATEMENT',

  SET_USER_STATEMENTS_SUCCESS: 'STATEMENTS/SET_USER_STATEMENTS_SUCCESS',

  SET_CURRENT_PERIOD_SUCCESS: 'STATEMENTS/SET_CURRENT_PERIOD_SUCCESS',

  PROCESS_WORKFLOW_STEP: 'STATEMENTS/PROCESS_WORKFLOW_STEP',

  CREATE_ADJUSTMENT: 'STATEMENTS/CREATE_ADJUSTMENT',
  EDIT_ADJUSTMENT: 'STATEMENTS/EDIT_ADJUSTMENT',
  DELETE_ADJUSTMENT: 'STATEMENTS/DELETE_ADJUSTMENT',

  CREATE_OVERWRITE: 'STATEMENTS/CREATE_OVERWRITE',
  CLEAR_OVERWRITE: 'STATEMENTS/CLEAR_OVERWRITE',
  CREATE_SIMULATED_OVERWRITE: 'STATEMENTS/CREATE_SIMULATED_OVERWRITE',
  CLEAR_SIMULATED_OVERWRITE: 'STATEMENTS/CLEAR_SIMULATED_OVERWRITE',

  SET_CURRENT_STATEMENT_THREADS: 'STATEMENTS/SET_CURRENT_STATEMENT_THREADS',
  SET_STATEMENT_THREADS_LIST: 'STATEMENTS/SET_STATEMENT_THREADS_LIST',
  CREATE_STATEMENT_THREAD: 'STATEMENTS/CREATE_STATEMENT_THREAD',
  SET_STATEMENT_THREAD_MESSAGES: 'STATEMENTS/SET_STATEMENT_THREAD_MESSAGES',
  ADD_STATEMENT_THREAD_MESSAGE: 'STATEMENTS/ADD_STATEMENT_THREAD_MESSAGE',
  SET_STATEMENT_THREAD_REVIEWED: 'STATEMENTS/SET_STATEMENT_THREAD_REVIEWED',

  CHANGE_LIST_FILTERS: 'STATEMENTS/CHANGE_LIST_FILTERS',
} as const;
