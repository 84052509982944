import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import NotificationSection, { type NotificationsSection } from './NotificationSection';
import { notificationsSettingsMessages } from './NotificationsSettings.messages';

const useStyles = makeStyles({
  line: {
    padding: '0 20px',
    display: 'grid',
    gridTemplateColumns: '1fr 100px 100px',
    alignItems: 'center',
  },
});

interface NotificationsSettingsProps {
  sections: NotificationsSection[];
}

const NotificationsSettings = memo(function NotificationsSettings({ sections }: NotificationsSettingsProps) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.line}>
        <span />
        <span>
          <FormattedMessage {...notificationsSettingsMessages.EMAIL} />
        </span>
        <span>
          <FormattedMessage {...notificationsSettingsMessages.SLACK} />
        </span>
      </div>
      {sections.map((s) => (
        <NotificationSection
          key={s.key}
          lines={s.lines}
          title={s.title}
        />
      ))}
    </div>
  );
});

export default NotificationsSettings;
