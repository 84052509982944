import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { type Challenge } from './challenge';
import { type Overwrite } from './overwrite';
import { type Period } from './period';
import { type Plan } from './plans';
import { type Rule } from './rules';
import { type Adjustment, type Statement } from './statement';
import { type Variable, type VariableDefinition } from './variables';

export enum PaymentCategory {
  achievement = 'achievement',
  hold = 'hold',
  paid = 'paid',
  adjustments = 'adjustments',
}

export enum PaymentType {
  RULE_PAYOUT = 'RULE_PAYOUT',
  RULE_LINE_PAYOUT = 'RULE_LINE_PAYOUT',
  RULE_SPLIT = 'RULE_SPLIT',
  LINE_HOLD = 'LINE_HOLD',
  ADJUSTMENT = 'ADJUSTMENT',
  CHALLENGE = 'CHALLENGE',
}

export const PaymentTypeLabels: Record<PaymentType, string> = {
  [PaymentType.RULE_PAYOUT]: 'Rule Payout',
  [PaymentType.RULE_LINE_PAYOUT]: 'Rule - Line Payout',
  [PaymentType.RULE_SPLIT]: 'Rule Split',
  [PaymentType.LINE_HOLD]: 'Line Hold',
  [PaymentType.ADJUSTMENT]: 'Adjustment',
  [PaymentType.CHALLENGE]: 'Challenge',
};

export interface Payment {
  id: string;

  companyId?: string;

  period: Period;
  periodId: string;

  plan?: Plan;
  planId?: string;

  user?: UserContract;
  userId: string;

  statement: Partial<Statement>;
  statementId: string;

  type: PaymentType;

  value: number;
  totalValue?: number;
  rate: number;
  currency: CurrencySymbolsEnum;

  adjustmentId?: string;

  paymentPeriod?: Period;
  paymentPeriodId?: string;

  ruleId?: string;
  rule?: Partial<Rule>;

  dealExternalId?: string;
  dealName?: string;
  dealObjectName?: string;

  paymentDate?: Date;
  forecastPaymentDate?: Date;

  objectVariable?: VariableDefinition;
  variableName?: string;

  adjustment?: Adjustment;

  challenge?: Challenge;
  challengeId?: string;

  masterPayment?: Payment;
  masterPaymentId?: string;

  overwrites?: Overwrite[];
}

export interface PatchPaymentRequest {
  paymentPeriodId: string | null;
}

// SPLIT
export interface PaymentSplitPart {
  value: number;
  paymentPeriodId: string;
}

export interface PaymentSplitConfiguration {
  splitParts: PaymentSplitPart[];
}

export interface PaymentLock {
  period: Period;
  periodId: string;
}

export type PaymentsMap = Record<string, Payment[]>;

export type PaymentAggregatedItem = {
  type: PaymentCategory;
  sum: number;
  userId: string;
  statementId: string;
  currency: CurrencySymbolsEnum;
  rate: number;
  planId?: string;
  teamId?: string;
};

export type PaymentAggregatedList = Record<PaymentCategory, PaymentAggregatedItem[]>;
export type PaymentAggregatedListWithFlag = PaymentAggregatedList & { containsHoldRules: boolean };
export type PaymentAggregatedListByUser = Record<PaymentCategory, Record<string, PaymentAggregatedItem[]>>;

export enum PaymentButtonStatus {
  none = 'none',
  unpaid = 'unpaid',
  paid = 'paid',
}

export interface PaymentContract {
  id: string;

  statement: Statement;

  companyId: string;

  challengeId?: string;

  masterPaymentId?: string;

  totalValue?: number;

  userId?: string;

  adjustmentId?: string;

  statementId: string;

  type: PaymentType;

  value: number;

  rate: number;

  currency: CurrencySymbolsEnum;

  periodId: string;

  period?: Omit<Period, 'company'>;

  plan?: Pick<Plan, 'id' | 'name'>;

  planId: string;

  paymentPeriod?: Pick<Period, 'endDate' | 'frequency' | 'name' | 'startDate'>;

  paymentPeriodId?: string;

  rule?: Pick<Rule, 'id' | 'name'>;

  ruleId?: string;

  user?: Pick<UserContract, 'firstName' | 'id' | 'lastName' | 'pictureURL'>;

  objectVariable?: Variable;

  variableName?: string;

  dealExternalId?: string;

  dealName?: string;

  dealObjectName?: string;

  adjustment?: Adjustment;

  paymentDate?: Date;

  forecastPaymentDate?: Date;

  overwrites: Pick<Overwrite, 'field' | 'id' | 'overwriteValue' | 'sourceValue'>[];
}
