import { hashQueryKey } from '@tanstack/react-query';
import { pick } from 'lodash';
import { useCallback, useEffect } from 'react';
import * as Yup from 'yup';

import { SortDirection } from '../DataGrid.types';

import { type DataGridState, useDataGridState } from './useDataGridState';

const DATAGRID_KEY_PREFIX = 'datagrid';

const schema = Yup.object({
  page: Yup.number(),
  pageSize: Yup.number(),
  columnVisibility: Yup.object(), // Yup doesn't support Record types.
  columnOrder: Yup.array(Yup.string().required()),
  columnSorting: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      direction: Yup.string().oneOf(Object.values(SortDirection)).required(),
    }),
  ),
  searchText: Yup.string(),
  columnPinning: Yup.boolean(),
});

type PersistableDataGridStateKey = keyof Omit<DataGridState, 'page'>;

const getFromLocalStorage = (key: string, fieldsToPersist: PersistableDataGridStateKey[]) => {
  const localStorageState = localStorage.getItem(key);
  try {
    return localStorageState
      ? (schema.validateSync(pick(JSON.parse(localStorageState), fieldsToPersist), { strict: true }) as DataGridState)
      : undefined;
  } catch (err) {
    return undefined;
  }
};

export const useDataGridStateInLocalStorage = (
  key: unknown,
  {
    initialState,
    fieldsToPersist = ['pageSize', 'columnVisibility', 'columnOrder', 'columnSorting', 'searchText', 'columnPinning'],
  }: {
    /** Initial state. Fields that are persisted in local storage overrides these values. */
    initialState?: Partial<DataGridState> | (() => Partial<DataGridState>);
    /** Only persist a subset of fields of the data grid state. Defaults to persist everything except the page. */
    fieldsToPersist?: PersistableDataGridStateKey[];
  } = {},
) => {
  // Use same algorithm as react-query because they handle object stringifying in a stable way.
  const hashedKey = hashQueryKey(([DATAGRID_KEY_PREFIX] as unknown[]).concat(key));

  // Init state from local storage.
  const dataGridState = useDataGridState(() => ({
    ...(typeof initialState === 'function' ? initialState() : initialState),
    ...getFromLocalStorage(hashedKey, fieldsToPersist),
  }));

  // When the state changes, persist it in local storage.
  useEffect(
    () => localStorage.setItem(hashedKey, JSON.stringify(pick(dataGridState, fieldsToPersist))),
    [hashedKey, dataGridState, fieldsToPersist],
  );

  const handleClear = useCallback(() => localStorage.removeItem(hashedKey), [hashedKey]);

  return {
    ...dataGridState,
    clear: handleClear,
  };
};
