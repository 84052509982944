import { type Action } from 'redux';
import { type ThunkAction } from 'redux-thunk';

import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { type StatementThreadScope } from './comments';

export interface ReduxAction<TActionType = any, TPayload = any, TError = any> extends Action<TActionType> {
  payload?: TPayload;
  error?: TError;
  isErrorRecoverable?: boolean;
}

export type DefaultRootState = object;

export interface RootState extends DefaultRootState {
  audit: any;
  comments: {
    openedThreadId?: string | null;
    openedThreadOptions: StatementThreadScope | null;
  };
  companies: any;
  currencies: any;
  customObjects: any;
  customObjectDefinitions: any;
  filters: any;
  overwrites: any;
  payments: any;
  periods: any;
  plans: any;
  planAgreements: any;
  planAssignments: any;
  planForecasts: any;
  snackbars: any;
  statements: any;
  teams: any;
  todos: any;
  users: {
    isLoading: boolean;
    map: UsersMap;
    allUsersLoaded: boolean;
    allActiveUsersLoaded: boolean;
  };
  userStatements: any;
  variables: any;
  rules: any;
  designer: any;
  superAdmin: any;
  loading: any;
  flows: any;
  slack: any;
}

export type ThunkResult<R> = ThunkAction<R, RootState, unknown, ReduxAction>;

export interface PaginationStoredInRedux<T> {
  currentPage: number | null;
  pageCount: number | null;
  totalItems: number;
  items: T[];
}

export const INITIAL_REDUX_PAGINATION = {
  currentPage: null,
  pageCount: null,
  items: [],
  totalItems: 0,
};
