import invariant from 'invariant';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type UserRole } from '@amal-ia/tenants/users/shared/types';

import { UserRoleLabels } from './roles.messages';

export type UserRoleLabelProps = {
  role: UserRole;
};

export const UserRoleLabel = memo(function UserRoleLabel({ role }: UserRoleLabelProps) {
  invariant(role in UserRoleLabels, `Invalid user role: ${role}`);

  return <FormattedMessage {...UserRoleLabels[role]} />;
});
