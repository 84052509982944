import { defineMessages } from 'react-intl';

export const customObjectRoutesMessages = defineMessages({
  CONNECTION: {
    defaultMessage: 'Connection',
  },
  DATA_CONNECTOR: {
    defaultMessage: 'Data connector',
  },
  DATA_CONNECTOR_CONNECTION: {
    defaultMessage: 'Data connector | Connection',
  },
  DATA_USER_CONNECTOR_LIST: {
    defaultMessage: 'User connectors',
  },
  DATA_CONNECTOR_LIST: {
    defaultMessage: 'Data connectors',
  },
} as const);
