import { useSelector } from 'react-redux';

import { selectCompany } from '@amal-ia/frontend/web-data-layers';
import { DEFAULT_REPORTS_PRECISION } from '@amal-ia/lib-types';
import { type CompanyCustomization } from '@amal-ia/tenants/shared/types';

/**
 * Better be realistic with the type here, if a configuration option disappears for instance.
 */
export const useCompanyCustomization = (): Partial<CompanyCustomization> | undefined => {
  const company = useSelector(selectCompany);

  return {
    holdAndReleasePaymentDateLabel: company?.customization?.holdAndReleasePaymentDateLabel || '',
    holdAndReleaseForecastPaymentDateLabel: company?.customization?.holdAndReleaseForecastPaymentDateLabel || '',
    totalCommissionLabel: company?.customization?.totalCommissionLabel || '',
    reportsPrecision: company?.customization?.reportsPrecision || DEFAULT_REPORTS_PRECISION,
    legacyKpiCardView: company?.customization?.legacyKpiCardView || false,
  };
};
