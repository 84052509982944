import { IconFiles, IconTrash, IconPencil } from '@tabler/icons-react';
import { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ColumnHelper, Table } from '@amal-ia/frontend/design-system/components';
import { fuzzyFilter } from '@amal-ia/frontend/kernel/search/filters';
import { type WorkflowDefinition } from '@amal-ia/lib-types';

import { useWorkflowStepName } from '../../statement/workflow/useWorkflowStepName';
import { formatStepNameForDisplay } from '../WorkflowStepper/WorkflowStepperStep';

import * as styles from './WorkflowDataGrid.styles';

const columnHelper = new ColumnHelper<WorkflowDefinition>();

export const useWorkflowDataGridColumns = ({
  onClickEdit,
  onClickDuplicate,
  onClickDelete,
}: {
  onClickEdit: (workflow: WorkflowDefinition) => void;
  onClickDuplicate: (workflow: WorkflowDefinition) => void;
  onClickDelete: (workflow: { rowId: string; name: string } | null) => void;
}) => {
  const { formatMessage } = useIntl();
  const { formatStepName } = useWorkflowStepName();

  return useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: formatMessage({ defaultMessage: 'Name' }),
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor('steps', {
        id: 'steps',
        header: formatMessage({ defaultMessage: 'Steps' }),
        size: null,
        filterFn: (steps, textToMatch) =>
          fuzzyFilter(steps.map((step) => formatStepName(step.stepName)).join(' '), textToMatch),
        cell: ({ value: steps, row: workflow }) => (
          <Table.Cell>
            <div css={styles.stepsContainer}>
              {steps.map((step, index) => (
                <div
                  key={formatStepName(step.stepName)}
                  aria-checked
                  css={styles.step}
                  data-last={index === steps.length - 1}
                  aria-label={formatMessage(
                    { defaultMessage: 'Workflow {workflowName} - step #{stepNumber, number}' },
                    { workflowName: workflow.name, stepNumber: index + 1 },
                  )}
                >
                  <div css={styles.dot} />
                  <span>{formatStepNameForDisplay(formatStepName(step.stepName))}</span>
                  <hr />
                </div>
              ))}
            </div>
          </Table.Cell>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: formatMessage({ defaultMessage: 'Actions' }),
        size: 0,
        cell: ({ row: workflow }) => (
          <Table.Cell.Actions>
            <Table.Cell.IconAction
              icon={<IconFiles />}
              label={formatMessage({ defaultMessage: 'Duplicate {workflowName}' }, { workflowName: workflow.name })}
              onClick={() => onClickDuplicate(workflow)}
            />

            {workflow.machineName !== 'default' && (
              <Fragment>
                <Table.Cell.IconAction
                  icon={<IconPencil />}
                  label={formatMessage({ defaultMessage: 'Edit {workflowName}' }, { workflowName: workflow.name })}
                  onClick={() => onClickEdit(workflow)}
                />
                <Table.Cell.IconAction
                  icon={<IconTrash />}
                  label={formatMessage({ defaultMessage: 'Delete {workflowName}' }, { workflowName: workflow.name })}
                  variant={Table.Cell.IconAction.Variant.DANGER}
                  onClick={() => onClickDelete({ rowId: workflow.id, name: workflow.name })}
                />
              </Fragment>
            )}
          </Table.Cell.Actions>
        ),
      }),
    ],
    [formatMessage, formatStepName, onClickEdit, onClickDuplicate, onClickDelete],
  );
};
