import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('EXACT', AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 2;

func.description = 'Return true if a string exactly matches another string';

func.params = [
  { name: 'string1', description: 'String to compare: variables, fields, properties, string' },
  { name: 'string2', description: 'String to compare: variables, fields, properties, string' },
];

func.examples = [
  {
    desc: 'Returns true if Opportunity type is Renewal.',
    formula: 'EXACT(opportunity.type, "Renewal")' as AmaliaFormula,
  },
  {
    desc: 'Returns false since the two strings are not exactly the same.',
    formula: 'EXACT("Jean", "Jean Dupont")' as AmaliaFormula,
  },
];

func.exec = (text1: string, text2: string): boolean => text1 === text2;

export default func;
