import { defineMessages } from 'react-intl';

export const cardMessages = defineMessages({
  NO_DATA: {
    defaultMessage: 'No data',
  },
  NO_DATA_INFO: {
    defaultMessage: 'No data available for the selected view.',
  },
  NO_KPI_INFO: {
    defaultMessage: 'No highlight KPI to display. Please contact your admin if you believe this is an error.',
  },
});
