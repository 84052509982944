import { http } from '@amal-ia/frontend/kernel/http';
import { type Period } from '@amal-ia/lib-types';

export async function saGetPeriods(companyId: string): Promise<Period[]> {
  const { data } = await http.get<Period[]>('/sa_periods', {
    params: {
      companyId,
    },
  });
  return data;
}
