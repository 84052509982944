// AmaliaFormula will inherit from string type so string operations will be authorized.
export type AmaliaFormula = string & { readonly _?: unique symbol };

export enum AmaliaFunctionCategory {
  ARRAY = 'ARRAY',
  CURRENCY = 'CURRENCY',
  DATES = 'DATES',
  INDICES = 'INDICES',
  MISC = 'MISC',
  NUMBERS = 'NUMBERS',
  STRING = 'STRING',
}

export enum FormulaBuilderFunctionCategory {
  DATE = 'DATE',
  STRING = 'STRING',
  USER = 'USER',
  NUMBER = 'NUMBER',
  PERCENT = 'PERCENT',
  BOOLEAN = 'BOOLEAN',
  CURRENCY = 'CURRENCY',
}
