import { type ComputedPlanRuleFieldsToDisplay } from '@amal-ia/compensation-definition/shared/types';
import { type Property as PropertyBase, type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type ComputedRule, type DatasetRow } from '@amal-ia/lib-types';
import { type Dataset } from '@amal-ia/payout-calculation/shared/types';

export type Property = PropertyBase & {
  id: string | undefined;
  name: string;
  format: FormatsEnum;
  definition: CustomObjectDefinition;
};

export type FunctionDetailsType = {
  numberOfParams: number;
};

export type CurrentTracingDataType = {
  rule: ComputedRule;
  datasetRow?: DatasetRow;
  dataset?: Dataset;
  fields?: ComputedPlanRuleFieldsToDisplay[];
};

export enum MathJsNodeType {
  AccessorNode = 'AccessorNode',
  ArrayNode = 'ArrayNode',
  ConstantNode = 'ConstantNode',
  FunctionNode = 'FunctionNode',
  OperatorNode = 'OperatorNode',
  ParenthesisNode = 'ParenthesisNode',
  SymbolNode = 'SymbolNode',
}

export enum FormulaNodeType {
  array = 'array',
  constant = 'constant',
  custom_object = 'custom_object',
  filter_dataset = 'filter_dataset',
  formula = 'formula',
  function = 'function',
  operator = 'operator',
  rule = 'rule',
  statement = 'statement',
  variable = 'variable',

  user = 'user',
  team = 'team',
  plan = 'plan',

  // Specific types
  rowMarginal = 'rowMarginal',
}

// All types accepted as a tracing block
export enum TracingBlockType {
  FILTER = 'FILTER',
  FUNCTION = 'FUNCTION',
  CUSTOM_OBJECT = 'CUSTOM_OBJECT',
  RULE = 'RULE',
  STATEMENT = 'STATEMENT',
  TABLE = 'TABLE',
  VARIABLE = 'VARIABLE',

  USER = 'USER',
  TEAM = 'TEAM',
  PLAN = 'PLAN',

  // SPECIFIC TYPES
  ROW_MARGINAL = 'ROW_MARGINAL',
}

// Type of a tracing label
export enum TracingLabelType {
  filter = 'filter',
  function = 'function',
  other = 'other',
  property = 'property',
  table = 'table',
}
