import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('toDate', AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Convert any date format to a timestamp';
// 'A format can be specified if the date is not formatted like `YYYY-MM-DD`. '
// 'The date will be formatted by moment.js (see documentation here https://momentjs.com/docs/#/parsing/string-format/ or examples below).'

func.params = [
  {
    name: 'dateString',
    description: 'Date in any format: variables, properties, fields, keywords or a specific date in defined format',
  },
  { name: 'format', description: 'Format of the dateString that is by default YYYY-MM-DD', defaultValue: 'YYYY-MM-DD' },
];

func.examples = [
  {
    desc: 'Returns 1685577600.',
    formula: 'toDate("2023-06-01")' as AmaliaFormula,
    result: '1685577600',
  },
  {
    desc: 'Returns 1685577600.',
    formula: 'toDate("2023/06/01", "YYYY/MM/DD")' as AmaliaFormula,
    result: '1685577600',
  },
  {
    desc: 'Returns 1685577600 for June 1st 2023.',
    formula: 'toDate("Jun 23", "MMM YY")' as AmaliaFormula,
    result: '1685577600',
  },
  {
    desc: 'Returns Close Date in timestamp converted from a MMM YY format.',
    formula: 'toDate(opportunity.closeDate, "MMM YY")',
  },
];

func.exec = (dateString: string, format: string | undefined): string | null =>
  moment(dateString, format || 'YYYY-MM-DD').format('X');

export default func;
