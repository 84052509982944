import { css } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { memo, Suspense, type ReactNode, useState } from 'react';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { Spinner } from '@amal-ia/lib-ui';

import { LEFT_BAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from './Layout.constants';
import { LayoutContext, type LayoutContextValue } from './Layout.context';
import { LeftBar, type LeftBarProps } from './left-bar/LeftBar';

export type LayoutProps = {
  currentPage?: LeftBarProps['currentPage'];
  children: ReactNode;
};

export const Layout = memo(function Layout({ currentPage, children }: LayoutProps) {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const [isPortalActive, setIsPortalActive] = useState(false);
  const pxToRemove = isPortalActive ? LEFT_BAR_WIDTH + RIGHT_SIDEBAR_WIDTH : LEFT_BAR_WIDTH;

  const layoutContextValue: LayoutContextValue = useShallowObjectMemo({ portalElement, setIsPortalActive });
  return (
    <div
      css={css`
        display: flex;
        padding-top: 8px;
        height: 100%;
      `}
    >
      <LayoutContext.Provider value={layoutContextValue}>
        <div
          css={(theme) => css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 8px;
            background-image: linear-gradient(
              106deg,
              ${theme.ds.colors.primary[500]} 0%,
              ${theme.ds.colors.primary[300]} 100%
            );
            z-index: 2;
          `}
        />

        <CssBaseline />

        <LeftBar currentPage={currentPage} />

        <main
          css={css`
            flex-grow: 1;
            padding-left: ${LEFT_BAR_WIDTH}px;
            ${isPortalActive && `padding-right: ${RIGHT_SIDEBAR_WIDTH}px;`}
            width: calc(100% - ${pxToRemove}px);
            position: relative;
          `}
        >
          <Suspense
            fallback={
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                `}
              >
                <Spinner />
              </div>
            }
          >
            {children}
          </Suspense>
        </main>

        {/* This is the statement comment section, declared in CommentDrawerContainer */}
        <div ref={setPortalElement} />
      </LayoutContext.Provider>
    </div>
  );
});
