import clsx from 'clsx';
import { type ReactNode, memo, cloneElement } from 'react';

import { Link, type LinkProps } from '@amal-ia/ext/react-router-dom';

import { type TablerIconElement } from '../../../general/icons/types';
import { Typography } from '../../../general/typography/Typography';

import * as styles from './MenuItem.styles';

export type MenuItemProps = {
  /** Href. */
  to: LinkProps['to'];
  /** Active state. */
  isActive?: boolean;
  /** Left icon. */
  icon: TablerIconElement;
  /** Label. */
  children: ReactNode;
};

export const MenuItem = memo(function MenuItem({ to, isActive, icon, children }: MenuItemProps) {
  return (
    <li css={styles.menuItem}>
      <Link
        className={clsx({ [styles.IS_ACTIVE_CLASSNAME]: isActive })}
        css={styles.menuItemLink}
        to={to}
      >
        {cloneElement(icon, {
          size: 18,
        })}

        <Typography variant={Typography.Variant.BUTTON_LARGE}>{children}</Typography>
      </Link>
    </li>
  );
});
