import { type TooltipProps, Box } from '@mui/material';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { type Payment } from '@amal-ia/lib-types';
import { Tooltip, TextType, Text } from '@amal-ia/lib-ui';
import { type CompanyCustomization } from '@amal-ia/tenants/shared/types';

interface PaymentTooltipProps extends TooltipProps {
  payment: Payment;
  formattedValue: any;
  companyCustomization: Partial<CompanyCustomization>;
}

export const PaymentTooltip = memo(function PaymentTooltip({
  title,
  payment,
  children,
  formattedValue,
  companyCustomization,
  ...restProps
}: PaymentTooltipProps) {
  const textToDisplay = useMemo(() => {
    switch (true) {
      // Locked but we don't have any info.
      case !payment.paymentPeriodId && !payment.paymentDate && !payment.forecastPaymentDate:
        return (
          <FormattedMessage
            defaultMessage="Not yet released."
            description="Payment not yet released."
          />
        );

      // Locked and unpaid.
      case !payment.paymentPeriodId && !payment.paymentDate && !!payment.forecastPaymentDate:
        return (
          <FormattedMessage
            defaultMessage="{holdAndReleaseDateLabel}: {date}"
            values={{
              holdAndReleaseDateLabel: companyCustomization?.holdAndReleaseForecastPaymentDateLabel || (
                <FormattedMessage defaultMessage="Forecast payment date" />
              ),
              date: moment.utc(payment.forecastPaymentDate).format('YYYY-MM-DD'),
            }}
          />
        );

      // Locked and paid.
      case !payment.paymentPeriodId && !!payment.paymentDate:
        return (
          <FormattedMessage
            defaultMessage="{holdAndReleaseDateLabel}: {date}"
            values={{
              holdAndReleaseDateLabel: companyCustomization?.holdAndReleasePaymentDateLabel || (
                <FormattedMessage defaultMessage="Payment date" />
              ),
              date: moment.utc(payment.paymentDate).format('YYYY-MM-DD'),
            }}
          />
        );

      // Unlocked.
      case !!payment.paymentPeriodId && !!payment.paymentPeriod:
        return (
          <FormattedMessage
            defaultMessage="Released on period: {periodName}"
            values={{ periodName: payment.paymentPeriod.name }}
          />
        );
      // This should not happen normally.
      default:
        return null;
    }
  }, [payment, companyCustomization]);

  return (
    <Tooltip
      {...restProps}
      title={
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
          py={1}
        >
          <Text type={TextType.TOOLTIP}>{title}</Text>
          <Box textAlign="center">
            <Text
              style={{ color: amaliaTheme.palette.primary.main }}
              type={TextType.PAYOUT_IN_TOOLTIP}
            >
              {formattedValue}
            </Text>
          </Box>
          <Box textAlign="center">{textToDisplay}</Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
});
