import { type FunctionComponent } from 'react';

import {
  IconBadgeColorBestDeal,
  IconBadgeColorFirst,
  IconBadgeColorInternational,
  IconBadgeColorOverPerformer,
  IconBadgeColorSecond,
  IconBadgeColorStar,
  IconBadgeColorTeamPlayer,
  IconBadgeColorThird,
  IconBadgeColorWinner,
  IconBadgeColorWinRate,
  IconBadgeGreyBestDeal,
  IconBadgeGreyFirst,
  IconBadgeGreyInternational,
  IconBadgeGreyOverPerformer,
  IconBadgeGreySecond,
  IconBadgeGreyStar,
  IconBadgeGreyTeamPlayer,
  IconBadgeGreyThird,
  IconBadgeGreyWinner,
  IconBadgeGreyWinRate,
} from '@amal-ia/frontend/ui-icons';
import { BadgeConfigurationIcon } from '@amal-ia/lib-types';

export interface BadgeConfigurationIconDetails {
  iconGrey: FunctionComponent;
  iconColor: FunctionComponent;
}

export const BADGE_LOGOS: Record<BadgeConfigurationIcon, BadgeConfigurationIconDetails> = {
  [BadgeConfigurationIcon.BEST_DEAL]: {
    iconColor: IconBadgeColorBestDeal,
    iconGrey: IconBadgeGreyBestDeal,
  },
  [BadgeConfigurationIcon.FIRST]: {
    iconColor: IconBadgeColorFirst,
    iconGrey: IconBadgeGreyFirst,
  },
  [BadgeConfigurationIcon.SECOND]: {
    iconColor: IconBadgeColorSecond,
    iconGrey: IconBadgeGreySecond,
  },
  [BadgeConfigurationIcon.THIRD]: {
    iconColor: IconBadgeColorThird,
    iconGrey: IconBadgeGreyThird,
  },
  [BadgeConfigurationIcon.INTERNATIONAL]: {
    iconColor: IconBadgeColorInternational,
    iconGrey: IconBadgeGreyInternational,
  },
  [BadgeConfigurationIcon.OVER_PERFORMER]: {
    iconColor: IconBadgeColorOverPerformer,
    iconGrey: IconBadgeGreyOverPerformer,
  },
  [BadgeConfigurationIcon.STAR]: {
    iconColor: IconBadgeColorStar,
    iconGrey: IconBadgeGreyStar,
  },
  [BadgeConfigurationIcon.TEAM_PLAYER]: {
    iconColor: IconBadgeColorTeamPlayer,
    iconGrey: IconBadgeGreyTeamPlayer,
  },
  [BadgeConfigurationIcon.WINNER]: {
    iconColor: IconBadgeColorWinner,
    iconGrey: IconBadgeGreyWinner,
  },
  [BadgeConfigurationIcon.WIN_RATE]: {
    iconColor: IconBadgeColorWinRate,
    iconGrey: IconBadgeGreyWinRate,
  },
};
