import { memo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { type DataConnector } from '@amal-ia/data-capture/connectors/types';

import { DataModal } from './DataModal';

interface DataModalContainerProps {
  isOpen: boolean;
  handleRefreshment: (
    dataConnectorObjectNames: { type: string; name: string }[],
    params: {
      willLaunchCalculation: boolean;
      willPrune: boolean;
      willFullSync: boolean;
      startOffset: number;
    },
  ) => void;
  onClose: () => void;
  defaultDataConnectorObjectName: string;
  listConnectors: () => any;
}

export const DataModalContainer = memo(function DataModalContainer({
  isOpen,
  handleRefreshment,
  onClose,
  defaultDataConnectorObjectName,
  listConnectors,
}: DataModalContainerProps) {
  const [connectors, setConnectors] = useState<DataConnector[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useAsyncEffect(async () => {
    const connectorsList = await listConnectors();
    setConnectors(connectorsList);
    setIsLoading(false);
  }, []);

  return (
    <DataModal
      connectors={connectors}
      defaultDataConnectorObjectName={defaultDataConnectorObjectName}
      handleRefreshment={handleRefreshment}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
});
