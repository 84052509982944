import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { useCurrentCompany } from '@amal-ia/frontend/web-data-layers';

import { planAgreementsRoutesMessages } from './planAgreements.routes.messages';

const PlanAgreementDetailsContainer = lazy(() => import('./details/PlanAgreementDetailsContainer'));
const PlanAgreementDetailsPreviewContainer = lazy(() => import('./details/PlanAgreementDetailsPreviewContainer'));
const PlanAgreementDetailsViewContainer = lazy(() => import('./details/PlanAgreementDetailsViewContainer'));
const PlanAgreementsContainer = lazy(() => import('./list/PlanAgreementsContainer'));

export const PlanAgreementsRoutes = memo(function PlanAgreementsRoutes() {
  const { data: user } = useCurrentUser();
  const { company } = useCurrentCompany();
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="planAgreements">
      <Switch>
        <CompanyRoute
          component={PlanAgreementDetailsViewContainer}
          path={routes.PLAN_AGREEMENT_ASSIGNMENT_VIEW_DOCUMENT}
          shouldRedirectToHome={!company.featureFlags?.PLAN_AGREEMENTS}
          title={formatMessage(planAgreementsRoutesMessages.VIEW_DOCUMENT)}
          user={user}
        />

        <CompanyRoute
          component={PlanAgreementDetailsPreviewContainer}
          path={routes.PLAN_AGREEMENT_PREVIEW_DOCUMENT}
          shouldRedirectToHome={!company.featureFlags?.PLAN_AGREEMENTS}
          title={formatMessage(planAgreementsRoutesMessages.PREVIEW_DOCUMENT)}
          user={user}
        />

        <CompanyRoute
          component={PlanAgreementDetailsContainer}
          path={routes.PLAN_AGREEMENT}
          shouldRedirectToHome={!company.featureFlags?.PLAN_AGREEMENTS}
          title={formatMessage(planAgreementsRoutesMessages.PLAN_AGREEMENT)}
          user={user}
        />

        <CompanyRoute
          component={PlanAgreementsContainer}
          path={routes.PLAN_AGREEMENTS}
          shouldRedirectToHome={!company.featureFlags?.PLAN_AGREEMENTS}
          title={formatMessage(planAgreementsRoutesMessages.PLAN_AGREEMENTS)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
