import { Unstable_Grid2 as Grid } from '@mui/material';
import moment from 'moment';
import { type Dispatch, memo, type SetStateAction, useCallback } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { type OverwriteCreationRequestDetails, OverwriteTooltip } from '@amal-ia/data-capture/overwrites/components';
import { useCompanyCustomization } from '@amal-ia/frontend/web-data-layers';
import {
  type ComputedVariable,
  formatTotal,
  formatValueTotal,
  type StatementThread,
  type VariableDefinition,
} from '@amal-ia/lib-types';
import { StatementKpi } from '@amal-ia/lib-ui-business';
import { type ComputeEnginePrimitiveTypes } from '@amal-ia/payout-calculation/shared/types';

export interface OverwritableKPIProps {
  computedVariable: ComputedVariable;
  variableDefinition: VariableDefinition;
  isReadOnly: boolean;
  canComment: boolean;
  resetOverwrite: (variable: ComputedVariable) => Promise<void>;
  statementThread?: StatementThread | null;
  openStatementThread: (statementThreadId: string | null, variable: ComputedVariable) => void;
  ruleDefinition: PlanRule;
  setOverwriteObjectDetails: Dispatch<SetStateAction<OverwriteCreationRequestDetails>>;
  openOverwriteModal: () => void;
}

const OverwritableKPI = memo(function OverwritableKPI({
  computedVariable,
  variableDefinition,
  isReadOnly,
  canComment,
  resetOverwrite,
  statementThread,
  openStatementThread,
  ruleDefinition,
  setOverwriteObjectDetails,
  openOverwriteModal,
}: OverwritableKPIProps) {
  const { legacyKpiCardView } = useCompanyCustomization();

  const resetOverwriteProxy = useCallback(() => resetOverwrite(computedVariable), [resetOverwrite, computedVariable]);

  const handleOpenOverwriteModal = useCallback(() => {
    setOverwriteObjectDetails({
      format: variableDefinition.format,
      oldValue: (computedVariable.overwrite?.overwriteValue || computedVariable.value) as number,
      currency: computedVariable.currency,
      rule: ruleDefinition.name,
      field: variableDefinition.name,
      isProperty: false,
      machineName: variableDefinition.machineName,
    });
    openOverwriteModal();
  }, [
    openOverwriteModal,
    setOverwriteObjectDetails,
    computedVariable.currency,
    computedVariable.overwrite?.overwriteValue,
    computedVariable.value,
    ruleDefinition.name,
    variableDefinition.format,
    variableDefinition.name,
    variableDefinition.machineName,
  ]);

  const openKpiComments = useCallback(
    () => openStatementThread(statementThread?.id || null, computedVariable),
    [statementThread, openStatementThread, computedVariable],
  );

  return (
    <Grid key={computedVariable.variableMachineName}>
      {computedVariable.overwrite ? (
        <OverwriteTooltip
          author={computedVariable.overwrite.creator}
          date={moment(computedVariable.overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
          handleOnClick={resetOverwriteProxy}
          isReadOnly={isReadOnly}
          openStatementThread={openKpiComments}
          placement="bottom-start"
          valueFormat={variableDefinition.format}
          newValue={
            variableDefinition.format
              ? formatTotal(
                  computedVariable.overwrite.overwriteValue as ComputeEnginePrimitiveTypes,
                  variableDefinition.format,
                  computedVariable.currency,
                  1,
                )
              : formatValueTotal(computedVariable.overwrite.overwriteValue as number)
          }
          oldValue={
            variableDefinition.format
              ? formatTotal(
                  computedVariable.overwrite.sourceValue as ComputeEnginePrimitiveTypes,
                  variableDefinition.format,
                  computedVariable.currency,
                  1,
                )
              : formatValueTotal(computedVariable.overwrite.sourceValue as number)
          }
        >
          <StatementKpi
            canComment={canComment}
            computedVariable={computedVariable}
            handleOpenModal={handleOpenOverwriteModal}
            isReadOnly={isReadOnly}
            openStatementThread={openStatementThread}
            statementThread={statementThread}
            useLegacyKpiCardView={legacyKpiCardView}
            variableDefinition={variableDefinition}
          />
        </OverwriteTooltip>
      ) : (
        <StatementKpi
          canComment={canComment}
          computedVariable={computedVariable}
          handleOpenModal={handleOpenOverwriteModal}
          isReadOnly={isReadOnly}
          openStatementThread={openStatementThread}
          statementThread={statementThread}
          useLegacyKpiCardView={legacyKpiCardView}
          variableDefinition={variableDefinition}
        />
      )}
    </Grid>
  );
});

export default OverwritableKPI;
