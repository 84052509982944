import { Fragment, memo } from 'react';
import { type ExtractRouteParams, type RouteComponentProps } from 'react-router';
import { generatePath, Redirect, Route, type RouteProps } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';

export type RouteCondition<
  TPath extends string = string,
  TParams extends Record<string, string | undefined> = ExtractRouteParams<TPath, string>,
> = boolean | ((params: RouteComponentProps<TParams>) => boolean);

export type SpecialRouteProps<TPath extends string = string> = Pick<
  RouteProps<TPath>,
  'component' | 'path' | 'render'
> & {
  shouldRedirectToHome?: RouteCondition<TPath>;
  title?: string;
};

const SpecialRouteBase = function SpecialRoute<TPath extends string = string>({
  shouldRedirectToHome,
  component: Component,
  render,
  path,
  title,
}: SpecialRouteProps<TPath>) {
  return (
    <Route
      path={path}
      render={(props) => {
        if (
          (typeof shouldRedirectToHome === 'boolean' && shouldRedirectToHome) ||
          /**
           * If shouldRedirectToHome is a function, it will be called with the route props.
           * Route params will be found under props.match.params.
           */
          (typeof shouldRedirectToHome === 'function' && shouldRedirectToHome(props))
        ) {
          return <Redirect to={generatePath(routes.ROOT)} />;
        }

        return (
          <Fragment>
            <AmaliaHead title={title} />
            {render ? render(props) : <Component {...props} />}
          </Fragment>
        );
      }}
    />
  );
};

export const SpecialRoute = memo(SpecialRouteBase) as typeof SpecialRouteBase;
