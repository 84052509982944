import { isEqual, pick } from 'lodash';
import { type ActionCreator } from 'redux';

import {
  type CalculationRequest,
  type PlanOption,
  type Statistics,
  type StatusStatementType,
  type TeamOption,
  type UserStatementsFacets,
  type UserStatementsPage,
  type WorkflowStatementStateAction,
  type ReduxAction,
  type ThunkResult,
  type Period,
  formatDate,
  RelativePeriodKeyword,
} from '@amal-ia/lib-types';

import { getOvertimeWidget } from '../../services/dashboards/dashboards.repository';
import { reviewStatements } from '../../services/statements/statements.repository';
import { findUserStatements, getUserStatementsFacets } from '../../services/statements/userStatements.repository';

import { USERS_STATEMENTS_ACTIONS } from './constants';
import { selectLastUserStatementsFetchParams, selectUserStatementsList } from './selectors';

const userStatementsStart: ActionCreator<ReduxAction> = () => ({
  type: USERS_STATEMENTS_ACTIONS.START,
});

const userStatementsError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: USERS_STATEMENTS_ACTIONS.ERROR,
  error,
});

const setUserStatementsFacetsSuccess: ActionCreator<ReduxAction> = (facets: UserStatementsFacets[]) => ({
  type: USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_FACETS_SUCCESS,
  payload: { facets },
});

const setUserStatementsStatisticsSuccess: ActionCreator<ReduxAction> = (statistics: Statistics) => ({
  type: USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_STATISTICS_SUCCESS,
  payload: { statistics },
});

const setUserStatementsPageSuccess: ActionCreator<ReduxAction> = (
  userStatementsPage: UserStatementsPage,
  options: {
    period: Period;
    planId: string;
    teamId: string;
    userIds: string[];
    isForecast: boolean;
    searchQuery: string;
    page: number;
    limit: number;
    sortColumn: string;
    sortOrder: string;
  },
) => ({
  type: USERS_STATEMENTS_ACTIONS.SET_USER_STATEMENTS_PAGE_SUCCESS,
  payload: { userStatementsPage, options },
});

export const clearUserStatementsList: ActionCreator<ReduxAction> = () => ({
  type: USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS,
});

export const clearUserStatementsListFacets: ActionCreator<ReduxAction> = () => ({
  type: USERS_STATEMENTS_ACTIONS.CLEAR_USER_STATEMENTS_FACETS,
});

/// //////////
/// STATEMENTS
/// /////////
export const fetchUserStatementsFacets =
  (
    periodId?: string,
    planId?: string,
    teamId?: string,
    status?: StatusStatementType,
    isForecast?: boolean,
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(userStatementsStart());
    try {
      const facets = await getUserStatementsFacets(periodId, planId ? [planId] : undefined, teamId, status, isForecast);

      return dispatch(setUserStatementsFacetsSuccess(facets));
    } catch (error) {
      return dispatch(userStatementsError(error));
    }
  };

export const fetchUserStatementsStatistics =
  (year: string, planId?: string, teamId?: string, userIds?: string[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(userStatementsStart());
    try {
      const statistics = await getOvertimeWidget(
        RelativePeriodKeyword.YEAR_TO_DATE,
        year,
        userIds,
        planId ? [planId] : undefined,
        teamId ? [teamId] : undefined,
      );
      return dispatch(setUserStatementsStatisticsSuccess(statistics));
    } catch (error) {
      return dispatch(userStatementsError(error));
    }
  };

export const fetchUserStatementsList =
  (
    period: Period,
    planId?: string,
    teamId?: string,
    userIds?: string[],
    searchQuery?: string,
    status?: StatusStatementType,
    isForecast?: boolean,
    loadStatistics?: boolean,
    force: boolean = false,
    pageNumber: number = 0,
    nbStatementsPerPage: number = 18,
    sortColumn?: string,
    sortOrder = 'asc',
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    dispatch(userStatementsStart());

    const lastOptions = selectLastUserStatementsFetchParams(getState());
    const userStatementsFromState = selectUserStatementsList(getState());

    const options = {
      period,
      planId,
      teamId,
      userIds,
      isForecast,
      searchQuery,
      page: pageNumber,
      limit: pageNumber * nbStatementsPerPage,
      sortColumn,
      sortOrder,
    };

    if (!force && isEqual(options, lastOptions)) {
      return dispatch(setUserStatementsPageSuccess(userStatementsFromState, options));
    }

    try {
      const userStatementsPage = await findUserStatements(
        period.id,
        userIds,
        planId,
        teamId,
        searchQuery,
        status,
        isForecast,
        pageNumber,
        nbStatementsPerPage,
        sortColumn,
        sortOrder,
      );

      // If only pagination change, no need to refresh facets nor statistics,
      if (
        isEqual(
          pick(options, ['periodId', 'planId', 'teamId', 'userId', 'searchQuery']),
          pick(lastOptions, ['periodId', 'planId', 'teamId', 'userId', 'searchQuery']),
        )
      ) {
        return dispatch(setUserStatementsPageSuccess(userStatementsPage, options));
      }

      await dispatch(fetchUserStatementsFacets(period.id, planId, teamId, status, isForecast));

      if (loadStatistics) {
        const year = formatDate(new Date(period.startDate * 1000), 'YYYY');
        await dispatch(fetchUserStatementsStatistics(year, planId, teamId, userIds));
      }

      return dispatch(setUserStatementsPageSuccess(userStatementsPage, options));
    } catch (error) {
      return dispatch(userStatementsError(error));
    }
  };

/// ///////
/// REVIEW
/// //////
const massReviewStatementsAction: ActionCreator<ReduxAction> = (
  calculationRequest: CalculationRequest,
  newValue: boolean,
) => ({
  type: USERS_STATEMENTS_ACTIONS.MASS_REVIEW_STATEMENTS,
  payload: { calculationRequest, newValue },
});

export const massReviewStatements =
  (
    calculationRequest: CalculationRequest,
    action: WorkflowStatementStateAction,
    isNotify: boolean,
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(userStatementsStart());

    try {
      await reviewStatements(
        {
          ...calculationRequest,
          action,
        },
        isNotify,
      );

      dispatch(clearUserStatementsListFacets());
      await dispatch(
        fetchUserStatementsFacets(calculationRequest.periodId, calculationRequest.planId, calculationRequest.teamId),
      );

      return dispatch(massReviewStatementsAction(calculationRequest, action));
    } catch (error) {
      return dispatch(userStatementsError(error));
    }
  };

/// ///////
/// FILTERS
/// //////
export const changeListSelectedOptions: ActionCreator<ReduxAction> = (
  patch: Partial<{ plan?: PlanOption; team?: TeamOption }>,
) => ({
  type: USERS_STATEMENTS_ACTIONS.CHANGE_LIST_SELECTED_OPTIONS,
  payload: { patch },
});
