import { get } from 'lodash';

import { type ParserScope } from '@amal-ia/amalia-lang/formula/evaluate';
import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('DEFAULT', AmaliaFunctionCategory.MISC);

func.nbParamsRequired = 2;

func.description = 'Return a default value if the parameter is null or undefined';

func.params = [
  { name: 'parameter', description: 'Variables, Fields or Properties' },
  { name: 'defaultValue', description: 'Value to return if parameter is null or undefined.' },
];

func.examples = [
  {
    desc: 'Returns the close date if it exists else, it returns the end date of the period.',
    formula: 'DEFAULT(opportunity.closeDate, statementPeriod.endDate)' as AmaliaFormula,
  },
  {
    desc: 'Returns the invoice date if it exists else, it returns an empty string.',
    formula: 'DEFAULT(opportunity.invoice.closeDate, "")' as AmaliaFormula,
  },
  {
    desc: 'Returns the amount cancelled if it exists else, it returns 0.',
    formula: 'DEFAULT(opportunity.amountCancelled, 0)' as AmaliaFormula,
  },
];

func.execRawArgs = ([parameter, defaultValue], _, scope: ParserScope): any => {
  // Stringify the first parameter, it'll give us the path to the key.
  const parameterPath = parameter.toString();

  // Parameter should have the form something.stuff.someValue.
  if (!parameterPath.match(/^(?:\w|\.)+$/u)) {
    throw new Error('Wrong usage of DEFAULT');
  }

  // After splitting it, it gives us a parser object to look in, and a path.
  const [objectName, ...path] = parameterPath.split('.');

  if (!objectName || !path.length) {
    throw new Error('Wrong usage of DEFAULT');
  }

  // Try to retrieve the object in the parser.
  const objectInScope = scope.get(objectName);
  if (!objectInScope) {
    throw new Error('Wrong usage of DEFAULT');
  }

  // Try to find the value.
  const foundValue = get(objectInScope, path.join('.'), null);

  // If we couldn't find a value or we got null, return the default value.
  return foundValue !== null ? foundValue : defaultValue.evaluate(scope);
};

export default func;
