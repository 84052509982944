import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import {
  AlertBanner,
  FormLayout,
  FormikCheckbox,
  FormikInput,
  Modal,
} from '@amal-ia/frontend/design-system/components';
import { type WorkflowDefinition, WorkflowType } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { useWorkflowListContext } from '../WorkflowList/useWorkflowListContext';
import { WorkflowStepsConfiguration } from '../WorkflowStepsConfiguration/WorkflowStepsConfiguration';

const validationSchema = Yup.object().shape({
  adminCanSkip: Yup.boolean(),
  name: Yup.string().required(),
  reviewsReminder: Yup.boolean(),
});

interface WorkflowConfigurationFormProps {
  isOpen: boolean;
  workflow: WorkflowDefinition | null;
  handleSubmit: (workflow: WorkflowDefinition) => any;
  handleCancel: () => any;
  users: UserContract[];
}

export const WorkflowConfigurationForm = memo(function WorkflowConfigurationForm({
  isOpen,
  workflow,
  handleSubmit,
  handleCancel,
  users,
}: WorkflowConfigurationFormProps) {
  const { areStatementsBeingReview, selectedWorkflowPlans } = useWorkflowListContext();
  const { formatMessage } = useIntl();

  const handleSubmitProxy = useCallback(
    (values: any) => {
      handleSubmit({
        id: workflow?.id,
        machineName: '', // Will be evaluated on backend.
        name: values.name,
        steps: values.steps,
        // We only support plan for now.
        type: WorkflowType.PLAN,
        workflowOptions: {
          adminCanSkip: values.adminCanSkip,
          reviewsReminder: values.reviewsReminder,
        },
      });
    },
    [workflow, handleSubmit],
  );

  const initialValues = useMemo(
    () => ({
      name: workflow?.name || '',
      steps: workflow?.steps || [],
      adminCanSkip: workflow?.workflowOptions?.adminCanSkip !== false,
      reviewsReminder: workflow?.workflowOptions?.reviewsReminder !== false,
    }),
    [workflow],
  );

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.LARGE}
      onClose={handleCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitProxy}
      >
        {({ isValid, dirty, setFieldValue, values }) => (
          <Form
            css={css`
              display: contents;
            `}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Workflow settings" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {areStatementsBeingReview ? (
                  <Box marginBottom={5}>
                    <AlertBanner variant={AlertBanner.Variant.WARNING}>
                      <FormattedMessage
                        defaultMessage="This workflow is set on plans {plans}.{br}Some of these plans' statements are currently being reviewed. Please, either unreview or review them all before."
                        values={{
                          plans: (
                            <FormattedList
                              style="short"
                              type="unit"
                              value={(selectedWorkflowPlans || []).map((p) => p.name)}
                            />
                          ),
                        }}
                      />
                    </AlertBanner>
                  </Box>
                ) : null}

                <FormLayout>
                  <FormLayout.Group>
                    <FormikInput
                      disabled={areStatementsBeingReview}
                      label={formatMessage({ defaultMessage: 'Name' })}
                      name="name"
                    />

                    <FormikCheckbox
                      disabled={areStatementsBeingReview}
                      name="adminCanSkip"
                      label={formatMessage({
                        defaultMessage:
                          'The administrator can review statements even if all other steps were not completed.',
                      })}
                    />

                    <Box>
                      <WorkflowStepsConfiguration
                        isReadOnly={areStatementsBeingReview}
                        users={users}
                        value={values?.steps || []}
                        onChange={(value) => setFieldValue('steps', value)}
                      />
                    </Box>
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid || !dirty || areStatementsBeingReview}
                type="submit"
              >
                <FormattedMessage defaultMessage="Submit" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
});
