import { css } from '@emotion/react';

export const selectItemPopover = css`
  min-width: max-content;
  max-height: 300px;
  overflow-y: auto;
`;

export const selector = css`
  max-width: 100%;
`;
