import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { toError } from '@amal-ia/ext/typescript';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { CustomObjectsApiClient } from '@amal-ia/frontend/web-data-layers';
import { type FiltersType, type PaginatedQuery } from '@amal-ia/lib-types';

import { CUSTOM_OBJECTS_KEYS } from './queries.keys';

export const useCustomObjectsPage = (
  objectDefinitionName: string,
  pagination: PaginatedQuery,
  filters?: FiltersType[] | null,
  overwrite = false,
  refetchInterval?: number,
) =>
  useQuery({
    enabled: !!objectDefinitionName && !!pagination,
    queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, pagination, filters, overwrite],
    queryFn: async () => CustomObjectsApiClient.getObjects(objectDefinitionName, pagination, filters, overwrite),
    refetchInterval,
  });

export const usePurgeCustomObjects = (objectDefinitionName: string) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, 'purge'],
    mutationFn: CustomObjectsApiClient.purgeObjectsByDefinition,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const usePatchCustomObject = (objectDefinitionName: string) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, 'patch'],
    mutationFn: CustomObjectsApiClient.patchCustomObject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};

export const useClearCustomObject = (objectDefinitionName: string) => {
  const queryClient = useQueryClient();

  const { snackError } = useSnackbars();

  return useMutation({
    mutationKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName, 'clear'],
    mutationFn: CustomObjectsApiClient.clearCustomObject,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinitionName],
      });
    },
    onError: (e) => {
      snackError(toError(e).message);
    },
  });
};
