import { floor } from 'lodash';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('FLOOR', AmaliaFunctionCategory.NUMBERS);

func.nbParamsRequired = 1;

func.description = 'Round a number down';

func.params = [
  {
    name: 'input',
    description: 'Input number to get its floor: numbers, fields, properties, variables',
  },
  {
    name: 'nbDecimals',
    description: 'The number of decimal places of the output. By default it is 0.',
    defaultValue: '0',
  },
];

func.hasInfiniteParams = false;

func.examples = [
  {
    desc: 'Returns 5',
    formula: 'FLOOR(5.3)' as AmaliaFormula,
    result: 5,
  },
  {
    desc: 'Returns 1.6',
    formula: 'FLOOR(1.68, 1)' as AmaliaFormula,
    result: 1.6,
  },
  {
    desc: 'Returns 1.57',
    formula: 'FLOOR(1.573, 2)' as AmaliaFormula,
    result: 1.57,
  },
  {
    desc: 'Returns the closet floor to Commission with 2 decimal places.',
    formula: 'FLOOR(statement.commission, 2)' as AmaliaFormula,
  },
  {
    desc: 'Returns 150',
    formula: 'FLOOR(154, -1)' as AmaliaFormula,
    result: 150,
  },
];

func.exec = (numberInput: number, nbDecimals: number): number => floor(numberInput, nbDecimals);

export default func;
