import { createSelector } from 'reselect';

import { type RootState } from '@amal-ia/lib-types';

// If -1, it means that the drawer is opened on a new thread.
export const selectOpenedThreadId = (state: RootState): number | string | null => state.comments.openedThreadId;

export const selectOpenedThread = createSelector(
  [selectOpenedThreadId, (state: RootState) => state.comments.openedThreadOptions],
  (openedThreadId, openedThreadOptions) => ({ id: openedThreadId, options: openedThreadOptions }),
);
