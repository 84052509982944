import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { get } from 'lodash';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { type CustomReportColumn, formatCurrencyAmount, PaymentCategory } from '@amal-ia/lib-types';
import {
  Mixedchart,
  type Axis,
  type ChartMeta,
  type ChartProps,
  type FormattedData,
  Text,
  TextType,
  PAYMENT_CATEGORIES_YTD_TOOLTIPS,
} from '@amal-ia/lib-ui';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { type StatementHistogramItem } from './statementHistogram.types';
import { StatementHistogramCustomTooltip } from './StatementHistogramCustomTooltip';
import { type StatementHistogramContextProps, StatementHistogramProvider } from './useStatementHistogram.context';
import { YTODStatItem } from './YTODStatItem';

const chartColors = {
  totalCommission: 'url(#colorUv)',
};

const useStyles = makeStyles(() => ({
  wideChartContainer: {
    padding: 30,
    maxHeight: 350,
    wrap: 'wrap',
  },
  ytodchart: {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: 'auto 0',
    alignItems: 'center',
  },
  ytodtitle: {
    textTransform: 'uppercase',
  },
}));

export interface StatementHistogramExpandedProps {
  items: StatementHistogramItem[];
  definitions: Record<string, CustomReportColumn>;
  containsHoldRules: boolean;
  user?: UserComputed;
  planName: string;
}

export const StatementHistogramExpanded = memo(function StatementHistogramExpanded({
  items,
  definitions,
  user,
  containsHoldRules,
  planName,
}: StatementHistogramExpandedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const currency = useMemo(() => items?.[0]?.currency, [items]);

  const memoeSummary = useMemo(() => {
    const totals = { achievement: 0, paid: 0, hold: 0 };
    items.forEach((item) => {
      if (item?.total) {
        totals.achievement += item?.total || 0;
      }
    });

    return { totals, count: items.length };
  }, [items]);

  const contextValues: StatementHistogramContextProps = useShallowObjectMemo({
    user,
    totals: memoeSummary.totals,
    definitions,
    currency,
    planName,
  });

  // The width of the divider is the number of bars multiplied by the width of a bar + the width of a gap between two bars.
  // We need to subtract one grid gap at the end because the gap is in-between elements.
  const WideProps: ChartProps = useMemo(() => {
    const data: FormattedData[] = items.map((item) => {
      const { period, total } = item;

      return { name: period, total };
    });

    const xAxis: Axis = { datakey: 'name' };
    const yAxis: Axis[] = [
      {
        AxisId: 'left',
        orientation: 'left',
        stroke: undefined,
        domain: [0, 100],
        tickFormatter: (value: number) => `${value > 1000 ? `${Math.round(value / 1000)}K` : value}`,
      },
      {
        AxisId: 'right',
        orientation: 'right',
        stroke: undefined,
        tickFormatter: (value: number) => `${value * 100}%`,
      },
    ];
    const color = get(chartColors, 'totalCommission');

    const display = 'bar';
    const chartMetas: ChartMeta[] = [
      {
        scale: 1,
        dataKey: 'total',
        radius: [4, 4, 0, 0],
        yAxisId: display === 'bar' ? 'left' : 'right',
        barSize: 10,
        stroke: color,
        fill: color,
        type: display,
        buttonLabel: formatMessage({ defaultMessage: 'Total achieved' }),
        ...(display === 'bar' ? {} : { dot: true }),
      },
    ];

    return {
      data,
      xAxis,
      yAxis,
      chartMetas,
      CustomTooltip: StatementHistogramCustomTooltip,
    };
  }, [items, formatMessage]);

  return (
    <StatementHistogramProvider value={contextValues}>
      <Grid container>
        <Grid lg={8}>
          <Mixedchart {...WideProps} />
        </Grid>
        {items ? (
          <Grid
            className={classes.wideChartContainer}
            lg={4}
          >
            <Grid lg={12}>
              <Text type={TextType.DASHBOARD_SECTION_TITLE}>
                <FormattedMessage defaultMessage="Year to date" />
              </Text>
            </Grid>
            <Box className={classes.ytodchart}>
              {Object.entries(memoeSummary.totals)
                .filter(([key]) => (!containsHoldRules && key === PaymentCategory.achievement) || containsHoldRules)
                .map(
                  ([key, chart]: any, ind: number) =>
                    chart !== 0 && (
                      <YTODStatItem
                        key={key}
                        label={key}
                        price={formatCurrencyAmount({ amount: chart, currencySymbol: currency })}
                        priceColorIndex={ind}
                        toolTipMessage={formatMessage(PAYMENT_CATEGORIES_YTD_TOOLTIPS[key as PaymentCategory])}
                        average={
                          items.length && ind < 2
                            ? formatCurrencyAmount({
                                amount: chart / items.length,
                                currencySymbol: currency,
                              })
                            : undefined
                        }
                      />
                    ),
                )}
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </StatementHistogramProvider>
  );
});
