import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { http } from '@amal-ia/frontend/kernel/http';
import { type Period } from '@amal-ia/lib-types';

export class PeriodsRepository {
  public static async getPeriodByDate(dateString: string, frequency: PeriodFrequencyEnum): Promise<Period> {
    const { data: period } = await http.get<Period>('/periods', {
      params: {
        date: dateString,
        shouldCreate: true,
        frequency,
      },
    });
    return period;
  }

  public static async getAllPeriods(): Promise<Period[]> {
    const { data: period } = await http.get<Period[]>('/periods');
    return period;
  }
}
