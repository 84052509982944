import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, lazy, memo, Suspense, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Switch as RouterSwitch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { Layout, PageContainer } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { type SelectOption } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { Spinner, SpinnerBoundary, useStateInLocalStorage } from '@amal-ia/lib-ui';

import CompaniesRoutes from './companies/companies.routes';
import { SuperadminHeader } from './superadmin-header/SuperadminHeader';
import { useCompaniesList, useIsSALoading } from './superAdmin.queries';
import { routesTitles } from './SuperAdmin.routes.messages';
import { SARouterContext } from './utils/SARouterContext';

const CalculationsPage = lazy(() => import('./calculations/CalculationsPage'));
const CompaniesEditPage = lazy(() => import('./companies/CompaniesEditPage'));
const MasterSettingsPage = lazy(() => import('./masterSettings/MasterSettingsPage'));
const RefreshmentsPage = lazy(() => import('./refreshments/RefreshmentsPage'));
const ConnectorsPage = lazy(() => import('./connectors/ConnectorsPage'));
const StatementsPage = lazy(() => import('./statements/StatementsPage'));
const UsersPage = lazy(() => import('./users/UsersPage'));
const PlansPage = lazy(() => import('./plans/PlansPage'));

export const useStyles = makeStyles({
  topMenu: {
    overflow: 'hidden',
  },
  filterBar: {
    width: '400px',
    flex: 'initial',
    marginLeft: 'auto',
  },
});

type CompanyOption = SelectOption<string>;

export const SuperAdminRoutes = memo(function SuperAdminRoutes() {
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  const { data: currentUser } = useCurrentUser();

  const isSALoading = useIsSALoading();

  const { data: companyList } = useCompaniesList();

  const [selectedCompanies, setSelectedCompanies] = useStateInLocalStorage<string[]>([], 'saCompaniesListId');

  const companiesOptions: CompanyOption[] = useMemo(
    () =>
      companyList
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((company) => ({
          value: company.id,
          label: company.name,
        })),
    [companyList],
  );

  const saRouterContextContent = useShallowObjectMemo({
    selectedCompanies,
    companies: companiesOptions,
  });

  return (
    <Layout>
      <PageContainer showLoadingBar={!!isSALoading}>
        <RouterSwitch>
          <Fragment>
            <SuperadminHeader
              companiesOptions={companiesOptions}
              selectedCompanies={selectedCompanies}
              onChangeSelectedCompanies={setSelectedCompanies}
            />

            <Box mt={2}>
              <Suspense
                fallback={
                  <SpinnerBoundary>
                    <Spinner />
                  </SpinnerBoundary>
                }
              >
                <SARouterContext.Provider value={saRouterContextContent}>
                  <CompanyRoute
                    component={CompaniesEditPage}
                    path={routes.SUPERADMIN_HOME}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_HOME,
                    )}`}
                  />
                  <CompanyRoute
                    component={MasterSettingsPage}
                    path={routes.SUPERADMIN_SETTINGS}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_MASTER_SETTINGS,
                    )}`}
                  />
                  <CompanyRoute
                    component={CompaniesRoutes}
                    path={routes.SUPERADMIN_COMPANIES}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_COMPANY_LIST,
                    )}`}
                  />
                  <CompanyRoute
                    component={CalculationsPage}
                    path={routes.SUPERADMIN_CALCULATIONS}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_CALCULATIONS,
                    )}`}
                  />
                  <CompanyRoute
                    component={RefreshmentsPage}
                    path={routes.SUPERADMIN_REFRESHMENTS}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_DATA_REFRESHMENTS,
                    )}`}
                  />
                  <CompanyRoute
                    component={ConnectorsPage}
                    path={routes.SUPERADMIN_CONNECTORS}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_CONNECTORS,
                    )}`}
                  />
                  <CompanyRoute
                    component={StatementsPage}
                    path={routes.SUPERADMIN_STATEMENTS}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_STATEMENTS,
                    )}`}
                  />
                  <CompanyRoute
                    component={UsersPage}
                    path={routes.SUPERADMIN_USERS}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_USERS,
                    )}`}
                  />
                  <CompanyRoute
                    component={PlansPage}
                    path={routes.SUPERADMIN_PLANS}
                    shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.SuperAdmin)}
                    user={currentUser}
                    title={`${formatMessage(routesTitles.SUPER_ADMIN)} | ${formatMessage(
                      routesTitles.SUPER_ADMIN_PLANS,
                    )}`}
                  />
                </SARouterContext.Provider>
              </Suspense>
            </Box>
          </Fragment>
        </RouterSwitch>
      </PageContainer>
    </Layout>
  );
});
