import { css } from '@emotion/react';
import { memo, forwardRef, type ForwardedRef, type ComponentPropsWithoutRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { type ColumnDefinition } from '../../Table.types';

import * as styles from './TableCell.styles';

export type TableCellProps = MergeAll<
  [
    (
      | MergeAll<
          [
            ComponentPropsWithoutRef<'td'>,
            {
              as: 'td';
            },
          ]
        >
      | MergeAll<
          [
            ComponentPropsWithoutRef<'th'>,
            {
              as: 'th';
            },
          ]
        >
    ),
    {
      /** Is this cell sticky. Only works with 1st column. */
      isSticky?: boolean;
      /** Left offset in px when sticky. */
      stickyLeftOffset?: number;
      /** Column size. */
      size?: ColumnDefinition['size'];
    },
  ]
>;

const TableCellForwardRef = forwardRef(function TableCell(
  { as: CellComponent, children, isSticky = false, stickyLeftOffset = 0, size, ...props }: TableCellProps,
  ref: ForwardedRef<HTMLTableCellElement>,
) {
  return (
    <CellComponent
      {...props}
      ref={ref}
      css={[
        styles.tableCell,
        isSticky &&
          css`
            position: sticky;
            left: ${stickyLeftOffset}px;
            z-index: 10;
          `,
        size !== null &&
          css`
            /** If size is 0 => width is 0, max-width is 240px, min-width is initial. */
            /** If size is > 0 => width/max-width/min-width are size. */
            /** If size is undefined => width is 240px, max-width is 240px, min-width is initial. */
            width: ${typeof size === 'number' ? `${size}px` : '240px'};
            max-width: ${typeof size === 'number' && size ? `${size}px` : '240px'};
            min-width: ${typeof size === 'number' && size ? `${size}px` : 'initial'};
          `,
      ]}
    >
      {children}
    </CellComponent>
  );
});

export const TableCell = memo(TableCellForwardRef);
