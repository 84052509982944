import { type ActionCreator } from 'redux';

import { type Period, type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import { PeriodsRepository } from '../../services/periods/periods.repository';

import { PERIOD_ACTIONS } from './constants';

const periodsStart: ActionCreator<ReduxAction> = () => ({
  type: PERIOD_ACTIONS.START,
});

const periodsError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: PERIOD_ACTIONS.ERROR,
  error,
});

const setPeriods: ActionCreator<ReduxAction> = (periods: Period[]) => ({
  type: PERIOD_ACTIONS.SET_PERIODS,
  payload: { periods },
});

export const fetchPeriods = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(periodsStart());
  try {
    const periods = await PeriodsRepository.getAllPeriods();
    return dispatch(setPeriods(periods));
  } catch (error) {
    return dispatch(periodsError(error));
  }
};
