import moment from 'moment';
import { memo, type MouseEventHandler } from 'react';

import {
  FieldValuePrettyFormat,
  isFieldValuePrettyFormatCompatible,
  LabelVariant,
} from '@amal-ia/data-capture/fields/components';
import { type FormatsEnum, type PropertyRef } from '@amal-ia/data-capture/fields/types';
import { OverwriteTooltip } from '@amal-ia/data-capture/overwrites/components';
import {
  formatUserFullName,
  formatValueOrPrintRemovedLabel,
  type Overwrite,
  type StatementThread,
} from '@amal-ia/lib-types';
import { LightTooltip } from '@amal-ia/lib-ui';

type RowsTableCellContentProps = {
  overwrite: Overwrite;
  formattedValue: number | string;
  cellStatementThread: StatementThread;
  clearOverwriteProxy: MouseEventHandler<HTMLButtonElement>;
  isReadOnly: boolean;
  formattedOverwriteValue: number | string;
  formattedOverwriteSourceValue: number | string;
  openStatementThread: () => void;
  format: FormatsEnum;
  propertyRef: PropertyRef;
  truncatedValue: number | string;
  rawValue: unknown;
};

export const RowsTableCellContent = memo(function RowsTableCellContent({
  overwrite,
  formattedValue,
  cellStatementThread,
  clearOverwriteProxy,
  isReadOnly,
  formattedOverwriteValue,
  formattedOverwriteSourceValue,
  openStatementThread,
  format,
  propertyRef,
  truncatedValue,
  rawValue,
}: RowsTableCellContentProps) {
  const cellContent = isFieldValuePrettyFormatCompatible(format, propertyRef) ? (
    <FieldValuePrettyFormat
      format={format}
      value={rawValue as any}
      valueRef={propertyRef}
      variant={overwrite ? LabelVariant.ACCENTUATED : LabelVariant.DEFAULT}
    />
  ) : overwrite ? (
    formatValueOrPrintRemovedLabel(formattedValue)
  ) : (
    formattedValue
  );

  if (overwrite) {
    return (
      <OverwriteTooltip
        author={formatUserFullName(overwrite.creator)}
        cellStatementThread={cellStatementThread}
        date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        handleOnClick={clearOverwriteProxy}
        isReadOnly={isReadOnly}
        newValue={formattedValue}
        newValueRaw={formattedOverwriteValue}
        oldValue={formattedOverwriteSourceValue}
        openStatementThread={openStatementThread}
        valueFormat={format}
        valueRef={propertyRef}
      >
        <div data-is-overwritten="true">{cellContent}</div>
      </OverwriteTooltip>
    );
  }
  if (truncatedValue) {
    return (
      <LightTooltip title={<span>{truncatedValue}</span>}>
        <div>{cellContent}</div>
      </LightTooltip>
    );
  }
  return cellContent;
});
