import { Box, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useState, type ReactElement, type MouseEvent } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { type Option } from '../../../utils/common.types';
import { type CheckboxProps } from '../formElements/Checkbox/Checkbox';

import { SelectFilterItem } from './SelectFilterItem';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  contentWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemsWrapper: {
    padding: theme.spacing(1, 2),
  },
}));

interface SelectFilterProps {
  content: ReactElement;
  items: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  color?: CheckboxProps['color'];
}

export const SelectFilter = memo(function SelectFilter({ content, items, value, onChange, color }: SelectFilterProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickItem = useCallback(
    (itemValue: string) => {
      onChange(value.includes(itemValue) ? value.filter((v) => v !== itemValue) : [...value, itemValue]);
    },
    [onChange, value],
  );

  return (
    <Box>
      <Box
        className={classes.contentWrapper}
        onClick={handleClick}
      >
        {content}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Box className={classes.itemsWrapper}>
          {items.map((item) => (
            <SelectFilterItem
              key={item.value}
              color={color}
              item={item}
              selected={value.includes(item.value)}
              onClick={onClickItem}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
});
