import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('UPPER', AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 1;

func.description = 'Return the string in upper case';

func.params = [{ name: 'string', description: 'String: variables, fields, properties, string' }];

func.hasInfiniteParams = false;

func.examples = [
  {
    desc: 'Returns HELLO.',
    formula: 'UPPER("Hello")' as AmaliaFormula,
    result: 'HELLO',
  },
  {
    desc: "Returns the user's first name in upper case.",
    formula: 'UPPER(user.firstName)',
  },
];

func.exec = (stringToUpper: string): string => stringToUpper?.toString()?.toUpperCase() || '';

export default func;
