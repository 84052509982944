import { useTheme } from '@emotion/react';
import { memo } from 'react';

import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { reformatDateString } from '@amal-ia/lib-types';

type AxisTick = {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
  frequency?: string;
};

export const MonthAxisTick = memo(function MonthAxisTick({ x, y, payload, frequency }: AxisTick) {
  const theme = useTheme();
  const monthLabel = reformatDateString(
    payload.value,
    'MM/YYYY',
    frequency === PeriodFrequencyEnum.quarter ? '[Q]Q YY' : 'MMM YY',
  );

  return (
    <g transform={`translate(${x},${y + 5})`}>
      <text
        dy={10}
        fill={theme.ds.colors.gray[800]}
        {...theme.ds.typographies.bodyBaseMedium}
        textAnchor="middle"
      >
        {monthLabel}
      </text>
    </g>
  );
});
