import { type Dispatch, type ForwardedRef, useCallback, type SetStateAction, type RefCallback } from 'react';

type Ref<TRef = unknown> = Dispatch<SetStateAction<TRef | null>> | ForwardedRef<TRef>;

/**
 * Merge a list of refs into a callback ref.
 *
 * @param refs - Refs to merge.
 * @returns A callback ref to pass to the element.
 */
export const useMergedRef = <TRef = unknown>(...refs: Ref<TRef>[]): RefCallback<TRef> =>
  useCallback(
    (node: TRef | null) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (typeof ref === 'object' && ref !== null && 'current' in ref) {
          // eslint-disable-next-line no-param-reassign -- This is how refs work.
          ref.current = node;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Array must be static.
    refs,
  );
