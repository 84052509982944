import { css } from '@emotion/react';
import { Box, Divider, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import invariant from 'invariant';
import { toString } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { FormatsEnum, PropertyRef } from '@amal-ia/data-capture/fields/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconLoading, Modal, ModalSize } from '@amal-ia/frontend/design-system/components';
import { COMMON_MESSAGES } from '@amal-ia/lib-ui';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { OverwriteForm } from './form/OverwriteForm';
import { type OverwriteCreationRequestDetails } from './overwrite.types';
import { overwriteModalMessages } from './OverwriteModal.messages';
import { createRowChange } from './OverwriteModal.utils';
import { OverwriteOptions } from './OverwriteOption';
import { OverwriteRecapInfos } from './recapitulation/OverwriteRecapitulationInfo';

export enum OVERWRITE_CONTEXT {
  'DATA' = 'data',
  'ROWTABLE' = 'rowtable',
  'KPI' = 'kpi',
  'COMMENTDRAWER' = 'commentDrawer',
  'FILTER_ROW_REMOVE' = 'filter_row_remove',
}

export type OverwriteModalContainerProps = {
  overwriteContext: OVERWRITE_CONTEXT;
  isOpen: boolean;
  currentObjectDetails: OverwriteCreationRequestDetails;
  handleSubmit: (state: unknown) => Promise<void>;
  handleClose: () => void;
  currentUser: UserContract;
  isSimulation?: boolean;
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  spacingTop: {
    marginTop: '2Opx',
  },
  formLabel: {
    color: theme.palette.grey['700'],
  },
}));

/*
 * Prefill the UserSelector with previous value
 * if the property is a user model reference.
 */
const initialValue = ({ ref, oldValue }: OverwriteCreationRequestDetails) =>
  ref === PropertyRef.USER ? oldValue ?? '' : '';

export type OverwriteModalInitialValues = {
  percentage: number;
  newValue: boolean | number | string | null;
  isApplyToOverall: boolean;
};

export const OverwriteModalContainer = memo(function OverwriteModalContainer({
  overwriteContext,
  isOpen,
  handleClose,
  currentObjectDetails,
  handleSubmit,
  currentUser,
  isSimulation = false,
}: OverwriteModalContainerProps) {
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const initialValues: OverwriteModalInitialValues = useMemo(
    () => ({
      percentage: 0,
      newValue: initialValue(currentObjectDetails),
      isApplyToOverall: false,
    }),
    [currentObjectDetails],
  );

  const formValidationSchema = useMemo(() => {
    let formikNewValueShape: Yup.BooleanSchema<boolean | undefined> | Yup.StringSchema<string | undefined>;

    switch (currentObjectDetails.format) {
      case FormatsEnum.currency:
      case FormatsEnum.number:
      case FormatsEnum.percent:
        formikNewValueShape = Yup.string()
          .min(1)
          .test(
            'no-final-dot',
            formatMessage({ defaultMessage: 'The string must not end with a dot.' }),
            (value) => !value?.endsWith('.'),
          );
        break;
      case FormatsEnum.boolean:
        formikNewValueShape = Yup.boolean().required();
        break;
      default:
        formikNewValueShape = Yup.string().min(1);
    }

    return Yup.object().shape({
      isApplyToOverall: Yup.boolean(),
      newValue: formikNewValueShape,
      percentage: Yup.number(),
    });
  }, [currentObjectDetails, formatMessage]);

  const onSubmit = useCallback(
    async (values: OverwriteModalInitialValues) => {
      const editedValues = { ...values };
      if ([FormatsEnum.currency, FormatsEnum.number, FormatsEnum.percent].includes(currentObjectDetails.format)) {
        invariant(typeof values.newValue === 'string', 'newValue should be a string');
        editedValues.newValue = parseFloat(values.newValue);
        if (currentObjectDetails.format === FormatsEnum.percent) {
          editedValues.newValue /= 100;
        }
      }
      const changed = createRowChange(currentObjectDetails, editedValues, overwriteContext);
      await handleSubmit({ changed });
      handleClose();
    },
    [currentObjectDetails, handleClose, handleSubmit, overwriteContext],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, dirty, setFieldValue, values, isSubmitting }) => (
        <Modal
          isOpen={isOpen}
          size={ModalSize.MEDIUM}
          onClose={handleClose}
        >
          <Form
            css={css`
              display: contents;
            `}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  {isSimulation ? (
                    <FormattedMessage {...overwriteModalMessages.CREATE_SIMULATION_MODAL_TITLE} />
                  ) : (
                    <FormattedMessage {...overwriteModalMessages.CREATE_OVERWRITE_MODAL_TITLE} />
                  )}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {/* Information recapitulation */}
                <OverwriteRecapInfos
                  currentObjectDetails={currentObjectDetails}
                  overwriteContext={overwriteContext}
                />

                {/* Formulaire  */}

                <OverwriteForm
                  currentObjectDetails={currentObjectDetails}
                  currentUser={currentUser}
                />

                {overwriteContext !== OVERWRITE_CONTEXT.DATA && !currentObjectDetails.isProperty && (
                  <Divider variant="middle" />
                )}

                {/* Options (Apply to whom) */}
                {!isSimulation && (
                  <Box className={classes.spacingTop}>
                    <Grid
                      container
                      alignItems="center"
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid>
                        {overwriteContext !== OVERWRITE_CONTEXT.DATA && !currentObjectDetails.isProperty && (
                          <OverwriteOptions
                            isApplyToOverall={values.isApplyToOverall}
                            setIsApplyToOverall={setFieldValue}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Modal.Body>
            </Modal.Content>
            <Modal.Actions>
              <Modal.CancelAction />
              <Modal.MainAction
                icon={isSubmitting ? <IconLoading /> : undefined}
                type="submit"
                aria-label={formatMessage(
                  { defaultMessage: 'Apply overwrite on {fieldName}' },
                  { fieldName: currentObjectDetails.field.trim() },
                )}
                disabled={
                  !isValid ||
                  !dirty ||
                  isSubmitting ||
                  toString(currentObjectDetails.oldValue) === toString(values.newValue) ||
                  values.percentage === 100
                }
              >
                <FormattedMessage {...COMMON_MESSAGES.APPLY} />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        </Modal>
      )}
    </Formik>
  );
});
