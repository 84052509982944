import { isNil } from 'lodash';
import { cloneElement, type JSXElementConstructor, memo, type ReactElement } from 'react';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';

import { type AlertBannerProps } from '../../feedback/alert-banner/AlertBanner';

import { CellAction } from './cell-helpers/cell-action/CellAction';
import { CellActions } from './cell-helpers/cell-actions/CellActions';
import { CellDatePicker } from './cell-helpers/cell-date-picker/CellDatePicker';
import { FormikCellDatePicker } from './cell-helpers/cell-date-picker/formik-cell-date-picker/FormikCellDatePicker';
import { CellIconAction } from './cell-helpers/cell-icon-action/CellIconAction';
import { CellLoading } from './cell-helpers/cell-loading/CellLoading';
import { CellMain } from './cell-helpers/cell-main/CellMain';
import { CellQuickSwitch } from './cell-helpers/cell-quick-switch/CellQuickSwitch';
import { FormikCellQuickSwitch } from './cell-helpers/cell-quick-switch/formik-cell-quick-switch/FormikCellQuickSwitch';
import { CellSelect } from './cell-helpers/cell-select/CellSelect';
import { FormikCellSelect } from './cell-helpers/cell-select/formik-cell-select/FormikCellSelect';
import { CellText } from './cell-helpers/cell-text/CellText';
import { CellTextField } from './cell-helpers/cell-text-field/CellTextField';
import { FormikCellTextField } from './cell-helpers/cell-text-field/formik-cell-text-field/FormikCellTextField';
import { CellWithActions } from './cell-helpers/cell-with-actions/CellWithActions';
import { ColumnAction } from './column-helpers/column-action/ColumnAction';
import { ColumnActions } from './column-helpers/column-actions/ColumnActions';
import { ColumnLinkTooltip } from './column-helpers/column-link-tooltip/ColumnLinkTooltip';
import { ColumnTooltip } from './column-helpers/column-tooltip/ColumnTooltip';
import { useTableMultiSelection } from './hooks/useTableMultiSelection';
import { TableBody } from './layout/table-body/TableBody';
import { TableBodyLoading } from './layout/table-body-loading/TableBodyLoading';
import { TableDataCell } from './layout/table-data-cell/TableDataCell';
import { TableDataCellContent } from './layout/table-data-cell-content/TableDataCellContent';
import { TableHeader } from './layout/table-header/TableHeader';
import { TableRow } from './layout/table-row/TableRow';
import { TableContext, type TableContextValue } from './Table.context';
import * as styles from './Table.styles';
import { type ColumnDefinitions, type RowData, type RowKey } from './Table.types';

export type TableProps<TData extends RowData, TKey extends RowKey> = {
  /** Column definitions. */
  columns: ColumnDefinitions<TData>;
  /** Rows. */
  data: TData[];
  /** Get row key. */
  rowKey: TableContextValue<TData, TKey>['rowKey'];
  /** Selected row ids. */
  selectedRows?: TKey[];
  /** Callback when a row is selected. */
  onChangeSelectedRows?: (selectedRows: TKey[]) => void;
  /** Show an AlertBanner instead of data, in case of error/warning/no data. */
  alert?: ReactElement<AlertBannerProps, JSXElementConstructor<AlertBannerProps>>;
  /** Ignore data, show Skeleton in every cell. */
  isLoading?: boolean;
  /** When loading, how many rows to display. */
  loadingRowsCount?: number;
  /** Should make the first column sticky. */
  pinFirstColumn?: boolean;
};

const TableBase = function Table<TData extends RowData, TKey extends RowKey>({
  data,
  columns,
  rowKey,
  alert = undefined,
  isLoading = false,
  loadingRowsCount = 10,
  pinFirstColumn = false,
  selectedRows = undefined,
  onChangeSelectedRows = undefined,
}: TableProps<TData, TKey>) {
  const {
    isSelectionEnabled,
    isSelectionActive,
    setIsSelectionActive,
    selectionColumnRef,
    selectionColumnWidth,
    handleSelectAllRows,
    handleSelectRow,
    hasSomeRowsSelected,
    hasAllRowsSelected,
  } = useTableMultiSelection({
    data,
    rowKey,
    selectedRows,
    onChangeSelectedRows,
    enabled: !alert && !isLoading && !isNil(selectedRows),
  });

  const dataRowsCount = alert ? 1 : isLoading ? loadingRowsCount : data.length;

  const headerRowsCount = 1;

  const contextValue = useShallowObjectMemo<TableContextValue<TData, TKey>>({
    dataRowsCount,
    headerRowsCount,
    totalRowsCount: dataRowsCount + headerRowsCount,
    isLoading,
    pinFirstColumn,
    rowKey,
  });

  return (
    // Need to cast because we can't use generics in contexts.
    <TableContext.Provider value={contextValue as unknown as TableContextValue<RowData, RowKey>}>
      <div css={styles.tableContainer}>
        <table css={styles.table}>
          <TableHeader<TData, TKey>
            columns={columns}
            handleSelectAllRows={handleSelectAllRows}
            hasAllRowsSelected={hasAllRowsSelected}
            hasSomeRowsSelected={hasSomeRowsSelected}
            isSelectionActive={isSelectionActive}
            isSelectionEnabled={isSelectionEnabled}
            selectionColumnRef={selectionColumnRef}
            selectionColumnWidth={selectionColumnWidth}
            setIsSelectionActive={setIsSelectionActive}
          />

          {alert ? (
            <tbody>
              <TableRow index={headerRowsCount}>
                <TableDataCell
                  colSpan={Math.max(1, columns.length)} // Span all columns.
                  css={styles.alertCell}
                >
                  {cloneElement(alert, { inline: true })}
                </TableDataCell>
              </TableRow>
            </tbody>
          ) : isLoading ? (
            <TableBodyLoading
              columns={columns}
              rowsCount={loadingRowsCount}
            />
          ) : (
            <TableBody<TData, TKey>
              columns={columns}
              data={data}
              isSelectionActive={isSelectionActive}
              isSelectionEnabled={isSelectionEnabled}
              selectedRows={selectedRows}
              selectionColumnWidth={selectionColumnWidth}
              onSelectRow={handleSelectRow}
            />
          )}
        </table>
      </div>
    </TableContext.Provider>
  );
};

export const Table = Object.assign(memo(TableBase) as typeof TableBase, {
  Column: {
    Actions: ColumnActions,
    Action: ColumnAction,
    Tooltip: ColumnTooltip,
    LinkTooltip: ColumnLinkTooltip,
  },
  Cell: Object.assign(TableDataCellContent, {
    Actions: CellActions,
    Action: CellAction,
    IconAction: CellIconAction,
    DatePicker: CellDatePicker,
    FormikDatePicker: FormikCellDatePicker,
    Main: CellMain,
    QuickSwitch: CellQuickSwitch,
    FormikQuickSwitch: FormikCellQuickSwitch,
    Loading: CellLoading,
    Select: CellSelect,
    FormikSelect: FormikCellSelect,
    Text: CellText,
    TextField: CellTextField,
    FormikTextField: FormikCellTextField,
    WithActions: CellWithActions,
  }),
});
