import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { teamRoutesMessages } from './list/team.routes.messages';

const TeamDetailContainer = lazy(() => import('./detail/TeamDetailContainer'));
const TeamListContainer = lazy(() => import('./list/TeamListContainer'));

export const TeamRoutes = memo(function TeamRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="teams">
      <Switch>
        <CompanyRoute
          component={TeamDetailContainer}
          path={routes.TEAM_ITEM}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Team)}
          title={formatMessage(teamRoutesMessages.TEAM_ITEM)}
          user={user}
        />
        <CompanyRoute
          component={TeamListContainer}
          path={routes.TEAM_LIST}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Team)}
          title={formatMessage(teamRoutesMessages.TEAM_LIST)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
