import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd';

import {
  HidableElementVisibility,
  type PlanRule,
  type PlanRuleKpiToDisplay,
} from '@amal-ia/compensation-definition/shared/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { moveElementInArray, type PlansMap, type Variable, VariableObjectsEnum } from '@amal-ia/lib-types';
import { DialogTitleWithCloseButton, List, SearchInput } from '@amal-ia/lib-ui';

import { PlanRuleSelectKpisModalListElement } from './PlanRuleSelectKpisModalListElement';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(2),
    height: '80vh',
  },
  searchField: {
    marginBottom: theme.spacing(2),
  },
  list: {
    height: '100%',
  },
  divider: {
    margin: `${theme.spacing(2)} 0`,
  },
  column: {
    height: '100%',
  },
}));

enum COLUMNS {
  SELECTED = 'SELECTED',
  NOT_SELECTED = 'NOT_SELECTED',
}

interface PlanRuleSelectFieldsProps {
  plansMap: PlansMap;
  availableVariables: Variable[];
  selectedRuleAssignment: PlanRule | null;
  onPatchRules: (rulesToPatch: PlanRule[]) => any;
  onCancel: () => any;
}

export const PlanRuleSelectKpisModal = memo(function PlanRuleSelectKpisModal({
  availableVariables,
  plansMap,
  selectedRuleAssignment,
  onPatchRules,
  onCancel,
}: PlanRuleSelectFieldsProps) {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedKpisToDisplay, setSelectedKpisToDisplay] = useState<PlanRuleKpiToDisplay[]>([]);

  useEffect(() => {
    // On selecting a ruleAssignment, setup form values according to what was persisted.
    // On dismount, reset to [].
    setSelectedKpisToDisplay(selectedRuleAssignment?.kpisToDisplay || []);
  }, [selectedRuleAssignment]);

  const onDragEnd = useCallback(
    ({ draggableId, source, destination }: DropResult) => {
      // Moving from unselected to unselected: do nothing.
      if (source.droppableId === COLUMNS.NOT_SELECTED && destination?.droppableId === COLUMNS.NOT_SELECTED) {
        return;
      }

      // Moving from selected to unselected: remove element from selected fields.
      if (source.droppableId === COLUMNS.SELECTED && destination?.droppableId === COLUMNS.NOT_SELECTED) {
        setSelectedKpisToDisplay((prev) => prev.filter((elt) => elt.id !== draggableId));
      }

      // Moving from unselected to selected: add field into selected.
      if (source.droppableId === COLUMNS.NOT_SELECTED && destination?.droppableId === COLUMNS.SELECTED) {
        const newArray = [...selectedKpisToDisplay];
        newArray.splice(destination?.index, 0, { id: draggableId, displayStatus: HidableElementVisibility.ON_DISPLAY });
        setSelectedKpisToDisplay(newArray);
      }

      // Moving from selected to selected: reorder elements.
      if (source.droppableId === COLUMNS.SELECTED && destination?.droppableId === COLUMNS.SELECTED) {
        setSelectedKpisToDisplay(moveElementInArray(selectedKpisToDisplay, source.index, destination.index));
      }
    },
    [setSelectedKpisToDisplay, selectedKpisToDisplay],
  );

  const onChangeDisplayStatus = useCallback(
    (id: string) => {
      setSelectedKpisToDisplay(
        selectedKpisToDisplay.map((field) => {
          // Not the clicked element: return as is.
          if (field.id !== id) {
            return field;
          }

          // Else, invert the status.
          return {
            ...field,
            displayStatus:
              field.displayStatus === HidableElementVisibility.ON_DISPLAY
                ? HidableElementVisibility.AVAILABLE
                : HidableElementVisibility.ON_DISPLAY,
          };
        }),
      );
    },
    [selectedKpisToDisplay, setSelectedKpisToDisplay],
  );

  const onSubmit = useCallback(() => {
    if (selectedRuleAssignment) {
      const newRule: PlanRule = { ...selectedRuleAssignment, kpisToDisplay: selectedKpisToDisplay };
      onPatchRules([newRule]);
    }
  }, [onPatchRules, selectedKpisToDisplay, selectedRuleAssignment]);

  const allKpisAvailable: (Variable & { contextPlanName?: string })[] = useMemo(
    () =>
      availableVariables
        .filter((variable) =>
          [
            VariableObjectsEnum.user,
            VariableObjectsEnum.plan,
            VariableObjectsEnum.team,
            VariableObjectsEnum.statement,
          ].includes(variable.type),
        )
        .map((kpi) => ({
          ...kpi,
          contextPlanName: kpi.planId ? plansMap[kpi.planId]?.name : undefined,
        })),
    [availableVariables, plansMap],
  );

  const notSelectedFields = useMemo(() => {
    const selectedFieldsId = selectedKpisToDisplay.map((f) => f.id);
    return Object.values(allKpisAvailable)
      .filter(
        (fieldAvailable) =>
          !selectedFieldsId.includes(fieldAvailable.id) &&
          (searchTerm ? fieldAvailable.name.toLowerCase().includes(searchTerm.toLowerCase()) : true),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [selectedKpisToDisplay, allKpisAvailable, searchTerm]);

  const selectedFields = useMemo(
    () =>
      selectedKpisToDisplay.map((kpi) => {
        const variableKpi = allKpisAvailable.find((v) => v.id === kpi.id);
        return {
          ...kpi,
          label: variableKpi?.name || '',
          contextPlanName: variableKpi?.contextPlanName,
        };
      }),
    [allKpisAvailable, selectedKpisToDisplay],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!selectedRuleAssignment}
      onClose={onCancel}
    >
      <DialogTitleWithCloseButton handleClose={onCancel}>
        Configure KPIs to display for rule {selectedRuleAssignment?.name}
      </DialogTitleWithCloseButton>
      <DialogContent dividers>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={classes.container}>
            <div className={classes.column}>
              <p>All KPIs in context</p>
              <SearchInput
                forceSearchVisible
                showBorder
                className={classes.searchField}
                value={searchTerm}
                onChange={setSearchTerm}
              />
              <Droppable droppableId={COLUMNS.NOT_SELECTED}>
                {(droppableProvided) => (
                  <List
                    {...droppableProvided.droppableProps}
                    className={classes.list}
                    innerRef={droppableProvided.innerRef}
                  >
                    {notSelectedFields.map(({ id, name, contextPlanName }, index) => (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <PlanRuleSelectKpisModalListElement
                            contextPlanName={contextPlanName}
                            dndProvidedProps={draggableProvided}
                            id={id}
                            label={name}
                            plansMap={plansMap}
                          />
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </List>
                )}
              </Droppable>
            </div>
            <div className={classes.column}>
              <p>Available KPIs</p>
              <Droppable droppableId={COLUMNS.SELECTED}>
                {(droppableProvided) => (
                  <List
                    {...droppableProvided.droppableProps}
                    className={classes.list}
                    innerRef={droppableProvided.innerRef}
                  >
                    {selectedFields.map(({ id, displayStatus, label, contextPlanName }, index) => (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <PlanRuleSelectKpisModalListElement
                            key={id}
                            contextPlanName={contextPlanName}
                            displayStatus={displayStatus}
                            dndProvidedProps={draggableProvided}
                            id={id}
                            label={label}
                            plansMap={plansMap}
                            onChangeDisplayStatus={onChangeDisplayStatus}
                          />
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </List>
                )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button
          variant={Button.Variant.LIGHT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-testid="apply-rules-btn"
          onClick={onSubmit}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
});
