import { Box } from '@mui/material';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import {
  deleteAdjustment as deleteAdjustmentAction,
  STATEMENTS_ACTIONS,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { type Adjustment, isBeingReviewed } from '@amal-ia/lib-types';
import { DataGrid, useStatementDetailContext } from '@amal-ia/lib-ui';

import AdjustmentsTableCell from './AdjustmentsTableCell';
import AdjustmentsTableContext, { type AdjustmentsTableContextInterface } from './AdjustmentsTableContext';

interface AdjustmentsTableProps {
  adjustments: Adjustment[];
  setSelectAdjustment: (adjustment: Adjustment) => void;
}

const AdjustmentsTable = memo(function AdjustmentsTable({ adjustments, setSelectAdjustment }: AdjustmentsTableProps) {
  const dispatch = useThunkDispatch();
  const { formatMessage } = useIntl();

  const statement = useStatementDetailContext();

  const columns = useMemo(
    () =>
      [
        { name: 'actions', title: '' },
        { name: 'name', title: formatMessage({ defaultMessage: 'Name' }) },
        // Only show the column if there is at least one adj on a record.
        adjustments.some((a) => a.rowExternalId) && {
          name: 'record',
          title: formatMessage({ defaultMessage: 'Record' }),
        },
        { name: 'amount', title: formatMessage({ defaultMessage: 'Amount' }) },
        { name: 'description', title: formatMessage({ defaultMessage: 'Description' }) },
      ].filter(Boolean),
    [adjustments, formatMessage],
  );

  const deleteAdjustment = useCallback(
    async (adjustmentId: string) => {
      const action = await dispatch(deleteAdjustmentAction(adjustmentId));
      if (action.type !== STATEMENTS_ACTIONS.ERROR) {
        await statement.launchCalculation();
      }
    },
    [dispatch, statement],
  );

  const isWorkflowBegun = useMemo(() => isBeingReviewed(statement), [statement]);

  const contextContent: AdjustmentsTableContextInterface = useShallowObjectMemo({
    deleteAdjustment,
    editAdjustment: setSelectAdjustment,
    statement,
    isWorkflowBegun,
  });

  const DATAGRID_OPTIONS = {
    sort: {
      sortable: true,
    },
  };

  return (
    <AdjustmentsTableContext.Provider value={contextContent}>
      {adjustments?.length > 0 ? (
        <DataGrid
          columns={columns}
          id={statement.id || 'adjustementId'}
          options={DATAGRID_OPTIONS}
          rows={adjustments}
          tableProps={{ cellComponent: AdjustmentsTableCell }}
        />
      ) : null}

      {adjustments?.length === 0 ? (
        <Box
          p={amaliaTheme.spacing(24)}
          textAlign="center"
        >
          <FormattedMessage defaultMessage="No rows" />
        </Box>
      ) : null}
    </AdjustmentsTableContext.Provider>
  );
});

export default AdjustmentsTable;
