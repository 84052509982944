import { Tab } from '@mui/material';
import { memo } from 'react';

import { eventPreventDefault } from '../../../../utils/common/eventHelpers';

import { AppbarNavItemLink } from './AppbarNavItemLink';
import { useStylesMainMenu } from './useStylesAppbar';

export interface AppbarNavItemProps {
  id?: string;
  label: string;
  href: string;
  icon?: string;
}

export const AppbarNavItem = memo(function AppbarNavItem(props: AppbarNavItemProps) {
  const classes = useStylesMainMenu();
  return (
    <Tab
      classes={{ root: classes.tabRoot }}
      component={AppbarNavItemLink}
      icon={props.icon}
      to={props.href}
      onClick={eventPreventDefault}
      {...props}
    />
  );
});
