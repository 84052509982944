import { concat, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import { getPageCount, useSnackbars } from '@amal-ia/frontend/design-system/components';
import {
  useThunkDispatch,
  fetchUsers,
  applyOverwriteToStatementDataset,
  clearOverwriteInStatementDataset,
} from '@amal-ia/frontend/web-data-layers';
import * as StatementDatasetsRepository from '@amal-ia/frontend/web-data-layers';
import { type DatasetRow, type Overwrite, type PaginatedResponse, type Statement } from '@amal-ia/lib-types';
import { useDebouncedInput } from '@amal-ia/lib-ui';
import { type Dataset, DatasetType } from '@amal-ia/payout-calculation/shared/types';

type transformSortOptions = { datasetType?: DatasetType };
// Change sorting fields for some exceptions.
const transformSort = (field?: { columnName: string; direction: string }, options?: transformSortOptions) => {
  if (!field) return { sort: undefined, desc: undefined };

  if (options?.datasetType === DatasetType.metrics && field.columnName === 'userId') {
    return { sort: 'userFirstName', desc: field.direction === 'desc' };
  }
  return {
    sort: field.columnName,
    desc: field.direction === 'desc',
  };
};

export default (
  statement: Statement | undefined,
  dataset: Dataset,
  // If controlled by the parent.
  datasetRows?: DatasetRow[],
  globalSearchValue?: string,
  forecasted?: boolean,
) => {
  const { snackError } = useSnackbars();
  const dispatch = useThunkDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [paginationState, setPaginationState] = useState<PaginatedResponse<DatasetRow> | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sorting, setSorting] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [searchValueDebounced, setSearchValueDebounced] = useState('');

  const [addedRows, setAddedRows] = useState<DatasetRow[]>([]);

  const { onChange: onDebouncedSearchInputChange } = useDebouncedInput(setSearchValue, setSearchValueDebounced, 500);

  useEffect(() => {
    setSearchValue(globalSearchValue || '');
    setSearchValueDebounced(globalSearchValue || '');
  }, [globalSearchValue]);

  useAsyncEffect(async () => {
    if (datasetRows !== undefined) {
      setPaginationState({
        items: datasetRows,
        totalItems: datasetRows.length,
        pageCount: getPageCount(datasetRows.length, pageSize),
      });
      return;
    }

    if (statement) {
      try {
        setIsLoading(true);
        const res = await StatementDatasetsRepository.fetchPaginatedDatasetRows(
          statement.id,
          dataset.id,
          {
            // Datagrid counts pages starting at 0, but API starts at 1.
            page: currentPage + 1,
            search: searchValueDebounced !== '' ? searchValueDebounced : undefined,
            limit: pageSize,
            ...transformSort(sorting[0], { datasetType: dataset.type }),
          },
          forecasted,
        );

        // If we have a metrics dataset, some users can be mentioned. Go fetch them.
        if (dataset.type === DatasetType.metrics) {
          const usersMentionedInDataset = res.items.map((row) => row.content?.userId as string).filter(Boolean);

          await dispatch(fetchUsers(uniq(usersMentionedInDataset)));
        }

        setPaginationState(res);
        setAddedRows([]);
      } catch (e) {
        snackError(e.message);
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    datasetRows,
    currentPage,
    sorting,
    searchValueDebounced,
    pageSize,
    dataset.id,
    // Create an implicit dependency to the statement, so the
    // dataset reloads if the statement is calculated.
    statement?.updatedAt,
    // And if the user navigates from a statement to another with the same
    // updatedAt. It doesn't happen often, but it does happen (batch workflow
    // operations for instance).
    statement?.id,
    forecasted,
  ]);

  const applyOverwrite = useCallback(
    (overwrite: Overwrite) => {
      const newRows = applyOverwriteToStatementDataset(paginationState.items, overwrite);

      setPaginationState({
        ...paginationState,
        items: newRows,
      });
    },
    [paginationState, setPaginationState],
  );

  const clearOverwrite = useCallback(
    (overwrite: Overwrite) => {
      const newRows = clearOverwriteInStatementDataset(paginationState.items, overwrite);

      setPaginationState({
        ...paginationState,
        items: newRows,
      });
    },
    [paginationState, setPaginationState],
  );

  const pageWithAddedRows = useMemo(
    () => (currentPage === 0 ? concat(addedRows || [], paginationState?.items || []) : paginationState.items || []),
    [addedRows, currentPage, paginationState?.items],
  );

  return {
    paginationState: paginationState ? { totalItems: paginationState.totalItems, rows: pageWithAddedRows } : null,
    isLoading,
    applyOverwrite,
    clearOverwrite,
    datagridControls: {
      currentPage,
      setCurrentPage,
      pageSize,
      setPageSize,
      sorting,
      onSortingChange: setSorting,
      searchValue,
      onDebouncedSearchInputChange,
    },
    setAddedRows,
  };
};
