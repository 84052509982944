import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import {
  type Period,
  type Statement,
  type Statistics,
  INITIAL_REDUX_PAGINATION,
  type PaginationStoredInRedux,
  type ReduxAction,
  type CommentThreadMessage,
  type StatementThread,
  type StatementForecast,
} from '@amal-ia/lib-types';

import {
  applyOverwriteToComputedStatement,
  removeOverwriteFromComputedStatement,
} from '../../services/statements/statements.service';
import { PLANS_ACTIONS as PLAN_ACTIONS } from '../plans/constants';

import { STATEMENTS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action): number => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.START:
        return state + 1;
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
      case STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS:
      case STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS:
      case STATEMENTS_ACTIONS.SET_STATEMENT_STATISTICS_SUCCESS:
      case STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS:
      case STATEMENTS_ACTIONS.SET_CURRENT_STATEMENT_THREADS:
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREADS_LIST:
      case STATEMENTS_ACTIONS.CREATE_STATEMENT_THREAD:
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREAD_MESSAGES:
      case STATEMENTS_ACTIONS.ADD_STATEMENT_THREAD_MESSAGE:
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREAD_REVIEWED:
      case STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS:
      case STATEMENTS_ACTIONS.CREATE_ADJUSTMENT:
      case STATEMENTS_ACTIONS.DELETE_ADJUSTMENT:
      case STATEMENTS_ACTIONS.EDIT_ADJUSTMENT:
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP:
      case STATEMENTS_ACTIONS.CREATE_OVERWRITE:
      case STATEMENTS_ACTIONS.CLEAR_OVERWRITE:
      case STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE:
      case STATEMENTS_ACTIONS.ERROR:
        return state - 1;
      default:
        return state;
    }
  },
  lastparams: (state: Record<string, any> = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
      case STATEMENTS_ACTIONS.CLEAR_STATEMENTS:
        return {};
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
        return action.payload.options;
      default:
        return state;
    }
  },
  map: (state: Record<string, Statement> = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
      case STATEMENTS_ACTIONS.CLEAR_STATEMENTS:
        return {};
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
        return keyBy(action.payload.statements, 'id');
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP:
        return {
          ...state,
          [action.payload.statementId]: {
            ...state[action.payload.statementId],
            workflowSteps: action.payload.updatedStatement.workflowSteps,
            workflowComplete: action.payload.updatedStatement.workflowComplete,
          },
        };
      default:
        return state;
    }
  },
  userStatements: (state: Record<string, Statement> = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
        return {};
      case STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS:
        return keyBy(action.payload.userStatements, 'id');
      default:
        return state;
    }
  },
  currentPeriod: (state: Period | null = null, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS: {
        if (action.payload.currentPeriod?.id === state?.id) {
          return state;
        }
        return action.payload.currentPeriod;
      }
      default:
        return state;
    }
  },
  currentStatement: (state: Statement | null = null, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
        return null;
      case STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS:
        return action.payload.statement;
      case STATEMENTS_ACTIONS.CLEAR_STATEMENT:
        return null;
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP: {
        return {
          ...state,
          workflowSteps: action.payload.updatedStatement.workflowSteps,
          workflowComplete: action.payload.updatedStatement.workflowComplete,
        };
      }
      case STATEMENTS_ACTIONS.CREATE_OVERWRITE:
        return {
          ...state,
          results: applyOverwriteToComputedStatement(state.results, action.payload.overwrite),
        };
      case STATEMENTS_ACTIONS.CLEAR_OVERWRITE:
        return {
          ...state,
          results: removeOverwriteFromComputedStatement(state.results, action.payload.overwrite),
        };
      default:
        return state;
    }
  },
  currentForecastedStatement: (state: StatementForecast | null = null, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS:
        return action.payload.forecastedStatement;
      case STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE:
        return {
          ...state,
          results: applyOverwriteToComputedStatement(state.results, action.payload.overwrite),
        };
      case STATEMENTS_ACTIONS.CLEAR_SIMULATED_OVERWRITE:
        return {
          ...state,
          results: removeOverwriteFromComputedStatement(state.results, action.payload.overwrite),
        };
      default:
        return state;
    }
  },
  currentStatementStatistics: (state: Statistics | null = null, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_STATEMENT_STATISTICS_SUCCESS:
        return action.payload.statistics;
      default:
        return state;
    }
  },
  currentStatementThreads: (state: Record<string, StatementThread> = {}, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_CURRENT_STATEMENT_THREADS:
        // Map the statementThreads array to an object with statement threads ids as keys
        return action.payload.statementThreads.reduce(
          (obj: object, statementThread: StatementThread) => ({
            ...obj,
            [statementThread.id]: statementThread,
          }),
          {},
        );
      case STATEMENTS_ACTIONS.CREATE_STATEMENT_THREAD:
        return {
          // Copy all existing statement threads
          ...state,
          // Add the new statement thread
          [action.payload.statementThread.id]: {
            ...action.payload.statementThread,
            messages: [],
          },
        };
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREAD_MESSAGES:
        return {
          // Copy all statement threads
          ...state,
          // Override the corresponding statement thread
          [action.payload.statementThreadId]: {
            // Copy its content
            ...state[action.payload.statementThreadId],
            thread: {
              ...state[action.payload.statementThreadId].thread,
              // Pass the new messages as the statement thread messages
              messages: action.payload.messages as CommentThreadMessage[],
            },
          },
        };
      case STATEMENTS_ACTIONS.ADD_STATEMENT_THREAD_MESSAGE:
        return {
          // Copy all existing statement threads
          ...state,
          // Override the corresponding statement thread
          [action.payload.statementThreadId]: {
            // Copy its content
            ...state[action.payload.statementThreadId],
            thread: {
              ...state[action.payload.statementThreadId].thread,
              isReviewed: false,
              messages: [
                // Copy all existing messages
                ...(state[action.payload.statementThreadId].thread.messages || []),
                // Then push the new message at the end
                action.payload.message as CommentThreadMessage,
              ],
            },
          },
        };
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREAD_REVIEWED:
        if (!state[action.payload.statementThreadId]) {
          return state;
        }
        return {
          // Copy all statement threads
          ...state,
          // Override the corresponding statement thread
          [action.payload.statementThreadId]: {
            // Copy its content
            ...state[action.payload.statementThreadId],
            thread: {
              ...state[action.payload.statementThreadId].thread,
              isReviewed: action.payload.isReviewed,
            },
          },
        };
      default:
        return state;
    }
  },
  statementThreadsList: (
    state: PaginationStoredInRedux<Partial<StatementThread>> = INITIAL_REDUX_PAGINATION,
    action: ReduxAction,
  ) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREADS_LIST:
        return action.payload.list;
      default:
        return state;
    }
  },
  listFilters: (state: { planId: string; teamId: string } = { planId: '', teamId: '' }, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.CHANGE_LIST_FILTERS:
        return {
          ...state,
          [action.payload.filterType]: action.payload.newFilter,
        };
      default:
        return state;
    }
  },
});
