import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import 'react-datepicker/dist/react-datepicker.css';

const variants = ['year-text', 'quarter', 'month', 'month-text', 'day'];

const makeSelector = (makeName: (granularity: string) => string) => variants.map((v) => makeName(v)).join(', ');

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  datePicker: {
    zIndex: 10_000,
    '& .react-datepicker': {
      borderColor: theme.palette.divider,
    },
    '& .react-datepicker__header': {
      backgroundColor: theme.palette.common.white,
      borderBottom: 'none',
    },
    '& .react-datepicker__current-month, & .react-datepicker-time__header, & .react-datepicker-year-header': {
      color: theme.palette.secondary.main,
    },
    '& .react-datepicker__triangle': {
      borderBottomColor: `${theme.palette.common.white} !important`,
    },
    // Cells.
    [makeSelector((g) => `& .react-datepicker__${g}-text`)]: {
      border: '1px solid transparent',
    },
    // Selected Cells.
    [makeSelector((g) => `& .react-datepicker__${g}--selected, & .react-datepicker__${g}--keyboard-selected`)]: {
      color: theme.palette.tertiary.main,
      border: `1px solid ${theme.palette.tertiary.main}`,
      background: theme.palette.common.white,
    },
    // Selected cells on hover.
    [makeSelector((g) => `& .react-datepicker__${g}--selected:hover`)]: {
      backgroundColor: theme.palette.grey['100'],
    },
    // Days on top of calendar.
    '& .react-datepicker__day-names > .react-datepicker__day-name': {
      color: theme.palette.grey['200'],
    },
    // Arrows
    '& .react-datepicker__navigation--previous, & .react-datepicker__navigation--previous:hover': {
      borderRightColor: theme.palette.tertiary.main,
    },
    '& .react-datepicker__navigation--next, & .react-datepicker__navigation--next:hover': {
      borderLeftColor: theme.palette.tertiary.main,
    },
  },
}));

interface DatePickerProps extends ReactDatePickerProps {
  language?: string;
}

export const DatePicker = memo(function DatePicker({ language, ...propsToPass }: DatePickerProps) {
  const classes = useStyles();

  return (
    <ReactDatePicker
      {...propsToPass}
      locale={language}
      popperClassName={classes.datePicker}
    />
  );
});
