import { http } from '@amal-ia/frontend/kernel/http';
import { type BadgeAward } from '@amal-ia/lib-types';

const apiEndpoint = '/badges';

export async function getAwardsForFilters(
  userIds?: string[],
  periodId?: string,
  planIds?: string[],
): Promise<BadgeAward[]> {
  const qs = [
    ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
    ...(planIds ? planIds.map((p) => `planIds=${p}`) : []),
    periodId ? `periodId=${periodId}` : '',
  ]
    .filter(Boolean)
    .join('&');

  const { data } = await http.get<BadgeAward[]>(`${apiEndpoint}/awards/?${qs}`);
  return data;
}
