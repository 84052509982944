import { type ForwardedRef, forwardRef, memo } from 'react';

import { Button, type ButtonProps } from '../../../../general/button/Button';

export type CellActionProps = Omit<ButtonProps, 'size'>;

const CellActionForwardRef = forwardRef(function CellAction(
  { variant = Button.Variant.SECONDARY_LIGHT, ...props }: CellActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      {...props}
      ref={ref}
      size={Button.Size.SMALL}
      variant={variant}
    />
  );
});

export const CellAction = Object.assign(memo(CellActionForwardRef), {
  IconPosition: Button.IconPosition,
  Variant: Button.Variant,
});
