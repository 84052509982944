import { memo } from 'react';
import { Redirect, generatePath } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amal-ia/common/routes';
import { downloadDataExportFile } from '@amal-ia/frontend/web-data-layers';

export const DataExports = memo(function DataExports({
  match: {
    params: { exportId },
  },
}: any) {
  useAsyncEffect(() => downloadDataExportFile(exportId), [exportId]);
  return <Redirect to={generatePath(routes.STATEMENTS)} />;
});
