import { Box } from '@mui/material';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { DataGrid, type DataGridPropsOptions } from '@amal-ia/lib-ui';

import { type LeaderboardDetailsProps } from '../LeaderboardDetails/LeaderboardDetails';

import { LeaderboardTableCell } from './LeaderboardTableCell';
import { LeaderboardTableContext, type LeaderboardTableContextInterface } from './LeaderboardTableContext';

const DATAGRID_OPTIONS: DataGridPropsOptions = {
  fullWidth: false,
};

export const LeaderboardTable = memo(function LeaderboardTable({
  variableName,
  variableFormat,
  challenge,
  usersMap,
  teamsMap,
  currentPeriod,
  company,
}: LeaderboardDetailsProps) {
  const isTeamMode = useMemo(() => challenge.leaderboard.some((res) => !!res.teamId), [challenge]);
  const { formatMessage } = useIntl();

  const columns = useMemo(
    () =>
      [
        { name: 'position', title: formatMessage({ defaultMessage: 'Rank' }) },
        {
          name: isTeamMode ? 'teamId' : 'userId',
          title: isTeamMode ? formatMessage({ defaultMessage: 'Team' }) : formatMessage({ defaultMessage: 'User' }),
        },
        // Hide the comparison column if the admin asked for hiding the result.
        (challenge.leaderboard || []).some((r) => r.comparisonValue !== undefined) && {
          name: 'comparisonValue',
          title: variableName,
        },
      ].filter(Boolean),
    [isTeamMode, variableName, challenge, formatMessage],
  );

  const contextContent: LeaderboardTableContextInterface = useShallowObjectMemo({
    challenge,
    variableFormat,
    usersMap,
    teamsMap,
    currentPeriod,
    company,
  });

  return (
    <LeaderboardTableContext.Provider value={contextContent}>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          columns={columns}
          id="leaderboardTable"
          options={DATAGRID_OPTIONS}
          rows={challenge.leaderboard}
          tableProps={{ cellComponent: LeaderboardTableCell }}
        />
      </Box>
    </LeaderboardTableContext.Provider>
  );
});
