import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('IN', AmaliaFunctionCategory.ARRAY);

func.nbParamsRequired = 2;

func.description = 'Return true if a given value is found in a given array';

func.params = [
  { name: 'array', description: 'Array' },
  { name: 'searchValue', description: 'Value to look for' },
];

func.examples = [
  {
    desc: 'Returns true if the team members array contains the ID of the user.',
    formula: 'IN(team.members, user.externalId)' as AmaliaFormula,
  },
  {
    desc: 'Returns true',
    formula: 'IN(["a","b","c"], "b")' as AmaliaFormula,
    result: true,
  },
  {
    desc: 'Returns false',
    formula: 'IN([12,13,14], 1)' as AmaliaFormula,
    result: false,
  },
];

/**
 * Check that array contains exactly an other text.
 * @param sourceStringArray
 * @param searchString
 */
func.exec = (sourceStringArray: string[], searchString: string): boolean =>
  sourceStringArray.some((element) => element.toString() === searchString.toString());

export default func;
