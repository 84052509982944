import { defineMessage } from 'react-intl';

import { formatUserFullName, type Plan, type TeamContract, VariableObjectsEnum } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { fetchPlans } from '../../redux/plans/actions';
import { type ThunkDispatch } from '../../redux/store';
import { fetchAllTeamsThunkAction } from '../../redux/teams/actions';
import { fetchAllUsers } from '../../redux/users/actions';
import { type VariableValue } from '../../types/variables';

export const isTheSameVariableValue = (vv1: VariableValue, vv2: VariableValue): boolean =>
  vv1.startDate === vv2.startDate &&
  vv1.endDate === vv2.endDate &&
  vv1.userId === vv2.userId &&
  vv1.teamId === vv2.teamId &&
  vv1.planId === vv2.planId &&
  vv1.variableId === vv2.variableId;

export const QuotaEntityAttributes = {
  [VariableObjectsEnum.user]: {
    showUnassignedMessage: defineMessage({ defaultMessage: 'Show unassigned users' }),
    uniqueIdAttribute: 'userId',
    buildCellValue: (user: Plan | TeamContract | UserContract) =>
      user ? formatUserFullName(user as UserContract) : '',
    buildCellLabel: (user: Plan | TeamContract | UserContract) => (user as UserContract).email,
    columnToAdd: {
      name: 'user',
      title: defineMessage({ defaultMessage: 'User' }),
      getCellValue: (row: any) => row.cellValue,
    },
    fetchPromise: (dispatch: ThunkDispatch) => dispatch(fetchAllUsers(true)),
  },
  [VariableObjectsEnum.team]: {
    showUnassignedMessage: defineMessage({ defaultMessage: 'Show unassigned teams' }),
    uniqueIdAttribute: 'teamId',
    buildCellValue: (team: Plan | TeamContract | UserContract) => (team as TeamContract).name,
    buildCellLabel: (team: Plan | TeamContract | UserContract) => (team as TeamContract).name,
    columnToAdd: {
      name: 'team',
      title: defineMessage({ defaultMessage: 'Team' }),
      getCellValue: (row: any) => row.cellValue,
    },
    fetchPromise: (dispatch: ThunkDispatch) => dispatch(fetchAllTeamsThunkAction()),
  },
  [VariableObjectsEnum.plan]: {
    showUnassignedMessage: defineMessage({ defaultMessage: 'Show unassigned plans' }),
    uniqueIdAttribute: 'planId',
    buildCellValue: (plan: Plan | TeamContract | UserContract) => (plan as Plan).name,
    buildCellLabel: (plan: Plan | TeamContract | UserContract) => (plan as Plan).name,
    columnToAdd: {
      name: 'plan',
      title: defineMessage({ defaultMessage: 'Plan' }),
      getCellValue: (row: any) => row.cellValue,
    },
    fetchPromise: (dispatch: ThunkDispatch) => dispatch(fetchPlans()),
  },
};
