import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('MATCH', AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 2;

func.description =
  'Return true if lookupValue matches searchKey in lookupArray. If you are using Team Hierarchy on Amalia, please use MATCH_USERS_ASSIGNED function';
// + 'Loop through the `lookupArray`. Returns true if a value of one of '
// + 'the `searchKeys` properties of one item of this array is equal or included inside the `lookupValue`.'

func.params = [
  { name: 'lookupValue', description: 'Value to look up: variables, fields, properties, string, keywords' },
  { name: 'lookupArray', description: 'Array to search in: array, keywords such as team.employees' },
  { name: 'searchKeys', description: 'Keys to look up such as ["externalId"]', defaultValue: '["id"]' },
];

func.examples = [
  {
    desc: "Returns true if Opportunity Owner ID matches one of the user's team members.",
    formula: 'MATCH(opportunity.ownerId, user.teamMembers, ["externalId"])',
  },
  {
    desc: 'Returns true.',
    formula:
      'MATCH("Jean Dupont", [{ firstName: "Jean", lastName: "Dupont" }], ["firstName", "lastName"])' as AmaliaFormula,
    result: true,
  },
];

func.exec = (lookupValue: { toString: () => any[] | string }, lookupArray: any, searchKeys?: string[]): boolean => {
  if (!lookupValue) {
    return false;
  }

  const keys = searchKeys || ['id'];
  for (const object of lookupArray) {
    let hasMatch = true;
    for (const key of keys) {
      hasMatch &&= lookupValue.toString().includes(object[key]);
    }
    if (hasMatch) {
      return true;
    }
  }
  return false;
};

export default func;
