import { IconChevronDown } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  useCallback,
  useState,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type JSXElementConstructor,
  cloneElement,
} from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { Collapse } from '../../../general/collapse/Collapse';
import { type CountBadgeProps } from '../../../general/count-badge/CountBadge';
import { CountBadgeSize } from '../../../general/count-badge/CountBadge.types';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';

import * as styles from './DropdownGroup.styles';

export type DropdownGroupProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Group label. */
      label: ReactNode;
      /** Optional count badge. */
      countBadge?: ReactElement<CountBadgeProps, JSXElementConstructor<CountBadgeProps>>;
      /** Options. */
      children?: ReactNode;
      /** Is the group open. Leave undefined for uncontrolled toggle. */
      isOpen?: boolean;
      /** Open change handler. Called with the new value. */
      onChangeIsOpen?: (isOpen: boolean) => void;
      /** Initial open state if isOpen is undefined. */
      initialIsOpen?: boolean;
      /** If not collapsible, the group is considered expanded. */
      isCollapsible?: boolean;
    },
  ]
>;

const DropdownGroupBase = memo(function DropdownGroup({
  label,
  countBadge,
  children,
  isOpen: controlledIsOpen = undefined,
  onChangeIsOpen: controlledOnChangeIsOpen = undefined,
  initialIsOpen = true,
  isCollapsible = true,
  ...props
}: DropdownGroupProps) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(initialIsOpen);

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;
  const onChangeIsOpen = controlledOnChangeIsOpen ?? setUncontrolledIsOpen;

  const handleToggleOpen = useCallback(() => onChangeIsOpen(!isOpen), [isOpen, onChangeIsOpen]);

  return (
    <styles.DropdownGroupContainer {...props}>
      <UnstyledButton
        css={styles.dropdownGroupTrigger}
        disabled={!isCollapsible}
        onClick={handleToggleOpen}
      >
        <TextOverflow
          css={(theme) => theme.ds.typographies.bodyXsmallMedium}
          tooltipContent={label}
          tooltipPlacement="right"
        >
          {label}
        </TextOverflow>

        {!!countBadge &&
          cloneElement(countBadge, {
            size: CountBadgeSize.SMALL,
          })}

        {isCollapsible ? (
          <IconChevronDown
            className={clsx({ [styles.IS_OPEN_CLASSNAME]: isOpen })}
            color="currentColor"
            css={styles.arrow}
            size={16}
          />
        ) : (
          // Empty element for justify-content: space-between.
          <div />
        )}
      </UnstyledButton>

      <Collapse
        lazy
        isOpen={!isCollapsible || isOpen}
      >
        {children}
      </Collapse>
    </styles.DropdownGroupContainer>
  );
});

export const DropdownGroup = Object.assign(DropdownGroupBase, {
  Container: styles.DropdownGroupContainer,
});
