import { type Theme, css } from '@emotion/react';

import { ButtonSize, ButtonVariant } from './Button.types';

export const IS_ACTIVE_CLASSNAME = 'is-active';

export const button = (theme: Theme) => css`
  display: flex;
  align-items: center;

  flex: none;

  /* No transition on outline because it does not work well. */
  transition: ${theme.ds.transitions.default('color', 'background-color', 'border-color', 'box-shadow', 'padding')};

  border-radius: ${theme.ds.borderRadiuses.squared};
  border: 1px solid transparent;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;

    color: ${theme.ds.colors.gray[300]};

    &.${ButtonVariant.PRIMARY},
      &.${ButtonVariant.SECONDARY},
      &.${ButtonVariant.SECONDARY_LIGHT},
      &.${ButtonVariant.LIGHT},
      &.${ButtonVariant.DANGER},
      &.${ButtonVariant.SUCCESS} {
      background-color: ${theme.ds.colors.gray[50]};
    }

    &.${ButtonVariant.SECONDARY_TEXT}, &.${ButtonVariant.LIGHT_TEXT} {
      background-color: ${theme.ds.colors.gray[0]};
    }
  }

  &:not(:disabled) {
    &:focus-visible {
      outline: 1px solid ${theme.ds.colors.secondary[200]};
      outline-offset: -1px;

      &.${ButtonSize.SMALL} {
        box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};
      }

      &.${ButtonSize.MEDIUM} {
        box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
      }

      &.${ButtonSize.LARGE} {
        outline-width: 2px;
        outline-offset: -2px;
        box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.secondary[50])};
      }
    }

    &.${ButtonVariant.PRIMARY} {
      background-color: ${theme.ds.colors.primary[400]};
      color: ${theme.ds.colors.gray[900]};

      &:hover,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.primary[500]};
      }
    }

    &.${ButtonVariant.SECONDARY} {
      background-color: ${theme.ds.colors.secondary[400]};
      color: ${theme.ds.colors.gray[0]};

      &:hover,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.secondary[500]};
      }
    }

    &.${ButtonVariant.SECONDARY_LIGHT}, &.${ButtonVariant.SECONDARY_TEXT} {
      background-color: ${theme.ds.colors.gray[0]};
      color: ${theme.ds.colors.secondary[500]};

      &:hover,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.gray[50]};
      }
    }

    &.${ButtonVariant.LIGHT}, &.${ButtonVariant.LIGHT_TEXT} {
      background-color: ${theme.ds.colors.gray[0]};
      color: ${theme.ds.colors.gray[800]};

      &:hover,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.gray[50]};
      }
    }

    &.${ButtonVariant.DANGER} {
      border-color: ${theme.ds.colors.danger[500]};
      background-color: ${theme.ds.colors.danger[50]};
      color: ${theme.ds.colors.danger[700]};

      &:hover,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.danger[100]};
      }

      &:focus-visible {
        outline-color: ${theme.ds.colors.danger[500]};

        &.${ButtonSize.SMALL} {
          box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
        }

        &.${ButtonSize.MEDIUM} {
          box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
        }

        &.${ButtonSize.LARGE} {
          box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.danger[100])};
        }
      }
    }

    &.${ButtonVariant.SUCCESS} {
      border-color: ${theme.ds.colors.success[500]};
      background-color: ${theme.ds.colors.success[50]};
      color: ${theme.ds.colors.success[700]};

      &:hover,
      &.${IS_ACTIVE_CLASSNAME} {
        background-color: ${theme.ds.colors.success[100]};
      }

      &:focus-visible {
        outline-color: ${theme.ds.colors.success[500]};

        &.${ButtonSize.SMALL} {
          box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.success[100])};
        }

        &.${ButtonSize.MEDIUM} {
          box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.success[100])};
        }

        &.${ButtonSize.LARGE} {
          box-shadow: ${theme.ds.shadows.focusLarge(theme.ds.colors.success[100])};
        }
      }
    }
  }

  &.${ButtonVariant.PRIMARY}, &.${ButtonVariant.SECONDARY}, &.${ButtonVariant.DANGER}, &.${ButtonVariant.SUCCESS} {
    box-shadow: ${theme.ds.shadows.soft};
  }

  &.${ButtonVariant.SECONDARY_LIGHT}, &.${ButtonVariant.LIGHT} {
    box-shadow: ${theme.ds.shadows.hard};

    &:disabled {
      box-shadow: ${theme.ds.shadows.soft};
    }
  }

  &.${ButtonSize.SMALL} {
    padding: 2px 7px;
  }

  &.${ButtonSize.MEDIUM} {
    padding: 4.5px 11px;
  }

  &.${ButtonSize.LARGE} {
    padding: 7px 15px;
  }
`;

export const text = css`
  &.${ButtonSize.SMALL} {
    padding: 0 6px;
  }

  &.${ButtonSize.MEDIUM} {
    padding: 0 8px;
  }

  &.${ButtonSize.LARGE} {
    padding: 0 12px;
  }
`;

export const icon = css`
  // Prevent icon from shrinking when the label needs to wrap.
  flex: none;

  display: flex;
  align-items: center;
`;
