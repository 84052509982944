import { css } from '@emotion/react';
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { Fragment, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { type ComputedPlanRuleFieldsToDisplay, type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { selectUsersMap } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, subject, SubjectsEnum } from '@amal-ia/lib-rbac';
import { type ComputedRule, type DatasetRow, formatUserFullName, isBeingReviewed } from '@amal-ia/lib-types';
import { DataGridPluginPosition, useStatementDetailContext } from '@amal-ia/lib-ui';
import { DatasetType } from '@amal-ia/payout-calculation/shared/types';

import { RowsTable } from './RowsTable/RowsTable';
import RuleResultAccessoryResults from './RuleResultAccessoryResults';
import { StatementRuleDatasetMenu } from './statement-rule-dataset-menu/StatementRuleDatasetMenu';
import { useDatasets } from './statement-rule-dataset-menu/useDatasets';

interface RuleResultProps {
  computedRule: ComputedRule;
  ruleDefinition: PlanRule;
  setCurrentTracingData?: any;
  globalSearchValue?: string;
}

const RuleResult = memo(function RuleResult({
  computedRule,
  ruleDefinition,
  setCurrentTracingData,
  globalSearchValue,
}: RuleResultProps) {
  const usersMap = useSelector(selectUsersMap);

  const ability = useAbilityContext();
  const canViewPlan = ability.can(ActionsEnum.view, subject(SubjectsEnum.Plan, {}));

  // =============== DEBUG ==============
  const statement = useStatementDetailContext();

  const { datasetsWithFields, selectedDataset, setSelectedDataset } = useDatasets(statement, ruleDefinition);

  /**
   * When the datasets list change, we select the first dataset.
   */
  useEffect(() => {
    const firstDataset = datasetsWithFields[0];
    setSelectedDataset(firstDataset);
  }, [datasetsWithFields, setSelectedDataset]);

  const enrichTracingDataWithRule = useCallback(
    (tracingData: { datasetRow: DatasetRow; fields: ComputedPlanRuleFieldsToDisplay[] }) => {
      setCurrentTracingData({
        ...tracingData,
        rule: computedRule,
      });
    },
    [setCurrentTracingData, computedRule],
  );

  const serializeRow = useMemo(() => {
    if (
      selectedDataset?.type === DatasetType.metrics &&
      selectedDataset?.customObjectDefinition.machineName === 'statementMetric'
    ) {
      return (row: DatasetRow) => {
        const foundUser = usersMap[row.content.userId];
        return foundUser ? formatUserFullName(foundUser) : undefined;
      };
    }
    return (row: DatasetRow) => row.externalId;
  }, [selectedDataset, usersMap]);

  const isReadonly = useMemo(() => isBeingReviewed(statement), [statement]);

  const headerElements = useMemo(
    () => [
      {
        key: 'dataset-menu',
        // People that have access to Designer (canViewPlan), will see the dataset menu even if only 1 dataset item.
        // Otherwise, for other users, menu is displayed if we have more than 1 dataset to display.
        children: ((canViewPlan && datasetsWithFields?.length > 0) || datasetsWithFields?.length > 1) && (
          <div
            css={css`
              margin-right: 8px;
            `}
          >
            <StatementRuleDatasetMenu
              computedStatementDefinitions={statement.results.definitions}
              datasets={datasetsWithFields}
              selectDatasetToDisplay={setSelectedDataset}
              selectedDataset={selectedDataset}
            />
          </div>
        ),
        position: DataGridPluginPosition.rightStart,
      },
    ],
    [canViewPlan, datasetsWithFields, statement.results.definitions, setSelectedDataset, selectedDataset],
  );

  return (
    <Fragment>
      <Box width="100%">
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
          p={2}
          spacing={4}
        >
          {computedRule ? <RuleResultAccessoryResults ruleDefinition={ruleDefinition} /> : null}
        </Grid>
      </Box>

      {!!selectedDataset && !!selectedDataset.fields && (
        <Box sx={{ width: '100%', marginTop: '40px' }}>
          {!!selectedDataset && (
            <RowsTable
              dataset={selectedDataset}
              fields={selectedDataset.fields}
              forecasted={statement.isForecastedView}
              globalSearchValue={globalSearchValue}
              hideComments={statement.isForecastedView}
              hideEditOverwrite={isReadonly || statement.isForecastedView}
              ruleDefinition={ruleDefinition}
              ruleId={ruleDefinition.id}
              serializeRow={serializeRow}
              setCurrentTracingData={enrichTracingDataWithRule}
              moreDatagridOptions={{
                additionalHeaderComponents: headerElements,
              }}
            />
          )}
        </Box>
      )}
    </Fragment>
  );
});

export default RuleResult;
