import { Box, FormControl, RadioGroup, type RadioProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { snakeCase } from 'lodash';
import { memo, type ChangeEvent } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { type OptionType } from '../../../../utils/common.types';
import { TextType, Text } from '../../typography';
import { formikToMui } from '../formikToMui';
import { InputLabel } from '../inputs/Input/Input';

import { RadioOption } from './RadioOption';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    margin: (props: { noMargin: boolean }) => (props.noMargin ? 0 : theme.spacing(2, 0)),
  },
}));

interface RadiosBaseProps extends Pick<RadioProps, 'color'> {
  label?: string;
  name?: string;
  options: OptionType[];
  value?: string;
  onChange?: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  layout?: 'column' | 'row';
  groupClassName?: string;
  optionClassName?: string;
  noMargin?: boolean;
}

export const RadiosBase = memo(function RadiosBase({
  label,
  name,
  options,
  value,
  onChange,
  color,
  layout,
  groupClassName,
  optionClassName,
  noMargin = false,
}: RadiosBaseProps) {
  const classes = useStyles({ noMargin });

  return (
    <Box className={classes.root}>
      {label ? (
        <InputLabel>
          <Text type={TextType.INPUT_NAME}>{label}</Text>
        </InputLabel>
      ) : null}
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <RadioGroup
          name={name || snakeCase(label)}
          value={value}
          onChange={onChange}
        >
          <Box
            className={groupClassName}
            display="flex"
            flexDirection={layout || 'column'}
          >
            {options.map((args) => (
              <RadioOption
                key={args.value}
                {...args}
                className={optionClassName}
                color={color}
              />
            ))}
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
});

export const Radios = memo<RadiosBaseProps>(formikToMui(RadiosBase));
