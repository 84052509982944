import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import { CalculationParser, type ParserScope } from '../../../CalculationParser';
import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('convertCurrency', AmaliaFunctionCategory.CURRENCY);

func.nbParamsRequired = 3;

func.description = 'Return an amount converted from a source currency to a target currency`';

func.params = [
  { name: 'amount', description: 'Amount to convert' },
  { name: 'sourceCurrency', description: 'Source currency: currency code, variables, fields, properties, keywords' },
  { name: 'targetCurrency', description: 'Target currency: currency code, variables, fields, properties, keywords' },
];

func.examples = [
  {
    desc: 'Converts Payout from EUR to USD.',
    formula: 'convertCurrency(opportunity.payout, "EUR", "USD")' as AmaliaFormula,
  },
  {
    desc: "Converts opportunity's amount from opportunity currency to user's currency.",
    formula: 'convertCurrency(opportunity.amount, opportunity.currency, user.currency)' as AmaliaFormula,
  },
];

func.generateComputedFunctionResult = (args) => ({
  formula: args[0].toString() as AmaliaFormula,
  fromCurrencyCode: args[1].toString() as AmaliaFormula,
  toCurrencyCode: args[2].toString() as AmaliaFormula,
});

func.execMock = () => 1;

func.execRawArgs = (args: any[], _, scope: ParserScope) =>
  CalculationParser.getFunctionResult(args, scope, 'convertCurrency');

export default func;
