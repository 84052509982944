import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { customObjectRoutesMessages } from './customObject.routes.messages';

const Connection = lazy(() => import('./connectors/connector/connection/Connection'));
const DataConnectorPage = lazy(() => import('./connectors/connector/DataConnectorPage'));
const UserConnectorContainer = lazy(() => import('./connectors/userConnector/UserConnectorContainer'));
const Connectors = lazy(() => import('./connectors/Connectors'));
const DataPage = lazy(() => import('./index'));

export const CustomObjectRoutes = memo(function CustomObjectRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="data">
      <Switch>
        <CompanyRoute
          component={Connection}
          path={routes.DATA_CONNECTOR_CONNECTION}
          shouldRedirectToHome={ability.cannot(ActionsEnum.modify, SubjectsEnum.Data_Connector)}
          title={formatMessage(customObjectRoutesMessages.DATA_CONNECTOR_CONNECTION)}
          user={user}
        />
        <CompanyRoute
          component={DataConnectorPage}
          path={routes.DATA_CONNECTOR}
          shouldRedirectToHome={ability.cannot(ActionsEnum.modify, SubjectsEnum.Data_Connector)}
          title={formatMessage(customObjectRoutesMessages.DATA_CONNECTOR)}
          user={user}
        />
        <CompanyRoute
          component={UserConnectorContainer}
          path={routes.DATA_USER_CONNECTOR}
          shouldRedirectToHome={ability.cannot(ActionsEnum.modify, SubjectsEnum.Data_Connector)}
          title={formatMessage(customObjectRoutesMessages.DATA_USER_CONNECTOR_LIST)}
          user={user}
        />
        <CompanyRoute
          component={Connectors}
          path={routes.DATA_CONNECTORS}
          shouldRedirectToHome={ability.cannot(ActionsEnum.modify, SubjectsEnum.Data_Connector)}
          title={formatMessage(customObjectRoutesMessages.DATA_CONNECTOR_LIST)}
          user={user}
        />

        <CompanyRoute
          component={DataPage}
          path={routes.DATA_SLUG}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Data)}
          user={user}
        />
        <CompanyRoute
          component={DataPage}
          path={routes.DATA}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Data)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
