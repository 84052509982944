import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

export const useStylesMainMenu = makeStyles((theme: AmaliaThemeType) => {
  const menuHeight = '8.5vh';
  return {
    root: {
      flexGrow: 1,
    },
    menu: {
      background: theme.palette.gradients.main,
    },
    logoLink: {
      whiteSpace: 'nowrap',
      display: 'flex',
      textDecoration: 'none',
    },
    title: {
      flexGrow: 1,
    },
    tabsRoot: {
      minHeight: menuHeight,
      height: menuHeight,
      paddingLeft: '30px',
    },
    tabRoot: {
      height: `calc(${menuHeight} - 30px)`,
      textDecoration: 'none',
      textTransform: 'capitalize',
      maxWidth: 'fit-content',
      width: 'fit-content',
      minWidth: 'fit-content',
      margin: '15px 0',
      padding: theme.spacing(0, 3),

      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    impersonatesChip: {
      cursor: 'pointer',

      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
  };
});

export const useStylesSecondMenu = makeStyles((theme: AmaliaThemeType) => {
  const secondMenuHeight = '8.5vh';
  return {
    root: {
      flexGrow: 1,
    },
    menu: {
      background: theme.palette.common.white,
    },
    title: {
      flexGrow: 1,
    },
    tabsRoot: {
      minHeight: secondMenuHeight,
      height: secondMenuHeight,
      paddingLeft: '30px',
    },
    tabRoot: {
      minHeight: secondMenuHeight,
      height: secondMenuHeight,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    actionButton: {
      wordBreak: 'keep-all',
      minWidth: '8rem',
      textAlign: 'center',
    },
  };
});
