import { css } from '@emotion/react';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Link } from '@amal-ia/ext/react-router-dom';
import { IconAmaliaIoLogoWithText } from '@amal-ia/frontend/ui-icons';
import { NotificationsWidget } from '@amal-ia/tenants/users/notifications/components';

export const LeftBarHeader = memo(function LeftBarHeader() {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      `}
    >
      <Link
        to={generatePath(routes.ROOT)}
        css={css`
          display: flex;
        `}
      >
        <IconAmaliaIoLogoWithText
          css={css`
            width: 104px;
            height: auto;
          `}
        />
      </Link>

      <NotificationsWidget />
    </div>
  );
});
