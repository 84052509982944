import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('monthsTo', AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 2;

func.description = 'Return the number of months between two dates (rounded up)';

func.params = [
  {
    name: 'date1',
    description:
      'Date to start counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
  },
  {
    name: 'date2',
    description:
      'Date to end counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
  },
];

func.examples = [
  {
    desc: 'Returns 1 (rounded up)',
    formula: 'monthsTo(toDate("2023-05-01"), toDate("2023-05-06"))' as AmaliaFormula,
    result: 1,
  },
  {
    desc: 'Returns 1',
    formula: 'monthsTo(toDate("2023-05-19"), toDate("2023-06-19"))' as AmaliaFormula,
    result: 1,
  },
  {
    desc: 'Returns 8 (rounded up)',
    formula: 'monthsTo(toDate("2023-02-12"), toDate("2023-10-05"))' as AmaliaFormula,
    result: 8,
  },
  {
    desc: 'Returns the number of months between the statement start date and the Close Date rounded up.',
    formula: 'monthsTo(statementPeriod.startDate, opportunity.closeDate)' as AmaliaFormula,
  },
];

// https://github.com/moment/moment/issues/3713
func.exec = (date1: moment.MomentInput | string, date2: moment.MomentInput | string): number | null =>
  Math.ceil(moment(date2, 'X').diff(moment(date1, 'X'), 'months', true));

export default func;
