import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

const useStyles = makeStyles({
  spinnerBoundary: {
    position: 'relative',
  },
});

interface SpinnerBoundaryProps {
  children: ReactNode;
}

export const SpinnerBoundary = memo(function SpinnerBoundary({ children }: SpinnerBoundaryProps) {
  const classes = useStyles();
  return <div className={classes.spinnerBoundary}>{children}</div>;
});
