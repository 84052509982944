import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Form, Formik } from 'formik';
import { isNil } from 'lodash';
import { memo, useCallback, useMemo } from 'react';

import { Button } from '@amal-ia/frontend/design-system/components';
import {
  type Plan,
  type PlansMap,
  type Variable,
  VariableObjectsEnum,
  type WorkflowDefinition,
} from '@amal-ia/lib-types';
import {
  DialogTitleWithCloseButton,
  type OptionType,
  SelectField,
  SwitchField,
  Text,
  TextField,
  TextType,
} from '@amal-ia/lib-ui';

import { renderOptions } from '../../../../utils/rule.utils';

interface SettingsModalProps {
  workflows: WorkflowDefinition[];
  availableVariables: Variable[];
  plansMap: PlansMap;
  isOpen: boolean;
  isLoading: boolean;
  plan: Plan;
  onCancel: () => void;
  onSubmit: (
    values: Pick<
      Plan,
      'linkToPlanDocumentation' | 'manualWeight' | 'totalVariableId' | 'useVariableInsteadOfTotal' | 'workflowId'
    >,
  ) => void;
}

export const SettingsModal = memo(function SettingsModal({
  workflows,
  availableVariables,
  plansMap,
  isOpen,
  isLoading,
  plan,
  onCancel,
  onSubmit,
}: SettingsModalProps) {
  const initialValues = useMemo(
    () => ({
      workflowId: plan?.workflowId || 'default',
      linkToPlanDocumentation: plan?.linkToPlanDocumentation || '',
      useVariableInsteadOfTotal: plan?.useVariableInsteadOfTotal,
      totalVariableId: plan?.totalVariableId,
      manualWeight: plan?.manualWeight || '',
    }),
    [plan],
  );

  const onSubmitProxy = useCallback(
    (values: any) => {
      onSubmit({
        workflowId: values.workflowId !== 'default' ? values.workflowId : null,
        linkToPlanDocumentation: values.linkToPlanDocumentation || null,
        useVariableInsteadOfTotal: values.useVariableInsteadOfTotal,
        totalVariableId: values.totalVariableId,
        manualWeight: values.manualWeight === '' ? null : +values.manualWeight,
      });
    },
    [onSubmit],
  );

  const workflowOptions = useMemo<OptionType[]>(
    () =>
      workflows
        .map(({ name, id }) => ({ label: name, value: id || 'default' }))
        .sort((w1, w2) => w1.label.localeCompare(w2.label)),
    [workflows],
  );

  const variableOptions = useMemo(
    () =>
      renderOptions(
        availableVariables.filter((v) => v.type === VariableObjectsEnum.statement),
        plansMap,
      ),
    [availableVariables, plansMap],
  );

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitProxy}
      >
        {({ values }) => (
          <Form>
            <DialogTitleWithCloseButton handleClose={onCancel}>
              <Text type={TextType.MODAL_TITLE}>Plan settings</Text>
            </DialogTitleWithCloseButton>
            <DialogContent dividers>
              <SelectField
                dir="top"
                id="select-workflow"
                label="Select the workflow that applies to this plan"
                name="workflowId"
                options={workflowOptions}
              />
              <TextField
                label="Link to the documentation of this plan"
                name="linkToPlanDocumentation"
                placeholder="http://example.com/link/to/documentation.pdf"
                type="url"
              />

              <SwitchField
                help="You can use a variable result in header instead of total"
                id="useVariableInsteadOfTotal"
                label="Use a variable result in header instead of total ?"
                name="useVariableInsteadOfTotal"
              />

              {values.useVariableInsteadOfTotal ? (
                <SelectField
                  hasResetOption
                  id="totalVariableId"
                  label="Choose a variable result to replace total"
                  name="totalVariableId"
                  options={variableOptions}
                />
              ) : null}

              <TextField
                label="Manually set plan weight"
                name="manualWeight"
                placeholder={!isNil(plan.indexedWeight) ? `Current weight: ${plan.indexedWeight}` : ''}
                help="
                  You can override the plan weight if you want to manually set the plan calculation order. By default, plan using
                  team assignments (team.*, user.teamMembers, and MATCH_USERS_ASSIGNED) have a weight of 100, others have a weight of 0.
                  Plans with the same weight will be computed in parallel.
                "
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isLoading}
                variant={Button.Variant.LIGHT}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? 'Loading' : 'Save'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});
