import { ButtonBase } from '@mui/material';
import { memo, useCallback } from 'react';

import { amaliaTheme, colors } from '@amal-ia/ext/mui/theme';
import { formatTotalAmount } from '@amal-ia/lib-types';
import { Tooltip } from '@amal-ia/lib-ui';

import { type StatementHistogramConfig, type StatementHistogramItem } from './statementHistogram.types';

interface StatementHistogramBarProps {
  statement: StatementHistogramItem;
  maximumAmount: number;
  onClickBar: (period: string) => void;
  config: StatementHistogramConfig;
}

export const StatementHistogramBar = memo(function StatementHistogramBar({
  statement,
  maximumAmount,
  onClickBar,
  config,
}: StatementHistogramBarProps) {
  // Consider that the full bar is 100%, we evaluate how much we need to fill it using the maximum amount.
  const heightProportion = ((statement.total < 0 ? 0 : statement.total) / maximumAmount) * 100;
  // Proxying the callback so the period is in the parameters.
  const onClickBarProxy = useCallback(() => statement?.id && onClickBar(statement.id), [statement, onClickBar]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Tooltip
        key="histogram-bar-amount"
        placement="bottom-start"
        title={formatTotalAmount(statement.total, statement.format, statement.currency)}
      >
        <ButtonBase
          aria-label={statement.period}
          style={{
            height: config.barHeight,
            width: config.barWidth,
            borderRadius: config.barWidth / 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            backgroundColor: colors['grey-100'],
          }}
          onClick={onClickBarProxy}
        >
          <div
            style={{
              height: `${heightProportion}%`,
              width: config.barWidth,
              borderRadius: config.barWidth / 2,
              background: `linear-gradient(180deg, ${amaliaTheme.palette.primary.light} 0%, ${amaliaTheme.palette.primary.main} 100%), ${amaliaTheme.palette.primary.main}`,
            }}
          />
        </ButtonBase>
      </Tooltip>
    </div>
  );
});
