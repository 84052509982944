import { Fragment, memo, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { type ComputedPlanRuleFieldsToDisplay, type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { log } from '@amal-ia/frontend/kernel/logger';
import { type ComputedRule, type DatasetRow, formatTotal, type Statement, TracingTypes } from '@amal-ia/lib-types';
import { TracingContext } from '@amal-ia/lib-ui';
import { TracingBlock, useTracingStyles } from '@amal-ia/lib-ui-business';
import { type Dataset, type FilterDataset } from '@amal-ia/payout-calculation/shared/types';

import TracingRow from './TracingRow';
import {
  parseFormula,
  renderFilter,
  renderFormula,
  renderFunctionTracingBlock,
  renderRowMarginalIndex,
  TracingBlockForTable,
} from './TracingUtils';

interface TracingRuleProps {
  computedRule: ComputedRule;
  statement: Statement;
  currencySymbol: CurrencySymbolsEnum;
  currencyRate: number;
}

const TracingRule = memo(function TracingRule({
  computedRule,
  statement,
  currencySymbol,
  currencyRate,
}: TracingRuleProps) {
  const classes = useTracingStyles();
  const ability = useAbilityContext();
  const { statementDatasets } = useContext(TracingContext);

  const [formulaChildren, setFormulaChildren] = useState<{ tracingData: any; index: number; indexInFormula: number }[]>(
    [],
  );

  const ruleDefinition: PlanRule | undefined = useMemo(
    () => statement.results.definitions.plan.rules?.find((rd) => rd.ruleMachineName === computedRule.ruleMachineName),
    [computedRule, statement],
  );

  // Store fields and dataset row for deal tracing
  const [fieldsAndDatasetRow, setFieldsAndDatasetRow] = useState<{
    datasetRow: DatasetRow;
    dataset: Dataset;
    fields: ComputedPlanRuleFieldsToDisplay[];
  } | null>(null);

  // Reset fields and dataset row to null if formula children was changed
  useEffect(() => setFieldsAndDatasetRow(null), [formulaChildren]);

  // Handle specific case for tracing per deal nodes
  const handleNodeForTracing = useCallback((node: any) => {
    const currentNode = node;

    // If the node is an opportuniy one, overwrite its total to be null
    // In the general tracing, opportunity nodes values are counter-intuitive and their value should not be printed
    if (
      [TracingTypes.FormulaNodeType.custom_object, TracingTypes.FormulaNodeType.variable].includes(currentNode.type) &&
      currentNode.value
    ) {
      currentNode.value.total = null;
    }

    // Return the node
    return currentNode;
  }, []);

  const setTracingData = useCallback(
    ({ datasetRow, dataset, fields }: TracingTypes.CurrentTracingDataType) => {
      setFieldsAndDatasetRow({
        fields,
        dataset,
        datasetRow,
      });
    },
    [setFieldsAndDatasetRow],
  );

  // Render a tracing block according to the node
  const renderFormulaChild: any = useCallback(
    ({ tracingData, index, functionParameters }: any): ReactNode => {
      if (!tracingData) {
        return null;
      }

      // If this is a filter, render it with the appropriate method
      if (tracingData.type === TracingTypes.FormulaNodeType.filter_dataset) {
        return renderFilter(
          ability,
          tracingData as FilterDataset,
          statement,
          statementDatasets,
          computedRule,
          classes,
          setTracingData,
        );
      }

      if (tracingData.type === TracingTypes.FormulaNodeType.rowMarginal) {
        return renderRowMarginalIndex(tracingData, statement, statementDatasets, currencySymbol, classes);
      }

      // If this is a table, pass it to the TracingBlockForTable
      if (tracingData.format === 'table') {
        return (
          <TracingBlockForTable
            key={index}
            result={tracingData}
            statementCurrency={statement.currency}
          />
        );
      }

      // If a function is detected, render it with the renderFunction
      if (tracingData.function) {
        return renderFunctionTracingBlock(
          index,
          tracingData,
          currencySymbol,
          currencyRate,
          functionParameters,
          statement,
        );
      }

      // Otherwise, print it as a generalist block

      // Compute total of this block
      let value = tracingData.format
        ? formatTotal(tracingData.total, tracingData.format, currencySymbol, currencyRate)
        : tracingData.total;

      // Force parse the value when we are on a rule
      if (tracingData.type === TracingTypes.FormulaNodeType.rule) {
        value = formatTotal(tracingData.total, FormatsEnum.currency, currencySymbol, currencyRate);
      }

      // Don't print total if node is opportunity when on classic tracing
      if (
        [TracingTypes.FormulaNodeType.custom_object, TracingTypes.FormulaNodeType.variable].includes(tracingData.type)
      ) {
        value = null;
      }

      // parse the formula nodes, handling special cases
      const formulaNodes = parseFormula(tracingData, computedRule, statement, statementDatasets).map(
        handleNodeForTracing,
      );

      if (value && typeof value === 'object') {
        log.error('Tracing block value has not been parsed correctly, found for node:', { value, tracingData });
        return 'ERROR';
      }

      // Print a tracing block paasing computed arguments
      return (
        <TracingBlock
          key={`${tracingData.function || ''}${tracingData.name}`}
          format={tracingData.format}
          formula={tracingData.formula}
          machineName={tracingData.machineName}
          overwrite={tracingData.overwrite}
          statementCurrency={statement.currency}
          value={value}
          type={
            TracingTypes.TracingBlockType[(tracingData.type?.toUpperCase() as TracingTypes.TracingBlockType) || ''] ||
            TracingTypes.TracingBlockType.VARIABLE
          }
        >
          {tracingData.formula
            ? renderFormula(
                tracingData,
                index,
                formulaChildren,
                setFormulaChildren,
                currencySymbol,
                currencyRate,
                formulaNodes,
              )
            : null}
        </TracingBlock>
      );
    },
    [
      currencySymbol,
      currencyRate,
      formulaChildren,
      statement,
      computedRule,
      handleNodeForTracing,
      classes,
      setTracingData,
      ability,
      statementDatasets,
    ],
  );

  // Render the first level of the RuleResult along with selected children
  return (
    <Fragment>
      {renderFormulaChild({
        tracingData: {
          ...computedRule,
          ...(ruleDefinition || {}),
          type: TracingTypes.FormulaNodeType.rule,
          total: computedRule?.value,
        },
        type: TracingTypes.FormulaNodeType.rule,
        index: 0,
      })}
      {formulaChildren ? formulaChildren.map(renderFormulaChild) : null}
      {fieldsAndDatasetRow ? (
        <TracingRow
          currencyRate={currencyRate}
          currencySymbol={currencySymbol}
          dataset={fieldsAndDatasetRow.dataset}
          datasetRow={fieldsAndDatasetRow.datasetRow}
          fields={fieldsAndDatasetRow.fields}
          ruleResult={computedRule}
          statement={statement}
        />
      ) : null}
    </Fragment>
  );
});

export default TracingRule;
