import { Search } from '@mui/icons-material';
import {
  Box,
  Chip,
  FormHelperText,
  InputAdornment,
  ListItem,
  ListSubheader,
  MenuItem,
  Select,
  type SelectProps,
  Unstable_Grid2 as Grid,
  type SelectChangeEvent,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import { IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import invariant from 'invariant';
import { flatten, isEmpty, lowerCase, noop, orderBy, startsWith } from 'lodash';
import { Fragment, memo, useCallback, useMemo, useState, type ReactElement, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton, type TablerIconElement } from '@amal-ia/frontend/design-system/components';
import { StringUtils } from '@amal-ia/lib-types';

import { eventStopPropagation, eventStopPropagationAndPreventDefault } from '../../../../utils/common/eventHelpers';
import { type Option, type OptionGroup } from '../../../../utils/common.types';
import { useLoadMore } from '../../../../utils/hooks/useLoadMore';
import { COMMON_MESSAGES } from '../../../../utils/messages/common.messages';
import { InfoWithTooltip } from '../../InfoWithTooltip';
import { TextType, Text } from '../../typography';
import { Checkbox } from '../Checkbox/Checkbox';
import { formikToMui } from '../formikToMui';
import { FormElementContainer, Input, InputLabel } from '../inputs/Input/Input';
import { SearchInput } from '../inputs/SearchInput/SearchInput';

export const useSelectFieldStyles = makeStyles((theme: AmaliaThemeType) => ({
  checkboxMultiple: {
    padding: theme.spacing(0.8),
  },
  listSubHeader: {
    backgroundColor: theme.palette.common.white,
  },
  actions: {
    position: 'absolute',
    right: 0,
    top: 0,
    marginTop: -theme.spacing(0.3),
    marginRight: -theme.spacing(0.4),
  },
  actionButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(-2, 0),
  },
  disabledOption: {
    opacity: 0.9,
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  additionalContent: {},
  dialogTitle: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(0.75, 0),
    color: theme.palette.secondary.main,
  },
  greyText: {
    color: theme.palette.grey['600'],
  },
}));

export interface SelectFieldBaseProps extends SelectProps {
  options?: Option[];
  optionGroups?: OptionGroup[];
  hasResetOption?: boolean;
  containerClassName?: string;
  labelClassName?: string;
  help?: string;
  actions?: { content: ReactNode; tooltipTitle: string; callback: () => void }[];
  disabled?: boolean;
  additionalContent?: {
    start?: ReactNode;
    end?: ReactNode;
  };
  isSearchable?: boolean;
  allowSelectAll?: boolean;
  searchWithAutocompletion?: boolean;
  renderItemValueOnSelectField?: boolean;
  renderOnlyLengthValue?: boolean;
  isFilter?: boolean;
  greyPlaceholder?: boolean;
}

const renderOptions = (
  options: Option[],
  multiple: boolean | undefined,
  value: any,
  classes: any,
  isOptionsOrder?: boolean,
): ReactNode =>
  (isOptionsOrder ? orderBy(options, ['label'], ['asc']) : options)
    // Ensure that each option has value.
    .map((option) => {
      const MultipleComponent = option.componentWhenMultiple === 'RADIO' ? Radio : Checkbox;

      return (
        <MenuItem
          key={option.value}
          aria-label={typeof option.label === 'string' ? StringUtils.camelCase(option.label.trim()) : option.value}
          className={clsx(option.disabled && classes.disabledOption)}
          value={option.value}
        >
          {multiple ? (
            <MultipleComponent
              checked={(value || []).includes(option.value)}
              className={classes.checkboxMultiple}
              color="primary"
            />
          ) : null}
          {option.label}
        </MenuItem>
      );
    });

const renderOptionGroups = (
  optionGroups: OptionGroup[],
  multiple: boolean | undefined,
  value: any,
  classes: any,
): ReactNode =>
  optionGroups.map((optionGroup) => [
    <ListSubheader
      key={optionGroup.label}
      className={classes.listSubHeader}
    >
      <div
        role="presentation"
        onClick={eventStopPropagationAndPreventDefault}
        onKeyDown={eventStopPropagationAndPreventDefault}
      >
        {optionGroup.label}
      </div>
    </ListSubheader>,
    renderOptions(optionGroup.options, multiple, value, classes),
  ]);

/**
 * Check if option match search string.
 * @param o
 * @param search
 * @param withAutocompletion
 */

const optionMatchSearchPredicate = (o: Option, search: string | undefined, withAutocompletion: boolean | undefined) => {
  const haystack = o.searchString || o.label;

  if (withAutocompletion) {
    return !search || lowerCase(haystack).includes(lowerCase(search));
  }
  return !search || startsWith(lowerCase(haystack), lowerCase(search));
};

export const SelectFieldBase = memo(function SelectFieldBase({
  label,
  error,
  options,
  optionGroups,
  hasResetOption,
  placeholder,
  containerClassName,
  labelClassName,
  hidden,
  help,
  actions,
  additionalContent,
  isSearchable,
  allowSelectAll,
  searchWithAutocompletion,
  renderItemValueOnSelectField,
  greyPlaceholder,
  // render the number of item selected
  renderOnlyLengthValue,
  // consistency UI for all Filter select
  isFilter,
  ...restProps
}: SelectFieldBaseProps) {
  const { required, multiple, id = '', title, value, onChange } = restProps;
  const [search, setSearch] = useState('');
  const classes = useSelectFieldStyles();
  const { formatMessage } = useIntl();

  const filteredOptions = useMemo(
    () =>
      search
        ? (options || []).filter((o) => optionMatchSearchPredicate(o, search, searchWithAutocompletion))
        : options || [],
    [searchWithAutocompletion, options, search],
  );

  const filteredOptionGroups = useMemo(
    () =>
      search
        ? (optionGroups || [])
            .map((g) => ({
              label: g.label,
              options: g.options.filter((o) => optionMatchSearchPredicate(o, search, searchWithAutocompletion)),
            }))
            .filter((g) => !isEmpty(g.options))
        : optionGroups || [],
    [searchWithAutocompletion, optionGroups, search],
  );

  const isGroupMode = !!filteredOptionGroups.length;

  const labelsMap = useMemo(
    () =>
      (
        (filteredOptionGroups
          ? flatten([...filteredOptionGroups.map((g) => g.options), ...(filteredOptions || [])])
          : filteredOptions) || []
      ).reduce<Record<string, ReactElement | string>>((acc: Record<string, ReactElement | string>, curr: Option) => {
        acc[curr.value] = curr.label;
        return acc;
      }, {}),
    [filteredOptions, filteredOptionGroups],
  );

  const { elementsCapped, onClickLoadMore, count, total } = useLoadMore<Option>(
    isGroupMode ? filteredOptionGroups : filteredOptions,
    isGroupMode,
    50,
  );

  const renderValue = useCallback(
    (selected: string[] | string) => {
      if (isEmpty(selected) || (multiple && selected.length === 0)) {
        return greyPlaceholder ? <span className={classes.greyText}>{placeholder}</span> : placeholder;
      }

      if (renderOnlyLengthValue) {
        return `${filteredOptions.length !== selected.length ? selected.length : 'All'} ${placeholder}`;
      }

      return multiple
        ? (Array.isArray(selected) ? selected : [selected])
            .map((s) => labelsMap[s] || '')
            .map((el: ReactNode | string, idx: number, arr) =>
              !renderItemValueOnSelectField ? (
                <Fragment key={idx}>
                  {el}
                  {idx < arr.length - 1 ? <span>, </span> : null}
                </Fragment>
              ) : (
                <Chip
                  key={Object.keys(labelsMap).find((key) => labelsMap[key] === el)}
                  label={el}
                />
              ),
            )
        : labelsMap[selected as string];
    },
    [
      multiple,
      renderOnlyLengthValue,
      labelsMap,
      greyPlaceholder,
      classes.greyText,
      placeholder,
      filteredOptions.length,
      renderItemValueOnSelectField,
    ],
  );

  const selectAll = useCallback(() => {
    const selectedOptions = options.map(({ value: optValue }) => optValue);

    invariant(Array.isArray(value), 'value should be an array');

    const event = {
      target: {
        value: value.length ? [] : selectedOptions,
        name: '',
      },
    } as unknown as SelectChangeEvent<string[]>;

    onChange(event, false);
  }, [options, value, onChange]);

  const searchInput = useMemo(() => {
    if (isFilter) {
      return (
        <Input
          autoFocus
          autoComplete="off"
          placeholder={formatMessage({ defaultMessage: 'Search' })}
          startAdornment={<Search fontSize="small" />}
          value={search}
          endAdornment={
            search ? (
              <InputAdornment position="end">
                <IconButton
                  icon={<IconX />}
                  label={<FormattedMessage defaultMessage="Clear search" />}
                  onClick={() => setSearch('')}
                />
              </InputAdornment>
            ) : null
          }
          onChange={(e) => setSearch(e.currentTarget.value)}
          onClick={eventStopPropagationAndPreventDefault}
          onKeyDown={eventStopPropagation}
        />
      );
    }
    return (
      <SearchInput
        forceSearchVisible
        value={search}
        onChange={setSearch}
        onClick={eventStopPropagationAndPreventDefault}
        onKeyDown={eventStopPropagation}
      />
    );
  }, [isFilter, search, formatMessage]);

  const errorMessage = useMemo(() => {
    if (error) {
      if (typeof error === 'object') {
        if (Object.keys(error).length) {
          return JSON.stringify(error);
        }
        return '';
      }
      return error;
    }
    return '';
  }, [error]);

  // We need this: M-UI uses aria-labelledBy on select, so we need to compute another id from the first one
  // To pass to the labelId prop (that will add it to the aria-labelledBy of the select)
  const labelId = useMemo(() => (id ? `${id}_label` : undefined), [id]);

  return (
    <FormElementContainer
      className={containerClassName}
      hidden={hidden}
      relative={!!actions}
    >
      {label ? (
        <InputLabel
          className={labelClassName}
          htmlFor={id}
          id={labelId}
        >
          <Text type={TextType.INPUT_NAME}>
            {label}
            {required ? ' *' : null}
            {help ? <InfoWithTooltip>{help}</InfoWithTooltip> : null}
          </Text>
        </InputLabel>
      ) : null}
      {actions ? (
        <Box className={classes.actions}>
          {(actions || []).map(({ content, tooltipTitle, callback }, index) => (
            <IconButton
              key={index}
              className={classes.actionButton}
              icon={content as TablerIconElement}
              label={tooltipTitle}
              onClick={callback}
            />
          ))}
        </Box>
      ) : null}
      <Select
        input={<Input />}
        labelId={labelId}
        variant="standard"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          style: { maxHeight: '50%' },
        }}
        renderValue={renderValue}
        // If we pass a placeholder, we need to show it.
        displayEmpty={!!placeholder}
        {...restProps}
      >
        {title !== null && (
          <Grid onClick={noop}>
            <span className={classes.dialogTitle}>{title}</span>
          </Grid>
        )}

        {isSearchable ? (
          <div
            role="presentation"
            onClick={eventStopPropagationAndPreventDefault}
          >
            {searchInput}
          </div>
        ) : null}

        {additionalContent?.start ? <li className={classes.additionalContent}>{additionalContent.start}</li> : null}
        {hasResetOption ? (
          <MenuItem
            key=""
            value=""
          >
            - {placeholder || <FormattedMessage defaultMessage="None" />} -
          </MenuItem>
        ) : null}

        {multiple && allowSelectAll
          ? (() => {
              invariant(value instanceof Array, '');
              return (
                <li>
                  <ListItem
                    button
                    aria-label={`select all ${placeholder}`}
                    component="li"
                    onClick={selectAll}
                  >
                    <Checkbox
                      checked={value.length === options?.length}
                      className={classes.checkboxMultiple}
                      color="primary"
                      indeterminate={value.length > 0 && value.length !== options?.length}
                      onChange={selectAll}
                      onClick={selectAll}
                    />
                    <FormattedMessage {...COMMON_MESSAGES.SELECT_ALL} />
                  </ListItem>
                </li>
              );
            })()
          : null}

        {isGroupMode
          ? renderOptionGroups(elementsCapped, multiple, value, classes)
          : renderOptions(elementsCapped, multiple, value, classes, isFilter)}

        {onClickLoadMore ? (
          <li>
            <ListItem
              button
              onClick={(e) => {
                eventStopPropagationAndPreventDefault(e);
                onClickLoadMore();
              }}
            >
              <i>
                <FormattedMessage
                  {...COMMON_MESSAGES.LOAD_MORE_PROGRESS}
                  values={{ count, total }}
                />
              </i>
            </ListItem>
          </li>
        ) : null}

        {additionalContent?.end ? <li className={classes.additionalContent}>{additionalContent.end}</li> : null}
      </Select>
      {errorMessage ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
    </FormElementContainer>
  );
});

export const SelectField = memo<SelectFieldBaseProps>(formikToMui(SelectFieldBase));

SelectField.displayName = 'SelectField';
