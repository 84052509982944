import { useTheme } from '@emotion/react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  type UseFloatingOptions,
  useTransitionStyles,
  FloatingPortal,
  safePolygon,
} from '@floating-ui/react';
import clsx from 'clsx';
import {
  Children,
  Fragment,
  type ReactElement,
  type ReactNode,
  cloneElement,
  memo,
  useRef,
  useState,
  type Ref,
  forwardRef,
  type ForwardedRef,
} from 'react';

import { type AnyRecord } from '@amal-ia/ext/typescript';

import { Typography } from '../../general/typography/Typography';

import * as styles from './Tooltip.styles';

export type TooltipProps = {
  /** Is the tooltip interactive (can the user click inside it). True by default to allow copy/pasting. When using more interactions, you should consider using a Popover. */
  interactive?: boolean;
  /** Preferred placement. If not possible, the tooltip will choose its placement automatically. */
  placement?: UseFloatingOptions['placement'];
  /** Placement strategy. */
  strategy?: UseFloatingOptions['strategy'];
  /** Is the tooltip visible. Leave as undefined for uncontrolled (auto) visibility. */
  isOpen?: boolean;
  /** Is open change handler. Leave as undefined for uncontrolled visibility. */
  onChangeIsOpen?: (isOpen: boolean) => void;
  /** Tooltip title. Increases the margin. */
  title?: ReactNode;
  /** Tooltip content. */
  content: ReactNode;
  /** Don't show the tooltip. */
  disabled?: boolean;
  /** Tooltip anchor. Must allow using refs (native HTML element or component wrapped with forwardRef). */
  children: ReactElement;
};

const TooltipBase = forwardRef(function Tooltip(
  {
    isOpen: controlledIsOpen = undefined,
    onChangeIsOpen: controlledOnChangeIsOpen = undefined,
    interactive = true,
    placement = 'top',
    strategy = 'fixed',
    disabled = false,
    title = undefined,
    content,
    children,
  }: TooltipProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const theme = useTheme();
  const arrowRef = useRef<SVGSVGElement>(null);

  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState<boolean>(false);

  // The tooltip can be either controlled or uncontrolled.
  // If no isOpen prop is passed, fallback on internal state.
  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;
  const onSetIsOpen = controlledOnChangeIsOpen ?? setUncontrolledIsOpen;

  // Create a new floating context.
  const { refs, floatingStyles, context } = useFloating({
    placement,
    strategy,
    open: isOpen,
    onOpenChange: onSetIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      // Distance to the anchor.
      offset(16),
      // Should change placement if not enough space.
      flip(),
      // Should move the tooltip if too close to the edge.
      shift(),
      // Add an arrow to the tooltip. It will be rendered by <FloatingArrow /> below.
      arrow({
        element: arrowRef,
      }),
    ],
  });

  // Get the ref of the child element if it exists.
  const child = Children.only(children);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any -- Cannot get the ref without casting as any.
  const childRef = (child as any)?.ref as Ref<unknown> | undefined;

  // Merge the child ref with the reference from floating-ui.
  const childRefWithFloatingUiRef = useMergeRefs([refs.setReference, childRef, ref]);

  // Interactions.
  // Show tooltip when focusing element.
  const focus = useFocus(context, { enabled: !disabled });
  // Hide tooltip on press escape.
  const dismiss = useDismiss(context, { enabled: !disabled });
  // Add accessibility role of tooltip.
  const role = useRole(context, { role: 'tooltip', enabled: !disabled });

  // Show tooltip on hover.
  const hover = useHover(context, {
    // Delay opening by the default transition duration.
    // This way the tooltip does not appear if the user skims through the application with their cursor.
    delay: { open: theme.ds.transitions.default.durationMs },
    handleClose: interactive ? safePolygon() : undefined,
    enabled: !disabled,
  });

  // Register interactions declared above.
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  // Add a transition on show/hide tooltip. Use the default transition parameters.
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: theme.ds.transitions.default.durationMs,
    common: {
      transitionTimingFunction: theme.ds.transitions.default.easing,
    },
  });

  return (
    <Fragment>
      {/* Render the anchor and add the reference from floating-ui to handle interactions. */}
      {cloneElement(
        child,
        getReferenceProps({
          ref: childRefWithFloatingUiRef,
          ...(child.props as AnyRecord),
        }),
      )}

      {/* Tooltip content. */}
      {isMounted && !!content ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className={clsx({ [styles.HAS_TITLE_CLASSNAME]: !!title, [styles.IS_INTERACTIVE_CLASSNAME]: interactive })}
            css={styles.tooltip}
            style={{
              ...floatingStyles,
              ...transitionStyles,
            }}
            {...getFloatingProps()}
          >
            {title ? (
              <Typography
                as="div"
                css={styles.title}
                variant={Typography.Variant.BODY_XSMALL_REGULAR}
              >
                {title}
              </Typography>
            ) : null}

            <Typography variant={Typography.Variant.BODY_XSMALL_MEDIUM}>{content}</Typography>

            <FloatingArrow
              ref={arrowRef}
              context={context}
              css={styles.arrow}
              tipRadius={1.5}
            />
          </div>
        </FloatingPortal>
      ) : null}
    </Fragment>
  );
});

export const Tooltip = memo(TooltipBase);
