import { IconHelpCircle } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo, type ReactNode, type ComponentPropsWithoutRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type Merge } from '@amal-ia/ext/typescript';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { Typography } from '../../../general/typography/Typography';
import { Tooltip } from '../../../overlays/tooltip/Tooltip';
import { FieldSize } from '../types';

import * as styles from './FormFieldLabel.styles';
import { formFieldLabelTestIds } from './FormFieldLabel.testIds';

const SIZE_TYPOGRAPHY_MAPPING: Record<FieldSize, TypographyVariant> = {
  [FieldSize.SMALL]: TypographyVariant.BODY_SMALL_MEDIUM,
  [FieldSize.MEDIUM]: TypographyVariant.BODY_BASE_MEDIUM,
  [FieldSize.LARGE]: TypographyVariant.BODY_LARGE_MEDIUM,
};

const SIZE_HELP_SIZE_MAPPING: Record<FieldSize, number> = {
  [FieldSize.SMALL]: 14,
  [FieldSize.MEDIUM]: 16,
  [FieldSize.LARGE]: 18,
};

export type FormFieldLabelProps = Merge<
  ComponentPropsWithoutRef<'div'>,
  {
    /** Help message. */
    tooltip?: ReactNode;
    /** Is the field disabled. */
    disabled?: boolean;
    /** Is the field required. */
    required?: boolean;
    /** Field size. */
    size?: FieldSize;
    /** Label. */
    children: ReactNode;
    /** Pass to the label. */
    htmlFor?: string;
  }
>;

export const FormFieldLabel = memo(function FormFieldLabel({
  htmlFor,
  disabled = false,
  required = false,
  size = FieldSize.MEDIUM,
  tooltip = null,
  children,
  ...props
}: FormFieldLabelProps) {
  const { formatMessage } = useIntl();

  return (
    <div
      {...props}
      className={clsx({ [styles.IS_DISABLED_CLASSNAME]: disabled })}
      css={styles.formFieldLabel}
    >
      <Typography
        as="label"
        htmlFor={htmlFor}
        variant={SIZE_TYPOGRAPHY_MAPPING[size]}
      >
        {children}
      </Typography>

      {!!required && (
        <Typography
          aria-label={formatMessage({ defaultMessage: 'Required' })}
          className={size}
          css={styles.required}
          variant={SIZE_TYPOGRAPHY_MAPPING[size]}
        >
          <FormattedMessage defaultMessage="*" />
        </Typography>
      )}

      {!!tooltip && (
        <Tooltip content={tooltip}>
          <IconHelpCircle
            className={size}
            color="currentColor"
            css={styles.help}
            data-testid={htmlFor ? formFieldLabelTestIds.tooltip(htmlFor) : undefined}
            size={SIZE_HELP_SIZE_MAPPING[size]}
          />
        </Tooltip>
      )}
    </div>
  );
});
