import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type CalculationDescriptorBatch } from '@amal-ia/lib-types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { MiniCardGroup } from '../cards/MiniCard/MiniCardGroup';

import { iconForCalculationStatus } from './calculationProgression.utils';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  batch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '3rem',
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  batchIcon: {
    height: '2rem',
    width: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
  batchDetails: {
    paddingLeft: '1rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '1rem',
    backgroundColor: theme.palette.common.white,
  },
  batchLine: {
    position: 'absolute',
    top: '50%',
    width: '1rem',
    left: '2rem',
    display: 'block',
    height: 1,
    backgroundColor: theme.palette.primary.main,
  },
  batchUsers: {
    display: 'flex',
  },
}));

interface CalculationProgressModalStepBatchProps {
  batch: CalculationDescriptorBatch;
}

export const CalculationProgressModalStepBatch = memo(function CalculationProgressModalStepBatch({
  batch,
}: CalculationProgressModalStepBatchProps) {
  const classes = useStyles();

  const icon = iconForCalculationStatus[batch.status];

  return (
    <Box className={classes.batch}>
      <Box className={classes.batchIcon}>{icon}</Box>
      <Box className={classes.batchLine} />
      <Box className={classes.batchDetails}>
        <Box className={classes.batchUsers}>
          <MiniCardGroup
            subTitle={batch.planName}
            users={batch.users as UserComputed[]}
          />
        </Box>
      </Box>
    </Box>
  );
});
