import { Table } from '@devexpress/dx-react-grid-material-ui';
import { memo, type ReactNode, useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatTotal } from '@amal-ia/lib-types';

import { LeaderboardTableContext } from './LeaderboardTableContext';
import { TeamChallenge } from './TeamChallenge';
import { UserChallenge } from './UserChallenge';

export const LeaderboardTableCell = memo(function LeaderboardTableCell(cellProps: any) {
  const { column, tableRow, value } = cellProps;

  const { company, variableFormat, usersMap, teamsMap, challenge, currentPeriod } = useContext(LeaderboardTableContext);

  const rowIdentifier = useMemo(() => {
    const { teamId, userId } = tableRow.row;
    const lineIdentifier = userId ? usersMap[userId]?.lastName : teamsMap[teamId]?.name;
    return `${lineIdentifier}-${tableRow.row.position || 'none'}`;
  }, [tableRow, usersMap, teamsMap]);

  let cellContent: ReactNode = '-';

  switch (column.name) {
    case 'comparisonValue':
      cellContent = formatTotal(value, variableFormat, company.currency);
      break;
    case 'userId': {
      cellContent = (
        <UserChallenge
          line={tableRow.row}
          period={currentPeriod}
          usersMap={usersMap}
        />
      );
      break;
    }
    case 'teamId': {
      cellContent = (
        <TeamChallenge
          challenge={challenge}
          line={tableRow.row}
          teamsMap={teamsMap}
          usersMap={usersMap}
        />
      );
      break;
    }
    case 'position': {
      cellContent = value || <FormattedMessage defaultMessage="Non eligible" />;
      break;
    }
    default:
      cellContent = value;
      break;
  }

  return (
    <Table.Cell
      data-testid={`${column.name} of ${rowIdentifier}`}
      name={`${rowIdentifier}.values[${column.name}]`}
      {...cellProps}
    >
      {cellContent}
    </Table.Cell>
  );
});
