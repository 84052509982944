import { type JSONSchema7 } from 'json-schema';
import { isArray } from 'lodash';

import { type Property, FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type Option } from '@amal-ia/lib-types';

/**
 * Extracts a usable format from a fieldSchema / custom object property
 *
 * @param fieldSchema The field schema definition
 *
 * @returns an object: the data format, and if the field is required or not
 */
export const getFormatFromFieldSchema = (
  fieldSchema: JSONSchema7 | undefined,
): {
  format: FormatsEnum;
  isRequired: boolean;
} => {
  let format: FormatsEnum = fieldSchema?.format as FormatsEnum;

  if (!format) {
    // Check if the field points to a currency definition
    if (fieldSchema?.$ref === '#/definitions/currency') {
      format = FormatsEnum.currency;
      // If the type is an array, take its first child
    } else if (fieldSchema && isArray(fieldSchema.type)) {
      format = fieldSchema.type[0] === 'string' ? FormatsEnum.text : (fieldSchema.type[0] as FormatsEnum);
    } else {
      // Otherwise it's already directly a format
      format = fieldSchema?.type === 'string' ? FormatsEnum.text : (fieldSchema?.type as FormatsEnum);
    }
  }

  return {
    format,
    isRequired:
      // value is required only if it cannot be null according to custom object property definition
      fieldSchema
        ? // If type is not an array, type is not null (it is another format). If type is an array, null can be present
          // so value is required only if null is present in the type array
          !isArray(fieldSchema.type) || !fieldSchema.type.includes('null')
        : false,
  };
};

export const getFieldAsOption = (definition: CustomObjectDefinition, fieldName: string) => {
  const property = definition.properties?.[fieldName];
  return property
    ? {
        label: property.name,
        name: property.machineName,
      }
    : null;
};

export const transformFieldToOption = (field: Property): Option => ({ value: field.machineName, label: field.name });
