import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { flowsRoutesMessages } from './flows.routes.messages';

const FlowFormContainer = lazy(() => import('./edit/FlowFormContainer'));
const FlowList = lazy(() => import('./list/FlowList'));

const FlowFormPage = function FlowFormPage() {
  return <FlowFormContainer isCreateMode />;
};

export const FlowRoutes = memo(function FlowRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="flow">
      <Switch>
        <CompanyRoute
          component={FlowFormPage}
          path={routes.FLOW_CREATE}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Flow)}
          title={formatMessage(flowsRoutesMessages.CREATE_FLOW)}
          user={user}
        />

        <CompanyRoute
          component={FlowFormContainer}
          path={routes.FLOW}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Flow)}
          title={formatMessage(flowsRoutesMessages.FLOW)}
          user={user}
        />

        <CompanyRoute
          component={FlowList}
          path={[routes.FLOWS, routes.ROOT]}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Flow)}
          title={formatMessage(flowsRoutesMessages.FLOWS)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
