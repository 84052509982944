import { Box, Dialog, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment/moment';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { BackgroundSparkles } from '@amal-ia/frontend/ui-icons';
import { notionFullTextToReact } from '@amal-ia/frontend/web-data-layers';
import { type ChangelogEntry } from '@amal-ia/lib-types';
import { DialogTitleWithCloseButton, Text, TextType, useOpenInNewTab } from '@amal-ia/lib-ui';

import ChangelogPills from './ChangelogPills';

export interface ChangelogModalProps {
  changelogEntries: ChangelogEntry[];
  forceOpen?: boolean;
  onClose?: () => unknown;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  dialogContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '0 !important',
  },
  screenshot: {
    borderLeft: `6px solid ${theme.palette.tertiary.main}`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  date: {
    color: theme.palette.grey['600'],
  },
  textContainer: {
    padding: '32px',
    paddingTop: 0,
    paddingBottom: '48px',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    marginTop: '16px',
  },
  title: {
    margin: `${theme.spacing(1)} 0`,
  },
}));

const ChangelogModal = memo(function ChangelogModal({ changelogEntries, forceOpen, onClose }: ChangelogModalProps) {
  const [dismissed, setDismissed] = useState(false);

  const classes = useStyles();
  const openInNewTab = useOpenInNewTab();

  const onDismiss = useCallback(() => {
    setDismissed(true);
    if (onClose) {
      onClose();
    }
  }, [setDismissed, onClose]);

  const goToUrl = useCallback(() => {
    onDismiss();
    if (changelogEntries[0].url) {
      openInNewTab(changelogEntries[0].url);
    }
  }, [onDismiss, openInNewTab, changelogEntries]);

  if (dismissed && !forceOpen) {
    return null;
  }

  const [changelogEntry] = changelogEntries;

  return (
    <Dialog
      fullWidth
      open
      maxWidth="md"
      onClose={onDismiss}
    >
      <DialogContent className={classes.dialogContent}>
        <div
          className={classes.screenshot}
          style={{ backgroundImage: `url('${changelogEntry.imageUrl}')` }}
        />
        <Box data-testid="changelog-modal-content">
          <DialogTitleWithCloseButton handleClose={onDismiss}>
            <Text
              className={classes.title}
              type={TextType.MODAL_TITLE}
            >
              {changelogEntry.name}
            </Text>
          </DialogTitleWithCloseButton>
          <Box className={classes.textContainer}>
            <Text
              className={classes.date}
              title={moment(changelogEntry.date).format('LL')}
              type={TextType.NORMAL_TEXT}
            >
              {moment(changelogEntry.date).fromNow()}
            </Text>

            <ChangelogPills changelogEntry={changelogEntry} />

            <p className={classes.content}>{notionFullTextToReact(changelogEntry.content)}</p>

            {changelogEntry.url ? (
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <BackgroundSparkles />
                <Button onClick={goToUrl}>
                  {changelogEntry.urlLabel ? (
                    notionFullTextToReact(changelogEntry.urlLabel)
                  ) : (
                    <FormattedMessage defaultMessage="Try it out!" />
                  )}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
});

export default ChangelogModal;
