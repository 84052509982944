import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

export const useRuleAccordionStyles = makeStyles((theme: AmaliaThemeType) => ({
  summaryTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  subAccordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  expanded: {
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(90deg)',
    },
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    transition: 'transform .5s',
  },
  nonPayoutRuleTitle: {
    color: theme.palette.grey[600],
  },
  accordionDetails: {
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: 'column',
  },
}));
