import { http } from '@amal-ia/frontend/kernel/http';
import {
  CustomReportsPresetsEnum,
  type PayoutAndPerformanceChartStatistics,
  type RelativePeriodKeyword,
  type Statistics,
} from '@amal-ia/lib-types';

const apiEndpoint = '/dashboards';

export const getWidget = async <ExpectedReturnType = Statistics>(
  reportId: CustomReportsPresetsEnum,
  periodId?: string,
  planIds?: string[],
  teamIds?: string[],
  userIds?: string[],
  year?: string,
  limit?: number,
): Promise<ExpectedReturnType> => {
  const { data } = await http.get<ExpectedReturnType>(`${apiEndpoint}/widget`, {
    params: {
      periodId,
      reportId,
      planIds: planIds || undefined,
      teamIds: teamIds || undefined,
      userIds: userIds || undefined,
      year,
      limit,
    },
  });

  return data;
};

const NB_MONTHS_TO_RETRIEVE = 12;

export const getOvertimeWidget = async (
  payoutMode: RelativePeriodKeyword.LAST_12_MONTHS | RelativePeriodKeyword.YEAR_TO_DATE,
  year?: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<PayoutAndPerformanceChartStatistics> =>
  getWidget<PayoutAndPerformanceChartStatistics>(
    CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME,
    payoutMode,
    planIds,
    teamIds,
    userIds,
    year,
    NB_MONTHS_TO_RETRIEVE,
  );

export const getOvertimeByPlanWidget = async (
  year: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<PayoutAndPerformanceChartStatistics> =>
  getWidget<PayoutAndPerformanceChartStatistics>(
    CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN,
    undefined,
    planIds,
    teamIds,
    userIds,
    year,
    NB_MONTHS_TO_RETRIEVE,
  );

export const getPlanKpisWidget = async (
  periodId: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> => getWidget(CustomReportsPresetsEnum.PRESET_PLAN_KPIS, periodId, planIds, teamIds, userIds);

export const getLeaderBoardWidget = async (
  periodId?: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> => getWidget(CustomReportsPresetsEnum.PRESET_LEADERBOARD, periodId, planIds, teamIds, userIds);

export const getBenchmarkByPlanWidget = async (
  periodId?: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> =>
  getWidget(CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_PLAN, periodId, planIds, teamIds, userIds);

export const getBenchmarkByRuleWidget = async (
  periodId?: string,
  userIds?: string[],
  planIds?: string[],
  teamIds?: string[],
): Promise<Statistics> =>
  getWidget(CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_RULE, periodId, planIds, teamIds, userIds);
