import { css } from '@emotion/react';
import { nth } from 'lodash';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { CardWithTabs } from '@amal-ia/frontend/design-system/components';
import {
  convertTimestampToDate,
  findRelativePeriodDate,
  formatDate,
  getDateRangeBoundaries,
  type PayoutAndPerformanceChartStatistics,
  type PayoutAndPerformanceChartStatisticsRecord,
  type Period,
} from '@amal-ia/lib-types';

import { findPayoutAndPerformanceChartStatisticsKpiColumns } from '../payout-and-performance-chart/kpiColumns.finder';

import { CardContent } from './card-with-kpis/CardContent';
import { CardTitle } from './card-with-kpis/CardTitle';
import { tabCardsKpisMessages } from './TabCardsKpis.messages';
import { useYtdRecord } from './useYtdRecord';

const findMonthRecord = (ytdStats: PayoutAndPerformanceChartStatistics, month: string) =>
  ytdStats?.records?.find((r) => r.ruleMetricPeriod__month === month);

type TabCardsKpisProps = Readonly<{
  ytdStats: PayoutAndPerformanceChartStatistics;
  currentPeriod: Period;
  userCurrency?: CurrencySymbolsEnum;
}>;
export const TabCardsKpis = memo(function TabCardsKpis({ ytdStats, currentPeriod, userCurrency }: TabCardsKpisProps) {
  const { formatMessage } = useIntl();

  const { currentPeriodShortLabel, currentPeriodLongLabel, previousPeriodShortLabel, previousPeriodLongLabel } =
    useMemo(() => {
      if (!currentPeriod)
        return {
          currentPeriodDate: undefined,
          previousPeriodDate: undefined,
        };

      const boundaries = getDateRangeBoundaries(
        currentPeriod.frequency,
        convertTimestampToDate(currentPeriod?.startDate),
      );

      const currentPeriodDate = convertTimestampToDate(boundaries.endDate);
      const previousPeriodDate = findRelativePeriodDate(currentPeriod, currentPeriod.frequency, -1, 'endDate');

      const currentPeriodLongLabel =
        currentPeriod.frequency === PeriodFrequencyEnum.quarter
          ? formatDate(currentPeriodDate, '[Q]Q YYYY')
          : formatDate(currentPeriodDate, 'MMMM YYYY');
      const previousPeriodLongLabel =
        currentPeriod.frequency === PeriodFrequencyEnum.quarter
          ? formatDate(previousPeriodDate, '[Q]Q YYYY')
          : formatDate(previousPeriodDate, 'MMMM YYYY');

      return {
        currentPeriodShortLabel: formatDate(currentPeriodDate, 'MM/YYYY'),
        currentPeriodLongLabel,
        previousPeriodShortLabel: formatDate(previousPeriodDate, 'MM/YYYY'),
        previousPeriodLongLabel,
      };
    }, [currentPeriod]);

  const currentPeriodRecord = useMemo(
    () => findMonthRecord(ytdStats, currentPeriodShortLabel),
    [currentPeriodShortLabel, ytdStats],
  );
  const previousPeriodRecord = useMemo(
    () => findMonthRecord(ytdStats, previousPeriodShortLabel),
    [previousPeriodShortLabel, ytdStats],
  );

  const [kpi1, kpi2] = useMemo(() => {
    const kpis = findPayoutAndPerformanceChartStatisticsKpiColumns(ytdStats);

    return [nth(kpis, 0), nth(kpis, 1)];
  }, [ytdStats]);

  const ytdAverageRecord: PayoutAndPerformanceChartStatisticsRecord = useYtdRecord(
    ytdStats,
    currentPeriodShortLabel,
    kpi1,
    kpi2,
  );

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <CardWithTabs defaultTab="current">
        <CardWithTabs.Tab
          value="previous"
          title={
            <CardTitle
              record={previousPeriodRecord}
              subTitle={previousPeriodLongLabel}
              userCurrency={userCurrency}
            />
          }
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={previousPeriodRecord}
          />
        </CardWithTabs.Tab>
        <CardWithTabs.Tab
          value="current"
          title={
            <CardTitle
              record={currentPeriodRecord}
              subTitle={currentPeriodLongLabel}
              userCurrency={userCurrency}
            />
          }
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={currentPeriodRecord}
          />
        </CardWithTabs.Tab>

        <CardWithTabs.Tab
          value="next"
          title={
            <CardTitle
              record={ytdAverageRecord}
              subTitle={formatMessage(tabCardsKpisMessages.YEAR_TO_DATE)}
              userCurrency={userCurrency}
            />
          }
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={ytdAverageRecord}
          />
        </CardWithTabs.Tab>
      </CardWithTabs>
    </div>
  );
});
