import { type Theme, css } from '@emotion/react';

import { QuickEditVariant } from './QuickEdit.types';

export const HAS_ERROR_CLASSNAME = 'has-error';

export const quickEdit = css`
  display: flex;
  align-items: stretch;

  &.${QuickEditVariant.HEADING_1_MEDIUM},
    &.${QuickEditVariant.HEADING_1_BOLD},
    &.${QuickEditVariant.HEADING_2_MEDIUM},
    &.${QuickEditVariant.HEADING_2_BOLD},
    &.${QuickEditVariant.HEADING_3_MEDIUM},
    &.${QuickEditVariant.HEADING_3_BOLD},
    &.${QuickEditVariant.HEADING_4_MEDIUM},
    &.${QuickEditVariant.HEADING_4_BOLD} {
    gap: 16px;
  }

  &.${QuickEditVariant.BODY_LARGE_MEDIUM},
    &.${QuickEditVariant.BODY_LARGE_BOLD},
    &.${QuickEditVariant.BODY_BASE_MEDIUM},
    &.${QuickEditVariant.BODY_BASE_BOLD},
    &.${QuickEditVariant.BODY_SMALL_MEDIUM},
    &.${QuickEditVariant.BODY_SMALL_BOLD},
    &.${QuickEditVariant.BODY_XSMALL_MEDIUM},
    &.${QuickEditVariant.BODY_XSMALL_BOLD} {
    gap: 12px;
  }

  > * {
    flex: none;
  }
`;

export const quickEditInput = (width?: number) => (theme: Theme) => css`
  border: none;
  border-radius: ${theme.ds.borderRadiuses.squared};
  min-width: 0;
  max-width: 100%;

  transition: ${theme.ds.transitions.default('box-shadow')};

  .${QuickEditVariant.HEADING_1_MEDIUM} &,
  .${QuickEditVariant.HEADING_1_BOLD} &,
  .${QuickEditVariant.HEADING_2_MEDIUM} &,
  .${QuickEditVariant.HEADING_2_BOLD} &,
  .${QuickEditVariant.HEADING_3_MEDIUM} &,
  .${QuickEditVariant.HEADING_3_BOLD} &,
  .${QuickEditVariant.HEADING_4_MEDIUM} &,
  .${QuickEditVariant.HEADING_4_BOLD} & {
    padding: 0 12px;
    /* Set width to content size + padding + 20px to be safe. */
    width: ${width ? `${Math.ceil(width) + 44}px` : 'auto'};

    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.secondary[50])};
    }
  }

  .${QuickEditVariant.BODY_LARGE_MEDIUM} &,
  .${QuickEditVariant.BODY_LARGE_BOLD} &,
  .${QuickEditVariant.BODY_BASE_MEDIUM} &,
  .${QuickEditVariant.BODY_BASE_BOLD} &,
  .${QuickEditVariant.BODY_SMALL_MEDIUM} &,
  .${QuickEditVariant.BODY_SMALL_BOLD} &,
  .${QuickEditVariant.BODY_XSMALL_MEDIUM} &,
  .${QuickEditVariant.BODY_XSMALL_BOLD} & {
    padding: 0 8px;
    /* Set width to content size + padding + 20px to be safe. */
    width: ${width ? `${Math.ceil(width) + 36}px` : 'auto'};

    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};
    }
  }

  .${QuickEditVariant.HEADING_1_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.HEADING_1_BOLD}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.HEADING_2_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.HEADING_2_BOLD}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.HEADING_3_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.HEADING_3_BOLD}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.HEADING_4_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.HEADING_4_BOLD}.${HAS_ERROR_CLASSNAME} & {
    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusMedium(theme.ds.colors.danger[100])};
    }
  }

  .${QuickEditVariant.BODY_LARGE_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.BODY_LARGE_BOLD}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.BODY_BASE_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.BODY_BASE_BOLD}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.BODY_SMALL_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.BODY_SMALL_BOLD}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.BODY_XSMALL_MEDIUM}.${HAS_ERROR_CLASSNAME} &,
  .${QuickEditVariant.BODY_XSMALL_BOLD}.${HAS_ERROR_CLASSNAME} & {
    &:focus-visible {
      box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.danger[100])};
    }
  }

  &:focus-visible {
    outline-offset: -1px;
    outline: 1px solid ${theme.ds.colors.secondary[200]};

    .${HAS_ERROR_CLASSNAME} & {
      outline-color: ${theme.ds.colors.danger[500]};
    }
  }
`;

export const quickEditReadonly = css`
  align-self: center;
`;

export const indicatorsContainer = css`
  display: flex;
  align-items: center;
  flex: none;

  .${QuickEditVariant.HEADING_1_MEDIUM} &,
  .${QuickEditVariant.HEADING_1_BOLD} &,
  .${QuickEditVariant.HEADING_2_MEDIUM} &,
  .${QuickEditVariant.HEADING_2_BOLD} &,
  .${QuickEditVariant.HEADING_3_MEDIUM} &,
  .${QuickEditVariant.HEADING_3_BOLD} &,
  .${QuickEditVariant.HEADING_4_MEDIUM} &,
  .${QuickEditVariant.HEADING_4_BOLD} & {
    gap: 8px;
  }

  .${QuickEditVariant.BODY_LARGE_MEDIUM} &,
  .${QuickEditVariant.BODY_LARGE_BOLD} &,
  .${QuickEditVariant.BODY_BASE_MEDIUM} &,
  .${QuickEditVariant.BODY_BASE_BOLD} &,
  .${QuickEditVariant.BODY_SMALL_MEDIUM} &,
  .${QuickEditVariant.BODY_SMALL_BOLD} &,
  .${QuickEditVariant.BODY_XSMALL_MEDIUM} &,
  .${QuickEditVariant.BODY_XSMALL_BOLD} & {
    gap: 6px;
  }
`;

export const offscreen = css`
  position: fixed;
  left: -9999px;
  top: -9999px;
`;
