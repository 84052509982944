import { type CompanyFeatureFlags } from '@amal-ia/tenants/shared/types';

import { useCurrentCompany } from './useCurrentCompany';

/**
 * This hook is used to check if a feature flag is enabled or not for the current company.
 *
 * @example
 *
 * Usage:
 *
 * ```tsx
 *  const { isFeatureEnabled } = useFeatureFlag(CompanyFeature.BADGES);
 * ```
 *
 * This will return `{ isFeatureEnabled: true }` if the feature flag BADGES is enabled for the company and `{ isFeatureEnabled: false }` otherwise.
 */
export const useFeatureFlag = (featureFlag: CompanyFeatureFlags) => {
  const isFeatureEnabled = !!useCurrentCompany()?.company?.featureFlags?.[featureFlag];

  return {
    isFeatureEnabled,
  };
};
