import { createContext } from 'react';

import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';

export interface OverwritesTableContextInterface {
  customObjectDefinitionsById: Record<string, CustomObjectDefinition>;
}

const OverwritesTableContext = createContext<OverwritesTableContextInterface>({} as OverwritesTableContextInterface);

export default OverwritesTableContext;
