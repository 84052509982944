import { chunk as lodashChunk, isNil } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Typed by the generics.
export const ensureArray = <T>(arr: T | T[]): T[] => (arr ? [].concat(arr) : []);

/**
 * Return true if one of the input array is not null and empty.
 * @param inputFilters
 */
export const isOneEmptyFilter = <T>(...inputFilters: T[][]): boolean =>
  inputFilters.some((filter) => !isNil(filter) && filter.length === 0);

/**
 * Async filter permits to filter an array using an async predicate function
 * @param arr The array of data
 * @param predicate The predicate function to run over arr items
 * @returns A filtered array
 */
export const asyncFilter = async <T>(arr: T[], predicate: (entity: T) => Promise<boolean>): Promise<T[]> => {
  const predicates = await Promise.all((arr || []).map(predicate));
  return (arr || []).filter((_, idx) => predicates[idx]);
};

/**
 * Allows the chunk of Promises, mostly to avoid blasting 4000 queries to the
 * database in a big-ass Promise.all.
 *
 * Example of usage:
 *
 * const values = await promiseDotAllChunk(
 *   ids,
 *   id => fetchStuffInDb(id, 'more', 'parameters'), // With an async function in the callback.
 *   2,
 * );
 *
 * @param arr
 * @param callback
 * @param chunkSize
 */
export const promiseDotAllChunk = async <I, O>(
  arr: I[],
  callback: (row: I, index: number) => Promise<O>,
  chunkSize: number,
): Promise<O[]> => {
  const chunks = lodashChunk(arr, chunkSize);

  const resolutions: O[] = [];

  // For each chunk, execute the callback, then accumulate its value.
  for (const chunk of chunks) {
    const resultsOfChunk = await Promise.all(chunk.map((row, index) => callback(row, index + resolutions.length)));

    resolutions.push(...resultsOfChunk);
  }

  return resolutions;
};

/**
 * Given an array and a predicate, ensure that elements that matches the predicate
 * are put at the top of the array.
 *
 * @param array
 * @param predicate
 */
export const putOnTop = <T>(array: T[], predicate: (elt: T) => boolean) =>
  [...array].sort((a) => (predicate(a) ? -1 : 1));

export const calculateNewIndicesAfterMovingElementsInArray = (
  fromIndex: number,
  toIndex: number,
  currentIndex: number,
) => {
  // Edge case: not moving.
  if (fromIndex === toIndex) {
    return currentIndex;
  }

  // Current element: let's put it at the end position.
  if (currentIndex === fromIndex) {
    return toIndex;
  }

  // Diff depends on if we're moving up or down.
  const diff = fromIndex < toIndex ? -1 : +1;

  // Out of boundaries moving down: no impact.
  if (diff < 0 && (currentIndex < fromIndex || currentIndex > toIndex)) {
    return currentIndex;
  }

  // Out of boundaries moving up: no impact.
  if (diff > 0 && (currentIndex < toIndex || currentIndex > fromIndex)) {
    return currentIndex;
  }

  // Applying diff on moved elements.
  return currentIndex + diff;
};

export const sortByIndex = <T extends { index: number | undefined }>(array: T[]): T[] =>
  [...array].sort((a, b) => (a.index || 0) - (b.index || 0));

export const moveElementInArray = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  // Cloning the array.
  const newArray = [...array];
  // Keeping a copy of the element.
  const element = newArray[fromIndex];
  // Removing old item.
  newArray.splice(fromIndex, 1);
  // Putting it back on new index.
  newArray.splice(toIndex, 0, element);

  return newArray;
};

/**
 * Immutable replace in an array.
 */
export const replaceAt = <T>(array: T[], index: number, value: T) => {
  // Creating shallow copy.
  const ret = array.slice(0);
  // Replacing the element.
  ret[index] = value;
  // Return the new array.
  return ret;
};

/**
 * Immutable delete in array.
 */
export const deleteAt = <T>(array: T[], index: number) => {
  // Creating shallow copy.
  const ret = array.slice(0);
  // Deleting element.
  ret.splice(index, 1);
  // Return the new array.
  return ret;
};

export const formatStringArrayWithLimit = (arr: string[], limit: number): string => {
  const extract = arr.slice(0, limit).join(', ');
  return arr.length <= limit ? extract : `${extract} (+${arr.length - limit})`;
};
