import { Box } from '@mui/material';
import { memo } from 'react';

import { type Filter } from '@amal-ia/compensation-definition/shared/types';
import { type PlansMap, type Rule, type Variable } from '@amal-ia/lib-types';
import { Text, TextType } from '@amal-ia/lib-ui';

interface DesignerItemWithContextProps {
  object?: Filter | Rule | Variable;
  nameAccessor?: string;
  planIdAccessor?: string;
  nameOverride?: string;
  planNameOverride?: string;
  plansMap: PlansMap;
}

export const DesignerItemWithContext = memo(function DesignerItemWithContext({
  object,
  nameAccessor,
  planIdAccessor,
  nameOverride,
  planNameOverride,
  plansMap,
}: DesignerItemWithContextProps) {
  const planId = object?.[(planIdAccessor as keyof typeof object) || 'planId'];
  const planName = planNameOverride || (planId && plansMap?.[planId]?.name);

  return (
    <Box
      display="inline-flex"
      flex="1"
      flexDirection="column"
    >
      <Box>
        <Text type={TextType.DESIGNER_OBJECT}>
          {nameOverride || object?.[(nameAccessor as keyof typeof object) || 'name']}
        </Text>
      </Box>
      {!!planName && (
        <Text
          style={{ opacity: 0.8 }}
          type={TextType.STEPPER_ITEM}
        >
          {planName}
        </Text>
      )}
    </Box>
  );
});
