import { type Company } from '@amal-ia/tenants/shared/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export enum TodoType {
  THREAD = 'THREAD',
  WORKFLOW = 'WORKFLOW',
}

export interface TodoMetadata {
  threadId?: string;
  statementId?: string;
  statementThreadId?: string;
  firstThreadMessage?: string;
}

export interface TodoDefinition {
  id?: string;
  company?: Company;
  assignedTo: UserContract[];
  type: TodoType;
  createdBy: UserContract;
  createdAt: Date;
  updatedBy?: UserContract;
  updatedAt?: Date;
  metadata: TodoMetadata;
  richText: string;
  reviewed: boolean;
}
