import { Dialog, DialogActions, DialogContent, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { keyBy } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, type DropResult } from 'react-beautiful-dnd';

import {
  type FiltersMap,
  HidableElementVisibility,
  type PlanRule,
  type PlanRuleFieldToDisplay,
  type PlanRuleFilterToDisplay,
} from '@amal-ia/compensation-definition/shared/types';
import { type CustomObjectDefinitionsMap } from '@amal-ia/data-capture/models/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { moveElementInArray, type PlansMap, type Variable, VariableObjectsEnum } from '@amal-ia/lib-types';
import { DialogTitleWithCloseButton, List, SearchInput, SelectFieldBase } from '@amal-ia/lib-ui';

import { DesignerInputWithRedirect } from '../../../DesignerRedirect/DesignerInputWithRedirect';

import { PlanRuleSelectFieldsModalListElement } from './PlanRuleSelectFieldsModalListElement';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(2),
    height: '80vh',
  },
  searchField: {
    marginBottom: theme.spacing(2),
  },
  list: {
    height: '100%',
  },
  divider: {
    margin: `${theme.spacing(2)} 0`,
  },
  column: {
    height: '100%',
  },
}));

enum COLUMNS {
  SELECTED = 'SELECTED',
  NOT_SELECTED = 'NOT_SELECTED',
}

interface PlanRuleSelectFieldsProps {
  filtersMap: FiltersMap;
  availableVariables: Variable[];
  plansMap: PlansMap;
  customObjectDefinitionsMap: CustomObjectDefinitionsMap;
  selectedRuleAssignment: PlanRule | null;
  onPatchRules: (rulesToPatch: PlanRule[]) => any;
  onCancel: () => any;
}

export const PlanRuleSelectFieldsModal = memo(function PlanRuleSelectFieldsModal({
  plansMap,
  filtersMap,
  availableVariables,
  customObjectDefinitionsMap,
  selectedRuleAssignment,
  onPatchRules,
  onCancel,
}: PlanRuleSelectFieldsProps) {
  const classes = useStyles();

  const [selectedFilterId, setSelectedFilterId] = useState<string>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFieldsToDisplay, setSelectedFieldsToDisplay] = useState<PlanRuleFieldToDisplay[]>([]);

  useEffect(() => {
    // On selecting a ruleAssignment, setup form values according to what was persisted.
    // On deselecting the ruleAssignment, reset form values.
    const filterToDisplay = selectedRuleAssignment?.filtersToDisplay?.[0];
    setSelectedFilterId(filterToDisplay?.id || '');
    setSelectedFieldsToDisplay(filterToDisplay?.fieldsToDisplay || []);
  }, [selectedRuleAssignment]);

  const onChangeFilter = useCallback(
    (event: any) => {
      setSelectedFilterId(event.target.value);
    },
    [setSelectedFilterId],
  );

  const filterArray = useMemo(() => Object.values(filtersMap), [filtersMap]);

  const filterOptions = useMemo(
    () =>
      filterArray
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((f1, f2) => f1.label.localeCompare(f2.label)),
    [filterArray],
  );

  const onDragEnd = useCallback(
    ({ draggableId, source, destination }: DropResult) => {
      // Moving from unselected to unselected: do nothing.
      if (source.droppableId === COLUMNS.NOT_SELECTED && destination?.droppableId === COLUMNS.NOT_SELECTED) {
        return;
      }

      // Moving from selected to unselected: remove element from selected fields.
      if (source.droppableId === COLUMNS.SELECTED && destination?.droppableId === COLUMNS.NOT_SELECTED) {
        setSelectedFieldsToDisplay((prev) => prev.filter((elt) => elt.name !== draggableId));
      }

      // Moving from unselected to selected: add field into selected.
      if (source.droppableId === COLUMNS.NOT_SELECTED && destination?.droppableId === COLUMNS.SELECTED) {
        const newArray = [...selectedFieldsToDisplay];
        newArray.splice(destination?.index, 0, {
          name: draggableId,
          displayStatus: HidableElementVisibility.ON_DISPLAY,
        });
        setSelectedFieldsToDisplay(newArray);
      }

      // Moving from selected to selected: reorder elements.
      if (source.droppableId === COLUMNS.SELECTED && destination?.droppableId === COLUMNS.SELECTED) {
        setSelectedFieldsToDisplay(moveElementInArray(selectedFieldsToDisplay, source.index, destination.index));
      }
    },
    [setSelectedFieldsToDisplay, selectedFieldsToDisplay],
  );

  const onChangeDisplayStatus = useCallback(
    (name: string) => {
      setSelectedFieldsToDisplay(
        selectedFieldsToDisplay.map((field) => {
          // Not the clicked element: return as is.
          if (field.name !== name) {
            return field;
          }

          // Else, invert the status.
          return {
            ...field,
            displayStatus:
              field.displayStatus === HidableElementVisibility.ON_DISPLAY
                ? HidableElementVisibility.AVAILABLE
                : HidableElementVisibility.ON_DISPLAY,
          };
        }),
      );
    },
    [selectedFieldsToDisplay, setSelectedFieldsToDisplay],
  );

  const onSubmit = useCallback(() => {
    if (selectedRuleAssignment) {
      const filtersToDisplay: PlanRuleFilterToDisplay[] = selectedFilterId
        ? // If we have a filter to display, put the config in the rule.
          [
            {
              id: selectedFilterId,
              displayStatus: HidableElementVisibility.ON_DISPLAY,
              fieldsToDisplay: selectedFieldsToDisplay,
            },
          ]
        : // If no filter selected, also remove fields configuration so it'll reset to default.
          [];

      const newRule: PlanRule = { ...selectedRuleAssignment, filtersToDisplay };

      onPatchRules([newRule]);
    }
  }, [onPatchRules, selectedFieldsToDisplay, selectedFilterId, selectedRuleAssignment]);

  const allFieldsAvailable = useMemo(() => {
    if (selectedFilterId) {
      const filter = filtersMap[selectedFilterId];

      const customObjectDefinitionMachineName = filter?.object?.machineName || filter?.virtualObjectMachineName;

      if (!customObjectDefinitionMachineName) {
        return {};
      }

      const customObjectDefinition = customObjectDefinitionsMap[customObjectDefinitionMachineName];

      if (!customObjectDefinition) {
        return {};
      }

      const allFieldsArray: { label: string; name: string; contextPlanName?: string }[] = [
        // Get all properties in definition.
        ...Object.values(customObjectDefinition.properties).map((property) => ({
          name: property.machineName,
          label: property.name,
        })),
        // Get all object variables that are link to the object of the filter.
        ...availableVariables
          .filter(
            (variable) =>
              variable.type === VariableObjectsEnum.object &&
              variable.object?.machineName === customObjectDefinitionMachineName,
          )
          .map((variable) => ({
            name: variable.machineName,
            label: `${variable.name} (var)`,
            // Populate this if variable is scoped
            contextPlanName: variable.planId ? plansMap[variable.planId]?.name : undefined,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ];

      // Reindex them by name.
      return keyBy(allFieldsArray, 'name');
    }

    return {};
  }, [filtersMap, availableVariables, plansMap, selectedFilterId, customObjectDefinitionsMap]);

  const notSelectedFields = useMemo(() => {
    const selectedFieldsId = selectedFieldsToDisplay.map((f) => f.name);
    return Object.values(allFieldsAvailable)
      .filter(
        (fieldAvailable) =>
          !selectedFieldsId.includes(fieldAvailable.name) &&
          (searchTerm ? fieldAvailable.label.toLowerCase().includes(searchTerm.toLowerCase()) : true),
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [selectedFieldsToDisplay, allFieldsAvailable, searchTerm]);

  const selectedFields = useMemo(
    () =>
      selectedFieldsToDisplay.map((field) => {
        const availableField = allFieldsAvailable[field.name];
        return {
          ...field,
          label: availableField?.label,
          contextPlanName: availableField?.contextPlanName,
        };
      }),
    [selectedFieldsToDisplay, allFieldsAvailable],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={!!selectedRuleAssignment}
      onClose={onCancel}
    >
      <DialogTitleWithCloseButton handleClose={onCancel}>
        Configure fields on display for rule {selectedRuleAssignment?.name}
      </DialogTitleWithCloseButton>
      <DialogContent dividers>
        <DesignerInputWithRedirect
          filters={filterArray}
          value={selectedFilterId}
        >
          <SelectFieldBase
            hasResetOption
            label="Filter"
            options={filterOptions}
            value={selectedFilterId}
            onChange={onChangeFilter}
          />
        </DesignerInputWithRedirect>
        {selectedFilterId ? (
          <React.Fragment>
            <Divider className={classes.divider} />
            <DragDropContext onDragEnd={onDragEnd}>
              <div className={classes.container}>
                <div className={classes.column}>
                  <p>All fields</p>
                  <SearchInput
                    forceSearchVisible
                    showBorder
                    className={classes.searchField}
                    value={searchTerm}
                    onChange={setSearchTerm}
                  />
                  <Droppable droppableId={COLUMNS.NOT_SELECTED}>
                    {(droppableProvided) => (
                      <List
                        {...droppableProvided.droppableProps}
                        className={classes.list}
                        innerRef={droppableProvided.innerRef}
                      >
                        {notSelectedFields.map(({ name, label, contextPlanName }, index) => (
                          <Draggable
                            key={name}
                            draggableId={name}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <PlanRuleSelectFieldsModalListElement
                                contextPlanName={contextPlanName}
                                dndProvidedProps={draggableProvided}
                                label={label}
                                name={name}
                                plansMap={plansMap}
                              />
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </div>
                <div className={classes.column}>
                  <p>Available fields on rule</p>
                  <Droppable droppableId={COLUMNS.SELECTED}>
                    {(droppableProvided) => (
                      <List
                        {...droppableProvided.droppableProps}
                        className={classes.list}
                        innerRef={droppableProvided.innerRef}
                      >
                        {selectedFields.map(({ name, displayStatus, label, contextPlanName }, index) => (
                          <Draggable
                            key={name}
                            draggableId={name}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <PlanRuleSelectFieldsModalListElement
                                key={name}
                                contextPlanName={contextPlanName}
                                displayStatus={displayStatus}
                                dndProvidedProps={draggableProvided}
                                label={label}
                                name={name}
                                plansMap={plansMap}
                                onChangeDisplayStatus={onChangeDisplayStatus}
                              />
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </div>
              </div>
            </DragDropContext>
          </React.Fragment>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant={Button.Variant.LIGHT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-testid="apply-rules-btn"
          onClick={onSubmit}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
});
