import { IconPencil, IconTrash } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { LabelDisplay, LabelVariant, UserPrettyFormat } from '@amal-ia/data-capture/fields/components';
import { IconButton, Typography } from '@amal-ia/frontend/design-system/components';
import { type CommentThreadMessage, MessageTypeEnum } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import * as styles from './CommentMessage.styles';

type CommentMessageProps = {
  /** Author of the message, could be undefined if we didn't manage to get user's infos */
  user?: UserContract;
  /** The message to display */
  message: CommentThreadMessage;
};

// Shows a specific comment from a thread
export const CommentMessage = memo(function CommentMessage({ user, message }: CommentMessageProps) {
  if (!user) {
    return null;
  }

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <UserPrettyFormat
          display={LabelDisplay.INLINE}
          firstName={user.firstName}
          lastName={user.lastName}
          pictureURL={user.pictureURL}
          variant={LabelVariant.BOLD}
        />
        <div css={styles.actions}>
          <IconButton
            icon={<IconPencil />}
            label={<FormattedMessage defaultMessage="Edit comment" />}
            size={IconButton.Size.SMALL}
          />
          <IconButton
            icon={<IconTrash />}
            label={<FormattedMessage defaultMessage="Delete comment" />}
            size={IconButton.Size.SMALL}
          />
        </div>
      </div>
      <div css={styles.content}>
        {message.content.map(({ content, type }) =>
          type === MessageTypeEnum.mention ? (
            <Typography
              key={content}
              css={styles.mention}
              variant={Typography.Variant.BODY_BASE_REGULAR}
            >
              <FormattedMessage
                defaultMessage="@{mention}"
                values={{ mention: content }}
              />
            </Typography>
          ) : (
            <Typography
              key={content}
              variant={Typography.Variant.BODY_BASE_REGULAR}
            >
              {content}
            </Typography>
          ),
        )}
      </div>
      <Typography
        css={styles.date}
        variant={Typography.Variant.BODY_XSMALL_REGULAR}
      >
        <FormattedDate
          day="numeric"
          hour="numeric"
          minute="numeric"
          month="short"
          value={message.createdAt}
          weekday="short"
        />
      </Typography>
    </div>
  );
});
