import invariant from 'invariant';
import { createContext, useContext } from 'react';

import { type WorkflowDefinition, type Plan } from '@amal-ia/lib-types';

export interface WorkflowListContextInterface {
  actions: {
    onClickEdit: (workflow: WorkflowDefinition) => void;
    onClickDelete: (workflowId: string) => void;
    onClickDuplicate: (workflow: WorkflowDefinition) => void;
  };
  isWorkflowToDelete?: { rowId: string; name: string };
  setWorkflowToDelete?: (value: { rowId: string; name: string } | null) => void;
  areStatementsBeingReview?: boolean;
  selectedWorkflowPlans?: Plan[];
  isLoadingStatementsUnderReview?: boolean;
}

const WorkflowListContext = createContext<WorkflowListContextInterface | null>(null);

export const WorkflowListProvider = WorkflowListContext.Provider;

export const useWorkflowListContext = () => {
  const context = useContext(WorkflowListContext);
  invariant(context, 'useWorkflowListContext must be used within a WorkflowListContext');
  return context;
};
