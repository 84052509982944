import { type ReactNode } from 'react';

import { type TablerIconElement } from '../../general/icons/types';

export type TabKey = string;

export type VerticalTabsMenuTabShape<TKey extends TabKey, TEditable extends boolean = false> = (TEditable extends true
  ? {
      label: string;
      placeholder?: string;
    }
  : {
      label: ReactNode;
      placeholder?: never;
    }) & {
  key: TKey;
  disabled?: boolean;
  isEditable?: TEditable;
  icon?: TablerIconElement;
};

export type VerticalTabsMenuGroup<TKey extends TabKey> = {
  label?: ReactNode;
  // Tabs can be a mix of editable and non-editable tabs.
  tabs: (VerticalTabsMenuTabShape<TKey, false> | VerticalTabsMenuTabShape<TKey, true>)[];
};

export const isTabGroups = <TKey extends TabKey>(
  tabs: VerticalTabsMenuGroup<TKey>['tabs'] | VerticalTabsMenuGroup<TKey>[],
): tabs is VerticalTabsMenuGroup<TKey>[] => !!tabs.length && 'tabs' in tabs[0];
