import invariant from 'invariant';
import { createContext, useContext } from 'react';

import {
  type ObjectsToDisplay,
  type Statement,
  type StatementError,
  type WorkflowStatementState,
} from '@amal-ia/lib-types';

export interface StatementDetailContextInterface extends Statement {
  launchCalculation: () => Promise<void>;
  stopCalculation: () => Promise<void>;
  calculationProgress: string;
  isCalculationRunning: boolean;
  isForecastedView: boolean;
  objectsToDisplay?: Record<string, ObjectsToDisplay>;
  statementId: string;
  statementError?: StatementError;
  workflowComplete: boolean;
  workflowSteps?: WorkflowStatementState[];
  lastCalculationDate: Date;
  // When we are in forecast we need to have the orginal statement to show the diff
  originalStatement?: Statement;
  userId: string;
}

const StatementContext = createContext<StatementDetailContextInterface | null>(null);

export const StatementDetailContextProvider = StatementContext.Provider;

export const useStatementDetailContext = () => {
  const context = useContext(StatementContext);
  invariant(context, 'useStatementDetailContext must be used within a StatementDetailContext');
  return context;
};
