import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { Button } from '@amal-ia/frontend/design-system/components';
import { type Plan, type PlansMap, type Rule } from '@amal-ia/lib-types';
import { DialogTitleWithCloseButton, type Option, SelectFieldBase } from '@amal-ia/lib-ui';

import { DesignerItemWithContext } from '../../DesignerItemWithContext/DesignerItemWithContext';

interface PlanDetailAddRuleProps {
  plan: Plan;
  plansMap: PlansMap;
  isOpen: boolean;
  onAddRules: (rules: Partial<PlanRule>[]) => any;
  onCancel: () => any;
  isLoading: boolean;
  rules: Rule[];
}

export const PlanDetailAddRuleModal = memo(function PlanDetailAddRuleModal({
  plan,
  plansMap,
  isOpen,
  onAddRules,
  onCancel,
  isLoading,
  rules,
}: PlanDetailAddRuleProps) {
  const [rulesToAdd, setRulesToAdd] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      // Reset form value at each opening.
      setRulesToAdd([]);
    }
  }, [isOpen]);

  const options: Option[] = useMemo(() => {
    const alreadyAffectedRulesId = (plan?.rules || []).map((r) => r.id);
    const activePlans = Object.entries(plansMap).map(([key, value]) => (value.archived ? null : key));

    return (rules || [])
      .filter(
        (rule) => !alreadyAffectedRulesId.includes(rule.id) && (activePlans.includes(rule?.planId) || !rule?.planId),
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((rule) => ({
        value: rule.id,
        label: (
          <DesignerItemWithContext
            object={rule}
            plansMap={plansMap}
          />
        ),
        searchString: rule.name,
      }));
  }, [plan, rules, plansMap]);

  const onSelectRule = useCallback(
    (event: any) => {
      setRulesToAdd(event.target.value);
    },
    [setRulesToAdd],
  );

  const onAddRulesProxy = useCallback(() => {
    const ruleAssignments: Partial<PlanRule>[] = rulesToAdd.map((ruleId) => ({
      fieldsToDisplay: null,
      filterToDisplayId: null,
      categories: [],
      id: ruleId,
    }));
    onAddRules(ruleAssignments);
  }, [rulesToAdd, onAddRules]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitleWithCloseButton handleClose={onCancel}>Add rule to plan {plan?.name}</DialogTitleWithCloseButton>
      <DialogContent dividers>
        {plan && !isLoading ? (
          <SelectFieldBase
            isSearchable
            multiple
            searchWithAutocompletion
            dir="top"
            id="add-rules-to-plan-select"
            options={options}
            value={rulesToAdd}
            onChange={onSelectRule}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant={Button.Variant.LIGHT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-testid="apply-rules-btn"
          onClick={onAddRulesProxy}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
});
