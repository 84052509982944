import { http } from '@amal-ia/frontend/kernel/http';
import { type Company, type CompanyIntegration } from '@amal-ia/tenants/shared/types';

export async function getCompany(): Promise<Company> {
  const { data } = await http.get('/companies');
  return data;
}

export async function updateCompany(companyToUpdate: Company): Promise<Company> {
  const { data } = await http.put('/companies', companyToUpdate);
  return data;
}

// UPDATE INTEGRATIONS
export async function updateIntegration(integrationToUpdate: Partial<CompanyIntegration>): Promise<void> {
  await http.put('/companies/integration', integrationToUpdate);
}
