import { lapin2, generateFakeList } from '@amal-ia/frontend/kernel/testing/data';
import { type Plan } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export const designerMockUsers = generateFakeList<UserContract>(
  {
    id: 'm',
    firstName: 'Michel',
    lastName: 'Manager',
    email: 'michel@manager.com',
  },
  10,
  { pictureURL: lapin2 },
);

export const designerMockPlans = generateFakeList<Plan>({ id: 'plan', name: 'Plan' }, 5);
