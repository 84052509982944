import { useMemo, useState } from 'react';

const DEFAULT_PAGE_SIZE = 50;

export const capElements = <T>(
  elements: { label: string; options: T[] }[] | T[] | null,
  groupMode: boolean,
  limit: number,
) => {
  if (!elements) {
    return { elementsCapped: [], hasMore: false, total: 0 };
  }

  // In non-group mode, that's pretty easy, an array slice will do.
  if (!groupMode) {
    return {
      elementsCapped: elements.slice(0, limit),
      hasMore: limit < elements.length,
      total: elements.length,
    };
  }

  // In group mode, we define a budget. We'll go through groups and pick
  // as many elements as we can while removing them from the budget.

  let budget = limit;
  let hasMore = false;
  let total = 0;

  const elementsCapped = elements
    .map((group) => {
      const groupLength = group.options.length;

      // Removing empty groups.
      if (!groupLength) {
        return null;
      }

      total += groupLength;

      // If we have elements but no budget to take them,
      // it means that we're leaving some behind.
      if (budget < groupLength) {
        hasMore = true;
      }

      // No budget left, stop here.
      if (budget === 0) {
        return null;
      }

      const output = {
        ...group,
        // If we can, take them all, if not, slice.
        options: budget < groupLength ? group.options.slice(0, budget) : group.options,
      };

      // And remove from the budget the number of items we took.
      budget -= output.options.length;

      return output;
    })
    // Remove empty groups.
    .filter(Boolean);

  return { elementsCapped, hasMore, total };
};

export const useLoadMore = <T>(
  elements: { label: string; options: T[] }[] | T[] | null,
  groupMode?: boolean,
  pageSize = DEFAULT_PAGE_SIZE,
) => {
  const [count, setCount] = useState(pageSize);

  const { elementsCapped, hasMore, total } = useMemo(
    () => capElements(elements, groupMode, count),
    [elements, groupMode, count],
  );

  const onClickLoadMore = useMemo(
    () =>
      // Should the button "Load more" be displayed?
      hasMore
        ? // If true, return the callback you can call to load more items.
          () => setCount(count + pageSize)
        : // Else return something falsy so you can condition the button display.
          null,
    [count, setCount, pageSize, hasMore],
  );

  return {
    elementsCapped,
    onClickLoadMore,
    count,
    total,
  };
};
