import { bufferCalls } from '@amal-ia/ext/typescript';
import { http } from '@amal-ia/frontend/kernel/http';
import { type AuthenticatedContext } from '@amal-ia/lib-rbac';
import {
  type BulkSyncUsersRequest,
  type KeysOfUserWithStringValues,
  type SyncUserRequest,
  type UserComputed,
  type UserContract,
  type UserSettings,
} from '@amal-ia/tenants/users/shared/types';

const SEARCH_PARAM_NAME_BY_PROPERTY = {
  id: 'ids',
  externalId: 'externalIds',
} as const satisfies Record<string, string>;

export class UserApiClient {
  public static mapDateFromApi(date?: Date | string | null) {
    return date ? new Date(date) : null;
  }

  public static mapUserFromApi(user: UserContract): UserContract {
    return {
      ...user,
      lastConnection: this.mapDateFromApi(user.lastConnection),
      createdAt: this.mapDateFromApi(user.createdAt),
      updatedAt: this.mapDateFromApi(user.updatedAt),
      joinedAt: this.mapDateFromApi(user.joinedAt),
      invitationSentAt: this.mapDateFromApi(user.invitationSentAt),
      clearedAt: this.mapDateFromApi(user.clearedAt),
    };
  }

  public static async fetchUserBy(property: KeysOfUserWithStringValues, value: string): Promise<UserComputed | null> {
    const searchParamName =
      property in SEARCH_PARAM_NAME_BY_PROPERTY
        ? SEARCH_PARAM_NAME_BY_PROPERTY[property as keyof typeof SEARCH_PARAM_NAME_BY_PROPERTY]
        : SEARCH_PARAM_NAME_BY_PROPERTY.externalId;

    const { data } = await http.post<UserComputed[]>('/users/searches', {
      [searchParamName]: [value],
    });
    return data.length ? data[0] : null;
  }

  public static readonly bufferedFetchUserBy = bufferCalls<
    [property: KeysOfUserWithStringValues, value: string],
    UserContract | null,
    UserContract[]
  >(
    async (bufferedArgs) => {
      const params = bufferedArgs.reduce<
        Record<(typeof SEARCH_PARAM_NAME_BY_PROPERTY)[keyof typeof SEARCH_PARAM_NAME_BY_PROPERTY], Set<string>>
      >(
        (acc, [property, value]) => {
          const searchParamName =
            property in SEARCH_PARAM_NAME_BY_PROPERTY
              ? SEARCH_PARAM_NAME_BY_PROPERTY[property as keyof typeof SEARCH_PARAM_NAME_BY_PROPERTY]
              : SEARCH_PARAM_NAME_BY_PROPERTY.externalId;

          acc[searchParamName].add(value);
          return acc;
        },
        { ids: new Set(), externalIds: new Set() },
      );

      const { data } = await http.post<UserContract[]>('/users/searches', {
        ids: Array.from(params.ids),
        externalIds: Array.from(params.externalIds),
      });
      return data;
    },
    ([property, value], result) => result.find((user) => user[property] === value) ?? null,
  );

  public static async fetchAllUsers(): Promise<UserComputed[]> {
    const { data } = await http.post<UserComputed[]>('/users/searches');
    return data;
  }

  public static async fetchAuthenticatedContext(): Promise<AuthenticatedContext> {
    const { data } = await http.get<AuthenticatedContext>('/users/me');
    return {
      ...data,
      user: this.mapUserFromApi(data.user),
    };
  }

  public static async getEmployees(): Promise<UserContract[]> {
    const { data } = await http.post<UserContract[]>('/users/searches');
    return data;
  }

  public static async logout(): Promise<void> {
    await http.patch('/users/logout');
  }

  public static async updateSettings(settings: UserSettings): Promise<UserContract> {
    const { data } = await http.patch<UserContract>('/users/settings', settings);
    return data;
  }

  public static async fetchUsersByIds(userIds: string[]): Promise<UserContract[]> {
    const { data } = await http.post<UserContract[]>('/users/searches', { ids: userIds });
    return data;
  }

  public static async fetchActiveUsers(): Promise<UserContract[]> {
    const { data } = await http.post<UserContract[]>('/users/searches', { active: true });
    return data;
  }

  public static async bulkSyncUsers(users: SyncUserRequest[]): Promise<void> {
    await http.post('/users/registrations', { users } as BulkSyncUsersRequest);
  }

  public static async sendInvitations(users: { email: string }[]): Promise<UserContract[]> {
    const { data } = await http.post<UserContract[]>('/users/invitations', { users });
    return data;
  }

  public static async impersonate({ userIdToImpersonate }: { userIdToImpersonate: string | null }): Promise<void> {
    await http.patch(`/users/impersonate`, { userIdToImpersonate });
  }

  public static async resetImpersonate(): Promise<void> {
    await http.patch(`/users/impersonate`, { userIdToImpersonate: null });
  }
}
