export interface RuleConfiguration {
  hideTotal?: boolean;
  mainKpiVariableOverwrite?: string | null;
  forecastPaymentDateFieldId?: string | null;
  paymentDateFieldId?: string | null;
  challengeComparisonVariableId?: string | null;
  challengeConditionVariableId?: string | null;
  challengePricesTableVariableId?: string | null;
  challengeHideResults?: boolean;
  challengeStartDate?: number | null;
  challengeEndDate?: number | null;
  slackChannelId?: string | null;
  showHideRuleVariableId?: string | null;
  payMonthly?: boolean | null;
  paymentMonthOffset?: number | null;
}

export enum RuleType {
  PAYOUT = 'PAYOUT',
  NON_PAYOUT = 'NON_PAYOUT',
  SPLIT = 'SPLIT',
  HOLD_AND_RELEASE = 'HOLD_AND_RELEASE',
  LINE_PAYOUT = 'LINE_PAYOUT',
  CHALLENGE = 'CHALLENGE',
}
