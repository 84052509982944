import { defineMessages } from 'react-intl';

export const teamRoutesMessages = defineMessages({
  TEAM_ITEM: {
    defaultMessage: 'Team details',
  },
  TEAM_LIST: {
    defaultMessage: 'Teams',
  },
} as const);
