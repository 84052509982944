import { OverwriteScopeEnum } from '@amal-ia/data-capture/overwrites/shared/types';
import { http } from '@amal-ia/frontend/kernel/http';
import {
  type Overwrite,
  type OverwriteFiltersResponse,
  type OverwriteResponse,
  type PaginatedResponse,
  type PatchCustomObjectRequest,
} from '@amal-ia/lib-types';

import { type OverwriteConfigFilters } from '../../types/overwrites';

const apiEndpoint = '/overwrites';

export async function getDataFilters(): Promise<OverwriteFiltersResponse> {
  const { data } = await http.get(`${apiEndpoint}/filters`);
  return data;
}

export async function getOverwrites(
  searchText?: string,
  page = 0,
  limit = 10,
  sortColumn?: string,
  sortOrder = 'asc',
  selectedFilters: OverwriteConfigFilters | null = null,
): Promise<PaginatedResponse<Partial<OverwriteResponse>>> {
  const {
    items: responseItems,
    totalItems,
    pageCount,
  } = (
    await http.get(apiEndpoint, {
      params: {
        sort: sortColumn,
        [sortOrder]: sortOrder,
        q: searchText,
        page,
        limit,
        ...selectedFilters,
      },
    })
  ).data;

  if (!responseItems) {
    return {
      totalItems: 0,
      items: [],
      pageCount: 0,
    };
  }
  return {
    totalItems,
    items: responseItems,
    pageCount,
  };
}

export async function createBulkOverwrite(
  customObjectDefinitionMachineName: string,
  overwriteObject: { overwrites: any[] },
): Promise<any> {
  const batchSize = 10;
  const nbBatches = Math.ceil(overwriteObject.overwrites.length / batchSize);

  let nbErrors = 0;
  let lastError = '';

  for (let i = 0; i < nbBatches; i++) {
    try {
      await http.post<Overwrite>(`/objects/${customObjectDefinitionMachineName}/records/bulk`, {
        patches: overwriteObject.overwrites.slice(i * batchSize, i * batchSize + batchSize).map((overwrite) => {
          const overwritePatch: PatchCustomObjectRequest & { objectExternalId: string } = {
            objectExternalId: overwrite.appliesToExternalId,
            field: overwrite.field,
            overwriteValue: overwrite.overwriteValue,
            scope: OverwriteScopeEnum.GLOBAL,
          };

          return overwritePatch;
        }),
      });
    } catch (e) {
      nbErrors++;
      lastError = (e as Error).message;
    }
  }

  if (nbErrors > 0) {
    throw new Error(`${nbErrors} batches over ${nbBatches} were in error. Last error: ${lastError}`);
  }
  return {
    status: 201,
  };
}
