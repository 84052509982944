import { makeStyles } from '@mui/styles';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

export default makeStyles((theme: AmaliaThemeType) => ({
  tableRow: {
    '&:hover $tracingDeal': {
      width: '3rem',
      borderColor: theme.palette.grey[300],
    },
  },
  link: {
    color: theme.palette.link.main,
  },

  // Tracing
  tracingDealContainer: {
    position: 'absolute',
    left: 0,
    height: 0,
    display: 'flex',
    alignItems: 'center',
  },
  tracingDeal: {
    textAlign: 'center',
    zIndex: 2,
    width: 0,
    height: '3rem',
    lineHeight: '150%',
    position: 'relative',
    top: 'calc(.5rem + 4px)',
    padding: '0',
    cursor: 'pointer',
    display: 'block',
    backgroundColor: theme.palette.grey[300],
    borderRight: '1px solid transparent',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    overflow: 'hidden',
    transition: 'width .5s, transform .5s',
    transformOrigin: 'left center',
    borderRadius: '0 1.5rem 1.5rem 0',

    '&:hover': {
      transform: 'scale(1.1)',
      backgroundColor: theme.palette.grey[300],
    },
  },
}));
