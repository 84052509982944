import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconCircleCheck, IconClockHour4 } from '@tabler/icons-react';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { formatUserFullName, type WorkflowCondensedStep } from '@amal-ia/lib-types';
import { Avatar } from '@amal-ia/lib-ui';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

const useStepStyles = makeStyles<
  AmaliaThemeType,
  {
    compact: boolean;
    isLongLine: boolean;
    isLastStepComplete: boolean;
    index: number;
  }
>((theme) => ({
  stepTitle: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.grey['100'],
    borderRadius: '4px',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  checkedIcon: {
    color: theme.palette.success.main,
  },
  AccessTimeFilledIcon: {
    color: theme.palette.common.white,
    backgroundColor: (props) =>
      props.isLastStepComplete || props.index === 0 ? theme.palette.primary.light : theme.palette.grey[600],
    borderRadius: '100%',
  },
  stepDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarUser: {
    marginRight: theme.spacing(1),
  },
  dashedLine: {
    backgroundImage: `linear-gradient(${theme.palette.grey[500]} 33%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: 'right',
    backgroundSize: '7px 10px',
    backgroundRepeat: 'repeat-y',
    position: 'relative',
    left: theme.spacing(2),
    width: '1px',
    height: (props) => (props.isLongLine ? '70%' : '50%'),
  },
  numberOfStatementsLabel: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const formatStepNameForDisplay = (stepName: string) => {
  const [first, second, ...rest] = stepName.split(', ');
  return [first, second, rest.length > 0 && `(+${rest.length})`].filter(Boolean).join(', ');
};

interface WorkflowStepperStepProps {
  index: number;
  workflowName: string;
  step: WorkflowCondensedStep;
  usersMap: UsersMap;
  isLast: boolean;
  isLastStepComplete: boolean;
  compact: boolean;
}

export const WorkflowStepperStep = memo(function WorkflowStepperStep({
  index,
  workflowName,
  step,
  usersMap,
  isLast,
  isLastStepComplete,
  compact,
}: WorkflowStepperStepProps) {
  const classes = useStepStyles({
    compact,
    isLongLine: step.completed,
    isLastStepComplete,
    index,
  });

  const theme = useTheme();

  const { formatMessage } = useIntl();

  const statusIcon = useMemo(
    () =>
      !step.completed ? (
        <IconClockHour4
          color={isLastStepComplete || index === 0 ? theme.ds.colors.primary[500] : theme.ds.colors.gray[700]}
          size={24}
        />
      ) : (
        <IconCircleCheck
          color={theme.ds.colors.success[500]}
          size={24}
        />
      ),
    [index, isLastStepComplete, step.completed, theme],
  );

  return (
    <Box display="flex">
      <Box>
        <Box
          position="relative"
          top="2px"
        >
          {statusIcon}
        </Box>
        {!isLast && <div className={classes.dashedLine} />}
      </Box>
      <Box className={classes.stepTitle}>
        <div
          aria-checked={step.completed}
          aria-label={formatMessage(
            { defaultMessage: 'Workflow {workflowName} - step #{stepNumber, number}' },
            { workflowName, stepNumber: index + 1 },
          )}
        >
          <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
            {formatStepNameForDisplay(step.stepName || '')}
          </Typography>
        </div>
        {!compact && (
          <div className={classes.stepDetails}>
            {step.userId && usersMap[step.userId] ? (
              <div className={classes.userInfo}>
                <Avatar
                  className={classes.avatarUser}
                  user={usersMap[step.userId]}
                />
                <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
                  {formatUserFullName(usersMap[step.userId])}
                </Typography>
              </div>
            ) : null}
            {step.date ? (
              <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
                {moment(step.date).format('YYYY-MM-DD')}
              </Typography>
            ) : null}
            {step.numberOfStatementsLabel ? (
              <Typography
                as="div"
                className={classes.numberOfStatementsLabel}
                variant={Typography.Variant.BODY_SMALL_REGULAR}
              >
                <span>
                  <FormattedMessage defaultMessage="statement(s) on this step: " />
                </span>
                <span>{step.numberOfStatementsLabel}</span>
              </Typography>
            ) : null}
          </div>
        )}
      </Box>
    </Box>
  );
});
