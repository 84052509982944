import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { deleteAt, type ReduxAction, replaceAt, type VariablesMap } from '@amal-ia/lib-types';

import { isTheSameVariableValue } from '../../services/variables/variableValues.service';
import { type VariableValue } from '../../types/variables';

import { VARIABLES_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action) => {
    switch (action.type) {
      case VARIABLES_ACTIONS.START:
        return state + 1;
      case VARIABLES_ACTIONS.SUCCESS:
      case VARIABLES_ACTIONS.ERROR:
      case VARIABLES_ACTIONS.SET_VARIABLES:
      case VARIABLES_ACTIONS.SET_VARIABLE_VALUES:
      case VARIABLES_ACTIONS.CLEAR_USER_VARIABLE_VALUES:
      case VARIABLES_ACTIONS.CLEAR_PLAN_VARIABLE_VALUES:
      case VARIABLES_ACTIONS.CLEAR_TEAM_VARIABLE_VALUES:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state: VariablesMap = {}, action: ReduxAction) => {
    switch (action.type) {
      case VARIABLES_ACTIONS.SET_VARIABLES:
        return keyBy(action.payload.variables, 'id');
      case VARIABLES_ACTIONS.CLEAR_VARIABLES:
        return {};
      default:
        return state;
    }
  },
  values: (state: VariableValue[] = [], action: ReduxAction) => {
    switch (action.type) {
      case VARIABLES_ACTIONS.SET_VARIABLE_VALUES:
        return action.payload.variableValues;
      case VARIABLES_ACTIONS.CLEAR_VARIABLE_VALUES:
        return [];
      case VARIABLES_ACTIONS.SET_VARIABLE_VALUE: {
        const { variableValue } = action.payload;
        const index = state.findIndex((vv) => isTheSameVariableValue(vv, variableValue));
        // Deletion of the value.
        if (variableValue.value === null) {
          return deleteAt(state, index);
        }

        // New value to add in the array.
        if (index === -1) {
          return state.concat(variableValue);
        }

        // Edit the value in place.
        return replaceAt(state, index, variableValue);
      }
      case VARIABLES_ACTIONS.CLEAR_USER_VARIABLE_VALUES:
        return state.filter((vv) => vv.userId !== action.payload.userId);
      case VARIABLES_ACTIONS.CLEAR_PLAN_VARIABLE_VALUES:
        return state.filter((vv) => vv.planId !== action.payload.planId);
      case VARIABLES_ACTIONS.CLEAR_TEAM_VARIABLE_VALUES:
        return state.filter((vv) => vv.teamId !== action.payload.teamId);
      default:
        return state;
    }
  },
});
