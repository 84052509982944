import { makeStyles } from '@mui/styles';

import { amaliaTheme, type AmaliaThemeType, colors } from '@amal-ia/ext/mui/theme';
import { StatementErrorLevel } from '@amal-ia/lib-types';

export const useStatementDetailHeaderStyles = makeStyles((theme: AmaliaThemeType) => ({
  '@keyframes floatAppear': {
    '0%': {
      top: '-10rem',
    },
    '100%': {
      top: '0',
    },
  },

  notShowInFloat: {},
  floatablePayoutAmount: {
    marginTop: theme.spacing(-1),
  },
  headerWrapper: {
    minHeight: '24rem',
  },
  quotaMissingErrorText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  quotaMissingError: {
    textAlign: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  gotoQuota: {
    backgroundColor: theme.palette.grey[100],
    color: `${theme.palette.grey[700]}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      color: `${theme.palette.grey[700]}`,
    },
  },
  floatableHeaderWrapper: {
    position: 'fixed',
    width: '100%',
    left: 'calc(50% - 500px)',
    top: 0,
    zIndex: 1001,
    maxWidth: 1000,
    animation: '$floatAppear .2s linear',
  },
  header: {},
  floatableHeader: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 1000,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    [theme.breakpoints.down('xl')]: {
      maxWidth: `calc(100% - ${theme.spacing(4)})`,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1300px',
    },

    '&$header': {
      margin: '0 auto',
    },

    '&$subheader, & $subheader': {
      padding: theme.spacing(0, 1),
      margin: '0 auto',
    },

    '& $notShowInFloat': {
      display: 'none',
    },

    '& .MuiGrid2-spacing-xs-4 > .MuiGrid2-item': {
      padding: theme.spacing(2),
    },

    '& > .MuiGrid2-spacing-xs-4 > .MuiGrid2-item': {
      padding: `0 ${theme.spacing(2)}`,
    },
  },
  headerGrid: {
    '&.MuiGrid2-item': {
      paddingBottom: 0,
    },
  },
  textOverline: {
    marginTop: theme.spacing(-0.5),
    display: 'block',
  },
  salesNameContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  salesName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    display: 'block',
  },

  subheader: {
    padding: theme.spacing(4),
  },

  hasThread: {
    color: theme.palette.link.main,
  },

  statementWorkflowComplete: {
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  statementWithTracing: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginBottom: 0,
  },
  loadingProgress: {
    margin: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.grey['300'],
  },

  userTeamRole: {
    display: 'flex',
    alignItems: 'center',
  },

  betaLabel: {
    backgroundColor: colors['blue-100'],
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  betaLabelAccent: {
    color: colors['blue-500'],
    marginRight: '1ch',
  },
  actionTooltip: {
    margin: 0,
    padding: 0,
    borderRadius: '8px',
    '&>li:first-child': {
      borderRadius: '8px 8px 0px 0px',
    },
    '&>li:last-child': {
      borderRadius: '0px 0px 8px 8px',
    },
  },
}));

export const themeColorForStatementErrorType: Record<StatementErrorLevel, string> = {
  [StatementErrorLevel.WARNING]: amaliaTheme.palette.warning.main,
  [StatementErrorLevel.ERROR]: amaliaTheme.palette.error.main,
};
