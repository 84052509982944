import { keyBy, omit, sortBy } from 'lodash';
import { combineReducers } from 'redux';

import { type TeamMap, type TeamNode } from '@amal-ia/lib-types';

import { type ComputedTeamAssignment, type TeamAssignmentsMap, type TeamPlanAssignmentsMap } from '../../types/teams';

import { TEAMS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action) => {
    switch (action.type) {
      case TEAMS_ACTIONS.START:
        return state + 1;
      case TEAMS_ACTIONS.ERROR:
      case TEAMS_ACTIONS.SET_TEAMS:
      case TEAMS_ACTIONS.SET_TEAM:
      case TEAMS_ACTIONS.DELETE_TEAM:
      case TEAMS_ACTIONS.SET_TEAMS_ASSIGNMENTS:
      case TEAMS_ACTIONS.SET_TEAM_ASSIGNMENTS:
      case TEAMS_ACTIONS.SET_TEAM_ASSIGNMENT:
      case TEAMS_ACTIONS.DELETE_TEAM_ASSIGNMENT:
      case TEAMS_ACTIONS.SET_TEAM_PLAN_ASSIGNMENTS:
      case TEAMS_ACTIONS.SET_TEAM_PLAN_ASSIGNMENT:
      case TEAMS_ACTIONS.DELETE_TEAM_PLAN_ASSIGNMENT:
      case TEAMS_ACTIONS.SET_TEAM_HIERARCHY:
        return state - 1;
      default:
        return state;
    }
  },
  map: (state: TeamMap = {}, action) => {
    switch (action.type) {
      case TEAMS_ACTIONS.SET_TEAMS:
        return keyBy(action.payload.teams, 'id');
      case TEAMS_ACTIONS.SET_TEAM:
        return {
          ...state,
          [action.payload.team.id]: action.payload.team,
        };
      case TEAMS_ACTIONS.DELETE_TEAM:
        return omit(state, action.payload.teamId);
      default:
        return state;
    }
  },
  isMapFullyLoaded: (state: boolean = false, action) => {
    switch (action.type) {
      case TEAMS_ACTIONS.SET_TEAMS:
        return true;
      default:
        return state;
    }
  },
  teamAssignments: (state: TeamAssignmentsMap = {}, action) => {
    switch (action.type) {
      case TEAMS_ACTIONS.SET_TEAMS_ASSIGNMENTS:
        return {
          ...state,
          ...Object.entries(
            (action.payload.teamAssignments || {}) as Record<string, Record<string, ComputedTeamAssignment>>,
          ).reduce<Record<string, Record<string, ComputedTeamAssignment>>>(
            (
              acc: Record<string, Record<string, ComputedTeamAssignment>>,
              [key, value]: [string, Record<string, ComputedTeamAssignment>],
            ) => {
              acc[key] = value;
              return acc;
            },
            {},
          ),
        };
      case TEAMS_ACTIONS.SET_TEAM_ASSIGNMENTS:
        return {
          ...state,
          [action.payload.teamId]: action.payload.teamAssignments,
        };
      case TEAMS_ACTIONS.SET_TEAM_ASSIGNMENT:
        return {
          ...state,
          [action.payload.teamId]: {
            ...state[action.payload.teamId],
            [action.payload.userId]: action.payload.assignment,
          },
        };
      case TEAMS_ACTIONS.DELETE_TEAM_ASSIGNMENT:
        return {
          ...state,
          [action.payload.teamId]: omit(state[action.payload.teamId], action.payload.userId),
        };
      default:
        return state;
    }
  },
  currentTeamPlanAssignments: (state: TeamPlanAssignmentsMap = {}, action) => {
    switch (action.type) {
      case TEAMS_ACTIONS.SET_TEAM_PLAN_ASSIGNMENTS:
        return keyBy(action.payload.planAssignments, 'planId');
      case TEAMS_ACTIONS.SET_TEAM_PLAN_ASSIGNMENT:
        return {
          ...state,
          [action.payload.planAssignment.planId]: action.payload.planAssignment,
        };
      case TEAMS_ACTIONS.DELETE_TEAM_PLAN_ASSIGNMENT:
        return omit(state, action.payload.planAssignment.planId);
      default:
        return state;
    }
  },
  hierarchy: (state: TeamNode[] = [], action) => {
    switch (action.type) {
      case TEAMS_ACTIONS.SET_TEAM_HIERARCHY:
        return sortBy(action.payload.hierarchy, 'name');
      default:
        return state;
    }
  },
});
