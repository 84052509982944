import { css } from '@emotion/react';
import { AccordionSummary, Box, Divider, type AccordionSummaryProps } from '@mui/material';
import { IconChevronRight } from '@tabler/icons-react';
import clsx from 'clsx';
import { Fragment, memo, type ReactNode } from 'react';

import { RuleType } from '@amal-ia/compensation-definition/shared/types';
import { type MainKpi } from '@amal-ia/lib-types';
import { InfoWithTooltip, Text, TextType } from '@amal-ia/lib-ui';

import { ForecastedKpiTooltip } from '../ForecastedKpiTooltip/ForecastedKpiTooltip';

import { KpiRuleItem } from './KpiRuleItem';
import { useRuleAccordionStyles } from './useRuleAccordionStyles';

interface RuleSummaryProps extends AccordionSummaryProps {
  label: ReactNode;
  helpLabel?: string;
  icon?: ReactNode;
  machineName: string;
  mainKpi: MainKpi;
  overwriteMainKpiFormattedValue?: string;
  isSubSummary?: boolean;
  onClick?: (event: any) => void;
  actions?: ReactNode;
  onClickToggleRuleAccordion: () => void;
}

export const RuleSummary = memo(function RuleSummary({
  label,
  helpLabel,
  icon,
  machineName,
  mainKpi,
  overwriteMainKpiFormattedValue,
  onClick,
  isSubSummary,
  actions,
  onClickToggleRuleAccordion,
  ...props
}: RuleSummaryProps) {
  const classes = useRuleAccordionStyles();

  return (
    <AccordionSummary
      aria-controls={`${machineName}-bh-content`}
      expandIcon={<Box>{!isSubSummary && <IconChevronRight size={28} />}</Box>}
      id={`${machineName}-bh-header`}
      style={{ display: 'flex', flexDirection: 'row-reverse', cursor: isSubSummary ? 'auto' : 'pointer' }}
      classes={{
        expanded: classes.expanded,
        expandIconWrapper: classes.expandIcon,
        ...(isSubSummary && { root: classes.subAccordionSummary }),
      }}
      {...props}
      css={(theme) => css`
        & .MuiAccordionSummary-expandIconWrapper {
          color: ${theme.ds.colors.gray[800]};
        }
      `}
      onClick={onClickToggleRuleAccordion}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          width="75%"
        >
          {icon ? <Box marginRight={2}>{icon}</Box> : null}
          <Text
            className={clsx(classes.summaryTitle, mainKpi.type === RuleType.NON_PAYOUT && classes.nonPayoutRuleTitle)}
            type={TextType.RULE_ACCORDION_NAME}
          >
            {label}
            {helpLabel ? (
              <InfoWithTooltip>
                <Box whiteSpace="pre-wrap">{helpLabel}</Box>
              </InfoWithTooltip>
            ) : null}
          </Text>
        </Box>

        {mainKpi.hideTotal ? null : (
          <Fragment>
            <Divider orientation="vertical" />

            <Box
              alignItems="flex-end"
              display="flex"
              flexDirection="row"
              pl={6}
              width="20%"
            >
              {mainKpi.isValueForecasted &&
              mainKpi.formattedOriginalValue &&
              mainKpi.formattedOriginalValue !== mainKpi.formattedForecastedValue ? (
                <ForecastedKpiTooltip
                  isValueForecasted
                  formattedAchievedValue={mainKpi.formattedOriginalValue}
                  formattedForecastedValue={mainKpi.formattedForecastedValue}
                  position="top"
                >
                  <KpiRuleItem
                    mainKpi={mainKpi}
                    overwriteFormattedValue={overwriteMainKpiFormattedValue}
                    onClick={onClick}
                  />
                </ForecastedKpiTooltip>
              ) : (
                <KpiRuleItem
                  mainKpi={mainKpi}
                  overwriteFormattedValue={overwriteMainKpiFormattedValue}
                  onClick={onClick}
                />
              )}
            </Box>
          </Fragment>
        )}
        <Box
          height="3em"
          position="relative"
          width="3em"
        >
          {actions}
        </Box>
      </Box>
    </AccordionSummary>
  );
});
