import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, subject, SubjectsEnum } from '@amal-ia/lib-rbac';

import { plansRoutesMessages } from './plans.routes.messages';

const PlanAssignmentsContainer = lazy(() => import('./Assignments/PlanAssignmentsContainer'));
const PlanListContainer = lazy(() => import('./Overview/PlanListContainer'));

export const PlansRoutes = memo(function PlansRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout currentPage="plans">
      <Switch>
        <CompanyRoute
          component={PlanAssignmentsContainer}
          path={routes.PLAN_ASSIGNMENT}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.Plan_Assignment)}
          title={formatMessage(plansRoutesMessages.PLAN_ASSIGNMENTS)}
          user={user}
        />
        <CompanyRoute
          component={PlanListContainer}
          path={routes.PLANS}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, subject(SubjectsEnum.Plan, {}))}
          title={formatMessage(plansRoutesMessages.PLANS)}
          user={user}
        />
        <CompanyRoute
          component={PlanListContainer}
          path={routes.PLAN_OVERVIEW}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, subject(SubjectsEnum.Plan, {}))}
          title={formatMessage(plansRoutesMessages.PLAN_OVERVIEW)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
