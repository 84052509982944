import { Unstable_Grid2 as Grid } from '@mui/material';
import { noop } from 'lodash';
import moment from 'moment';
import { memo, useCallback, useMemo } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { type OverwriteCreationRequestDetails } from '@amal-ia/data-capture/overwrites/components';
import { OverwriteScopeEnum } from '@amal-ia/data-capture/overwrites/shared/types';
import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { useCompanyCustomization } from '@amal-ia/frontend/web-data-layers';
import {
  type ComputedVariable,
  type ComputedVariableWithVariableIdAndLabel,
  formatTotal,
  type VariableDefinition,
} from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { ForecastedKpiTooltip, StatementKpi } from '@amal-ia/lib-ui-business';
import { type ComputeEnginePrimitiveTypes } from '@amal-ia/payout-calculation/shared/types';

import type * as React from 'react';

interface ForecastedKPIProps {
  computedVariable: ComputedVariableWithVariableIdAndLabel;
  variableDefinition: VariableDefinition;
  canSimulate?: boolean;
  setOverwriteObjectDetails: React.Dispatch<React.SetStateAction<OverwriteCreationRequestDetails>>;
  openOverwriteModal: () => void;
  ruleDefinition: PlanRule;
  resetOverwrite: (variable: ComputedVariable) => Promise<void>;
}
export default memo(function ForecastedKPIProps({
  computedVariable,
  variableDefinition,
  canSimulate,
  setOverwriteObjectDetails,
  openOverwriteModal,
  ruleDefinition,
  resetOverwrite,
}: ForecastedKPIProps) {
  const { currency, rate } = useStatementDetailContext();
  const { legacyKpiCardView } = useCompanyCustomization();

  const handleOpenOverwriteModal = useCallback(() => {
    setOverwriteObjectDetails({
      format: variableDefinition.format,
      oldValue: (computedVariable.overwrite?.overwriteValue || computedVariable.value) as number,
      currency: computedVariable.currency,
      field: variableDefinition.name,
      isProperty: false,
      machineName: variableDefinition.machineName,
      rule: ruleDefinition.name,
    });
    openOverwriteModal();
  }, [
    openOverwriteModal,
    setOverwriteObjectDetails,
    computedVariable.currency,
    computedVariable.overwrite?.overwriteValue,
    computedVariable.value,
    variableDefinition.format,
    variableDefinition.name,
    variableDefinition.machineName,
    ruleDefinition.name,
  ]);

  const resetOverwriteProxy = useCallback(() => resetOverwrite(computedVariable), [resetOverwrite, computedVariable]);

  const { formattedAchievedValue, formattedForecastedValue, overwriteInfo, isValueForecasted } = useMemo(() => {
    const kpiCurrency = computedVariable.currency || currency || CurrencySymbolsEnum.EUR;
    const achievedValueResult = computedVariable.achievedValue;
    const isKpiSimulated =
      computedVariable.overwrite && computedVariable.overwrite.scope === OverwriteScopeEnum.FORECAST;
    const isKpiForecasted = achievedValueResult !== computedVariable.value;

    return {
      formattedAchievedValue: formatTotal(achievedValueResult, variableDefinition.format, kpiCurrency, rate),
      formattedForecastedValue: formatTotal(
        computedVariable.value as ComputeEnginePrimitiveTypes,
        variableDefinition.format,
        kpiCurrency,
        rate,
      ),
      overwriteInfo: isKpiSimulated && {
        sourceValue: formatTotal(
          computedVariable.overwrite?.sourceValue as ComputeEnginePrimitiveTypes,
          variableDefinition.format,
          kpiCurrency,
          rate,
        ),
        value: formatTotal(
          computedVariable.overwrite?.overwriteValue as ComputeEnginePrimitiveTypes,
          variableDefinition.format,
          kpiCurrency,
          rate,
        ),
        author: computedVariable.overwrite?.creator,
        date: moment(computedVariable.overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY'),
      },
      isValueForecasted: isKpiForecasted,
    };
  }, [computedVariable, currency, variableDefinition.format, rate]);

  return (
    <Grid key={computedVariable.variableMachineName}>
      {isValueForecasted || overwriteInfo ? (
        <ForecastedKpiTooltip
          formattedAchievedValue={formattedAchievedValue}
          formattedForecastedValue={formattedForecastedValue}
          isValueForecasted={isValueForecasted}
          overwriteInfo={overwriteInfo}
          resetOverwriteProxy={resetOverwriteProxy}
        >
          <StatementKpi
            isKpiForecasted
            canComment={false}
            computedVariable={computedVariable}
            handleOpenModal={handleOpenOverwriteModal}
            isReadOnly={!canSimulate}
            openStatementThread={noop}
            useLegacyKpiCardView={legacyKpiCardView}
            variableDefinition={variableDefinition}
          />
        </ForecastedKpiTooltip>
      ) : (
        <StatementKpi
          canComment={false}
          computedVariable={computedVariable}
          handleOpenModal={handleOpenOverwriteModal}
          isReadOnly={!canSimulate}
          openStatementThread={noop}
          useLegacyKpiCardView={legacyKpiCardView}
          variableDefinition={variableDefinition}
        />
      )}
    </Grid>
  );
});
