import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { DesignerRedirect, type DesignerRedirectProps, DesignerRedirectBase } from './DesignerRedirect';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    '& + &$root': {
      marginTop: theme.spacing(3),
    },
  },
}));

interface DesignerInputWithRedirectProps extends DesignerRedirectProps {
  children: ReactNode;
}

export const DesignerInputWithRedirect = memo(function DesignerInputWithRedirect({
  children,
  ...rest
}: DesignerInputWithRedirectProps) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {children}
      {rest.name ? <DesignerRedirect {...rest} /> : <DesignerRedirectBase {...rest} />}
    </Box>
  );
});
