import { config } from '@amal-ia/frontend/kernel/config';

export const bootstrapHubspot = () => {
  const { hubspotChatId } = config.hubspot;
  if (hubspotChatId) {
    const e = document.createElement('script');
    e.type = 'text/javascript';
    e.id = 'hs-script-loader';
    e.async = true;
    e.defer = true;
    e.src = `https://js-na1.hs-scripts.com/${hubspotChatId}.js`;
    const s = document.getElementsByTagName('script')[0];
    if (s.parentNode) s.parentNode.insertBefore(e, s);
  }
};
