/**
 * Workaround for
 * @see {@link https://github.com/jestjs/jest/issues/7547}
 */
import { toError } from '@amal-ia/ext/typescript';

export const ok = (condition: unknown, error?: unknown): asserts condition => {
  if (!condition) {
    throw toError(error || 'Assertion failed');
  }
};

export const assert: typeof ok = ok;
