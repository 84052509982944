import { type CSSProperties } from 'react';

import { type Relationship } from '@amal-ia/compensation-definition/shared/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amal-ia/ext/mui/theme';
import { TokenType } from '@amal-ia/lib-types';

import { IFormulaNode, FormulaNodeEnum } from './IFormulaNode';

export class RelationshipFormulaNode extends IFormulaNode {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Relationship,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.RELATIONSHIP, nodeObject, objectDefinition, relationship);
  }

  public getDesignerPath() {
    return (this.nodeObject as Relationship)?.id
      ? { type: TokenType.LINK, id: (this.nodeObject as Relationship).id }
      : null;
  }

  public getStyles(): CSSProperties {
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.link };
  }

  public getLabel(): string {
    return (this.nodeObject as Relationship).name;
  }
}
