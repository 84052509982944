import { Box, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconPlus } from '@tabler/icons-react';
import { type Dispatch, memo, type ReactNode, type SetStateAction, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { type Option, type Plan } from '@amal-ia/lib-types';

import { Text, TextType } from '../../typography';
import { TitleSelectWithAction } from '../TitleSelectWithAction';

import { messages } from './PlanSelectDesigner.messages';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  selectedPlan: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    color: theme.palette.secondary.main,
  },
  noRadius: {
    borderRadius: '0',
  },
  archivedTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export interface PlanSelectDesignerProps {
  arePlansArchived: boolean;
  showArchivedPlans: boolean;
  planOptions: { value: string; label: string; text: ReactNode | string; archived: boolean }[];
  setShowArchivedPlans: Dispatch<SetStateAction<boolean>>;
  onChange: (plan: Option) => void;
  onClickAddPlan: () => void;
}

export const PlanSelectDesigner = memo(function PlanSelectDesigner({
  arePlansArchived,
  showArchivedPlans,
  planOptions,
  setShowArchivedPlans,
  onChange,
  onClickAddPlan,
}: PlanSelectDesignerProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const onChangeProxy = useCallback(
    (planId: string) => onChange(planOptions.find((p) => p.value === planId)!),
    [planOptions, onChange],
  );

  const renderShowArchived = useMemo(() => {
    if (showArchivedPlans || !arePlansArchived) {
      return undefined;
    }
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginBottom={2}
        marginTop={2}
      >
        <Button
          variant={Button.Variant.SECONDARY_LIGHT}
          onClick={() => setShowArchivedPlans(true)}
        >
          <FormattedMessage {...messages.SHOW_ARCHIVED_PLANS} />
        </Button>
      </Box>
    );
  }, [arePlansArchived, setShowArchivedPlans, showArchivedPlans]);

  const displayArchivedTextToOption = useCallback((plan: Plan) => plan?.archived, []);
  const archiveTextOption = useMemo(
    () => (
      <ListItemText
        primary={
          <Text
            className={classes.pointer}
            type={TextType.POPIN_TITLE}
          >
            <FormattedMessage {...messages.ARCHIVE} />
          </Text>
        }
      />
    ),
    [classes.pointer],
  );

  return (
    <TitleSelectWithAction
      action={{ label: <FormattedMessage {...messages.PLAN} />, icon: <IconPlus />, onClick: onClickAddPlan }}
      endListAction={renderShowArchived}
      meetSecondaryConditionDisplay={displayArchivedTextToOption}
      options={planOptions}
      secondaryActionText={archiveTextOption}
      title={formatMessage(messages.SWITCH_PLAN)}
      onChange={onChangeProxy}
    />
  );
});
