import invariant from 'invariant';
import { createContext, useContext } from 'react';

export interface StatementCalculationContextInterface {
  launchCalculation: () => Promise<void>;
  stopCalculation: () => Promise<void>;
  calculationProgress: string;
  isCalculationRunning: boolean;
}

const StatementCalculationContext = createContext<StatementCalculationContextInterface | null>(null);

export const StatementCalculationContextProvider = StatementCalculationContext.Provider;

export const useStatementCalculationContext = () => {
  const context = useContext(StatementCalculationContext);
  invariant(context, 'useStatementCalculationContext must be used within a StatementCalculationContext');
  return context;
};
