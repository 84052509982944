import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconPencil, IconX } from '@tabler/icons-react';
import { memo, useMemo, useCallback } from 'react';

import { PlanRuleChartEnumType, type PlanRuleChart } from '@amal-ia/compensation-definition/shared/types';
import { type CustomObjectDefinitionsMap } from '@amal-ia/data-capture/models/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';
import { type VariablesMap } from '@amal-ia/lib-types';
import { ListElement } from '@amal-ia/lib-ui';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.divider}`,
  },
}));

interface PlanRuleListChartListChartProps {
  chart: PlanRuleChart;
  editChart: (chart: PlanRuleChart) => any;
  deleteChart: (chart: PlanRuleChart) => any;
  variablesMap: VariablesMap;
  customObjectDefinitionsMap: CustomObjectDefinitionsMap;
}

export const PlanRuleListChartListChart = memo(function PlanRuleListChartListChart({
  chart,
  editChart,
  deleteChart,
  variablesMap,
  customObjectDefinitionsMap,
}: PlanRuleListChartListChartProps) {
  const classes = useStyles();

  const onEditChart = useCallback(() => editChart(chart), [editChart, chart]);
  const onDeleteChart = useCallback(() => deleteChart(chart), [deleteChart, chart]);

  const nameToPrint = useMemo(() => {
    if (chart.name) {
      return chart.name;
    }

    // If no name provided and chart is a target achievemnet chart, fetch the target variable's name
    if (chart.type === PlanRuleChartEnumType.TARGET_ACHIEVEMENT) {
      const foundVariable = Object.values(variablesMap).find(
        (v) => v.id === chart.configuration.targetAchievementVariableId,
      );
      if (foundVariable) {
        return foundVariable.name;
      }
    }

    // If no name provided and chart is a metrics chart, fetch property's name
    if (chart.type === PlanRuleChartEnumType.STATEMENT_METRIC) {
      const foundProperty =
        customObjectDefinitionsMap.statementMetric?.properties[chart.configuration.metricsPropertyMachineName];

      if (foundProperty) {
        return foundProperty.name;
      }
    }

    return '';
  }, [chart, variablesMap, customObjectDefinitionsMap]);

  return (
    <ListElement
      key={chart.id}
      className={classes.listElement}
    >
      <Box mx={0.7}>{nameToPrint}</Box>
      <IconButton
        icon={<IconPencil />}
        label={`Edit chart ${nameToPrint}`}
        onClick={onEditChart}
      />
      <IconButton
        icon={<IconX />}
        label={`Delete chart ${nameToPrint}`}
        onClick={onDeleteChart}
      />
    </ListElement>
  );
});
