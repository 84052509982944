import { type ChangeSet, EditingState } from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  Table,
  TableColumnReordering,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import { keyBy } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { StringUtils } from '@amal-ia/lib-types';

import { TableBuilderCell } from './cells/TableBuilderCell';
import { TableBuilderCellHeader } from './cells/TableBuilderCellHeader';
import { type TableBuilderColumn, type TableBuilderRow } from './tableBuilder.types';
import { TableBuilderContext } from './TableBuilderContext';
import { TableBuilderRenameColumnModal } from './TableBuilderRenameColumnModal';

/**
 * Rebuild computed rows to the [[], [], []] format.
 */
const rebuildRows = (computedRows: Record<string, any>[], columns: TableBuilderColumn[]) => {
  const columnNames = columns.map((c) => c.machineName);
  return computedRows.map((row) => columnNames.map((columnName) => row[columnName] || 0));
};

const COLUMN_EXTENSIONS = [
  {
    columnName: 'actions',
    width: 50,
    editingEnabled: false,
  },
];

export interface TableBuilderProps {
  rows: TableBuilderRow[];
  columns: TableBuilderColumn[];

  shouldFormatCellValue?: boolean;

  // Either you have the builder in edit mode and those two setters are mandatory.
  setRows?: (rows: TableBuilderRow[]) => Promise<void> | void;
  setColumns?: (columns: TableBuilderColumn[]) => Promise<void> | void;
  // Or you have it in readonly mode and this props should be true.
  isReadonly?: boolean;
}

export const TableBuilder = memo(function TableBuilder({
  columns,
  rows,
  setColumns,
  setRows,
  isReadonly,
  shouldFormatCellValue,
}: TableBuilderProps) {
  const { snackError } = useSnackbars();

  const computedColumns = useMemo(() => {
    const formattedColumns = columns.map((c) => ({
      name: c.machineName,
      title: c.name,
      format: c.format,
    }));

    return isReadonly ? formattedColumns : [...formattedColumns, { name: 'actions', title: ' ' }];
  }, [columns, isReadonly]);

  const columnOrder = useMemo(() => columns.map((c) => c.machineName), [columns]);

  // ====================== DATA ======================

  /**
   * Working with two-levels arrays in hard and is not really compliant with our dx-grid.
   * First thing we can do to make it easier to work with is to transform each row to an
   * object, keys are the machineName: [{}, {}, {}].
   *
   * Caveat is that when we call setRows, we have to rebuild it in the [[], [], []] format,
   * see the `rebuildRows` which is called before every setRows.
   */
  const computedRows = useMemo(
    () =>
      rows.map((row) =>
        row.reduce((acc: Record<string, any>, currentRow: any, index: number) => {
          acc[columns[index].machineName] = currentRow;
          return acc;
        }, {}),
      ),
    [rows, columns],
  );

  // When user submits change to a cell, update rows.
  const onCommitChanges = useCallback(
    (changes: ChangeSet) => {
      const indexChanged = Object.keys(changes.changed || {});
      const newRows = computedRows.map((row, index) =>
        indexChanged.includes(`${index}`)
          ? {
              ...row,
              // Merge current row with changes. Forbid empty values
              ...(changes.changed?.[`${index}`] || 0),
            }
          : row,
      );
      setRows(rebuildRows(newRows, columns));
    },
    [computedRows, setRows, columns],
  );

  // ====================== COLUMNS MANAGEMENT ======================

  const isColumnExists = useCallback(
    (name: string) => columns.find((c) => c.name === name || c.machineName === StringUtils.camelCase(name)),
    [columns],
  );

  /**
   * On reordering columns, actually reorder columns in the definition,
   * and reorder rows accordingly.
   */
  const onReorderColumns = useCallback(
    (newColumnsOrder: string[]) => {
      // Building a hashmap, then rebuilding the array on the newColumnsOrder.
      const columnsMap = keyBy(columns, 'machineName');
      const newColumns = newColumnsOrder.map((columnName) => columnsMap[columnName]);

      setColumns(newColumns);
      setRows(rebuildRows(computedRows, newColumns));
    },
    [columns, computedRows, setRows, setColumns],
  );

  const onAddColumn = useCallback(
    (referenceColumnName: string, diff: number) => {
      // Finding the reference column index, add a column beside it.
      const referenceColumnIndex = columns.findIndex((c) => c.machineName === referenceColumnName);
      const newColumns = [...columns];

      let newColumnName = '';
      let columnIndex = 1;

      // Build a new column depending on existing ones to not have the same name / machineName
      // User columnIndex to defined both the new column name AND the max loop interations for safe guard
      do {
        newColumnName = `New Column ${columnIndex}`;
        columnIndex++;
      } while (isColumnExists(newColumnName) || columnIndex > 30);

      // Diff is 0 if we need to replace the current column, 1 if we should put it on its right.
      newColumns.splice(referenceColumnIndex + diff, 0, {
        name: newColumnName,
        machineName: StringUtils.camelCase(newColumnName),
        format: FormatsEnum.number,
      });

      setColumns(newColumns);
      setRows(rebuildRows(computedRows, newColumns));
    },
    [columns, computedRows, setRows, setColumns, isColumnExists],
  );

  const onDeleteColumn = useCallback(
    (referenceColumnName: string) => {
      // Don't delete the last column (safeguard)
      if (columns.length < 2) {
        return;
      }
      const referenceColumnIndex = columns.findIndex((c) => c.machineName === referenceColumnName);
      const newColumns = [...columns];
      newColumns.splice(referenceColumnIndex, 1);

      setColumns(newColumns);
      setRows(rebuildRows(computedRows, newColumns));
    },
    [columns, computedRows, setColumns, setRows],
  );

  const onChangeColumnFormat = useCallback(
    (columnName: string, newFormat: FormatsEnum) => {
      // Change the format of the column.
      const newColumns = columns.map((c) =>
        c.machineName === columnName
          ? {
              ...c,
              format: newFormat,
            }
          : c,
      );

      setColumns(newColumns);
    },
    [columns, setColumns],
  );

  const [columnToRename, setColumnToRename] = useState<TableBuilderColumn | undefined>(undefined);

  const onRenameColumn = useCallback(
    (columnMachineName) => {
      setColumnToRename(columns.find((c) => c.machineName === columnMachineName));
    },
    [setColumnToRename, columns],
  );

  const onCloseRenameRowModal = useCallback(() => {
    setColumnToRename(undefined);
  }, [setColumnToRename]);

  const onSubmitRenameColumn = useCallback(
    (newName: string) => {
      if (isColumnExists(newName)) {
        snackError(
          <FormattedMessage defaultMessage="Another column with the same name exists. Column names must be unique." />,
        );
        return;
      }

      const previousMachineName = columnToRename.machineName;
      const newMachineName = StringUtils.camelCase(newName);

      const newColumns = columns.map((c) =>
        c.machineName === previousMachineName
          ? {
              ...c,
              machineName: newMachineName,
              name: newName,
            }
          : c,
      );

      const newRows = computedRows.map((row) => ({
        ...row,
        [newMachineName]: row[previousMachineName],
        [previousMachineName]: undefined,
      }));

      setColumns(newColumns);
      setRows(rebuildRows(newRows, newColumns));
      onCloseRenameRowModal();
    },
    [columnToRename, columns, computedRows, setColumns, setRows, onCloseRenameRowModal, snackError, isColumnExists],
  );

  // ====================== ROWS MANAGEMENT ======================

  const onAddRow = useCallback(
    (referenceRowIndex: number, diff: number) => {
      const newRows = [...computedRows];
      // Diff is 0 if we need to replace the current row, 1 if we should put it under it.
      newRows.splice(referenceRowIndex + diff, 0, {});
      setRows(rebuildRows(newRows, columns));
    },
    [computedRows, columns, setRows],
  );

  const onDeleteRow = useCallback(
    (referenceRowIndex: number) => {
      // Don't delete the last row (safeguard)
      if (computedRows.length < 2) {
        return;
      }

      const newRows = [...computedRows];
      newRows.splice(referenceRowIndex, 1);
      setRows(rebuildRows(newRows, columns));
    },
    [computedRows, columns, setRows],
  );

  // ====================== RENDER ======================

  const contextContent = useShallowObjectMemo({
    onAddColumn,
    onDeleteColumn,
    onChangeColumnFormat,
    onAddRow,
    onDeleteRow,
    onRenameColumn,
    nbColumns: computedColumns.length,
    nbRows: computedRows.length,
    isReadonly: !!isReadonly,
    shouldFormatCellValue: !!shouldFormatCellValue,
  });

  return (
    <TableBuilderContext.Provider value={contextContent}>
      <Grid
        columns={computedColumns}
        rows={computedRows}
      >
        {!isReadonly && <DragDropProvider />}
        {!isReadonly && (
          <EditingState
            columnExtensions={COLUMN_EXTENSIONS}
            onCommitChanges={onCommitChanges}
          />
        )}
        <Table
          cellComponent={TableBuilderCell}
          columnExtensions={COLUMN_EXTENSIONS}
        />
        {!isReadonly && (
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={onReorderColumns}
          />
        )}
        <TableHeaderRow cellComponent={TableBuilderCellHeader} />
        {!isReadonly && (
          <TableInlineCellEditing
            selectTextOnEditStart
            startEditAction="click"
          />
        )}
      </Grid>
      <TableBuilderRenameColumnModal
        columnToRename={columnToRename}
        onCancel={onCloseRenameRowModal}
        onSubmit={onSubmitRenameColumn}
      />
    </TableBuilderContext.Provider>
  );
});
