import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';

import { type PlanRuleChart } from '@amal-ia/compensation-definition/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { extractChartConfiguration, type ExtractedChartConfiguration } from '@amal-ia/frontend/web-data-layers';
import { type VariableFormatOptionsTable } from '@amal-ia/lib-types';
import { type CardinalChartRowWithNumberOnly, Text, useStatementDetailContext, TextType } from '@amal-ia/lib-ui';
import { TargetAchievementChart } from '@amal-ia/lib-ui-business';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  chart: {
    width: '50%',
    padding: theme.spacing(5, 5, 5, 9),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',

    '@media (max-width: 1600px)': {
      width: '100%',
    },
  },
}));

interface ChartItemProps {
  chart: PlanRuleChart;
}

const ChartItem = memo(function ChartItem({ chart }: ChartItemProps) {
  const classes = useStyles();
  const { snackError } = useSnackbars();

  const statement = useStatementDetailContext();

  const extractedChartConfiguration: ExtractedChartConfiguration | null = useMemo(() => {
    const result = extractChartConfiguration(statement, chart);
    if (result?.error) {
      snackError(result.error);
      return null;
    }
    return result;
  }, [chart, statement, snackError]);

  const labelsToAdd = useMemo(() => {
    const result: CardinalChartRowWithNumberOnly[] = [];

    if (extractedChartConfiguration?.values?.table?.variable?.name) {
      result.push({
        x: 0,
        value: 0,
        valueLabel: extractedChartConfiguration.values.table.variable.name,
      });
    }

    if (extractedChartConfiguration?.values?.target?.variable?.name) {
      result.push({
        x: 325,
        value: 415,
        valueLabel: extractedChartConfiguration.values.target.variable.name,
      });
    }

    return result;
  }, [extractedChartConfiguration]);

  const [xAxisFormat, yAxisFormat]: ['number' | 'percent', 'number' | 'percent'] = useMemo(() => {
    const tableFormat = (
      extractedChartConfiguration?.values?.table?.variable?.formatOptions as VariableFormatOptionsTable
    )?.columns?.[2]?.format;
    return [
      extractedChartConfiguration?.values?.target.variable.format === FormatsEnum.percent ? 'percent' : 'number',
      tableFormat === FormatsEnum.percent ? 'percent' : 'number',
    ];
  }, [extractedChartConfiguration]);

  if (!extractedChartConfiguration?.values) {
    return null;
  }

  return (
    <Box className={classes.chart}>
      {chart.name ? <Text type={TextType.SUBTITLE}>{chart.name}</Text> : null}
      <TargetAchievementChart
        labelsToAdd={labelsToAdd}
        mode={extractedChartConfiguration.values.mode}
        table={extractedChartConfiguration.values.table.value}
        value={extractedChartConfiguration.values.target.value}
        xAxisFormat={xAxisFormat}
        yAxisFormat={yAxisFormat}
      />
    </Box>
  );
});

export default ChartItem;
