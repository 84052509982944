import { Table } from '@devexpress/dx-react-grid-material-ui';
import clsx from 'clsx';
import { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentStatement, selectCurrentStatementThreads } from '@amal-ia/frontend/web-data-layers';

import { isAThreadOnARow } from './RowsTable.utils';
import RowsTableContext from './RowsTableContext';
import useRowsTableStyles from './useRowsTableStyles';

const RowsTableRow = memo(function RowsTableRow({ row, ...restProps }: Table.DataRowProps) {
  const classes = useRowsTableStyles();
  const { dataset, tracingExternalId, rowsToHighlight, ruleId } = useContext(RowsTableContext);

  const statementThreads = useSelector(selectCurrentStatementThreads);
  const currentStatement = useSelector(selectCurrentStatement);

  const isThreadOnRow = useMemo(
    () => isAThreadOnARow(statementThreads, row, ruleId, currentStatement, dataset),
    [statementThreads, row, ruleId, currentStatement, dataset],
  );

  const isRowHighlighted = (row?.overwrites && row.overwrites.length > 0) || isThreadOnRow;
  const isRowSelected =
    tracingExternalId === row?.externalId || (rowsToHighlight || []).includes(row?.externalId || '');

  return (
    <Table.Row
      row={row}
      className={clsx(
        classes.tableRow,
        isRowHighlighted && classes.rowHighlight,
        isRowSelected && classes.rowSelected,
        row.isAdditionalRow && classes.isAdditionalRow,
      )}
      {...restProps}
    />
  );
});

export default RowsTableRow;
