import { IconUserOff } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography, type TypographyProps } from '@amal-ia/frontend/design-system/components';

import { accentuatedLabel, dimmedLabel } from '../../FieldValuePrettyFormat.styles';
import { LabelVariant } from '../../types';

import { messages } from './UserNullValue.messages';
import { flex, nullIcon, TYPOGRAPHY_VARIANT } from './UserNullValue.styles';

export type UserNullValueProps = Omit<TypographyProps, 'variant'> & {
  variant?: LabelVariant;
};

export const UserNullValue = memo(function UserNullValue({
  variant = LabelVariant.DIMMED,
  ...props
}: UserNullValueProps) {
  // DEFAULT and BOLD variants are not supported
  const supportedVariant =
    variant === LabelVariant.DEFAULT || variant === LabelVariant.BOLD ? LabelVariant.DIMMED : variant;

  return (
    <Typography
      variant={TYPOGRAPHY_VARIANT[supportedVariant]}
      css={[
        flex,
        supportedVariant === LabelVariant.DIMMED && dimmedLabel,
        supportedVariant === LabelVariant.ACCENTUATED && accentuatedLabel,
      ]}
      {...props}
    >
      <IconUserOff css={nullIcon} />
      <span>
        <FormattedMessage {...messages.NONE} />
      </span>
    </Typography>
  );
});
