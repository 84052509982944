import { type ForwardedRef, forwardRef } from 'react';

import { type FormikFieldProps, useFormikFieldAdapter } from '@amal-ia/ext/formik';

import { type InputTypeProp } from '../../../../../forms/input/formik-input/FormikInput';
import { CellTextField, type CellTextFieldProps } from '../CellTextField';

export type FormikCellTextFieldProps<TType extends InputTypeProp = 'text'> = FormikFieldProps<
  CellTextFieldProps,
  TType
>;

const FormikCellTextFieldForwardRef = forwardRef(function FormikCellTextField<TType extends InputTypeProp = 'text'>(
  {
    validate, // Omit validate and any props not passed to CellTextField.
    type = 'text' as TType,
    ...props
  }: FormikCellTextFieldProps<TType>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { onChange, ...formikFieldProps } = useFormikFieldAdapter<CellTextFieldProps['value'], TType>({
    validate,
    type,
    ...props,
  });

  return (
    <CellTextField
      {...props}
      {...formikFieldProps}
      ref={ref}
      type={type}
      onChange={onChange as (value: string) => void}
    />
  );
});

export const FormikCellTextField = FormikCellTextFieldForwardRef as <TType extends InputTypeProp = 'text'>(
  props: FormikCellTextFieldProps<TType> & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof FormikCellTextFieldForwardRef>;
