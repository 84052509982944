import { css } from '@emotion/react';
import { capitalize } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertBanner, AlertBannerVariant } from '@amal-ia/frontend/design-system/components';
import { type Calculation, formatDate } from '@amal-ia/lib-types';

interface CalculationErrorProps {
  calculation: Calculation;
  showDebug?: boolean;
}

export const CalculationError = memo(function CalculationError({ calculation, showDebug }: CalculationErrorProps) {
  return (
    <div
      css={css`
        margin-top: 16px;
      `}
    >
      <AlertBanner variant={AlertBannerVariant.ERROR}>
        <p>
          <FormattedMessage
            defaultMessage="An error happened during the last calculation / {calculationDate} / {calculationError}"
            values={{
              calculationDate: formatDate(calculation.createdAt, 'lll'),
              calculationError: (
                <em>
                  {calculation.error || (
                    <FormattedMessage defaultMessage="No error to display, please contact your administrator." />
                  )}
                </em>
              ),
            }}
          />
        </p>

        {!!showDebug && (
          <p
            css={css`
              list-style: none;
              margin-top: 8px;
            `}
          >
            {Object.entries(calculation.errorMetadata || {})
              .filter(([k]) => k !== 'message')
              .map(([k, v], index, elts) => (
                <span key={k}>
                  {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                  <u>{capitalize(k)}</u>: {v}
                  {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                  {index + 1 !== elts.length && ' / '}
                </span>
              ))}
          </p>
        )}
      </AlertBanner>
    </div>
  );
});
