import { type MentionOptions } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import invariant from 'invariant';
import { useCallback, useMemo, useRef } from 'react';
import tippy, { type Instance as TippyInstance, type Props as TippyProps } from 'tippy.js';

import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { type ForwardedMentionListRef, MentionList, type MentionListProps } from '../MentionList';

export type Suggestion = Required<MentionOptions['suggestion']>;

/**
 * Inspired from example here
 * @see {@link} https://tiptap.dev/api/nodes/mention
 */
export const useSuggestions = (statementUsers: Partial<UserContract>[] = []) => {
  const isOpen = useRef<boolean>(false);

  const items: Suggestion['items'] = useCallback(
    ({ query }) => {
      const q = query.toLowerCase();

      return statementUsers.filter(
        ({ firstName, lastName }) => firstName?.toLowerCase().startsWith(q) || lastName?.toLowerCase().startsWith(q),
      );
    },
    [statementUsers],
  );

  const render: Suggestion['render'] = useCallback(() => {
    let component: ReactRenderer<ForwardedMentionListRef, MentionListProps> | null = null;
    let popup: TippyInstance<TippyProps> | null = null;

    return {
      onStart: (props) => {
        component = new ReactRenderer<ForwardedMentionListRef, MentionListProps>(MentionList, {
          props,
          editor: props.editor,
          attrs: { style: 'width: 230px;' },
        });

        if (!props.clientRect) {
          return;
        }

        [popup] = tippy('body', {
          getReferenceClientRect: () => props.clientRect?.() ?? document.body.getBoundingClientRect(),
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });

        isOpen.current = true;
      },

      onUpdate: (props) => {
        invariant(component, 'component should have been set with onStart');

        component.updateProps(props);

        invariant(popup, 'popup should have been set with onStart');
        popup.setProps({
          getReferenceClientRect: () => props.clientRect?.() ?? document.body.getBoundingClientRect(),
        });
      },

      onKeyDown: (props) => {
        invariant(component, 'component should have been set with onStart');
        invariant(popup, 'popup should have been set with onStart');

        if (props.event.key === 'Escape') {
          popup.hide();

          return true;
        }

        return component.ref?.onKeyDown(props) ?? false;
      },

      onExit: () => {
        isOpen.current = false;

        invariant(popup, 'popup should have been set with onStart');
        popup.destroy();

        invariant(component, 'component should have been set with onStart');
        component.destroy();
      },
    };
  }, []);

  /**
   * @see {@link} https://tiptap.dev/api/utilities/suggestion
   */
  const suggestion: MentionOptions['suggestion'] = useMemo(
    () => ({
      /**
       * Allows or disallows spaces in suggested items.
       *
       * Default: false
       */
      allowSpaces: false,
      /**
       * The character that triggers the autocomplete popup.
       *
       * Default: '@'
       */
      char: '@',
      /**
       * The prefix characters that are allowed to trigger a suggestion.
       * Set to null to allow any prefix character.
       *
       * Default: [' ']
       *
       * We use null to allow mentions to be separated by a space /shrug
       * https://gitlab.com/amal-ia/amalia-web/-/issues/5656
       */
      allowedPrefixes: null,
      /**
       * Pass an array of filtered suggestions, can be async.
       *
       * Default: ({ editor, query }) => []
       */
      items,
      /**
       * render
       * A render function for the autocomplete popup.
       *
       * Default: () => ({})
       */
      render,
    }),
    [items, render],
  );

  return {
    suggestion,
    isOpen,
  };
};
