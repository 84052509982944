import { Checkbox, List, ListItem, ListItemText, Popover } from '@mui/material';
import { IconEye } from '@tabler/icons-react';
import {
  type ChangeEvent,
  type Dispatch,
  memo,
  type MouseEvent,
  type SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@amal-ia/frontend/design-system/components';

import { type ChartSeries } from '../chartSeries';

import { seriesSelectorMessages } from './SeriesSelector.messages';

type SeriesSelectorProps = Readonly<{
  seriesMetadata: Record<string, ChartSeries>;
  setHiddenSeries: Dispatch<SetStateAction<string[]>>;
}>;

/**
 * Component to select the series to display in the chart.
 */
export const SeriesSelector = memo(function SeriesSelector({ seriesMetadata, setHiddenSeries }: SeriesSelectorProps) {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle the selection of a series.
   */
  const handleSelectSeries = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setHiddenSeries((prevState) => {
        if (prevState?.includes(value)) {
          return prevState.filter((option) => option !== value);
        }
        return [...prevState, value];
      });
    },
    [setHiddenSeries],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (Object.values(seriesMetadata).length <= 1) {
    return null;
  }

  return (
    <div>
      <IconButton
        withBackground
        icon={<IconEye />}
        label={formatMessage(seriesSelectorMessages.CHOOSE_SERIES)}
        onClick={handleButtonClick}
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <div>
          <List>
            {Object.values(seriesMetadata).map((series) => (
              <ListItem
                key={series.name}
                dense
              >
                <Checkbox
                  checked={series.selected}
                  value={series.name}
                  onChange={handleSelectSeries}
                />
                <ListItemText primary={series.label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
});
