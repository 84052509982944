/* eslint-disable react/no-multi-comp */
import { Box, Card, CardContent, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import moment from 'moment';
import { memo, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { OverwriteTooltip } from '@amal-ia/data-capture/overwrites/components';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR, amaliaTheme, type AmaliaThemeType, colors } from '@amal-ia/ext/mui/theme';
import {
  formatTotal,
  formatUserFullName,
  formatValueOrPrintRemovedLabel,
  formatValueTotal,
  type Overwrite,
  TracingTypes,
} from '@amal-ia/lib-types';
import { Text, TextType } from '@amal-ia/lib-ui';
import { type ComputedOverwrite } from '@amal-ia/payout-calculation/shared/types';

import { useTracingStyles } from './useTracingStyles';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    position: 'relative',
    minHeight: '8rem',
    display: 'flex',
    alignItems: 'stretch',
    width: '90%',
    margin: `0 ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)}`,
  },
  titleRotate: {
    transformOrigin: 'center center',
    transform: 'rotate(-90deg)',
  },
  formula: {
    flex: 1,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'calc(100% - 3rem)',
  },
  titleContainer: {
    padding: `0 ${theme.spacing(0.25)} 0 ${theme.spacing(0.25)}`,
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    flex: 'none',
  },
  title: {
    whiteSpace: 'nowrap',
  },

  // Styles according to the tracing block type
  [`titleContainer_${TracingTypes.TracingBlockType.FILTER}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.filter,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.FUNCTION}`]: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  [`titleContainer_${TracingTypes.TracingBlockType.CUSTOM_OBJECT}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.RULE}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.rule,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.STATEMENT}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.statement,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.TABLE}`]: {
    borderColor: colors['blue-500'],
    color: colors['blue-500'],
  },
  [`titleContainer_${TracingTypes.TracingBlockType.USER}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.user,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.TEAM}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.team,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.PLAN}`]: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.plan,
    color: 'rgba(0, 0, 0, .4)',
  },
  [`titleContainer_${TracingTypes.TracingBlockType.VARIABLE}`]: {
    color: amaliaTheme.palette.link.main,
  },
  [`titleContainer_${TracingTypes.TracingBlockType.ROW_MARGINAL}`]: {
    backgroundColor: colors['orange-100'],
    color: 'rgba(0, 0, 0, .4)',
  },
}));

interface TracingBlockProps {
  type: TracingTypes.TracingBlockType;
  children: ReactElement<any, any> | null;
  value?: string;
  overwrite?: Overwrite;
  machineName?: string;
  options?: { customObjectMachineName: string };
  formula?: string;
  statementCurrency: CurrencySymbolsEnum;
  format?: FormatsEnum;
}

interface TracingBlockValueProps {
  value?: string;
  overwrite?: ComputedOverwrite | Overwrite;
  machineName?: string;
  statementCurrency: CurrencySymbolsEnum;
  format?: FormatsEnum;
}

const TracingBlockValue = function TracingBlockValue({
  value,
  overwrite,
  machineName,
  statementCurrency,
  format,
}: TracingBlockValueProps) {
  if (!!overwrite && !!machineName) {
    // Check if row overwrite or computed overwrite
    if ((overwrite as Overwrite).appliesToExternalId) {
      return (
        <OverwriteTooltip
          isReadOnly
          author={formatUserFullName((overwrite as Overwrite).creator)}
          date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
          valueFormat={format}
          newValue={
            overwrite.overwriteValue[machineName]?.symbol
              ? formatTotal(
                  overwrite.overwriteValue[machineName].value,
                  FormatsEnum.currency,
                  overwrite.overwriteValue[machineName].symbol,
                  1,
                )
              : formatValueTotal(overwrite.overwriteValue[machineName]?.value || overwrite.overwriteValue[machineName])
          }
          oldValue={
            overwrite.sourceValue[machineName]?.symbol
              ? formatTotal(
                  overwrite.sourceValue[machineName].value,
                  FormatsEnum.currency,
                  overwrite.sourceValue[machineName].symbol,
                  1,
                )
              : formatValueTotal(overwrite.sourceValue[machineName]?.value || overwrite.sourceValue[machineName])
          }
        >
          <Text type={TextType.NORMAL_TEXT}>{`= ${formatValueOrPrintRemovedLabel(value)}`}</Text>
        </OverwriteTooltip>
      );
    }
    // Computed overwrite
    return (
      <OverwriteTooltip
        isReadOnly
        author={(overwrite as ComputedOverwrite).creator}
        date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        valueFormat={format}
        newValue={
          format
            ? formatTotal(overwrite.overwriteValue, format, statementCurrency, 1)
            : formatValueTotal(overwrite.overwriteValue)
        }
        oldValue={
          format
            ? formatTotal(overwrite.sourceValue, format, statementCurrency, 1)
            : formatValueTotal(overwrite.sourceValue)
        }
      >
        <Text type={TextType.NORMAL_TEXT}>{`= ${formatValueOrPrintRemovedLabel(value)}`}</Text>
      </OverwriteTooltip>
    );
  }

  return <Text type={TextType.NORMAL_TEXT}>{`= ${formatValueOrPrintRemovedLabel(value)}`}</Text>;
};

/**
 * TracingBlock
 * Details a specific tracing formula, along with its type
 */
export const TracingBlock = memo(function TracingBlock({
  type,
  children,
  value,
  formula,
  overwrite,
  machineName,
  options,
  statementCurrency,
  format,
}: TracingBlockProps) {
  const classes = useStyles();
  const tracingStyles = useTracingStyles();

  const tracingBlockTypeValue =
    type === TracingTypes.TracingBlockType.CUSTOM_OBJECT && options?.customObjectMachineName
      ? capitalize(options.customObjectMachineName)
      : capitalize(type);

  return (
    <Card
      className={classes.root}
      data-testid={`Trace formula: ${formula}`}
    >
      <Box
        alignItems="center"
        className={clsx(classes.titleContainer, classes[`titleContainer_${type}`])}
        display="flex"
        m={2}
      >
        <Box
          className={classes.titleRotate}
          display="flex"
          height="1rem"
          justifyContent="center"
          maxWidth="1rem"
          width="1rem"
        >
          <Box
            className={classes.title}
            display="block"
          >
            {tracingBlockTypeValue}
          </Box>
        </Box>
      </Box>
      <CardContent className={classes.formula}>
        <Grid
          container
          alignItems="center"
          className={tracingStyles.result}
          direction="row"
          justifyContent="center"
        >
          {overwrite ? <FormattedMessage defaultMessage="This value was overwritten" /> : children}
        </Grid>
        {value ? (
          <div className={tracingStyles.total}>
            <TracingBlockValue
              format={format}
              machineName={machineName}
              overwrite={overwrite}
              statementCurrency={statementCurrency}
              value={value}
            />
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
});
