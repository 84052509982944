// eslint-disable-next-line no-restricted-imports -- ONLY ALLOWED HERE
import { Box, DialogTitle } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { memo, type ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@amal-ia/frontend/design-system/components';

interface DialogTitleWithCloseButtonProps {
  handleClose: () => any;
  children?: ReactNode | string;
  id?: string;
  className?: string;
}

export const DialogTitleWithCloseButton = memo(function DialogTitleWithCloseButton({
  handleClose,
  children,
  ...props
}: DialogTitleWithCloseButtonProps) {
  const { formatMessage } = useIntl();

  return (
    <DialogTitle {...props}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
      >
        <Box
          flex={1}
          textAlign="center"
        >
          {children}
        </Box>
        <Box>
          <IconButton
            icon={<IconX />}
            label={formatMessage({ defaultMessage: 'Close modal' })}
            onClick={handleClose}
          />
        </Box>
      </Box>
    </DialogTitle>
  );
});
