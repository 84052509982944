import { type ForwardedRef, forwardRef, memo } from 'react';

import { Button, type ButtonProps } from '../../../general/button/Button';

export type ActionProps = Omit<ButtonProps, 'size'>;

const ActionForwardRef = forwardRef(function Action(
  { variant = Button.Variant.SECONDARY_LIGHT, children, ...props }: ActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      {...props}
      ref={ref}
      size={Button.Size.MEDIUM}
      variant={variant}
    >
      {children}
    </Button>
  );
});

export const Action = memo(ActionForwardRef);
