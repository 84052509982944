import { defineMessages } from 'react-intl';

export const addRecordsModalMessages = defineMessages({
  ADD_RECORDS: { defaultMessage: 'Add record(s)' },
  ADD_ONE_SEVERAL_RECORDS: { defaultMessage: 'Add one/several of your record(s) and simulate your results.' },
  NO_RECORD_FOUND: { defaultMessage: 'No records found.' },
  SEARCH_RECORDS: {
    defaultMessage:
      'Search a record <strong>by name</strong> or <strong>by ID</strong>{br}' +
      'then <strong>select</strong> it to add it to your statement.',
  },
  SELECTED_RECORDS: { defaultMessage: 'Selected records' },
});
