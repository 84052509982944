import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { type TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { colors } from '@amal-ia/ext/mui/theme';
import { IconButton, type TablerIconElement } from '@amal-ia/frontend/design-system/components';

import { Tooltip } from '../Tooltip/Tooltip';

const useStyles = makeStyles({
  root: {
    width: '10rem',
    padding: '.5rem',
    height: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '1rem',
    backgroundColor: colors['grey-100'],
  },
  contentWrapper: {
    minWidth: '9rem',
  },
  leftActionButton: {
    padding: 0,
  },
});

interface PopContentProps {
  title: ReactNode | string;
  content: ReactNode;
  popDirection: 'bottom' | 'top';
  tooltipProps?: Partial<TooltipProps>;
  leftAction?: {
    tooltipTitle: string;
    icon: ReactNode;
    callback: () => void;
  };
}

export const PopContent = memo(function PopContent({
  title,
  content,
  popDirection,
  tooltipProps,
  leftAction,
}: PopContentProps) {
  const classes = useStyles();
  return (
    <Tooltip
      placement={popDirection}
      title={<div className={classes.contentWrapper}>{content}</div>}
      {...(tooltipProps || {})}
    >
      <div className={classes.root}>
        {leftAction ? (
          <IconButton
            className={classes.leftActionButton}
            icon={leftAction.icon as TablerIconElement}
            label={leftAction.tooltipTitle}
            onClick={leftAction.callback}
          />
        ) : null}
        <span>{title}</span>
        {popDirection === 'top' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
    </Tooltip>
  );
});
