import { defineMessages } from 'react-intl';

export const payoutModeButtonMessages = defineMessages({
  LAST_YEAR: {
    defaultMessage: 'Last year',
  },
  CURRENT_YEAR: {
    defaultMessage: 'Current year',
  },
});
