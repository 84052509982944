import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amal-ia/frontend/design-system/components';
import { ShadowVariant } from '@amal-ia/frontend/design-system/meta';
import { DrawingNotInvited, LayoutAnonymous } from '@amal-ia/lib-ui';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  margin: auto;
  margin-top: 64px;
  padding: 40px 40px 56px 40px;
  box-shadow: ${({ theme }) => theme.ds.shadows[ShadowVariant.SOFT]};
  background-color: ${({ theme }) => theme.ds.colors.gray[0]};
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  gap: 46px;
`;

export const AuthenticationNotInvited = memo(function AuthenticationNotInvited() {
  return (
    <LayoutAnonymous>
      <Container>
        <DrawingNotInvited />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 24px;
          `}
        >
          <Typography
            as="p"
            variant={Typography.Variant.HEADING_4_BOLD}
          >
            <FormattedMessage defaultMessage="Unfortunately, you are not yet invited to Amalia." />
          </Typography>
          <div>
            <Typography
              as="p"
              variant={Typography.Variant.BODY_LARGE_MEDIUM}
            >
              <FormattedMessage defaultMessage="Your account is being set-up :)" />
            </Typography>
            <Typography
              as="p"
              variant={Typography.Variant.BODY_LARGE_REGULAR}
            >
              <FormattedMessage defaultMessage="Please retry after you receive an invitation from us (check your spams) or ask your Amalia Admin for help." />
            </Typography>
          </div>
        </div>
      </Container>
    </LayoutAnonymous>
  );
});
