import { memo } from 'react';

import { isUserHaveCompany } from '@amal-ia/frontend/web-data-layers';

import { SpecialRoute } from './SpecialRoute';
import { type UserConditionedRouteProps } from './userConditionedRouteProps';

const CompanyRouteBase = function CompanyRoute<TPath extends string = string>({
  user,
  shouldRedirectToHome,
  ...props
}: UserConditionedRouteProps<TPath>) {
  return (
    <SpecialRoute
      shouldRedirectToHome={!isUserHaveCompany(user) || shouldRedirectToHome}
      {...props}
    />
  );
};

export const CompanyRoute = memo(CompanyRouteBase) as typeof CompanyRouteBase;
