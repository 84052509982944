import { DndContext, type DndContextProps } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IconArrowsSort } from '@tabler/icons-react';
import invariant from 'invariant';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Dropdown } from '../../../overlays/dropdown/Dropdown';
import { getMenuColumnName } from '../columns';
import { useDataGridContext } from '../DataGrid.context';
import { SortDirection, type ColumnSortingState } from '../DataGrid.types';

import {
  ColumnSortingMenuItem,
  type ColumnSortingMenuItemProps,
} from './column-sorting-menu-item/ColumnSortingMenuItem';
import { ColumnSortingSingle } from './column-sorting-single/ColumnSortingSingle';
import * as styles from './ColumnSorting.styles';
import { useSortableColumns } from './hooks/useSortableColumns';

export type ColumnSortingProps = {
  /** Sort state. */
  columnSorting: ColumnSortingState;
  /** Sort state change handler. */
  onChangeColumnSorting: (sort: ColumnSortingState) => void;
};

const ColumnSortingBase = memo(function ColumnSorting({ columnSorting, onChangeColumnSorting }: ColumnSortingProps) {
  const { columns } = useDataGridContext();
  const { formatMessage } = useIntl();
  const [searchText, setSearchText] = useState('');

  const sortableColumns = useSortableColumns(columns, searchText);

  /** List of columns that are not yet used for sorting. */
  const availableColumns = useMemo(
    () => sortableColumns.filter((column) => !columnSorting.some((columnSort) => columnSort.id === column.id)),
    [sortableColumns, columnSorting],
  );

  /** Columns used for sorting, in sorting order. */
  const sortedColumns = useMemo(
    () =>
      columnSorting.map((columnSort) => sortableColumns.find((column) => column.id === columnSort.id)).filter(Boolean),
    [sortableColumns, columnSorting],
  );

  const handleChangeActivated: ColumnSortingMenuItemProps['onChangeActivated'] = useCallback(
    (id, isActivated) =>
      onChangeColumnSorting(
        isActivated
          ? [...columnSorting, { id, direction: SortDirection.ASC }]
          : columnSorting.filter((columnSort) => columnSort.id !== id),
      ),
    [onChangeColumnSorting, columnSorting],
  );

  const handleChangeDirection: Required<ColumnSortingMenuItemProps>['onChangeDirection'] = useCallback(
    (id, direction) =>
      onChangeColumnSorting(
        columnSorting.map((columnSort) => (columnSort.id === id ? { ...columnSort, direction } : columnSort)),
      ),
    [onChangeColumnSorting, columnSorting],
  );

  const handleDragEnd: Required<DndContextProps>['onDragEnd'] = useCallback(
    ({ active, over }) => {
      const currentIndex = columnSorting.findIndex((columnSort) => columnSort.id === active.id);
      const targetIndex = columnSorting.findIndex((columnSort) => columnSort.id === over?.id);
      onChangeColumnSorting(arrayMove(columnSorting, currentIndex, targetIndex));
    },
    [onChangeColumnSorting, columnSorting],
  );

  const dndItems = useMemo(() => columnSorting.map(({ id }) => id), [columnSorting]);

  return (
    <Dropdown
      title={<FormattedMessage defaultMessage="Sort by" />}
      content={
        sortedColumns.length || availableColumns.length ? (
          <Fragment>
            {!!sortedColumns.length && (
              <DndContext
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={dndItems}
                  strategy={verticalListSortingStrategy}
                >
                  <ul css={styles.listContainer}>
                    {sortedColumns.map((column) => {
                      const sortDirection = columnSorting.find((columnSort) => columnSort.id === column.id)?.direction;
                      invariant(sortDirection, 'The filtering ensured that all sortedColumns have a sort direction.');

                      return (
                        <ColumnSortingMenuItem
                          key={column.id}
                          isActivated
                          direction={sortDirection}
                          disableReordering={!!searchText || sortedColumns.length === 1}
                          icon={column.icon}
                          id={column.id}
                          label={getMenuColumnName(column)}
                          onChangeActivated={handleChangeActivated}
                          onChangeDirection={handleChangeDirection}
                        />
                      );
                    })}
                  </ul>
                </SortableContext>
              </DndContext>
            )}

            {!!(sortedColumns.length && availableColumns.length) && <Dropdown.Divider />}

            {!!availableColumns.length && (
              <ul css={styles.listContainer}>
                {availableColumns.map((column) => (
                  <ColumnSortingMenuItem
                    key={column.id}
                    id={column.id}
                    isActivated={false}
                    label={getMenuColumnName(column)}
                    onChangeActivated={handleChangeActivated}
                  />
                ))}
              </ul>
            )}
          </Fragment>
        ) : null
      }
      searchInput={
        <Dropdown.SearchInput
          placeholder={formatMessage({ defaultMessage: 'Search column' })}
          value={searchText}
          onChange={setSearchText}
        />
      }
    >
      <Dropdown.IconButton
        withBackground
        icon={<IconArrowsSort />}
        label={formatMessage({ defaultMessage: 'Sorting' })}
      />
    </Dropdown>
  );
});

export const ColumnSorting = Object.assign(ColumnSortingBase, {
  Single: ColumnSortingSingle,
});
