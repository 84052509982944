import clsx from 'clsx';
import { type ReactNode, memo } from 'react';

import { useFormLayoutContext } from '../../../layout/form-layout/FormLayout.context';
import { FormLayoutDirection } from '../../../layout/form-layout/FormLayout.types';
import { FormFieldError } from '../form-field-error/FormFieldError';
import { FormFieldLabel, type FormFieldLabelProps } from '../form-field-label/FormFieldLabel';
import { FieldSize, LabelPosition } from '../types';

import * as styles from './FormField.styles';

export type FormFieldProps = {
  /** Field id. */
  id: string;
  /** Field size. */
  size?: FieldSize;
  /** Position of label if specified. */
  labelPosition?: LabelPosition;
  /** Override FormLayout's direction. */
  direction?: FormLayoutDirection;
  /** Is the field disabled. */
  disabled?: boolean;
  /** Field label. */
  label?: FormFieldLabelProps['children'];
  /** Help message. */
  tooltip?: FormFieldLabelProps['tooltip'];
  /** Is the field required. */
  required?: FormFieldLabelProps['required'];
  /** Error message. */
  error?: ReactNode;
  /** Should show error if defined. */
  showError?: boolean;
  /** Field. */
  children?: ReactNode;
  /** Arbitrary field type. Used by FormLayout. */
  formFieldType?: string;
};

const FormFieldBase = memo(function FormField({
  id,
  size = FieldSize.MEDIUM,
  labelPosition = LabelPosition.DEFAULT,
  direction: propsDirection,
  disabled = false,
  label = null,
  error = null,
  showError = true,
  required = false,
  tooltip = null,
  formFieldType = undefined,
  children = null,
}: FormFieldProps) {
  // Direction from props overrides direction from context. Defaults to vertical direction.
  const { direction: formLayoutDirection = FormLayoutDirection.VERTICAL } = useFormLayoutContext() || {};
  const direction = propsDirection || formLayoutDirection;

  return (
    <div
      css={styles.formField}
      data-form-field-type={formFieldType}
    >
      <div
        className={clsx(size, labelPosition, direction)}
        css={styles.fieldWithLabel}
      >
        {!!label && (
          <div
            className={labelPosition}
            css={styles.label}
          >
            <FormFieldLabel
              disabled={disabled}
              htmlFor={id}
              required={required}
              size={size}
              tooltip={tooltip}
            >
              {label}
            </FormFieldLabel>
          </div>
        )}

        {children}
      </div>

      {!!error && !!showError && (
        <div
          className={size}
          css={styles.error}
        >
          <FormFieldError size={size}>{error}</FormFieldError>
        </div>
      )}
    </div>
  );
});

export const FormField = Object.assign(FormFieldBase, {
  LabelPosition,
  Size: FieldSize,
});
