import { type JSONSchema7 } from 'json-schema';
import { cloneDeep, isArray } from 'lodash';

import { type Relationship } from '@amal-ia/compensation-definition/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { JSON_DATA_FORMAT } from '@amal-ia/lib-types';

import { convertDateToTimestamp } from './dates';

type ContentType = Record<string, any>;

export class RowConverterUtils {
  /**
   * Convert row content dates and currencies according to definition, so that they can be used for calculation :
   * - currency fields are converted to their value
   * - date fields are converted to timestamps
   * @param currentObjectMachineName
   * @param content
   * @param relationshipsMap
   * @param customObjectDefinitionsMap
   */
  public static convertRowDatesAndCurrencies(
    currentObjectMachineName: string,
    content: ContentType,
    relationshipsMap: Record<string, Relationship>,
    customObjectDefinitionsMap: Record<string, CustomObjectDefinition>,
  ): object {
    const customObjectDefinition = customObjectDefinitionsMap[currentObjectMachineName];
    const properties = customObjectDefinition.schema.properties as Record<string, JSONSchema7>;
    const convertedContent = cloneDeep(content);
    Object.keys(properties).forEach((key) => {
      const prop = properties[key];

      if (
        prop.format &&
        [JSON_DATA_FORMAT.DATE, JSON_DATA_FORMAT.DATE_TIME, JSON_DATA_FORMAT.TIME].includes(
          prop.format as JSON_DATA_FORMAT,
        )
      ) {
        // If property is a variable date => converts to timestamp.
        convertedContent[key] = null;
        if (content[key]) {
          convertedContent[key] =
            typeof content[key] === 'string' ? convertDateToTimestamp(content[key]) : content[key];
        }
      } else if (prop.$ref === '#/definitions/currency') {
        // TODO(#6086): create an enum for currency definition in amalia-lib-data-import
        // If it's a currency => converts to its value.
        convertedContent[key] = null;
        if (content[key]) {
          convertedContent[key] = content[key].value ? content[key].value : content[key];
        } else if (content[key] === 0) {
          convertedContent[key] = 0;
        }
      }
    });

    Object.keys(convertedContent).forEach((key) => {
      if (convertedContent[key]) {
        const relation = relationshipsMap[key];

        if (relation) {
          // 1 - search if property is a relation => if so, run convertRowDatesAndCurrencies recursively according to relation definition.
          if (isArray(content[key])) {
            convertedContent[key] = (content[key] as []).map((contentItem) =>
              RowConverterUtils.convertRowDatesAndCurrencies(
                relation.toDefinitionMachineName,
                contentItem,
                relationshipsMap,
                customObjectDefinitionsMap,
              ),
            );
          } else {
            convertedContent[key] = RowConverterUtils.convertRowDatesAndCurrencies(
              relation.toDefinitionMachineName,
              convertedContent[key],
              relationshipsMap,
              customObjectDefinitionsMap,
            );
          }
        } else if (convertedContent[key].symbol) {
          // 2 - search and convert variable object
          // If it's a variable currency => converts to its value.
          convertedContent[key] = convertedContent[key].value;
        } else if (convertedContent[key].format === FormatsEnum.date) {
          // If property is a variable date => converts to timestamp.
          convertedContent[key] = convertDateToTimestamp(convertedContent[key].value);
        }
      }
    });

    return convertedContent;
  }

  /**
   * Get a row content (row can be a filter row with a content property or
   * a relation : in case of a relation row there is no content property,
   * content is the row object itself).
   * @param row
   */
  public static getRowContent(row: any) {
    return row.content || row;
  }
}
