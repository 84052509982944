import { type AuditRecord } from '@amal-ia/backend/kernel/monitoring/audit';
import { type RootState } from '@amal-ia/lib-types';

import { type AuditFiltersOptions } from '../../types/audit';

export const selectAuditLoading = (state: RootState): boolean => !!state.audit.loading;

export const selectAuditFilters = (state: RootState): AuditFiltersOptions => state.audit.filters;
export const selectAuditRecordsData = (state: RootState): AuditRecord[] => state.audit.records;
export const selectAuditRecordsCount = (state: RootState): number => state.audit.recordsCount;
