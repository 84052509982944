import { Box } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { entries, isEmpty } from 'lodash';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '@amal-ia/frontend/design-system/components';
import { Toolbar } from '@amal-ia/lib-ui';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { AddFilterMenu } from './AddFilterMenu';
import { isFilterBarInDirtyState, resetFilterItems, updateFilterItems } from './filterBar.utils';
import { FilterBarItem, type FilterItem } from './FilterBarItem';

interface FilterBarProps {
  items: Record<string, FilterItem>;
  additionalFilters?: Record<string, FilterItem>;
  onUpdateItems: (newItems: Record<string, FilterItem>) => void;
}

export const FilterBar = memo(function FilterBar({ items, additionalFilters, onUpdateItems }: FilterBarProps) {
  const { formatMessage } = useIntl();

  /**
   * Add a new filter on the filter list.
   * load filter elements.
   * @param filterKey
   * @param additionalFilter
   */
  const addNewFilterHandler = useCallback(
    (filterKey: string, additionalFilter: FilterItem) => {
      const newFilters = {
        ...items,
        [filterKey]: additionalFilter,
      };

      onUpdateItems(newFilters);
    },
    [items, onUpdateItems],
  );

  /**
   * Reset filters: remove additional filters from filter list.
   */
  const resetHandler = useCallback(() => {
    const newItems = resetFilterItems(items, additionalFilters);

    onUpdateItems(newItems);
  }, [additionalFilters, items, onUpdateItems]);

  /**
   * Update filter value when selected.
   */
  const onSelectValue = useCallback(
    (filterKey: string, selected: string[] | UserContract[] | boolean) => {
      const newItems = updateFilterItems(items, filterKey, selected);

      onUpdateItems(newItems);
    },
    [items, onUpdateItems],
  );

  const displayResetButton = useMemo(
    () => isFilterBarInDirtyState(items, additionalFilters),
    [additionalFilters, items],
  );

  return (
    <Toolbar
      leftActions={
        <Fragment>
          {!isEmpty(items) && (
            <Fragment>
              {entries(items).map(([key, item]) =>
                item ? (
                  <Box
                    key={key}
                    alignItems="center"
                    display="inline-flex"
                    height="100%"
                    marginRight={2}
                  >
                    <FilterBarItem
                      filterKey={key}
                      item={item}
                      onChange={onSelectValue}
                    />
                  </Box>
                ) : null,
              )}
              {displayResetButton ? (
                <IconButton
                  icon={<IconX />}
                  id="resetFilters"
                  label={formatMessage({ defaultMessage: 'Reset filters' })}
                  onClick={resetHandler}
                />
              ) : null}
            </Fragment>
          )}

          {!isEmpty(additionalFilters) && (
            <AddFilterMenu
              activeFilters={items}
              additionalFilters={additionalFilters}
              onAddNewFilter={addNewFilterHandler}
            />
          )}
        </Fragment>
      }
    />
  );
});
