import { DragHandle } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  handle: {
    '& > svg': {
      transform: 'rotate(90deg)',
      color: theme.palette.divider,
      verticalAlign: 'bottom',
    },
    marginLeft: theme.spacing(-1),
  },
  listHeader: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.divider}`,
  },
}));

interface ListElementProps {
  header?: boolean;
  className?: string;
  innerRef?: any;
  handleProps?: any;
  children?: ReactNode;
  styleToApply?: object;
  style?: object;
}

export const ListElement = memo(function ListElement({
  children,
  className,
  header,
  innerRef,
  handleProps,
  styleToApply,
  ...props
}: ListElementProps) {
  const classes = useStyles();

  return (
    <div
      ref={innerRef}
      className={clsx(classes.listElement, header && classes.listHeader, className)}
      {...props}
      style={{
        ...(props.style || {}),
        ...(styleToApply || {}),
      }}
    >
      {handleProps ? (
        <span
          className={classes.handle}
          {...handleProps}
        >
          <DragHandle />
        </span>
      ) : null}
      {children}
    </div>
  );
});
