import { type ReactNode, memo, type ReactElement, type JSXElementConstructor } from 'react';

import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../general/typography/Typography';
import { CellWithActions, type CellWithActionsProps } from '../cell-with-actions/CellWithActions';

import { CellMainLink, type CellMainLinkProps } from './cell-main-link/CellMainLink';

export type CellMainProps = {
  hideActions?: CellWithActionsProps['hideActions'];
  /** Link href. */
  link: ReactElement<CellMainLinkProps, JSXElementConstructor<CellMainLinkProps>>;
  /** Cell content. */
  children: ReactNode;
};

const CellMainBase = function CellMain({ link, hideActions = undefined, children }: CellMainProps) {
  return (
    <CellWithActions
      actions={link}
      hideActions={hideActions}
    >
      <Typography
        as={TextOverflow}
        variant={Typography.Variant.BODY_BASE_MEDIUM}
      >
        {children}
      </Typography>
    </CellWithActions>
  );
};

export const CellMain = Object.assign(memo(CellMainBase) as typeof CellMainBase, {
  Link: CellMainLink,
});
