import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconPencil, IconPlus } from '@tabler/icons-react';
import { memo } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, IconButton } from '@amal-ia/frontend/design-system/components';
import { type VariablesMap } from '@amal-ia/lib-types';
import { List, ListElement, Text, TextType } from '@amal-ia/lib-ui';

interface PlanRuleListKpiListProps {
  ruleAssignment: PlanRule;
  variablesMap: VariablesMap;
  onAddKpiToRule: (rule: PlanRule) => any;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.divider}`,
  },
  list: {
    marginTop: theme.spacing(1),
    '& $listElement': {
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
  },
}));

export const PlanRuleListKpiList = memo(function PlanRuleListKpiList({
  ruleAssignment,
  variablesMap,
  onAddKpiToRule,
}: PlanRuleListKpiListProps) {
  const classes = useStyles();

  return (
    <List
      className={classes.list}
      mode="horizontalWrap"
    >
      {!ruleAssignment.kpisToDisplay?.length ? (
        <Button
          aria-label={`Assign KPIs on rule ${ruleAssignment.name}`}
          icon={<IconPlus />}
          variant={Button.Variant.SECONDARY}
          onClick={() => onAddKpiToRule(ruleAssignment)}
        >
          Add KPI
        </Button>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
        >
          <Text type={TextType.SUBTITLE}>KPIs</Text>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
          >
            <IconButton
              icon={<IconPencil />}
              label={`Assign KPIs on rule ${ruleAssignment.name}`}
              onClick={() => onAddKpiToRule(ruleAssignment)}
            />
            {(ruleAssignment.kpisToDisplay || []).map(({ id }) => (
              <ListElement
                key={id}
                className={classes.listElement}
              >
                {variablesMap[id]?.name}
              </ListElement>
            ))}
          </Box>
        </Box>
      )}
    </List>
  );
});
