import { defineMessages } from 'react-intl';

export const notificationsSettingsMessages = defineMessages({
  EMAIL: {
    defaultMessage: 'Email',
  },

  SLACK: {
    defaultMessage: 'Slack',
    description: 'As in the message service',
  },
} as const);
