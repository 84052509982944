import { type Adjustment } from '@amal-ia/lib-types';

export enum DuplicatesOptions {
  OVERWRITE = 'overwrite',
  SKIP = 'skip',
  UPDATE_MISSING_FIELDS = 'update-missing-fields',
}

export interface StatementCsvFile {
  data: string[][];
  filename: string;
}

export type CreateAdjustmentDto = Omit<Adjustment, 'amount'> & {
  amount?: number;
};
