import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { objectToQs } from '../common/url';

import { useQueryString } from './useQueryString';

/**
 * With the same interface as useState, but also stores state in the query string.
 * @param defaultValue
 * @param key
 */
export const useStateInQueryString = (
  defaultValue: string,
  key: string,
): [string, (newValue: string | null) => void] => {
  const { pathname } = useLocation();
  const qs = useQueryString();
  const history = useHistory();

  const [value, setValue] = useState(qs[key] || defaultValue);

  useEffect(() => {
    const newQs = objectToQs({
      ...omit(qs, [key]),
      ...(value && { [key]: value }),
    });
    history.push({
      pathname,
      search: `?${newQs}`,
    });
  }, [history, pathname, key, qs, value]);

  return [value, setValue];
};
