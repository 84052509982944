import { useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- importing types only
import * as ExtendRedux from 'redux-thunk/extend-redux';

import auditReducer from './audit/reducer';
import commentsReducer from './comments/reducer';
import companiesReducer from './companies/reducer';
import currenciesReducer from './currencies/reducer';
import customObjectDefinitionsReducer from './customObjectDefinitions/reducer';
import designerReducer from './designer/reducer';
import filtersReducer from './filters/reducer';
import flowsReducer from './flows/reducer';
import { errorHandler } from './middlewares';
import overwritesReducer from './overwrites/reducer';
import paymentsReducer from './payments/reducer';
import periodsReducer from './periods/reducer';
import planAgreementsReducer from './planAgreements/reducer';
import planAssignmentsReducer from './planAssignments/reducer';
import planForecastsReducer from './planForecasts/reducer';
import plansReducer from './plans/reducer';
import rulesReducer from './rules/reducer';
import slackReducer from './slack/reducer';
import snackbarsReducer from './snackbars/reducer';
import statementsReducer from './statements/reducer';
import teamsReducer from './teams/reducer';
import todosReducer from './todos/reducer';
import usersReducer from './users/reducer';
import userStatementsReducer from './userStatements/reducer';
import variablesReducer from './variables/reducer';

const rootReducer = combineReducers({
  audit: auditReducer,
  comments: commentsReducer,
  companies: companiesReducer,
  currencies: currenciesReducer,
  customObjectDefinitions: customObjectDefinitionsReducer,
  filters: filtersReducer,
  flows: flowsReducer,
  overwrites: overwritesReducer,
  payments: paymentsReducer,
  periods: periodsReducer,
  plans: plansReducer,
  planAgreements: planAgreementsReducer,
  planAssignments: planAssignmentsReducer,
  planForecasts: planForecastsReducer,
  slack: slackReducer,
  snackbars: snackbarsReducer,
  statements: statementsReducer,
  teams: teamsReducer,
  todos: todosReducer,
  users: usersReducer,
  userStatements: userStatementsReducer,
  variables: variablesReducer,
  rules: rulesReducer,
  designer: designerReducer,
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk, errorHandler];

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export type ThunkDispatch = typeof store.dispatch;

export const useThunkDispatch = () => useDispatch<ThunkDispatch>();
