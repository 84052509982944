import { ArrowDropDownCircleRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import invariant from 'invariant';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { Avatar, SmallAvatarGroup, UserSelect, type UserSelectProps } from '@amal-ia/lib-ui';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  menuButton: {
    transform: 'translate(0, 0.5rem)',
    '& .MuiMenu-list, .MuiMenu-paper': {
      boxShadow: '0px 0px 12px 0px  rgba(0, 0, 0, 0.1)',
    },
    '.MuiMenu-paper': {
      width: 250,
      height: 300,
      maxHeight: 'calc(100% - 10px)',
    },
  },
  ArrowDropDownCircleRounded: {
    marginLeft: '20px',
    color: theme.palette.grey[700],
  },
}));

type FilterBarUserSelectProps<TUser extends UserComputed> = UserSelectProps<TUser> & {
  render?: never;
  children?: never;
};

const FilterBarUserSelectBase = function FilterBarUserSelect<TUser extends UserComputed>(
  props: FilterBarUserSelectProps<TUser>,
) {
  const classes = useStyles();

  const { multiple, users } = props;

  const renderAvatars = useCallback(
    (selectedUsers: TUser | TUser[] | null) => {
      if (multiple && Array.isArray(selectedUsers)) {
        return (
          <SmallAvatarGroup
            max={3}
            // If no user is currently selected, show all of them.
            users={(selectedUsers?.length ? selectedUsers : users) || []}
          />
        );
      }
      if (multiple && !selectedUsers) {
        // If no user is currently selected, show all of them.
        return (
          <SmallAvatarGroup
            max={3}
            users={users || []}
          />
        );
      }
      invariant(!Array.isArray(selectedUsers), 'UserSelect: multiple is false but value is an array');
      if (!selectedUsers) {
        return <FormattedMessage defaultMessage="No user selected" />;
      }
      return <Avatar user={selectedUsers} />;
    },
    [multiple, users],
  );

  return (
    <UserSelect<TUser> {...props}>
      {({ onClick, value }) => (
        <Button
          data-testid="filterUsers"
          variant={Button.Variant.LIGHT}
          onClick={onClick}
        >
          {renderAvatars(value)}
          <ArrowDropDownCircleRounded className={classes.ArrowDropDownCircleRounded} />
        </Button>
      )}
    </UserSelect>
  );
};

export const FilterBarUserSelect = memo(FilterBarUserSelectBase) as typeof FilterBarUserSelectBase;
