import { Box, Divider, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconSearch, IconX } from '@tabler/icons-react';
import { memo, useCallback, useMemo, useState, type ChangeEvent, type ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, IconButton, type TablerIconElement } from '@amal-ia/frontend/design-system/components';

import { COMMON_MESSAGES } from '../../../utils/messages/common.messages';
import { Input } from '../formElements/inputs/Input/Input';
import { Text, TextType } from '../typography';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  button: {
    height: 30,
  },
  menu: {
    transform: 'translate(0, 10px)',
  },
  dialog: {
    width: 250,
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `0 ${theme.spacing(2)}`,
  },
  list: {
    padding: theme.spacing(0.5, 0),
    height: 180,
    overflowY: 'auto',
    flex: 1,
  },
  divider: {
    margin: theme.spacing(1, 1.5),
    backgroundColor: theme.palette.grey[200],
  },
  searchBox: {
    margin: theme.spacing(0, 1),
    marginBottom: theme.spacing(1),
  },
  archive: {
    paddingRight: '33%',
  },
}));

interface TitleSelectWithActionProps {
  options: { value: string; label: string; text: ReactNode | string; archived?: boolean }[];
  title?: ReactNode | string;
  action?: { label: ReactNode; icon?: TablerIconElement; onClick: () => void };
  endListAction?: ReactNode;
  meetSecondaryConditionDisplay?: (item: any) => boolean;
  secondaryActionText?: ReactNode;
  onChange: (id: string) => void;
}

export const TitleSelectWithAction = memo(function TitleSelectWithAction({
  options,
  title,
  action,
  endListAction,
  meetSecondaryConditionDisplay,
  secondaryActionText,
  onChange,
}: TitleSelectWithActionProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const onClickAddPlanProxy = useCallback(() => {
    action.onClick();
  }, [action]);

  // ============ SEARCH CONTROL ============
  const [search, setSearch] = useState<string>('');

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value), []);

  const optionsFiltered = useMemo(() => {
    if (search) {
      return options.filter((o) => o.label.toLowerCase().includes(search.toLowerCase()));
    }
    return options;
  }, [search, options]);

  return (
    <Box className={classes.dialog}>
      <Box className={classes.dialogHeader}>
        <Text type={TextType.INPUT_NAME}>{title}</Text>
        {action ? (
          <Button
            data-testid="selector-header"
            icon={action.icon}
            variant={Button.Variant.SECONDARY}
            onClick={onClickAddPlanProxy}
          >
            {action.label}
          </Button>
        ) : null}
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.searchBox}>
        <Input
          autoFocus
          placeholder={formatMessage(COMMON_MESSAGES.SEARCH)}
          startAdornment={<IconSearch />}
          value={search}
          endAdornment={
            search ? (
              <InputAdornment position="end">
                <IconButton
                  icon={<IconX />}
                  label={formatMessage({ defaultMessage: 'Clear search' })}
                  onClick={() => setSearch('')}
                />
              </InputAdornment>
            ) : null
          }
          onChange={onSearch}
          // Prevent weird behavoir when we press some keys like 's' for example
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </Box>
      <List
        dense
        className={classes.list}
        role="listbox"
      >
        {optionsFiltered.map((o) => (
          <ListItem
            key={o.value}
            button
            className={meetSecondaryConditionDisplay?.(o) ? classes.archive : null}
            component="li"
            onClick={() => onChange(o.value)}
          >
            <ListItemText
              id={o.value}
              primary={o.text}
            />
            {meetSecondaryConditionDisplay?.(o) ? (
              <ListItemSecondaryAction>{secondaryActionText}</ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
        {endListAction}
      </List>
    </Box>
  );
});
