import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { AUTHZ_CONTEXT_QUERY_KEYS } from '@amal-ia/frontend/kernel/authz/context';
import { NotificationsApiClient, UserApiClient } from '@amal-ia/tenants/users/shared/api-client';
import { type KeysOfUserWithStringValues, type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { USER_INFORMATIONS_QUERY_KEYS, USER_NOTIFICATIONS_QUERY_KEYS } from './queries.keys';

export const useUsersInformations = (usersToFetch: [property: KeysOfUserWithStringValues, value: string][]) =>
  useQueries({
    queries: usersToFetch.map(([property, value]) => ({
      queryKey: [USER_INFORMATIONS_QUERY_KEYS.USER_INFORMATIONS, property, value],
      queryFn: () => UserApiClient.bufferedFetchUserBy(property, value),
    })),
  });

export const useUsersById = (userIds: string[]) => useUsersInformations(userIds.map((id) => ['id', id]));

export const useUsersMap = (userIds: string[]) => {
  const queries = useUsersById(userIds);

  return useMemo(
    () =>
      queries.reduce<UsersMap>((acc, query) => {
        if (query.data) {
          acc[query.data.id] = query.data;
        }
        return acc;
      }, {}),
    [queries],
  );
};

export const useUserInformations = (property: KeysOfUserWithStringValues, value: string) =>
  useQuery({
    queryKey: [USER_INFORMATIONS_QUERY_KEYS.USER_INFORMATIONS, property, value],
    queryFn: () => UserApiClient.bufferedFetchUserBy(property, value),
    refetchOnWindowFocus: false,
  });

export const useCompanyUsers = () =>
  useQuery({
    queryKey: [USER_INFORMATIONS_QUERY_KEYS.USER_INFORMATIONS],
    queryFn: () => UserApiClient.fetchAllUsers(),
  });

export const useUpdateUserSettings = () => {
  const { snackSuccess } = useSnackbars();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [AUTHZ_CONTEXT_QUERY_KEYS.AUTHENTICATED_CONTEXT],
    mutationFn: UserApiClient.updateSettings,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [AUTHZ_CONTEXT_QUERY_KEYS.AUTHENTICATED_CONTEXT] });
      snackSuccess(formatMessage({ defaultMessage: 'Settings successfully updated!' }));
    },
  });
};

export const useInfiniteNotifications = () => {
  const { data: { pages } = {}, ...rest } = useInfiniteQuery({
    queryKey: [USER_NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS],
    queryFn: ({ pageParam = 1 }: { pageParam?: number }) => NotificationsApiClient.fetchNotifications(pageParam),
    getNextPageParam: (lastPage, allPages) =>
      !isNil(lastPage.paginatedNotifications.pageCount) && allPages.length < lastPage.paginatedNotifications.pageCount
        ? allPages.length + 1
        : undefined,
  });

  const notifications = useMemo(() => (pages || []).flatMap((page) => page.paginatedNotifications.items), [pages]);

  return {
    data: notifications,
    ...rest,
  };
};

export const useUnreadNotificationsCount = () =>
  useQuery({
    queryKey: [USER_NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS, 'unreadCount'],
    queryFn: () => NotificationsApiClient.fetchUnreadCount(),
    refetchInterval: 15_000,
  });

export const useMarkNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args: Parameters<(typeof NotificationsApiClient)['markAsRead']>) => {
      await NotificationsApiClient.markAsRead(...args);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([USER_NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS]);
    },
  });
};
