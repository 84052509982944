import { findIndex, slice } from 'lodash';
import { useMemo } from 'react';

import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import {
  type CustomReportColumn,
  type PayoutAndPerformanceChartStatistics,
  type PayoutAndPerformanceChartStatisticsRecord,
} from '@amal-ia/lib-types';

const computeCommissionSum = (ytdRecords: PayoutAndPerformanceChartStatisticsRecord[]) =>
  ytdRecords.reduce((acc, record) => acc + (record.ruleMetricPayment__value?.value || 0), 0);

const computeKpiAverage = (ytdRecords: PayoutAndPerformanceChartStatisticsRecord[], kpiIdentifier: string) =>
  ytdRecords.reduce((acc, record) => acc + (record[kpiIdentifier] as number), 0) / ytdRecords.length;

export const useYtdRecord = (
  ytdStats: PayoutAndPerformanceChartStatistics,
  currentMonth: string,
  kpi1?: CustomReportColumn,
  kpi2?: CustomReportColumn,
) =>
  useMemo(() => {
    const record: PayoutAndPerformanceChartStatisticsRecord = {
      ruleMetricPeriod__month: '',
      ruleMetricPayment__value: { value: 0, symbol: CurrencySymbolsEnum.EUR },
    };

    if (!ytdStats?.records.length) {
      return record;
    }

    // Year to date records are all records until current date (If we are in September, but we select current month as August, we should get all records until August).
    const monthRecordIndex = findIndex(ytdStats.records, (record) => record.ruleMetricPeriod__month === currentMonth);
    // Include all month records including current month.
    const ytdRecords = slice(ytdStats.records, 0, monthRecordIndex + 1);

    const amountSum = computeCommissionSum(ytdRecords);
    const symbol = ytdStats.records.find((r) => !!r.ruleMetricPayment__value?.symbol)?.ruleMetricPayment__value?.symbol;

    if (ytdStats.records[0]?.ruleMetricPayment__value) {
      record.ruleMetricPayment__value = {
        value: amountSum,
        symbol,
      };
    }

    if (kpi1) {
      record[kpi1.identifier] = computeKpiAverage(ytdRecords, kpi1.identifier);
    }

    if (kpi2) {
      record[kpi2.identifier] = computeKpiAverage(ytdRecords, kpi2.identifier);
    }
    return record;
  }, [currentMonth, kpi1, kpi2, ytdStats?.records]);
