import { defineMessages } from 'react-intl';

export const routesTitles = defineMessages({
  SUPER_ADMIN: {
    defaultMessage: 'Superadmin',
  },
  SUPER_ADMIN_COMPANY_DETAILS: {
    defaultMessage: 'Company details',
  },
  SUPER_ADMIN_COMPANY_LIST: {
    defaultMessage: 'Company list',
  },
  SUPER_ADMIN_HOME: {
    defaultMessage: 'Home',
  },
  SUPER_ADMIN_MASTER_SETTINGS: {
    defaultMessage: 'Master settings',
  },
  SUPER_ADMIN_CALCULATIONS: {
    defaultMessage: 'Calculations',
  },
  SUPER_ADMIN_DATA_REFRESHMENTS: {
    defaultMessage: 'Data refreshments',
  },
  SUPER_ADMIN_CONNECTORS: {
    defaultMessage: 'Connectors',
  },
  SUPER_ADMIN_STATEMENTS: {
    defaultMessage: 'Statements',
  },
  SUPER_ADMIN_USERS: {
    defaultMessage: 'Users',
  },
  SUPER_ADMIN_PLANS: {
    defaultMessage: 'Plans',
  },
} as const);

export const headerMessages = defineMessages({
  SUPER_ADMIN_PANEL: {
    defaultMessage: 'Superadmin panel',
  },
  RESET_IMPERSONATE: {
    defaultMessage: 'Reset impersonate',
  },
  POWER_USER_MODE: {
    defaultMessage: 'Power user mode',
  },
  FILTER_COMPANIES: {
    defaultMessage: 'Filter companies',
  },
} as const);
