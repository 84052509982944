import { isEmpty } from 'lodash';

import { type PlanRule } from '@amal-ia/compensation-definition/shared/types';
import { sortByIndex } from '@amal-ia/lib-types';

export const getRulesInCategoryV2 = (categoryName: string | null, rules: PlanRule[]): PlanRule[] => {
  if (!categoryName) {
    return sortByIndex(rules.filter((r) => isEmpty(r.category)));
  }
  return sortByIndex(rules.filter((r) => r.category === categoryName));
};
