import { useTheme } from '@emotion/react';
import { memo, useCallback } from 'react';

import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type PayoutAndPerformanceChartStatistics } from '@amal-ia/lib-types';

import { getDefinitionNameFromDataKey } from '../chartSeries';
import { formatChartValue } from '../chartValue.formatter';

type LabelProps = Readonly<{
  value?: number;
  x?: number;
  y?: number;
  width?: number;
  currency: CurrencySymbolsEnum;
  statistics: PayoutAndPerformanceChartStatistics;
}>;
export const BarLabel = memo(function Label({ currency, value, x, y, width, statistics }: LabelProps) {
  const theme = useTheme();

  // Format amount using a short format.
  const valueFormatter = useCallback(
    (value: number, dataKey: string) =>
      formatChartValue(value, statistics?.definitions[getDefinitionNameFromDataKey(dataKey)].format, currency),
    [currency, statistics],
  );
  return (
    <g transform={`translate(${+x + +width / 2},${+y - 20})`}>
      <text
        dy={10}
        fill={theme.ds.colors.gray[800]}
        {...theme.ds.typographies.bodyBaseMedium}
        textAnchor="middle"
      >
        {valueFormatter(value, statistics?.definitions?.ruleMetricPayment__value?.identifier)}
      </text>
    </g>
  );
});
