import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { BackgroundSparkles } from '@amal-ia/frontend/ui-icons';
import { type ChangelogEntry } from '@amal-ia/lib-types';
import { DialogTitleWithCloseButton, Text, TextType, useOpenInNewTab } from '@amal-ia/lib-ui';

export interface ChangelogPopupProps {
  changelogEntries: ChangelogEntry[];
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    position: 'fixed',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
    width: '360px',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    padding: '16px',
    zIndex: 1010,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
}));

const MAX_DISPLAYED_ENTRIES = 2;

const ChangelogPopup = memo(function ChangelogPopup({ changelogEntries }: ChangelogPopupProps) {
  const [dismissed, setDismissed] = useState(false);
  const classes = useStyles();
  const openInNewTab = useOpenInNewTab();

  const goToChangelog = useCallback(() => {
    setDismissed(true);
    openInNewTab('changelog');
  }, [setDismissed, openInNewTab]);

  const changelogTextContent = useMemo(
    () =>
      changelogEntries
        .slice(0, MAX_DISPLAYED_ENTRIES)
        .map((e) => `${e.releaseType} on ${e.topic}`)
        .join(', '),
    [changelogEntries],
  );

  if (dismissed) {
    return null;
  }

  return (
    <Box
      className={classes.container}
      data-testid="changelog-popup"
    >
      <DialogTitleWithCloseButton handleClose={() => setDismissed(true)}>
        <Text type={TextType.MODAL_TITLE}>
          <FormattedMessage defaultMessage="Latest updates" />
        </Text>
      </DialogTitleWithCloseButton>
      <Text type={TextType.SUBTITLE}>
        {changelogTextContent}
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- Changelog content is not translated. */}
        {changelogEntries.length > MAX_DISPLAYED_ENTRIES ? ' and more!' : '!'}
      </Text>
      <Box className={classes.buttonContainer}>
        <BackgroundSparkles />
        <Button
          variant={Button.Variant.SECONDARY}
          onClick={goToChangelog}
        >
          <FormattedMessage defaultMessage="Go!" />
        </Button>
      </Box>
    </Box>
  );
});

export default memo(ChangelogPopup);
