import { memo, useState } from 'react';
import { useParams, useHistory, generatePath } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amal-ia/common/routes';
import { toError } from '@amal-ia/ext/typescript';
import { AuthenticationError } from '@amal-ia/frontend/connected-components/auth';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import {
  fetchCompany,
  useThunkDispatch,
  type INTEGRATIONS,
  registerIntegration,
} from '@amal-ia/frontend/web-data-layers';
import { useQueryString } from '@amal-ia/lib-ui';

export const IntegrationOauthCallback = memo(function IntegrationOauthCallback() {
  const { integration } = useParams<{ integration: INTEGRATIONS }>();

  const { code } = useQueryString();
  const history = useHistory();
  const { snackSuccess } = useSnackbars();
  const dispatch = useThunkDispatch();

  const [error, setError] = useState<string | undefined>();

  useAsyncEffect(async () => {
    if (code) {
      try {
        await registerIntegration(integration, code);
        // Once integration is set up, refresh the company in redux.
        await dispatch(fetchCompany());
        snackSuccess(`Integration ${integration} set up successfully!`);
        history.push(generatePath(routes.COMPANY_SETTINGS));
      } catch (e) {
        setError(toError(e).message);
      }
    }
  }, []);

  return error ? <AuthenticationError message={error} /> : null;
});
