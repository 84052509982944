import { noop } from 'lodash';
import { memo, type ReactNode, useCallback, useState } from 'react';

import { Modal } from '@amal-ia/frontend/design-system/components';

interface ConfirmationModalProps {
  isOpened: boolean;
  title: ReactNode;
  contentText: ReactNode;
  confirmText: ReactNode;
  confirmDataTestId?: string;
  cancelText?: ReactNode;
  cancelDataTestId?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  isDeleteAction?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const ConfirmationModal = memo(function ConfirmationModal({
  isOpened,
  title,
  contentText,
  handleClose,
  handleConfirm,
  cancelText,
  cancelDataTestId,
  confirmText,
  confirmDataTestId,
  isDeleteAction,
  isLoading,
  isDisabled,
}: ConfirmationModalProps) {
  return (
    <Modal
      isOpen={isOpened}
      variant={isDeleteAction ? Modal.Variant.DANGER : Modal.Variant.DEFAULT}
      onClose={handleClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{contentText}</Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction data-testid={cancelDataTestId}>{cancelText}</Modal.CancelAction>

        <Modal.MainAction
          data-testid={confirmDataTestId}
          disabled={isLoading || isDisabled}
          onClick={handleConfirm}
        >
          {confirmText}
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});

export interface ConfirmModalControls {
  isOpened: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

export const useConfirmModal = ({
  onConfirm = noop,
  onCancel = noop,
}): {
  controls: ConfirmModalControls;
  openModal: (...params: unknown[]) => void;
} => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [passthroughsParams, setPassthroughsParams] = useState<unknown[]>(undefined);

  const openModal = useCallback(
    (...params: unknown[]) => {
      setIsOpened(true);
      setPassthroughsParams(params);
    },
    [setIsOpened],
  );

  const handleConfirm = useCallback(() => {
    onConfirm(...passthroughsParams);
    setIsOpened(false);
    setPassthroughsParams(undefined);
  }, [onConfirm, setIsOpened, passthroughsParams]);

  const handleClose = useCallback(() => {
    onCancel(...passthroughsParams);
    setIsOpened(false);
    setPassthroughsParams(undefined);
  }, [onCancel, setIsOpened, passthroughsParams]);

  return {
    controls: {
      isOpened,
      handleConfirm,
      handleClose,
    },
    openModal,
  };
};
