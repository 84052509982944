import { groupBy } from 'lodash';
import { type ReactNode } from 'react';

import { type Variable, VariableObjectsEnum } from '@amal-ia/lib-types';

export const groupVariableOptionsByType = (
  variablesList: Variable[],
  selectedTypes: VariableObjectsEnum[],
  // Pass a function to customize the label rendering
  customLabelRenderer?: (variable: Variable) => ReactNode | string,
) => {
  const availableVariables = variablesList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((variable) => ({
      value: variable.id,
      searchString: variable.name,
      label: customLabelRenderer?.(variable) || variable.name,
      type: variable.type,
    }));

  // Group them by their type.
  const variableGroupedByType = groupBy(availableVariables, 'type');

  // Put them into option groups.
  return [
    selectedTypes.includes(VariableObjectsEnum.plan) && {
      label: 'Plan Variables',
      options: variableGroupedByType[VariableObjectsEnum.plan] || [],
    },
    selectedTypes.includes(VariableObjectsEnum.team) && {
      label: 'Team Variables',
      options: variableGroupedByType[VariableObjectsEnum.team] || [],
    },
    selectedTypes.includes(VariableObjectsEnum.user) && {
      label: 'User Variables',
      options: variableGroupedByType[VariableObjectsEnum.user] || [],
    },
    selectedTypes.includes(VariableObjectsEnum.statement) && {
      label: 'Statement Variables',
      options: variableGroupedByType[VariableObjectsEnum.statement] || [],
    },
    selectedTypes.includes(VariableObjectsEnum.object) && {
      label: 'Object Variables',
      options: variableGroupedByType[VariableObjectsEnum.object] || [],
    },
  ].filter(Boolean) as { label: string; options: { label: ReactNode | string; value: string }[] }[];
};

export const filterVariablesThatContainsKeywords = (variablesList: Variable[], keywords: string[] = []) =>
  variablesList.filter((v) => keywords.some((k) => v.formula.toLowerCase().includes(k.toLowerCase())));

export const isTableFormulaPure = (formula: string) =>
  /^\[(?:\[(?:-?(?:\d+(?:\.\d+)?|Infinity)(?:\s?,\s?)?)+\](?:\s?,\s?)?)+\]$/u.exec(formula);
