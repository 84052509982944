import { Table } from '@devexpress/dx-react-grid-material-ui';
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { Fragment, memo, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { selectCustomObjectDefinitionsMapById } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { type Adjustment, formatTotal } from '@amal-ia/lib-types';
import { objectToQs } from '@amal-ia/lib-ui';

import AdjustmentsTableContext from './AdjustmentsTableContext';

const useStyles = makeStyles({
  multiLineCellContent: {
    maxWidth: '30rem',
    width: '24rem',
    minWidth: '24rem',
    whiteSpace: 'normal',
    height: 'auto',

    '@media (max-width: 1280px)': {
      minWidth: '0',
      width: 'auto',
    },
  },
});

const AdjustmentsTableCell = memo(function AdjustmentsTableCell(cellProps: any) {
  const classes = useStyles();
  const ability = useAbilityContext();
  const { formatMessage } = useIntl();
  const { column, tableRow, value } = cellProps;
  const adjustment: Adjustment = tableRow.row;

  const customObjectDefinitionMap = useSelector(selectCustomObjectDefinitionsMapById);

  const { deleteAdjustment, editAdjustment, statement, isWorkflowBegun } = useContext(AdjustmentsTableContext);

  const handleDeleteAdjustment = useCallback(() => deleteAdjustment(adjustment.id), [adjustment, deleteAdjustment]);

  const handleEditAdjustment = useCallback(() => editAdjustment(adjustment), [adjustment, editAdjustment]);

  switch (column.name) {
    case 'name':
      return (
        <Table.Cell
          name={`${adjustment.id}.values[${column.name}]`}
          {...cellProps}
        >
          <Box className={classes.multiLineCellContent}>{adjustment.name}</Box>
        </Table.Cell>
      );
    case 'record':
      return (
        <Table.Cell
          name={`${adjustment.id}.values[${column.name}]`}
          {...cellProps}
        >
          {!adjustment.rowExternalId ? (
            // Not on a record, display empty cell.
            ''
          ) : ability.can(ActionsEnum.view, SubjectsEnum.Data) &&
            customObjectDefinitionMap[adjustment.rowDefinitionId]?.machineName ? (
            <Link
              rel="noreferrer"
              style={{ color: amaliaTheme.palette.link.main }}
              target="_blank"
              to={{
                pathname: generatePath(routes.DATA_SLUG, {
                  slug: customObjectDefinitionMap[adjustment.rowDefinitionId].machineName,
                }),
                search: `?${objectToQs({ search: adjustment.rowExternalId })}`,
              }}
            >
              {adjustment.rowName || adjustment.rowExternalId}
            </Link>
          ) : (
            adjustment.rowName || adjustment.rowExternalId
          )}
        </Table.Cell>
      );
    case 'amount':
      return (
        <Table.Cell
          name={`${adjustment.id}.values[${column.name}]`}
          {...cellProps}
        >
          {value ? formatTotal(value, FormatsEnum.currency, statement.currency) : value}
        </Table.Cell>
      );
    case 'actions':
      return (
        <Table.Cell
          name={`${adjustment.id}.values[${column.name}]`}
          {...cellProps}
          css={css`
            display: flex;
          `}
        >
          {!isWorkflowBegun && ability.can(ActionsEnum.adjust, SubjectsEnum.Statement) && (
            <Fragment>
              <IconButton
                icon={<IconPencil />}
                label={formatMessage({ defaultMessage: `Edit the adjustment {name}` }, { name: adjustment.name })}
                size={IconButton.Size.LARGE}
                onClick={handleEditAdjustment}
              />
              <IconButton
                icon={<IconTrash />}
                label={formatMessage({ defaultMessage: `Delete the adjustment {name}` }, { name: adjustment.name })}
                size={IconButton.Size.LARGE}
                onClick={handleDeleteAdjustment}
              />
            </Fragment>
          )}
        </Table.Cell>
      );
    default:
      return (
        <Table.Cell
          name={`${adjustment.id}.values[${column.name}]`}
          {...cellProps}
        >
          <Box className={classes.multiLineCellContent}>{value}</Box>
        </Table.Cell>
      );
  }
});

export default AdjustmentsTableCell;
