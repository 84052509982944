import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconViewfinder } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Button, ButtonLink } from '@amal-ia/frontend/design-system/components';
import { useStatementDetailContext } from '@amal-ia/lib-ui';

import { themeColorForStatementErrorType } from './useStatementDetailHeaderStyles';

const useStyles = makeStyles({
  quotaMissingError: {
    textAlign: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
  },
});
export default memo(function StatementDetailHeaderPayoutQuotaErrorProps() {
  const classes = useStyles();
  const { error, user } = useStatementDetailContext();
  return (
    <Box className={classes.quotaMissingError}>
      <span style={{ color: themeColorForStatementErrorType[error?.level] }}>{error?.message}</span>
      <Box mt="0.5em">
        <ButtonLink
          icon={<IconViewfinder />}
          to={generatePath(routes.QUOTAS_USER, { userId: user.id })}
          variant={Button.Variant.SECONDARY}
        >
          <FormattedMessage defaultMessage="Go to quota" />
        </ButtonLink>
      </Box>
    </Box>
  );
});
