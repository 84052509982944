import '@amal-ia/frontend/design-system/meta';

import { css, useTheme } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { type FormatsEnum, type PropertyRef } from '@amal-ia/data-capture/fields/types';
import { TokenType } from '@amal-ia/lib-types';

import { getTokenIcon } from './getTokenIcon';

/**
 * The exact color of tokens is specific to the context. In the formula editor, we are using the 50 strength of the color.
 */
const useTokenColor = (tokenType: TokenType): string | undefined => {
  const theme = useTheme();

  return {
    [TokenType.VARIABLE]: theme.ds.hues.cyan[100],
    [TokenType.FILTER]: theme.ds.hues.green[100],
    [TokenType.LINK]: theme.ds.hues.red[100],
    [TokenType.QUOTA]: theme.ds.hues.purple[100],
    [TokenType.FUNCTION]: theme.ds.hues.brown[100],
    [TokenType.KEYWORD]: theme.ds.hues.magenta[100],
    [TokenType.FIELD]: theme.ds.hues.orange[100],
    [TokenType.PROPERTY]: theme.ds.hues.orange[100],
    [TokenType.VIRTUAL_PROPERTY]: theme.ds.hues.orange[100],
    [TokenType.OBJECT]: theme.ds.hues.orange[100],
    [TokenType.RULE]: undefined,
    [TokenType.PLAN]: undefined,
  }[tokenType];
};

export type DesignerTokenIconProps = ComponentPropsWithoutRef<'svg'> & {
  /** The type of token to display. */
  tokenType: TokenType;
  /** The format of the token. If omitted, will use the default icon for the provided tokenType. */
  tokenFormat?: FormatsEnum;
  /** The property ref of the token. If omitted, will use the default icon for the provided tokenType. */
  propertyRef?: PropertyRef;
};

export const DesignerTokenIcon = memo(function DesignerTokenIcon({
  tokenType,
  tokenFormat = undefined,
  propertyRef = undefined,
  ...svgProps
}: DesignerTokenIconProps) {
  const Icon = getTokenIcon({ tokenType, tokenFormat, propertyRef });
  const color = useTokenColor(tokenType);

  return Icon ? (
    <Icon
      {...svgProps}
      css={[
        css`
          border-radius: 4px;
          padding: 2px;
        `,
        color &&
          css`
            background-color: ${color};
          `,
      ]}
    />
  ) : null;
});
