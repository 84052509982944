import { type PlanRuleCategory } from '@amal-ia/compensation-definition/shared/types';
import { http } from '@amal-ia/frontend/kernel/http';

export async function list(): Promise<PlanRuleCategory[]> {
  const { data } = await http.get('/plan_rule_categories');
  return data;
}

export async function create(category: Partial<PlanRuleCategory>): Promise<PlanRuleCategory> {
  const { data } = await http.post('/plan_rule_categories/', category);
  return data;
}

export async function update(category: PlanRuleCategory): Promise<PlanRuleCategory> {
  const { data } = await http.put(`/plan_rule_categories/${category.id}`, category);
  return data;
}

export async function remove(category: PlanRuleCategory): Promise<any> {
  return http.delete(`/plan_rule_categories/${category.id}`);
}
