import { Box } from '@mui/material';
import clsx from 'clsx';
import { Fragment, memo, type ReactNode } from 'react';

import { type TokenType } from '@amal-ia/lib-types';
import { usePageHeaderStyles, Text, TextType } from '@amal-ia/lib-ui';

import { DesignerElementBadge } from '../DesignerElementBadge/DesignerElementBadge';

interface DesignerHeaderProps {
  title?: ReactNode | string;
  subTitle?: ReactNode | string;
  badge: TokenType;
  children?: ReactNode;
  section?: ReactNode;
}

export const DesignerHeader = memo(function DesignerHeader({
  title,
  subTitle,
  children,
  section,
  badge,
}: DesignerHeaderProps) {
  // Reusing most of the style from the page header.
  const classes = usePageHeaderStyles();
  return (
    <Fragment>
      <Box className={classes.container}>
        <DesignerElementBadge type={badge} />
        <Box className={classes.headerContainer}>
          <Box className={clsx(classes.header, children && classes.headerContainerWithHeaderContent)}>
            <Box className={classes.headerTitle}>
              {title ? (
                <Text
                  id="designer-header-title"
                  type={TextType.PAGE_TITLE}
                >
                  {title}
                </Text>
              ) : null}
              {subTitle ? (
                <Text
                  id="designer-header-subtitle"
                  type={TextType.SUBTITLE}
                >
                  {subTitle}
                </Text>
              ) : null}
            </Box>
          </Box>
          {children}
        </Box>
      </Box>
      {section ? <Box bgcolor="white">{section}</Box> : null}
    </Fragment>
  );
});
