import { type ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { TodoType } from '@amal-ia/lib-types';

import { objectToQs } from './url';

const regex = /\[(?<entity>\w+)(\|(?<value>[\w,-]+)]|.)\((?<text>[^)]+)\)/g;

type EntityType = 'customObject' | 'statement' | 'statementThread' | 'user';

const getLink = (entity: EntityType, value: string, text: string) => {
  switch (entity) {
    case 'user':
      return <b>{text}</b>;
    case 'statement':
      return (
        <Link
          rel="noreferrer"
          style={{ color: amaliaTheme.palette.link.main }}
          target="_blank"
          to={generatePath(routes.STATEMENT, { id: value })}
        >
          {text}
        </Link>
      );
    case 'statementThread': {
      const [statementId, statementThreadId] = value.split(',');
      return (
        <Link
          rel="noreferrer"
          style={{ color: amaliaTheme.palette.link.main }}
          target="_blank"
          to={generatePath(routes.STATEMENT_COMMENT, {
            id: statementId,
            stid: statementThreadId,
          })}
        >
          {text}
        </Link>
      );
    }
    case 'customObject':
      return (
        <Link
          rel="noreferrer"
          style={{ color: amaliaTheme.palette.link.main }}
          target="_blank"
          to={{
            pathname: generatePath(routes.DATA_SLUG, { slug: value }),
            search: `?${objectToQs({ search: text })}`,
          }}
        >
          {text}
        </Link>
      );
    default:
      return text;
  }
};

export const replaceRichText = (
  todoId: string,
  text: string,
  todoType: TodoType,
  statementThreadId?: string,
): (ReactElement | string)[] => {
  const richText: (ReactElement | string)[] = [];
  let position = 0;

  for (const occur of text.matchAll(regex)) {
    const { groups } = occur;
    if (groups) {
      const [element] = occur;
      const occurPosition = text.indexOf(element);
      if (text.slice(position, occurPosition).length) {
        richText.push(text.slice(position, occurPosition));
      }
      position = occurPosition + element.length;
      let link: ReactElement | string;
      if (todoType === TodoType.THREAD && statementThreadId) {
        link = getLink('statementThread', `${groups.value},${statementThreadId}`, groups.text);
      } else {
        link = getLink(groups.entity as EntityType, groups.value, groups.text);
      }
      richText.push(<span key={`todo_${todoId}_${position}`}>{link}</span>);
    }
  }
  return richText;
};
