import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { CompanyRoute } from '@amal-ia/frontend/connected-components/routing';
import { useAbilityContext, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { companyRoutesMessages } from './company.routes.messages';

const RateListPage = lazy(() => import('./rates'));
const ApiKeySettings = lazy(() => import('./settings/ApiKeySettings/ApiKeySettings'));
const CompanyPage = lazy(() => import('./settings/CompanyPage'));
const WorkflowContainer = lazy(() => import('./workflows/WorkflowContainer'));

export const CompanyRoutes = memo(function CompanyRoutes() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  return (
    <Layout>
      <Switch>
        <CompanyRoute
          component={WorkflowContainer}
          path={routes.COMPANY_WORKFLOWS}
          shouldRedirectToHome={ability.cannot(ActionsEnum.modify, SubjectsEnum.Workflow)}
          title={formatMessage(companyRoutesMessages.WORKFLOW)}
          user={user}
        />

        <CompanyRoute
          component={RateListPage}
          path={routes.COMPANY_RATES}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view_rates, SubjectsEnum.Company)}
          title={formatMessage(companyRoutesMessages.CURRENCY)}
          user={user}
        />

        <CompanyRoute
          component={ApiKeySettings}
          path={routes.COMPANY_API}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view, SubjectsEnum.ApiKeys)}
          title={formatMessage(companyRoutesMessages.COMPANY_API)}
          user={user}
        />

        <CompanyRoute
          component={CompanyPage}
          path={routes.COMPANY}
          shouldRedirectToHome={ability.cannot(ActionsEnum.view_settings, SubjectsEnum.Company)}
          title={formatMessage(companyRoutesMessages.COMPANY)}
          user={user}
        />
      </Switch>
    </Layout>
  );
});
