import { type DataConnectorObject } from '@amal-ia/data-capture/connectors/types';
import { http } from '@amal-ia/frontend/kernel/http';

const apiEndpoint = '/connectors';

export class DataConnectorObjectRepository {
  /**
   * Add data connector object.
   * @param connectorId
   * @param object
   */
  public static async addConnectorObject(
    connectorId: string,
    object: DataConnectorObject,
  ): Promise<DataConnectorObject> {
    const response = await http.post<DataConnectorObject>(`${apiEndpoint}/${connectorId}/objects`, object);
    return response.data;
  }

  /**
   * Update data connector object.
   * @param connectorId
   * @param object
   */
  public static async updateConnectorObject(
    connectorId: string,
    object: DataConnectorObject,
  ): Promise<DataConnectorObject> {
    const response = await http.put<DataConnectorObject>(
      `${apiEndpoint}/${connectorId}/objects/${object.name}`,
      object,
    );
    return response.data;
  }

  /**
   * Delete data connector object.
   * @param connectorId
   * @param objectName
   */
  public static async deleteConnectorObject(connectorId: string, objectName: string): Promise<void> {
    const response = await http.delete(`${apiEndpoint}/${connectorId}/objects/${objectName}`);
    return response.data;
  }
}
