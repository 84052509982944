import { css } from '@emotion/react';

import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { LabelVariant } from '../../types';

export const NAME_TYPOGRAPHY_VARIANT = {
  [LabelVariant.DEFAULT]: TypographyVariant.BODY_BASE_REGULAR,
  [LabelVariant.ACCENTUATED]: TypographyVariant.BODY_BASE_MEDIUM,
  [LabelVariant.BOLD]: TypographyVariant.BODY_BASE_MEDIUM,
} as const;

export const EXTERNAL_ID_TYPOGRAPHY_VARIANT = TypographyVariant.BODY_XSMALL_REGULAR;

export const styles = {
  container: css`
    --row-gap: 0px;
    --column-gap: 12px;

    width: 100%;

    display: grid;
    grid-template-areas: 'avatar user-info';
    justify-content: flex-start;
    gap: var(--row-gap) var(--column-gap);
  `,
  containerBlockDisplay: css`
    /** See footnote (1) */
    align-items: flex-start;
  `,
  containerInlineDisplay: css`
    align-items: center;
  `,
  avatarSkeleton: css`
    grid-area: avatar;

    /** Don't allow the skeleton & avatar to shrink when the full name is long */
    flex-shrink: 0;
  `,
  avatarSkeletonBlockDisplay: css`
    /** See footnote (1). Define it on Avatar if you remove Skeleton */
    margin-top: 6px;
  `,
  userInfoContainer: css`
    grid-area: user-info;

    display: grid;
    gap: var(--row-gap) var(--column-gap);
    grid-template-areas: 'name' 'sub-label';

    align-items: baseline;
    word-break: break-word;
  `,
  userInfoContainerInlineDisplay: css`
    grid-template-areas: 'name sub-label';
    overflow: hidden;
    white-space: nowrap;
  `,
  nameSkeleton: css`
    grid-area: name;
  `,
  name: css`
    text-transform: capitalize;
  `,
  subLabel: css`
    grid-area: sub-label;
  `,
} as const;

/*
 * (1): We expect the avatar to be aligned with the
 * first two lines of the text (fullName + subLabel).
 *
 * The text can grow over this count with very long names.
 *
 * That's why we don't use `align-items: center;`, and
 * we push the avatar down with margin-top.
 */
