import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type BadgeAward, type BadgeConfiguration } from '@amal-ia/lib-types';

import { BadgeItem } from './BadgeItem';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
}));

interface BadgeListProps {
  badgeAwards: BadgeAward[];
  configurations: BadgeConfiguration[];
}

export const BadgeList = memo(function BadgeList({ badgeAwards, configurations }: BadgeListProps) {
  const classes = useStyles();

  const notAwarded = useMemo(() => {
    const awardedIds = badgeAwards.map((b) => b.configurationId);
    return (configurations || []).filter((c) => !c.deletedAt && !awardedIds.includes(c.id));
  }, [configurations, badgeAwards]);

  return (
    <Box className={classes.container}>
      {badgeAwards.map((a) => (
        <BadgeItem
          key={a.configuration.id}
          award={a}
          configuration={a.configuration}
        />
      ))}
      {notAwarded.map((c) => (
        <BadgeItem
          key={c.id}
          configuration={c}
        />
      ))}
    </Box>
  );
});
