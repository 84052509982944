import { Box } from '@mui/material';
import { type Dispatch, memo, type SetStateAction } from 'react';

import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { amaliaTheme, colors as colorPalette } from '@amal-ia/ext/mui/theme';
import { formatAmount } from '@amal-ia/lib-types';

import { Text, TextType } from '../../typography';

import { type DataDonut } from './DonutChart';

interface DonutAnnotationProps {
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
  annotationIndex: number;
  colors?: string[];
  currency?: CurrencySymbolsEnum;
  entry: DataDonut;
}

export const DonutAnnotation = memo(function DonutAnnotation({
  activeSection,
  setActiveSection,
  annotationIndex,
  colors,
  currency,
  entry,
}: DonutAnnotationProps) {
  return (
    <Box
      key={`cell-${annotationIndex + 1}`}
      bgcolor={activeSection === annotationIndex ? colors[annotationIndex % colors.length] : colorPalette['grey-100']}
      color={colorPalette['grey-700']}
      data-testid="donut-annotation"
      display="flex"
      flexDirection="row"
      height={35}
      margin={amaliaTheme.spacing(0.75, 0)}
      padding={amaliaTheme.spacing(1)}
      width="100%"
      onMouseLeave={() => setActiveSection(undefined)}
      onMouseOver={() => setActiveSection(annotationIndex)}
    >
      <Box
        bgcolor={colors[annotationIndex % colors.length]}
        border={activeSection === annotationIndex ? 2 : 'unset'}
        borderRadius="9999px"
        height={10}
        id={`donut-annotation-${annotationIndex + 1}`}
        margin={amaliaTheme.spacing(0.3, 1, 0, 0.2)}
        width={10}
        borderColor={
          activeSection === annotationIndex ? colorPalette['grey-100'] : colors[annotationIndex % colors.length]
        }
      />
      <Text
        flex={1}
        type={TextType.DONUT_ANNOTATION_TITLE}
      >
        {entry.name}
      </Text>
      <Box marginLeft="40px">
        <Text type={TextType.DONUT_ANNOTATION_VALUE}>{formatAmount(entry.value, currency)}</Text>
      </Box>
    </Box>
  );
});
