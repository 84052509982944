import { useCallback, useRef, useState } from 'react';

import { useSnackbars } from '@amal-ia/frontend/design-system/components';

/**
 * When you have the flemme to use redux.
 *
 * Kinda ugly but at least it supports error handling and loaders.
 * But it doesn't have any cache whatsoever.
 * Use redux instead.
 *
 * Please rename the `data` property while destructuring (or you will
 * be targeted during code review).
 *
 * @param fetchData
 * @param defaultData
 */
export const useDataFetch = <F extends (...params: any[]) => any>(
  fetchData: F,
  defaultData: Awaited<ReturnType<F>>,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Awaited<ReturnType<F>>>(defaultData);
  const { snackError } = useSnackbars();
  const onGoingCallUUID = useRef<string | null>(null);

  const isLastCallExecuted = useCallback(
    (raceConditionUUID: string) => onGoingCallUUID.current === raceConditionUUID,
    [],
  );

  const getData = useCallback(
    async (...params: Parameters<F>) => {
      setIsLoading(true);

      const raceConditionUUID = crypto.randomUUID();
      onGoingCallUUID.current = raceConditionUUID;

      try {
        const newData: Awaited<ReturnType<any>> = await fetchData(...params);
        if (isLastCallExecuted(raceConditionUUID)) setData(newData);
      } catch (e) {
        snackError(e.message);
      } finally {
        if (isLastCallExecuted(raceConditionUUID)) setIsLoading(false);
      }
    },
    [setData, setIsLoading, snackError, fetchData, isLastCallExecuted],
  );

  return {
    getData,
    data,
    isLoading,
    // Expose setters so we can eventually update the data from the component.
    setIsLoading,
    setData,
  };
};
