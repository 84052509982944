import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('monthNumber', AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the month in number format of a given date';

func.params = [
  {
    name: 'date',
    description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
  },
];

func.examples = [
  {
    desc: 'Returns 9',
    formula: 'monthNumber(toDate("2023-09-16"))' as AmaliaFormula,
    result: 9,
  },
  {
    desc: 'Returns the month in number format of Close Date.',
    formula: 'monthNumber(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null => moment(date, 'X').month() + 1;

export default func;
