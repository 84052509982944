import { memo, forwardRef, type ForwardedRef } from 'react';

import { Button, type ButtonProps } from '../../../general/button/Button';
import { type ButtonVariant } from '../../../general/button/Button.types';
import { useModalContext } from '../Modal.context';
import { ModalVariant } from '../Modal.types';

const MODAL_VARIANT_BUTTON_VARIANT_MAPPING: Record<ModalVariant, ButtonVariant> = {
  [ModalVariant.DEFAULT]: Button.Variant.PRIMARY,
  [ModalVariant.DANGER]: Button.Variant.DANGER,
};

export type ModalMainAction = Omit<ButtonProps, 'size' | 'variant'>;

const ModalMainActionForwardRef = forwardRef(function ModalMainAction(
  { children, ...props }: ModalMainAction,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { variant } = useModalContext();

  return (
    <Button
      {...props}
      ref={ref}
      size={Button.Size.MEDIUM}
      variant={MODAL_VARIANT_BUTTON_VARIANT_MAPPING[variant]}
    >
      {children}
    </Button>
  );
});

export const ModalMainAction = memo(ModalMainActionForwardRef);
