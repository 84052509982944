import moment from 'moment';

import { type AmaliaFormula, AmaliaFunctionCategory } from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction('startOfYear', AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the first day of year of a given date';

func.params = [
  {
    name: 'date',
    description:
      'Date to apply function on: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
  },
];

func.examples = [
  {
    desc: 'Returns "2023-01-01"',
    formula: 'startOfYear(toDate("2023-05-19"))' as AmaliaFormula,
    result: 1_672_531_200,
  },
  {
    desc: "Returns first day of year of the user's plan assignment start date.",
    formula: 'startOfYear(planAssignement.effectiveAsOf)' as AmaliaFormula,
  },
  {
    desc: 'Returns first day of year of opportunity close date.',
    formula: 'startOfYear(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null =>
  +moment(date, 'X').startOf('year').format('X') || null;

export default func;
