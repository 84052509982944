import invariant from 'invariant';
import { uniqBy } from 'lodash';
import { useCallback } from 'react';

import {
  type SelectDropdownStateProps,
  type SelectDropdownOption,
  type SelectOptionGroup,
} from '../SelectDropdown.types';

const isOptionSelectable = <TOption extends SelectDropdownOption = SelectDropdownOption>(
  option: TOption,
  checkedOptionsValues: TOption['value'][],
) => !option.disabled || checkedOptionsValues.includes(option.value);

const isOptionRemovable = <TOption extends SelectDropdownOption = SelectDropdownOption>(
  option: TOption,
  checkedOptionsValues: TOption['value'][],
) => !(option.disabled && checkedOptionsValues.includes(option.value));

export type UseStateHandlersOptions<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
> = {
  /** Is multi-select. */
  isMultiple?: SelectDropdownStateProps<TOption, TIsMultiple, true, TIsClearable>['isMultiple'];
  /** Is value clearable in single select. */
  isClearable?: SelectDropdownStateProps<TOption, TIsMultiple, true, TIsClearable>['isClearable'];
  /** Option or list of options or null. */
  value: Required<SelectDropdownStateProps<TOption, TIsMultiple, true, TIsClearable>>['value'];
  /** Value change handler. */
  onChange: Required<SelectDropdownStateProps<TOption, TIsMultiple, true, TIsClearable>>['onChange'];
  /** Flat list of options. */
  options: TOption[];
  /** Values of checked options. */
  checkedOptionsValues: TOption['value'][];
};

export type UseStateHandlersValue<
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = {
  handleSelectAllChange: (selectAllChecked: boolean) => void;
  handleClear: () => void;
  handleGroupSelectAllChange: (group: TGroup, selectAllChecked: boolean) => void;
  handleOptionChange: (option: TOption, checked: boolean) => void;
};

export const useStateHandlers = <
  TOption extends SelectDropdownOption = SelectDropdownOption,
  TIsMultiple extends boolean | undefined = undefined,
  TIsClearable extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>({
  isMultiple,
  isClearable,
  onChange,
  options,
  checkedOptionsValues,
  value,
}: UseStateHandlersOptions<TOption, TIsMultiple, TIsClearable>): UseStateHandlersValue<TOption, TGroup> => {
  // Handler for the global select all.
  const handleSelectAllChange: UseStateHandlersValue<TOption, TGroup>['handleSelectAllChange'] = useCallback(
    (selectAllChecked) => {
      invariant(isMultiple, 'Select all checkbox should not be rendered if isMultiple is false');

      return (onChange as (options: TOption[]) => void)(
        selectAllChecked
          ? // Only add options that are not disabled or already selected.
            options.filter((option) => isOptionSelectable(option, checkedOptionsValues))
          : // Only remove options that are not disabled and already checked.
            options.filter((option) => !isOptionRemovable(option, checkedOptionsValues)),
      );
    },
    [checkedOptionsValues, options, isMultiple, onChange],
  );

  const handleClear: UseStateHandlersValue<TOption, TGroup>['handleClear'] = useCallback(() => {
    if (isMultiple) {
      return handleSelectAllChange(false);
    }

    invariant(isClearable, 'Clear button should not be rendered if isClearable is false');

    return (onChange as (options: TOption | null) => void)(null);
  }, [handleSelectAllChange, isClearable, isMultiple, onChange]);

  // Handler for the group select all.
  const handleGroupSelectAllChange: UseStateHandlersValue<TOption, TGroup>['handleGroupSelectAllChange'] = useCallback(
    (group, selectAllChecked) => {
      invariant(isMultiple, 'Select all checkbox should not be rendered if isMultiple is false');

      const currentValue = (value ?? []) as TOption[];
      const onChangeMulti = onChange as (options: TOption[]) => void;

      if (selectAllChecked) {
        // Only add options that are not disabled or already selected.
        const optionsToAdd = group.options.filter((option) => isOptionSelectable(option, checkedOptionsValues));

        onChangeMulti(uniqBy([...currentValue, ...optionsToAdd], 'value'));
      } else {
        // Only remove options that are not disabled and already checked.
        const optionsToRemove = group.options.filter((option) => isOptionRemovable(option, checkedOptionsValues));

        onChangeMulti(
          currentValue.filter((option) => !optionsToRemove.some((groupOption) => groupOption.value === option.value)),
        );
      }
    },
    [checkedOptionsValues, isMultiple, onChange, value],
  );

  // Handler for the option change.
  const handleOptionChange: UseStateHandlersValue<TOption, TGroup>['handleOptionChange'] = useCallback(
    (option, checked) => {
      if (isMultiple) {
        const currentValue = (value ?? []) as TOption[];
        const onChangeMulti = onChange as (options: TOption[]) => void;

        onChangeMulti(
          checked
            ? uniqBy([...currentValue, option], 'value')
            : currentValue.filter((currentOption) => currentOption.value !== option.value),
        );
      } else {
        (onChange as (options: TOption | null) => void)(!isClearable || checked ? option : null);
      }
    },
    [isMultiple, onChange, value, isClearable],
  );

  return {
    handleSelectAllChange,
    handleClear,
    handleGroupSelectAllChange,
    handleOptionChange,
  };
};
