import { memo, type ReactNode } from 'react';

import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import {
  AmaliaLocalizationProvider,
  type AmaliaLocalizationProviderProps,
} from '@amal-ia/frontend/kernel/intl/context';
import { type LocaleBundle } from '@amal-ia/frontend/kernel/intl/types';
import { type LanguagesEnum } from '@amal-ia/kernel/intl/types';

interface WebLocalizationProviderProps {
  children: ReactNode;
}

const importLocale: AmaliaLocalizationProviderProps<LanguagesEnum>['importLocale'] = async (locale) =>
  ((await import(`../assets/i18n/${locale}`)) as { bundle: LocaleBundle<LanguagesEnum> }).bundle;

export const WebLocalizationProvider = memo(function WebLocalizationProvider({
  children,
}: WebLocalizationProviderProps) {
  const { data: user } = useCurrentUser();

  return (
    <AmaliaLocalizationProvider
      importLocale={importLocale}
      locale={user?.language}
    >
      {children}
    </AmaliaLocalizationProvider>
  );
});
