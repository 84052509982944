import { type ForwardedRef, forwardRef, memo, type ReactNode, useState } from 'react';

import { SelectorTrigger, type SelectorTriggerProps } from '../../../general/selector-trigger/SelectorTrigger';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Popover } from '../../../overlays/popover/Popover';

import * as styles from './BreadcrumbsSelectItem.styles';

export type BreadcrumbsSelectItemProps = Omit<SelectorTriggerProps, 'as'> & {
  popoverContent: ReactNode;
  children: SelectorTriggerProps['children'];
};

const BreadcrumbsSelectItemForwardRef = forwardRef(function BreadcrumbsSelectItem(
  { children, popoverContent, ...props }: BreadcrumbsSelectItemProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div>
      <Popover
        content={popoverContent}
        css={styles.selectItemPopover}
        isOpen={isPopoverOpen}
        onChangeIsOpen={setIsPopoverOpen}
      >
        <SelectorTrigger
          {...props}
          ref={ref}
          as={TextOverflow}
          css={styles.selector}
          isActive={isPopoverOpen}
        >
          {children}
        </SelectorTrigger>
      </Popover>
    </div>
  );
});

export const BreadcrumbsSelectItem = memo(BreadcrumbsSelectItemForwardRef);
