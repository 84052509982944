import { noop } from 'lodash';
import { memo, useMemo, type ComponentPropsWithoutRef } from 'react';

import { type Merge } from '@amal-ia/ext/typescript';

import { Typography } from '../../general/typography/Typography';

import { VerticalTabsMenuItem, type VerticalTabsMenuItemProps } from './vertical-tabs-menu-item/VerticalTabsMenuItem';
import * as styles from './VerticalTabsMenu.styles';
import { isTabGroups, type TabKey, type VerticalTabsMenuGroup } from './VerticalTabsMenu.types';

export type VerticalTabsMenuProps<TKey extends TabKey> = Merge<
  ComponentPropsWithoutRef<'div'>,
  {
    /** Tabs or groups of tabs. Empty groups are ignored. */
    tabs: VerticalTabsMenuGroup<TKey>['tabs'] | VerticalTabsMenuGroup<TKey>[];
    /** Key of the active tab. */
    activeTab: TKey;
    /** Change tab handler. */
    onChangeActiveTab: (key: TKey) => void;
    /** Tab label change handler. */
    onChangeTabLabel?: VerticalTabsMenuItemProps<TKey, true>['onChangeLabel'];
  }
>;

export const VerticalTabsMenuBase = function VerticalTabsMenu<TKey extends TabKey>({
  tabs,
  activeTab,
  onChangeActiveTab,
  onChangeTabLabel = noop,
  ...props
}: VerticalTabsMenuProps<TKey>) {
  // If the argument is not tab groups, convert it to a group.
  const tabsGroups: VerticalTabsMenuGroup<TKey>[] = useMemo(
    () => (isTabGroups(tabs) ? tabs : [{ tabs }]).filter((tabsGroup) => tabsGroup.tabs.length),
    [tabs],
  );

  return (
    <div
      {...props}
      css={styles.verticalTabsMenuContainer}
    >
      <ul css={styles.tabsGroups}>
        {tabsGroups.map((tabGroup) => (
          <li
            key={tabGroup.tabs[0].key}
            css={styles.tabsGroup}
          >
            {!!tabGroup.label && (
              <Typography
                as="div"
                css={styles.tabsGroupLabel}
                variant={Typography.Variant.BODY_XSMALL_MEDIUM}
              >
                {tabGroup.label}
              </Typography>
            )}

            <ul>
              {tabGroup.tabs.map((tab) => (
                <VerticalTabsMenuItem
                  key={tab.key}
                  isSelected={activeTab === tab.key}
                  tab={tab}
                  onChangeLabel={onChangeTabLabel}
                  onClick={onChangeActiveTab}
                />
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const VerticalTabsMenu = memo(VerticalTabsMenuBase) as typeof VerticalTabsMenuBase;
