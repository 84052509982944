import { defineMessages } from 'react-intl';

export const quotasRoutesMessages = defineMessages({
  QUOTAS_VALUE: {
    defaultMessage: 'Quota',
  },
  QUOTAS_USER: {
    defaultMessage: 'Quota by user',
  },
  QUOTAS: {
    defaultMessage: 'Quota list',
  },
} as const);
