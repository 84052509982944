import { defineMessages } from 'react-intl';

export const statementsRoutesMessages = defineMessages({
  STATEMENT: {
    defaultMessage: 'Statement',
  },
  STATEMENTS: {
    defaultMessage: 'Statements',
  },
  FORECAST: {
    defaultMessage: 'Forecast',
  },
  FORECASTS: {
    defaultMessage: 'Forecasts',
  },
} as const);
