import { ArrowDropDown } from '@mui/icons-material';
import { Box, ClickAwayListener, List, ListItem, ListItemText, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { IconButton } from '@amal-ia/frontend/design-system/components';

import { TransformedIcon, IconTransformations } from '../../../utils/common/transformedIcons';
import { TextType, Text } from '../typography';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    position: 'relative',
    zIndex: 1001,
  },
  dialog: {
    right: 0,
    minWidth: 200,
    bottom: -5,
    background: theme.palette.common.white,
    zIndex: 1000,
    borderRadius: 5,
    transform: 'translateY(100%)',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.1)',
  },
  buttonContainer: {
    marginLeft: theme.spacing(1),
  },
}));

interface TitleSelectProps {
  value: string;
  options: string[];
  onChange: (value: string) => void;
}

export const TitleSelect = memo(function TitleSelect({ value, options, onChange }: TitleSelectProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState<boolean>(false);

  const onClick = useCallback(() => setOpen((status) => !status), []);

  const onChangeProxy = useCallback(
    (newValue: string) => {
      setOpen(false);
      onChange(newValue);
    },
    [onChange],
  );

  const onClickAway = useCallback(() => setOpen(false), []);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Grid
        container
        alignItems="center"
        className={classes.root}
        justifyContent="center"
        spacing={1}
        wrap="nowrap"
      >
        <Grid onClick={onClick}>
          <Text
            align="center"
            type={TextType.SELECTOR_PILL}
          >
            {value}
          </Text>
        </Grid>
        {options.length !== 0 && (
          <Grid className={classes.buttonContainer}>
            <IconButton
              color="action"
              label={formatMessage({ defaultMessage: 'Filter…' })}
              icon={
                <TransformedIcon
                  icon={<ArrowDropDown fontSize="inherit" />}
                  size={8}
                  transformations={[
                    IconTransformations.SIZE(1.2),
                    IconTransformations.COLOR(amaliaTheme.palette.common.white),
                  ]}
                />
              }
              onClick={onClick}
            />
          </Grid>
        )}
        {open ? (
          <Box
            className={classes.dialog}
            position="absolute"
            role="listbox"
          >
            <List dense>
              {options.map((option) => (
                <ListItem
                  key={`selectTitle_${option}`}
                  button
                  onClick={() => onChangeProxy(option)}
                >
                  <ListItemText primary={option} />
                </ListItem>
              ))}
            </List>
          </Box>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
});
