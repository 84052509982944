import { combineReducers } from 'redux';

import { type StatementThreadScope, type ReduxAction } from '@amal-ia/lib-types';

import { COMMENTS_ACTIONS } from './constants';

export default combineReducers({
  openedThreadId: (
    state: string | null = null,
    action: ReduxAction<(typeof COMMENTS_ACTIONS)[keyof typeof COMMENTS_ACTIONS], { id: string }>,
  ) => {
    switch (action.type) {
      case COMMENTS_ACTIONS.SET_OPENED_THREAD:
        return action.payload.id;
      case COMMENTS_ACTIONS.CLOSE_THREAD:
        return null;
      default:
        return state;
    }
  },
  openedThreadOptions: (
    state: StatementThreadScope | null = null,
    action: ReduxAction<
      (typeof COMMENTS_ACTIONS)[keyof typeof COMMENTS_ACTIONS],
      { scope: StatementThreadScope | null }
    >,
  ) => {
    switch (action.type) {
      case COMMENTS_ACTIONS.SET_OPENED_THREAD:
        return action.payload.scope || null;
      case COMMENTS_ACTIONS.CLOSE_THREAD:
        return null;
      default:
        return state;
    }
  },
});
