import { IconBuilding, IconCurrencyDollar, IconLock, IconSettings, IconTopologyBus } from '@tabler/icons-react';
import { cloneElement, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { MenuDropdown } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { useFeatureFlag } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { CompanyFeatureFlags } from '@amal-ia/tenants/shared/types';

export const LeftBarSettings = memo(function LeftBarSettings() {
  const ability = useAbilityContext();
  const { isFeatureEnabled: isPaymentsLockEnabled } = useFeatureFlag(CompanyFeatureFlags.PAYMENTS_LOCK);

  const actions = [
    ability.can(ActionsEnum.modify, SubjectsEnum.Workflow) && {
      key: 'workflows',
      item: (
        <MenuDropdown.ItemLink
          icon={<IconTopologyBus />}
          label={<FormattedMessage defaultMessage="Workflows" />}
          to={generatePath(routes.COMPANY_WORKFLOWS)}
        />
      ),
    },
    ability.can(ActionsEnum.view_rates, SubjectsEnum.Company) && {
      key: 'currencies',
      item: (
        <MenuDropdown.ItemLink
          icon={<IconCurrencyDollar />}
          label={<FormattedMessage defaultMessage="Currencies" />}
          to={generatePath(routes.COMPANY_RATES)}
        />
      ),
    },
    isPaymentsLockEnabled &&
      ability.can(ActionsEnum.modify_settings, SubjectsEnum.Company) && {
        key: 'paymentsLock',
        item: (
          <MenuDropdown.ItemLink
            icon={<IconLock />}
            label={<FormattedMessage defaultMessage="Payments lock" />}
            to={generatePath(routes.COMPANY_PAYMENTS_LOCK)}
          />
        ),
      },
    ability.can(ActionsEnum.view_settings, SubjectsEnum.Company) && {
      key: 'settings',
      item: (
        <MenuDropdown.ItemLink
          icon={<IconBuilding />}
          label={<FormattedMessage defaultMessage="Company" />}
          to={generatePath(routes.COMPANY_SETTINGS)}
        />
      ),
    },
  ].filter(Boolean);

  return actions.length ? (
    <MenuDropdown content={actions.map(({ key, item }) => cloneElement(item, { key }))}>
      <MenuDropdown.IconButton
        icon={<IconSettings />}
        label={<FormattedMessage defaultMessage="Settings" />}
      />
    </MenuDropdown>
  ) : null;
});
