import { defineMessages } from 'react-intl';

export const planAgreementsRoutesMessages = defineMessages({
  VIEW_DOCUMENT: {
    defaultMessage: 'Plan agreement | View document',
  },
  PREVIEW_DOCUMENT: {
    defaultMessage: 'Plan agreement | Preview document',
  },
  PLAN_AGREEMENT: {
    defaultMessage: 'Plan agreement',
  },
  PLAN_AGREEMENTS: {
    defaultMessage: 'Plan agreements',
  },
} as const);
