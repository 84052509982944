import { ClassNames } from '@emotion/react';
import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  cloneElement,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type JSXElementConstructor,
} from 'react';

import { useTextOverflows } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { type GrabHandleProps } from '../../../general/grab-handle/GrabHandle';
import { type TablerIconElement } from '../../../general/icons/types';
import { Typography } from '../../../general/typography/Typography';
import { Tooltip } from '../../tooltip/Tooltip';
import { DropdownItemContentActions } from '../dropdown-item-content-actions/DropdownItemContentActions';

import * as styles from './DropdownItemContent.styles';

export type DropdownItemContentProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      label: ReactNode;
      isInteractive?: boolean;
      icon?: TablerIconElement;
      disabled?: boolean;
      isActive?: boolean;
      rightActions?: ReactNode;
      grabHandle?: ReactElement<GrabHandleProps, JSXElementConstructor<GrabHandleProps>>;
    },
  ]
>;

const DropdownItemContentBase = memo(function DropdownItemContent({
  label,
  isInteractive = false,
  icon = undefined,
  disabled = false,
  isActive = false,
  grabHandle = undefined,
  rightActions = undefined,
  ...props
}: DropdownItemContentProps) {
  const { ref: labelRef, doesTextOverflow } = useTextOverflows([label]);

  return (
    <Tooltip
      content={doesTextOverflow ? label : ''}
      placement="right"
    >
      <div
        {...props}
        css={styles.dropdownItemContent}
        className={clsx(props.className, {
          [styles.IS_ACTIVE_CLASSNAME]: isActive,
          [styles.IS_DISABLED_CLASSNAME]: disabled,
          [styles.IS_INTERACTIVE_CLASSNAME]: isInteractive,
          [styles.HAS_HANDLE_CLASSNAME]: grabHandle && !disabled,
        })}
      >
        {!disabled && grabHandle}

        {!!icon && (
          <ClassNames>
            {({ css }) =>
              cloneElement(icon, {
                width: icon.props.width || icon.props.size || 18,
                height: icon.props.height || icon.props.size || 18,
                color: disabled || !icon.props.color ? 'currentColor' : icon.props.color,
                className: clsx(
                  icon.props.className,
                  css`
                    flex: none;
                  `,
                ),
              })
            }
          </ClassNames>
        )}

        <Typography
          ref={labelRef}
          css={styles.label}
          variant={isActive ? Typography.Variant.BODY_BASE_MEDIUM : Typography.Variant.BODY_BASE_REGULAR}
        >
          {label}
        </Typography>

        {rightActions}
      </div>
    </Tooltip>
  );
});

export const DropdownItemContent = Object.assign(DropdownItemContentBase, {
  Actions: DropdownItemContentActions,
});
