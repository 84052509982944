import { IconSearch } from '@tabler/icons-react';
import { type ForwardedRef, memo, useCallback, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { Input, type InputProps } from '../../../forms/input/Input';

import * as styles from './DropdownSearchInput.styles';

export type DropdownSearchInputProps = Omit<InputProps, 'action' | 'leftIcon' | 'size' | 'type'>;

const DropdownSearchInputForwardRef = forwardRef(function DropdownSearchInput(
  { onChange, value, ...props }: DropdownSearchInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { formatMessage } = useIntl();
  const handleClear = useCallback(() => onChange?.(''), [onChange]);

  return (
    <Input
      {...props}
      ref={ref}
      action={value ? <Input.ClearAction onClick={handleClear} /> : undefined}
      css={styles.input}
      leftIcon={<IconSearch />}
      placeholder={props.placeholder ?? formatMessage({ defaultMessage: 'Search…' })}
      size={Input.Size.SMALL}
      type="text"
      value={value}
      onChange={onChange}
    />
  );
});

export const DropdownSearchInput = memo(DropdownSearchInputForwardRef);
